






























































































































import { map, sumBy } from 'lodash';

import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoPrice from '../../Atoms/co-price/coPrice.vue';
import CoDate from '../co-date/CoDate.vue';
import CoTable from '../co-table/CoTable.vue';

export default {
    name: 'CoInvoiceDetail',
    components: { CoTable, CoHeadline, CoDate, CoPrice },
    props: {
        invoice: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            tax: {},
        };
    },
    computed: {
        items() {
            if (!this.invoice || !this.invoice.InvoiceLines) {
                return [];
            }
            return this.invoice.InvoiceLines.map((item) => {
                const taxAmount = 0;
                map(item.TaxRates, 'Percentage').forEach((i) => {
                    if (this.tax[i] && this.tax[i].netAmount) {
                        this.tax[i].netAmount += item.Amount;
                        this.tax[i].taxAmount += item.Amount * (i / 100);
                    } else {
                        this.tax[i] = {
                            netAmount: item.Amount,
                            taxAmount: item.Amount * (i / 100),
                        };
                    }
                });
                item.AmountWithTax = item.Amount + taxAmount;
                return item;
            });
        },
        subtotal() {
            return sumBy(this.items, 'Amount');
        },
    },
};
