import { VueRenderer } from '@tiptap/vue-2';
import tippy from 'tippy.js';
import axios from 'axios';
import MentionList from './MentionList.vue';
import { PluginKey, Plugin } from 'prosemirror-state';
import plugin from '@egjs/vue-flicking';

export const SuggestionPluginKey = new PluginKey('suggestion');

const exitSuggestion = (view, pluginKeyName) => {
    const state = {
        active: false,
        key: null,
        range: {},
        query: null,
        text: null,
        composing: false,
    };
    view.dispatch(view.state.tr.setMeta(pluginKeyName, state));
};

export default {
    // name: 'suggestion',
    pluginKey: SuggestionPluginKey,
    char: '@',
    allowSpaces: true,
    items: async ({ editor, query }) => {
        if (!query) {
            return [];
        }
        const result = [];
        await axios({
            method: 'GET',
            url: `/search/v2/user/${query}`,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response && response.data) {
                    response.data.forEach((entry) => {
                        result.push({
                            name: entry.Profile.Name,
                            slug: entry.Slug,
                            id: entry.ID,
                            thumbnail: entry.Profile.ImageURL,
                        });
                    });
                }
            })
            .catch((error) => {});
        return result;
    },

    render: () => {
        var component;
        var popup;
        return {
            onStart: (props) => {
                component = new VueRenderer(MentionList, {
                    // using vue 2:
                    parent: this,
                    propsData: props,
                    // props,
                    editor: props.editor,
                });

                popup = tippy('body', {
                    appendTo: () => document.body,
                    content: component.element,
                    showOnCreate: true,
                    hideOnClick: false, // check if this makes sense when hiding it from another method
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start',
                    getReferenceClientRect: props.clientRect || null,
                });
            },

            onUpdate(props) {
                component.updateProps(props);

                if (!props.clientRect) {
                    return;
                }

                popup[0].setProps({
                    content: component.element,
                    getReferenceClientRect: props.clientRect,
                });
            },

            onKeyDown(props) {
                if (props.event.key === 'Escape') {
                    exitSuggestion(props.view, 'mention');
                    popup[0].hide();
                    return true;
                }

                return component.ref.onKeyDown(props);
            },

            onBeforeUpdate(props) {},

            onExit() {
                popup && popup[0] ? popup[0].destroy() : null;
                component ? component.destroy() : null;
            },
        };
    },
};
