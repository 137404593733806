

















import Vue, { PropType } from 'vue';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoSimpleDropdownList from '@/components/Molecules/co-simple-dropdown-list/CoSimpleDropdownList.vue';
import VuePortal from '@linusborg/vue-simple-portal';

Vue.use(VuePortal);

type Element = {
    text: string;
    id: string;
};

const outside = {
    bind(el, binding, vnode) {
        // eslint-disable-next-line no-param-reassign
        el.clickOutsideEvent = (event) => {
            // here I check that click was outside the el and his children
            if (!(el === event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    },
};

export default Vue.extend({
    name: 'CoThreeDotsMenu',
    components: { CoIcon, CoSimpleDropdownList },
    directives: {
        'outside-click': outside,
    },
    props: {
        items: {
            type: Array as PropType<Array<string | Element>>,
            required: true,
            default: () => [],
        },
        icon: {
            type: String,
            default: 'three-dots',
        },
        portalSelector: {
            type: String,
            required: false,
            default: '',
        },
        extraData: {
            required: false,
            default: () => {},
        },
    },
    data() {
        return {
            open: false,
            dropdownStyle: {},
        };
    },
    methods: {
        getScrollableParent(element) {
            while (element && element !== document.body) {
                const { overflowY } = window.getComputedStyle(element);
                if (overflowY === 'auto' || overflowY === 'scroll') {
                    if (!element.classList.contains('co-table')) return element;
                }
                element = element.parentElement;
            }
            return window;
        },
        toggle(event) {
            if (this.portalSelector !== '') {
                const scrollableParent = this.getScrollableParent(event.target);
                const scrollTop = scrollableParent === window ? window.scrollY : scrollableParent.scrollTop;

                this.dropdownStyle = {
                    position: 'absolute',
                    top: `${event.clientY + scrollTop + 10}px`,
                    left: `${event.clientX - 80}px`,
                };
            }
            this.open = !this.open;
        },
        closeMenu() {
            this.open = false;
        },
        onClick(id: string) {
            this.open = false;
            if (this.extraData) {
                this.$emit('click', id, this.extraData);
                return;
            }
            this.$emit('click', id);
        },
    },
});
