








export default {
    name: 'CoLoadingIndicator',
    props: {},
};
