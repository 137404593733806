



































































import Vue from 'vue';
import VuePortal from '@linusborg/vue-simple-portal';
import LazyLoadDirective from './LazyLoadDirective';
import CoRoundButton from '../co-round-button/CoRoundButton.vue';

Vue.use(VuePortal);

export default {
    name: 'CoImage',
    components: {
        CoRoundButton,
    },
    directives: {
        lazyload: LazyLoadDirective,
    },
    props: {
        src: {
            type: String,
            default: '',
        },
        alt: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: null,
            validator: (value: string) => ['image', 'user', 'page', 'event'].includes(value),
        },
        placeholderSize: {
            type: String,
            default: '',
        },
        placeholderFormat: {
            type: String,
            default: 'default',
            validator: (value: string) => ['default', 'portrait', 'landscape', 'square'].includes(value),
        },
        objectFit: {
            type: String,
            default: 'cover',
            validator: (value: string) => ['cover', 'contain'].includes(value),
        },
        imageWidthAuto: {
            type: Boolean,
            default: false,
            requred: false,
        },
        lightbox: {
            type: Boolean,
            default: false,
        },
        rounded: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            lightboxShow: false,
            lightboxLoaded: false,
        };
    },
    watch: {
        src() {
            this.$nextTick(() => {
                if (this.$refs.imageWrapper && this.$refs.imageWrapper.reloadImage) {
                    this.$refs.imageWrapper.reloadImage();
                }
            });
        },
    },
    computed: {
        classes() {
            return {
                contain: this.objectFit === 'contain',
                cover: this.objectFit === 'cover',
            };
        },
        placeholderClasses() {
            return {
                user: this.placeholder === 'user',
                image: this.placeholder !== 'user',
            };
        },
    },
    methods: {
        imgClick(event) {
            if (!this.lightbox) return;
            event.stopPropagation();
            this.showLightbox();
        },
        lightboxLoad(image) {
            this.lightboxLoaded = true;
        },
        showLightbox() {
            this.lightboxShow = true;
            document.body.classList.toggle('overflow-hidden', true);
        },
        hideLightbox() {
            this.lightboxLoaded = false;
            this.lightboxShow = false;
            document.body.classList.toggle('overflow-hidden', false);
        },
        imageLoaded() {
            this.$emit('loaded');
        },
    },
};
