
















































































































































import axios from 'axios';
import { get } from 'lodash';
import { User, Space, Image, Reaction } from '@/types/GlobalTypes.ts';
import EventBus from '@/eventBus.js';
import CoThumbnail from '../../Molecules/co-thumbnail/CoThumbnail.vue';
import CoText from '../../Atoms/co-text/CoText.vue';
import CoLink from '../../Atoms/co-link/CoLink.vue';
import CoDate from '../../Molecules/co-date/CoDate.vue';
import CoDropdown from '../../Molecules/co-dropdown/CoDropdown.vue';
import CoDropdownItem from '../../Molecules/co-dropdown-item/CoDropdownItem.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoReactions from '../../Molecules/co-reactions/coReactions.vue';
import CoEditor from '../../Molecules/co-editor/CoEditor.vue';
import CoConfirmation from '../../Molecules/co-confirmation/CoConfirmation.vue';
import CoInput from '../../Molecules/co-input/CoInput.vue';
import CoSlider from '../../Molecules/co-slider/CoSlider.vue';
import CoImage from '../../Atoms/co-image/CoImage.vue';

interface Comment {
    ID: String;
    PostID: String;
    PostSlug: String;
    PostType: String;
    Author: User;
    Text: String;
    CreatedAt: Number;
    UpdatedAt: Number;
    Space: Space;
    Images: Image[];
}

export default {
    components: {
        CoThumbnail,
        CoLink,
        CoText,
        CoDate,
        CoDropdown,
        CoDropdownItem,
        CoIcon,
        CoReactions,
        CoEditor,
        CoInput,
        CoConfirmation,
        CoSlider,
        CoImage,
    },
    name: 'CoComment',
    data() {
        return {
            editorShown: false,
            replyValue: null,
            deleted: false,
            sending: false,
            isAdmin:
                get(this, '$store.state.me.Permissions') &&
                get(this, '$store.state.me.Permissions').includes('space_admin')
                    ? true
                    : false,
            isMySpace: get(this.comment, 'SpaceID') === get(this, '$store.state.space.ID') ? true : false,
            rerender: 0,
        };
    },
    props: {
        comment: {
            type: Object as () => Comment,
            required: true,
            default: () => ({}),
        },
    },
    computed: {
        commentLink() {
            return `${window.location.origin}/${this.comment.PostType}/${this.comment.PostSlug}?commentid=${this.comment.ID}`;
        },
        reply() {
            return {
                Version: '1',
                PostID: this.comment.PostID,
                PostType: this.comment.PostType,
            };
        },
        canEdit() {
            if (!get(this, '$store.state.me.ID') && !get(this, 'comment.Author.ID')) return false;
            return get(this, '$store.state.me.ID') === get(this, 'comment.Author.ID');
        },
    },
    watch: {
        editorShown(newVal, oldVal) {
            if (!newVal) return;
            this.$nextTick(() => {
                this.$refs.replyEditor.focus();
            });
        },
    },
    methods: {
        replyToggle() {
            this.editorShown = !this.editorShown;
            if (!this.editorShown) return;
            this.$nextTick(() => {
                this.$refs.replyEditor.insertMention(
                    get(this.comment, 'Author.Name'),
                    get(this.comment, 'Author.Slug')
                );
            });
        },
        uploadImages(files) {
            return new Promise((resolve, reject) => {
                if (!files || files.length === 0) resolve([]);
                var counter = 0;
                var images = [];

                files.forEach((file) => {
                    const data = new FormData();
                    data.append('file', file.file);
                    axios({
                        method: 'POST',
                        url: '/upload/image/comment',
                        data,
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'image/*',
                        },
                    })
                        .then((response) => {
                            counter++;
                            images.push(get(response, 'data.url'));
                            if (counter === files.length) {
                                resolve(images);
                            }
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            });
        },
        async createComment(commentData) {
            if (!commentData || typeof commentData !== 'object') return;
            this.sending = true;
            const images = await this.uploadImages(commentData.Files);
            let newComment = {
                ...this.reply,
                Text: get(commentData, 'Html', ''),
                Images: images,
            };

            let data = JSON.stringify(newComment);

            axios({
                method: 'POST',
                url: '/comment',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    //update the comments
                    this.$emit('replied', response.data);
                    //clear the editor
                    this.$refs.replyEditor.clear(true);
                    this.replyToggle();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.sending = false;
                });
        },
        commentDelete(asAdmin = false) {
            axios({
                method: 'DELETE',
                url: asAdmin ? '/admin/comment' : '/comment',
                data: this.comment,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.deleted = true;
                    this.rerender++;
                    this.$emit('commentDeleted', this.comment);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        copyLink() {
            EventBus.$emit('INFO', {
                Message: this.$t('messages.copiedtoclipboard'),
            });
            navigator.clipboard.writeText(this.commentLink);
        },
    },
};
