<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.round-unify {
    border-radius: 1rem !important;
    overflow: hidden;
}

.cancel-plan {
    background: #fd6969;
}

@media (min-width: 768px) {
    .col-image {
        height: 70vh;
    }

    .column {
        min-height: 70vh;
    }
}
</style>

<template>
    <div>
        <div class="d-flex align-items-center pt-2 ml-3 border-bottom pb-2">
            <span>Details</span>
        </div>
        <div class="row mb-4">
            <div class="col-12 col-md-10 col-lg-6">
                <div class="bg-white round-unify-xs p-3" v-if="timepass">
                    <h2 class="mb-0 pt-2">{{ timepass.Name }}</h2>
                    <p v-if="taxRate" class="text-muted mt-2 mb-4">
                        {{ calculatePrice(timepass) }}
                        {{ timepass.Currency ? timepass.Currency : $store.state.space.Currency }} / Month<br />

                        <small>price includes {{ taxRate.Percentage }}% {{ taxRate.DisplayName }} VAT</small>
                    </p>
                    <div>
                        <p class="text-muted mb-0">Number of times to use</p>
                        <p>{{ timepass.Number }} days</p>
                    </div>
                    <div>
                        <p class="text-muted mb-0">Max. hours per time</p>
                        <p>{{ timepass.Duration }} hours</p>
                    </div>

                    <div>
                        <p class="text-muted mb-0">Description</p>
                        <p v-html="timepass.Description"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BuyPassDetails',
    props: ['timepass', 'taxRate', 'adminSpace'],
    data() {
        return {
            me: this.$store.state.me,
        };
    },
    methods: {
        next() {
            this.$emit('nextStep');
        },
        calculatePrice(item) {
            if (this.taxRate.Inclusive) {
                const price = item.PriceInCents / 100.0;
                return price;
            }
            const price = item.PriceInCents / 100.0;
            const totalPrice = price * (this.taxRate.Percentage / 100 + 1);
            return totalPrice.toFixed(2);
        },
    },
};
</script>
