<template>
    <div>
        <!-- plan upgrade start -->
        <div class="p-0 pl-sm-4 m-0">
            <div v-if="taxRates.length === 0 && loadingTaxRate === false">
                <b-alert show variant="danger text-dark"> Please create at least a one tax rate</b-alert>
            </div>
            <b-overlay
                v-if="taxRates.length !== 0 && loadingTaxRate === false"
                :show="loading || loadingTaxRate"
                class="round-unify my-4"
            >
                <div class="bg-white round-unify p-4">
                    <b-form ref="plan-upgrade-form" class="d-flex flex-column">
                        <b-form-group
                            id="input-group-name"
                            label="Name"
                            label-for="input-name"
                            aria-describedby="name-description"
                            label-class="label-align"
                        >
                            <b-form-input
                                id="input-name"
                                v-model="form['Name']"
                                type="text"
                                required
                                placeholder="Enter resource name"
                                class="rounded-pill"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-description"
                            label="Description (Optional)"
                            label-for="input-description"
                            label-class="label-align"
                        >
                            <b-form-textarea
                                id="input-description"
                                placeholder="Text (Optional)"
                                rows="3"
                                style="border-radius: 1rem"
                                v-model="form.Description"
                            ></b-form-textarea>
                        </b-form-group>

                        <b-form-group
                            id="input-group-price"
                            label="Price"
                            label-for="input-price"
                            aria-describedby="price-description"
                            label-class="label-align"
                        >
                            <b-form-input
                                id="input-price"
                                v-model="Price"
                                type="number"
                                number
                                required
                                class="rounded-pill"
                                step="0.01"
                                @change="priceChange"
                            ></b-form-input>
                            <b-form-text id="price-description" class="ml-3">Net price</b-form-text>
                        </b-form-group>

                        <b-form-group
                            id="input-group-tax-rate"
                            label="Tax rate"
                            label-for="input-tax-rate"
                            label-class="label-align"
                        >
                            <b-form-select
                                id="select-tax-rate"
                                v-model="form.TaxRateID"
                                class="form-control rounded-pill d-block"
                                required
                                @change="calculateFinalPrice($event)"
                            >
                                <template #first>
                                    <b-form-select-option :value="null" disabled selected
                                        >-- Please select default tax rate --
                                    </b-form-select-option>
                                </template>
                                <b-form-select-option v-for="(item, _) in taxRates" :value="item.ID"
                                    >{{ item.Percentage ? item.Percentage : 0 }}% -

                                    <span v-if="item.Inclusive">Inclusive</span>
                                    <span v-else>Exclusive</span>
                                    - {{ item.DisplayName }}
                                </b-form-select-option>
                            </b-form-select>
                            <b-form-text>
                                Final price for customer will be
                                <strong>{{ FinalPrice }} {{ form.Currency }}</strong>
                            </b-form-text>
                        </b-form-group>

                        <b-form-group
                            id="input-group-currency"
                            label="Currency"
                            label-for="input-currency"
                            label-class="label-align"
                        >
                            <b-form-select
                                id="select-currency"
                                v-model="form.Currency"
                                required
                                class="form-control rounded-pill d-block"
                                :disabled="true"
                            >
                                <b-form-select-option :value="form.Currency">{{ form.Currency }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>

                        <div class="d-flex justify-content-between">
                            <b-button
                                variant="outline-danger"
                                type="button"
                                @click="
                                    hasHistory() ? $router.go(-1) : $router.push('/admin/monetization/plan-upgrade')
                                "
                                >Cancel
                            </b-button>
                            <b-button variant="primary" type="button" @click="update">Save</b-button>
                        </div>
                    </b-form>
                </div>
            </b-overlay>
        </div>
        <!-- plan upgrades end -->
    </div>
</template>

<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';

export default {
    name: 'EditPlanUpgrade',
    components: {},
    data() {
        return {
            id: this.$route.params.ID,
            Price: 0.0,
            TaxRate: 19.0,
            FinalPrice: 0.0,

            form: {
                Currency: 'EUR',
                TaxRateID: null,
            },
            loading: false,
            loadingTaxRate: false,

            taxRates: [],
        };
    },
    mounted() {
        this.getPlanUpgrade(this.id);
    },

    methods: {
        hasHistory() {
            return window.history.length > 2;
        },
        update() {
            if (this.$refs['plan-upgrade-form'].checkValidity()) {
                this.putPlanUpgrade();
            } else {
                this.$refs['plan-upgrade-form'].reportValidity();
            }
        },
        getPlanUpgrade(id) {
            this.loading = true;
            this.loadingTaxRate = true;
            axios({
                method: 'GET',
                url: `/admin/extra/${id}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.form = response.data;
                        if (response.data.PriceInCents) {
                            this.Price = response.data.PriceInCents / 100.0;
                        } else {
                            this.Price = 0.0;
                        }
                        if (this.$store.state.space.Currency) {
                            this.form.Currency = this.$store.state.space.Currency;
                        }
                    }
                    this.getTaxRates();
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    EventBus.$emit('ERROR', {
                        Message: 'Failed to get plan upgrade',
                        Details: error,
                    });
                    this.loading = false;
                });
        },

        putPlanUpgrade() {
            this.loading = true;
            const object = this.form;
            object.PriceInCents = Math.round(this.Price * 100);

            axios({
                method: 'PUT',
                url: '/admin/extra/update',
                data: object,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    EventBus.$emit('INFO', {
                        Message: 'Plan upgrade updated',
                        Details: '',
                    });
                    this.loading = false;
                    this.$router.push(`/admin/monetization/plan-upgrade/${response.data.ID}`);
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: 'Failed to create plan upgrade',
                        Details: error,
                    });
                    this.loading = false;
                });

            this.loading = false;
        },

        getTaxRates() {
            this.loadingTaxRate = true;
            axios({
                method: 'GET',
                url: `/admin/tax-rate/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.taxRates = response.data;
                    }
                    this.priceChange();
                    this.loadingTaxRate = false;
                })
                .catch((error) => {
                    this.loadingTaxRate = false;
                    console.log(error);
                });
        },
        priceChange() {
            if (this.form.TaxRateID) {
                this.calculateFinalPrice(this.form.TaxRateID);
            }
        },
        calculateFinalPrice(taxID) {
            const tax = this.taxRates.find((item) => item.ID === taxID);
            if (tax.Inclusive) {
                this.FinalPrice = this.Price;
            } else {
                this.FinalPrice = (this.Price * (tax.Percentage / 100 + 1)).toFixed(2);
            }
        },
    },
};
</script>
