<template>
    <b-overlay :show="isLoading">
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
            </div>
        </template>
        <div class="chat-wrapper d-flex flex-column">
            <div class="d-flex p-2 align-items-center justify-content-between border-bottom">
                <div class="">
                    <b-button @click="back" class="ml-0 mr-2 burger-menu-btn btn-round">
                        <b-icon icon="arrow-left"></b-icon>
                    </b-button>
                </div>
                {{ $t('labels.edit') }}
                <div>
                    <b-button @click="publishUpdate" class="ml-0 mr-2 burger-menu-btn btn-round">
                        <b-icon icon="check" scale="1"></b-icon>
                    </b-button>
                </div>
            </div>
            <div class="d-flex w-100 justify-content-center my-4">
                <div class="user">
                    <img v-if="thread['ImageURL'] != null" :src="thread['ImageURL']" alt="" :key="thread.ImageURLKey" />
                    <div v-else></div>
                    <div class="file text-center float-r text-light">
                        <b-icon icon="camera-fill" scale="2.5"></b-icon>
                        <input type="file" name="file" @change="previewGroupImage" />
                    </div>
                </div>
            </div>
            <div class="mb-2 px-3">
                <b-input type="text" class="rounded-pill" v-model="thread['Title']" placeholder="Group name" required>
                </b-input>
            </div>
            <div class="d-flex justify-content-between align-items-baseline mt-2 px-3">
                <h3>{{ $tc('labels.member', 2) }}</h3>
                <b-button class="" variant="outline-primary" v-b-modal.add-member-modal>{{
                    $t('labels.add')
                }}</b-button>
            </div>
            <div class="mt-3 overflow-auto user-select px-3">
                <div class="d-flex flex-column">
                    <div v-if="thread.Peers != null && thread.Peers.length == 0" class="text-center">
                        No members in this chat
                    </div>
                    <div
                        v-for="(user, i) in thread.Peers"
                        :key="user.ID"
                        class="d-flex align-items-center justify-content-between py-2 userdiv round-unify-xs"
                    >
                        <ProfileCircle :ImageURL="user.Profile.ImageURL" :Name="user.Profile.Name"></ProfileCircle>
                        <span v-if="thread.InitiatorID === user.ID">{{ $t('labels.owner') }}</span>
                        <span v-on:click="removeMember(i)" class="pointer" v-if="user.ID != $store.state.me.ID"
                            ><b-icon font-scale="1.2" icon="person-x" variant="danger"></b-icon
                        ></span>
                    </div>
                </div>
            </div>
            <b-modal id="add-member-modal" ref="add-member-modal" size="md" title="Add member" hide-footer centered>
                <div class="d-flex flex-grow-1">
                    <div class="align-self-start w-100">
                        <div class="mt-0">
                            <b-input
                                type="text"
                                class="rounded-pill"
                                v-model="usersearch"
                                placeholder="Search people"
                                @input="usersSearchResult"
                                required
                            >
                            </b-input>
                        </div>
                        <div class="mt-3 user-select overflow-auto">
                            <div v-if="searchingUsers">
                                <b-spinner variant="primary" label="Searching"></b-spinner>
                            </div>
                            <div
                                v-for="user in users"
                                @click="addMember(user)"
                                :key="user.ID"
                                class="d-block py-2 userdiv round-unify-xs"
                            >
                                <ProfileCircle
                                    :ImageURL="user.Profile.ImageURL"
                                    :Name="user.Profile.Name"
                                ></ProfileCircle>
                            </div>
                        </div>
                    </div>
                </div>
            </b-modal>
        </div>
    </b-overlay>
</template>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.chat-wrapper {
    height: calc(100vh - 159px);
    overflow: auto;
    @media (max-width: 768px) {
        max-height: calc(100vh - 144px);
        height: calc(100vh - 144px);
    }
}

.user {
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
}

.user img,
.user .user-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    image-orientation: from-image;
    outline: none;
    text-decoration: none;
    border: 0;
}

.user .file {
    border: none;
    font-size: 15px;
    line-height: 100px;
    background: rgba(0, 0, 0, 0.2);
    width: 100px;
    position: absolute;
    padding: 0 !important;
    text-align: center;
    left: 0;
    top: 0;
    border-radius: 50%;
    cursor: pointer;
    display: block;
}

.user:hover > .file {
    background: rgba(0, 0, 0, 0.5);
}

.user .file input {
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
    cursor: pointer;
}

.user-select {
    height: calc(100vh / 3) !important;
}
</style>

<script>
import Vue from 'vue';
import moment from 'moment';
import axios from 'axios';
import EventBus from '../../eventBus';

export default {
    name: 'EditChat',

    data() {
        return {
            threadID: this.$route.params.slug,
            thread: JSON.parse(JSON.stringify(this.$store.state.threads[this.$route.params.slug])),
            isLoading: false,

            usersearch: '',
            users: [],
            searchingUsers: false,
            usersRerender: 0,
            community: [],
        };
    },
    watch: {
        $route(to, from) {
            this.threadID = to.params.slug;
            this.thread = JSON.parse(JSON.stringify(this.$store.state.threads[this.$route.params.slug]));
        },
    },

    mounted() {
        if (this.$store.state.me.ID != this.thread.InitiatorID) {
            this.$router.push(`/messenger/${this.threadID}`);
        }
        this.getCommunity();
    },
    methods: {
        usersSearchResult() {
            this.searchingUsers = true;
            this.users = [];
            axios({
                method: 'GET',
                url: `/search/v2/user/${this.usersearch}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.users = [];
                        response.data.forEach(function (entry) {
                            if (this.$store.state.me.ID != entry.ID) {
                                this.users.push(entry);
                            }
                        }, this);
                        this.searchingUsers = false;
                    }
                })
                .catch((error) => {
                    this.searchingUsers = false;
                });
        },
        previewGroupImage(event) {
            const file = event.target.files[0];
            this.thread.ImageURL = URL.createObjectURL(file);
            this.thread.ImageFile = file;
            this.thread.ImageURLKey++;
        },
        back() {
            this.$router.push(`/messenger/${this.threadID}`);
        },

        removeMember(index) {
            this.thread.Peers.splice(index, 1);
        },

        addMember(user) {
            const Gotuser = this.thread.Peers.find((item) => item.ID === user.ID);
            if (Gotuser == null) {
                this.thread.Peers.push(user);
            }
            this.$refs['add-member-modal'].hide();
        },

        uploadImage() {
            this.$store
                .dispatch('imageUpload', {
                    file: this.thread.ImageFile,
                    url: '/upload/image/group-chat-avater',
                })
                .then((response) => {
                    this.thread.ImageURL = response;
                    this.updateThread();
                })
                .catch((error) => {
                    this.isLoading = false;
                    const errorMsg = {
                        Message: 'Could not upload image, please try again.',
                        Details: error.response.data,
                    };
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.message === 'Request Entity Too Large'
                    ) {
                        errorMsg.Message = 'The file you tried to upload is too large. Max 10 MB.';
                    }
                    EventBus.$emit('ERROR', errorMsg);
                });
        },

        publishUpdate(event) {
            this.isLoading = true;

            if (this.thread.ImageFile != null) {
                this.uploadImage();
            } else {
                this.updateThread();
            }
        },

        updateThread() {
            const data = new Object(this.thread);
            delete data.ImageFile;
            const participants = [];
            this.thread.Peers.forEach((element) => {
                participants.push(element.ID);
            });
            data.Participants = participants;
            delete data.Peer;
            delete data.Peers;

            axios({
                method: 'PUT',
                url: '/chat/t/group-chat',
                withCredentials: true,
                data: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        // this.thread = response.data
                        // this.$store.commit("SET_THREAD", response.data, this.threadID);
                        EventBus.$emit('UPDATE_THREAD');
                        this.$router.push(`/messenger/${this.threadID}`);
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                });
        },
        getCommunity() {
            this.$store
                .dispatch('getCommunity', this.feedNextPage)
                .then((response) => {
                    if (response != null && response.objects != null) {
                        response.objects.forEach((element) => {
                            if (this.$store.state.me.ID != element.object.ID) {
                                this.community.push(element.object);
                            }
                        });
                        this.users = this.community;
                    }
                })
                .catch((error) => {
                    // todo show error
                });
        },
    },
};
</script>
