




// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';

// Import bar charts, all suffixed with Chart
import { PieChart, BarChart, LineChart } from 'echarts/charts';

// Import the tooltip, title, rectangular coordinate system, dataset and transform components
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
} from 'echarts/components';

// Features like Universal Transition and Label Layout
import { LabelLayout, UniversalTransition } from 'echarts/features';

// Import the Canvas renderer
// Note that including the CanvasRenderer or SVGRenderer is a required step
import { CanvasRenderer } from 'echarts/renderers';

// Register the required components
echarts.use([
    PieChart,
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer,
]);

export default {
    name: 'CoChartPie',
    data: {
        chart: null,
    },
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    watch: {
        data(newVal, oldVal) {
            this.chart.setOption({
                data: newVal,
            });
        },
    },
    mounted() {
        // The chart is initialized and configured in the same manner as before
        this.chart = echarts.init(this.$refs.chartContainer);
        this.chart.setOption({
            series: [
                {
                    type: 'pie',
                    data: this.data,
                },
            ],
        });
    },
};
