











export default {
    name: 'CoLabel',
    props: {
        text: {
            type: String,
            default: '',
        },
    },
};
