<style lang="less" scoped>
@import './CoCreateResourceConfirmationPage.less';

@import '~@/assets/less/variables.less';
.round-unify {
    border-radius: 1rem !important;
    overflow: hidden;
}

@media (min-width: 768px) {
    .col-image {
        height: 70vh;
    }
    .column {
        min-height: 70vh;
    }
}
</style>

<template>
    <div class="bg-white p-0 p-lg-3 w-100 round-unify pb-5 column" style="overflow-y: visible !important">
        <div class="d-flex w-100 align-items-center mb-3">
            <div v-for="(tab, index) in tabs" :key="tab">
                <div
                    class="pr-2 mr-1 tab text-muted pointer mt-3"
                    :id="'confirmation-tab' + index"
                    @click="changeActiveTab(index)"
                >
                    {{ tab }}
                </div>
            </div>
        </div>
        <div class="w-100" v-if="index == 0">
            <div>
                <CoInput label="Title (optional)" placeholder="Enter title" v-model="confirmationMessage.Title">
                </CoInput>
            </div>
            <div class="mt-2">
                <div>
                    <CoLabel class="" text="Description (optional)"></CoLabel>
                </div>
                <CoTextArea placeholder="Description" v-model="confirmationMessage.Description"></CoTextArea>
            </div>
            <div>
                <CoInput
                    label="Button title (optional)"
                    placeholder="Enter button title"
                    v-model="confirmationMessage.ButtonTitle"
                >
                </CoInput>
            </div>
            <div>
                <CoInput
                    label="Button URL (optional)"
                    placeholder="Enter button URL"
                    v-model="confirmationMessage.ButtonURL"
                >
                </CoInput>
            </div>
        </div>
        <div class="w-100" v-if="index == 1">
            <div>
                <CoLabel text="Send confirmation email to booker"></CoLabel>
            </div>
            <div>
                <CoCheckbox v-model="sendConfirmationEmail" name="Send Email"></CoCheckbox>
            </div>
            <div>
                <CoLabel text="Email content"></CoLabel>
            </div>
            <div>
                <CoTextArea :value="emailContent" @input="addEmailContent"></CoTextArea>
            </div>
            <div><CoLabel text="Notify others about booking"></CoLabel></div>
            <div v-for="(email, index) in emails" :key="index" class="mb-2">
                <div class="d-flex align-items-center">
                    <div>
                        <CoInput placeholder="Email address" @input="inputEmail($event, index)"></CoInput>
                    </div>
                    <div></div>
                </div>
            </div>
            <div class="mt-3">
                <CoButton
                    class="text-muted"
                    iconleft="plus-circle"
                    label="Add email"
                    type="outline"
                    @onClick="addEmail(index)"
                ></CoButton>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from '../../../eventBus';
import axios from 'axios';

export default {
    name: 'CoCreateResourceConfirmationPage',
    data() {
        return {
            me: this.$store.state.me,

            windowWidth: window.innerWidth,
            minWidth: true,
            tabs: ['Confirmation message', 'Confirmation email'],
            confirmationMessage: {},
            sendConfirmationEmail: false,

            loading: false,
            emailContent: '',
            index: 0,
            emails: [''],

            id: this.$route.params.ID, // current or upcoming
        };
    },
    created() {},

    mounted() {
        var element = document.getElementById('confirmation-tab0');
        element.classList.remove('text-muted');

        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;

            if (this.windowWidth >= 768) {
                this.minWidth = true;
            } else {
                this.minWidth = false;
            }
        });
    },
    watch: {},
    methods: {
        addEmail(index) {
            this.emails.push({ Index: index, Email: '' });
        },
        changeActiveTab(index) {
            var oldActive = document.getElementById('confirmation-tab' + this.index);
            oldActive.classList.add('text-muted');
            var newActive = document.getElementById('confirmation-tab' + index);
            newActive.classList.remove('text-muted');
            this.index = index;
        },
        inputEmail(e, index) {
            this.emails[index] = e;
            console.log(this.emails);
        },
        inputDescription(e) {
            this.confirmationMessage.Description = e;
        },
        addEmailContent(e) {
            this.emailContent = e;
        },
    },
};
</script>
