<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.round-unify {
    border-radius: 1rem !important;
    overflow: hidden;
}

.cancel-plan {
    background: #fd6969;
}

@media (min-width: 768px) {
    .col-image {
        height: 70vh;
    }

    .column {
        min-height: 70vh;
    }
}
</style>

<template>
    <div>
        <div class="row mb-4">
            <div class="col-12 col-md-10 col-lg-6 mt-4 column">
                <div class="bg-white round-unify-xs p-3" v-if="plan">
                    <h2 class="mb-0">{{ plan.Name }}</h2>
                    <p class="text-muted mt-2 mb-4">
                        {{ calculatePrice(plan.PriceInCents, plan.TaxRate) }}
                        {{ plan.Currency ? plan.Currency : $store.state.space.Currency }} / Month<br />
                        <small>price includes {{ plan.TaxRate.Percentage }}% {{ plan.TaxRate.DisplayName }}</small>
                    </p>
                    <div class="overflow-auto">
                        <!-- display descriptions for this plan -->
                        <div v-if="plan.Description">
                            <p v-html="plan.Description"></p>
                        </div>
                        <!-- display Cancelation notice for this plan -->
                        <div>
                            <p class="text-muted mb-0">Cancelation notice</p>
                            <p>{{ plan.CancelationNotice }} days</p>
                        </div>
                        <!-- display booking conditions for this plan -->
                        <div v-if="plan.AvailableResources || plan.AvailableResourceGroups">
                            <p class="text-muted mb-3">Bookings</p>
                            <div
                                v-for="(Resource, Index) in plan.AvailableResources"
                                :key="Index"
                                class="align-items-center round-unify-xs bg-secondary mb-3 p-3"
                            >
                                <div v-if="Resource.CreditCents > 0" class="float-right text-center">
                                    <span class="badge original badge-pill badge-success"
                                        >{{ Resource.CreditCents ? getFormattedPrice(Resource.CreditCents) : 0 }}
                                        {{ plan.Currency ? plan.Currency : $store.state.space.Currency }}</span
                                    ><br />
                                    <small>incl.</small>
                                </div>
                                <div v-else-if="Resource.CreditHours > 0" class="float-right text-center">
                                    <span class="badge original badge-pill badge-success"
                                        >{{ Resource.CreditHours }} h</span
                                    ><br />
                                    <small>incl.</small>
                                </div>
                                <h3 class="m-0">{{ Resource.Name }}</h3>
                                <h4>
                                    <small v-if="Resource.PriceInCents > 0">
                                        {{ calculatePrice(Resource.PriceInCents, plan.TaxRate) }}
                                        {{ plan.Currency ? plan.Currency : $store.state.space.Currency }} / hour</small
                                    >
                                    <small v-else>free</small>
                                </h4>
                            </div>
                            <div
                                v-for="(ResourceGroup, Index) in plan.AvailableResourceGroups"
                                :key="Index"
                                class="align-items-center round-unify-xs bg-secondary mb-3 p-3"
                            >
                                <div v-if="ResourceGroup.CreditCents > 0" class="float-right text-center">
                                    <span class="badge original badge-pill badge-success"
                                        >{{
                                            ResourceGroup.CreditCents ? getFormattedPrice(ResourceGroup.CreditCents) : 0
                                        }}
                                        {{ plan.Currency ? plan.Currency : $store.state.space.Currency }}</span
                                    ><br />
                                    <small>incl.</small>
                                </div>
                                <div v-else-if="ResourceGroup.CreditHours > 0" class="float-right text-center">
                                    <span class="badge original badge-pill badge-success"
                                        >{{ ResourceGroup.CreditHours }} h</span
                                    ><br />
                                    <small>incl.</small>
                                </div>
                                <h3 class="m-0">{{ ResourceGroup.Name }}</h3>
                                <h4>
                                    <small v-if="ResourceGroup.PriceInCents == null">prices vary</small>
                                    <small v-else-if="ResourceGroup.PriceInCents > 0"
                                        >{{ calculatePrice(ResourceGroup.PriceInCents, plan.TaxRate) }}
                                        {{ plan.Currency ? plan.Currency : $store.state.space.Currency }} / hour</small
                                    >
                                    <small v-else-if="ResourceGroup.PriceInCents === 0">free</small>
                                </h4>
                                <small>
                                    <span
                                        v-for="(Resource, index) in ResourceGroup.ListResources"
                                        :key="index"
                                        class="badge original badge-pill badge-light mr-1 text-truncate"
                                    >
                                        {{ Resource.Name }}
                                    </span>
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="w-100 d-flex justify-content-between mt-4">
                        <b-button
                            v-if="member"
                            :to="`/admin/community/member/plan/change/${member.ID}`"
                            variant="outline-primary"
                            >Back
                        </b-button>
                        <b-button v-else to="/account/plan/change" variant="outline-primary">Back</b-button>
                        <b-button @click="next()" variant="primary">Choose plan</b-button>
                    </div>
                </div>
            </div>
            <div v-if="plan.ImageURL" class="col-12 col-md-10 col-lg-6 mt-4 column">
                <div class="round-unify-xs">
                    <LazyLoadImg :src="plan.ImageURL"></LazyLoadImg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import EventBus from '@/eventBus';
// import axios from 'axios';
import { defaultTo } from 'lodash';
import LazyLoadImg from '@/components/old-components/LazyLoadImg.vue';

export default {
    name: 'ChangePlanDetails',
    components: { LazyLoadImg },
    props: {
        plan: {
            type: Object,
            default: null,
        },
        member: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            me: this.$store.state.me,
            imageURL: '',
        };
    },
    methods: {
        getFormattedPrice(priceInCents) {
            return (defaultTo(priceInCents, 0) / 100).toFixed(2);
        },
        calculatePrice(priceInCents, taxRate) {
            if (taxRate && taxRate.Inclusive) {
                const price = priceInCents / 100.0;
                return price.toFixed(2);
            }
            const price = priceInCents / 100.0;
            return (price * (taxRate.Percentage / 100 + 1)).toFixed(2);
        },
        filterPictures(imageUrl) {
            if (imageUrl) {
                return imageUrl;
            }

            const randomItem = parseInt(Math.random() * 16, 10) + 1;
            const images = require.context('@/assets/gradients/', true);
            return images(`./Platform_Gradients-${randomItem}.jpg`);
        },
        next() {
            this.$emit('nextStep');
        },
    },
};
</script>
