



















import { get, reject } from 'lodash';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoLink from '@/components/Atoms/co-link/CoLink.vue';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoThumbnail from '../co-thumbnail/CoThumbnail.vue';

export default {
    name: 'CoAcceptReject',
    components: {
        CoRoundButton,
        CoThumbnail,
        CoText,
        CoLink,
    },
    props: {
        user: {
            type: Object,
            required: true,
            default: null,
        },
    },
    methods: {
        get,
        accept() {
            this.$emit('accept', this.user);
        },
        reject() {
            this.$emit('reject', this.user);
        },
    },
};
