<template>
    <div v-if="suggestedContent.length >= 5">
        <div>
            <div class="d-flex align-items-center justify-content-between labels mb-3">
                <co-text type="p2" class="m-0 p-0 ml-3 mr-1">{{ $t('labels.suggestedcontent') }}</co-text>
                <co-text type="p2" class="m-0 p-0 ml-1 mr-3 pointer" variant="primary" @click.native="refresh">{{
                    $t('labels.refresh')
                }}</co-text>
            </div>
            <div class="carousel p-md-0">
                <div @scroll="userScrolled = true" class="d-flex suggested-wrapper hide-scrollbar">
                    <template v-for="(item, index) in suggestedContent">
                        <router-link
                            v-if="item.type === 'project'"
                            :to="`/project/${item.object.Slug}?frm=suggested`"
                            :key="index"
                            class="round-unify-xs bg-white suggested-card"
                        >
                            <div>
                                <LazyLoadImg
                                    :src="item.object.ImageURL[0]"
                                    :relativeHeight="100"
                                    dark-variant
                                    alt=""
                                    style=""
                                >
                                </LazyLoadImg>
                            </div>
                            <div class="p-2 py-3">
                                <span class="card-title-project">{{ item.object.Title }}</span>
                            </div>
                        </router-link>

                        <router-link
                            v-if="item.type === 'event'"
                            :to="`/event/${item.object.Slug}?frm=suggested`"
                            :key="index"
                            class="round-unify-xs bg-white suggested-card"
                        >
                            <div>
                                <LazyLoadImg
                                    :src="item.object.ImageURL"
                                    :relativeHeight="100"
                                    dark-variant
                                    alt=""
                                    style=""
                                >
                                </LazyLoadImg>
                            </div>
                            <div class="p-3">
                                <span class="card-title-event">{{ item.object.Title }}</span>
                                <p v-bind:class="timeCssClasses(item.object.StartDate + '000')" class="mb-0 small">
                                    {{ (item.object.StartDate + '000') | readabledate }} |
                                    {{ (item.object.StartDate + '000') | readabletime }}
                                </p>
                            </div>
                        </router-link>
                    </template>
                </div>
                <div class="prev-arrow">
                    <b-button
                        v-if="userScrolled || nextClicked"
                        variant="light"
                        class="bg-white btn-round"
                        @click="goPrev"
                    >
                        <b-icon
                            icon="arrow-left"
                            class="m-0"
                            style="position: relative; bottom: 1px"
                            scale="1"
                        ></b-icon>
                    </b-button>
                </div>
                <div class="next-arrow">
                    <b-button variant="light" class="bg-white btn-round" @click="goNext">
                        <b-icon
                            icon="arrow-right"
                            class="m-0"
                            style="position: relative; bottom: 1px"
                            scale="1"
                        ></b-icon>
                    </b-button>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="less" scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.labels {
    @media screen and (max-width: 768px) {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
}

.card-title-project {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.card-title-event {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.carousel {
    position: relative;
}
.suggested-wrapper {
    overflow-x: scroll;
    position: relative;
    scroll-behavior: smooth;
}
.prev-arrow,
.next-arrow {
    position: absolute;
    top: 50%;

    // position the button vertically in the center
    transform: translateY(-50%);
    left: 15px;
}
.next-arrow {
    left: auto;
    right: 15px;
}
.suggested-card {
    margin: 0 0.5rem;
    min-width: 200px;
    display: block;
    color: black;
    text-decoration: none !important;
    &:first-of-type {
        @media screen and (min-width: 768px) {
            margin-left: 0;
        }
    }
}
</style>
<script>
import axios from 'axios';
import moment from 'moment';
import CoText from '../Atoms/co-text/CoText.vue';

export default {
    components: { CoText },
    name: 'Suggested',
    data() {
        return {
            suggestedLoading: false,
            suggestedTag: 'coworking',
            suggestedTagSlug: 'coworking',
            suggestedContent: [],
            userScrolled: false,
            nextClicked: false,
        };
    },
    mounted() {
        this.makeSuggested();
    },
    methods: {
        timeCssClasses(value) {
            const time = parseInt(value);
            const result = [];

            if (moment(time).isSame(moment(), 'day')) {
                result.push('today');
            } else if (moment(time).isSame(moment().add(1, 'days'), 'day')) {
                result.push('tomorrow');
            }
            return result;
        },

        goPrev() {
            const div = document.querySelector('.suggested-wrapper');

            div.scrollBy({
                top: 0,
                left: -600,
                behavior: 'smooth',
            });
        },
        goNext() {
            const div = document.querySelector('.suggested-wrapper');

            div.scrollBy({
                top: 0,
                left: 600,
                behavior: 'smooth',
            });
            this.nextClicked = true;
        },
        refresh() {
            this.makeSuggested();
        },
        makeSuggested() {
            this.suggestedLoading = true;

            axios({
                method: 'GET',
                url: `/dashboard/suggested-content`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.suggestedLoading = false;
                    if (response && response.data) {
                        if (response.data.objects) {
                            this.suggestedContent = [];
                            this.$set(this.$data, 'suggestedContent', response.data.objects);
                        } else {
                            this.$set(this.$data, 'suggestedContent', []);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.suggestedLoading = false;
                });
        },
    },
};
</script>
