<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <div>
        <div class="pl-sm-4">
            <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
                <div class="my-4 bg-white p-4 round-unify">
                    <div contenteditable="true" ref="input" v-html="text" class="border round-unify p-3"></div>
                    <b-form-checkbox v-model="agreement.RequestApproval" :value="true" size="md" class="mt-2">
                        <p class="mb-0">Request approval from your members</p>
                    </b-form-checkbox>

                    <!-- Footer start -->
                    <div class="d-flex justify-content-between mt-4">
                        <b-button
                            variant="outline-danger"
                            @click="hasHistory() ? $router.go(-1) : $router.push('/admin/settings')"
                            >Cancel</b-button
                        >

                        <b-button variant="primary" @click="createTermsAndConditions()">Save</b-button>
                    </div>
                    <!-- Footer end -->
                </div>
            </b-overlay>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

import EventBus from '@/eventBus';

export default {
    name: 'TermsAndConditions',
    data() {
        return {
            agreement: {
                Content: '',
                RequestApproval: false,
            },
            text: '<p class:="muted">Terms and conditions text </p>',
            loading: false,
        };
    },
    mounted() {
        this.getTermsAndConditions();
    },
    methods: {
        hasHistory() {
            return window.history.length > 2;
        },

        createTermsAndConditions() {
            const div = this.$refs.input;
            this.agreement.Content = div.innerHTML;
            this.loading = true;
            axios({
                method: 'POST',
                url: '/admin/agreement/terms-and-conditions',
                data: this.agreement,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data) {
                        this.agreement = response.data;
                    }
                    EventBus.$emit('INFO', {
                        Message: 'New version is saved',
                        Details: '',
                    });
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response && error.response.status === 404) {
                    } else {
                        const msg = {
                            Message: 'Failed to get terms and conditions',
                            Details: '',
                        };
                        EventBus.$emit('ERROR', msg);
                    }
                });
        },

        getTermsAndConditions() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/admin/agreement/terms-and-conditions',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data) {
                        this.agreement = response.data;
                        this.text = this.agreement.Content;
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response && error.response.status === 404) {
                    } else {
                        const msg = {
                            Message: 'Failed to get terms and conditions',
                            Details: '',
                        };
                        EventBus.$emit('ERROR', msg);
                    }
                });
        },
    },
};
</script>
