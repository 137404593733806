<template>
    <div>
        <div class="ml-md-4">
            <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
                <h3 class="m-0 mt-4">Space logo</h3>
                <div class="mt-2 d-flex flex-column flex-md-row white-bg round-unify mb-3 p-3">
                    <b-form class="w-100" @submit.stop.prevent="handleSubmitLogo">
                        <b-form-group id="input-group-3" label="" label-for="input-image" label-class="label-align">
                            <b-form-file
                                id="input-image"
                                @change="previewLogo"
                                accept="image/jpeg, image/png"
                                class="rounded-pill"
                                placeholder="Choose a file or drop it here..."
                                drop-placeholder="Drop file here..."
                                v-model="logo"
                                required
                            ></b-form-file>
                        </b-form-group>
                        <div class="d-flex align-items-center justify-content-end">
                            <b-button variant="primary" type="submit">Save</b-button>
                        </div>
                    </b-form>
                    <div class="pl-md-3 mt-4 mt-md-0 w-100">
                        <h3 class="pl-4">Preview</h3>
                        <div class="mt-2 bg-white p-4 round-unify border">
                            <nav
                                class="navbar sticky-top navbar-light d-flex align-items-start hfv-header p-0"
                                id="brandbar"
                            >
                                <div class="navbar-layout align-items-center">
                                    <div class="nav-sidebar">
                                        <router-link class="d-none d-md-block" to="/feed">
                                            <img
                                                v-if="logo"
                                                :src="logoURL"
                                                class="ml-4 align-middle"
                                                width="auto"
                                                height="23"
                                            />
                                            <img
                                                v-else
                                                :src="space['LogoURL']"
                                                class="ml-4 align-middle"
                                                width="auto"
                                                height="23"
                                            />
                                        </router-link>
                                    </div>
                                    <div class="nav-content pl-md-3 pr-md-3 position-relative">
                                        <div class="d-flex w-100 align-items-center search-bar-wrapper rounded-pill">
                                            <b-icon class="mr-2" icon="search"></b-icon>
                                            <div class="search-input-wrapper">
                                                <input
                                                    class="search-input"
                                                    type="search"
                                                    placeholder="Search"
                                                    aria-label="Search"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="nav-sidebar d-flex justify-content-end pr-3">
                                        <!-- create menu -->
                                        <b-dropdown
                                            variant="outline-primary btn-round mx-sm-3 mx-2"
                                            menu-class="mt-3 border-0"
                                            no-caret
                                            right
                                            id="create-tooltip"
                                        >
                                            <template v-slot:button-content>
                                                <b-icon
                                                    icon="plus"
                                                    class="m-0"
                                                    scale="1.2"
                                                    style="position: relative; bottom: 1px"
                                                ></b-icon>
                                            </template>
                                        </b-dropdown>
                                        <b-tooltip target="create-tooltip" bottom triggers="hover"> Create</b-tooltip>
                                        <!-- create menu -->

                                        <!-- Notifications -->
                                        <b-button
                                            variant="outline-primary"
                                            d
                                            class="btn-round mr-2 mr-sm-3"
                                            id="notifications-tooltip"
                                        >
                                            <b-icon
                                                icon="bell"
                                                class="m-0"
                                                style="position: relative; bottom: 1px"
                                                scale="1"
                                            ></b-icon>
                                        </b-button>
                                        <b-tooltip target="notifications-tooltip" bottom triggers="hover">
                                            Notifications
                                        </b-tooltip>
                                        <!-- Notifications -->

                                        <!-- user menu -->
                                        <b-dropdown
                                            variant="round"
                                            menu-class="mt-3 border-0"
                                            no-caret
                                            right
                                            id="user-tooltip"
                                        >
                                            <template
                                                slot="button-content"
                                                style="border-color: grey"
                                                class="profile-avatar"
                                            >
                                                <ProfileCircle
                                                    :ImageURL="this.$store.state.me.Profile.ImageURL"
                                                ></ProfileCircle>
                                            </template>
                                        </b-dropdown>
                                        <b-tooltip target="user-tooltip" bottom triggers="hover"> Profile</b-tooltip>
                                        <!-- user menu -->
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </b-overlay>
            <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
                <h3 class="m-0 mt-4">Favicon</h3>
                <div class="mt-2 d-flex flex-column flex-md-row white-bg round-unify mb-3 p-3">
                    <b-form class="w-100" @submit.stop.prevent="handleSubmitFavicon">
                        <b-form-group
                            id="input-group-3"
                            label=""
                            label-for="input-image"
                            label-class="label-align"
                            description="Favicon should be 32x32 pixels"
                        >
                            <b-form-file
                                id="favicon-image-input"
                                accept="image/jpeg, image/png"
                                class="rounded-pill"
                                placeholder="Choose a file or drop it here..."
                                drop-placeholder="Drop file here..."
                                v-model="favicon"
                                @change="previewFavicon"
                            ></b-form-file>
                        </b-form-group>
                        <div class="d-flex align-items-center justify-content-end">
                            <b-button variant="primary" type="submit">Save</b-button>
                        </div>
                    </b-form>
                </div>
            </b-overlay>
            <h3 class="m-0 mt-4">Space colors</h3>
            <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
                <div class="d-flex flex-column mt-2 bg-white p-4 round-unify">
                    <!-- <div>Click on the colored circle to open a color picker.</div> -->
                    <b-form ref="createform" @submit.stop.prevent="$bvModal.show('save-modal')">
                        <div class="d-block">
                            <h3 class="mb-1 label-align">Primary color (brand color)</h3>
                            <div class="d-flex w-100 align-items-center mb-3">
                                <b-input
                                    id="name"
                                    placeholder="hex code"
                                    v-model="primaryColor"
                                    class="rounded-pill inputWidth"
                                    required
                                ></b-input>
                                <div class="colorCircle" :style="'background:' + primaryColor">
                                    <b-input
                                        class="colorInput"
                                        type="color"
                                        ref="PrimaryColor"
                                        v-model="primaryColor"
                                        :value="primaryColor"
                                    ></b-input>
                                </div>
                            </div>
                        </div>

                        <div class="">
                            <h3 class="mb-1 label-align">Secondary color (links color)</h3>
                            <div class="d-flex w-100 align-items-center mb-3">
                                <b-input
                                    id="name"
                                    placeholder="hex code"
                                    v-model="secondaryColor"
                                    class="rounded-pill inputWidth"
                                    required
                                ></b-input>
                                <div class="colorCircle" :style="'background:' + secondaryColor">
                                    <b-input
                                        class="colorInput"
                                        type="color"
                                        ref="SecondaryColor"
                                        v-model="secondaryColor"
                                        :value="secondaryColor"
                                    ></b-input>
                                </div>
                            </div>
                        </div>

                        <div class="">
                            <h3 class="mb-1 label-align">Text color</h3>
                            <div class="d-flex w-100 align-items-center mb-3">
                                <b-input
                                    id="name"
                                    placeholder="hex code"
                                    v-model="textColor"
                                    class="rounded-pill inputWidth"
                                    required
                                ></b-input>
                                <div class="colorCircle" :style="'background:' + textColor">
                                    <b-input
                                        class="colorInput"
                                        type="color"
                                        ref="TextColor"
                                        v-model="textColor"
                                        :value="textColor"
                                    ></b-input>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex align-items-center justify-content-end">
                            <b-button variant="primary" type="submit">Save</b-button>
                        </div>
                    </b-form>
                </div>
            </b-overlay>
            <h3 class="m-0 mt-4">Space introduction</h3>
            <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
                <!-- <div class="mt-4 bg-white p-5 round-unify"> -->
                <b-tabs class="mt-2" v-model="tabIndex">
                    <b-tab title="Step 1">
                        <div class="d-flex flex-column flex-md-row white-bg round-unify mb-3 p-3">
                            <b-form class="w-100" @submit.stop.prevent="saveStep(1)">
                                <b-form-group
                                    id="input-group-1"
                                    label="Title"
                                    label-for="input-title"
                                    label-class="label-align"
                                >
                                    <b-form-input
                                        id="input-title"
                                        v-model="steps[1]['Title']"
                                        type="text"
                                        class="form-control rounded-pill"
                                        required
                                        placeholder="title"
                                    ></b-form-input>
                                </b-form-group>

                                <b-form-group
                                    id="input-group-"
                                    label="Subtitle"
                                    label-for="input-subtitle"
                                    label-class="label-align"
                                >
                                    <b-form-input
                                        id="input-subtitle"
                                        v-model="steps[1]['Subtitle']"
                                        type="text"
                                        class="form-control rounded-pill"
                                        required
                                        placeholder="Subtitle"
                                    ></b-form-input>
                                </b-form-group>

                                <b-form-group
                                    id="input-group-3"
                                    label="Welcome image"
                                    label-for="input-welcome-image"
                                    label-class="label-align"
                                >
                                    <b-form-file
                                        id="input-welcome-image"
                                        @change="previewImage"
                                        class="rounded-pill"
                                        placeholder="Choose a file or drop it here..."
                                        drop-placeholder="Drop file here..."
                                        v-model="welcomeImage"
                                    ></b-form-file>
                                </b-form-group>
                                <div class="d-flex align-items-center justify-content-end">
                                    <b-button variant="primary" type="submit">Save</b-button>
                                </div>
                            </b-form>
                            <div class="pl-md-3 mt-4 mt-md-0 w-100">
                                <h3 class="pl-4">Preview</h3>
                                <div class="mt-2 bg-white p-4 round-unify border">
                                    <div class="step-description pt-0">
                                        <h2>{{ steps[1]['Title'] }}</h2>
                                        <p>{{ steps[1]['Subtitle'] }}</p>
                                    </div>
                                    <div class="step-image">
                                        <img
                                            v-if="welcomeImageURL"
                                            class="w-100"
                                            :src="welcomeImageURL"
                                            alt="Welcome to the Community!"
                                        />
                                        <img
                                            v-else-if="steps[1]['ImageURL']"
                                            class="w-100"
                                            :src="steps[1]['ImageURL']"
                                            alt="Welcome to the Community!"
                                        />
                                        <illustration v-else type="projects" class="my-0 illu"></illustration>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="Step 2">
                        <div class="d-flex flex-column flex-md-row white-bg round-unify mb-3 p-3">
                            <b-form class="w-100" @submit.stop.prevent="saveStep(2)">
                                <b-form-group
                                    id="input-group-1"
                                    label="Title"
                                    label-for="input-title"
                                    label-class="label-align"
                                >
                                    <b-form-input
                                        id="input-title"
                                        v-model="steps[2]['Title']"
                                        type="text"
                                        class="form-control rounded-pill"
                                        required
                                        placeholder="title"
                                    ></b-form-input>
                                </b-form-group>

                                <b-form-group
                                    id="input-group-"
                                    label="Subtitle"
                                    label-for="input-subtitle"
                                    label-class="label-align"
                                >
                                    <b-form-input
                                        id="input-subtitle"
                                        v-model="steps[2]['Subtitle']"
                                        type="text"
                                        class="form-control rounded-pill"
                                        required
                                        placeholder="Subtitle"
                                    ></b-form-input>
                                </b-form-group>
                                <div class="d-flex align-items-center justify-content-end">
                                    <b-button variant="primary" type="submit">Save</b-button>
                                </div>
                            </b-form>
                            <div class="pl-md-3 mt-4 mt-md-0 w-100">
                                <h3 class="pl-4">Preview</h3>
                                <div class="mt-2 bg-white p-4 round-unify border">
                                    <div class="step-description pt-0">
                                        <h2>{{ steps[2]['Title'] }}</h2>
                                        <p>{{ steps[2]['Subtitle'] }}</p>
                                    </div>
                                    <div class="step-image">
                                        <div class="">
                                            <div class="d-flex w-100 justify-content-center my-4">
                                                <div class="user">
                                                    <img :src="$store.state.me.Profile.ImageURL" alt="" />
                                                    <div class="file text-center float-r text-light">
                                                        <span>Upload new picture</span>
                                                        <input type="file" name="file" disabled />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="Step 3">
                        <div class="d-flex flex-column flex-md-row white-bg round-unify mb-3 p-3">
                            <b-form class="w-100" @submit.stop.prevent="saveStep(4)">
                                <b-form-group
                                    id="input-group-1"
                                    label="Title"
                                    label-for="input-title"
                                    label-class="label-align"
                                >
                                    <b-form-input
                                        id="input-title"
                                        v-model="steps[4]['Title']"
                                        type="text"
                                        class="form-control rounded-pill"
                                        required
                                        placeholder="title"
                                    ></b-form-input>
                                </b-form-group>

                                <b-form-group
                                    id="input-group-"
                                    label="Subtitle"
                                    label-for="input-subtitle"
                                    label-class="label-align"
                                >
                                    <b-form-input
                                        id="input-subtitle"
                                        v-model="steps[4]['Subtitle']"
                                        type="text"
                                        class="form-control rounded-pill"
                                        placeholder="Subtitle (optional)"
                                    ></b-form-input>
                                </b-form-group>

                                <b-form-group
                                    id="input-group-"
                                    label="Strong tags suggestion"
                                    label-for="input-StrongTags"
                                    label-class="label-align"
                                >
                                    <b-form-input
                                        id="input-StrongTags"
                                        v-model="space['StrongTags']"
                                        type="text"
                                        class="form-control rounded-pill"
                                        placeholder="Strong tags suggestion"
                                    ></b-form-input>
                                </b-form-group>

                                <div class="d-flex align-items-center justify-content-end">
                                    <b-button variant="primary" type="submit">Save</b-button>
                                </div>
                            </b-form>
                            <div class="pl-md-3 mt-4 mt-md-0 w-100">
                                <h3 class="pl-4">Preview</h3>
                                <div class="mt-2 bg-white p-4 round-unify border">
                                    <div class="step-description pt-0">
                                        <h2>{{ steps[4]['Title'] }}</h2>
                                        <p>{{ steps[4]['Subtitle'] }}</p>
                                    </div>
                                    <div class="step-image overflow-auto">
                                        <div>
                                            <div class="form-group my-4">
                                                <label for="tags">Let's talk about</label><br />
                                                <div>
                                                    <vue-tags-input
                                                        id="tags"
                                                        class="mt-0 border"
                                                        v-model="tag"
                                                        :tags="tags"
                                                        placeholder="type anything"
                                                        :separators="separators"
                                                        :autocomplete-items="autocompleteItems"
                                                        :add-only-from-autocomplete="false"
                                                        @tags-changed="updateTags"
                                                        @before-adding-tag="addTag"
                                                        disable
                                                    />
                                                </div>
                                                <small v-if="this.space['StrongTags']"
                                                    >Only the first 10 tags will be shown in the user list, so choose
                                                    the most important ones first.<br />Strong Tags:
                                                    {{ this.space['StrongTags'] }}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="Step 4">
                        <div class="d-flex flex-column flex-md-row white-bg round-unify mb-3 p-3">
                            <b-form class="w-100" @submit.stop.prevent="saveStep(5)">
                                <b-form-group
                                    id="input-group-1"
                                    label="Title"
                                    label-for="input-title"
                                    label-class="label-align"
                                >
                                    <b-form-input
                                        id="input-title"
                                        v-model="steps[5]['Title']"
                                        type="text"
                                        class="form-control rounded-pill"
                                        required
                                        placeholder="title"
                                    ></b-form-input>
                                </b-form-group>

                                <b-form-group
                                    id="input-group-"
                                    label="Subtitle"
                                    label-for="input-subtitle"
                                    label-class="label-align"
                                >
                                    <b-form-input
                                        id="input-subtitle"
                                        v-model="steps[5]['Subtitle']"
                                        type="text"
                                        class="form-control rounded-pill"
                                        placeholder="Subtitle (optional)"
                                    ></b-form-input>
                                </b-form-group>

                                <b-form-group
                                    id="input-group-"
                                    label="Placeholder"
                                    label-for="input-Placeholder"
                                    label-class="label-align"
                                >
                                    <b-form-input
                                        id="input-Placeholder"
                                        v-model="steps[5]['Placeholder']"
                                        type="text"
                                        class="form-control rounded-pill"
                                        placeholder="Placeholder (optional)"
                                    ></b-form-input>
                                </b-form-group>
                                <div class="d-flex align-items-center justify-content-end">
                                    <b-button variant="primary" type="submit">Save</b-button>
                                </div>
                            </b-form>
                            <div class="pl-md-3 mt-4 mt-md-0 w-100">
                                <h3 class="pl-4">Preview</h3>
                                <div class="mt-2 bg-white p-4 round-unify border">
                                    <div class="step-description pt-0">
                                        <h2>{{ steps[5]['Title'] }}</h2>
                                        <p>{{ steps[5]['Subtitle'] }}</p>
                                    </div>
                                    <div class="step-image overflow-auto">
                                        <div>
                                            <div class="form-group my-4">
                                                <label for="Interests">About me</label>
                                                <textarea
                                                    v-model="$store.state.me.Profile['Interests']"
                                                    min-rows="5"
                                                    max-rows="10"
                                                    name="Interests"
                                                    class="w-100 p-2 hfv-textarea"
                                                    :placeholder="steps[5]['Placeholder']"
                                                >
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
                <!-- </div> -->
            </b-overlay>
        </div>

        <b-modal
            id="save-modal"
            size="sm"
            title="Apply new colors"
            @ok="updateColors"
            ok-title="Apply"
            ok-variant="primary"
            cancel-title="Cancel"
            cancel-variant="outline-danger"
        >
            <p class="mb-1">The changes will be applied immediately.</p>
        </b-modal>
    </div>
</template>

<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.step-description {
    min-height: 100px;
    // text-align: center;
    overflow: hidden;
    padding: 20px 0px;
}

.step-image {
    overflow: hidden;
    height: 250px;
    @media (min-width: 768px) {
        height: 300px;
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top center;
        border-radius: 7px;
    }
}

.tips {
    p {
        font-size: 18px;
        line-height: 2;
    }

    .menu-icon {
        margin: 0px 10px;
        // display: inline-block;
        // border: 1px solid @purple;
        // border-radius: 50%;
        // width: 20px;
        // height: 20px;
        // padding: 2px;
    }
}

.modal-content {
    height: 50%;
}

@media (max-width: 575.98px) {
    .modal-content {
        height: 70%;
    }
}

.user {
    display: block;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    position: relative;
}

.user img,
.user .user-img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    image-orientation: from-image;
}

.user .file {
    border: none;
    font-size: 15px;
    line-height: 200px;
    background: rgba(0, 0, 0, 0.2);
    width: 200px;
    position: absolute;
    padding: 0 !important;
    text-align: center;
    left: 0;
    top: 0;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    transition: background 0.3s ease-in-out;
}

.user .file input {
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
    cursor: pointer;
}

.colorInput {
    height: 38px !important;
    width: 38px !important;
    margin-left: 0px;
    border-radius: 0px;
    opacity: 0;
    border: none;
    padding: 0 !important;
}

.colorCircle {
    width: 38px;
    height: 38px;
    border-radius: 19px;
    border: 1px solid @ngrey;
    margin-left: 10px;
}

.inputWidth {
    max-width: 158px;
}
</style>

<script>
import axios from 'axios';
import Vue from 'vue';
import { VueTagsInput } from '@johmun/vue-tags-input';
import EventBus from '../../../eventBus';

import setCustomColorsGlobal from '../../../customColors.js';

export default {
    name: 'Customization',
    components: {
        VueTagsInput,
    },
    data() {
        return {
            space: null,
            logo: null,
            logoURL: null,
            favicon: null,
            faviconURL: null,
            primaryColor: null,
            secondaryColor: null,
            textColor: null,
            tabIndex: 0,
            loading: true,

            welcomeImage: null,
            welcomeImageURL: null,
            welcomeImageVal: null,
            steps: {},

            tag: '',
            tags: [],
            autocompleteItems: [],
            separators: [';', ',', '#', ', #'],
        };
    },
    computed: {},
    created() {
        this.getSpaceSettings();
    },
    mounted() {},
    tabIndex(newVal, oldVal) {
        this.getSpaceSettings();
    },
    methods: {
        previewLogo(event) {
            const file = event.target.files[0];
            this.logoURL = URL.createObjectURL(file);
            this.logo = file;
        },
        previewFavicon(event) {
            const file = event.target.files[0];
            this.faviconURL = URL.createObjectURL(file);
            this.favicon = file;
        },

        updateColors() {
            this.loading = true;
            this.space.PrimaryColor = this.primaryColor;
            this.space.SecondaryColor = this.secondaryColor;
            this.space.TextColor = this.textColor;

            const data = JSON.stringify(this.space);
            axios({
                method: 'PUT',
                url: '/admin/space/settings',
                withCredentials: true,
                data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data) {
                        this.space = response.data;
                        this.setCustomColors(this.space);
                        const msg = {
                            Message: 'Changes saved',
                            Details: '',
                        };
                        EventBus.$emit('INFO', msg);
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to save space settings',
                        Details: '',
                    };
                    EventBus.$emit('ERROR', msg);
                });
        },
        handleSubmitLogo() {
            this.loading = true;
            if (this.logoURL != null) {
                this.$store
                    .dispatch('imageUpload', {
                        file: this.logo,
                        url: '/upload/image/logo',
                    })
                    .then((response) => {
                        this.space.LogoURL = response;
                        this.loading = false;
                        this.updateSpace();
                    })
                    .catch((error) => {
                        const message = {
                            Message: 'Failed to save space settings',
                            Details: error,
                        };
                        EventBus.$emit('ERROR', message);
                        this.loading = false;
                    });
            }
        },
        handleSubmitFavicon() {
            this.loading = true;
            if (this.faviconURL != null) {
                this.$store
                    .dispatch('imageUpload', {
                        file: this.favicon,
                        url: '/upload/image/favicon',
                    })
                    .then((response) => {
                        this.space.FaviconURL = response;
                        this.loading = false;
                        this.updateSpace();
                    })
                    .catch((error) => {
                        const message = {
                            Message: 'Failed to save space settings',
                            Details: error,
                        };
                        EventBus.$emit('ERROR', message);
                        this.loading = false;
                    });
            }
        },

        updateSpace() {
            this.loading = true;

            const data = JSON.stringify(this.space);
            axios({
                method: 'PUT',
                url: '/admin/space/settings',
                withCredentials: true,
                data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data) {
                        this.space = response.data;
                        const msg = {
                            Message: 'Changes saved',
                            Details: '',
                        };
                        EventBus.$emit('INFO', msg);
                        this.logoURL = null;
                        this.logo = null;
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to save space settings',
                        Details: '',
                    };
                    EventBus.$emit('ERROR', msg);
                });
        },
        getSpaceSettings() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/admin/space/settings',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data) {
                        this.space = response.data;
                        this.loading = false;
                        this.space = response.data;
                        this.primaryColor = this.space.PrimaryColor;
                        this.secondaryColor = this.space.SecondaryColor;
                        this.textColor = this.space.TextColor;
                        this.space.Intro.forEach((element, index) => {
                            this.steps[element.Step] = element;
                        });
                    }
                    this.welcomeImage = null;
                    this.welcomeImageURL = null;
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to get space settings',
                        Details: '',
                    };
                    EventBus.$emit('ERROR', msg);
                });
        },
        setCustomColors(space) {
            // color Mockdata. Light and dark versions of colors are automagically generated
            const colorData = [
                {
                    name: 'purple',
                    value: space.PrimaryColor,
                },
                {
                    name: 'red',
                    value: space.SecondaryColor,
                },
                {
                    name: 'text',
                    value: space.TextColor,
                },
            ];
            setCustomColorsGlobal(colorData);
        },
        saveStep(index) {
            this.loading = true;
            const localSteps = [];
            this.space.Intro.forEach((element, index) => {
                localSteps[element.Step] = element;
            });

            localSteps[index] = this.steps[index];
            const intro = [];
            localSteps.forEach((value, key) => {
                intro.push(value);
            });

            if (this.welcomeImageURL != null && index === 1) {
                this.$store
                    .dispatch('imageUpload', {
                        file: this.welcomeImage,
                        url: '/upload/image/intro',
                    })
                    .then((response) => {
                        intro[0].ImageURL = response;
                        this.space.Intro = intro;
                        this.loading = false;
                        this.handleSubmitIntro();
                    })
                    .catch((error) => {
                        console.log(error);
                        const message = {
                            Message: 'Could not update step.',
                            Details: error,
                        };
                        EventBus.$emit('ERROR', message);
                        this.loading = false;
                    });
            } else {
                this.space.Intro = intro;
                this.handleSubmitIntro();
            }
        },

        changedInput() {
            this.$emit('input-changed');
        },
        addTag(obj) {
            const tag = JSON.stringify({ Value: obj.tag.text });
            this.$store
                .dispatch('createTag', tag)
                .then((response) => {
                    const tag = {
                        text: response.Value,
                        slug: response.Slug,
                    };
                    this.tags.push(tag);
                    this.tag = '';
                    return true;
                })
                .catch((error) => {
                    console.log('error: ', error);
                    return false;
                });
        },

        updateTags(newTags) {
            this.changedInput();
            this.autocompleteItems = [];
            this.tags = newTags;
        },

        previewImage(event) {
            const file = event.target.files[0];
            this.welcomeImageURL = URL.createObjectURL(file);
            this.welcomeImage = file;
        },
        handleSubmitIntro() {
            this.loading = true;

            const data = JSON.stringify(this.space);
            axios({
                method: 'PUT',
                url: '/admin/space/settings',
                withCredentials: true,
                data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data) {
                        this.space = response.data;
                        this.space.Intro.forEach((element, index) => {
                            this.steps[element.Step] = element;
                        });
                        const msg = {
                            Message: 'Changes saved',
                            Details: '',
                        };
                        EventBus.$emit('INFO', msg);
                        this.welcomeImage = null;
                        this.welcomeImageURL = null;
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to save space settings',
                        Details: '',
                    };
                    EventBus.$emit('ERROR', msg);
                });
        },
    },
};
</script>
