<style lang="less" scoped>
@import '~@/assets/less/variables.less';
@import '~@/assets/less/coapp.less';

.grey-text {
    color: #868685;
}

.table-height-60 {
    height: 55vh;
}
</style>

<template>
    <div class="mb-5">
        <!-- Invoice Table -->
        <div class="px-sm-4">
            <co-headline v-if="user && user.Profile" :level="2" class="px-3 pb-2">
                {{ user.Profile.FirstName }}'s upcoming and past invoices
            </co-headline>
            <coTable
                :items="allInvoices"
                :sortable="true"
                :loading="loading"
                :preventHorizontalScroll="true"
                :columns="[
                    {
                        title: $t('labels.date'),
                        key: 'Created',
                    },
                    {
                        title: $t('labels.status'),
                        key: 'Status',
                        class: 'd-none d-md-table-cell',
                    },
                    {
                        title: $t('labels.amount'),
                        key: 'Total',
                        class: 'text-right',
                    },
                    {
                        title: '',
                        key: 'Action',
                        style: 'width: 5rem; padding:0;',
                        notSortable: true,
                    },
                ]"
            >
                <template v-slot:Created="slotProps">
                    <co-date :date="slotProps.item['Created']" format="exact" />
                </template>
                <template v-slot:Status="slotProps">
                    {{ $t(slotProps.item.Status) }}
                </template>
                <template v-slot:Total="slotProps">
                    <co-price
                        inheritTextSizing
                        :priceObj="{
                            PriceInCents: slotProps.item['Total'],
                            Currency: slotProps.item['Currency'],
                        }"
                    />
                </template>
                <template v-slot:Action="slotProps">
                    <CoLink
                        v-if="slotProps.item && slotProps.item.InvoicePDF"
                        class="d-inline-block"
                        :to="slotProps.item.InvoicePDF"
                        :isExternalLink="true"
                    >
                        <coIcon name="download" />
                    </CoLink>
                    <CoLink
                        v-else-if="slotProps.item && slotProps.item.InvoiceLines"
                        class="d-inline-block"
                        :isExternalLink="true"
                        @click.native.prevent="showInvoicePreview(slotProps.item)"
                    >
                        <coIcon name="eye" />
                    </CoLink>
                    <CoLink
                        v-if="slotProps.item && slotProps.item.HostedInvoiceURL"
                        class="d-inline-block"
                        :isExternalLink="true"
                        :newTab="true"
                        :to="slotProps.item.HostedInvoiceURL"
                    >
                        <coIcon name="credit-card" />
                    </CoLink>
                </template>
            </coTable>
        </div>

        <CoModal size="lg" ref="invoicePreviewModal" :title="$t('labels.invoicepreview')">
            <template slot="body">
                <co-invoice-detail :invoice="previewInvoice" />
            </template>
        </CoModal>
    </div>
</template>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';
import CoHeadline from '../../../../components/Atoms/co-headline/CoHeadline.vue';
import CoDate from '../../../../components/Molecules/co-date/CoDate.vue';
import CoPrice from '../../../../components/Atoms/co-price/coPrice.vue';
import CoLink from '../../../../components/Atoms/co-link/CoLink.vue';
import CoModal from '../../../../components/Organisms/co-modal/CoModal.vue';
import CoInvoiceDetail from '../../../../components/Molecules/co-invoice-detail/CoInvoiceDetail.vue';

export default {
    components: {
        CoHeadline,
        CoDate,
        CoPrice,
        CoLink,
        CoModal,
        CoInvoiceDetail,
    },
    name: 'Invoices',
    data() {
        return {
            paymentMethods: [],
            loading: false,
            errorMsg: null,
            windowWidth: window.innerWidth,
            publicKey: '',
            accountID: '',
            page: 0,
            user: null,
            invoicesOnPage: [],
            upcomingInvoice: null,
            allInvoices: [],
            lastPage: false,
            pastInvoices: [],
            previewInvoice: null,
        };
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });

        const { ID } = this.$route.params;
        this.adminGetUserByID(ID);
        this.getInvoicesByID(ID);
        //override locale for this view to prevent partially translated views
        //needs to be rest to original in beforeDestroy() hook
        this.$i18n.locale = 'en';
    },
    beforeDestroy() {
        //reset locale settings
        this.$i18n.locale = this.$language ? this.$language : 'en';
    },
    methods: {
        adminGetUserByID(ID) {
            this.$store
                .dispatch('adminGetUserByID', ID)
                .then((response) => {
                    this.$data.user = response;
                    if (this.user && this.user.Profile && this.user.Profile.Name) {
                        if (this.user.Profile.FirstName === '' || this.user.Profile.FirstName == null) {
                            const names = this.user.Profile.Name.split(' ');
                            this.user.Profile.FirstName = names[0];
                            names.splice(0, 1);
                            if (names.length > 1) {
                                this.user.Profile.LastName = names.join(' ');
                            } else {
                                this.user.Profile.LastName = names[0];
                            }
                        }
                    }
                })
                .catch((error) => {
                    const message = {
                        Message: 'Can`t get user data',
                        Details: error.response.data,
                    };
                    EventBus.$emit('ERROR', message);
                    this.$router.push(`/admin/community/member/list`);
                });
        },
        getInvoicesByID(userID) {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/payment/stripe/invoices/${userID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        if (response.data.PastInvoices) {
                            this.pastInvoices = response.data.PastInvoices;
                            this.allInvoices = this.pastInvoices;
                        }

                        if (response.data.UpcomingInvoice) {
                            this.upcomingInvoice = response.data.UpcomingInvoice;
                            this.allInvoices.unshift(this.upcomingInvoice);
                        }

                        if (this.allInvoices.length > 0) {
                            this.nextPage(this.page);
                        }
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        showInvoicePreview(invoice) {
            this.previewInvoice = invoice;
            this.$refs.invoicePreviewModal.show();
        },
    },
};
</script>
