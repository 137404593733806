<template>
    <div class="min-vh-100 d-flex bg justify-content-center align-items-md-center">
        <vue-headful
            :title="'Login -' + this.$store.state.space.Name + ' Community Platform'"
            description=""
            :image="this.$store.state.space.LogoURL"
        />

        <div class="login-form">
            <div class="mt-4 mt-md-0 p-4 bg-white round-unify text-left">
                <div class="text-center">
                    <img
                        v-if="this.$store.state.space.LogoURL"
                        :src="this.$store.state.space.LogoURL"
                        width="145"
                        height="auto"
                        alt="Community Logo"
                        class="my-2"
                    />
                    <div v-else class="my-2">
                        <h2>{{ this.$store.state.space.Name }}</h2>
                    </div>
                </div>
                <div class="w-100 py-4 text-center">
                    <h2>Space has been suspended.</h2>
                    <p>If you're admin of this space, please contact support.</p>
                    <a href="https://coapp.io">coapp.io</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Axios from 'axios';

export default {
    data() {
        return {};
    },

    mounted() {},

    methods: {},
};
</script>
