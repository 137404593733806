import Vue from 'vue'; // initialize
import { plainToClass } from 'class-transformer';
// import localization plugin
import VueI18n from 'vue-i18n';
import userrouter from './router.ts'; // use vue router
import saasrouter from './saas-router.ts'; // use vue router

// import BootstrapVue from 'bootstrap-vue'; //bootstrap components
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import Axios from 'axios'; // use axios to consume APIs
import Vuex from 'vuex';
import moment from 'moment';

import Notifications from 'vue-notification';
import velocity from 'velocity-animate';
import vueHeadful from 'vue-headful';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { v4 as uuidv4 } from 'uuid';

import * as linkify from 'linkifyjs';
import linkifyHtml from 'linkifyjs/html';
import hashtag from 'linkifyjs/plugins/hashtag';
import mention from 'linkifyjs/plugins/mention';

import VueNativeSock from 'vue-native-websocket';

import { UnleashClient } from 'unleash-proxy-client';

import App from './App.vue';

// eslint-disable-next-line import/no-extraneous-dependencies
import Bugsnag from '@bugsnag/js';
// eslint-disable-next-line import/no-extraneous-dependencies
import BugsnagPluginVue from '@bugsnag/plugin-vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import BugsnagPerformance from '@bugsnag/browser-performance';

import 'web-pingjs';

// import bootstrap styling
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// import modal fulscreen
import 'bootstrap4-fs-modal/dist/css/bootstrap-fs-modal.min.css';
// import custom styling
import './assets/less/coapp.less';
// import Base components globally
import './components/globals';

import SvgIcon from 'vue-svgicon';
import VuePortal from '@linusborg/vue-simple-portal';

import store from './store';
// make Vue use the localization plugin
Vue.use(VueI18n);
// import combined global language files
import { messages, numbers } from './language/locales.js';
// detect client language setting
Vue.prototype.$language = null;
try {
    Vue.prototype.$language = navigator.language;
} catch (error) {
    console && console.info ? console.info('could not detect navigator.language') : null;
}
// initiate the localization module
const i18n = new VueI18n({
    locale: Vue.prototype.$language,
    fallbackLocale: 'en',
    silentFallbackWarn: true,
    silentTranslationWarn: true,
    messages: messages,
    numberFormats: numbers,
});

// hashtag(linkify);
mention(linkify);

library.add(faAngleUp, faAngleDown);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.component('VueHeadful', vueHeadful);
const VueCookie = require('vue-cookie');
// Tell Vue to use the plugin

Vue.use(VuePortal);
Vue.use(VueCookie);
Vue.use(Notifications, { velocity });
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuex);
Vue.use(SvgIcon);

Bugsnag.start({
    apiKey: '0c0b78108263499699812bb84ff6d0a5',
    plugins: [new BugsnagPluginVue()],
});
BugsnagPerformance.start({ apiKey: '0c0b78108263499699812bb84ff6d0a5' });

const bugsnagVue = Bugsnag.getPlugin('vue');
bugsnagVue.installVueErrorHandler(Vue);

Vue.config.productionTip = false;
// API consumption setup
Vue.prototype.axios = Axios;
// set api base url for all requests
Axios.defaults.baseURL = 'https://api.coapp.io/v1/';

// Initialize the unleash client
const unleash = new UnleashClient({
    url: 'https://unleash-proxy.coapp.io/proxy',
    clientKey: 'key1-yMqT8noydCDxLmCz8uqzpqYaacvB9bN8aH7BfqtXZ2vLs33fRid6zCz9LLHeoKNX',
    appName: 'coapp-frontend',
});

Vue.prototype.$unleash = unleash;

// set $isMobile flag globally based on screen width
Vue.prototype.$isMobile = window && window.innerWidth <= 992;

function isLocalStorage() {
    const test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
        // available
    } catch (e) {
        return false;
        // unavailable
    }
}

if (isLocalStorage()) {
    const token = localStorage.getItem('_authtoken');
    Axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}

Axios.interceptors.request.use(
    (config) => {
        config.headers['X-Request-ID'] = uuidv4();

        return config;
    },
    (error) => {
        console.error(error);

        return Promise.reject(error);
    }
);

Axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.data && error.response.data.message) {
            const message = error.response.data.message.trim();
            const errorMsg = String('invalid or expired jwt');
            if (message === errorMsg) {
                store.commit('LOGOUT');
                const path = window.location.pathname;
                if (window.location.pathname !== '/login') {
                    userrouter.push('/login');
                }
            }
            return Promise.reject(error);
        }
        return Promise.reject(error);
    }
);

// add directives globally
Vue.directive('linkifytiptap', (el: HTMLElement, binding) => {
    el.innerHTML = linkifyHtml(el.innerHTML, {
        nl2br: true, // optional
        validate: {
            mention(value) {
                return false;
            },
        },
    });

    // old tip tap
    el.querySelectorAll('.mention').forEach((element: HTMLElement) => {
        let basepath = '/profile/';
        if (element.classList.contains('hashtag')) {
            basepath = '/search?term=';
            // for hashtags, hashtag links are already created in hashtag.js
            // return;
        }

        element.onclick = function (params) {
            if (element.dataset.mentionId) userrouter.push(basepath + element.dataset.mentionId);
            else userrouter.push(basepath + element.dataset.hashtagId);
        };
    });

    // new tip tap

    el.querySelectorAll("[data-type='mention']").forEach((element: HTMLElement) => {
        const basepath = '/profile/';
        const id = element.attributes['data-id'];
        element.onclick = function (params) {
            userrouter.push(basepath + id.value);
        };
    });

    el.querySelectorAll("[data-type='cohashtag']").forEach((element: HTMLElement) => {
        const basepath = '/search?term=';
        const id = element.attributes['data-id'];
        element.onclick = function (params) {
            userrouter.push(basepath + id.value);
        };
    });
});

/*
  capitalize
  Params: word [String]
  returns String
  converts first letter to uppercase
*/
Vue.filter('capitalize', (word) => {
    if (!word) return '';
    word = word.toString();
    return word.charAt(0).toUpperCase() + word.slice(1);
});
/*
  readabledate
  Params: timestamp [Integer]
  Returns human readable date string
*/
Vue.filter('readabledate', (value) => {
    let time = 0;

    if (typeof value === 'string') {
        time = parseInt(value);
    } else {
        time = value;
    }

    if (!value || !time) {
        return '';
    }
    time = time; // convert to milliseconds
    return moment(time).calendar(null, {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'dddd, MMM Do',
    });
});

/*
  readabletime
  Params: timestamp [Integer]
  Returns human readable time string
*/
Vue.filter('readabletime', (value) => {
    if (!parseInt(value)) return '';
    return moment(parseInt(value)).format('HH:mm');
});

/*
  timestampToString
  Params: timestamp [Integer]
  Returns human readable time string in fromat: 'YYYY-MM-DD HH:mm'
*/
Vue.filter('timestampToString', (value) => {
    if (!parseInt(value, 10)) return '';
    const timestamp = parseInt(value, 10) * 1000; // convert to milliseconds
    return moment(timestamp).format('HH:mm, DD.MM.YYYY');
});

/*
  save current viewport height in CSS variable '--viewport-height'
  needed for mobile views and software keyboard handling
*/
visualViewport.addEventListener('resize', (event) => {
    const viewportDimensions = event.target as VisualViewport;
    if (!viewportDimensions || !viewportDimensions.height) return;
    document.documentElement.style.setProperty('--viewport-height', `${viewportDimensions.height}px`);
});
// initiate Vue

// Vue.config.productionTip = false;

const { host } = window.location;
const parts = host.split('.');

const router = () => {
    let routes;
    if (parts[0] === 'app' || parts[0] === 'staging-app') {
        // app.coapp.io/create or staging-app.coapp.io/create
        routes = saasrouter;
    } else {
        routes = userrouter;
    }
    return routes;
};

new Vue({
    i18n,
    store,
    router: router(),
    render: (h) => h(App),
}).$mount('#app');
