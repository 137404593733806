<template>
    <div v-if="plan" class="pl-md-5 pr-md-5">
        <div class="px-sm-4">
            <div class="mb-2 d-flex ml-3 ml-md-3 align-items-center justify-content-between">
                <div class="d-flex align-items-center p-0">
                    <div class="coapp-icon">
                        <b-icon icon="credit-card" scale="1.2"></b-icon>
                    </div>
                    <h2 class="mr-2">{{ plan.Name }}</h2>
                </div>
                <b-dropdown
                    variant="ml-2 px-2"
                    class="d-menu"
                    menu-class="mt-0 border-1"
                    no-caret
                    right
                    round
                    style="position: relative"
                >
                    <template v-slot:button-content>
                        <b-icon icon="three-dots" class="m-0" scale="1.2"></b-icon>
                    </template>
                    <b-dropdown-item
                        class="d-action"
                        :to="`/admin/monetization/plan/edit/${plan.ID}?tabIndex=${tabIndex}`"
                        >Edit plan
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <div class="ml-3 ml-md-3">
                <!-- copy shareable plan URL -->
                <CoSharableInput
                    label="Shareable registration link"
                    icon="link"
                    type="password"
                    :value="`https://${this.$store.state.space.DomainName}/plans/${plan.ID}`"
                    readonly
                >
                </CoSharableInput>
            </div>
        </div>
        <!-- header end -->
        <div class="px-sm-4 mb-4">
            <div>
                <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
                    <!-- <div class="mt-4 bg-white p-5 round-unify"> -->
                    <b-tabs class="mt-1 my-tabs my-plan-tabs" v-model="tabIndex">
                        <b-tab title="General" class="tab" :title-link-class="linkClass(tabIndex, 0)">
                            <div class="w-100 col-12 p-0 m-0 col-xl-6 bg-white mt-3 bg-white round-unify-xs">
                                <div class="w-100 mb-2 mt-0 p-4">
                                    <h2 class="mb-2">General</h2>
                                    <div class="title mt-2">Name</div>
                                    <div class="text-field mt-1">{{ plan.Name }}</div>
                                    <div class="title mt-3">Description</div>
                                    <div class="text-field mt-1">{{ plan.Description }}</div>
                                    <div class="title mt-3">Status</div>
                                    <div class="text-field mt-1">
                                        <span v-if="plan.Active">Active</span>
                                        <span v-else>Inactive</span>
                                    </div>
                                    <div class="title mt-3">Visability</div>
                                    <div class="text-field mt-1">
                                        <span v-if="plan.Hidden">Hidden</span>
                                        <span v-else>Visible</span>
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab title="Price" class="tab" :title-link-class="linkClass(tabIndex, 1)">
                            <div class="w-100 mt-3 col-12 col-xl-6 p-4 bg-white round-unify-xs">
                                <h2>Price</h2>
                                <div class="d-flex mt-4">
                                    <div class="d-flex flex-column">
                                        <span class="grey">Net price</span>
                                        <span>{{ centsToPrice(plan.PriceInCents) }} {{ plan.Currency }} </span>
                                    </div>

                                    <div class="d-flex flex-column ml-4">
                                        <span class="grey">Time interval</span>
                                        <span v-if="plan.TimeInteval === 1"> Year </span>
                                        <span v-else> Month </span>
                                    </div>
                                    <div class="d-flex flex-column ml-4">
                                        <span class="grey">Interval Count</span>
                                        <span> {{ cycleDuration }} </span>
                                    </div>
                                </div>
                                <div class="d-flex flex-column mt-4">
                                    <span class="grey">Tax rate</span>
                                    <div class="d-flex">
                                        <span v-if="taxRate">{{ taxRate.Percentage ? taxRate.Percentage : 0 }}% </span>
                                        <span v-else>0%</span>
                                        <div class="ml-1" v-if="taxRate">
                                            <span v-if="taxRate.Inclusive"> Inclusive</span>
                                            <span v-else> Exclusive</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-column mt-4">
                                    <span class="grey">Gross price</span>
                                    <span>{{ FinalPrice ? FinalPrice : 0 }} {{ plan.Currency }}</span>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab title="Upgrades" class="tab" :title-link-class="linkClass(tabIndex, 2)">
                            <div class="w-100 mt-3 col-12 col-xl-6 p-4 bg-white round-unify-xs">
                                <h2>Upgrades</h2>
                                <div class="d-flex mt-2">
                                    <table class="w-100 p-0 m-0 mt-3 resource-table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>

                                                <th scope="col">Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="item in plan.Extras"
                                                :key="item.ID"
                                                class="align-items-start vertical-align-start"
                                            >
                                                <td scope="row">
                                                    <div class="d-flex flex-column">
                                                        <span class="align-top pt-3 pb-3 grey-text">{{
                                                            item.Name
                                                        }}</span>
                                                    </div>
                                                </td>

                                                <td class="align-top pt-3 pb-3 grey-text">
                                                    {{ item ? getFormattedPrice(item.PriceInCents) : 0 }}€
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab
                            title="Resources"
                            class="tab"
                            :title-link-class="linkClass(tabIndex, 3)"
                            :key="rerenderKey"
                        >
                            <!-- Resources -->
                            <div class="d-block d-xl-flex p-0 m-0">
                                <div class="col-12 p-0 m-0 col-xl-6 min-height-60">
                                    <div
                                        class="
                                            d-flex
                                            m-0
                                            mt-4 mt-xl-3
                                            p-4
                                            flex-column
                                            align-items-end
                                            bg-white
                                            round-unify-xs
                                            h-100
                                        "
                                    >
                                        <div class="align-self-start">
                                            <h1 class="pb-2">Resources</h1>
                                            <span class="grey-text">Manage available resources for this plan</span>
                                        </div>
                                        <div class="align-self-start w-100 mt-4 overflow-auto">
                                            <table class="w-100 p-0 m-0 mt-4 resource-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style="width: 40%">Name</th>
                                                        <th scope="col" style="width: 25%">Credits</th>
                                                        <th scope="col" style="width: 25%">Price</th>

                                                        <th
                                                            scope="col"
                                                            class="d-none d-sm-block"
                                                            style="width: 10%"
                                                        ></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(resource, index) in selectedResources"
                                                        :key="resource.ID"
                                                        class="align-items-start vertical-align-start"
                                                    >
                                                        <td scope="row " class="align-top pt-3 pb-3 grey-text">
                                                            {{ resource.Name }}
                                                        </td>
                                                        <td
                                                            class="align-top pt-3 pb-3 grey-text"
                                                            v-if="resource.CashCredits === true"
                                                        >
                                                            {{ resource ? getFormattedPrice(resource.CreditCents) : 0 }}
                                                            {{ plan.Currency }}
                                                        </td>
                                                        <td class="align-top pt-3 pb-3 grey-text" v-else>
                                                            {{ resource.CreditHours }} hours
                                                        </td>
                                                        <td class="align-top pt-3 pb-3 grey-text">
                                                            {{
                                                                resource ? getFormattedPrice(resource.PriceInCents) : 0
                                                            }}€
                                                        </td>
                                                        <td class="d-none align-top d-sm-block float-right"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 m-0 p-0 ml-xl-3 col-xl-6 min-height-60">
                                    <div
                                        class="
                                            d-flex
                                            m-0
                                            mt-4 mt-xl-3
                                            p-4
                                            flex-column
                                            align-items-end
                                            bg-white
                                            round-unify-xs
                                            h-100
                                        "
                                    >
                                        <div class="align-self-start">
                                            <h1 class="pb-2">Resource Groups</h1>
                                            <span class="grey-text"
                                                >Manage available resource groups for this plan</span
                                            >
                                        </div>
                                        <div class="align-self-start w-100 mt-4 mh-100 overflow-auto">
                                            <table
                                                v-if="selectedResourceGroups && selectedResourceGroups.length > 0"
                                                class="w-100 p-0 m-0 mt-4 resource-table"
                                            >
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style="width: 40%">Name</th>
                                                        <th scope="col" style="width: 25%">Credits</th>
                                                        <th scope="col" style="width: 25%">Price</th>

                                                        <th
                                                            scope="col"
                                                            class="d-none d-sm-block"
                                                            style="width: 10%"
                                                        ></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(resourceGroup, index) in selectedResourceGroups"
                                                        :key="resourceGroup.Name + '-' + index"
                                                        class="align-items-start vertical-align-start"
                                                    >
                                                        <td scope="row">
                                                            <div class="d-flex flex-column">
                                                                <span class="align-top grey-text">{{
                                                                    resourceGroup.Name
                                                                }}</span>
                                                                <div>
                                                                    <span
                                                                        class="d-flex m-0 grey-text"
                                                                        v-for="resource in resourceGroup.ListResources"
                                                                        :key="resource.ID"
                                                                    >
                                                                        <b-icon
                                                                            icon="arrow-return-right"
                                                                            class="icon-coapp mr-2"
                                                                            scale="1"
                                                                        ></b-icon>
                                                                        {{ resource.Name }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="align-top p-2 grey-text">
                                                            <div v-if="resourceGroup.CashCredits">
                                                                {{
                                                                    resourceGroup
                                                                        ? getFormattedPrice(resourceGroup.CreditCents)
                                                                        : 0
                                                                }}
                                                                {{ plan.Currency }}
                                                            </div>
                                                            <div v-else>{{ resourceGroup.CreditHours }} hours</div>
                                                        </td>
                                                        <td
                                                            v-if="resourceGroup.OverridePrice"
                                                            class="align-top p-2 grey-text"
                                                        >
                                                            {{
                                                                resourceGroup
                                                                    ? getFormattedPrice(resourceGroup.PriceInCents)
                                                                    : 0
                                                            }}€
                                                        </td>
                                                        <td v-else class="align-top p-2 grey-text">
                                                            using resource prices
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                    <!-- </div> -->
                </b-overlay>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped src="@/assets/less/manage-space/base.less">
@import '~@/assets/less/variables.less';

.h-70 {
    height: 70 v !important;
}

.bg-white {
    background-color: #ffffff;
}

.title {
    color: grey;
    padding: 0.5;
    font-weight: 300;
}

.pt-c {
    padding-top: 3px;
}

.grey {
    font-size: 15px;
    color: grey;
    font-weight: 300;
    padding-top: 3px;
}

.block {
    height: 70vh !important;
    overflow: auto;
}

/deep/ .d-action a {
    color: @purple !important;
}

/deep/ .d-link a {
    color: @red !important;
}
</style>

<script>
import axios from 'axios';
import Vue from 'vue';
import EventBus from '@/eventBus';
import { defaultTo } from 'lodash';

export default {
    name: 'ViewPlan',
    data() {
        return {
            tabIndex: 0,
            tabIndexModal: 0,
            image: null,
            imageURL: '',
            cycleDuration: '1',
            loading: false,
            price: '',
            plan: null,

            FinalPrice: null,
            taxRate: null,
            taxRates: [],

            loadingTaxRate: false,

            fields: [
                {
                    key: 'Resource.Name',
                    label: 'Name',
                    sortable: true,
                },
                {
                    key: 'Selected',
                    label: 'Selected',
                    sortable: true,
                },
                {
                    key: 'ID',
                    label: 'ID',
                    sortable: true,
                },
            ],

            fieldsResources: [
                {
                    key: 'Name',
                    label: 'Name',
                    sortable: true,
                },
                {
                    key: 'Price',
                    label: 'Price',
                    sortable: true,
                },
                {
                    key: 'CreditHours',
                    label: 'Credits',
                    sortable: true,
                },
            ],

            rerenderKey: 4567,
            selectedResourceGroups: [],
            selectedResources: [],
        };
    },
    created() {
        const { ID } = this.$route.params;
        if (this.$route.query.tabIndex) {
            this.tabIndex = parseInt(this.$route.query.tabIndex);
        }
        this.$store
            .dispatch('getPlanByID', ID)
            .then((response) => {
                this.plan = response;
                this.getTaxRates();
                this.loading = false;
                if (response.AvailableResources) {
                    const tmp = response.AvailableResources.map((item) => {
                        if (item.PriceInCents > 0) {
                            item.Price = item.PriceInCents / 100;
                        } else {
                            item.PriceInCents = 0.0;
                            item.Price = 0.0;
                        }
                        if (!item.CreditHours) {
                            item.CreditHours = 0;
                        }
                        return item;
                    });
                    this.selectedResources = tmp;
                }
                if (response.AvailableResourceGroups) {
                    const tmp = response.AvailableResourceGroups.map((item) => {
                        if (item.PriceInCents > 0) {
                            item.Price = item.PriceInCents / 100;
                        } else {
                            item.PriceInCents = 0.0;
                            item.Price = 0.0;
                        }
                        if (!item.CreditHours) {
                            item.CreditHours = 0;
                        }
                        return item;
                    });
                    this.selectedResourceGroups = tmp;
                }
            })
            .catch((error) => {
                if (error.message.includes('404')) {
                    this.$router.replace('/feed/error404');
                }
            });
    },

    computed: {
        name() {
            return this.plan.Name;
        },
    },

    methods: {
        getFormattedPrice(priceInCents) {
            if (!priceInCents) {
                return 0;
            }

            const price = priceInCents / 100;

            return price.toFixed(2);
        },

        centsToPrice(price) {
            let priceStr = '';
            price ? (priceStr = (Number(price) / 100).toFixed(2)) : (priceStr = (0).toFixed(2));
            return priceStr;
        },
        back() {
            this.tabIndexModal -= 1;
        },
        linkClass(tabIndex, idx) {
            if (tabIndex === idx) {
                return ['activeOne'];
            }
            return ['not-activeOne'];
        },

        getTaxRates() {
            this.loadingTaxRate = true;
            axios({
                method: 'GET',
                url: `/admin/tax-rate/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.taxRates = response.data;
                    }
                    this.calculateFinalPrice();
                    this.loadingTaxRate = false;
                })
                .catch((error) => {
                    this.loadingTaxRate = false;
                    console.log(error);
                });
        },
        calculateFinalPrice() {
            this.taxRate = this.taxRates.find((item) => item.ID === this.plan.TaxRateID);
            if (this.taxRate && this.taxRate.Inclusive) {
                this.FinalPrice = this.plan.PriceInCents / 100.0;
            } else if (this.taxRate) {
                this.FinalPrice = ((this.plan.PriceInCents / 100.0) * (this.taxRate.Percentage / 100 + 1)).toFixed(2);
            } else {
                this.FinalPrice = this.plan.PriceInCents / 100.0;
            }
        },
    },
};
</script>
