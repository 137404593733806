import { render, staticRenderFns } from "./co-feed.vue?vue&type=template&id=5e58738c&scoped=true&"
import script from "./co-feed.vue?vue&type=script&lang=ts&"
export * from "./co-feed.vue?vue&type=script&lang=ts&"
import style0 from "./co-feed.vue?vue&type=style&index=0&id=5e58738c&prod&lang=less&scoped=true&"
import style1 from "./co-feed.vue?vue&type=style&index=1&id=5e58738c&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e58738c",
  null
  
)

export default component.exports