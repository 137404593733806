














































import axios from 'axios';
import CoTag from '../../Atoms/co-tag/CoTag.vue';
import CoInput from '../../Molecules/co-input/CoInput.vue';
import CoInputWithAutoComplete from '../co-input-with-autocomplete/CoInputWithAutoComplete.vue';

export default {
    name: 'CoTagList',
    components: {
        CoTag,
        CoInputWithAutoComplete,
        CoInput,
    },
    props: {
        tags: {
            type: Array,
            default: null,
        },
        placeholder: {
            type: String,
            default: '',
        },
        editable: Boolean,
        replaceWhiteSpacesInInput: {
            type: Boolean,
            default: true,
        },
        noSuggestions: {
            type: Boolean,
            default: false,
        },
        inputValidator: {
            type: Function, // must return true || false
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            newTags: this.tags ? this.tags : [],
            inputValue: null,
        };
    },
    computed: {
        inputIsValid() {
            if (this.inputValidator) {
                return this.inputValue ? this.inputValidator(this.inputValue) : false;
            }
        },
    },
    methods: {
        tagsAutocompleteMethod(searchValue) {
            return new Promise((resolve, reject) => {
                if (!searchValue || searchValue.length < 1) {
                    resolve(null);
                    return true;
                }
                axios({
                    method: 'GET',
                    url: `/tags/search?term=${searchValue}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        if (Array.isArray(response.data)) {
                            const items = response.data.map((el) => el.Value);
                            resolve(items);
                        } else {
                            // handle empty search results
                            resolve(null);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        addTag(event) {
            event && typeof event === 'string' ? (this.inputValue = event) : null;

            if (!this.inputValue) return;

            if (this.inputValidator && !this.inputIsValid) {
                this.$emit('invalid', this.inputValue);
                return false;
            }

            const newValue = this.replaceWhiteSpacesInInput
                ? this.inputValue.trim().replace(/[\s]+/gi, '-').toLowerCase()
                : this.inputValue;

            if (newValue.length < 1) return;

            if (this.newTags.length < 1) {
                this.newTags = [newValue];
            } else {
                this.newTags.push(newValue);
            }
            this.$refs.coInput.resetValue();
            this.$emit('tagsChanged', this.newTags);
        },
        removeTag(tag) {
            const index = this.newTags.indexOf(tag);
            if (index > -1) {
                this.newTags.splice(index, 1);
            }
            this.$emit('tagsChanged', this.newTags);
        },
        tagClicked(event) {
            this.$emit('tagClicked', event);
        },
        handleInput(value) {
            this.$emit('input', value);
            this.inputValue = value;
        },
    },
};
