<style lang="less" scoped>
@import '~@/assets/less/manage-space/base.less';

.form-control {
    border: none !important;
    border-bottom: 1px solid @coapp-darkgrey !important;
    &:focus {
        border: none !important;
        outline: none !important;
        border-bottom: 1px solid @purple !important;
    }
}

.bootstrap-datetimepicker-widget {
    top: auto !important;
    bottom: auto !important;
}
</style>

<template>
    <div>
        <div class="px-sm-4">
            <b-overlay :show="loading" class="">
                <div class="bg-white coapp-rounded px-3 py-3">
                    <b-form ref="new-identity-form" class="col col-md-6 pl-2">
                        <b-form-group
                            id="input-group-user"
                            label="User (optional)"
                            label-for="input-user"
                            label-class="m-0 p-0"
                        >
                            <b-form-input
                                v-if="selectedUser"
                                id="input-user"
                                ref="input-user"
                                v-model="selectedUser.Profile.Name"
                                type="text"
                                class="underline-input px-0 py-2"
                                @click="showSelectUserModal"
                            ></b-form-input>
                            <b-form-input
                                v-else
                                id="input-user"
                                ref="input-user"
                                type="text"
                                placeholder="Select user"
                                class="underline-input px-0 py-2"
                                @click="showSelectUserModal"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-first-name"
                            label="First Name"
                            label-for="input-first-name"
                            label-class="m-0 p-0"
                        >
                            <b-form-input
                                id="input-first-name"
                                v-model="identity.FirstName"
                                type="text"
                                required
                                placeholder="Enter user first name"
                                class="underline-input px-0 py-2"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-last-name"
                            label="Last Name"
                            label-for="input-last-name"
                            label-class="m-0 p-0"
                        >
                            <b-form-input
                                id="input-last-name"
                                v-model="identity.LastName"
                                type="text"
                                required
                                placeholder="Enter user last name"
                                class="underline-input px-0 py-2"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-email"
                            label="Email"
                            label-for="input-email"
                            label-class="m-0 p-0"
                        >
                            <b-form-input
                                id="input-email"
                                v-model="identity.Email"
                                type="email"
                                required
                                placeholder="Enter email"
                                class="underline-input px-0 py-2"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-source"
                            label="Source"
                            label-for="input-source"
                            label-class="m-0 p-0"
                        >
                            <div class="underline-input px-0 py-2">
                                <p class="mb-0">Deister</p>
                            </div>
                        </b-form-group>

                        <b-form-group
                            id="input-group-valid-from-date"
                            label="Valid From"
                            label-for="input-valid-from-date"
                            label-class="m-0 p-0"
                        >
                            <b-form-datepicker v-model="ValidFrom" class="mb-2"></b-form-datepicker>
                        </b-form-group>

                        <b-form-group
                            id="input-group-valid-to-date"
                            label="Valid To"
                            label-for="input-valid-to-date"
                            label-class="m-0 p-0"
                        >
                            <b-form-datepicker v-model="ValidTo" class="mb-2"></b-form-datepicker>
                        </b-form-group>
                    </b-form>

                    <div class="pt-2">
                        <b-button type="submit" variant="primary" class="mr-2" @click="createIdentity">
                            Create
                        </b-button>
                        <b-button type="submit" variant="secondary" @click="cancel"> Cancel </b-button>
                    </div>
                </div>
            </b-overlay>
        </div>
        <!-- Forms end -->

        <b-modal
            id="modal-select-user"
            ref="modal-select-user"
            title="Select user linked with identity"
            centered
            hide-footer
        >
            <form ref="" @submit.stop.prevent="">
                <b-form-group label="Search user" label-for="name-input" invalid-feedback="Type username here">
                    <b-form-input
                        id="name"
                        class="underline-input px-0 py-2"
                        v-model="searchInput"
                        placeholder="Type user name"
                        required
                        @input="searchUsers(searchInput)"
                    ></b-form-input>
                </b-form-group>
                <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
                    <b-table
                        striped
                        hover
                        :items="searchResult"
                        :fields="fields"
                        :current-page="currentPage"
                        sort-icon-left
                        :per-page="perPage"
                        stacked="md"
                        primary-key="Name"
                        class="table table-borderless table-responsive w-100 d-md-table"
                    >
                        <template v-slot:cell(Name)="row">
                            <div class="d-flex align-items-center">
                                <div>
                                    <ProfileCircle
                                        :ImageURL="row.item.Profile.ImageURL"
                                        :Slug="row.item.Slug"
                                    ></ProfileCircle>
                                </div>
                                <div class="ml-3">
                                    <router-link style="color: #212529" :to="'/profile/' + row.item.Slug">{{
                                        row.item.Profile.Name
                                    }}</router-link>
                                </div>
                            </div>
                        </template>

                        <template v-slot:cell(Actions)="row">
                            <b-button
                                v-if="selectedUser && row.item.ID === selectedUser.ID"
                                variant="primary"
                                @click="unselectUser(row.item)"
                            >
                                Unselect</b-button
                            >
                            <b-button v-else @click="selectUser(row.item)" class="mr-2 primary">Select</b-button>
                        </template>
                    </b-table>
                    <p v-if="searchInput !== '' && searchResult.length === 0">
                        No users matching your search input found
                    </p>
                </b-overlay>
            </form>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';
import EventBus from '@/eventBus.js';

export default {
    name: 'IdentityCreate',
    data() {
        return {
            loading: false,
            identity: {
                FirstName: '',
                LastName: '',
                Email: '',
                Source: 0,
                ValidFrom: null,
                ValidTo: null,

                Tags: [],
                Policy: [],
            },
            ValidFrom: null,
            ValidTo: null,
            searchInput: '',
            searchResult: [],
            fields: [
                {
                    key: 'Name',
                    label: 'Name',
                    sortable: true,
                },
                {
                    key: 'Actions',
                    label: '',
                },
            ],
            perPage: 10,
            currentPage: 1,
            totalRows: 0,

            selectedUser: null,
        };
    },
    methods: {
        checkFormValidity() {
            const valid = this.$refs['new-identity-form'].checkValidity();
            return valid;
        },

        createIdentity() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                this.$refs['new-identity-form'].reportValidity();
                return;
            }

            this.loading = true;
            const data = this.identity;
            if (this.selectedUser) {
                data.UserID = this.selectedUser.ID;
            }

            if (this.ValidFrom) {
                var date = Date.parse(this.ValidFrom);
                data.ValidFrom = date;
            }

            if (this.ValidTo) {
                var date = Date.parse(this.ValidTo);
                data.ValidTo = date;
            }

            axios
                .post('/admin/space/access/identity', this.identity)
                .then(() => {
                    EventBus.$emit('INFO', {
                        Message: `Identity for ${data.FirstName} created`,
                    });
                    this.$router.push({ name: 'Identities' });
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: "Can't create identity",
                        Details: error,
                    });
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        cancel() {
            this.$router.push({ name: 'Identities' });
        },

        showSelectUserModal() {
            if (this.selectedUser) {
                this.searchResult.push(this.selectedUser);
            }
            this.$refs['modal-select-user'].show();
        },
        unselectUser() {
            this.selectedUser = null;
            this.searchInput = '';
            this.searchResult = [];
            this.$refs['input-user'].blur();
            this.$refs['modal-select-user'].hide();
        },

        selectUser(item) {
            this.selectedUser = item;
            this.identity.Email = item.Email;
            if (item.Profile) {
                this.identity.FirstName = item.Profile.FirstName;
                this.identity.LastName = item.Profile.LastName;
            }
            this.searchInput = '';
            this.searchResult = [];
            this.$refs['input-user'].blur();
            this.$refs['modal-select-user'].hide();
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        /*
         * Search users by name
         */
        searchUsers(input) {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/member/search/${input}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data && response.data.objects) {
                        this.searchResult = response.data.objects.map((item) => item.object);
                    } else {
                        this.searchResult = [];
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    this.searchResult = [];
                });
        },
    },
};
</script>
