





































































interface Suggestion {
    label: String;
    icon?: String;
    thumbnail?: String; // not yet supported
    disabled?: Boolean; // optional flag
    value?: any; // payload can be anything
}

import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoLabel from '../../Atoms/co-label/CoLabel.vue';
import CoDropdown from '../co-dropdown/CoDropdown.vue';
import CoDropdownItem from '../co-dropdown-item/CoDropdownItem.vue';

import axios from 'axios';
import { get, debounce } from 'lodash';
import { format } from 'date-fns';

export default {
    name: 'CoInput',
    components: {
        CoIcon,
        CoLabel,
        CoDropdown,
        CoDropdownItem,
    },
    props: {
        label: {
            type: String,
            default: null,
        },
        value: {
            type: [String, Number, Boolean, null, undefined],
            default: null,
        },

        type: {
            type: String,
            default: 'text',
        },
        // used only for type number and date
        min: {
            type: [Number, String],
            default: 0,
        },
        max: {
            type: [Number, String],
            default: Number.MAX_SAFE_INTEGER,
        },
        // used only for type number
        step: {
            type: Number,
            default: 1,
        },
        placeholder: String,
        readonly: Boolean,
        disabled: Boolean,
        icon: String,
        greyTheme: Boolean,
        reduce: Boolean,
        width: {
            type: String,
            default: '100%',
        },
        background: {
            type: String,
            default: 'white',
            validator: (value) => ['white', 'transparent'].includes(value),
        },
        variant: {
            type: String,
            default: 'default',
        },
        validator: {
            type: Function,
            default: null,
        },
        noBorder: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: null,
        },
        enterkeyhint: {
            type: String,
            default: 'send', // supported values https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/enterkeyhint
        },
        suggester: {
            type: Function,
            default: null, // function must return a promise and return an Array of type Suggestions
        },
        resetonselect: {
            type: Boolean, // reset input value after selecting a suggestion
            default: false,
        },
    },
    data() {
        return {
            content: this.value,
            windowWidth: 0,
            isMobile: false,
            activeSuggester: null,
            suggestionsLoading: false,
            suggestions: [],
            source: null,
            selectedItem: -1,
        };
    },
    computed: {
        isValid() {
            if (this.validator) {
                return this.validator(this.content);
            }
            return undefined;
        },
        inputValue() {
            return this.content;
        },
    },
    watch: {
        content(newVal) {
            this.$emit('input', newVal);
            if (this.suggester) {
                this.handleSuggestions(newVal);
            }
        },
        value(newVal) {
            this.content = newVal;
        },
    },
    mounted() {
        this.windowWidth = window.innerWidth;
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;

            if (this.windowWidth <= 992) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        });

        this.$nextTick(() => {
            this.content = this.value;
        });
    },
    methods: {
        get,
        processValue(input) {
            let v = input;
            if (this.type === 'number') {
                if (v === '') {
                    return this.min;
                }

                // if v has any letter, remove it
                if (v.match(/[a-z]/i)) {
                    v = v.replace(/[a-z]/i, '');
                }

                // if v contains a comma, replace it with a dot
                if (v.includes(',')) {
                    v = v.replaceAll(',', '.');
                }
                // if v has more than one dot, remove all dots except the first one
                if (v.split('.').length > 2) {
                    v = v.replaceAll(/\.(?=.*\.)/g, '');
                }
                // if v has more than one minus, remove all minuses except the first one
                if (v.split('-').length > 2) {
                    v = v.replaceAll(/-(?=.*-)/g, '');
                }

                // if there space in the string, remove it
                if (v.includes(' ')) {
                    v = v.replaceAll(' ', '');
                }

                const numberV = parseFloat(v);

                if (numberV < this.min) {
                    return this.min;
                }

                if (numberV > this.max) {
                    return this.max;
                }

                const step = parseFloat(this.step);
                let rest = numberV % step;
                // round rest to step
                if (rest !== 0) {
                    rest = parseFloat(rest.toFixed(10));
                }
                // if step defined, round to step
                if (this.step && rest !== 0) {
                    if (this.step === 1) {
                        return Math.trunc(numberV);
                    }

                    const rounded = Math.round(numberV / step) * step;
                    return rounded;
                }

                return numberV;
            }
            if (this.type === 'date') {
                //  parse date to date format
                //  if min and max are defined, check if date is between min and max and return min or max if not

                if (this.min) {
                    const minDate = new Date(this.min);
                    const date = new Date(v);
                    if (date < minDate) {
                        const mindateasstring = format(minDate, 'yyyy-MM-dd');
                        return mindateasstring;
                    }
                }

                if (this.max) {
                    const maxDate = new Date(this.max);
                    const date = new Date(v);
                    if (date > maxDate) {
                        // convert max date to string in format yyyy-mm-dd
                        const maxdateasstring = format(maxDate, 'yyyy-MM-dd');
                        return maxdateasstring;
                    }
                }

                return v;
            }
            return v;
        },
        resetValue() {
            this.content = null;
        },
        handleInput(e) {
            if (!e.target.value) {
                this.$emit('input', undefined);
                return;
            }
            switch (this.type) {
                case 'number':
                    this.content = this.processValue(e.target.value);
                    break;
                default:
                    this.content = e.target.value;
            }
            this.$emit('input', this.content);
        },
        handleChange(e) {
            switch (this.type) {
                case 'number':
                    this.content = this.processValue(e.target.value);
                    break;
                default:
                    this.content = e.target.value;
            }

            this.$emit('change', this.content);
        },
        handleFocus(e) {
            this.$emit('focus');
        },
        handleEnter(e) {
            this.$emit('enter', this.content);
        },
        handleKeyDown(e) {
            //propagate the keydown event
            this.$emit('keydown', e);

            //handle the keydown event
            const key = get(e, 'key', '');
            switch (key) {
                case 'Enter':
                    this.handleSelect(get(this.suggestions, `[${this.selectedItem}]`, null));
                    break;
                case 'ArrowDown':
                    if (this.selectedItem < this.suggestions.length - 1) {
                        this.selectedItem++;
                    } else {
                        this.selectedItem = 0;
                    }
                    break;
                case 'ArrowUp':
                    if (this.selectedItem > 0) {
                        this.selectedItem--;
                    } else {
                        this.selectedItem = this.suggestions.length - 1;
                    }
                    break;
                default:
                    break;
            }
        },
        handleBlur(e) {
            switch (this.type) {
                case 'number':
                    this.content = this.processValue(e.target.value);
                    break;
                case 'date':
                    this.content = this.processValue(e.target.value);
                    break;
                default:
                    this.content = e.target.value;
            }
            this.$emit('change', this.content);
            this.$emit('blur', this.content);
        },
        handleSuggestions(e) {
            //cancel previous suggester runs
            if (this.activeSuggester && this.activeSuggester.cancel) {
                this.activeSuggester.cancel();
            }
            // define debounced suggester
            this.activeSuggester = debounce(
                async function (e) {
                    if (!this.suggester || !e || e.length < 1) {
                        this.suggestions = [];
                        this.suggestionsLoading = false;
                        return;
                    }

                    // cancel previous suggester runs (the message parameter is optional)
                    this.source ? this.source.cancel('Operation canceled by the user.') : null;
                    this.source = axios.CancelToken.source();
                    //
                    this.suggestionsLoading = true;
                    try {
                        this.suggestions = await this.suggester(e, this.source);
                        this.$emit('suggestions', this.suggestions);
                        this.suggestionsLoading = false;
                    } catch (error) {
                        if (!axios.isCancel(error)) {
                            console.warn('error fetching suggestions', error);
                            this.suggestionsLoading = false;
                        }
                    }
                },
                750,
                { leading: true, trailing: true }
            );
            // run debounced suggester
            this.activeSuggester(e);
        },
        handleSelect(suggestion) {
            if (!suggestion) return;
            this.$emit('select', suggestion);

            this.selectedItem = -1;

            this.resetonselect ? (this.content = '') : (this.content = get(suggestion, 'label', ''));
        },
        focus() {
            this.$refs['coInputElem'].focus();
        },
        blur() {
            this.$refs['coInputElem'].blur();
        },
        clear() {
            this.content = '';
        },
    },
};
