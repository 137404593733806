import { render, staticRenderFns } from "./CoImage.vue?vue&type=template&id=4cb18ba4&scoped=true&"
import script from "./CoImage.vue?vue&type=script&lang=ts&"
export * from "./CoImage.vue?vue&type=script&lang=ts&"
import style0 from "./CoImage.vue?vue&type=style&index=0&id=4cb18ba4&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cb18ba4",
  null
  
)

export default component.exports