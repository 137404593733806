






















































































































































































































































import EventBus from '../../../eventBus';
import axios from 'axios';

interface Resource {
    Name: String;
    Description: String;
    Location: String;
    ImageURL: String;
    Color: String;
    PriceInCents: Number;
    Currency: String;
    PriceInterval: String;
    Hidden: Boolean;
    BookingInterval: Boolean;
    FixedTimeSpan: Boolean;
}

export default {
    name: 'CoCreateResourceBookingPage',
    props: {
        resource: {
            type: (Object as () => Resource) || null,
            default: null,
            required: true,
        },
    },
    data() {
        return {
            plan: null,
            me: this.$store.state.me,
            imageURL: '',
            changedCategories: [],
            categories: [],
            color: 'black',
            hideResource: false,
            windowWidth: window.innerWidth,
            minWidth: true,

            loading: false,
            locations: [],
            location: null,
            intervals: [0],
            exceptions: [],
            exportIntervals: [
                {
                    Weekdays: [0, 1, 2, 3, 4],
                    From: '17:00',
                    To: '10:00',
                    ID: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
                },
            ],

            timeSpan: false,
            bookingInterval: true,

            id: this.$route.params.ID, // current or upcoming
        };
    },
    created() {},

    mounted() {
        this.listLocations();
        this.listCategories();

        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;

            if (this.windowWidth >= 768) {
                this.minWidth = true;
            } else {
                this.minWidth = false;
            }
        });
    },
    watch: {
        bookingInterval: function (newVal) {
            this.timeSpan = !newVal;
            this.resource.BookingInterval = newVal;
        },
        timeSpan: function (newVal) {
            this.bookingInterval = !newVal;
            this.resource.FixedTimeSpan = newVal;
        },
    },
    methods: {
        categoriesChanged(categories) {
            this.changedCategories = categories;
        },
        selectLocation(event) {
            this.location = event;
        },
        searchLocation(e) {
            return new Promise((resolve, reject) => {
                var items = this.locations;

                items.filter((l) => items.includes(e));

                resolve(items);
            });
        },
        listCategories() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/booking/v2/category/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data) {
                        this.categories = response.data;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        listLocations() {
            this.$store
                .dispatch('listLocations')
                .then((locations) => {
                    this.locations = locations;
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        selectColor(color) {
            this.color = color;
        },
        addInterval() {
            const ID = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

            this.exportIntervals.push({
                Weekdays: [0, 1, 2, 3, 4],
                From: '17:00',
                To: '10:00',
                ID: ID,
            });
        },
        removeInterval(index) {
            this.exportIntervals.splice(index, 1);
        },
        selectDays(e, index) {
            this.exportIntervals[index].Weekdays = e;
        },
        selectEndTime(e, index) {
            this.exportIntervals[index].From = e;
        },
        selectStartTime(e, index) {
            this.exportIntervals[index].To = e;
        },
        selectIntervalTimeFrame(e) {
            this.resource.BookingIntervalTimeFrame = e;
        },
        selectMinBookingTimeFrame(e) {
            this.resource.MinBookingTimeFrame = e;
        },
        selectMaxBookingTimeFrame(e) {
            this.resource.MaxBookingTimeFrame = e;
        },
        selectCancelationTimeFrame(e) {
            this.resource.CancelationTimeFrame = e;
        },
        addException(index) {
            const exception = {
                ID: '',
            };
            const ID = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            exception.ID = ID;

            if (!index) {
                this.exceptions.push(exception);
            } else {
                this.exceptions.push(exception);
            }
        },
        removeException(index) {
            this.exceptions.splice(index, 1);
        },
    },
};
