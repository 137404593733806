




















































































































































































































































































































































import Vue from 'vue';
import moment from 'moment';
import axios from 'axios';
import { google, outlook, office365, yahoo, ics } from 'calendar-link';
import { getUnixTime, differenceInMinutes, sub, setHours, setMinutes, parseISO, isBefore, format, add } from 'date-fns';
import EventBus from '../../eventBus';
import CoTextArea from '../Molecules/co-TextArea/CoTextArea.vue';

interface bookingRequest {
    start: number;
    end: number;
    resourceid: string;
    couponIDs: string[];
}

export default {
    name: 'BookingModalV2',
    components: {
        CoTextArea,
    },

    data() {
        return {
            bookingStartString: '00:00',
            bookingEndString: '00:00',
            bookingStartOptions: [],
            bookingEndOptions: [],
            booking: {
                Title: `${this.$store.state.me.Profile.Name}'s booking`,
                Comment: '',
            },
            loading: false,
            success: false,
            error: false,

            valid: true,

            tooShort: false,
            tooLong: false,

            // modal visibility state
            visible: false,

            acceptedTerms: false,

            couponID: '',
            coupon: null,

            paymentMethods: [],

            bookingPrice: null,
            bookingPriceLoading: false,
            billingOn: this.$store.state.billingTurnedOn,
        };
    },
    props: ['timeslot', 'resource', 'space', 'title', 'taxRate', 'beginHourString'],

    mounted() {
        if (this.billingOn) {
            this.calculatePrice();
            this.getPaymentMethods().then((response) => {
                this.paymentMethods = response;
            });
        }
    },
    watch: {
        visible(newVal, oldVal) {
            if (newVal) {
                this.success = false;
                this.error = false;
                this.loading = false;
                this.calculatePrice();
            } else {
                this.booking = {
                    Title: `${this.$store.state.me.Profile.Name}'s booking`,
                };
                this.bookingStartString = '00:00';
                this.bookingEndString = '00:00';
                this.bookingStartOptions = [];
                this.bookingEndOptions = [];
                this.couponID = '';
                this.coupon = null;
                this.acceptedTerms = false;
                this.paymentMethods = [];
                this.bookingPrice = null;
                this.bookingPriceLoading = false;

                this.$emit('close');
            }
        },
        timeslot(newVal, oldVal) {
            if (newVal) {
                this.parseChoosenSlotData();
                this.calculatePrice();
                this.visible = true;
            }
        },
    },
    created() {
        // run this.getPaymentMethods() every 5 seconds
        const self = this;
        if (self.billingOn) {
            setInterval(() => {
                if (self.paymentMethods.length === 0) {
                    self.getPaymentMethods()
                        .then((response) => {
                            self.paymentMethods = response;
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            }, 1000);
        }
    },
    methods: {
        close() {
            this.booking = {
                Title: `${this.$store.state.me.Profile.Name}'s booking`,
            };
            this.bookingStartString = '00:00';
            this.bookingEndString = '00:00';
            this.bookingStartOptions = [];
            this.bookingEndOptions = [];
            this.couponID = '';
            this.coupon = null;
            this.acceptedTerms = false;
            this.paymentMethods = [];
            this.bookingPrice = null;
            this.bookingPriceLoading = false;

            this.$emit('close');
            this.visible = false;
        },
        addPaymentMethod() {
            window.open('/account/billing/add-payment-method?closeAfterSuccess=true', '_blank');
        },

        // getPaymentMethods  with async/await
        async getPaymentMethods() {
            try {
                const paymentMethods = await this.$store.dispatch('getPaymentMethods');
                if (paymentMethods && paymentMethods.length > 0) {
                    this.paymentMethods = paymentMethods;
                    return paymentMethods;
                }
                this.paymentMethods = [];
                return [];
            } catch (error) {
                this.paymentMethods = [];
                console.error(error);
                return [];
            }
        },

        applyCoupon() {
            if (this.couponID === '') {
                return;
            }
            axios
                .get(`/space/coupon/${this.couponID}`)
                .then((res) => {
                    this.coupon = res.data;
                    this.calculatePrice();
                })
                .catch((err) => {
                    this.coupon = null;
                    EventBus.$emit('ERROR', {
                        Message: 'Coupon code is not valid',
                    });
                    console.log(err);
                });
        },
        updateCoupon() {
            if (!this.couponID) {
                this.coupon = null;
                this.calculatePrice();
            }
        },

        removeCoupon() {
            this.coupon = null;
            this.couponID = '';
            this.calculatePrice();
        },

        termsAccepted() {
            if (this.resource.TermsAndConditionsLink) {
                return this.acceptedTerms;
            }

            return true;
        },
        interval() {
            if (this.$store.state.space.ID === 'localhost:8080') {
                return 60;
            }
            if (
                this.$store.state.space.ID === 'c97c5a2253a56e8e9027881658c37c54a458bd1a2b5d59a81bacde6a52f42af5' &&
                this.resource.CobotID === '84c900fb6d352df1b1cbc63bac7a3979'
            ) {
                return 60;
            }
            if (
                this.$store.state.space.ID ===
                'hwMwnRdbGeXIido_GODOx_gqSSs4sMMoORTPY7dTJLz7UJCQ4NcwVTSpf0_yLuTiN0ivjTW57YxcjIFKsvRbsQ=='
            ) {
                return 60;
            }
            return 30;
        },

        // convert cents to euro/dollar/...
        convertPrice(price: number) {
            if (!price) {
                return 0;
            }

            return (price / 100).toFixed(2);
        },

        previewBookingPrice(req: bookingRequest) {
            if (!this.billingOn) {
                return;
            }
            this.bookingPriceLoading = true;

            axios({
                method: 'POST',
                url: `/booking/preview`,
                withCredentials: true,
                data: req,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.bookingPrice = response.data;
                })
                .catch((error) => {
                    console.log(error);
                    EventBus.$emit('ERROR', {
                        Message:
                            'Could not calculate price. Please, make sure you have a valid membership and payment method.',
                    });
                })
                .finally(() => {
                    this.bookingPriceLoading = false;
                });
        },

        // calculatePrice calculates price
        calculatePrice() {
            if (!this.billingOn) {
                return;
            }
            const timeStart = this.bookingStartString.split(':');
            const startHours = parseInt(timeStart[0], 10);
            const startMinutes = parseInt(timeStart[1], 10);
            const timeEnd = this.bookingEndString.split(':');
            const endHours = parseInt(timeEnd[0], 10);
            const endMinutes = parseInt(timeEnd[1], 10);
            // keep day information, but update hours and minutes with choosen time from select
            if (!this.booking.From || !this.booking.To) {
                return;
            }

            const from = this.booking.From;
            from.setHours(startHours);
            from.setMinutes(startMinutes);

            const to = new Date(this.booking.From);
            to.setHours(endHours);
            to.setMinutes(endMinutes);
            // get duration in minutes
            const diffTime = Math.abs(to.getTime() - from.getTime());

            const durationInMinutes = Math.ceil(diffTime / (1000 * 60));

            this.tooShort = false;
            this.tooLong = false;
            if (
                this.resource.MinBookingDuration != null &&
                this.resource.MinBookingDuration !== 0 &&
                this.resource.MinBookingDuration > durationInMinutes &&
                this.resource.BookWholeSlot === false
            ) {
                this.tooShort = true;
                return;
            }

            if (
                this.resource.MaxBookingDuration != null &&
                this.resource.MaxBookingDuration !== 0 &&
                this.resource.MaxBookingDuration < durationInMinutes &&
                this.resource.BookWholeSlot === false
            ) {
                this.tooLong = true;

                return;
            }

            // get unix time for start and end
            const start = from.getTime() / 1000;
            const end = to.getTime() / 1000;
            // call price preview
            const req: bookingRequest = {
                start,
                end,
                resourceid: this.resource.ID,
                couponIDs: [],
            };
            if (this.coupon) {
                req.couponIDs.push(this.coupon.ID);
            }

            this.previewBookingPrice(req);
        },

        getRoundedPrice(price) {
            if (!price) {
                return 0;
            }
            // parse price to float
            const fprice = parseFloat(price);
            return fprice.toFixed(2);
        },

        closeBookingModal() {
            this.loading = false;
            this.$bvModal.hide('makebookingmodal');
        },
        setToTime() {
            this.calculatePrice();
        },
        updateToSelect() {
            // clear booking End Options
            this.bookingEndOptions = [];

            // get end time of slot with day information (day is necessary for handling 00:00 comparisons)
            const slotEndTime = moment(this.timeslot.To);

            const chosenBeginTime = moment(this.bookingStartString, 'HH:mm');

            // keep day information, but update hours and minutes with choosen time from select
            const finalBeginTime = moment(this.timeslot.From)
                .set('hour', chosenBeginTime.hours())
                .set('minute', chosenBeginTime.minutes());
            const duration = moment.duration(slotEndTime.diff(finalBeginTime)).asMinutes();
            if (duration <= this.interval() || (this.resource.BookWholeSlot && !this.resource.PricePerSlot)) {
                this.bookingEndString = slotEndTime.format('HH:mm');
                this.bookingEndOptions.push(this.bookingEndString);

                this.calculatePrice();

                return;
            }

            // min booking time = start + 15 mins

            let beginTimeToSelect = moment(finalBeginTime).add(this.interval(), 'minute');

            // if MinBookingDuration not equal 0 change beginTimeToSelect to start time + min duration also reset bookingEndString to the same point
            if (this.resource.MinBookingDuration != null && this.resource.MinBookingDuration != 0) {
                beginTimeToSelect = moment(finalBeginTime).add(this.resource.MinBookingDuration, 'minute');

                if (
                    duration >= 60 &&
                    this.resource.MinBookingDuration <= 60 &&
                    this.resource.MaxBookingDuration >= 60
                ) {
                    const booking1HDuration = moment(finalBeginTime).add(1, 'hour');
                    this.bookingEndString = booking1HDuration.format('HH:mm');
                } else {
                    this.bookingEndString = beginTimeToSelect.format('HH:mm');
                }
            } else if (duration >= 60) {
                this.bookingEndString = moment(finalBeginTime).add(1, 'hour').format('HH:mm');
            } else {
                this.bookingEndString = slotEndTime.format('HH:mm');
            }

            let loopHour = beginTimeToSelect.toDate();
            const interval = this.interval();

            while (isBefore(loopHour, slotEndTime.toDate())) {
                const str = format(loopHour, 'HH:mm');
                this.bookingEndOptions.push(str);
                loopHour = add(loopHour, { minutes: interval });
            }

            this.calculatePrice();
        },
        parseChoosenSlotData() {
            // initial setup on changed time slot data
            // set up the booking params
            this.booking = JSON.parse(JSON.stringify(this.timeslot)); // make copy of timeslot object
            this.booking.From = this.timeslot.From.toDate(); // copy of moment object
            this.booking.To = this.timeslot.To.toDate(); // copy of moment object
            this.bookingStartString = this.beginHourString;

            // update From-Select. max begin time = slot end time - 15 mins
            this.bookingStartOptions = [];

            const interval = this.interval();
            // eslint-disable-next-line vue/no-mutating-props

            // const to = sub(this.booking.To, { minutes: interval });

            let loopHour = this.booking.From;

            while (isBefore(loopHour, this.booking.To)) {
                const str = format(loopHour, 'HH:mm');
                this.bookingStartOptions.push(str);
                loopHour = add(loopHour, { minutes: interval });
            }
            // sort bookingStartOptions in alphabetical order

            // update To-Select
            this.updateToSelect();

            this.booking.Title = `${this.$store.state.me.Profile.Name}'s booking`;
            this.$bvModal.show('makebookingmodal');
        },
        retry() {
            this.success = false;
            this.error = false;
            const el1 = this.$el.getElementsByClassName('circle-loader')[0];
            el1.classList.remove('load-complete');
            var el2 = this.$el.getElementsByClassName('checkmark')[0];
            el2.style.display = 'none';
            var el2 = this.$el.getElementsByClassName('xmark')[0];
            el2.style.display = 'none';

            this.confirm();
        },
        createBooking() {
            // start loader
            this.loading = true;
            if (this.bookingPrice && this.bookingPrice.GrossPriceInCents !== 0) {
                // eslint-disable-next-line consistent-return
                this.getPaymentMethods()
                    .then((response) => {
                        this.paymentMethods = response;
                        if (this.paymentMethods.length === 0 && this.billingOn) {
                            this.addPaymentMethod();
                            this.loading = false;
                        } else {
                            this.confirm();
                        }
                    })
                    .catch((error) => {
                        this.error = true;
                        this.loading = false;
                        this.errorMessage = error;
                    });
            } else {
                this.confirm();
            }
        },

        confirm() {
            // start loader
            this.loading = true;

            // add current day to time
            const timeStart = this.bookingStartString.split(':');
            const timeEnd = this.bookingEndString.split(':');

            const from = moment(this.booking.From);
            const to = moment(this.booking.From);

            from.hours(parseInt(timeStart[0]));
            from.minutes(parseInt(timeStart[1]));

            to.hours(parseInt(timeEnd[0]));
            to.minutes(parseInt(timeEnd[1]));

            const booking = {
                From: from.unix().toString(),
                To: to.unix().toString(),
                ResourceID: this.resource.ID,
                Title: this.booking.Title,
                CouponIDs: [],
                Comment: this.booking.Comment,
            };

            if (this.coupon) {
                booking.CouponIDs = [this.coupon.ID];
            }

            const data = JSON.stringify(booking);

            axios({
                method: 'POST',
                url: '/booking/v2/create',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.$emit('bookingCreated');
                    setTimeout(() => {
                        this.error = false;
                        this.success = true;
                    }, 300);
                })
                .catch((error) => {
                    setTimeout(() => {
                        this.error = true;
                        this.success = false;
                    }, 300);
                });
        },
    },
};
