





















export default {
    name: 'CoCheckboxNumber',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: '#000000',
        },
        name: {
            type: String,
            default: '',
        },
    },
    model: {
        prop: 'value',
        event: 'change',
    },
    data() {
        return {
            model: this.value,
            checkColor: this.value ? this.color : 'transparent',
        };
    },
    watch: {
        model: function (val) {
            if (val) {
                this.checkColor = this.color;
            } else {
                this.checkColor = 'transparent';
            }
            this.$emit('change', val);
        },
        value: function (val) {
            this.model = val;
        },
    },
};
