<template>
    <div class="bg-white round-unify-xs mh-100 h-100 d-flex flex-column align-items-end">
        <!-- header -->
        <div class="p-3 text-center border-bottom align-self-start w-100">{{ $t('messages.createaccount') }}</div>
        <!-- body -->
        <div class="align-self-start w-100 overflow-auto mh-100 p-3">
            <div class="alert alert-danger bg-white text-orange" role="alert" v-if="error">
                <p class="mb-0">{{ error.message }}</p>
            </div>
            <b-form ref="account_form">
                <b-form-group
                    id="input-group-0"
                    :label="$t('labels.salutation')"
                    label-for="input-0"
                    label-class="label-align mb-0"
                >
                    <b-form-select
                        id="input-0"
                        class="form-control rounded-pill"
                        v-model="signUpForm.Salutation"
                        :options="salutationOptions"
                    ></b-form-select>
                </b-form-group>
                <div class="d-flex">
                    <b-form-group
                        id="input-group-1"
                        :label="$t('labels.namefirst')"
                        label-for="input-1"
                        label-class="label-align mb-0"
                        class="pr-1"
                    >
                        <b-form-input
                            id="input-1"
                            v-model="signUpForm.FirstName"
                            type="text"
                            class="form-control rounded-pill"
                            required
                            :placeholder="$t('labels.namefirst')"
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-group-1"
                        :label="$t('labels.namelast')"
                        label-for="input-1"
                        label-class="label-align mb-0"
                        class="pl-1"
                    >
                        <b-form-input
                            id="input-1"
                            v-model="signUpForm.LastName"
                            type="text"
                            class="form-control rounded-pill"
                            required
                            :placeholder="$t('labels.namelast')"
                        ></b-form-input>
                    </b-form-group>
                </div>

                <b-form-group
                    v-if="$unleash.isEnabled('ProfileBirthDate')"
                    id="input-group-1.3"
                    :label="$t('labels.birthdate')"
                    label-for="input-1.3"
                    label-class="label-align mb-0"
                >
                    <b-form-input
                        id="input-1.3"
                        v-model="signUpForm.BirthDate"
                        type="date"
                        class="form-control rounded-pill w-50"
                        required
                        :max="maxDate"
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    v-if="$unleash.isEnabled('frontend.billing.phone')"
                    id="input-group-1.5"
                    :label="$t('labels.phoneNumber')"
                    label-for="input-1.5"
                    label-class="label-align mb-0"
                >
                    <b-form-input
                        id="input-1.5"
                        v-model="signUpForm.Phone"
                        type="text"
                        class="form-control rounded-pill"
                        required
                        :placeholder="$t('labels.phoneNumber')"
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    id="input-group-2"
                    :label="$t('labels.email')"
                    label-for="input-2"
                    label-class="label-align mb-0"
                >
                    <b-form-input
                        id="input-2"
                        v-model="signUpForm.Email"
                        type="email"
                        class="form-control rounded-pill"
                        required
                        :placeholder="$t('placeholders.email')"
                    ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-3">
                    <div class="mb-0">
                        <span class="label-align mb-0">{{ $t('labels.password') }}</span
                        ><span class="ml-2 clickable" @click="switchPasswordView"
                            ><b-icon variant="primary" :icon="passwortViewIcon"></b-icon
                        ></span>
                    </div>
                    <b-form-input
                        id="input-3"
                        v-model="signUpForm.Password"
                        :type="passwortInputType"
                        class="form-control rounded-pill"
                        required
                        :placeholder="$t('placeholders.password')"
                        :formatter="checkLength"
                    ></b-form-input>
                </b-form-group>
            </b-form>
            <hr v-if="isAuthMethods" :data-content="$t('labels.or')" class="mt-4 hr-text" />
            <OA2Providers
                class="mt-4"
                :is-login="false"
                padding-left="0"
                padding-right="27rem"
                back-ground-color="#eaeaea"
                @haveAuthMethods="isAuthMethods = $event"
            ></OA2Providers>
        </div>

        <!-- footer -->
        <div class="p-3 border-top d-flex justify-content-between mt-auto w-100">
            <coButton variant="secondary" @click="back" :label="$t('labels.back')" />
            <coButton variant="primary" @click="next" :label="$t('labels.next')" />
            <!-- <b-button variant="outline-primary" @click="back">{{$t('labels.back')}}</b-button>
<b-button variant="primary" @click="next">{{$t('labels.next')}}</b-button> -->
        </div>
    </div>
</template>

<script>
import Vuei18n from 'vue-i18n';
import debounce from 'lodash/debounce';
import EventBus from '../../eventBus.js';
import OA2Providers from '../auth_methods/OA2Providers';

export default {
    i18n: {
        messages: {
            en: {
                messages: {
                    createaccount: 'Create your account',
                    passwordlength: 'Password can not be longer than 56 characters',
                },
            },
            de: {
                messages: {
                    createaccount: 'Erstelle dein Konto',
                    passwordlength: 'Passwort darf nicht länger als 56 Zeichen sein',
                },
            },
        },
    },
    name: 'Account',
    components: {
        OA2Providers,
    },
    data() {
        return {
            signUpForm: {
                Salutation: null,
                FirstName: '',
                LastName: '',
                Phone: '',
                Email: '',
                Password: '',
                BirthDate: '',
            },
            maxDate: '',
            error: null,
            passwortInputType: 'password',
            passwortViewIcon: 'eye-slash',
            salutationOptions: [
                { value: null, text: this.$t('placeholders.selectplease') },
                { value: 'm', text: this.$t('labels.salutationmale') },
                { value: 'f', text: this.$t('labels.salutationfemale') },
                { value: 'x', text: this.$t('labels.salutationnonbinary') },
            ],
            isAuthMethods: false,
            debouncedCheckLength: null,
        };
    },
    created() {
        const today = new Date();
        const maxDate = new Date();
        maxDate.setFullYear(today.getFullYear() - 18);
        this.maxDate = maxDate.toISOString().slice(0, 10);

        this.debouncedCheckLength = debounce(this.notifyPasswordLength, 500);
        if (this.$store.state.newMembership.Account) {
            this.signUpForm = this.$store.state.newMembership.Account;
        }
    },
    methods: {
        back() {
            if (
                this.$store.state.newMembership.Plan &&
                (!this.$store.state.newMembership.Plan.Extras ||
                    this.$store.state.newMembership.Plan.Extras.length === 0)
            ) {
                this.$router.push('/plans');
            } else if (!this.$store.state.newMembership.Plan) {
                this.$router.push('/plans');
            } else {
                this.$store.commit('PREVIOUS_STEP');
            }
        },
        next() {
            if (this.$refs.account_form.checkValidity()) {
                this.$store.commit('SET_ACCOUNT', this.signUpForm);
            } else {
                this.$refs.account_form.reportValidity();
            }
        },

        switchPasswordView() {
            if (this.passwortInputType === 'password') {
                this.passwortInputType = 'text';
                this.passwortViewIcon = 'eye';
            } else {
                this.passwortInputType = 'password';
                this.passwortViewIcon = 'eye-slash';
            }
        },
        notifyPasswordLength() {
            EventBus.$emit(`ERROR`, {
                Message: this.$t('messages.passwordlength'),
            });
        },
        checkLength(value) {
            if (value.length > 56) {
                this.debouncedCheckLength();
                return value.substring(0, 56);
            }
            return value;
        },
    },
};
</script>

<style scoped>
hr.hr-text {
    position: relative;
    border: none;
    height: 1px;
    background: #999;
}

hr.hr-text::before {
    content: attr(data-content);
    display: inline-block;
    background: #fff;
    font-weight: bold;
    font-size: 0.85rem;
    color: #999;
    border-radius: 30rem;
    padding: 0.2rem 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
