<template>
    <div>
        <!-- OG/meta -->
        <vue-headful title="Create your Community - coapp" description="" />
        <div class="py-4 d-flex justify-content-center align-items-md-center">
            <div class="step-wrapper login-form p-4 bg-white round-unify text-center">
                <h2>{{ $t('success') }}</h2>
                <h3 class="mt-1">{{ $t('wait') }}</h3>

                <b-spinner class="mt-2" label="Spinning"></b-spinner>

                <div class="w-100 mt-4">
                    <illustration type="welcome" class="my-0 illu"></illustration>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { get } from 'lodash';
import smoothReflow from 'vue-smooth-reflow';
import axios from 'axios';
import i18n from 'vue-i18n';

export default {
    name: 'CreateSpaceSuccess',
    mixins: [smoothReflow],
    i18n: {
        messages: {
            en: {
                success: 'Great, you created your community!',
                wait: 'Please wait a second to be redirected...',
            },
            de: {
                success: 'Super, du hast deine Community erstellt!',
                wait: 'Bitte warte einen Moment, um weitergeleitet zu werden...',
            },
        },
    },
    data() {
        return {
            domain: get(this.$route, 'params.domain', null),
        };
    },
    created() {},
    mounted() {
        setTimeout(
            (domain) => {
                window.location.href = `https://${domain}`;
            },
            3000,
            this.domain
        );
        this.$smoothReflow({
            el: '.step-wrapper',
        });
    },
};
</script>
