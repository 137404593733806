<style lang="less" scoped>
@import '../../assets/less/variables.less';
@import '../../assets/less/manage-space/base.less';

.co.button.round {
    .dropdown-toggle::before {
        display: flex;
        height: 16px !important;
        width: 16px !important;
        padding: 0;
    }

    border-radius: 2.25rem;
    border: 1px solid @coapp-purple;
    width: 2.25rem;
    height: 2.25rem;
    color: @coapp-purple;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;

    .icon-wrapper {
        display: flex;
        height: 16px !important;
        width: 16px !important;
        padding: 0;
    }

    .counter {
        position: absolute;
        top: -0.5rem;
        left: 1.5rem;
        background-color: @coapp-purple;
        border-color: @coapp-lightgrey;
        border: 1px solid @coapp-lightgrey;
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        font-size: 0.625rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.primary {
        background-color: @coapp-purple;
        color: #fff;
    }

    &.secondary {
        background-color: @coapp-midgrey;
        border-color: @coapp-midgrey;
        color: @coapp-black;

        &:hover {
            background-color: @coapp-purple;
            color: #fff;
        }
    }

    &.danger {
        background-color: #fff;
        border-color: @coapp-flamingo;
        color: @coapp-flamingo;

        &:hover {
            background-color: @coapp-flamingo;
            color: #fff;
        }
    }

    &.light {
        background-color: #fff;
        border: none;
        color: @coapp-black;

        &:hover {
            background-color: @coapp-purple;
            color: #fff;
        }
    }
}

.calendar-container {
    display: flex;
    // min-height: 100%;
    max-height: 100vh;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 14px;
}

.calendar {
    max-height: 50vh;
    height: 50vh;
    margin: 0 10px;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 1.125rem !important;
    background-color: #fff;

    .fc .fc-toolbar.fc-header-toolbar {
        border-top-left-radius: 1.125rem !important;
        border-top-right-radius: 1.125rem !important;
    }
}

.resources-list {
    max-height: 200px !important;
    height: 200px !important;
    overflow-y: auto;
}

.co {
    .btn .b-icon.bi {
        margin: 0 !important;
    }
}

.co-text-wrap {
    overflow-wrap: break-word;
}

.pre-scrollable {
    max-height: calc(100vh - 164px) !important;
    overflow-y: scroll;
}

.pre-scrollable-filter {
    max-height: calc(100vh - 264px) !important;
    overflow-y: scroll;
    overflow-x: hidden;
}
</style>

<template>
    <b-overlay :show="loading">
        <div>
            <!-- header start -->
            <div class="row p-0 m-0 pb-3">
                <div class="d-none d-sm-block col-sm-3 col-md-3">
                    <div class="d-none d-md-block col-sm-1"></div>
                    <!-- search and actions start -->
                    <!--                    col-12 col-sm-3 col-md-2 pr-3-->
                    <div class="col-none d-none d-sm-block col-sm-1 d-md-none"></div>
                    <!-- text button with text "Back" and action "clearAllFilters" -->

                    <div
                        v-if="!mobile && selectedResource"
                        class="d-flex w-100 align-items-center justify-content-center"
                        @click="returnToList()"
                    >
                        <co-icon name="arrow-left"></co-icon>
                        <span class="pointer">{{ $t('labels.back') }}</span>
                    </div>
                    <div class="d-flex w-100 align-items-center justify-content-center"></div>
                </div>
                <div class="col-12 col-sm-10 col-md-6 px-4 px-md-2">
                    <div class="d-flex justify-content-between w-100 align-items-center">
                        <div class="d-flex align-items-center">
                            <co-icon name="calendar2-plus"></co-icon>
                            <h3 class="mb-0" v-if="selectedResource">{{ selectedResource.Name }}</h3>
                            <h3 class="mb-0" v-else>{{ $t('labels.bookings') }}</h3>
                        </div>
                        <CoRoundButton
                            class="d-block d-md-none"
                            variant="icon"
                            icon="filter"
                            @click="viewFiltersToggle"
                        />
                    </div>
                </div>
            </div>

            <!-- header end -->
            <div class="row p-0 m-0">
                <div class="d-none d-md-block col-sm-1"></div>
                <!-- search and actions start -->
                <div class="col-none d-none d-sm-block col-sm-1 d-md-none"></div>

                <div v-show="viewFilter" class="col-12 col-sm-3 col-md-2 pr-3">
                    <div class="pre-scrollable-filter mt-3">
                        <CoCheckboxSelection
                            classes="mb-3 co-text-wrap"
                            :selections="locations"
                            :defaultExpanded="true"
                            :name="$t('labels.location')"
                            @selection-changed="selectionChangedLocations"
                        />

                        <CoCheckboxSelection
                            classes="mb-3"
                            :selections="categories"
                            :defaultExpanded="true"
                            :name="$t('labels.category')"
                            @selection-changed="selectionChangedCategory"
                        />

                        <CoCheckboxSelection
                            v-if="viewCalendar"
                            :selections="matchedResources"
                            :default-expanded="true"
                            :name="$t('labels.resources')"
                            @selection-changed="selectionChangedResource"
                        />
                    </div>
                    <div class="mt-3 d-flex align-items-center text-nowrap overflow-hidden">
                        <co-icon name="trash"></co-icon>
                        <span class="pointer d-inline-block text-truncate" @click="clearAllFilters()"
                            >{{ $t('labels.clearall') }}
                        </span>
                    </div>
                </div>

                <!-- search and actions end -->

                <!-- resources list -->
                <div class="col-12 col-sm-10 col-md-6 p-0" v-if="!viewCalendar && (!mobile || !viewFilter)">
                    <CoInput
                        v-model="searchText"
                        :placeholder="$t('placeholders.search')"
                        aria-label="Search"
                        class="w-100 mb-2 px-4 px-md-2"
                        icon="search"
                    />

                    <!-- search -->
                    <div class="pre-scrollable" v-if="matchedResources">
                        <CoBookingResourcesList
                            :resources="matchedResources"
                            @select="selectResource"
                            class="d-flex px-2"
                            :isBillingTurnedOn="isBillingTurnedOn"
                        />
                    </div>
                    <div v-if="!loading && matchedResources.length === 0" class="w-100 text-center">
                        <span>{{ $t('messages.searchnomatches') }}</span>
                    </div>
                </div>

                <div class="col-12 col-sm-10 col-md-9" v-if="viewCalendar && (!mobile || !viewFilter)">
                    <co-booking-calendar-enduser
                        :resources="[selectedResource]"
                        @back="clearAllFilters"
                    ></co-booking-calendar-enduser>
                </div>

                <div class="col-none d-none d-sm-block col-sm-2 d-md-none"></div>
            </div>
        </div>
    </b-overlay>
</template>

<script>
import axios from 'axios';
import { format, isSameDay } from 'date-fns';

import CoBookingResourcesList from '@/components/Organisms/co-booking-resources-list/CoBookingResourcesList';
import CoCheckboxSelection from '@/components/Organisms/co-checkbox-selection/CoCheckboxSelection';
import CoBookingCalendarEnduser from '@/components/Organisms/co-booking-calendar-enduser/CoBookingCalendarEnduser.vue';
import CoInput from '@/components/Molecules/co-input/CoInput.vue';
import CoIcon from '@/components/Atoms/co-icon/CoIcon';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';

function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    const aSorted = a.sort();
    const bSorted = b.sort();

    for (let i = 0; i < aSorted.length; i += 1) {
        if (aSorted[i] !== bSorted[i]) return false;
    }
    return true;
}

export default {
    name: 'BookingCalendar',
    components: {
        CoBookingCalendarEnduser,
        CoInput,
        CoBookingResourcesList,
        CoCheckboxSelection,
        CoIcon,
    },

    data() {
        return {
            format,
            isSameDay,
            loading: false,

            viewCalendar: false,
            viewFilter: false,

            sort: false,
            searchText: '',
            start: null,

            end: null,

            locations: [],
            selectedLocations: [],
            categories: [],
            currentPlanID: '',
            selectedCategories: [],
            resources: [],
            selectedResource: null,
            selectedResources: [],
            matchedResources: [],
            userResources: [],

            windowWidth: window.innerWidth,
            mobile: false,

            bookingCandidate: {
                showResourceSelector: false,
                start: null,
                end: null,
                resource: null,
            },

            // single booking component modal
            loadingModal: false,
            deleting: false,

            title: '',

            bookingComponentStartDate: null,
            bookingComponentEndDate: null,

            weekdayEnd: '',

            bookingComponent: {},

            resource_id: this.$route.query.resource_id,
            isBillingTurnedOn: this.$store.state.billingTurnedOn,
        };
    },
    async mounted() {
        this.loading = false;

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        window.addEventListener('resize', () => {
            that.onResize();
        });
        that.onResize();
        await Promise.all([this.listLocations(), this.listCategories()]);
        await this.listResources();
        this.$root.$on('ClearBookingCalendarFilters', () => {
            this.clearAllFilters();
        });
    },
    watch: {
        $route: {
            handler(newRouteValue) {
                this.resource_id = newRouteValue.query.resource_id;
            },
            deep: true,
        },
        resource_id(newVal) {
            if (!newVal) {
                this.viewCalendar = false;
            }
        },
        selectedResource(newVal) {
            if (newVal === null) {
                this.viewCalendar = false;
            }

            const queries = { ...this.$route.query };

            queries.resource_id = newVal ? newVal.Id : undefined;
            this.$router.push({ query: queries }).catch((e) => console.warn(e));
        },
        searchText() {
            this.listResources();
        },

        selectedLocations(newVal) {
            const queries = { ...this.$route.query };
            queries.location_ids = [...newVal];
            this.$router.replace({ query: queries }).catch((e) => console.warn(e));
        },
        selectedCategories(newVal) {
            const queries = { ...this.$route.query };
            queries.category_ids = [...newVal];
            this.$router.replace({ query: queries }).catch((e) => console.warn(e));
        },

        locations(newVal) {
            if (this.$route.query.location_ids) {
                const queryLocs = Array.isArray(this.$route.query.location_ids)
                    ? this.$route.query.location_ids
                    : [this.$route.query.location_ids];

                this.selectedLocations = queryLocs.filter((e) => !!newVal.find((x) => e === x.Id));

                newVal.forEach((e) => {
                    if (this.selectedLocations.includes(e.Id)) {
                        e.Selected = true;
                    }
                });
            }
        },
        categories(newVal) {
            if (this.$route.query.category_ids) {
                const queryCats = Array.isArray(this.$route.query.category_ids)
                    ? this.$route.query.category_ids
                    : [this.$route.query.category_ids];

                this.selectedCategories = queryCats.filter((e) => !!newVal.find((x) => e === x.Id));

                newVal.forEach((e) => {
                    if (this.selectedCategories.includes(e.Id)) {
                        e.Selected = true;
                    }
                });
            }
        },
        resources() {
            const resourceId = this.$route.query.resource_id;
            if (resourceId) {
                const res = this.resources.find((e) => e.Id === resourceId);
                if (res) this.selectResource(res);
                else this.selectedResource = null;
            }
        },
    },
    methods: {
        // window resize handler
        onResize() {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth < 768) {
                this.viewFilter = false;
                this.mobile = true;
            } else {
                this.viewFilter = true;
                this.mobile = false;
            }
        },
        // Calendar events and filter
        async fetchSelections(url, mappingFunction) {
            try {
                const response = await axios({
                    method: 'GET',
                    url,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                return await mappingFunction(response.data);
            } catch (error) {
                console.error(error);
            }
            return [];
        },
        async listLocations() {
            this.locations = await this.fetchSelections('/booking/v2/locations', (response) =>
                response.Locations.map((item) => ({
                    Name: item.Name,
                    Color: '#000',
                    Selected: false,
                    Id: item.ID,
                }))
            );
        },
        async listCategories() {
            this.categories = await this.fetchSelections('/booking/v2/category/list', (response) =>
                response.map((item) => ({
                    Name: item.name,
                    Color: '#000',
                    Selected: false,
                    Id: item.id,
                }))
            );
        },
        async getUserResources() {
            // get the users plan info
            axios({
                method: 'GET',
                url: '/me/plan',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.currentPlanID = response.data.current.Plan.ID;
                    response.data.current.Plan.AvailableResources.map((value) => {
                        this.userResources.push(value.ID);
                    });
                    response.data.current.Plan.AvailableResourceGroups.map((value) => {
                        value.ListResources.map((i) => {
                            this.userResources.push(i.ID);
                        });
                    });
                    this.matchedResources = this.matchUserResources(this.resources, this.userResources);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        matchUserResources(resources, userResources) {
            return resources.filter((resource) => userResources.includes(resource.ID));
        },

        async listResources() {
            const payload = {
                SpaceID: this.$store.state.space.ID,
                Categories: this.selectedCategories,
                Location: this.selectedLocations,
            };

            if (this.searchText) {
                payload.SearchInput = this.searchText;
            }

            try {
                const response = await axios({
                    method: 'POST',
                    url: `/booking/v2/resources/filter`,
                    withCredentials: true,
                    data: JSON.stringify(payload),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.data) {
                    this.resources = response.data;

                    // map resources with additional data
                    this.resources = this.resources.map((resource) => {
                        const resourceData = resource;
                        const resourceDataWithPrice = {
                            ...resourceData,
                            PriceInCentsPerHour: resource.PriceInCentsPerHour,
                            Selected: this.selectedResource && resource.ID === this.selectedResource.Id,
                            Id: resource.ID,
                        };
                        return resourceDataWithPrice;
                    });

                    if (this.$unleash.isEnabled('frontend.allow-only-resources-in-plan')) {
                        this.getUserResources();
                    } else {
                        this.matchedResources = this.resources;
                    }
                } else {
                    this.resources = [];
                }
            } catch (error) {
                console.error(error);
            }
        },
        async selectionChangedCategory({ item, selected }) {
            if (selected) {
                if (!(item.Id in this.selectedCategories)) this.selectedCategories.push(item.Id);
            } else {
                const i = this.selectedCategories.indexOf(item.Id);
                if (i !== -1) this.selectedCategories.splice(i, 1);
            }
            await this.listResources();
        },
        async selectionChangedLocations({ item, selected }) {
            if (selected) {
                if (!(item.Id in this.selectedLocations)) this.selectedLocations.push(item.Id);
            } else {
                const i = this.selectedLocations.indexOf(item.Id);
                if (i !== -1) this.selectedLocations.splice(i, 1);
            }
            await this.listResources();
        },

        async selectionChangedResource({ item, selected }) {
            if (selected) {
                this.resources.forEach((resource) => {
                    if (resource.Id === item.Id) {
                        resource.Selected = true;
                    } else {
                        resource.Selected = false;
                    }
                });

                this.selectedResource = item;
            } else {
                const index = this.resources.findIndex((resource) => resource.Id === item.Id);
                this.resources[index].Selected = false;

                if (this.selectedResource === item) this.selectedResource = null;
            }
        },

        selectResource(resource) {
            // check if mobile mode is on
            if (this.$store.state.isInMobile) {
                // replace url quiry params with resource id
                const urltoResource = `/booking-calendar?resource_id=${resource.Id}`;
                const queryParam = { ...this.$route.query, openinbrowser: urltoResource };
                this.$router.push({ query: queryParam });
                return;
            }
            // find the resource in the resources array and set it to selected = true
            const index = this.resources.findIndex((item) => item.Id === resource.Id);
            this.resources[index].Selected = true;
            this.selectedResource = resource;
            this.viewCalendar = true;
        },

        clearAllFilters() {
            if (this.windowWidth < 992) {
                this.viewFilter = false;
            }
            this.loading = true;

            this.selectedLocations = [];
            this.selectedCategories = [];
            this.selectedResource = null;

            this.resources.forEach((resource) => {
                // eslint-disable-next-line no-param-reassign
                resource.Selected = false;
            });

            this.locations.forEach((location) => {
                // eslint-disable-next-line no-param-reassign
                location.Selected = false;
            });

            this.categories.forEach((category) => {
                // eslint-disable-next-line no-param-reassign
                category.Selected = false;
            });

            this.searchText = '';

            this.listResources();
            this.loading = false;
        },

        returnToList() {
            this.loading = true;
            this.resources.forEach((resource) => {
                // eslint-disable-next-line no-param-reassign
                resource.Selected = false;
            });
            this.selectedResource = null;
            this.loading = false;
        },

        viewFiltersToggle() {
            this.viewFilter = !this.viewFilter;
        },

        hideModals() {
            this.bookingCandidate.resource = null;
            const selectModal = this.$refs['resource-select-modal'];
            selectModal.hide();
        },
    },
};
</script>
