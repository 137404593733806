<template>
    <b-overlay :show="loading">
        <div class="d-flex align-items-center pt-2 ml-3 border-bottom pb-2">
            <span>Checkout</span>
        </div>
        <div class="d-flex mt-4 w-100">
            <!-- content section -->
            <div class="row m-0 w-100">
                <div v-if="timepass" class="col-12 col-lg-8">
                    <div class="bg-white mh-100 h-100 d-flex flex-column align-items-end">
                        <!-- header -->

                        <!-- body -->
                        <div class="align-self-start w-100 overflow-auto mh-100">
                            <div class="d-flex">
                                <div class="d-flex flex-column">
                                    <h3 class="grey">Time Pass</h3>
                                    <div class="d-flex">
                                        {{ timepass.Name }}
                                    </div>
                                </div>
                                <div class="ml-auto d-flex">
                                    <div class="d-flex flex-column">
                                        <h3 class="grey">Payment due today</h3>
                                        <span>{{ totalPrice() }} {{ timepass.Currency }} / Month</span>
                                        <small class="grey mt-1"
                                            >price includes {{ taxRate.Percentage }} % {{ taxRate.DisplayName }}</small
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="d-flex flex-column">
                                    <h3 class="grey mt-2">Account</h3>
                                    <span>{{ me.Profile.Name }}</span>
                                    <span class="mt-2">{{ me.Email }}</span>
                                </div>
                            </div>
                            <div class="d-flex mt-3">
                                <div class="d-flex flex-column">
                                    <h3 class="grey">Payment method</h3>
                                    <!-- Add current payment method display -->
                                    <span></span>
                                    <span class="mt-1"></span>
                                </div>
                            </div>
                            <div class="d-flex mt-2">
                                <div class="d-flex flex-column">
                                    <span class="m-0 p-0"> Time pass will be available immediately. </span>
                                </div>
                            </div>
                            <div v-if="isAdminSpace" class="d-flex mt-4 align-items-center">
                                <div class="d-flex flex-column">
                                    <span>Override Price </span>
                                    <b-form-checkbox v-model="overRide" name="check-button" switch></b-form-checkbox>
                                    <span class="small"> If checked, user will not pay for Time Pass</span>
                                </div>
                            </div>

                            <div class="d-flex align-items-center mt-4 mb-3" v-if="TermsAndConditions">
                                <b-form-checkbox
                                    id="checkbox-2"
                                    name="checkbox-2"
                                    v-model="AcceptTermsAndConditions"
                                    required
                                >
                                </b-form-checkbox>
                                <p class="mb-0">
                                    I accept the
                                    <a
                                        class="text-primary mb-0"
                                        @click="$bvModal.show('terms-and-conditions')"
                                        title="Terms and Conditions"
                                        >Terms and Conditions</a
                                    >
                                </p>
                            </div>
                        </div>
                        <!-- footer -->
                    </div>
                </div>
            </div>
        </div>
        <!-- membership state -->
        <b-modal id="terms-and-conditions" hide-footer title="Terms and conditions" size="xl">
            <div v-if="TermsAndConditions" v-html="TermsAndConditions.Content"></div>
        </b-modal>
    </b-overlay>
</template>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.grey {
    color: gray;
}

.small-text {
    font-size: 0.8rem;
}

@media (min-width: 768px) {
    .col-image {
        height: 70vh;
    }

    .column {
        min-height: 70vh;
    }
}
</style>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';

export default {
    name: 'BuyPassReview',
    props: ['timepass', 'taxRate', 'isAdminSpace'],
    data() {
        return {
            AcceptTermsAndConditions: false,
            overRide: false,
            TermsAndConditions: null,
            loading: false,
            me: this.$store.state.me,
        };
    },
    watch: {
        AcceptTermsAndConditions(newVal, oldVal) {
            console.log(newVal);
            this.$emit('isAccepted');
        },
        overRide(newVal, oldVal) {
            console.log(newVal);
            this.$emit('priceOverride');
        },
    },
    computed: {
        isReady() {
            return this.AcceptTermsAndConditions;
        },
    },
    created() {},
    mounted() {
        this.getTermsAndConditions();
    },
    methods: {
        back() {
            this.$emit('prevStep');
            this.AcceptTermsAndConditions = false;
        },
        buyPass() {
            this.$emit('buyPass');
        },
        calculatePrice(timepass) {
            if (this.taxRate.Inclusive) {
                const price = timepass.PriceInCents / 100.0;
                return price;
            }
            const price = timepass.PriceInCents / 100.0;
            const totalPrice = price * (this.taxRate.Percentage / 100 + 1);
            return totalPrice;
        },
        totalPrice() {
            const total = this.calculatePrice(this.timepass);

            return total.toFixed(2);
        },
        getTermsAndConditions() {
            axios({
                method: 'GET',
                url: '/space/get-terms-and-conditions',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.TermsAndConditions = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>
