








import CoText from '@/components/Atoms/co-text/CoText.vue';
import Vue, { PropType } from 'vue';

type Element = { text: string; id: string };
export default Vue.extend({
    name: 'CoSimpleDropdownList',
    components: { CoText },
    props: {
        items: {
            type: Array as PropType<Array<string | Element>>,
            required: true,
            default: () => [],
        },
    },
    computed: {
        elements(): Array<Element> {
            return this.$props.items.map((e) => {
                if (typeof e === 'string') {
                    return {
                        text: e,
                        id: e,
                    };
                }
                return e;
            });
        },
    },
    methods: {
        onClick(event, id: string) {
            event.stopPropagation();
            this.$emit('click', id);
        },
    },
});
