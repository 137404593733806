
































import { get } from 'lodash';

export default {
    name: 'UserMainGrid',
    data() {
        return {
            sideBarShow: false,
        };
    },
    props: {
        hideHeader: {
            type: Boolean,
            default: false,
        },
        hideLeft: {
            type: Boolean,
            default: false,
        },
        hideRight: {
            type: Boolean,
            default: false,
        },
        stretchCenter: {
            type: Boolean,
            default: false,
        },
        hideFooter: {
            type: Boolean,
            default: false,
        },
        disableMobileMenu: {
            type: Boolean,
            default: false,
        },
        headerBackgroundColor: {
            type: String,
            default: 'var(--c-lightgrey)',
        },
        leftComponent: {
            type: Object,
            default: null,
        },
        rightComponent: {
            type: Object,
            default: null,
        },
    },
    computed: {
        hasPreHeader() {
            return get(this, `$slots['pre-header']`, null) && !this.hideHeader;
        },
        hasHeader() {
            return get(this, `$slots['header']`, null) && !this.hideHeader;
        },
        hasLeft() {
            return (this.leftComponent || get(this, `$slots['left']`, null)) && !this.hideLeft;
        },
        hasContent() {
            return get(this, `$slots['content']`, null);
        },
        hasRight() {
            return (this.rightComponent || get(this, `$slots['right']`, null)) && !this.hideRight;
        },
        hasFooter() {
            return get(this, `$slots['footer']`, null) && !this.hideFooter;
        },
        isInMobile() {
            return get(this, '$store.state.isInMobile', false);
        },
    },
    methods: {
        menuHide() {
            this.sideBarShow = false;
        },
        menuShow() {
            this.sideBarShow = true;
        },
    },
};
