import { render, staticRenderFns } from "./CoMedia.vue?vue&type=template&id=03d9abef&scoped=true&"
import script from "./CoMedia.vue?vue&type=script&lang=js&"
export * from "./CoMedia.vue?vue&type=script&lang=js&"
import style0 from "./CoMedia.vue?vue&type=style&index=0&id=03d9abef&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03d9abef",
  null
  
)

export default component.exports