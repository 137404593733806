<template>
    <div class="min-vh-100 d-flex bg justify-content-center align-items-md-center">
        <div class="login-form mt-4 mt-md-0 p-4 bg-white round-unify text-center">
            <img src="../../assets/coapp_logo.png" alt="coapp logo" height="64" />
            <div class="card-body w-100 py-4">
                <div v-if="loading" class="text-center">
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                    <p class="mt-2">Retrieving access token...</p>
                </div>

                <div v-else-if="!loading && error" class="alert bg-white">
                    <p class="pb-0">{{ error.message }}</p>
                    <small>{{ error.details }}</small> <br />
                    <small>Details: {{ error.response }}</small>
                </div>

                <div v-else class="alert bg-white">
                    <p class="pb-0">Access granted.</p>
                </div>

                <b-button variant="primary" v-if="url" :href="url"
                    ><b-icon icon="plus"></b-icon>Back to Admin page</b-button
                >
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    data() {
        return {
            loading: false,
            error: null,

            url: null,
            code: this.$route.query.code,
            state: this.$route.query.state,
        };
    },
    mounted() {
        this.exchangeCodeForToken();
    },

    methods: {
        exchangeCodeForToken() {
            this.loading = true;
            axios
                .post('/oauth2/callback', {
                    code: this.code,
                    state: this.state,
                })
                .then((response) => {
                    this.loading = false;
                    // half second timeout to show success message
                    // redirect to admin page after success
                    if (response && response.data) {
                        setTimeout(() => {
                            window.location.href = response.data.redirect_url;
                        }, 500);
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = {
                        message: 'Failed to retrieve access token. Please, contact support.',
                        details: error,
                        response: error.response.data ? error.response && error.response.data : '',
                    };
                });
        },
    },
};
</script>
