







































































































































import Vue from 'vue';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoText from '../../Atoms/co-text/CoText.vue';
import CoCard from '../../Molecules/co-card/CoCard.vue';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoRoundButton from '../../Atoms/co-round-button/CoRoundButton.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';

import CoPrice from '../../Atoms/co-price/coPrice.vue';
import CoTextReadmore from '../../Molecules/co-text-readmore/CoTextReadmore.vue';
import CoInput from '../../Molecules/co-input/CoInput.vue';

interface Price {
    PriceInCents: number;
    Currency: string;
    TaxRate: number;
    TaxName: string;
    Inclusive: boolean;
    Unit?: string;
}

export default {
    name: 'CoPlanDetail',
    components: {
        CoText,
        CoCard,
        CoHeadline,
        CoRoundButton,
        CoPrice,
        CoIcon,
        CoInput,
        CoButton,
        CoTextReadmore,
    },
    props: {
        plan: {
            type: Object,
            required: true,
        },
        currentPlan: {
            type: Boolean,
            required: false,
        },
        showUpgrades: {
            type: Boolean,
            required: false,
        },
        hasHeaderTitleSlot: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        upgrades() {
            if (this.currentPlan || this.showUpgrades) {
                return this.plan.Extras;
            }
            return null;
        },
        hasHeaderButtonSlot() {
            return this.$slots['header-button'];
        },
    },
    methods: {
        centsToWholeUnit(cents: number): string {
            const price = cents / 100;
            return price.toFixed(2);
        },
    },
};
