var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lzy-img-container d-flex",class:{
        'is-loaded hide_placeholder': _vm.ready,
        'lzy-variant-dark': _vm.darkVariant,
        round: _vm.round,
        profilePlaceholder: _vm.profilePlaceholder,
        'w-v': _vm.whiteVariant,
    },style:({
        'padding-top': _vm.relativeHeight + '% !important',
    }),attrs:{"empty":_vm.error || !_vm.src}},[_c('img',{staticClass:"lazy-load lzy-img-loader",attrs:{"data-object-fit":"cover","src":_vm.imgSrc,"width":"100","height":"100","ready":_vm.ready},on:{"load":function($event){return _vm.onLoad()},"error":function($event){return _vm.onError()}}})])}
var staticRenderFns = []

export { render, staticRenderFns }