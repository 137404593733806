<template>
    <div class="row">
        <div class="col">
            <router-view />
        </div>
    </div>
</template>

<script>
export default {
    name: 'SettingsBase',
};
</script>
