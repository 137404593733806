<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <div>
        <div class="container-fluid p-0 m-0">
            <div class="row m-0">
                <div class="col p-0 pr-md-1">
                    <SettingsCard
                        id="SettingsCardIntegrationsZapier"
                        title="Zapier"
                        subtitle="Connect coapp community to zapier"
                        to="/admin/settings/integrations/zapier"
                        :isNew="true"
                    >
                    </SettingsCard>
                    <SettingsCard
                        id="SettingsCardIntegrationsGoogleCalendar"
                        title="Google Calendar"
                        subtitle="Connect and sync booking resources"
                        to="/admin/settings/integrations/google-calendar"
                        beta
                    >
                    </SettingsCard>
                    <div class="col-lg p-0 pl-md-1">
                        <SettingsCard
                            id="SettingsCardIntegrationsNuki"
                            title="NUKI"
                            subtitle="Connect your Nuki Account"
                            to="/admin/settings/integrations/nuki"
                            :isNew="true"
                        >
                        </SettingsCard>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IntegrationsList',
    data() {
        return {};
    },
};
</script>
