


















import EventBus from '../../../eventBus';

import axios from 'axios';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoCardPlanHistory from '../../Molecules/co-card-plan-history/CoCardPlanHistory.vue';

interface Plan {
    Name: String;
    Description: String;
    Location: String;
    ImageURL: String;

    PriceInCents: Number;
    Currency: String;
    PriceInterval: String;
    Hidden: Boolean;
}

export default {
    name: 'CoPlanHistory',
    components: {
        CoCardPlanHistory,
        CoHeadline,
    },
    props: {
        previousPlans: {
            type: Array as () => Array<Plan>,
            default: () => [],
        },
        user: {
            type: Object,
            default: null,
            required: false,
        },
    },
    data() {
        return {
            categories: [],
            color: 'black',

            windowWidth: window.innerWidth,

            loading: false,
        };
    },
    created() {},

    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;

            if (this.windowWidth >= 768) {
                this.minWidth = true;
            } else {
                this.minWidth = false;
            }
        });
    },
    watch: {},
    methods: {},
};
