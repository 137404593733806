









































































import axios from 'axios';
import EventBus from '@/eventBus';
import CoAlert from '@/components/Molecules/co-alert/CoAlert.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoSelect from '@/components/Molecules/co-select/CoSelect.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoTagList from '@/components/Organisms/co-TagList/coTagList.vue';
import { set } from 'lodash';

export default {
    name: 'SignUpStrategy',
    components: { CoTagList, CoCard, CoSelect, CoText, CoAlert },
    data() {
        return {
            space: this.$store.state.space,
            loading: false,
            authFlowConfig: {},
            closeAfterSave: this.$route.query.closeAfterSave,
        };
    },
    created() {
        this.getAuthFlowConfig();
    },
    computed: {
        isDomain() {
            return (input: string) => {
                const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/;
                return domainRegex.test(input);
            };
        },
        selectedStrategy() {
            if (this.authFlowConfig.signUpStrategy === 'approval') {
                return { Value: 'approval', Name: 'Approval based' };
            }
            if (this.authFlowConfig.signUpStrategy === 'invite-only') {
                return { Value: 'invite-only', Name: 'By invitation only' };
            }
            return { Value: 'open', Name: 'Anyone can sign up' };
        },
        selectItems() {
            const list = [
                { Value: 'open', Name: 'Anyone can sign up' },
                { Value: 'invite-only', Name: 'By invitation only' },
                { Value: 'approval', Name: 'Approval based', Disabled: this.$store.state.billingTurnedOn },
            ];

            return list;
        },
    },
    methods: {
        hasHistory() {
            return window.history.length > 2;
        },
        selectStrategy(value) {
            set(this.authFlowConfig, 'signUpStrategy', value.Value);
        },
        getAuthFlowConfig() {
            this.loading = true;
            axios
                .get(`/admin/signup-config/`)
                .then((response) => {
                    this.authFlowConfig = response.data;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        handleSubmit() {
            this.loading = true;
            if (
                this.authFlowConfig.signUpStrategy === 'approval' &&
                this.authFlowConfig.approvedDomains &&
                this.authFlowConfig.approvedDomains.length > 0
            ) {
                this.authFlowConfig.autoApprove = true;
            }
            if (this.authFlowConfig.signUpStrategy !== 'approval') {
                this.authFlowConfig.autoApprove = false;
                this.authFlowConfig.approvedDomains = [];
            }
            axios
                .put(`/admin/signup-config/`, this.authFlowConfig)
                .then((response) => {
                    this.space = response.data;
                    this.loading = false;
                    EventBus.$emit('INFO', {
                        Message: 'Sign-up strategy updated successfully',
                    });
                    if (this.closeAfterSave) {
                        window.opener.postMessage(
                            {
                                type: 'WINDOW_CLOSE',
                                data: {
                                    strategy: this.authFlowConfig.signUpStrategy,
                                },
                            },
                            '*'
                        );
                        window.close();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                    EventBus.$emit('ERROR', {
                        Message: 'Failed to update sign-up strategy',
                    });
                });
        },
        domainUpdatedHandler(event) {
            this.authFlowConfig.approvedDomains = event;
        },
    },
};
