




















































































































































import axios from 'axios';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoSkeleton from '@/components/Atoms/co-skeleton/CoSkeleton.vue';
import { SubscriptionSchedule } from '@/components/Organisms/co-subscription-schedule-card/models';
import { UpcommingInvoice } from '@/components/Organisms/co-subscription-next-invoice-preview/models';
import CoSubscriptionNextInvoicePreview from '@/components/Organisms/co-subscription-next-invoice-preview/CoSubscriptionNextInvoicePreview.vue';
import CoFormGroup from '@/components/Molecules/co-form-group/CoFormGroup.vue';
import EventBus from '../../../../../eventBus';

import CoCard from '../../../../Molecules/co-card/CoCard.vue';
import CoHeading from '../../../../Atoms/co-heading/CoHeading.vue';
import CoCardPlan from '../../../../Molecules/co-card-plan/CoCardPlan.vue';
import CoAlert from '../../../../Molecules/co-alert/CoAlert.vue';
import CoTable from '../../../../Molecules/co-table/CoTable.vue';
import CoButton from '../../../../Atoms/co-button/CoButton.vue';
import CoLink from '../../../../Atoms/co-link/CoLink.vue';
import CoPlanDetail from '../../../../Organisms/co-plan-detail/CoPlanDetail.vue';
import CoPlanSelectConfigCustomise from '../../../../Organisms/co-plan-select-config-customise-modal/CoPlanSelectConfigCustomiseModal.vue';
import CoThreeDotsMenu from '../../../../Extensions/co-three-dots-menu/CoThreeDotsMenu.vue';
import CoSubscriptionScheduleCard from '../../../../Organisms/co-subscription-schedule-card/CoSubscriptionScheduleCard.vue';
import CoPlanChildMemberSelectModal from '../../../../Organisms/co-plan-child-member-select-modal/CoPlanChildMemberSelectModal.vue';

interface PlanPerUser {
    plan: object;
    userID: string;
    userName: string;
}

// NewMembership is the type of the object that is sent to the server when creating a new membership.
interface NewMembership {
    plan: PlanPerUser;

    // child plans
    childPlans: PlanPerUser[];

    // Start is the time when the membership starts.
    // if 0 means now
    start: number;
    end: number; // if 0 means forever
    anchorDate: number; // if 0 means same as start
    freeTrialDays: number; // if 0 means no free trial
    collectionMethod: 'CHARGE_AUTOMATICALLY' | 'SEND_INVOICE';
    prorate: boolean;
    prorationDate: number; // if 0 means undefined
}

export default {
    name: 'MembershipCreate',
    components: {
        CoFormGroup,
        CoSkeleton,
        CoRoundButton,
        CoSubscriptionNextInvoicePreview,
        CoCard,
        CoHeading,
        CoCardPlan,
        CoAlert,
        CoTable,
        CoButton,
        CoLink,
        CoPlanDetail,
        CoPlanSelectConfigCustomise,
        CoThreeDotsMenu,
        CoSubscriptionScheduleCard,
        CoPlanChildMemberSelectModal,
    },
    props: {
        userID: {
            type: String,
            default: null,
        },
        userName: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            membership: {
                // eslint-disable-next-line no-bitwise
                type: Object as () => NewMembership,
                default: {},
            },
            loadingPaymentMethods: true,
            subscriptionSchedule: {} as SubscriptionSchedule,
            mainPlanKey: 1,
            childPlansKey: 1000,
            childPlanTableColumns: [
                {
                    title: 'Name',
                    key: 'userName',
                },
                {
                    title: 'Plan',
                    key: 'plan.Name',
                },
                {
                    title: 'Price',
                    key: 'userID',
                },
            ],
            childPlans: [] as PlanPerUser[],
            error: null,
            creatingSubscription: false,

            paymentMethods: [],
            nextInvoice: {
                subscriptionStart: Math.round(new Date().getTime() / 1000),
            } as UpcommingInvoice,
            nextInvoicePreviewResult: null,
            nextInvoicePreviewLoading: false,
            nextInvoiceRenderKey: 0,
            disableProrate: false,
            prorate: false,
        };
    },
    created() {
        this.getUserPaymentMethods();
    },
    watch: {
        prorate(value: boolean) {
            if (value) {
                this.membership.prorate = true;
            } else {
                this.membership.prorate = false;
            }
            this.previewInvoice();
        },
        membership: {
            handler(value: NewMembership) {
                this.previewInvoice();
            },
            deep: true,
        },
        childPlans: {
            handler(value: PlanPerUser[]) {
                this.previewInvoice();
            },
            deep: true,
        },
        subscriptionSchedule: {
            handler(value: object) {
                this.previewInvoice();
            },
            deep: true,
        },
    },
    methods: {
        getUserPaymentMethods(userID = this.userID) {
            this.loadingPaymentMethods = true;
            axios({
                method: 'GET',
                url: `/admin/payment/stripe/payment-methods/${userID}?UserID=${userID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.paymentMethods = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingPaymentMethods = false;
                });
        },
        discard() {
            this.$router.push({
                name: 'AdminMemberPlan',
                params: {
                    ID: this.userID,
                },
            });
        },
        subscriptionScheduleUpdate(schedule: object) {
            this.subscriptionSchedule = schedule;
            this.nextInvoice.subscriptionStart = this.subscriptionSchedule.startDate;
            this.nextInvoice.freeTrialDays = this.subscriptionSchedule.trialDays;
            if (this.nextInvoice.freeTrialDays > 0) {
                // force proration
                this.disableProrate = true;
                this.prorate = true;
            } else {
                this.disableProrate = false;
                this.prorate = false;
            }
            this.nextInvoiceRenderKey += 1;
            this.previewInvoice();
        },
        prepareNewMembershipData() {
            if (this.childPlans && this.childPlans.length > 0) {
                this.membership.childPlans = [];
                this.childPlans.forEach((childPlan) => {
                    this.membership.childPlans.push({
                        plan: childPlan.plan,
                        userID: childPlan.userID,
                    });
                });
            }

            if (this.membership.plan.plan && this.membership.plan.plan.TaxRate) {
                if (this.membership.plan.plan.TaxRate.ID) {
                    this.membership.plan.plan.TaxRateID = this.membership.plan.plan.TaxRate.ID;
                } else if (this.membership.plan.plan.TaxRate.Value) {
                    this.membership.plan.plan.TaxRateID = this.membership.plan.plan.TaxRate.Value.ID;
                }
                // remove tax rate object from plan
                delete this.membership.plan.plan.TaxRate;

                this.membership.plan.userID = this.userID;

                if (this.membership.plan.plan.Extras && this.membership.plan.plan.Extras.length > 0) {
                    for (let k = 0; k < this.membership.plan.plan.Extras.length; k += 1) {
                        if (this.membership.plan.plan.Extras[k].TaxRate) {
                            this.membership.plan.plan.Extras[k].TaxRateID =
                                this.membership.plan.plan.Extras[k].TaxRate.ID;
                            // remove tax rate object from plan
                            delete this.membership.plan.plan.Extras[k].TaxRate;
                        }
                    }
                }
            }

            if (this.membership.childPlans) {
                for (let i = 0; i < this.membership.childPlans.length; i += 1) {
                    if (this.membership.childPlans[i].plan.TaxRate) {
                        this.membership.childPlans[i].plan.TaxRateID = this.membership.childPlans[i].plan.TaxRate.ID;
                        if (this.membership.childPlans[i].plan.TaxRate.ID) {
                            this.membership.childPlans[i].plan.TaxRateID =
                                this.membership.childPlans[i].plan.TaxRate.ID;
                        } else if (this.membership.childPlans[i].plan.TaxRate.Value) {
                            this.membership.childPlans[i].plan.TaxRateID =
                                this.membership.childPlans[i].plan.TaxRate.Value.ID;
                        }
                        // remove tax rate object from plan
                        delete this.membership.childPlans[i].plan.TaxRate;

                        if (
                            this.membership.childPlans[i].plan.Extras &&
                            this.membership.childPlans[i].plan.Extras.length > 0
                        ) {
                            for (let k = 0; k < this.membership.childPlans[i].plan.Extras.length; k += 1) {
                                if (this.membership.childPlans[i].plan.Extras[k].TaxRate) {
                                    this.membership.childPlans[i].plan.Extras[k].TaxRateID =
                                        this.membership.childPlans[i].plan.Extras[k].TaxRate.ID;
                                    // remove tax rate object from plan
                                    delete this.membership.childPlans[i].plan.Extras[k].TaxRate;
                                }
                            }
                        }
                    }
                }
            }

            this.membership.start = this.subscriptionSchedule.startDate;
            this.membership.end = this.subscriptionSchedule.endDate;
            this.membership.anchorDate = this.subscriptionSchedule.anchorDate;
            this.membership.freeTrialDays = this.subscriptionSchedule.trialDays;
            this.membership.collectionMethod = this.subscriptionSchedule.paymentCollectionMethod;
            this.membership.prorate = this.prorate;
        },
        assignSubscription() {
            this.error = null;
            this.prepareNewMembershipData();
            const data = JSON.stringify(this.membership);
            this.creatingSubscription = true;
            axios({
                method: 'POST',
                url: `/admin/community/member/membership/${this.userID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
                data,
            })
                .then((response) => {
                    if (response) {
                        // cast response to UserMembership
                        EventBus.$emit('INFO', {
                            Message: 'Subscription was assigned',
                            Details: '',
                        });
                        this.$router.push({
                            name: 'AdminMemberPlan',
                            params: {
                                ID: this.userID,
                            },
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.data && error.response.data.message) {
                        this.error = error.response.data.message;
                    }
                })
                .finally(() => {
                    this.creatingSubscription = false;
                });
        },
        showSelectMainPlanModal() {
            this.$refs.selectMainPlanModal.show();
        },
        showChildPlanModal() {
            this.$refs.selectChildPlanModal.show();
        },
        clickOnMainPlan(plan: object) {
            this.$refs.selectMainPlanModal.show();
        },
        chooseMainPlan(plan: object) {
            this.membership.plan = {
                plan,
                userID: this.userID,
            };
            this.$refs.selectMainPlanModal.hide();
            this.mainPlanKey += 1;
            this.previewInvoice();
        },
        mainPlanActionClick(key: string) {
            if (key === 'remove') {
                this.membership.plan = null;
                this.childPlans = [];
                this.membership.childPlans = [];
                this.mainPlanKey += 1;
                this.previewInvoice();
            }
        },
        chooseChildPlan(childPlan: object) {
            // if this.childPlans contain plan with childPlan.user.ID then remove it
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const index = this.childPlans.findIndex((item: any) => item.userID === childPlan.user.ID);
            if (index !== -1) {
                this.childPlans.splice(index, 1);
            }
            const planPerUser = {
                // @ts-ignore
                plan: childPlan.plan,
                // @ts-ignore
                userID: childPlan.user.ID,
                // @ts-ignore
                userName: childPlan.user.Profile.Name,
            };

            this.childPlans.push(planPerUser);
            this.$refs.selectChildPlanModal.hide();
            this.childPlansKey += 1;
        },
        removeChildPlan(userID: string) {
            const index = this.childPlans.findIndex((item: any) => item.userID === userID);
            if (index !== -1) {
                this.childPlans.splice(index, 1);
            }
            this.childPlansKey += 1;
        },
        previewInvoice() {
            this.error = null;
            // check if this.membership is empty
            if (!this.membership || !this.membership.plan || !this.membership.plan.plan) {
                return;
            }
            this.prepareNewMembershipData();
            const data = JSON.stringify(this.membership);
            this.nextInvoicePreviewLoading = true;

            axios({
                method: 'POST',
                url: `/admin/community/member/membership/preview-create`,
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response) {
                        this.nextInvoicePreviewResult = response.data;

                        if (this.nextInvoicePreviewResult) {
                            this.nextInvoice.created = this.nextInvoicePreviewResult.Created;
                            this.nextInvoice.total = this.nextInvoicePreviewResult.Total;
                            this.nextInvoice.currency = this.nextInvoicePreviewResult.Currency;
                            this.nextInvoice.raw = this.nextInvoicePreviewResult;
                            this.nextInvoiceRenderKey += 1;
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.data && error.response.data.message) {
                        this.error = error.response.data.message;
                    }
                })
                .finally(() => {
                    this.nextInvoicePreviewLoading = false;
                });
        },
    },
};
