
























import EventBus from '../../../eventBus';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';

import axios from 'axios';
import CoCard from '../../Molecules/co-card/CoCard.vue';

interface Plan {
    Name: String;
    Description: String;
    Location: String;
    ImageURL: String;

    PriceInCents: Number;
    Currency: String;
    PriceInterval: String;
    Hidden: Boolean;
}

interface Price {
    PriceInCents: number;
    Currency: string;
    TaxRate: number;
    TaxName: string;
    Inclusive: boolean;
    Unit?: string;
}

export default {
    name: 'CoCardPlanHistory',
    components: {
        CoHeadline,
        CoCard,
    },
    props: {
        plan: {
            type: (Object as () => Plan) || null,
            default: null,
            required: true,
        },
    },
    data() {
        return {
            categories: [],
            color: 'black',

            windowWidth: window.innerWidth,

            loading: false,
        };
    },
    created() {},

    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;

            if (this.windowWidth >= 768) {
                this.minWidth = true;
            } else {
                this.minWidth = false;
            }
        });
    },
    watch: {},
    methods: {
        priceObject() {
            const priceObj: Price = {
                PriceInCents: this.plan.PriceInCents,
                Currency: this.plan.Currency,
                Unit: 'Month',
                TaxName: '',
                TaxRate: 0,
                Inclusive: false,
            };

            if (this.plan.TaxRate) {
                priceObj.TaxRate = this.plan.TaxRate.Percentage;
                priceObj.TaxName = this.plan.TaxRate.Name;
                priceObj.Inclusive = this.plan.TaxRate.Inclusive;
            }
            return priceObj;
        },
    },
};
