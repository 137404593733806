














































import i18n from 'vue-i18n';
import axios from 'axios';

import CoCardEvent from '@/components/Molecules/co-card-event/CoCardEvent.vue';
import { Event } from '@/components/Molecules/co-card-event/models.ts';
import { th } from 'date-fns/locale';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';

export default {
    name: 'ListEvents',
    components: {
        CoCardEvent,
        CoButton,
        CoIcon,
        CoHeadline,
        CoCard,
        CoRoundButton,
    },
    i18n: {
        messages: {
            en: {
                eventsemptyheadline: 'Community Events',
                eventsemptysubline: 'Create events on your community platform.',
                eventsemptycta: 'Create an event',
            },
            de: {
                eventsemptyheadline: 'Community-Veranstaltungen',
                eventsemptysubline:
                    'Erstelle Veranstaltungen, um Wissen auszutauschen und gemeinsame Erfahrungen zu teilen.',
                eventsemptycta: 'Erstelle eine Veranstaltung',
            },
        },
    },
    data() {
        return {
            feed: [],
            feedNextPage: null,

            nextParams: null,

            loading: true,

            isCirclesOn: this.$store.state.circlesOn,
        };
    },
    created() {
        window.addEventListener('scroll', this.scroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.scroll);
    },
    mounted() {
        if (this.isCirclesOn) {
            this.feed = [];
            this.feedNextPage = null;
            this.nextParams = null;
            this.scroll();
            this.getEventsForCircles();
        } else {
            this.feed = [];
            this.feedNextPage = null;
            this.nextParams = null;
            this.scroll();
            this.getEvents();
        }
    },
    computed: {
        me() {
            return this.$store.state.me;
        },
        isDesktop() {
            return window.innerWidth >= 768;
        },
        isAdmin() {
            if (this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin') === true) {
                return true;
            }
            return false;
        },
        isUserAllowedToCreateEvent() {
            return this.isAdmin || !this.$store.state.permissions.EventsCreateAdminsOnly;
        },
    },
    methods: {
        mapEventStucture(object) {
            const event = object.object;
            const spaceItem = object.space;
            const e: Event = {
                id: event.ID,
                title: event.Title,
                description: event.Description,
                start: event.StartDate,
                end: event.EndDate,
                slug: event.Slug,
                image: event.ImageURL,
            };

            if (event.Participants && event.Participants.length > 0) {
                e.attend = event.Participants.includes(this.me.ID);
            }

            if (spaceItem && spaceItem.ID !== this.$store.state.space.ID) {
                e.space = {
                    id: spaceItem.ID,
                    name: spaceItem.Name,
                    favicon: spaceItem.FaviconURL,
                    primaryColor: spaceItem.PrimaryColor,
                };
            }

            return e;
        },
        getEventsForCircles() {
            this.loading = true;
            const todayTimestamp = Math.round(new Date().getTime() / 1000);

            const data = {
                Params: this.nextParams,
                // Desc: true,
                From: todayTimestamp,
                To: todayTimestamp + 60 * 60 * 24 * 5000,
            };

            axios({
                method: 'POST',
                url: '/event/list/circles/v2',
                withCredentials: true,
                data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data && response.data.Objects) {
                        this.feed = [...this.feed, ...response.data.Objects];

                        if (response.data.NextParams) {
                            this.nextParams = response.data.NextParams;
                        } else {
                            this.nextParams = null;
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // todo show error
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getEvents() {
            this.loading = true;
            this.$store
                .dispatch('getEventsV2', this.feedNextPage)
                .then((response) => {
                    if (response.objects) {
                        response.objects.forEach(function (entry) {
                            // entry.object["StartDate"] = entry.object["StartDate"]+"000";
                            // entry.object["EndDate"] = entry.object["EndDate"]+"000";
                            this.feed.push(entry);
                        }, this);
                        this.feed.sort((a, b) => a.object.StartDate - b.object.StartDate);
                        if (response.next !== '' && response.next != null) {
                            this.feedNextPage = response.next;
                        } else {
                            this.feedNextPage = null;
                        }
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                    // todo show error
                });
        },

        scroll() {
            window.onscroll = () => {
                const distanceFromBottom = document.body.scrollHeight - window.innerHeight - window.scrollY;

                const percentage = (distanceFromBottom * 100) / (document.body.scrollHeight - window.innerHeight);

                if (percentage < 30) {
                    if (this.isCirclesOn && this.nextParams != null && !this.loading) {
                        this.getEventsForCircles();
                    } else if (this.feedNextPage != null && !this.loading) {
                        this.getEvents();
                    }
                }
            };
        },
    },
};
