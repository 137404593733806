<template>
    <div>
        <CoEventCreate />
    </div>
</template>

<script>
import CoEventCreate from '@/components/Pages/user/event/CoEventCreate.vue';

export default {
    name: 'CreateEventWrapper',
    components: {
        CoEventCreate,
    },
};
</script>
