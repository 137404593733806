<template>
    <div>
        <div class="px-sm-4 d-flex align-items-center justify-content-between">
            <b-button
                variant="primary"
                :to="'/admin/monetization/time-passes/edit/' + timepass.ID"
                :disabled="!$store.state.billingTurnedOn"
                >Edit</b-button
            >
        </div>

        <!-- plans start -->
        <!-- if loading -->
        <div class="row m-0 w-100">
            <div class="col-lg-6 p- pr-md-1 mt-4">
                <div class="timepass-card-long pt-3 pb-2 round-unify-xs p-4">
                    <div class="d-flex justify-content-between align-items-center mb-2">
                        <h1>{{ timepass.Name }}</h1>
                    </div>

                    <p class="pt-3">Price: {{ FinalPrice }} EUR</p>
                    <p class="pt-2">Quantity: {{ timepass.Number }} times</p>
                    <p class="pt-2" v-if="taxRate">Tax Rate: {{ taxRate.Percentage }} %</p>
                    <p class="pt-2">Duration: {{ timepass.Duration }} hours</p>
                    <div class="d-flex flex-column mt-5">
                        <small>Created at {{ this.created }}</small>
                        <small class="mt-2">Last updated at {{ this.updated }}</small>
                    </div>
                </div>
            </div>
        </div>
        <!-- plans end -->
    </div>
</template>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';
</style>
<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<script>
import axios from 'axios';
import Vue from 'vue';
import EventBus from '@/eventBus';

import moment from 'moment/moment';

export default {
    name: 'AdminViewTimepass',
    components: {},
    data() {
        return {
            form: {},
            tabIndex: 0,
            loading: false,
            timepass: {},

            cancellation: 28,
            interval: 'month',

            currency: 'EUR',

            created: null,
            updated: null,

            taxRate: null,
            image: null,
            imageURL: '',
            search: '',
            price: 0,

            FinalPrice: null,

            taxRates: [],
            loadingTaxRate: false,
        };
    },
    created() {
        const { ID } = this.$route.params;
    },
    mounted() {
        this.getTimePassByID(this.$route.params.ID);
    },
    methods: {
        getTimePassByID(ID) {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/time-pass/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        const catchTimePass = response.data.Passes.find((item) => item.ID === ID);
                        this.timepass = catchTimePass;
                        this.created = moment(this.timepass.CreatedAt * 1000).format('DD.MM.YY');
                        this.updated = moment(this.timepass.UpdatedAt * 1000).format('DD.MM.YY');
                        if (this.timepass.PriceInCents) {
                            this.price = this.timepass.PriceInCents / 100.0;
                        }
                        if (this.timepass.TaxRateID) {
                            this.getTaxRate(this.timepass.TaxRateID);
                        }
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
        getTaxRate(taxID) {
            axios({
                method: 'GET',
                url: `/space/tax/${taxID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.taxRate = response.data;
                        this.calculateFinalPrice(this.taxRate);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        calculateFinalPrice(tax) {
            if (tax && tax.Inclusive) {
                this.FinalPrice = this.price;
            } else {
                this.FinalPrice = (this.price * (tax.Percentage / 100 + 1)).toFixed(2);
            }
        },
    },
};
</script>
