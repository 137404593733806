<template>
    <b-overlay :show="loading">
        <div class="">
            <!-- header start -->
            <Header :title="$t('labels.planchange')"></Header>
            <!-- header end -->
            <div v-if="step == 1" class="" style="max-width: 600px">
                <coPlanDetail :plan="plan">
                    <template v-slot:header-button>
                        <co-button :label="$t('labels.planchoose')" variant="primary" @click="next" />
                    </template>
                </coPlanDetail>
            </div>
            <div v-if="step == 2">
                <ChangePlanUpgrades :plan="plan" @nextStep="upgradesNext"></ChangePlanUpgrades>
            </div>
            <div v-if="step == 3">
                <ChangePlanReview
                    :plan="plan"
                    :selected="selected"
                    @changePlan="checkout()"
                    @prevStep="back()"
                ></ChangePlanReview>
            </div>
        </div>
    </b-overlay>
</template>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';
import CoButton from '../../../components/Atoms/co-button/CoButton.vue';

export default {
    components: { CoButton },
    name: 'ChangePlanCheckout',
    data() {
        return {
            plan: null,
            updatedPlan: null,
            extras: [],
            step: 0,
            selected: [],
            me: this.$store.state.me,
            imageURL: '',
            loading: false,
        };
    },
    created() {
        const { ID } = this.$route.params;

        this.loading = true;
        axios({
            method: 'GET',
            url: `space/plan/${ID}`,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response && response.data) {
                    this.plan = response.data;
                    this.step = 1;
                    this.loading = false;
                }
            })
            .catch((error) => {
                console.log(error);
                this.loading = false;
            });
    },
    mounted() {
        // this.prepareData();
    },
    methods: {
        next() {
            this.step += 1;
        },
        back() {
            this.step -= 1;
        },
        upgradesNext(selected) {
            this.selected = selected;
            this.prepareData();
            this.step += 1;
        },
        prepareData() {
            const extras = [];
            if (this.selected) {
                this.selected.forEach((element) => {
                    extras.push({ ID: element.ID, Version: element.Version });
                });
            }

            this.updatedPlan = {
                PlanID: this.plan.ID,
                PlanVersion: this.plan.Version,
                Extras: extras,
            };
        },
        checkout() {
            this.prepareData();
            this.loading = true;

            let changePlanUrl = 'me/change-plan';
            if (this.$unleash.isEnabled('frontend.change-plan')) {
                changePlanUrl = 'me/change-planV2';
            }
            axios({
                method: 'POST',
                url: changePlanUrl,
                withCredentials: true,
                data: JSON.stringify(this.updatedPlan),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Plan successfully updated!',
                        Details: '',
                    };
                    EventBus.$emit('INFO', msg);
                    this.$router.push(`/account/plan`);
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to update plan',
                        Details: error,
                    };
                    EventBus.$emit('ERROR', msg);
                    console.log(error);
                });
        },
    },
};
</script>
