<template>
    <div class="d-flex align-items-center">
        <div class="profile-avatar" v-if="show">
            <LazyLoadImg
                :src="url"
                :relativeHeight="100"
                class="card-image-container"
                round
                profile-placeholder
                dark-variant
                alt=""
                style=""
            >
            </LazyLoadImg>
        </div>
        <span v-if="Name" class="ml-3 d-flex align-items-center pointer">{{ Name }}</span>
    </div>
</template>
<script>
export default {
    name: 'ProfileCircleWithoutRedirect',
    data() {
        return {
            show: true,
            url: null,
        };
    },
    props: ['ImageURL', 'Slug', 'Name'],
    watch: {
        ImageURL(newVal) {
            this.show = false;
            this.show = true;
            this.url = newVal;
        },
    },
    mounted() {
        this.url = this.ImageURL;
    },
};
</script>
