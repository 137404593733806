<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.search_input {
    border-radius: 50rem !important;
}

.threads-wrapper {
    height: calc(100vh - 186px);
    overflow: auto;

    @media (max-width: 768px) {
        max-height: calc(100vh - 180px);
        height: calc(100vh - 180px);
    }
}

.threads-wrapper:hover {
    overflow: auto;
}

.user-select {
    height: calc(100vh / 3) !important;
}

.message-list {
    width: 100%;
}

@media (min-width: 768px) {
    .message-list {
        width: 300px;
        min-width: 300px;
        margin-right: 15px;
    }
}

.list-chat {
    background: transparent;
    overflow: visible;

    &:hover {
        text-decoration: none;
    }

    &.router-link-exact-active {
        background: white;

        // color: white !important;
        .text-body {
            // color: white !important;
        }
    }

    div.hasnewmessages {
        position: relative;
        padding-right: 2.5rem !important;
    }

    div.hasnewmessages::after {
        content: ' ';
        width: 1rem;
        height: 1rem;
        position: absolute;
        right: 1rem;
        background-color: @purple;
        border-radius: 10rem;
    }
}

.user {
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
}

.user img,
.user .user-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    image-orientation: from-image;
    outline: none;
    text-decoration: none;
    border: 0;
}

.user .file {
    border: none;
    font-size: 15px;
    line-height: 100px;
    background: rgba(0, 0, 0, 0.2);
    width: 100px;
    position: absolute;
    padding: 0 !important;
    text-align: center;
    left: 0;
    top: 0;
    border-radius: 50%;
    cursor: pointer;
    display: block;
}

.user:hover > .file {
    background: rgba(0, 0, 0, 0.5);
}

.user .file input {
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
    cursor: pointer;
}

.ti-tag::before {
    content: none !important;
    display: none !important;
}
</style>

<template>
    <div>
        <div class="container-fluid">
            <div class="row mt-1 mb-3">
                <div class="col d-flex align-items-center justify-content-between px-3 p-md-0">
                    <div class="mb-0 d-flex align-items-center">
                        <h2 class="">
                            {{ $t('labels.messenger') }}
                        </h2>
                    </div>
                    <b-dropdown variant="ml-2 p-0" menu-class="mt-1border-1" no-caret right>
                        <template v-slot:button-content>
                            <b-button variant="primary">{{ $t('labels.chatnew') }}</b-button>
                        </template>
                        <b-dropdown-item v-b-modal.newchat-modal-1>
                            <b-icon icon="person"></b-icon>
                            {{ $t('labels.chatnew') }}
                        </b-dropdown-item>
                        <b-dropdown-item v-b-modal.newgroup-modal-1>
                            <b-icon icon="people"></b-icon>
                            {{ $t('labels.groupnew') }}
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>

            <div class="row">
                <!-- visible on desktop -->
                <div
                    class="col-4 d-none d-sm-flex flex-column align-items-stretch p-0 pr-2 threads-wrapper"
                    v-if="isDesktop"
                >
                    <div
                        v-if="mychats.length == 0 && !loading"
                        class="list-chat d-block round-unify mb-2 w-100"
                        style="color: #212529"
                    >
                        <div class="d-flex p-1">
                            <coText :text="$t('nothreads')" variant="mute" />
                        </div>
                    </div>

                    <div v-if="loading" class="list-chat d-block round-unify mb-2 w-100" style="color: #212529">
                        <b-spinner variant="primary" label="loading"></b-spinner>
                    </div>

                    <div v-for="chat in mychats" :key="chat.object.ID">
                        <router-link
                            class="list-chat d-block round-unify mb-2 w-100"
                            style="color: #212529"
                            :to="'/messenger/' + chat.object.ID"
                        >
                            <!-- <Card :object="chat"></Card> -->
                            <div
                                class="d-flex p-2 align-items-center"
                                :class="{
                                    hasnewmessages: isThereAnyMessage(chat.object.ID),
                                }"
                            >
                                <div class="">
                                    <ProfileCircle
                                        v-if="!chat.object.GroupChat && chat.object.Peer"
                                        :ImageURL="chat.object.Peer.Profile.ImageURL"
                                    ></ProfileCircle>
                                    <ProfileCircle
                                        v-else-if="chat.object.GroupChat"
                                        :ImageURL="chat.object.ImageURL"
                                    ></ProfileCircle>
                                    <ProfileCircle v-else></ProfileCircle>
                                </div>
                                <div class="ml-3" v-if="!chat.object.GroupChat && chat.object.Peer">
                                    <span>{{ chat.object.Peer.Profile.Name }}</span>
                                    <!-- <small class="d-block text-body mt-1">Last message here....</small> -->
                                </div>
                                <div class="ml-3" v-else-if="chat.object.GroupChat">
                                    <span>{{ chat.object.Title }}</span>
                                    <small class="d-block text-body mt-1"
                                        >{{ chat.object.Peers.length }}
                                        {{ $tc('labels.member', chat.object.Peers.length) }}</small
                                    >
                                </div>
                                <div class="ml-3" v-else>
                                    <span>User does not exist or was deleted</span>
                                </div>
                                <div class="flex-grow-1"></div>
                            </div>
                        </router-link>
                    </div>
                </div>

                <!-- visible on mobile -->
                <div
                    class="col-12 px-2 col-sm-8 chat flex-grow-1 h-100 pr-0"
                    v-if="!isDesktop && !navigationVisibleOnMobile"
                >
                    <div
                        v-if="mychats.length == 0"
                        class="list-chat d-block round-unify mb-1 mb-xs-3 w-100"
                        style="color: #212529"
                    >
                        <div class="d-flex px-1">
                            <coAlert :text="$t('nothreads')" variant="grey" />
                        </div>
                    </div>
                    <div v-for="chat in mychats" :key="chat.object.ID">
                        <router-link
                            class="list-chat d-block round-unify mb-1 mb-xs-3 w-100"
                            style="color: #212529"
                            :to="'/messenger/' + chat.object.ID"
                        >
                            <!-- <Card :object="chat"></Card> -->
                            <div
                                class="d-flex pl-3 pb-3 pt-3 pr-1 align-items-center"
                                :class="{
                                    hasnewmessages: isThereAnyMessage(chat.object.ID),
                                }"
                            >
                                <div class="">
                                    <ProfileCircle
                                        v-if="!chat.object.GroupChat && chat.object.Peer"
                                        :ImageURL="chat.object.Peer.Profile.ImageURL"
                                    ></ProfileCircle>
                                    <ProfileCircle
                                        v-else-if="chat.object.GroupChat"
                                        :ImageURL="chat.object.ImageURL"
                                    ></ProfileCircle>
                                    <ProfileCircle v-else></ProfileCircle>
                                </div>
                                <div class="ml-3" v-if="!chat.object.GroupChat && chat.object.Peer">
                                    <span>{{ chat.object.Peer.Profile.Name }}</span>
                                    <!-- <small class="d-block text-body mt-1">Last message here....</small> -->
                                </div>
                                <div class="ml-3" v-else-if="chat.object.GroupChat">
                                    <span>{{ chat.object.Title }}</span>
                                    <small class="d-block text-body mt-1">{{ chat.object.Peers.length }} Members</small>
                                </div>
                                <div class="ml-3" v-else>
                                    <span>User does not exist or deleted</span>
                                </div>
                                <div class="flex-grow-1"></div>
                            </div>
                        </router-link>
                        <!-- </div> -->
                    </div>
                </div>
                <div
                    class="col-12 col-sm-8 chat flex-grow-1 h-100 p-0"
                    v-else-if="navigationVisibleOnMobile || isDesktop"
                >
                    <div class="bg-white h-100 round-unify-2">
                        <router-view :key="$route.path" />
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="newchat-modal-1" ref="newchat-modal" size="md" :title="$t('labels.chatnew')" hide-footer centered>
            <div class="d-flex flex-grow-1">
                <div class="align-self-start w-100">
                    <div class="mt-0">
                        <b-input
                            type="text"
                            class="rounded-pill"
                            v-model="usersearch"
                            :placeholder="$t('placeholders.search')"
                            @input="usersSearchResult"
                            required
                        >
                        </b-input>
                    </div>
                    <div class="mt-3 user-select overflow-auto">
                        <div v-if="searchingUsers">
                            <b-spinner variant="primary" label="Searching"></b-spinner>
                        </div>
                        <div
                            v-for="user in users"
                            @click="createThread(user)"
                            :key="user.ID"
                            class="d-block py-2 userdiv round-unify-xs"
                        >
                            <ProfileCircle :ImageURL="user.Profile.ImageURL" :Name="user.Profile.Name"></ProfileCircle>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal
            id="newgroup-modal-1"
            ref="newgroup-modal"
            size="md"
            :title="$t('labels.groupnew')"
            :ok-title="$t('labels.create')"
            :cancel-title="$t('labels.cancel')"
            :ok-disabled="
                groupChat['Title'] == null ||
                groupChat['Title'] === '' ||
                participants == null ||
                participants.length == 0
                    ? true
                    : false
            "
            @ok="publishGroupChat"
            centered
        >
            <div class="d-flex flex-grow-1">
                <div class="align-self-start w-100">
                    <div class="row w-100 justify-content-center my-4">
                        <div class="user">
                            <img
                                v-show="groupChat['ImageURL'] != null"
                                :src="groupChat['ImageURL']"
                                alt=""
                                :key="groupChat.ImageURLKey"
                            />
                            <div class="file text-center float-r text-light">
                                <b-icon icon="camera-fill" scale="2.5"></b-icon>
                                <input type="file" name="file" @change="previewGroupImage" />
                            </div>
                        </div>
                    </div>
                    <div class="mb-2">
                        <b-input
                            type="text"
                            class="rounded-pill"
                            v-model="groupChat['Title']"
                            :placeholder="$t('labels.name')"
                            required
                        >
                        </b-input>
                    </div>
                    <div class="mt-0">
                        <vue-tags-input
                            id="participants"
                            c
                            v-model="participant"
                            :tags="participants"
                            :separatos="separators"
                            :placeholder="$t('addparticipants')"
                            @before-adding-tag="addTag"
                        />
                    </div>
                    <div class="mt-3 overflow-auto user-select">
                        <div class="d-flex flex-column" :key="usersRerender">
                            <div v-if="searchingUsers">
                                <b-spinner variant="primary" label="Searching"></b-spinner>
                            </div>
                            <div
                                v-for="user in users"
                                :key="user.ID"
                                @click="addUserToGroupChat(user)"
                                class="d-flex align-items-center justify-content-between py-2 userdiv round-unify-xs"
                            >
                                <ProfileCircle
                                    :ImageURL="user.Profile.ImageURL"
                                    :Name="user.Profile.Name"
                                ></ProfileCircle>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import { get } from 'lodash';
import VueTagsInput from '@johmun/vue-tags-input';
import i18n from 'vue-i18n';
import EventBus from '../../eventBus';

const debounce = require('debounce');

export default {
    i18n: {
        messages: {
            en: {
                nothreads: 'You don’t have any chats yet. Start a new one.',
                addparticipants: 'Add people to your group',
            },
            de: {
                nothreads: 'Du hast noch keine Chats. Starte einen neuen.',
                addparticipants: 'Mitglieder hinzufügen',
            },
        },
    },
    name: 'Messenger',
    components: {
        VueTagsInput,
    },
    data() {
        return {
            usersearch: '',
            users: [],
            searchingUsers: false,
            usersRerender: 0,

            mychats: [],
            nextChatsLink: null,
            loading: false,
            isLoading: false,
            navigationVisibleOnMobile: false,
            windowWidth: 0,

            groupChat: {
                ImageURLKey: 0,
            },

            participants: [],
            participant: '',
            separators: [';'],

            community: [],
        };
    },
    computed: {
        isDesktop() {
            return this.windowWidth >= 768;
        },
    },
    watch: {
        participant: 'usersSearchForGroupChat',
        $route(to, from) {
            if (to.name === 'Chat' || to.name === 'EditChat') {
                this.navigationVisibleOnMobile = true;
            } else {
                this.navigationVisibleOnMobile = false;
            }
        },
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });
        this.windowWidth = window.innerWidth;

        if (this.$route.name === 'Chat' || this.$route.name === 'EditChat') {
            this.navigationVisibleOnMobile = true;
        } else {
            this.navigationVisibleOnMobile = false;
        }

        this.getMyChats();
        this.getCommunity();

        EventBus.$on(
            'UPDATE_THREAD',
            (payLoad) => {
                this.getMyChats();
            },
            this
        );
    },
    destroyed() {
        EventBus.$off('UPDATE_THREAD');
    },
    methods: {
        isThereAnyMessage(threadID) {
            const newMessages = get(this.$store, `state.newMessages.MessagesPerThread.${threadID}`);

            return !!newMessages;
        },
        uploadImage() {
            this.$store
                .dispatch('imageUpload', {
                    file: this.groupChat.ImageFile,
                    url: '/upload/image/group-chat-avater',
                })
                .then((response) => {
                    this.groupChat.ImageURL = response;
                    this.createGroupChat();
                })
                .catch((error) => {
                    this.isLoading = false;
                    const errorMsg = {
                        Message: 'Could not upload image, please try again.',
                        Details: error.response.data,
                    };
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.message === 'Request Entity Too Large'
                    ) {
                        errorMsg.Message = 'The file you tried to upload is too large. Max 10 MB.';
                    }
                    EventBus.$emit('ERROR', errorMsg);
                });
        },

        publishGroupChat(event) {
            this.isLoading = true;

            if (this.groupChat.ImageFile != null) {
                this.uploadImage();
            } else {
                this.createGroupChat();
            }
        },

        createGroupChat() {
            const data = new Object(this.groupChat);
            delete data.ImageFile;
            const participants = [this.$store.state.me.ID];
            this.participants.forEach((element) => {
                participants.push(element.userID);
            });
            data.Participants = participants;

            axios({
                method: 'POST',
                url: '/chat/t/group-chat',
                withCredentials: true,
                data: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.$refs['newgroup-modal'].hide();
                        this.getMyChats(response.data.ID);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getCommunity() {
            this.$store
                .dispatch('getCommunity', this.feedNextPage)
                .then((response) => {
                    if (response != null && response.objects != null) {
                        response.objects.forEach((element) => {
                            if (this.$store.state.me.ID !== element.object.ID) {
                                this.community.push(element.object);
                            }
                        });
                        this.users = this.community;
                    }
                })
                .catch((error) => {
                    // todo show error
                });
        },

        previewGroupImage(event) {
            const file = event.target.files[0];
            this.groupChat.ImageURL = URL.createObjectURL(file);
            this.groupChat.ImageFile = file;
            this.groupChat.ImageURLKey++;
        },
        search() {},
        getMyChats(routeTo) {
            this.loading = true;
            let url = '/chat/t/list';
            if (this.$store.state.circlesOn) {
                url = '/chat/t/listV2';
            }
            axios({
                method: 'GET',
                url,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response.data && response.data.objects.length !== 0) {
                        this.mychats = response.data.objects;
                        this.nextChatsLink = response.data.next;
                        if (routeTo != null) {
                            this.$router.replace(`/messenger/${routeTo}`);
                        } else if (this.$route.name !== 'Chat' && this.$route.name !== 'EditChat') {
                            this.$router.push(`messenger/${this.mychats[0].object.ID}`);
                        }
                    }

                    const threads = {};
                    this.mychats.forEach((element, index) => {
                        threads[element.object.ID] = element.object;
                    });
                    this.$store.commit('SET_THREADS', threads);
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },

        usersSearchForGroupChat() {
            if (this.participant === '') {
                return;
            }
            this.users = [];
            axios({
                method: 'GET',
                url: `/search/v2/user/${this.participant}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.users = [];
                        response.data.forEach(function (entry) {
                            if (this.$store.state.me.ID !== entry.ID) {
                                this.users.push(entry);
                            }
                        }, this);
                        this.searchingUsers = false;
                    }
                })
                .catch((error) => {
                    this.users = [];
                    this.searchingUsers = false;
                });
        },

        addUserToGroupChat(user) {
            const participant = {
                text: user.Profile.Name,
                userID: user.ID,
                classes: 'no-before',
            };
            if (!this.isDuplicate(this.participants, participant)) {
                this.participant = '';
                this.participants.push(participant);
            }
        },
        addTag(obj) {
            // made to stop adding by enter
        },
        removeUserFromGroup(index) {
            this.participants.split(index, 1);
        },
        isDuplicate(tags, tag) {
            return tags.map((t) => t.text).indexOf(tag.text) !== -1;
        },

        usersSearchResult: debounce(function (e) {
            this.searchingUsers = true;
            this.users = [];
            axios({
                method: 'GET',
                url: `/search/v2/user/${this.usersearch}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.users = [];
                        response.data.forEach(function (entry) {
                            if (this.$store.state.me.ID !== entry.ID) {
                                this.users.push(entry);
                            }
                        }, this);
                        this.searchingUsers = false;
                    }
                })
                .catch((error) => {
                    this.searchingUsers = false;
                });
        }, 200),

        createThread(user) {
            const thread = {
                Participants: [this.$store.state.me.ID, user.ID],
            };
            axios({
                method: 'POST',
                url: '/chat/t',
                withCredentials: true,
                data: JSON.stringify(thread),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.getMyChats(response.data.ID);
                        this.$refs['newchat-modal'].hide();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>
