
























import EventBus from '@/eventBus';

import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoDropdown from '@/components/Molecules/co-dropdown/CoDropdown.vue';
import CoDropdownItem from '@/components/Molecules/co-dropdown-item/CoDropdownItem.vue';

export default {
    name: 'CoShare',
    components: {
        CoRoundButton,
        CoDropdown,
        CoDropdownItem,
    },
    props: {
        title: {
            type: String,
            default: '',
        },

        url: {
            type: String,
            default: '',
        },

        text: {
            type: String,
            default: '',
        },
    },
    computed: {
        shareData() {
            return {
                title: this.title,
                text: this.text,
                url: this.url,
            };
        },

        isWebShareSupported() {
            return !!navigator.share;
        },
    },
    methods: {
        shareInTwitter() {
            const shareUrl = `https://twitter.com/intent/tweet?url=${this.url}`;
            window.open(shareUrl, '_blank');
        },
        shareInLinkedin() {
            const shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${this.url}`;
            window.open(shareUrl, '_blank');
        },
        shareInFacebook() {
            const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${this.url}`;
            window.open(shareUrl, '_blank');
        },
        showShareDialog() {
            // user window.navigator.share() to share the content
            if (window.navigator.share) {
                window.navigator
                    .share(this.shareData)
                    .then(() => console.log('Successful share'))
                    .catch((error) => console.log('Error sharing', error));
            } else {
                console.log('WebShare API not supported.');
            }
        },

        async copyLink() {
            try {
                await navigator.clipboard.writeText(this.url);
                EventBus.$emit('INFO', {
                    Message: this.$t('messages.copiedtoclipboard'),
                });
            } catch (err) {
                EventBus.$emit('ERROR', {
                    Message: this.$t('labels.failedGeneric'),
                });
            }
        },
        shareInPost() {
            EventBus.$emit('STARTNEWPOST', `👉 <a href="${this.shareData.url}">${this.shareData.title}</a>`);
        },
    },
};
