<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

a {
    color: inherit;
    text-decoration: inherit;
}
</style>

<template>
    <router-link class="bg-white round-unify w-100 d-flex align-items-start overflow-hidden" tag="a" :to="to">
        <div class="d-flex w-100 flex-column align-items-start item-data pl-3 py-3">
            <div class="d-flex flex-column w-100 justify-content-around">
                <div class="d-flex w-100 justify-content-between">
                    <div class="mb-2 mb-xs-3">
                        <small>Name</small> <br />
                        <h2 class="title">
                            {{ object.DisplayName }}
                        </h2>
                    </div>
                    <div class="d-block float-right">
                        <b-dropdown
                            variant="px-2"
                            class="d-menu"
                            menu-class="mt-0 border-1"
                            no-caret
                            right
                            round
                            style="position: relative"
                        >
                            <template v-slot:button-content>
                                <b-icon icon="three-dots" class="m-0" scale="1.2"></b-icon>
                            </template>
                            <b-dropdown-item class="d-action" @click="edit(object.ID)">Edit</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="mr-3">
                        <small>Rate</small> <br />
                        <h3>{{ object.Percentage }}%</h3>
                    </div>
                    <div>
                        <small>Rate type</small> <br />
                        <h3 v-if="object.Inclusive">Inclusive</h3>
                        <h3 v-else>Exclusive</h3>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="mr-3">
                        <small>Tax type</small> <br />
                        <h3>VAT</h3>
                    </div>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'TaxRateCard',
    props: ['object', 'to'],
    data() {
        return {};
    },
    beforeCreate() {},
    created() {},

    mounted() {},
    methods: {
        edit(id) {
            this.$emit('editCallBack', id);
        },
    },
};
</script>
