




























































































































import { PropType } from 'vue';

import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoText from '../../Atoms/co-text/CoText.vue';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoSkeleton from '../../Atoms/co-skeleton/CoSkeleton.vue';
import CoCard from '../../Molecules/co-card/CoCard.vue';
import CoInvoiceDetail from '../../Molecules/co-invoice-detail/CoInvoiceDetail.vue';
import CoModal from '../co-modal/CoModal.vue';

import { UpcommingInvoice } from './models.ts';

import { format } from 'date-fns';

export default {
    name: 'CoSubscriptionNextInvoicePreview',
    components: {
        CoIcon,
        CoHeadline,
        CoCard,
        CoText,
        CoSkeleton,
        CoModal,
        CoInvoiceDetail,
        CoButton,
    },
    props: {
        invoice: {
            type: Object as PropType<UpcommingInvoice>,
            default: {} as UpcommingInvoice,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            rendringKey: 0,
            freeTrialEnd: '',
        };
    },
    watch: {
        invoice: {
            handler(value: UpcommingInvoice) {
                this.rendringKey += 1;
            },
            deep: true,
        },
    },

    computed: {
        freeTrialEndDate() {
            if (this.invoice.freeTrialEnd) {
                return this.timestampToDate(this.invoice.freeTrialEnd);
            }

            // add free trial days to the subscription start date using date-fns
            return this.timestampToDate(this.invoice.subscriptionStart + this.invoice.freeTrialDays * 24 * 60 * 60);
        },
    },

    methods: {
        price(priceInCents: number) {
            if (!priceInCents) {
                return '0.00';
            }

            if (priceInCents < 0) {
                return `-${this.price(-priceInCents)}`;
            }
            return (priceInCents / 100).toFixed(2);
        },
        currencyToSymbol(currency: string) {
            // lower case to avoid issues with the switch
            currency = currency.toLowerCase();

            switch (currency) {
                case 'eur':
                    return '€';
                case 'usd':
                    return '$';
                default:
                    return currency;
            }
        },
        timestampToDate(timestamp: number) {
            if (timestamp === undefined || timestamp === null || timestamp === 0) {
                return 'unavailable';
            }
            return format(new Date(timestamp * 1000), 'dd MMMM yyyy');
        },
        showInvoicePreviewDetails() {
            this.$refs.invoicePreviewDetailsModal.show();
        },
    },
};
