<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>
<template>
    <div>
        <!-- search and actions start -->
        <div class="px-sm-4 d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center bg-white search-bar-wrapper rounded-pill">
                <b-icon class="mr-2" icon="search"></b-icon>
                <div class="search-input-wrapper">
                    <input
                        class="search-input"
                        type="search"
                        v-model="search"
                        placeholder="Search"
                        aria-label="Search"
                    />
                </div>
            </div>

            <b-button id="import_btn" variant="primary" :disabled="!1" @click="importPolicies">Import</b-button>
            <b-tooltip
                label="Import tags (tokens/creddentials) from Deister access
      system"
                target="import_btn"
                position="is-bottom"
                >Imports tags (tokens/credentials) from Deister access system</b-tooltip
            >
        </div>
        <!-- search and actions end -->

        <!-- tags start -->
        <div class="container-fluid p-0 m-0">
            <!-- if emtpy and not loading -->
            <div class="row text-center m-0 mt-5" v-if="objects.length === 0 && !loading">
                <div class="col">
                    <h2>Get started!</h2>
                    <div class="mb-4">Import tags</div>
                </div>
            </div>
            <!-- if loading -->
            <div class="row text-center m-0 mt-5" v-else-if="loading">
                <div class="col">
                    <b-spinner variant="primary" label="loading"></b-spinner>
                </div>
            </div>
            <div class="m-0 mt-4 px-sm-4">
                <div class="mb-2" v-for="(item, i) in filteredItems" :key="item.ID">
                    <AccessPolicyCard :object="item"></AccessPolicyCard>
                </div>
            </div>
        </div>
        <!-- tags end -->
    </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import EventBus from '@/eventBus';

export default {
    name: 'Policies',
    components: {},
    data() {
        return {
            tabIndex: 0,
            loading: false,
            objects: [
                // {
                //   ID: 'test',
                //   Name: 'test',
                //   Description: 'test description',
                //   Type: 0,
                //   SystemPolicyID: 34,
                //   Source: 0,
                //   CreatedAt: 1649934457,
                //   UpdatedAt: 1649934457,
                // },
            ],

            search: '',
        };
    },
    mounted() {
        this.listPolicies();
    },
    computed: {
        filteredItems() {
            return this.objects.filter((item) => {
                if (item.Name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 == true) {
                    return true;
                }
                if (item.Description.indexOf(this.search) > -1 == true) {
                    return true;
                }
                return false;
            });
        },
    },
    methods: {
        importPolicies() {
            this.objects = true;
            axios
                .get('/admin/space/access/policy/import')
                .then((response) => {
                    if (response && response.data) {
                        this.objects = response.data.Policies;
                    }
                    this.loading = false;
                    EventBus.$emit('INFO', {
                        message: 'Policies imported successfully',
                    });
                })
                .catch((error) => {
                    this.loading = false;
                    EventBus.$emit('ERROR', {
                        message: 'Error importing policies',
                        details: error,
                    });
                });
        },
        listPolicies() {
            this.loading = true;
            axios
                .get('/admin/space/access/policy/list')
                .then((response) => {
                    if (response && response.data) {
                        this.objects = response.data;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        back() {
            if (this.tabIndex > 0) {
                this.tabIndex -= 1;
            }
        },
        linkClass(idx) {
            if (this.tabIndex === idx) {
                return ['activeOne'];
            }
            return ['not-activeOne'];
        },
    },
};
</script>
