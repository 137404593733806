<template>
    <div>
        <div class="ml-md-4">
            <p class="text-muted">Manage your coapp subscription, payment methods and invoices</p>

            <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
                <div class="mt-4 bg-white p-4 round-unify" v-if="space && space.SubscriptionCanceledTo">
                    <b-alert show variant="warning" class="text-dark">
                        Your coapp subscription was cancelled. It will terminate on
                        {{ space.SubscriptionCanceledToStr }}.
                        <a href="" @click="getCustomerPortalURL">Renew your subscription</a>
                        to continue using coapp.
                    </b-alert>

                    <p>Renew your subscription to continue using coapp.</p>
                    <p class="mt-2">
                        Download invoices and manage your payment credentials.
                        <br />
                        Subscription can be managed only by user who initialy created it.
                        <br />
                        Billing manager:
                        <router-link v-if="billingAdmin" target="_blank" :to="'/profile/' + billingAdmin.Slug"
                            >{{ billingAdmin.Profile.Name }}
                        </router-link>
                    </p>
                    <b-button
                        variant="primary"
                        @click="getCustomerPortalURL"
                        :disabled="$store.state.me.ID !== space.PaymentMethodeCreatedBy"
                        >Manage Account
                    </b-button>
                </div>
                <div
                    class="mt-4 bg-white p-4 round-unify"
                    v-else-if="space && space.StripeCustomerID && space.Suspended == true"
                >
                    <h2 class="text-danger">Your space has been suspended</h2>
                    <h3 class="mt-2" v-if="space.SuspentionReason">
                        <strong>Reason:</strong> {{ space.SuspentionReason }}
                    </h3>

                    <p class="mt-2">
                        Download invoices and manage your payment credentials. Subscription can be managed only by user
                        who initialy created it.
                        <br />
                        Billing manager:
                        <router-link v-if="billingAdmin" target="_blank" :to="'/profile/' + billingAdmin.Slug"
                            >{{ billingAdmin.Profile.Name }}
                        </router-link>
                    </p>
                    <b-button
                        variant="primary"
                        @click="getCustomerPortalURL"
                        :disabled="$store.state.me.ID !== space.PaymentMethodeCreatedBy"
                        >Manage Account
                    </b-button>
                </div>
                <div
                    class="mt-4 bg-white p-4 round-unify"
                    v-else-if="space && space.StripeCustomerID && space.Suspended != true"
                >
                    <h3>Download invoices and manage your payment credentials.</h3>

                    <p class="mt-2">
                        Subscription can be managed only by user who initialy created it.
                        <br />
                        Billing manager:
                        <router-link v-if="billingAdmin" target="_blank" :to="'/profile/' + billingAdmin.Slug"
                            >{{ billingAdmin.Profile.Name }}
                        </router-link>
                    </p>
                    <b-button
                        variant="primary"
                        @click="getCustomerPortalURL"
                        :disabled="$store.state.me.ID !== space.PaymentMethodeCreatedBy"
                        >Manage Account
                    </b-button>
                </div>
                <div class="mt-4 bg-white p-4 round-unify" v-else-if="space && restFreeTrial > 0">
                    <h2>Your trial will expire in {{ restFreeTrial }} days</h2>
                    <p class="mt-3">
                        Subscribe now for €99/Month with 100 users included, to use coapp after trial period. <br />
                        Every additional user €0.99/Month.
                    </p>
                    <b-button variant="primary" @click="getCheckoutURL">Subscribe now</b-button>
                </div>
                <div class="mt-4 bg-white p-4 round-unify" v-else-if="space && restFreeTrial == 0">
                    <h2>Your free trial is over</h2>
                    <p class="mt-3">
                        Subscribe now for €99/Month with 100 users included, to continue using coapp. <br />
                        Every additional user €0.99/Month.
                    </p>
                    <b-button variant="primary" @click="getCheckoutURL">Subscribe now</b-button>
                </div>
            </b-overlay>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import EventBus from '../../../eventBus';

const moment = require('moment');

export default {
    name: 'Billing',

    data() {
        return {
            loading: false,
            space: null,
            domainFree: null,
            restFreeTrial: 0,
            billingAdmin: null,
        };
    },
    computed: {
        isDomainFree() {
            return this.domainFree;
        },
    },
    created() {
        this.getSpaceSettings();
    },
    mounted() {},
    watch: {},
    methods: {
        getUserByID(ID) {
            this.$store
                .dispatch('adminGetUserByID', ID)
                .then((response) => {
                    this.billingAdmin = response;
                })
                .catch((error) => {
                    const message = {
                        Message: 'Can`t get billing admin data',
                        Details: error.response.data,
                    };
                    EventBus.$emit('ERROR', message);
                });
        },
        getCheckoutURL() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/space/billing/checkout-url`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data && response.data.URL) {
                        window.location = response.data.URL;
                    } else {
                        const msg = {
                            Message: 'Failed to generate billing url. Please contact support via Intercom widget.',
                            Details: '',
                        };
                        EventBus.$emit('Error', msg);
                    }

                    this.loading = false;
                })
                .catch((error) => {
                    const msg = {
                        Message: 'Failed to generate billing url. Please contact support via Intercom widget.',
                        Details: '',
                    };
                    EventBus.$emit('Error', msg);
                    this.loading = false;
                });
        },
        getCustomerPortalURL() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/space/billing/customer-portal-url`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data && response.data.URL) {
                        window.location = response.data.URL;
                    } else {
                        const msg = {
                            Message:
                                'Failed to generate customer portal url. Please contact support via Intercom widget.',
                            Details: '',
                        };
                        EventBus.$emit('Error', msg);
                    }

                    this.loading = false;
                })
                .catch((error) => {
                    const msg = {
                        Message: 'Failed to generate customer portal url. Please contact support via Intercom widget.',
                        Details: '',
                    };
                    EventBus.$emit('Error', msg);
                    this.loading = false;
                });
        },

        getSpaceSettings() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/admin/space/settings',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data) {
                        this.space = response.data;
                        const date = new Date(this.space.CreatedAt * 1000);
                        const now = new Date();
                        const diffTime = Math.abs(now - date);
                        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
                        this.restFreeTrial = 14 - diffDays < 0 ? 0 : 14 - diffDays; // if rest less then 0 then set rest 0, otherwise set difference

                        const SubscriptionCanceledTo = new Date(this.space.SubscriptionCanceledTo * 1000);
                        this.space.SubscriptionCanceledToStr = moment(SubscriptionCanceledTo).format('DD.MM.YY');
                    }
                    if (this.space && this.space.PaymentMethodeCreatedBy) {
                        this.getUserByID(this.space.PaymentMethodeCreatedBy);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to get space settings',
                        Details: '',
                    };
                    EventBus.$emit('ERROR', msg);
                });
        },
    },
};
</script>
