
































import Vue from 'vue';
import axios from 'axios';
import EventBus from '@/eventBus';
import { Page, User } from '@/components/Molecules/co-card-page/models.ts';
import CoCardPage from '@/components/Molecules/co-card-page/CoCardPage.vue';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';

export default {
    name: 'MyPages',
    data() {
        return {
            projects: [],
            channelList: [],
            owners: {},
            pages: Array<Page>(),
            me: this.$store.state.me,
            loading: false,
        };
    },
    components: {
        CoCardPage,
        CoRoundButton,
        CoButton,
        CoCard,
    },
    created() {
        this.getChannelList();
    },
    mounted() {
        this.getMyProjects();
    },
    methods: {
        create() {
            this.$router.push(`/create/page`);
        },
        mapFeedPostToPage() {
            // map feed items to Page type
            const tmp = this.projects.map((feedpost) => {
                const owners = this.owners[feedpost.object.ID];
                const p: Page = {
                    id: feedpost.object.ID,
                    title: feedpost.object.Title,
                    description: feedpost.object.Description,
                    image: feedpost.object.ImageURL && feedpost.object.ImageURL[0] ? feedpost.object.ImageURL[0] : null,
                    slug: feedpost.object.Slug,
                    owners,
                    // Add the remaining properties here
                    channelSlug: this.getChannelSlugByID(feedpost.object.ChannelID),
                    followed: !!(feedpost.object.FollowedBy && feedpost.object.FollowedBy.indexOf(this.me.ID) > -1),
                    private: !feedpost.object.Published,
                };

                return p;
            });
            this.pages = tmp;
        },

        getOwners(feed) {
            // loop through feed
            for (let i = 0; i < feed.length; i++) {
                // get owner id
                const obj = feed[i].object;

                // if obj.ID is already in owners map, skip
                if (this.owners[obj.ID]) {
                    continue;
                }
                const ids = obj.Owner.map((entry) => ({ ID: entry }));
                const ownersIDs = { IDS: ids };
                const data = JSON.stringify(ownersIDs);
                this.$store.dispatch('listUsersByIDs', { ids: data }).then((response) => {
                    if (response.Users) {
                        let owners = response.Users;
                        owners = owners.map((owner) => {
                            // add owner to feed item
                            const tmp: User = {
                                id: owner.ID,
                                slug: owner.Slug,
                                name: `${owner.Profile.FirstName} ${owner.Profile.LastName}`,
                                image: owner.Profile.ImageURL,
                            };

                            if (!owner.Profile.FirstName) {
                                tmp.name = owner.Profile.Name;
                            }
                            return tmp;
                        });

                        this.owners[obj.ID] = owners;
                        this.mapFeedPostToPage();
                    }
                });
            }
        },

        follow(page) {
            const subs = JSON.stringify({ ProjectID: page.id });
            axios({
                method: 'POST',
                url: '/project/follow',
                data: subs,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    EventBus.$emit('INFO', {
                        Message: 'Page followed',
                        Details: null,
                    });

                    // update page in pages array
                    const index = this.pages.findIndex((p) => p.id === page.id);
                    this.pages[index].followed = true;
                    // this.listRerenderKey += 1;
                })
                .catch((error) => {
                    console.log(error);
                    EventBus.$emit('ERROR', {
                        Message: 'Page follow failed',
                        Details: null,
                    });
                });
        },
        unfollow(page) {
            const subs = JSON.stringify({ ProjectID: page.id });
            axios({
                method: 'DELETE',
                url: '/project/follow',
                data: subs,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    EventBus.$emit('INFO', {
                        Message: 'Page unfollowed',
                        Details: null,
                    });

                    // update page in pages array
                    const index = this.pages.findIndex((p) => p.id === page.id);
                    this.pages[index].followed = false;
                    // this.listRerenderKey += 1;
                })
                .catch((error) => {
                    console.log(error);
                    EventBus.$emit('ERROR', {
                        Message: 'Page unfollow failed',
                        Details: null,
                    });
                });
        },

        getChannelSlugByID(ID) {
            // get channel slug by ID
            let slug = '';
            this.channelList.map((channel) => {
                if (channel.object.ID === ID) {
                    slug = channel.object.Slug;
                }
            });
            return slug;
        },
        getMyProjects() {
            this.loading = true;
            this.$store
                .dispatch('getMyProjects')
                .then((response) => {
                    this.projects = response.objects;
                    this.getOwners(this.projects);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getChannelList() {
            axios
                .get('/project/channel/list')
                .then((response) => {
                    if (response.data) {
                        this.channelList = response.data.objects;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.mapFeedPostToPage(this.projects);
                });
        },
    },
};
