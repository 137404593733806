<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <div>
        <b-form @submit.stop.prevent="saveAddress">
            <b-form-group
                id="input-company-name"
                label="Company"
                label-for="input-company-name"
                label-class="label-align"
            >
                <b-form-input
                    v-model="form.Company"
                    placeholder="Company Name"
                    class="form-control rounded-pill d-block"
                ></b-form-input>
            </b-form-group>
            <b-form-group
                v-if="this.$unleash.isEnabled('frontend.billing.phone')"
                id="input-phone"
                label="Phone"
                label-for="input-phone"
                label-class="label-align"
            >
                <b-form-input
                    v-model="form.Phone"
                    placeholder="Phone Number"
                    class="form-control rounded-pill d-block"
                    type="tel"
                ></b-form-input>
            </b-form-group>
            <b-form-group
                id="input-additional-info"
                label="Additional Info"
                label-for="input-additional-info"
                label-class="label-align"
            >
                <b-form-input
                    v-model="form.AdditionalInfo"
                    placeholder="e.g. department"
                    class="form-control rounded-pill d-block"
                ></b-form-input>
            </b-form-group>
            <b-form-group id="input-address" label="Address" label-for="input-address" label-class="label-align">
                <b-form-input
                    v-model="form.Address"
                    placeholder="e.g. Mainstreet 34"
                    class="form-control rounded-pill d-block"
                ></b-form-input>
            </b-form-group>
            <b-form-group
                id="input-postal-code"
                label="Postal Code"
                label-for="input-postal-code"
                label-class="label-align"
            >
                <b-form-input
                    placeholder="e.g. 50943"
                    v-model="form.PostalCode"
                    class="form-control rounded-pill d-block"
                ></b-form-input>
            </b-form-group>
            <b-form-group id="input-city" label="City" label-for="input-city" label-class="label-align">
                <b-form-input
                    placeholder="City"
                    v-model="form.City"
                    class="form-control rounded-pill d-block"
                ></b-form-input>
            </b-form-group>
            <b-form-group id="input-country" label="Country" label-for="input-country" label-class="label-align">
                <b-form-input
                    placeholder="Country"
                    v-model="form.Country"
                    class="form-control rounded-pill d-block"
                ></b-form-input>
            </b-form-group>
            <div class="d-flex justify-content-between w-100 m-0 mt-4">
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <div class="d-flex">
                    <b-button size="sm" class="text-grey" @click="cancel()">Cancel</b-button>
                </div>
                <b-button size="sm" variant="primary" type="submit">Save</b-button>
            </div>
        </b-form>
    </div>
</template>
<script>
export default {
    name: 'EditAddress',
    props: {
        address: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            form: { ...this.address },
        };
    },

    methods: {
        cancel() {
            this.form = this.address;

            this.$emit('cancel');
        },
        saveAddress() {
            this.$emit('saveAddress', this.form);
        },
    },
};
</script>
