<template>
    <div class="chat-wrapper d-flex align-items-center justify-content-center">
        <coAlert variant="grey" size="sm" :text="$t('chatnotselected')" class="mx-3" />
    </div>
</template>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';
.user-select {
    height: calc(100vh / 3) !important;
}

.chat-wrapper {
    height: calc(100vh - 159px);
    overflow: auto;
    @media (max-width: 768px) {
        max-height: calc(100vh - 144px);
    }
}
.userdiv {
    cursor: pointer;
    // &:hover {
    //   background: @ngrey;
    // }
}
</style>

<script>
import Vue from 'vue';
import axios from 'axios';
import CoAlert from '../../components/Molecules/co-alert/CoAlert.vue';
import i18n from 'vue-i18n';

const debounce = require('debounce');

export default {
    i18n: {
        messages: {
            en: {
                chatnotselected: 'Select a chat to start messaging',
            },
            de: {
                chatnotselected: 'Wähle einen Chat zum Schreiben',
            },
        },
    },
    name: 'NewMessage',
    components: {
        CoAlert,
    },
    data() {
        return {
            creatingThread: true,
            projects: [],
            usersearch: '',
            users: [],
            searchingUsers: false,
        };
    },
    mounted() {},
    methods: {
        usersSearchResult: debounce(function (e) {
            this.searchingUsers = true;
            axios({
                method: 'GET',
                url: `/search/v2/user/${this.usersearch}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        console.log(response);
                        this.users = [];
                        response.data.forEach(function (entry) {
                            if (this.$store.state.me.ID == entry.ID) return;

                            this.users.push(entry);
                        }, this);
                        this.searchingUsers = false;
                    }
                })
                .catch((error) => {
                    this.searchingUsers = false;
                });
        }, 200),

        createThread(user) {
            const thread = {
                Participants: [this.$store.state.me.ID, user.ID],
            };
            axios({
                method: 'POST',
                url: '/chat/t',
                withCredentials: true,
                data: JSON.stringify(thread),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        console.log(response);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>
