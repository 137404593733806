










export default {
    name: 'CoAdminMembersBase',
    data() {
        return {};
    },
};
