

















































import emojilist from './emojilist';

import { debounce, forEach, isEmpty } from 'lodash';
import i18n from 'vue-i18n';
import de from './de.js';

import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoText from '../../Atoms/co-text/CoText.vue';
import CoInput from '../co-input/CoInput.vue';
import CoTippy from '../../Atoms/co-tippy/CoTippy.vue';

export default {
    components: { CoIcon, CoText, CoInput, CoTippy },
    i18n: {
        messages: {
            de: de,
        },
        silentTranslationWarn: true,
    },
    name: 'CoEmojiPicker',
    data() {
        return {
            search: '',
            pickerTop: 0,
            pickerLeft: 0,
            triggerWidth: null,
            triggerHeight: null,
            pickerShow: false,
            emojis: emojilist,
            arrowColor: null,
        };
    },
    mounted() {
        if (this.$refs.picker) {
            this.arrowColor = window.getComputedStyle(this.$refs.picker, null).getPropertyValue('border-color');
        }
    },
    watch: {
        search(newVal, oldVal) {
            debounce(this.filterEmojis, 500)();
        },
    },
    methods: {
        insertEmoji(emoji) {
            this.$emit('picked', emoji);
            this.$refs.emopicker.hide();
        },
        filterEmojis() {
            let searchTerm = this.search;
            if (searchTerm && searchTerm.length > 1) {
                let retObj = {};
                forEach(emojilist, (list, key) => {
                    let filteredList = {};
                    forEach(list, (item, itemkey) => {
                        //add translated key to the original key
                        //support searching in local language
                        //Example: search for 'heart' and 'herz' would both match 💙
                        //for german users
                        let searchableKey = `${itemkey}_${this.$t(itemkey)}`;
                        let searchRegEx = new RegExp(searchTerm, 'i');
                        searchableKey.match(searchRegEx) ? (filteredList[itemkey] = item) : null;
                    });
                    isEmpty(filteredList) ? null : (retObj[key] = filteredList);
                });
                this.emojis = retObj;
            } else {
                this.emojis = emojilist;
            }
        },
    },
};
