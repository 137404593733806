<style scoped></style>

<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <b-overlay :show="loading">
        <div class="d-flex align-items-center pt-2 ml-3 pb-2">
            <span
                >{{ this.priceInitial }} {{ $store.state.space.Currency }} -
                {{ this.taxRate.Inclusive ? 'incl.' : 'excl.' }} {{ this.taxRate.Percentage }}% VAT</span
            >
        </div>
        <div v-if="isAdminSpace" class="px-3">
            <b-form-group
                id="input-group-rate"
                label="Set a new price (optional)"
                label-for="input-rate"
                aria-describedby="rate-description"
                label-class="label-align"
            >
                <b-form-input
                    id="input-rate"
                    v-model="priceOverride"
                    number
                    required
                    class="rounded-pill"
                    step="0.01"
                ></b-form-input>
            </b-form-group>
        </div>
        <div v-if="isAdminSpace && expirationDate && expirationTime" class="d-flex flex-column flex-md-row w-100">
            <div class="col-md-6">
                <b-form-group
                    id="input-group-expire"
                    label="Expire Date"
                    label-for="input-expire"
                    aria-describedby="expire-description"
                    label-class="label-align"
                >
                    <b-form-datepicker
                        id="input-expire"
                        v-model="expirationDate"
                        required
                        class="rounded-pill"
                        :min="new Date().toISOString().split('T')[0]"
                    ></b-form-datepicker>
                </b-form-group>
            </div>
            <div class="col-md-6">
                <b-form-group
                    id="input-group-time"
                    label="Time"
                    label-for="input-time"
                    aria-describedby="time-description"
                    label-class="label-align"
                >
                    <b-form-timepicker
                        id="input-time"
                        v-model="expirationTime"
                        required
                        class="rounded-pill"
                    ></b-form-timepicker>
                </b-form-group>
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center mb-2 px-3">
            <div class="co-card secondary mt-3 w-100">
                <span class="mr-3">Total:</span>
                <span class="rounded-pill">
                    {{ overRide ? priceOverride : calculatePrice(this.timepass).toFixed(2) }}
                    {{ $store.state.space.Currency }}
                </span>
            </div>
        </div>
        <div
            v-if="!isAdminSpace && paymentMethod && paymentMethod.Details"
            class="d-flex justify-content-between align-items-center mb-2 px-3"
        >
            <div class="d-block round-unify bg-grey mt-3 w-100 text-left p-2">
                <router-link
                    to="/account/billing/payment-methods"
                    style="color: black !important; text-decoration: none"
                >
                    <b-icon scale="1" id="edit-payment" icon="pencil" class="mr-2"></b-icon>
                    Pay with
                    {{ paymentMethod.Details.CardBrand }}
                    {{ paymentMethod.Type }} - {{ paymentMethod.Details.Last4Digits }}
                </router-link>
            </div>
        </div>
        <div v-if="isAdminSpace" class="px-3 mt-2">
            <div class="bg-white round-unify">
                <b-form-group
                    id="input-group-name"
                    label="Message (optional)"
                    label-for="input-name"
                    aria-describedby="name-description"
                    label-class="label-align"
                >
                    <b-form-input
                        id="input-name"
                        v-model="this.message"
                        type="text"
                        required
                        placeholder="Text"
                        class="rounded-pill"
                    ></b-form-input>
                </b-form-group>
            </div>
        </div>
        <div v-else class="px-3">
            <div class="d-flex align-items-center mt-3 mb-3" v-if="TermsAndConditions">
                <b-form-checkbox id="checkbox-2" name="checkbox-2" v-model="AcceptTermsAndConditions" required>
                </b-form-checkbox>
                <p class="mb-0">
                    I accept the
                    <a
                        class="text-primary mb-0"
                        @click="$bvModal.show('terms-and-conditions')"
                        title="Terms and Conditions"
                        >Terms and Conditions</a
                    >
                </p>
            </div>
        </div>

        <b-modal id="terms-and-conditions" hide-footer title="Terms and conditions" size="xl">
            <div v-if="TermsAndConditions" v-html="TermsAndConditions.Content"></div>
        </b-modal>
    </b-overlay>
</template>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';

export default {
    name: 'BuyPassCheckout',
    props: ['timepass', 'taxRate', 'isAdminSpace', 'userID'],
    data() {
        return {
            AcceptTermsAndConditions: false,
            overRide: false,
            TermsAndConditions: null,
            loading: false,
            paymentMethods: [],
            me: this.$store.state.me,
            priceOverride: 0,
            priceInitial: 0,
            message: null,
            expirationDate: null,
            expirationTime: null,
            paymentMethod: {},
            pricePreview: {
                PriceInCents: 0,
                CanBuy: false,
                ActiveMembershipRequired: false,
                PaymentMethodeRequired: false,
            },
        };
    },
    watch: {
        AcceptTermsAndConditions(newVal, oldVal) {
            this.$emit('isAccepted');
        },
        priceOverride(newVal) {
            if (newVal >= 0) {
                if (newVal === this.priceInitial) {
                    this.overRide = false;
                } else {
                    this.overRide = true;
                }
                this.totalPrice = newVal;
                // this.timepass.PriceInCents = newVal * 100.0;
                this.$emit('priceOverride', newVal, this.overRide);
                if (newVal === 0) {
                    // set expiration date
                    this.setInitialExpirationDate();
                } else {
                    this.expirationDate = null;
                    this.expirationTime = null;
                }
            } else {
                this.totalPrice = 0.0;
            }
            if (newVal === '') {
                this.totalPrice = 0.0;
            }
        },
        expirationDate(newVal) {
            const date = new Date(newVal);
            const time = this.expirationTime.split(':');
            date.setHours(time[0]);
            date.setMinutes(time[1]);
            this.$emit('expirationOverride', date);
        },
        expirationTime(newVal) {
            const date = this.expirationDate;
            const time = newVal.split(':');
            date.setHours(time[0]);
            date.setMinutes(time[1]);
            this.$emit('expirationOverride', date);
        },
        loading(newVal) {
            if (newVal === false) {
                this.$emit('loading', false);
            }
        },
    },
    computed: {
        isReady() {
            return this.AcceptTermsAndConditions;
        },
        formattedDate() {
            const options = {
                weekday: 'long',
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            };
            return this.expirationDate.toLocaleDateString('en-GB', options);
        },
    },
    mounted() {
        this.getTermsAndConditions();
        this.priceOverride = this.timepass.PriceInCents / 100.0;
        // this.priceOverride = this.calculatePrice(this.timepass);
        this.priceInitial = this.priceOverride;

        if (this.isAdminSpace) {
            this.listUsersPaymentMethods(this.userID);
        } else {
            this.previewPassPrice();
        }
    },
    created() {
        window.addEventListener('blur', this.onTabBlur);
        window.addEventListener('focus', this.onTabFocus);
    },
    methods: {
        onTabBlur() {
            this.switchedToAnotherTab = true;
        },

        onTabFocus() {
            if (this.switchedToAnotherTab) {
                this.switchedToAnotherTab = false;
                this.previewPassPrice();
            }
        },
        listUsersPaymentMethods(ID) {
            this.loading = true;
            this.$store
                .dispatch('adminGetPaymentMethods', { ID })
                .then((paymentMethods) => {
                    this.loading = false;
                    this.paymentMethods = [];
                    this.paymentMethods = paymentMethods;
                    const that = this;
                    if (this.paymentMethods && this.paymentMethods.length !== 0) {
                        this.paymentMethods.forEach((item) => {
                            if (item.Default === true) {
                                that.paymentMethod = item;
                            }
                        });
                    } else {
                        this.errorMsg =
                            'This user does have any payment method. Before subscribing to a plan, the user must have at least one payment method.';
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    console.error(error);
                    if (error.response && error.response.status && error.response.status === 404) {
                        this.errorMsg =
                            'This user does have any payment method. Before buying a time pass, the user must have at least one payment method.';
                    } else {
                        this.errorMsg =
                            'This user does have any payment method. Before buying a time pass, the user must have at least one payment method.';
                    }
                });
            EventBus.$emit('ERROR', this.errorMsg);
        },
        previewPassPrice() {
            this.loading = true;
            axios({
                method: 'POST',
                url: 'user/time-pass/preview',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    TimePassID: this.timepass.ID,
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.pricePreview = response.data;
                        this.priceInitial = this.pricePreview.PriceInCents / 100.0;
                        this.$emit('pricePreview', this.pricePreview);
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to get price preview',
                        Details: '',
                    };
                    EventBus.$emit('ERROR', msg);
                });
        },
        setInitialExpirationDate() {
            const date = new Date();
            date.setMonth(date.getMonth() + 1);
            this.expirationDate = date;
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);
            date.setHours(date.getHours() + 1);
            this.expirationTime = date.toISOString().split('T')[1].split('.')[0];
        },
        calculatePrice(item) {
            const price = item.PriceInCents / 100.0;
            if (!this.taxRate || this.taxRate.Inclusive) {
                return price;
            }
            const totalPrice = price * (this.taxRate.Percentage / 100 + 1);
            return totalPrice;
        },
        getTermsAndConditions() {
            axios({
                method: 'GET',
                url: '/space/get-terms-and-conditions',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.TermsAndConditions = response.data;
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 404) {
                        this.AcceptTermsAndConditions = true;
                    } else {
                        const msg = {
                            Message: 'Failed to get terms and conditions',
                            Details: '',
                        };
                        EventBus.$emit('ERROR', msg);
                    }
                    console.log(error);
                });
        },
    },
};
</script>
