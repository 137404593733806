import { render, staticRenderFns } from "./CoCreateResourceConfirmationPage.vue?vue&type=template&id=3df1caed&scoped=true&"
import script from "./CoCreateResourceConfirmationPage.vue?vue&type=script&lang=js&"
export * from "./CoCreateResourceConfirmationPage.vue?vue&type=script&lang=js&"
import style0 from "./CoCreateResourceConfirmationPage.vue?vue&type=style&index=0&id=3df1caed&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3df1caed",
  null
  
)

export default component.exports