<template>
    <div>
        <cookie-law class="banner" theme="none">
            <div slot-scope="props">
                <div :class="[isMobile ? 'cookie-banner-container-mobile' : 'cookie-banner-container-footer']">
                    <div class="cookie-banner-content">
                        <!-- Title -->
                        <co-headline :level="3" class="mb-2" style="font-weight: bold">🍪 Cookies</co-headline>
                        <!-- Body Message -->
                        <div class="cookie-message">
                            <p>
                                {{ $t('labels.textBeforeLink') }}
                                <a
                                    v-if="privacyPolicy"
                                    class="link"
                                    title="Privacy policy"
                                    @click="$bvModal.show('privacy-policy-main')"
                                    >{{ $t('labels.privacypolicy') }}</a
                                >
                                <a
                                    v-else-if="
                                        $store.state.space['PrivacyPolicyURL'] &&
                                        $store.state.space.PrivacyPolicyURL !== 'https://www.coapp.io/privacy-police'
                                    "
                                    target="_blank"
                                    :href="$store.state.space['PrivacyPolicyURL']"
                                    >{{ $t('labels.privacypolicy') }}</a
                                >
                                <span v-else>{{ $t('labels.privacypolicy') }}</span
                                >{{ $t('labels.textAfterLink') }}
                            </p>
                        </div>
                        <co-button class="w-100 center-text" variant="black" @click="props.accept"
                            >{{ $t('labels.ok') }}
                        </co-button>
                    </div>
                </div>
            </div>
        </cookie-law>
        <!-- privacy policy modal start -->
        <b-modal
            id="privacy-policy-main"
            hide-footer
            title="Imprint & Privacy Policy"
            size="xl"
            @shown="handleModalVisibility(true)"
            @hidden="handleModalVisibility(false)"
        >
            <div v-if="privacyPolicy" v-html="privacyPolicy.Content"></div>
        </b-modal>
        <!-- privacy policy modal end -->
    </div>
</template>

<script>
import CookieLaw from 'vue-cookie-law';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';

export default {
    name: 'CookieBanner',
    components: {
        CoHeadline,
        CoButton,
        CookieLaw,
    },
    i18n: {
        messages: {
            en: {
                labels: {
                    textBeforeLink:
                        'This site uses cookies, some essential for functionality and others to enhance your experience. By using this site, you consent to our use of cookies. See our ',
                    textAfterLink: ' for details.',
                },
            },
            de: {
                labels: {
                    textBeforeLink:
                        'Diese Website verwendet Cookies, einige sind für die Funktion erforderlich, andere verbessern Ihre Nutzererfahrung. Durch die Nutzung dieser Seite stimmen Sie der Verwendung von Cookies zu. Weitere Details finden Sie in unserer ',
                    textAfterLink: '.',
                },
            },
        },
    },
    computed: {
        isMobile() {
            return window.innerWidth <= 768;
        },
    },
    props: {
        privacyPolicy: {
            type: Object,
            default: null,
        },
    },
    methods: {
        handleModalVisibility(isVisible) {
            if (isVisible) {
                document.body.classList.add('modal-open');
            } else {
                document.body.classList.remove('modal-open');
            }
        },
    },
};
</script>

<style scoped>
.cookie-banner-container-footer {
    width: 45%;
    position: fixed;
    bottom: 20px;
    left: 32px;
    background-color: #e0dfde;
    border-radius: 18px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 16px;
    z-index: 1000;
}

.modal-open .banner {
    display: none;
}

.cookie-banner-container-mobile {
    width: calc(100% - 32px);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #e0dfde;
    border-radius: 18px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 16px;
    z-index: 1000;
}

.cookie-banner-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.cookie-message {
    margin: 8px 0 8px 0;
    font-size: 0.9em;
    line-height: 1.4;
}
</style>
