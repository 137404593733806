<template>
    <div class="co drop-down-list">
        <ul>
            <li v-if="loading" class="loading">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </li>
            <li
                v-if="firstItem"
                class="first-item"
                :title="firstItem"
                @mousedown.capture.stop="$emit('itemClicked', firstItem)"
            >
                {{ firstItem }}
            </li>
            <hr v-if="firstItem" />
            <li
                v-for="(item, index) in items"
                :key="index"
                :title="item"
                :class="{ selected: index === selected }"
                @mousedown.capture.stop="$emit('itemClicked', item)"
            >
                {{ item }}
            </li>
        </ul>
    </div>
</template>
<style lang="less" scoped>
@import '../../../assets/less/variables.less';
@import './CoDropdownList.less';
</style>
<script>
export default {
    name: 'CoDropdownList',
    props: {
        items: Array, //
        firstItem: String, //
        selected: Number, //
        loading: Boolean, //
    },
};
</script>
