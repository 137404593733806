







































import CoCardResource from '../../Molecules/co-card-resource/CoCardResource.vue';

export default {
    name: 'CoResourceSelectModal',
    components: {
        CoCardResource,
    },
    props: {
        bestOptions: {
            type: Array as () => Array<any>,
            default: () => [],
        },
        moreOptions: {
            type: Array as () => Array<any>,
            default: () => [],
        },
    },
    watch: {},
    data() {
        return {
            showModal: false,
            listBestIDs: [],
        };
    },
    mounted() {
        // next tick
        this.$nextTick(() => {
            if (this.bestOptions.length === 1) {
                const r = JSON.parse(JSON.stringify(this.bestOptions[0]));
                this.$emit('select', r);
            }
        });
    },
    methods: {
        checkForDuplicates(resource) {
            const listBestIDs = this.bestOptions.map((obj) => obj.ID);

            if (this.bestOptions.length > 0) {
                if (listBestIDs.includes(resource.ID)) {
                    return false;
                }
                return true;
            }
            return true;
        },
        show() {
            this.$refs['select-resource-modal'].show();
        },
        hide() {
            this.$refs['select-resource-modal'].hide();
        },
        select(resource) {
            const r = JSON.parse(JSON.stringify(resource));
            this.$emit('select', r);
        },
    },
};
