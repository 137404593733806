<template>
    <b-overlay :show="loading">
        <div v-if="plan" style="max-width: 600px">
            <coHeadline v-if="!isUpcoming" :level="2" style="margin: 1.5rem 1.25rem !important"
                >{{ $t('labels.plancurrent') }}
            </coHeadline>
            <coHeadline v-else :level="2" style="margin: 1.5rem 1.25rem !important"
                >{{ $t('labels.planupcoming') }}
            </coHeadline>
            <coPlanDetail :plan="this.plan" show-upgrades></coPlanDetail>
        </div>
    </b-overlay>
</template>

<script>
import axios from 'axios';
import { defaultTo } from 'lodash';
import EventBus from '../../../eventBus';
import CoHeadline from '../../../components/Atoms/co-headline/CoHeadline.vue';
import CoButton from '../../../components/Atoms/co-button/CoButton.vue';
import CoPlanDetail from '../../../components/Organisms/co-plan-detail/CoPlanDetail.vue';

export default {
    name: 'PlanViewDetails',
    components: { CoButton, CoHeadline, CoPlanDetail },
    data() {
        return {
            plan: null,
            me: this.$store.state.me,
            imageURL: '',
            loading: false,
            isUpcoming: false,
            id: this.$route.params.ID, // current or upcoming
        };
    },
    created() {
        this.loading = true;
        axios({
            method: 'GET',
            url: '/me/plan',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response && response.data) {
                    if (this.id === 'current' && response.data.current) {
                        this.plan = response.data.current.Plan;
                    } else if (this.id === 'current') {
                        EventBus.$emit('ERROR', {
                            Message: 'Plan object not found',
                            Details: '',
                        });
                        this.back();
                    } else if (this.id === 'upcoming' && response.data.upcoming) {
                        this.plan = response.data.upcoming.Plan;
                        this.isUpcoming = true;
                    } else if (this.id === 'upcoming') {
                        EventBus.$emit('ERROR', {
                            Message: 'Plan object not found',
                            Details: '',
                        });
                        this.back();
                    }
                } else {
                    EventBus.$emit('ERROR', {
                        Message: 'Plan object not found',
                        Details: '',
                    });
                    this.back();
                }

                this.loading = false;
            })
            .catch((error) => {
                console.log(error);
                this.loading = false;
            });
    },

    methods: {
        back() {
            if (window.history.length > 2) {
                this.$router.go(-1);
            } else {
                this.$router.push('/account/plan');
            }
        },

        getFormattedPrice(priceInCents) {
            return (defaultTo(priceInCents, 0) / 100).toFixed(2);
        },
    },
};
</script>
