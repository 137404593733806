
















































































import EventBus from '@/eventBus';
import axios from 'axios';
import { get, map, uniq, filter } from 'lodash';
import CoTable from '../../../components/Molecules/co-table/CoTable.vue';
import CoLink from '@/components/Atoms/co-link/CoLink.vue';
import CoDate from '@/components/Molecules/co-date/CoDate.vue';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';

export default {
    name: 'AdminPages',
    components: {
        CoTable,
        CoLink,
        CoDate,
        CoIcon,
    },
    data() {
        return {
            pages: [],
            loading: true,
        };
    },
    async mounted() {
        try {
            //fetch channels
            const { data } = await axios.get('/project/channel/list');
            const channels = get(data, 'objects', []).map((channel) => {
                return get(channel, 'object', {});
            });
            //fetch pages
            var pages = await new Promise<Array<any>>((r, j) => this.listPages(r, j));

            if (pages.length === 0) {
                this.loading = false;
                return;
            }
            // fetch users info
            var userList = [];
            map(pages, (page) => {
                userList = userList.concat(get(page, 'Owner', []));
            });

            let users = await axios.post('/user/listbyids', {
                IDS: map(uniq(userList), (id) => {
                    return {
                        ID: id,
                    };
                }),
            });
            //assign channel and user info to pages list
            this.pages = pages.map((page) => {
                return {
                    ...page,
                    Channel: channels.find((channel) => channel.ID === page.ChannelID),
                    Owners: filter(get(users, 'data.Users', []), (user) => {
                        return get(page, 'Owner', []).includes(user.ID);
                    }),
                };
            });
        } catch (error) {
            //error handling
            EventBus.$emit('ERROR', { Message: 'Error while loading pages. Please try again or send a bug report.' });
            console.error(error);
        }
        // end loading state
        this.loading = false;
    },
    methods: {
        get(collection, key, fallback = null) {
            return get(collection, key, fallback);
        },
        async listPages(resolve, reject, pages = [], next = null) {
            const request = await axios.get(next ? next : '/admin/community/page/list');
            var newPages = get(request, 'data.objects', []);

            if (!pages || !Array.isArray(pages)) {
                pages = [];
            }

            if (newPages && Array.isArray(newPages) && newPages.length > 0) {
                newPages = newPages.map((page) => {
                    return get(page, 'object', {});
                });
                pages = pages.concat(newPages);
            }

            if (get(request, 'data.next', '').length > 0) {
                this.listPages(resolve, reject, pages, request.data.next);
            } else {
                resolve(pages);
            }
        },
    },
};
