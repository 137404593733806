
















import axios from 'axios';
import CoInputWithAutoComplete from '../co-input-with-autocomplete/CoInputWithAutoComplete.vue';
import { de } from 'date-fns/locale';

export default {
    name: 'CoSelectUser',
    components: { CoInputWithAutoComplete },
    props: {
        user: {
            type: Object,
            required: false,
            default: null,
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Search for user',
        },
        deletable: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            selectedUser: null,
        };
    },
    methods: {
        searchUser(e) {
            return new Promise((resolve, reject) => {
                if (e.length < 2) {
                    resolve(null);
                    return true;
                }
                axios({
                    method: 'GET',
                    url: `/search/user/${e}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        if (response && response.data) {
                            const users = response.data;
                            resolve(users);
                        } else {
                            resolve(null);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .finally(() => {});
            });
        },
        selectUser(event) {
            this.selectedUser = event;
            this.$emit('select', event);
        },
    },
};
