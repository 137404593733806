
























































































import EventBus from '../../../eventBus';
import axios from 'axios';

interface Resource {
    Name: String;
    Description: String;
    Location: String;
    ImageURL: String;
    Color: String;
    PriceInCents: Number;
    Currency: String;
    PriceInterval: String;
    Hidden: Boolean;
    BookingInterval: Boolean;
    FixedTimeSpan: Boolean;
}

export default {
    name: 'CoCreateResourceGeneralPage',
    props: {
        resource: {
            type: (Object as () => Resource) || null,
            default: null,
            required: true,
        },
    },
    data() {
        return {
            plan: null,

            imageURL: '',
            changedCategories: [],
            categories: [],
            color: 'black',
            hideResource: false,

            loading: false,
            locations: [],
            location: null,

            id: this.$route.params.ID, // current or upcoming
        };
    },
    created() {},
    mounted() {
        this.listLocations();
        this.listCategories();
    },
    methods: {
        categoriesChanged(categories) {
            this.changedCategories = categories;
        },
        selectLocation(event) {
            this.location = event;
        },
        searchLocation(e) {
            return new Promise((resolve, reject) => {
                var items = this.locations;
                items.filter((l) => items.includes(e));
                resolve(items);
            });
        },
        listCategories() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/booking/v2/category/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data) {
                        this.categories = response.data;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        listLocations() {
            this.$store
                .dispatch('listLocations')
                .then((locations) => {
                    this.locations = locations;
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        selectColor(color) {
            this.color = color;
        },
        checkImageFile(image) {
            this.resource.ImageURL = image.name;
        },
        setLocation(location) {
            this.resource.Location = location;
        },
        inputResourceDescription(e) {
            this.resource.Description = e;
        },
    },
};
