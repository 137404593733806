<template>
    <div>
        <p :id="Id" class="co form text muted">{{ Text }}</p>
    </div>
</template>

<style lang="less" scoped>
@import './CoFormText.less';
</style>

<script>
export default {
    name: 'CoFormText',
    props: {
        Id: {
            type: String,
            default: '',
        },
        Text: {
            type: String,
            default: '',
        },
    },
};
</script>

<style scoped></style>
