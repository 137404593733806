




import EventBus from '@/eventBus.js';
import MembershipCreate from '../../../../Templates/Admin/Membership/MembershipCreate/MembershipCreate.vue';

export default {
    name: 'CoMemberPlanCreate',
    components: {
        MembershipCreate,
    },
    data() {
        return {
            userId: this.$route.params.ID,
            userName: 'User',
        };
    },
    created() {
        this.adminGetUserByID(this.userId);
    },
    methods: {
        adminGetUserByID(ID) {
            this.$store
                .dispatch('adminGetUserByID', ID)
                .then((response) => {
                    const user = response;

                    if (user.Profile && user.Profile.Name) {
                        this.userName = user.Profile.Name;
                    }
                })
                .catch((error) => {
                    const message = {
                        Message: 'Can`t get user data',
                        Details: error.response.data,
                    };
                    EventBus.$emit('ERROR', message);
                })
                .finally(() => {
                    this.setBreadcrumbs();
                });
        },
        setBreadcrumbs() {
            this.$store.commit('SET_BREADCRUMBS', [
                {
                    text: 'Manage',
                    to: '/admin',
                },
                {
                    text: 'Members',
                    to: '/admin/community/member/list',
                },
                {
                    text: this.userName,
                    to: `/admin/community/member/view/${this.userId}`,
                },
                {
                    text: 'Create Membership',
                },
            ]);
        },
    },
};
