


































import { map } from 'lodash';
import CoImage from '../../Atoms/co-image/CoImage.vue';
import CoRoundButton from '../../Atoms/co-round-button/CoRoundButton.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';

interface Slide {
    ID: number;
    ImageURL: string;
}

export default {
    name: 'CoSlider',
    components: {
        CoImage,
        CoRoundButton,
        CoIcon,
    },
    props: {
        pagination: {
            type: Boolean,
            default: false,
        },
        buttons: {
            type: Boolean,
            default: false,
        },
        lightbox: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            currentSlide: 0,
            slides: [],
            slidePositions: [],
        };
    },
    computed: {
        slideCount() {
            return this.slides.length || 0;
        },
    },
    mounted() {
        this.slides = this.$refs.sliderContent.children;
    },
    methods: {
        prev() {
            this.currentSlide > 0 ? this.currentSlide-- : (this.currentSlide = this.slideCount - 1);
            this.moveTo(this.currentSlide);
        },
        next() {
            this.currentSlide < this.slideCount - 1 ? this.currentSlide++ : (this.currentSlide = 0);
            this.moveTo(this.currentSlide);
        },
        moveTo(index) {
            this.currentSlide = index;
            this.slides[index].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        },
    },
};
