


































import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoDropdown from '@/components/Molecules/co-dropdown/CoDropdown.vue';
import CoDropdownItem from '@/components/Molecules/co-dropdown-item/CoDropdownItem.vue';

import i18n from 'vue-i18n';

export default {
    name: 'CoIslandCreateButton',
    components: {
        CoRoundButton,
        CoDropdown,
        CoDropdownItem,
    },
    props: {
        marketIsOn: {
            type: Boolean,
            default: true,
            required: false,
        },
        eventIsOn: {
            type: Boolean,
            default: true,
            required: false,
        },
        isAdmin: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data() {
        return {};
    },
    methods: {
        // createPost emit event to parent component
        createPost() {
            this.$emit('createPost');
        },

        createEvent() {
            this.$emit('createEvent');
        },

        createMarketItem() {
            this.$emit('createMarketItem');
        },

        createPage() {
            this.$emit('createPage');
        },

        createChannel() {
            this.$emit('createChannel');
        },
    },
};
