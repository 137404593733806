


























export default {
    name: 'CoHeading',
    props: {
        text: {
            type: String,
            required: true,
            default: '',
        },
        type: {
            default: 'h1',
            type: String,
            validator: (value) => ['h1', 'h2', 'h3'].includes(value),
        },
    },
};
