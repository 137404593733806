<template>
    <div class="dropdown" v-if="options">
        <!-- Dropdown Input -->
        <input
            class="dropdown-input-search d-flex bg-grey no-border p-2 rounded-pill"
            style="border-bottom: 0 !important"
            :name="name"
            @focus="showOptions()"
            @keyup="keyMonitor"
            v-model="searchFilter"
            :disabled="disabled"
            :placeholder="placeholder"
        />

        <!-- Dropdown Menu -->
        <template class="bg-white">
            <div
                class="dropdown-content bg-white overflow-hidden border pl-0 round-unify-bottom m-0"
                style="
                    position: absolute;
                    max-width: 100%;
                    left: -20px;
                    margin-top: -20px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                "
                v-show="optionsShown"
            >
                <div
                    class="dropdown-item overflow-hidden px-0"
                    style="text-overflow: ellipsis; white-space: nowrap"
                    @mousedown="selectOption(option)"
                    v-for="option in filteredOptions"
                    :key="option.ID"
                >
                    <div class="px-2 py-1 pointer">
                        <div>
                            {{ trimString(option.Name) || '-' }}
                        </div>
                        <div>{{ trimString(option.Email) || '-' }}</div>
                        <div>
                            {{ (option.CreatedAt + '000') | readabledate }} |
                            {{ (option.CreatedAt + '000') | readabletime }}
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'Dropdown',
    template: 'Dropdown',
    props: {
        name: {
            type: String,
            required: false,
            default: 'dropdown',
            note: 'Input name',
        },
        options: {
            type: Array,
            required: true,
            default: [],
            note: 'Options of dropdown. An array of options with id and name',
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Please select an option',
            note: 'Placeholder of dropdown',
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
            note: 'Disable the dropdown',
        },
        maxItem: {
            type: Number,
            required: false,
            default: 6,
            note: 'Max items showing',
        },
    },
    data() {
        return {
            selected: {},
            optionsShown: false,
            searchFilter: '',
        };
    },
    created() {
        this.$emit('selected', this.selected);
    },
    computed: {
        filteredOptions() {
            const filtered = [];
            const regOption = new RegExp(this.searchFilter, 'ig');
            for (const option of this.options) {
                if (this.searchFilter.length > 1) {
                    if (filtered.length < this.maxItem) filtered.push(option);
                }
            }
            return filtered;
        },
    },
    methods: {
        trimString(string) {
            if (string == null) {
                return '-';
            }
            if (string.length > 23) {
                string = `${string.substring(0, 23 - 1)}...`;
            }
            return string;
        },

        selectOption(option) {
            this.selected = option;
            this.optionsShown = false;
            this.$emit('selected', this.selected);
        },
        showOptions() {
            if (!this.disabled) {
                this.optionsShown = true;
            }
        },
        exit() {
            if (!this.selected.id) {
                this.selected = {};
                this.searchFilter = '';
            } else {
                this.searchFilter = this.selected.name;
            }
            this.$emit('selected', this.selected);
            this.optionsShown = false;
        },
        // Selecting when pressing Enter
        keyMonitor(event) {
            if (event.key === 'Enter' && this.filteredOptions[0]) this.selectOption(this.filteredOptions[0]);
        },
    },
    watch: {
        searchFilter() {
            if (this.filteredOptions.length === 0) {
                this.selected = {};
            } else {
                this.selected = this.filteredOptions[0];
            }
            this.$emit('filter', this.searchFilter);
        },
    },
};
</script>

<style lang="less" scoped src="@/assets/less/manage-space/base.less">
input {
    background: transparent !important;
    border: none;
}

.dropdown-content {
    width: 20vw;
}
</style>
