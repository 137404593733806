<template>
    <div>
        <!-- payment methods start -->
        <div class="p-0 pl-sm-4 m-0">
            <b-overlay :show="loading" class="round-unify my-4 overflow-visible">
                <CoCard overflow="visible">
                    <div class="w-100 d-flex justify-content-between align-items-center">
                        <CoHeadline :level="2">Currency</CoHeadline>
                    </div>
                    <div class="mt-3" :key="rerender">
                        <CoSelect :value="preSelectedValue" :items="options" @select="select" />
                    </div>

                    <div class="mt-3 d-flex justify-content-end w-100">
                        <CoButton
                            :disabled="loading"
                            :loading="loading"
                            variant="primary"
                            @click="setCurrency"
                            label="Save"
                        />
                    </div>
                </CoCard>
            </b-overlay>
        </div>
        <!-- payment methods end -->
    </div>
</template>

<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';
import CoSelect from '@/components/Molecules/co-select/CoSelect.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';

export default {
    name: 'Currency',
    components: {
        CoSelect,
        CoCard,
        CoHeadline,
        CoButton,
    },
    data() {
        return {
            loading: false,
            currencies: [],
            selectedCurrency: null,
            preSelectedValue: null,

            space: null,
            rerender: 0,
        };
    },
    computed: {
        options() {
            return this.currencies.map((currency) => ({
                Value: currency.Code,
                Name: currency.Name,
            }));
        },
    },
    mounted() {
        this.getCurrency();
        this.getCurrencyList();
    },

    methods: {
        select(item) {
            this.selectedCurrency = item.Value;
        },

        setCurrency() {
            this.loading = true;

            const data = JSON.stringify({
                Currency: this.selectedCurrency,
            });
            axios({
                method: 'PUT',
                url: '/admin/payment/stripe/currency',
                withCredentials: true,
                data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data) {
                        this.preSelectedValue = {
                            Value: response.data.Currency,
                            Name: response.data.Currency,
                        };
                        const msg = {
                            Message: 'Changes saved',
                            Details: '',
                        };
                        EventBus.$emit('INFO', msg);
                    }
                    this.rerender = Math.random();
                    this.getSpaceSettings();
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to save currency settings.',
                        Details: '',
                    };
                    EventBus.$emit('ERROR', msg);
                });
        },

        getCurrency() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/admin/payment/stripe/currency',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data) {
                        this.preSelectedValue = {
                            Value: response.data.Currency,
                            Name: response.data.Currency,
                        };
                    }
                    this.rerender = Math.random();
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to get space currency settings.',
                        Details: '',
                    };
                    EventBus.$emit('ERROR', msg);
                });
        },
        getCurrencyList() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/admin/space/currencies',
                withCredentials: true,
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data) {
                        this.currencies = response.data;
                    }
                    this.rerender = Math.random();
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: 'Could not get currency list.',
                        Details: error,
                    });
                    this.loading = false;
                });
        },
        getSpaceSettings() {
            this.$store
                .dispatch('getSpaceSettings', null)
                .then((response) => {})
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: 'Could not get updated space settings.',
                        Details: error,
                    });
                });
        },
    },
};
</script>
