










































export default {
    name: 'CoHeadline',
    props: {
        level: {
            type: Number,
            default: 1,
        },
        trunc: {
            type: Boolean,
            default: false,
        },
        muted: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classes() {
            return {
                truncate: this.trunc,
                muted: this.muted,
            };
        },
    },
};
