import { render, staticRenderFns } from "./PlanConfigurator.vue?vue&type=template&id=4bc10802&scoped=true&"
import script from "./PlanConfigurator.vue?vue&type=script&lang=ts&"
export * from "./PlanConfigurator.vue?vue&type=script&lang=ts&"
import style0 from "./PlanConfigurator.vue?vue&type=style&index=0&id=4bc10802&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bc10802",
  null
  
)

export default component.exports