<template>
    <div class="d-flex justify-content-between align-items-center mr-2">
        <div class="card-avatar">
            <LazyLoadImg
                :src="ImageURL"
                :relativeHeight="100"
                class="card-image-container bg-asphalt"
                style="border-radius: 0.5rem !important"
                alt=""
            >
            </LazyLoadImg>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CardAvatar',
    data() {
        return {};
    },
    props: ['ImageURL', 'Name'],
    mounted() {},

    methods: {},
};
</script>
