<style lang="less" scoped>
.payment_method_card {
    .picture {
        height: 120px;
    }
}
</style>
<template>
    <b-overlay :show="loading">
        <div class="w-100 d-flex bg-white round-unify-xs p-3 payment_method_card" @click="selectPaymentMethod()">
            <div class="d-sm-flex align-items-center picture d-none">
                <div class="picture-info">
                    <b-img
                        v-if="object.Type === 'card'"
                        :src="require(`@/assets/payment-methods/${object.Details.CardBrand}.svg`)"
                        class="picture"
                    ></b-img>
                    <b-img
                        v-else-if="object.Type === 'sepa_debit'"
                        :src="require(`@/assets/payment-methods/sepa_debit.svg`)"
                        class="picture"
                    ></b-img>
                </div>
            </div>
            <div class="d-flex w-100 flex-column align-items-start item-data pl-3 py-2">
                <div class="d-flex flex-column w-100 justify-content-around">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="d-flex">
                            <h2 class="title mt-1 mt-lg-2 mb-2 mb-xs-3" v-if="object.Type === 'card'">
                                {{ getCardText(object.Details.CardBrand) }}

                                <span v-if="object.Default" class="text-muted">Standard</span>
                            </h2>
                            <h2 class="title mt-1 mt-lg-2 mb-2 mb-xs-3" v-if="object.Type === 'sepa_debit'">
                                SEPA
                                <span v-if="object.Default" class="text-muted">Standard</span>
                            </h2>
                        </div>
                        <div class="d-block float-right">
                            <b-dropdown
                                variant="px-2"
                                class="d-menu"
                                menu-class="mt-0 border-1"
                                no-caret
                                right
                                round
                                style="position: relative"
                            >
                                <template v-slot:button-content>
                                    <b-icon icon="three-dots" class="m-0" scale="1.2"></b-icon>
                                </template>
                                <b-dropdown-item class="d-action" @click="setAsDefault" :disabled="object.Default">
                                    <span :class="{ 'text-muted': object.Default }"> Set as standard </span>
                                </b-dropdown-item>
                                <b-dropdown-item class="d-action" @click="detachPaymentMethod">
                                    Delete method
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>
                    <div class="d-flex mt-0 mt-lg-2">
                        <!-- card details -->
                        <div v-if="object.Type === 'card'">
                            <p class="text-muted mb-0">Last four digits: {{ object.Details.Last4Digits }}</p>
                            <p class="text-muted">
                                Expires at: {{ object.Details.ExpMonth }}.
                                {{ object.Details.ExpYear }}
                            </p>
                        </div>

                        <!-- sepa details-->
                        <div v-else>
                            <p class="text-muted">Bank code: {{ object.Details.BankCode }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-overlay>
</template>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';

export default {
    name: 'PaymentMethod',
    props: {
        object: null,
        selectable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        selectPaymentMethod() {
            if (this.selectable) {
                this.$emit('selected', this.object);
            }
        },
        setAsDefault() {
            this.loading = true;
            const data = {
                ID: this.object.ID,
            };
            axios({
                method: 'PUT',
                url: '/user/account/billing/payment-methods/default',
                withCredentials: true,
                data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.$emit('setDefault', response.data);
                    }
                    this.loading = false;
                    EventBus.$emit('INFO', {
                        Message: 'Set as standard',
                        Details: '',
                    });
                })
                .catch((error) => {
                    this.loading = false;
                    EventBus.$emit('ERROR', {
                        Message: 'Failed to set this method as standard',
                        Details: '',
                    });
                    console.log(error);
                });
        },
        getCardText(brand) {
            switch (brand) {
                case 'amex':
                    return 'American Express';
                case 'diners':
                    return 'Diners';
                case 'discover':
                    return 'Discover';
                case 'jcb':
                    return 'JCB';
                case 'mastercard':
                    return 'Mastercard';
                case 'unionpay':
                    return 'UnionPay';
                case 'visa':
                    return 'VISA';
                default:
                    return brand;
            }
        },
        detachPaymentMethod() {
            this.loading = true;
            const paymentMethodId = this.object.ID;
            axios({
                method: 'DELETE',
                url: `/user/account/billing/delete-payment-method`,
                data: JSON.stringify({ ID: paymentMethodId }),
                timeout: 5000,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    this.$emit('detach', this.object.ID);
                    EventBus.$emit('INFO', {
                        Message: 'Payment method detached',
                        Details: '',
                    });
                })
                .catch((error) => {
                    this.loading = false;
                    EventBus.$emit('ERROR', {
                        Message: 'Failed to detach payment method',
                        Details: '',
                    });
                    console.log(error);
                });
        },
    },
};
</script>
