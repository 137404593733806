








































































































































import { PropType } from 'vue';
import { union, orderBy, get, sumBy, filter, isEmpty, pick, forEach, map, set } from 'lodash';

import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoDownloadAsCsv from '../../Organisms/co-download-as-csv/CoDownloadAsCsv.vue';
import CoAlert from '../../Molecules/co-alert/CoAlert.vue';
import CoSkeleton from '../../Atoms/co-skeleton/CoSkeleton.vue';

interface Column {
    title: string;
    key: string;
}

interface OrderCall {
    key: string;
    asc: boolean;
}

export default {
    components: {
        CoHeadline,
        CoIcon,
        CoButton,
        CoDownloadAsCsv,
        CoAlert,
        CoSkeleton,
    },
    name: 'CoTable',
    props: {
        items: {
            type: Array as PropType<Object[]>,
            default() {
                return [];
            },
        },
        columns: {
            type: Array as PropType<Column[]>,
            default() {
                return [];
            },
        },
        striped: {
            type: Boolean,
            default: false,
        },
        itemsPerPage: {
            type: Number,
            default: 10,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        sortable: {
            type: Boolean,
            default: true,
        },
        showItemsCount: {
            type: Boolean,
            default: true,
        },
        preventHorizontalScroll: {
            type: Boolean,
            default: false,
        },
        fixedFirstColumn: {
            type: Boolean,
            default: false,
        },
        fixedFirstRow: {
            type: Boolean,
            default: false,
        },
        resizableColumns: {
            type: Boolean,
            default: false,
        },
        csvDownload: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        info: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            orderedByColumn: null,
            order: 'asc',
            orderedItems: this.items,
            currentPage: 1,
            dragStartX: null,
            dragStartColWidth: null,
            draggedTh: null,
            dragger: null,
            draggerStartX: null,
        };
    },
    computed: {
        locCols() {
            if (this.columns && this.columns.length > 0) {
                return this.columns;
            }
            // create an inventory of all unique keys to build columns accordingly
            let keys = [];
            this.items.forEach((element) => {
                keys = union(keys, Object.keys(element));
            });
            // return title,key objects
            return keys.map((i) => ({ title: i, key: i }));
        },
        itemsCount() {
            return this.items.length;
        },
        pagesCount() {
            if (!this.itemsCount || !this.itemsPerPage) return null;
            return Math.ceil(this.itemsCount / this.itemsPerPage);
        },
        displayedItems() {
            if (!this.pagesCount || this.pagesCount < 2) return this.orderedItems;
            return this.orderedItems.slice(
                this.currentPage * this.itemsPerPage - this.itemsPerPage,
                this.currentPage * this.itemsPerPage
            );
        },
        sums() {
            let sumObj = {};
            filter(this.locCols, ['sum', true]).forEach((i) => {
                sumObj[i.key] = sumBy(this.items, (o) => {
                    if (o[i.key] && parseFloat(o[i.key])) {
                        return parseFloat(o[i.key]);
                    }
                });
            });
            return isEmpty(sumObj) ? null : sumObj;
        },
        colClasses() {
            // create an inventory of all unique keys to build columns accordingly
            var classesObj = {};
            this.locCols.map((element) => {
                let classes = element.class ? element.class.split(' ') : [];
                element.important ? classes.push('important') : null;
                element.notSortable ? classes.push('not-sortable') : null;
                classes.forEach((i) => {
                    set(classesObj, `[${element.key}][${i}]`, true);
                });
            });
            return classesObj;
        },
        colStyles() {
            // create an inventory of all unique keys to build columns accordingly
            var stylesObj = {};
            this.locCols.map((element) => {
                let styles = element.style ? element.style : '';
                stylesObj[element.key] = styles;
            });
            return stylesObj;
        },
    },
    watch: {
        items(newVal, oldVal) {
            this.orderedItems = newVal;
            if (newVal.length < oldVal.length) {
                this.currentPage = 1;
            }
        },
    },
    emits: ['order'],
    mounted() {
        this.$nextTick(function () {
            // Code that will run only after the
            // entire view has been rendered
            this.updateColumnPosition();
            window.addEventListener('resize', this.updateColumnPosition);
        });
    },
    updated() {
        this.$nextTick(() => {
            // Code that will run only after the
            // entire view has been rendered
            this.updateColumnPosition();
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateColumnPosition);
    },
    methods: {
        get, // lodash get
        orderItems(orderkey) {
            if (!orderkey || typeof orderkey !== 'string') return false;
            // jump to page 1 when ordering changes
            this.currentPage = 1;

            if (this.orderedByColumn === orderkey) {
                this.order = this.order === 'asc' ? 'desc' : 'asc';
            } else {
                this.orderedByColumn = orderkey;
                this.order = 'asc';
            }

            let that = this;
            this.orderedItems = orderBy(
                this.orderedItems,
                (o) => {
                    let orderType = typeof get(o, orderkey, '');
                    switch (orderType) {
                        case 'number':
                            return get(o, orderkey, '');
                        case 'string':
                        case 'boolean':
                            return get(o, orderkey, '').toString().toLowerCase();
                        default:
                            return false;
                    }
                },
                [that.order]
            );

            this.$emit('order', {
                key: orderkey,
                asc: this.order === 'asc',
            } as OrderCall);

            return true;
        },

        nextPage() {
            // currentPage < pagesCount ? currentPage++ : null
            if (this.currentPage < this.pagesCount) {
                this.currentPage += 1;
            }

            this.$emit('nextPage');
            return true;
        },

        getValue(item, key) {
            if (!item || !key || typeof key !== 'string') return null;

            // if key is a nested object, return the value using lodash get
            return get(item, key);
        },

        getIcon(columnKey) {
            if (this.orderedByColumn === columnKey && this.order === 'asc') return 'arrow-up-short';
            if (this.orderedByColumn === columnKey && this.order === 'desc') return 'arrow-down-short';
            return 'chevron-expand';
        },
        columnDragStart(event, index) {
            const resizeContainer = this.$refs.resizeContainer;
            const table = this.$refs.table;
            const th = this.$el.querySelector(`th:nth-of-type(${index + 1})`);

            this.dragger = event.target;
            this.draggerStartX = event.target.offsetLeft;
            this.dragStartColWidth = th.offsetWidth;
            this.dragStartX = event.pageX;
            this.draggedTh = th;

            this.dragger.classList.add('active');

            document.body.style.cursor = 'col-resize';
            document.body.style.userSelect = 'none';

            resizeContainer.addEventListener('mousemove', this.columnDrag);
            table.addEventListener('mousemove', this.columnDrag);
            document.addEventListener('mouseup', this.columnDragEnd);
        },
        columnDrag(event) {
            const distanceX = event.pageX - this.dragStartX;
            const th = this.draggedTh;
            if (th && th.style && this.dragStartColWidth - 50 > -distanceX) {
                this.dragger.style.left = this.draggerStartX + distanceX + 'px';
                th.style.width = this.dragStartColWidth + distanceX + 'px';
            }
        },
        columnDragEnd(event) {
            const resizeContainer = this.$refs.resizeContainer;
            const table = this.$refs.table;

            this.dragger.classList.remove('active');

            document.body.style.cursor = '';
            document.body.style.userSelect = '';

            document.removeEventListener('mouseup', this.columnDragEnd);
            resizeContainer.removeEventListener('mousemove', this.columnDrag);
            table.removeEventListener('mousemove', this.columnDrag);
            this.updateColumnPosition();
        },
        updateColumnPosition() {
            if (!this.resizableColumns) return;
            if (!this.$refs.table) return;
            const tableDimensions = this.$refs.table.getBoundingClientRect();
            const ths = this.$el.querySelectorAll('th');
            const draggers = this.$el.querySelectorAll('.columns-dragger');
            var columWidth = 0;
            forEach(ths, (th, index) => {
                const dragger = draggers[index];
                // set min width for columns
                const offsetWidth = th.offsetWidth > 100 ? th.offsetWidth : 100;
                columWidth += offsetWidth;
                dragger.style.top = '0';
                dragger.style.height = tableDimensions.height + 'px';
                dragger.style.left = columWidth - 5 + 'px';
                th.style.width = offsetWidth + 'px';
            });
        },
        getTableData() {
            let columns = map(this.locCols, 'key');
            let data = [];
            forEach(this.orderedItems, (value, key) => {
                data.push(pick(value, columns));
            });
            return data;
        },
        downloadCsv() {
            var csvData = this.getTableData();
            this.$refs.csvDownloader.exportAsCSV(csvData);
        },
    },
};
