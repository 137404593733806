var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"co emopicker"},[_c('CoTippy',{ref:"emopicker",attrs:{"tippyOptions":{
            trigger: 'click',
            hideOnClick: true,
            interactive: true,
            delay: 0,
            duration: 0,
            arrow: null,
            placement: 'auto',
        },"arrowColor":_vm.arrowColor}},[_vm._t("default",[_c('CoIcon',{staticClass:"emotrigger",attrs:{"name":"custom-emoji-add"}})]),_c('template',{slot:"content"},[_c('div',{ref:"picker",staticClass:"picker p-3"},[_c('div',{staticClass:"emoji-search mb-3"},[_c('CoInput',{attrs:{"type":"text","icon":"search","placeholder":_vm.$t('placeholders.search'),"background":"transparent"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"emoji-list"},_vm._l((_vm.emojis),function(emojiGroup,category){return _c('div',{key:category},[_c('CoText',{staticClass:"my-1",attrs:{"type":"p2","variant":"mute"}},[_vm._v(_vm._s(_vm.$t(category)))]),_c('div',{staticClass:"emojis"},_vm._l((emojiGroup),function(emoji,emojiName){return _c('span',{key:emojiName,attrs:{"title":_vm.$t(emojiName)},on:{"click":function($event){return _vm.insertEmoji(emoji)}}},[_vm._v(_vm._s(emoji))])}),0)],1)}),0)])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }