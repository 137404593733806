<style lang="less" scoped src="@/assets/less/manage-space/base.less">
.input-group.email-with-admin {
    .input-left-rounded {
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        background-color: transparent;
    }

    .input-group-append {
        .input-group-text {
            background-color: transparent;
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;
            border-left-color: transparent;
        }
    }
}
</style>
<template>
    <div>
        <!-- search and actions start -->
        <div class="px-sm-4 d-flex align-items-center justify-content-end">
            <CoUserInvite class="mr-2" @invite-success="reload()" @invite-error="reload()" />
        </div>
        <!-- search and actions end -->
        <div class="px-sm-4">
            <coTable
                :items="this.objects"
                :sortable="true"
                :loading="this.requestLoading"
                :showItemsCount="true"
                :limit="100"
                :itemsPerPage="50"
                :columns="this.fields"
                @order="this.sortingChanged"
                @nextPage="this.nextPage"
            >
                <template v-slot:InvitedAt="slotProps">
                    <CoDate :date="slotProps.item.InvitedAt ? slotProps.item.InvitedAt : null" format="date" />
                </template>
                <template v-slot:SignedUpAt="slotProps">
                    <CoDate
                        v-if="slotProps.item.SignedUpAt"
                        :date="slotProps.item.SignedUpAt ? slotProps.item.SignedUpAt : null"
                        format="date"
                    />
                    <span v-else>-</span>
                </template>
                <template v-slot:Action="slotProps">
                    <CoButton
                        iconleft="clipboard"
                        @click="copyToClipboard(slotProps.item.ID)"
                        label="Copy"
                        variant="text"
                    />
                </template>
            </coTable>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import EventBus from '@/eventBus';
import CoUserInvite from '../../../../components/Organisms/co-user-invite/CoUserInvite.vue';
import CoTable from '../../../../components/Molecules/co-table/CoTable.vue';
import CoDate from '../../../../components/Molecules/co-date/CoDate.vue';
import CoButton from '../../../../components/Atoms/co-button/CoButton.vue';
import CoLink from '../../../../components/Atoms/co-link/CoLink.vue';
import CoIcon from '../../../../components/Atoms/co-icon/CoIcon.vue';

export default {
    name: 'InvitedUsers',
    components: {
        CoUserInvite,
        CoTable,
        CoButton,
        CoDate,
        CoLink,
        CoIcon,
    },
    data() {
        return {
            tableFilter: null,
            objects: [],
            errorFalseMes: false,
            errorManyMes: false,
            sendShow: false,
            errorSend: false,
            fields: [
                {
                    key: 'Email',
                    title: 'Email',
                    sortable: true,
                },
                {
                    key: 'Status',
                    title: 'Status',
                },
                {
                    key: 'InvitedAt',
                    title: 'Invited at',
                },
                {
                    key: 'SignedUpAt',
                    title: 'Signed up at',
                },
                {
                    title: 'Unique invite link',
                    key: 'Action',
                    style: 'width: 2.375rem; padding:0;',
                    notSortable: true,
                },
            ],
            requestLoading: false,

            requestedCount: 0,

            orderBy: 'InvitedAt',
            orderAsc: false,
            limit: 10,
            next: null,
        };
    },
    computed: {
        errorMany() {
            return this.emails.length > 10;
        },
    },
    mounted() {
        this.requestLoading = true;
        this.listInvitations();
    },
    methods: {
        copyToClipboard(id) {
            // get current page domain
            let domain = window.location.hostname;
            if (window.location.port) {
                domain += `:${window.location.port}`;
            }

            // add path /signup?invite=${text}
            const url = `https://${domain}/signup?invite=${id}`;

            const el = document.createElement('textarea');
            el.value = url;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            EventBus.$emit('INFO', {
                Message: 'Copied to clipboard',
            });
        },
        reload() {
            this.requestLoading = true;
            this.requestedCount = 0;
            this.objects = [];
            this.listInvitations();
        },

        sortingChanged(sorting) {
            switch (sorting.key) {
                case 'Email':
                    this.orderBy = 'email';
                    break;
                case 'Status':
                    this.orderBy = 'status';
                    break;
                case 'InvitedAt':
                    this.orderBy = 'invitedAt';
                    break;
                case 'signedUpAt':
                    this.orderBy = 'SignedUpAt';
                    break;
                default:
                    this.orderBy = 'invitedAt';
                    break;
            }

            if (sorting.asc) {
                this.orderAsc = 'asc';
            } else {
                this.orderAsc = 'desc';
            }
            this.reload();
        },

        nextPage() {
            if (this.next) {
                this.listInvitations(this.next);
            }
        },

        listInvitations(next) {
            this.requestedCount += 1;
            let url = `/admin/member/invitations?orderBy=${this.orderBy}&order=${this.orderAsc}&limit=${this.limit}`;
            if (next) {
                url = next;
            }
            this.$store
                .dispatch('getInvitedUsers', url)
                .then((response) => {
                    this.requestLoading = false;

                    if (response && response.data && response.data.objects) {
                        this.objects.push.apply(this.objects, response.data.objects);
                        if (response.data.next && this.requestedCount < 2) {
                            this.listInvitations(response.data.next);
                        }

                        if (response.data.next) {
                            this.next = response.data.next;
                        } else {
                            this.next = null;
                        }
                    }
                })
                .catch((error) => {
                    this.requestLoading = false;

                    console.log(error);
                });
        },
    },
};
</script>
