




































































































import axios from 'axios';
import EventBus from '@/eventBus';
import { formatDate } from '@fullcalendar/common';
import Membership from './Membership.vue';

export default {
    components: { Membership },
    name: 'Review',
    data() {
        return {
            AcceptPrivacyPolicy: false,
            AcceptTermsAndConditions: false,
            PrivacyPolicy: null,
            TermsAndConditions: null,
            isReady: false,

            loading: false,

            customButtonTitle: '',
        };
    },
    computed: {
        address() {
            return this.$store.state.newMembership.Address;
        },
        account() {
            return this.$store.state.newMembership.Account;
        },

        plan() {
            return this.$store.state.newMembership.Plan;
        },
        extras() {
            return this.$store.state.newMembership.Extras;
        },
        membershipTotalPrice() {
            let total = this.calculatePrice(this.$store.state.newMembership.Plan);
            if (this.$store.state.newMembership && this.$store.state.newMembership.Extras) {
                this.$store.state.newMembership.Extras.forEach((element) => {
                    total += this.calculatePrice(element);
                });
            }

            return total.toFixed(2);
        },
        confirmationBtnTitle() {
            if (this.customButtonTitle) {
                return this.customButtonTitle;
            }
            if (this.membershipTotalPrice > 0) {
                return this.$t('labels.ordercompletesubcription');
            }
            return this.$t('labels.ordercompletesubcriptionfree');
        },
    },
    mounted() {
        this.getPrivacyPolicy();
        this.getTermsAndConditions();

        // next tick to make sure the data is loaded
        this.$nextTick(() => {
            const interfaceCustomisations = this.plan ? this.plan.InterfaceCustomisations : null;
            if (interfaceCustomisations && interfaceCustomisations.length > 0) {
                // find the customisation for the confirmation button text
                const customisation = interfaceCustomisations.find((x) => x.Key === 'CreateSubscriptionButtonTitle');
                this.customButtonTitle = customisation.Value;
            }
        });
    },
    methods: {
        formatDate(timestamp: string) {
            return new Date(timestamp).toLocaleDateString();
        },
        calculatePrice(item) {
            if (!item) return 0;
            if (item.TaxRate && item.TaxRate.Inclusive) {
                const price = item.PriceInCents / 100.0;
                return price;
            }
            const price = item.PriceInCents / 100.0;
            const totalPrice = price * (item.TaxRate.Percentage / 100 + 1);
            return totalPrice;
        },
        changeAcception() {
            let ready = true;
            if (this.PrivacyPolicy && !this.AcceptPrivacyPolicy) {
                ready = false;
            }

            if (this.TermsAndConditions && !this.AcceptTermsAndConditions) {
                ready = false;
            }
            this.isReady = ready;
        },
        back() {
            if (this.$unleash.isEnabled('frontend.skip-payment')) {
                this.$store.commit('SET_STEP', 3);
            } else {
                this.$store.commit('PREVIOUS_STEP');
            }
        },

        prepareData() {
            const signUpRequest = {
                Coupon: this.$store.state.newMembership.Coupon,
                CustomerID: this.$store.state.newMembership.CustomerID,
                PaymentMethodeID: this.$store.state.newMembership.PaymentMethodeID,
                Account: {
                    Salutation: this.account.Salutation,
                    FirstName: this.account.FirstName,
                    LastName: this.account.LastName,
                    Email: this.account.Email,
                    Password: this.account.Password,
                    BirthDate: '',
                },
                Address: {
                    Company: this.address.Company,
                    Phone: this.account.Phone,
                    Address: this.address.Address,
                    AdditionalInfo: this.address.AdditionalInfo,
                    PostalCode: this.address.PostalCode,
                    City: this.address.City,
                    Country: this.address.Country,
                },
                IsFromOA2Provider: this.$store.state.newMembership.IsFromOA2Provider,
                PlanID: '',
                PlanVersion: '',
                Extras: [],
                Agreements: [],
                InviteID: '',
            };

            if (this.$store.state.newMembership.InviteID && this.$store.state.newMembership.InviteID !== '') {
                signUpRequest.InviteID = this.$store.state.newMembership.InviteID;
            }

            if (this.account.BirthDate) {
                signUpRequest.Account.BirthDate = this.account.BirthDate;
            }

            if (this.plan) {
                signUpRequest.PlanID = this.plan.ID;
                signUpRequest.PlanVersion = this.plan.Version;
            }

            const extras = [];
            if (this.extras && this.extras.length !== 0) {
                this.extras.forEach((element, index) => {
                    extras.push({
                        ID: element.ID,
                        Version: element.Version,
                    });
                });
            }
            signUpRequest.Extras = extras;

            const Agreements = [];
            if (this.PrivacyPolicy && this.PrivacyPolicy.CreatedAt !== 0) {
                Agreements.push({
                    Version: this.PrivacyPolicy.CreatedAt,
                    Type: 0,
                });
            }

            if (this.TermsAndConditions) {
                Agreements.push({
                    Version: this.TermsAndConditions.CreatedAt,
                    Type: 1,
                });
            }

            signUpRequest.Agreements = Agreements;
            return signUpRequest;
        },

        create() {
            this.loading = true;
            const data = this.prepareData();
            let url = '/user/signupV3';

            axios({
                method: 'POST',
                url,
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response) {
                        const token = response.data;
                        this.$store.commit('SET_JWT', token);
                    }
                    this.$store.commit('RESET_MEMBERSHIP_PROVIDER');
                    this.$store.commit('RESET_NEW_MEMBERSHIP');
                    this.$router.push('/signup-success');
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        const errorMsg = {
                            Message: error.response.data.message,
                            Details: '',
                        };
                        EventBus.$emit('ERROR', errorMsg);
                    }
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        getPrivacyPolicy() {
            axios({
                method: 'GET',
                url: '/space/get-privacy-policy',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.PrivacyPolicy = response.data;
                    }
                })
                .catch((error) => {
                    this.PrivacyPolicy = {
                        Content: '',
                        CreatedAt: 0,
                    };
                    console.log(error);
                });
        },

        getTermsAndConditions() {
            axios({
                method: 'GET',
                url: '/space/get-terms-and-conditions',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.TermsAndConditions = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
