


























import CoIcon from '../co-icon/CoIcon.vue';

export default {
    name: 'CoCheckbox',
    components: {
        CoIcon,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: '#000000',
        },
        name: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String,
            default: 'radio',
            validator: (value: string) => ['radio', 'checkbox'].includes(value),
        },
    },
    model: {
        prop: 'value',
        event: 'change',
    },
    data() {
        return {
            model: this.value,
            checkColor: this.value ? this.color : 'transparent',
        };
    },
    watch: {
        model(val) {
            if (this.disabled) {
                if (val) {
                    this.checkColor = '#e5e5e5';
                } else {
                    this.checkColor = 'transparent';
                }
                return;
            }
            if (val) {
                this.checkColor = this.color;
            } else {
                this.checkColor = 'transparent';
            }
        },
        value(val) {
            this.model = val;
        },

        disabled(val) {
            if (val) {
                if (this.model) {
                    this.checkColor = '#e5e5e5';
                } else {
                    this.checkColor = 'transparent';
                }
            } else if (this.model) {
                this.checkColor = this.color;
            } else {
                this.checkColor = 'transparent';
            }
        },
    },
    methods: {
        toggle() {
            if (this.disabled) {
                return;
            }
            this.model = !this.model;
            this.$emit('change', this.model);
        },
    },
};
