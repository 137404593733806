<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <transition name="post-fade">
        <div v-if="!deleted">
            <b-overlay :show="deleting" class="round-unify">
                <div class="bg-yellow round-unify">
                    <div class="d-flex align-items-center p-2">
                        <div class="coapp-icon">
                            <b-icon icon="geo"></b-icon>
                        </div>
                        <div class="d-flex align-items-center justify-content-between w-100 flex-grow-1">
                            <div>
                                {{ userNote.AuthorName }}
                                <small class="ml-1">
                                    <CoDate :date="userNote.Timestamp" format="relative" />
                                </small>
                            </div>
                            <b-dropdown
                                variant="ml-2 px-2"
                                class="d-menu"
                                menu-class="mt-0 border-1"
                                no-caret
                                right
                                round
                                style="position: relative"
                            >
                                <template v-slot:button-content>
                                    <b-icon icon="three-dots-vertical" class="m-0" scale="1.2"></b-icon>
                                </template>
                                <b-dropdown-item class="d-action" @click="deleteUserNote(userNote.ID)"
                                    >Delete</b-dropdown-item
                                >
                            </b-dropdown>
                        </div>
                    </div>
                    <div class="d-flex">
                        <span class="coapp-icon"></span>
                        <div class="pl-2" v-html="userNote.Text" v-linkifytiptap></div>
                    </div>
                </div>
            </b-overlay>
        </div>
    </transition>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import EventBus from '@/eventBus';
import linkify from 'vue-linkify';
import CoDate from '../../Molecules/co-date/CoDate.vue';

Vue.directive('linkified', linkify);

export default {
    components: { CoDate },
    name: 'UserNote',
    props: ['userNote'],
    data() {
        return {
            deleting: false,
            deleted: false,

            me: this.$store.state.me,
        };
    },
    mounted() {},
    created() {},
    methods: {
        deleteUserNote(id) {
            this.deleting = true;
            axios({
                method: 'DELETE',
                url: `/admin/notes/${id}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.deleting = false;
                    this.deleted = true;
                    this.$emit('delete', id);
                })
                .catch((error) => {
                    this.deleting = false;
                    this.deleted = true;
                    EventBus.$emit('ERROR', {
                        Message: 'Error deleting user note',
                        Details: error,
                    });
                });
        },
    },
};
</script>
