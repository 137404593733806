<style lang="less" scoped>
.list-item {
    background: white;
    margin-bottom: 10px;
    cursor: pointer;

    .picture {
        width: 96px;
        height: 96px;
        object-fit: cover;
        @media (min-width: 768px) {
            width: 142px;
            height: 142px;
            object-fit: cover;
        }
    }

    .item-data {
        // height: 80px;

        font-size: 0.8rem;
        line-height: 0.8rem;
        .btn {
            padding: 0.2rem 0.8rem;
            font-size: 0.8rem;
            line-height: 0.8rem;
        }

        .small-icon {
            width: 24px !important;
            min-width: 24px !important;
            height: 24px !important;
            min-height: 24px !important;
        }
        @media (min-width: 768px) {
            // height: 120px;

            font-size: 1rem;
            line-height: 1rem;
            .btn {
                padding: 0.3rem 0.8rem;
                font-size: 1rem;
                line-height: 1rem;
            }

            .small-icon {
                width: 32px !important;
                min-width: 32px !important;
                height: 32px !important;
                min-height: 32px !important;
            }
        }
    }

    .title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    &:hover .picture {
        opacity: 0.7;
    }
}
a {
    color: inherit;
    text-decoration: inherit;
}
</style>

<template>
    <router-link
        class="list-item round-unify w-100 d-flex align-items-start"
        tag="a"
        :to="'/job/' + object.object.Slug"
    >
        <div class="picture">
            <b-img :src="filterPictures(imageURL)" class="picture" rounded="left"></b-img>
        </div>
        <div>
            <div class="d-flex w-100 flex-column align-items-start item-data mt-2 pl-3 py-2">
                <h2 class="title mb-2 mb-xs-3">{{ object.object.Title }}</h2>
                <div class="d-flex align-items-center mb-2 mt-1 mb-xs-3">
                    <!-- <span class="ml-1">{{ category }}</span> -->
                    <div v-if="author" class="small-icon ml-2">
                        <CoThumbnail
                            :ImageURL="author.Profile.ImageURL"
                            :Round="true"
                            :Clickable="false"
                            contentType="user"
                        />
                    </div>
                    <div v-if="author" class="ml-2">
                        <router-link @click.prevent.stop.self="viewUser(author.Slug)" :to="'/profile/' + author.Slug">{{
                            author.Profile.Name
                        }}</router-link>
                    </div>
                </div>

                <!-- TODO: CTA-Action Button finish. Remove v-if in next line to show buttons -->
                <div class="mt-1">
                    <b-button
                        v-if="object.object.Open && !amIInterested && !iAmAuthor"
                        @click.prevent.stop.self="showModal('apply-modal-' + object.object.ID)"
                        variant="primary"
                        >{{ $t('labels.interested') }}</b-button
                    >
                    <b-button v-else-if="amIInterested" disabled variant="outline-success" class="cursor-not-allowed">{{
                        $t('labels.interestedsent')
                    }}</b-button>
                    <b-button
                        v-else-if="!object.object.Open"
                        disabled
                        variant="outline-danger"
                        class="disabled cursor-not-allowed"
                    >
                        {{ $t('labels.closed') }}</b-button
                    >
                </div>
            </div>
        </div>

        <!-- modals -->
        <b-modal
            :id="'apply-modal-' + object.object.ID"
            :ref="'apply-modal-' + object.object.ID"
            size="sm"
            :title="$t('labels.interested')"
            @ok="iAmInterested()"
            :ok-title="$t('labels.send')"
            :cancel-title="$t('labels.cancel')"
            ok-variant="primary"
            centered
        >
            <b-overlay :show="loadingForInterest" spinner-variant="primary" rounded="sm">
                <p class="mb-1">{{ $t('messages.marketiterestsubmit') }}</p>
            </b-overlay>
        </b-modal>
    </router-link>
</template>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';

import CoThumbnail from '../Molecules/co-thumbnail/CoThumbnail.vue';

export default {
    name: 'JobCard',
    components: {
        CoThumbnail,
    },
    props: ['object'],
    data() {
        return {
            imageURL: this.object.object.ImageURL,
            name: '',
            windowWidth: window.innerWidth,
            author: null,
            iAmAuthor: this.$store.state.me.ID === this.object.object.AuthorID,
            amIInterested: false,
            loadingForInterest: false,
            interest: {
                UserID: this.$store.state.me.ID,
                JobID: '',
            },
        };
    },

    watch: {
        object(newVal, oldVal) {
            this.imageURL = this.object.object.ImageURL;
            this.getAuthor();
        },
    },

    computed: {
        icon() {
            this.iconMap[this.object.type];
        },
        description() {
            this.typeMap[this.object.type];
        },
        isDesktop() {
            return this.windowWidth >= 768;
        },

        category() {
            if (this.object.object.Category == 0) {
                return 'Job by';
            }
            if (this.object.object.Category == 1) {
                return 'Service by';
            }
            return 'Product by';
        },
    },

    mounted() {
        this.getAuthor();
        this.getUsersInterestedInJob();
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });
    },
    methods: {
        showModal(id) {
            this.$refs[id].show();
        },
        iAmInterested() {
            this.loadingForInterest = true;
            this.interest.JobID = this.object.object.ID;
            axios({
                method: 'POST',
                url: '/job/interest',
                data: JSON.stringify(this.interest),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loadingForInterest = false;
                    const msg = {
                        Message: 'Request sent',
                        Details: '',
                    };
                    EventBus.$emit('INFO', msg);
                    this.amIInterested = true;
                    this.$refs[`apply-modal-${this.object.object.ID}`].hide();
                })
                .catch((error) => {
                    this.loadingForInterest = false;
                    EventBus.$emit('ERROR', {
                        Message: 'Your request failed with internal error. Please, report to team.',
                        Details: '',
                    });
                    this.$refs[`apply-modal-${this.object.object.ID}`].hide();
                });
        },

        viewUser(slug) {
            this.$router.push(`/profile/${slug}`);
        },
        getAuthor() {
            const id = this.object.object.AuthorID;
            const ids = JSON.stringify({ IDS: [{ ID: id }] });
            this.$store
                .dispatch('listUsersByIDs', { ids })
                .then((response) => {
                    if (response.Users != null) {
                        this.author = response.Users[0];
                    } else {
                        this.author = {
                            ID: '',
                            Profile: { ImageURL: '', Name: 'User not found' },
                        };
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.author = {
                        ID: '',
                        Profile: { ImageURL: '', Name: 'User not found' },
                    };
                });
        },

        getUsersInterestedInJob() {
            axios({
                method: 'GET',
                url: `/job/get-interested-users/${this.object.object.ID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    response.data.Interests.forEach(function (entry) {
                        if (this.$store.state.me.ID === entry.UserID) {
                            this.amIInterested = true;
                        }
                    }, this);
                })
                .catch((error) => {});
        },

        filterPictures(imageurl) {
            if (imageurl) {
                return imageurl;
            }

            const randomItem = parseInt(Math.random() * 16) + 1;
            const images = require.context('@/assets/gradients/', true);
            return images(`./Platform_Gradients-${randomItem}.jpg`);
        },
    },
};
</script>
