






import ListChannels from '../../../Templates/User/Channel/List/ListChannels.vue';

export default {
    name: 'CoChannelList',
    components: {
        ListChannels,
    },
};
