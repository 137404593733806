<template>
    <div class="min-vh-100 d-flex bg justify-content-center align-items-md-center">
        <vue-headful
            :title="'Login -' + this.$store.state.space.Name + 'Community Platform'"
            description=""
            :image="this.$store.state.space.LogoURL"
        />
        <!-- user login -->
        <div class="login-form">
            <div class="mt-4 mt-md-0 p-4 bg-white round-unify text-left">
                <div class="text-center">
                    <img
                        v-if="this.$store.state.space.LogoURL"
                        :src="this.$store.state.space.LogoURL"
                        width="145"
                        height="auto"
                        alt="Community Logo"
                        class="my-2"
                    />
                    <div v-else class="my-2">
                        <h2>{{ this.$store.state.space.Name }}</h2>
                    </div>
                </div>
                <h2 class="my-1 text-center pt-2"><coIcon name="shield-lock" /> Please set a new password</h2>
                <div class="w-100 py-4">
                    <div class="bg-white text-center">
                        <p>To keep your account secure, we have initiated a password reset.</p>
                        <p><b>Please check your email for a link to reset your password.</b></p>
                        <p>
                            If you do not receive our message within the next few minutes, check your spam folder or try
                            to
                            <a href="/reset-password" title="reset your password manually"
                                >reset your password manually.</a
                            >
                        </p>
                        <b-button class="m-2" variant="primary" to="/login">back to Login</b-button>
                    </div>
                </div>
            </div>
        </div>
        <!-- user login -->
    </div>
</template>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

// login view
.login-form {
    width: 100%;
    max-width: 400px;
}

input[type='text'].form-control::placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='text'].form-control::-webkit-input-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='text'].form-control:-moz-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='text'].form-control::-ms-input-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='text'].form-control::-moz-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

.bg {
    background-color: #fff;

    @media (min-width: 768px) {
        background-color: #dedfde;
    }
}

.hfv-red-btn {
    margin-top: 35px;
}

.d-block {
    margin-left: 0.8em !important;
    font-weight: 600 !important;
    color: black !important;
    font-size: 18px !important;
}

.card-body {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
</style>

<script>
import Axios from 'axios';

export default {
    name: 'ResetPassword',
    data() {
        return {
            email: null,
            error: false,
            loading: false,
            step: 1,
        };
    },
    mounted() {},

    methods: {
        resetPassword() {
            this.loading = true;
            this.error = null;
            const data = JSON.stringify({ Email: this.email });
            Axios({
                method: 'POST',
                url: '/user/reset-password',
                withCredentials: true,
                data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    this.step = 2;
                })
                .catch((error) => {
                    this.error = error;
                    this.loading = false;
                    console.log(error);
                });
        },
    },
};
</script>
