<template>
    <div
        class="lzy-img-container d-flex"
        :class="{
            'is-loaded hide_placeholder': ready,
            'lzy-variant-dark': darkVariant,
            round: round,
            profilePlaceholder: profilePlaceholder,
            'w-v': whiteVariant,
        }"
        :empty="error || !src"
        :style="{
            'padding-top': relativeHeight + '% !important',
        }"
    >
        <img
            class="lazy-load lzy-img-loader"
            data-object-fit="cover"
            :src="imgSrc"
            width="100"
            height="100"
            :ready="ready"
            @load="onLoad()"
            @error="onError()"
        />
    </div>
</template>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.lzy-img-container {
    position: relative;
    overflow: hidden;
    background-image: url(../../assets/picture-placeholder.png);
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: center;
    background-color: @co-lightgrey;
    transition: background-color 0.3s ease-in-out;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);

    &.rounded {
        @media (min-width: 768px) {
            border-radius: 7px;
        }
    }

    &.round {
        border-radius: 50%;
    }

    &.w-v {
        background-color: white;
    }

    &.profilePlaceholder {
        background-image: url(../../assets/profile-placeholder.svg);
        background-size: 70px;

        &.lzy-variant-dark {
            background-size: 16px;
        }
    }
}

.hide_placeholder {
    background-image: none;
}

.lzy-img-container[empty] {
    // background-color: rgba(255,255,255,.2);
}

.lzy-img-container.lzy-variant-dark {
    background-color: @newgrey;
}

.lzy-img-container.is-loaded {
    background-color: transparent;
}

img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

img[ready] {
    opacity: 1;
}
</style>
<script>
export default {
    name: 'LazyLoadImg',
    props: {
        src: {
            type: String,
            default: null,
        },
        relativeWidth: {
            type: Number,
            default: 100,
        },
        relativeHeight: {
            type: Number,
            default: 100,
        },
        alt: {
            type: String,
            default: null,
        },
        imgClass: {
            type: String,
            default: null,
        },
        darkVariant: {
            type: Boolean,
            default: false,
        },
        whiteVariant: {
            type: Boolean,
            default: false,
        },
        round: {
            type: Boolean,
            default: false,
        },
        profilePlaceholder: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ready: false,
            error: false,
            imgSrc: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
            imgSrcOriginal:
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
        };
    },

    watch: {
        src(newVal, oldVal) {
            this.ready = false;
            this.error = false;
            this.imgSrc = this.imgSrcOriginal;
            if (this.src == null || this.src == '') {
                // if no image source url is given with props, there is no need to observe the image for lazy loading
                // instead, just show the default image
                return;
            }

            /* 
            event listener to load image 
            only when entering the viewport 
            */
            const that = this;
            const images = this.$el.querySelectorAll('img');
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((image) => {
                        if (image.isIntersecting) {
                            observer.unobserve(image.target);
                            that.ready = false;
                            that.imgSrc = that.$props.src;
                        }
                    });
                },
                {
                    root: null,
                    rootMargin: '0px 0px 200px 0px',
                }
            );

            images.forEach((image) => observer.observe(image));
        },
    },
    mounted() {
        if (this.src == null || this.src == '') {
            // if no image source url is given with props, there is no need to observe the image for lazy loading
            // instead, just show the default image
            return;
        }

        /* 
        event listener to load image 
        only when entering the viewport 
        */
        const that = this;
        const images = this.$el.querySelectorAll('img');
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((image) => {
                    if (image.isIntersecting) {
                        observer.unobserve(image.target);
                        that.ready = false;
                        that.imgSrc = that.$props.src;
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px 0px 200px 0px',
            }
        );

        images.forEach((image) => observer.observe(image));
    },
    methods: {
        onLoad() {
            if (this.src == null || this.src == '') {
                // if no image source url is given with props, don't let the image set to ready because the base64 placeholder image is loaded.
                // instead, just show the default image
                return;
            }
            if (this.imgSrc == this.imgSrcOriginal) {
                // if the base64 defaultsrc is still there, then actual image has not loaded
                return;
            }

            this.ready = true;
        },
        onError() {
            this.error = true;
            this.ready = false;
        },
    },
};
</script>
