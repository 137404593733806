<template>
    <div>
        <div class="mt-2 pt-2 d-flex w-100 justify-content-between align-items-top">
            <div class="mr-3">
                <ProfileCircle :ImageURL="profileDisplayed.ImageURL" :Slug="profileDisplayed.Slug"></ProfileCircle>
            </div>
            <b-overlay class="w-100" :show="creatingNewComment">
                <b-form class="w-100" ref="newcomment">
                    <b-form-group class="mb-0">
                        <Editor
                            id="textarea-default"
                            :placeholder="$t('commentplaceholder')"
                            taclass="m-0 comment-input"
                            rows="5"
                            ref="editor"
                            :withSendAction="true"
                            v-model="comment.Text"
                            @action-send="publishComment"
                            @action-enter="publishComment"
                            with-image
                            v-bind:imageFiles="imageFiles"
                        ></Editor>
                    </b-form-group>
                </b-form>
            </b-overlay>
        </div>
        <div ref="commentwrapper">
            <div v-if="ShowComments" class="mt-3">
                <div
                    v-if="(!allCommentsLoaded && comments.length !== 0) || (comments.length === 0 && loading)"
                    class="text-center"
                >
                    <b-spinner label="Spinning"></b-spinner>
                </div>

                <div class="text-center round-unify" v-if="comments.length == 0 && !loading">
                    <illustration type="community" class="my-0 illu-small"></illustration>
                    <div class="mb-4">{{ $t('nocomments') }}</div>
                </div>

                <div :class="{ 'd-none': !allCommentsLoaded }">
                    <Comment
                        class="list-complete-item"
                        v-for="(item, index) in comments"
                        :key="item.ID"
                        :class="{ 'hide-comment': index > visibleCommentsEndIndex }"
                        ref="comment"
                        @loaded="commentLoaded"
                        :highlight="item.ID == highlightCommentID"
                        @comment-deleted="getComments"
                        :object="item"
                    ></Comment>
                    <a v-if="notAllCommentsAreVisible" href="#" @click.stop.prevent="showMoreComments">{{
                        $t('showmore')
                    }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from '@/eventBus';
import smoothReflow from 'vue-smooth-reflow';
import { eventify } from 'calendar-link';
import i18n from 'vue-i18n';
import Illustration from './Illustration.vue';

export default {
    i18n: {
        messages: {
            en: {
                nocomments: 'No comments yet. Be the first to comment',
                commentplaceholder: 'Write a comment',
                showmore: 'show more comments',
            },
            de: {
                nocomments: 'Noch keine Kommentare. Mach den Anfang.',
                commentplaceholder: 'Schreibe einen Kommentar',
                showmore: 'zeige weitere Kommentare',
            },
        },
    },
    components: { Illustration },
    name: 'CommentSection',
    mixins: [smoothReflow],
    data() {
        return {
            comment: {
                Text: '',
                PostID: '',
                PostType: 'Post',
                Images: [],
            },
            comments: [],
            imageFiles: [],
            imageURLs: [],

            visibleCommentsEndIndex: 0,

            // set true when creating new comment
            creatingNewComment: false,
            // set true when loading comments
            loading: true,
            commentkey: 0,
            commentsVisibleAtStart: 3, // constant: amount of comments visible initially when opening comment section
            commentsVisibleOnClick: 3, // constant: amount of comments that become visible when clicking "show x more comments"
            allCommentsLoaded: false,
            highlightedCommentShouldScrollIntoView: true,
        };
    },
    props: {
        profileDisplayed: Object,
        PostID: String,
        /**
         * @PostType can be post, projectUpdate, project, event,
         */
        PostType: String, //
        ShowComments: false,
        highlightCommentID: false,
    },
    computed: {
        notAllCommentsAreVisible() {
            return this.visibleCommentsEndIndex < this.comments.length - 1;
        },
        amountOfCommentsNotVisible() {
            return this.comments.length - this.visibleCommentsEndIndex - 1;
        },
        showMoreCommentsStrNr() {
            if (this.amountOfCommentsNotVisible > this.commentsVisibleOnClick) {
                return this.commentsVisibleOnClick;
            }
            return this.amountOfCommentsNotVisible;
        },
    },
    watch: {
        ShowComments(value) {
            if (this.ShowComments === false) {
                this.allCommentsLoaded = false;
            } else if (this.comments.length > this.commentsVisibleAtStart) {
                this.visibleCommentsEndIndex = this.commentsVisibleAtStart - 1;
            } else {
                this.visibleCommentsEndIndex = this.comments.length;
            }
        },

        imageURLs(newVal, oldVal) {
            if (newVal.length === this.imageFiles.length && this.creatingNewComment) {
                this.createComment();
            }
        },
    },
    created() {
        this.comment.PostID = this.PostID;
        this.comment.PostType = this.PostType;
        this.getComments();
    },
    mounted() {},
    methods: {
        showMoreComments() {
            this.visibleCommentsEndIndex += this.commentsVisibleOnClick;
        },
        commentLoaded() {
            if (!this.highlightedCommentShouldScrollIntoView) return;

            let allCommentsLoaded = true;
            this.$refs.comment.forEach((comment) => {
                allCommentsLoaded &= comment.loaded;
            });
            if (allCommentsLoaded) {
                this.allCommentsLoaded = true;
                this.$refs.comment.forEach((comment) => {
                    if (comment.highlight) {
                        setTimeout(() => {
                            comment.$el.scrollIntoView({ block: 'end', behavior: 'smooth' });
                            this.highlightedCommentShouldScrollIntoView = false;
                        }, 1000);
                    }
                });
            }
        },
        uploadImages() {
            this.imageFiles.forEach(function (entry, index) {
                this.$store
                    .dispatch('imageUpload', {
                        file: entry.File,
                        url: '/upload/image/comment',
                    })
                    .then((response) => {
                        this.imageURLs.push(response);
                    })
                    .catch((error) => {
                        this.creatingNewComment = false;
                        const errorMsg = {
                            Message: 'Could not upload one or more images, please try again or report to team',
                            Details: error.response.data,
                        };
                        if (
                            error.response &&
                            error.response.data &&
                            error.response.data.message === 'Request Entity Too Large'
                        ) {
                            errorMsg.Message = 'The file you tried to upload is too large. Max 10 MB.';
                        }
                        EventBus.$emit('ERROR', errorMsg);
                    });
            }, this);
        },
        publishComment() {
            this.creatingNewComment = true;
            if (this.imageFiles.length !== 0) {
                this.uploadImages();
            } else {
                this.createComment();
            }
        },
        createComment(event) {
            this.comment.Version = '1';
            this.comment.Images = this.imageURLs;
            if (this.comment.Text) {
                this.comment.Text = this.comment.Text.replace(/<p><\/p>/g, '<br>');
            }
            const commentData = JSON.parse(JSON.stringify(this.comment));

            // Prevent modal from closing
            if (this.comment.Text === '') return;

            if (event) event.preventDefault();

            this.$store
                .dispatch('createComment', commentData)
                .then((response) => {
                    this.getComments();
                    this.creatingNewComment = false;
                    this.imageFiles = [];
                    this.imageURLs = [];
                    this.$refs.editor.clear(true, true);

                    if (this.comments.length > this.commentsVisibleAtStart) {
                        this.visibleCommentsEndIndex = this.commentsVisibleAtStart - 1;
                    } else {
                        this.visibleCommentsEndIndex = this.comments.length;
                    }
                    this.$emit('show-comments');
                })
                .catch((error) => {
                    this.creatingNewComment = false;
                    console.log(error);
                    if (error.response.data && error.response.data.message) {
                        EventBus.$emit('ERROR', {
                            Message: error.response.data.message,
                            Details: error.response.data.details,
                        });
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: 'Could not create comment. Please try again later.',
                            Details: null,
                        });
                    }
                });
        },
        getComments() {
            const url = `/comment/list?postid=${this.PostID}`;
            this.$store
                .dispatch('listCommentsForPost', url)
                .then((response) => {
                    this.loading = false;
                    if (response.objects != null) {
                        this.comments = response.objects.map((item) => item.object);

                        // this.commentkey += 1;
                        this.$emit('comment-count-update', this.comments.length);
                        if (this.comments.length > this.commentsVisibleAtStart) {
                            this.visibleCommentsEndIndex = this.commentsVisibleAtStart - 1;
                        } else {
                            this.visibleCommentsEndIndex = this.comments.length;
                        }
                        this.$emit('show-comments');
                    } else {
                        this.comments = [];
                        this.$emit('comment-count-update', this.comments.length);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // todo show error
                });
        },
    },
};
</script>
