


















































































import { PropType } from 'vue';
import { Event } from './models.ts';

import CoCard from '../co-card/CoCard.vue';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoThumbnail from '../co-thumbnail/CoThumbnail.vue';
import CoText from '../../Atoms/co-text/CoText.vue';
import CoDate from '../co-date/CoDate.vue';

export default {
    name: 'CoCardEvent',
    components: {
        CoCard,
        CoHeadline,
        CoDate,
        CoThumbnail,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        event: {
            type: Object as PropType<Event>,
            required: true,
            default: () => ({} as Event),
        },
    },

    data() {
        return {
            attendedByUser: false,
        };
    },
    computed: {
        eventURL() {
            return `/event/${this.event.slug}`;
        },
        isMultiDay() {
            if (
                this.$t('datetime', { date: this.event.start, format: 'date' }) !==
                this.$t('datetime', { date: this.event.end, format: 'date' })
            ) {
                return true;
            }
            return false;
        },
    },
    watch: {
        page: {
            handler() {
                this.attendedByUser = this.event.attend;
            },
            deep: true,
        },
    },
    created() {
        this.attendedByUser = this.event.attend;
    },
    methods: {
        randomPercents(from = 0, to = 100) {
            const percents = Math.floor(Math.random() * (to - from + 1) + from);
            return `${percents}%`;
        },
        view() {
            this.$router.push(this.eventURL);
        },
        attend() {
            // emit event to parent
            this.$emit('attend', this.event);
        },
        unattend() {
            // emit event to parent
            this.$emit('unattend', this.event);
        },
    },
};
