<template>
    <textarea v-model="value" :placeholder="placeholder"></textarea>
</template>

<script>
export default {
    name: 'CoTextArea',
    props: {
        text: String,
        placeholder: String,
    },
    data() {
        return {
            value: this.text,
        };
    },
    watch: {
        value(newVal, oldVal) {
            this.$emit('changed', newVal);
        },
    },
};
</script>

<style lang="less" scoped></style>
