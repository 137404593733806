<template>
    <div v-if="og">
        <a :href="this.verifyedURL" target="_blank">
            <b-icon icon="paperclip"></b-icon>
            {{ title }}</a
        >
    </div>
</template>

<script>
export default {
    name: 'LinkPreviewMini',
    data() {
        return {
            og: null,
            host: '',
            loading: false,
        };
    },
    props: {
        url: {
            type: String,
            default: '',
        },
    },
    computed: {
        title() {
            const l = document.createElement('a');
            l.href = this.verifyedURL;
            const host = l.hostname;
            return this.og ? this.og['title'] : host;
        },

        verifyedURL() {
            if (this.url == null || this.url.length === 0) {
                return '';
            }
            // if url does not start with http or https, add https
            if (!this.url.match(/^(http|https):\/\//)) {
                return `https://${this.url}`;
            }
            return this.url;
        },
    },
    mounted() {
        this.getOG();
    },
    methods: {
        openLink() {
            window.open(this.verifyedURL, '_blank').focus();
        },
        getOG() {
            this.loading = true;

            this.$store
                .dispatch('getOpenGraph', this.verifyedURL)
                .then((response) => {
                    if (response != null) {
                        this.og = response;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
    },
};
</script>
