<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <div>
        <div class="px-sm-4">
            <b-overlay :show="loading">
                <div class="my-3 bg-white round-unify p-3">
                    <b-form @submit.stop.prevent="createdCategory">
                        <b-form-group
                            id="input-group-name"
                            label="Name"
                            label-for="input-name"
                            label-class="label-align"
                            description="Name will be displayed to users on booking page."
                        >
                            <b-form-input
                                id="input-name"
                                v-model="form['Name']"
                                type="text"
                                required
                                placeholder="Enter category name"
                                class="rounded-pill"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-description"
                            label="Description (Optional)"
                            label-for="input-description"
                            label-class="label-align"
                            description="Short description"
                        >
                            <b-form-textarea
                                id="input-description"
                                placeholder="Text (Optional)"
                                rows="3"
                                style="border-radius: 16px"
                                v-model="form['Description']"
                            ></b-form-textarea>
                        </b-form-group>

                        <!-- Resources -->
                        <b-form-group
                            id="input-group-resources"
                            label="Resources"
                            label-for="input-resources"
                            label-class="label-align"
                            description="Here are resources of this category."
                        >
                            <b-button variant="primary" v-b-modal.modal-add-resource>Add Resource</b-button>
                            <b-table
                                striped
                                hover
                                :items="selectedResources"
                                :fields="fields"
                                sort-icon-left
                                stacked="md"
                                class="table table-borderless table-responsive w-100 d-md-table"
                            >
                                <template v-slot:cell(Actions)="row">
                                    <b-button
                                        size="sm"
                                        variant="danger"
                                        class="mr-2"
                                        @click="removeResourceFromCategory(row.item.ID)"
                                        >Remove
                                    </b-button>
                                </template>
                            </b-table>
                        </b-form-group>

                        <div class="w-100 d-flex justify-content-between">
                            <b-button
                                variant="outline-danger"
                                type="button"
                                @click="hasHistory() ? $router.go(-1) : $router.push('/admin/booking/categories')"
                                >Cancel
                            </b-button>
                            <b-button variant="primary" type="submit">Create</b-button>
                        </div>
                    </b-form>
                </div>
            </b-overlay>
        </div>

        <!-- add resource -->
        <b-modal id="modal-add-resource" ref="modal" title="Add resource " centered hide-footer>
            <b-table
                striped
                hover
                :items="allResources"
                :fields="fields"
                sort-icon-left
                stacked="md"
                class="table table-borderless table-responsive w-100 d-md-table"
            >
                <template v-slot:cell(Actions)="row">
                    <b-button
                        size="sm"
                        variant="danger"
                        class="mr-2"
                        v-if="containsObject(row.item)"
                        @click="removeResourceFromCategory(row.item.ID)"
                        >Remove
                    </b-button>
                    <b-button size="sm" variant="primary" class="mr-2" v-else @click="selectedResources.push(row.item)"
                        >Add
                    </b-button>
                </template>
            </b-table>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';

export default {
    name: 'CreateBookinCategory',

    data() {
        return {
            allResources: [],
            selectedResources: [],
            fields: [
                {
                    key: 'Name',
                    label: 'Name',
                    sortable: true,
                },
                {
                    key: 'Actions',
                    label: 'Actions',
                },
            ],

            loadingResources: false,
            loading: false,

            form: {
                Name: '',
            },
        };
    },
    created() {
        this.listResources();
    },

    watch: {
        tag: 'loadAutocompleteItems',
    },
    methods: {
        hasHistory() {
            return window.history.length > 2;
        },
        containsObject(obj) {
            let i;
            for (i = 0; i < this.selectedResources.length; i++) {
                if (this.selectedResources[i].ID === obj.ID) {
                    return true;
                }
            }

            return false;
        },

        removeResourceFromCategory(itemID) {
            // remove from selectedResources by item id
            this.selectedResources = this.selectedResources.filter((el) => el.ID !== itemID);
        },

        /**
         * listResources - return list of resources for space
         */
        listResources() {
            this.loadingResources = true;
            axios({
                method: 'GET',
                url: '/admin/booking/resource/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data && response.data.Resources.length != 0) {
                        this.allResources = response.data.Resources;
                    }
                    this.loadingResources = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loadingResources = true;
                });
        },

        createdCategory() {
            this.loading = true;
            const resources = [];
            this.selectedResources.forEach((entry, index) => {
                resources.push(entry.ID);
            });
            this.form.Resources = resources;
            const data = JSON.stringify(this.form);
            axios({
                method: 'POST',
                url: '/admin/booking/category',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    const message = {
                        Message: 'Booking category created',
                        Details: '',
                    };
                    EventBus.$emit('INFO', message);

                    this.$router.push('/admin/booking/categories');
                })
                .catch((error) => {
                    console.log(error);
                    // todo error processing
                    this.loading = false;
                    const message = {
                        Message: 'Could not create booking category',
                        Details: '',
                    };
                    EventBus.$emit('Error', message);
                });
        },
    },
};
</script>
