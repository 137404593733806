<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>
<style lang="less" scoped>
/* Sidebar sizes when expanded and expanded */
.sidebar-expanded {
    width: 250px;
    -webkit-transition: width 0.5s ease-in-out;
    -moz-transition: width 0.5s ease-in-out;
    -ms-transition: width 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out;
}

.sidebar-collapsed {
    width: 68px;
    z-index: 10000;
    // transition: width 1s;
    -webkit-transition: width 0.5s ease-in-out;
    -moz-transition: width 0.5s ease-in-out;
    -ms-transition: width 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out;

    -webkit-transition: display 0.5s ease-in-out;
    -moz-transition: display 0.5s ease-in-out;
    -ms-transition: display 0.5s ease-in-out;
    -o-transition: display 0.5s ease-in-out;
    transition: display 0.5s ease-in-out;

    // transition: display 0.3s;

    // &:hover {
    //   width: 230px;
    //   .admin-submenu {
    //     display: block;
    //   }

    //   .menu-option-title {
    //     display: block;
    //   }
    // }
    .admin-submenu {
        display: none;
        -webkit-transition: display 0.5s ease-in-out;
        -moz-transition: display 0.5s ease-in-out;
        -ms-transition: display 0.5s ease-in-out;
        -o-transition: display 0.5s ease-in-out;
        transition: display 0.5s ease-in-out;
    }

    .menu-option-title {
        overflow: hidden;
        display: none;
        -webkit-transition: display 0.5s ease-in-out;
        -moz-transition: display 0.5s ease-in-out;
        -ms-transition: display 0.5s ease-in-out;
        -o-transition: display 0.5s ease-in-out;
        transition: display 0.5s ease-in-out;
    }
}

.submenu {
    position: absolute;
    width: 241px;
    height: 100vh;
    left: 69px;
    top: 0px;
    z-index: 1000;

    .space {
        margin-top: 204px;
    }

    .community {
        margin-top: 244px;
    }

    .booking {
        margin-top: 286px;
    }

    .event {
        margin-top: 326px;
    }
    .pages {
        margin-top: 365px;
    }
}

.menu-list {
    margin-top: 93px;
    height: calc(100vh - 161px);
}

@media screen and (max-width: 480px), (max-height: 768px) {
    .menu-list {
        margin-top: 10px;
        height: calc(100vh - 161px);
    }

    .menu-footer {
        margin-bottom: 24px;
    }
}

.menu-tooltip {
    z-index: 100000;
    // background-color: white !important;
}

.menu-footer {
    position: relative;

    margin-top: auto;
    margin-bottom: 16px;
}
</style>
<template>
    <div class="admin-bg">
        <!-- side menu for mobile -->
        <b-sidebar class="d-md-none" id="sidebar-1" shadow no-header backdrop>
            <div class="w-100 h-100 menu p-0" style="">
                <div class="">
                    <b-navbar type="light" class="px-4 py-3">
                        <b-navbar-brand href="/admin" class="m-0">
                            <img src="@/assets/coapp_logo.png" alt="coapp logo" height="32px" />
                        </b-navbar-brand>
                    </b-navbar>
                </div>

                <div class="menu-list d-flex flex-column">
                    <div>
                        <div
                            id="dashboard_link_mb"
                            class="admin-menu-item d-flex justify-content-start"
                            v-b-toggle.collapse-1
                        >
                            <b-icon icon="graph-up"></b-icon>
                            <div class="ml-3">Dashboard</div>
                        </div>
                        <b-collapse
                            accordion="admin-menu"
                            id="collapse-1"
                            class="admin-submenu"
                            :visible="this.$router.currentRoute.path.startsWith('/admin/dashboard')"
                        >
                            <router-link
                                id="administrators_link_mb"
                                class="admin-menu-subitem"
                                to="/admin/dashboard/general"
                            >
                                General
                            </router-link>
                            <router-link
                                v-if="this.$unleash.isEnabled('frontend.checkin-statistics.admin')"
                                id="administrators_link_mb"
                                class="admin-menu-subitem"
                                to="/admin/dashboard/check-ins"
                            >
                                Check-ins
                            </router-link>
                        </b-collapse>
                        <div
                            id="space_link_mb"
                            class="admin-menu-item d-flex align-items-center text-black"
                            v-b-toggle.collapse-2
                            variant=""
                        >
                            <b-icon icon="house"></b-icon>
                            <div class="ml-3">Space</div>
                        </div>
                        <b-collapse
                            accordion="admin-menu"
                            id="collapse-2"
                            class="admin-submenu"
                            :visible="this.$router.currentRoute.path.startsWith('/admin/space')"
                        >
                            <router-link
                                id="administrators_link_mb"
                                class="admin-menu-subitem"
                                to="/admin/space/administrators"
                            >
                                Administrators
                            </router-link>
                            <router-link id="locations_link_mb" class="admin-menu-subitem" to="/admin/space/locations"
                                >Locations
                            </router-link>
                            <router-link
                                id="wifi_access_link_mb"
                                class="admin-menu-subitem"
                                to="/admin/space/wifi-access"
                                v-if="this.$store.state.space['RadiusServerOn']"
                                >WiFi Access
                            </router-link>
                            <router-link
                                id="access_link"
                                class="admin-menu-subitem"
                                to="/admin/access"
                                v-if="this.$unleash.isEnabled('frontend.access-management.admin')"
                                >Access
                            </router-link>
                        </b-collapse>

                        <div
                            id="community_link_mb"
                            class="admin-menu-item d-flex align-items-center text-black"
                            v-b-toggle.collapse-3
                            variant=""
                        >
                            <b-icon icon="person"></b-icon>
                            <div class="ml-3">Community</div>
                        </div>
                        <b-collapse accordion="admin-menu" id="collapse-3" class="admin-submenu">
                            <router-link
                                id="members_link_mb"
                                class="admin-menu-subitem"
                                to="/admin/community/member/list"
                                >Members
                            </router-link>
                            <router-link
                                id="invitations_link_mb"
                                class="admin-menu-subitem"
                                to="/admin/community/member/invitations"
                            >
                                Invited Users
                            </router-link>
                            <router-link
                                id="member_upgrades_link_mb"
                                class="admin-menu-subitem"
                                to="/admin/community/member/upgrades"
                            >
                                Membership Upgrades
                            </router-link>
                        </b-collapse>

                        <div
                            id="bookings_link_id"
                            class="admin-menu-item d-flex align-items-center text-black"
                            v-if="this.$store.state.space['BookingOn']"
                            v-b-toggle.collapse-4
                        >
                            <CoIcon name="calendar2-plus" no-padding />
                            <div class="ml-3">Bookings</div>
                        </div>
                        <b-collapse
                            accordion="admin-menu"
                            id="collapse-4"
                            class="admin-submenu"
                            :visible="this.$router.currentRoute.path.startsWith('/admin/booking')"
                        >
                            <router-link id="calendar_link_mb" class="admin-menu-subitem" to="/admin/booking/calendar"
                                >Calendar
                            </router-link>
                            <router-link id="resources_link_mb" class="admin-menu-subitem" to="/admin/booking/resource"
                                >Resources
                            </router-link>
                            <router-link
                                id="resource_categories_link_mb"
                                class="admin-menu-subitem"
                                to="/admin/booking/categories"
                            >
                                Resource Categories
                            </router-link>
                            <router-link
                                id="resource_categories_link_mb"
                                class="admin-menu-subitem"
                                to="/admin/booking/statistics"
                            >
                                Statistics
                            </router-link>
                        </b-collapse>

                        <div
                            id="events_collapse_link_mb"
                            class="admin-menu-item d-flex align-items-center text-black"
                            v-b-toggle.collapse-5
                        >
                            <CoIcon name="ticket-perforated" no-padding />
                            <div class="ml-3">Events</div>
                        </div>
                        <b-collapse
                            accordion="admin-menu"
                            id="collapse-5"
                            class="admin-submenu"
                            :visible="this.$router.currentRoute.path.startsWith('/admin/event')"
                        >
                            <router-link id="evens_link_mb" class="admin-menu-subitem" to="/admin/event/list"
                                >Events
                            </router-link>
                            <router-link id="event_types_link_mb" class="admin-menu-subitem" to="/admin/event/types"
                                >Event Types
                            </router-link>
                            <router-link
                                id="event_categories_link_mb"
                                class="admin-menu-subitem"
                                to="/admin/event/categories"
                                >Event Categories
                            </router-link>
                        </b-collapse>
                        <div
                            id="pages_collapse_link_mb"
                            class="admin-menu-item d-flex align-items-center text-black is-new"
                            v-b-toggle.collapse-6
                            :visible="this.$router.currentRoute.path.startsWith('/admin/pages')"
                        >
                            <CoIcon name="collection" no-padding />
                            <div class="ml-3">Channels & Pages</div>
                        </div>
                        <b-collapse
                            accordion="admin-menu"
                            id="collapse-6"
                            class="admin-submenu"
                            :visible="this.$router.currentRoute.path.startsWith('/admin/pages')"
                        >
                            <router-link id="pages_link_mb" class="admin-menu-subitem" to="/admin/pages/list"
                                >Pages
                            </router-link>
                            <router-link id="channels_link_mb" class="admin-menu-subitem" to="/admin/pages/channels"
                                >Channels
                            </router-link>
                        </b-collapse>
                        <router-link
                            id="monetization_link_mb"
                            class="admin-menu-item d-flex justify-content-start align-items-center"
                            to="/admin/monetization"
                        >
                            <b-icon icon="credit-card"></b-icon>
                            <div class="ml-3">Monetization</div>
                        </router-link>
                        <router-link
                            v-if="this.$unleash.isEnabled('frontend.admin.circles-page')"
                            id="circles_link_mb"
                            class="admin-menu-item d-flex justify-content-start align-items-center"
                            to="/admin/circles"
                        >
                            <b-icon icon="share"></b-icon>
                            <div class="ml-3">Circles</div>
                        </router-link>
                    </div>

                    <div class="menu-footer">
                        <b-link
                            id="feature_request_link_mb"
                            href="https://roadmap.coapp.io/feature-requests"
                            target="_blank"
                            class="admin-menu-item d-flex align-items-center"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-send"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"
                                />
                            </svg>
                            <div class="ml-3">Send Feature Request</div>
                        </b-link>

                        <router-link
                            id="settings_link_mb"
                            to="/admin/settings"
                            class="admin-menu-item d-flex align-items-center text-black w-100 mb-5"
                        >
                            <b-icon icon="gear"></b-icon>
                            <div class="ml-3">Settings</div>
                        </router-link>
                    </div>
                </div>
            </div>
        </b-sidebar>
        <!-- Side menu for mobile end -->

        <!-- side menu for desktop -->
        <div class="container-fluid">
            <div class="row maxh-100" ref="menu-row">
                <div class="d-none d-md-block menu p-0 sidebar-collapsed">
                    <b-navbar type="light" class="d-flex justify-content-center" style="height: 68px">
                        <b-navbar-brand href="/admin" class="p-0 m-0">
                            <img class="p-0" src="@/assets/coapp_mini_purple.png" alt="coapp logo" height="36px" />
                        </b-navbar-brand>
                    </b-navbar>

                    <div class="menu-list d-flex flex-column">
                        <div class="">
                            <!-- dashboard -->
                            <div
                                id="dashboard_link"
                                class="admin-menu-item d-flex justify-content-center"
                                @click="showMenu('statistics')"
                                :class="{ active: menu.statistics }"
                            >
                                <b-icon icon="graph-up"></b-icon>
                            </div>
                            <b-tooltip
                                placement="right"
                                custom-class="menu-tooltip"
                                target="dashboard_link"
                                title="Statistics"
                                triggers="hover"
                                ref="dashboard_link_tooltip"
                                no-fade
                            >
                                Dashboard
                            </b-tooltip>

                            <!-- space -->
                            <div
                                id="space_link"
                                class="admin-menu-item d-flex justify-content-center"
                                @click="showMenu('space')"
                                :class="{ active: menu.space }"
                            >
                                <b-icon icon="house"></b-icon>
                            </div>
                            <b-tooltip
                                placement="right"
                                custom-class="menu-tooltip"
                                target="space_link"
                                title="Space"
                                triggers="hover"
                                ref="space_link_tooltip"
                                no-fade
                            >
                                Space
                            </b-tooltip>

                            <!-- community -->
                            <div
                                id="comminity_link"
                                class="admin-menu-item d-flex justify-content-center"
                                @click="showMenu('community')"
                                :class="{ active: menu.community }"
                            >
                                <b-icon icon="person"></b-icon>
                            </div>
                            <b-tooltip
                                placement="right"
                                custom-class="menu-tooltip"
                                target="comminity_link"
                                title="Community"
                                triggers="hover"
                                ref="community_link_tooltip"
                                no-fade
                            >
                                Community
                            </b-tooltip>

                            <!-- booking -->
                            <div
                                id="bookings_link"
                                class="admin-menu-item d-flex justify-content-center"
                                v-if="this.$store.state.space['BookingOn']"
                                @click="showMenu('booking')"
                                :class="{ active: menu.booking }"
                            >
                                <CoIcon name="calendar2-plus" no-padding />
                            </div>
                            <b-tooltip
                                placement="right"
                                custom-class="menu-tooltip"
                                target="bookings_link"
                                title="Bookings"
                                triggers="hover"
                                ref="booking_link_tooltip"
                                no-fade
                            >
                                Bookings
                            </b-tooltip>

                            <!-- event -->
                            <div
                                id="events_link_collapse"
                                class="admin-menu-item d-flex justify-content-center"
                                @click="showMenu('event')"
                                :class="{ active: menu.event }"
                            >
                                <CoIcon name="ticket-perforated" no-padding />
                            </div>
                            <b-tooltip
                                placement="right"
                                custom-class="menu-tooltip"
                                target="events_link_collapse"
                                title="Events"
                                triggers="hover"
                                ref="event_link_tooltip"
                                no-fade
                            >
                                Events
                            </b-tooltip>
                            <!-- channels & pages -->
                            <div
                                id="pages_link_collapse"
                                class="admin-menu-item d-flex justify-content-center is-new"
                                @click="showMenu('pages')"
                                :class="{ active: menu.pages }"
                            >
                                <CoIcon name="collection" no-padding />
                            </div>
                            <b-tooltip
                                placement="right"
                                custom-class="menu-tooltip"
                                target="pages_link_collapse"
                                title="Channels & Pages"
                                triggers="hover"
                                ref="pages_link_tooltip"
                                no-fade
                            >
                                Channels & Pages
                            </b-tooltip>

                            <router-link
                                id="monetization_link"
                                class="admin-menu-item d-flex justify-content-center"
                                to="/admin/monetization"
                            >
                                <b-icon icon="credit-card"></b-icon>
                                <div class="ml-3 menu-option-title">Monetization</div>
                            </router-link>
                            <b-tooltip
                                placement="right"
                                title="Monetization"
                                target="monetization_link"
                                triggers="hover"
                                custom-class="menu-tooltip"
                                ref="monetization_link_tooltip"
                                no-fade
                            >
                                Monetization
                            </b-tooltip>
                            <router-link
                                v-if="this.$unleash.isEnabled('frontend.admin.circles-page')"
                                id="circles_link"
                                class="admin-menu-item d-flex justify-content-center"
                                to="/admin/circles"
                            >
                                <b-icon icon="share"></b-icon>
                                <div class="ml-3 menu-option-title">Circles</div>
                            </router-link>
                            <b-tooltip
                                placement="right"
                                title="Circles"
                                target="circles_link"
                                triggers="hover"
                                custom-class="menu-tooltip"
                                ref="monetization_link_tooltip"
                                no-fade
                            >
                                Circles
                            </b-tooltip>
                        </div>
                        <div class="menu-footer">
                            <b-link
                                id="feature_request_link"
                                href="https://roadmap.coapp.io/feature-requests"
                                target="_blank"
                                class="admin-menu-item d-flex justify-content-center"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-send"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"
                                    />
                                </svg>
                            </b-link>
                            <b-tooltip
                                placement="right"
                                title="Send feature request"
                                target="feature_request_link"
                                triggers="hover focus"
                                custom-class="menu-tooltip"
                            >
                                Send feature request
                            </b-tooltip>

                            <router-link
                                id="settings_link"
                                to="/admin/settings"
                                class="admin-menu-item d-flex justify-content-center"
                            >
                                <b-icon icon="gear"></b-icon>
                            </router-link>
                            <b-tooltip
                                placement="right"
                                title="Settings"
                                target="settings_link"
                                triggers="hover focus"
                                custom-class="menu-tooltip"
                            >
                                Settings
                            </b-tooltip>
                        </div>
                    </div>
                </div>

                <!-- Statistics sub menu -->
                <b-sidebar
                    id="statistics-submenu-sidebar"
                    ref="statistics-submenu-sidebar"
                    width="241px"
                    v-model="menu.statistics"
                    :no-slide="anyMenuOpen"
                    @hidden="menuHidden('statistics')"
                >
                    <div class="bg-secondary submenu">
                        <div class="d-flex h-100">
                            <div class="space">
                                <div class="admin-submenu-title">
                                    <H2>Statistics</H2>
                                </div>
                                <router-link
                                    id="administrators_link"
                                    class="admin-menu-subitem"
                                    to="/admin/dashboard/general"
                                >
                                    General
                                </router-link>
                                <router-link
                                    id="project_types_link"
                                    class="admin-menu-subitem"
                                    to="/admin/dashboard/check-ins"
                                    v-if="this.$unleash.isEnabled('frontend.checkin-statistics.admin')"
                                >
                                    Check-ins
                                </router-link>
                            </div>
                        </div>
                    </div>
                </b-sidebar>
                <!-- Space sub menu -->
                <b-sidebar
                    id="space-submenu-sidebar"
                    ref="space-submenu-sidebar"
                    width="241px"
                    v-model="menu.space"
                    :no-slide="anyMenuOpen"
                    @hidden="menuHidden('space')"
                >
                    <div class="bg-secondary submenu">
                        <div class="d-flex h-100">
                            <div class="space">
                                <div class="admin-submenu-title">
                                    <H2>Space</H2>
                                </div>
                                <!-- <router-link class="admin-menu-subitem" to="/admin/settings">Settings</router-link> -->
                                <router-link
                                    id="administrators_link"
                                    class="admin-menu-subitem"
                                    to="/admin/space/administrators"
                                >
                                    Administrators
                                </router-link>
                                <router-link id="locations_link" class="admin-menu-subitem" to="/admin/space/locations"
                                    >Locations
                                </router-link>
                                <router-link
                                    id="wifi_access_link"
                                    class="admin-menu-subitem"
                                    to="/admin/space/wifi-access"
                                    v-if="this.$store.state.space['RadiusServerOn']"
                                    >WiFi Access
                                </router-link>
                                <router-link
                                    id="access_link"
                                    class="admin-menu-subitem"
                                    to="/admin/access"
                                    v-if="this.$unleash.isEnabled('frontend.access-management.admin')"
                                    >Access
                                </router-link>
                            </div>
                        </div>
                    </div>
                </b-sidebar>

                <!-- Community sub menu -->
                <b-sidebar
                    id="community-submenu-sidebar"
                    width="241px"
                    v-model="menu.community"
                    :no-slide="anyMenuOpen"
                    @hidden="menuHidden('community')"
                >
                    <div class="bg-secondary submenu">
                        <div class="d-flex h-100">
                            <div class="community">
                                <div class="admin-submenu-title">
                                    <H2>Community</H2>
                                </div>
                                <router-link
                                    id="members_link"
                                    class="admin-menu-subitem"
                                    to="/admin/community/member/list"
                                    >Members
                                </router-link>
                                <router-link
                                    id="invited_users_link"
                                    class="admin-menu-subitem"
                                    to="/admin/community/member/invitations"
                                    >Invited Users
                                </router-link>
                                <router-link
                                    id="member_upgrades_link"
                                    class="admin-menu-subitem"
                                    to="/admin/community/member/upgrades"
                                >
                                    Membership Upgrades
                                </router-link>
                            </div>
                        </div>
                    </div>
                </b-sidebar>

                <!-- Booking sub menu -->
                <b-sidebar
                    id="booking-submenu-sidebar"
                    width="241px"
                    v-model="menu.booking"
                    :no-slide="anyMenuOpen"
                    @hidden="menuHidden('booking')"
                >
                    <div class="bg-secondary submenu">
                        <div class="d-flex h-100">
                            <div class="booking">
                                <div class="admin-submenu-title">
                                    <H2>Booking</H2>
                                </div>
                                <router-link id="calendar_link" class="admin-menu-subitem" to="/admin/booking/calendar"
                                    >Calendar
                                </router-link>
                                <router-link id="resources_link" class="admin-menu-subitem" to="/admin/booking/resource"
                                    >Resources
                                </router-link>
                                <router-link
                                    id="resource_categories_link"
                                    class="admin-menu-subitem"
                                    to="/admin/booking/categories"
                                >
                                    Resource Categories
                                </router-link>
                                <router-link
                                    id="resource_categories_link_mb"
                                    class="admin-menu-subitem"
                                    to="/admin/booking/statistics"
                                >
                                    Statistics
                                </router-link>
                            </div>
                        </div>
                    </div>
                </b-sidebar>

                <!-- Event submenu -->
                <b-sidebar
                    id="event-submenu-sidebar"
                    width="241px"
                    v-model="menu.event"
                    :no-slide="anyMenuOpen"
                    @hidden="menuHidden('event')"
                >
                    <div class="bg-secondary submenu">
                        <div class="d-flex h-100">
                            <div class="event">
                                <div class="admin-submenu-title">
                                    <H2>Event</H2>
                                </div>
                                <router-link id="event_link" class="admin-menu-subitem" to="/admin/event/list"
                                    >Events
                                </router-link>
                                <router-link id="event_types_link" class="admin-menu-subitem" to="/admin/event/types"
                                    >Event Types
                                </router-link>
                                <router-link
                                    id="event_categories_link"
                                    class="admin-menu-subitem"
                                    to="/admin/event/categories"
                                    >Event Categories
                                </router-link>
                            </div>
                        </div>
                    </div>
                </b-sidebar>

                <!-- Channels & Pages submenu -->
                <b-sidebar id="channels-submenu-sidebar" width="241px" v-model="menu.pages" :no-slide="anyMenuOpen">
                    <div class="bg-secondary submenu">
                        <div class="d-flex h-100">
                            <div class="pages">
                                <div class="admin-submenu-title">
                                    <H2>Channels & Pages</H2>
                                </div>
                                <router-link id="pages_link" class="admin-menu-subitem" to="/admin/pages/list"
                                    >Pages
                                </router-link>
                                <router-link
                                    id="pages_channels_link"
                                    class="admin-menu-subitem"
                                    to="/admin/pages/channels"
                                    >Channels
                                </router-link>
                            </div>
                        </div>
                    </div>
                </b-sidebar>

                <div class="col p-0 right-side">
                    <CoNavBar>
                        <div class="d-flex align-items-center">
                            <!-- show only on mobile -->
                            <div class="d-block d-md-none">
                                <CoRoundButton icon="list" variant="secondary" v-b-toggle.sidebar-1></CoRoundButton>
                            </div>

                            <!-- hide on mobile view, show on bigger screen -->
                            <div class="container-for-back-btn d-none d-md-flex justify-content-end">
                                <CoButton variant="text" label="Back" iconleft="arrow-left" @click="back()"></CoButton>
                            </div>
                            <!-- hide on mobile view, show on bigger screen -->
                            <div class="d-none d-md-block overflow-hidden">
                                <CoBreadcrumbs :items="this.$store.state.breadcrumbs" />
                            </div>
                            <div class="ml-auto d-flex flex-shrink-0">
                                <coPill
                                    v-if="spacePlanName && this.$unleash.isEnabled('frontend.showplaninheader.admin')"
                                    class="mx-2"
                                    :outline="true"
                                >
                                    {{ spacePlanName }} <span style="color: var(--c-midgrey)">|</span> 
                                    <router-link class="d-inline" to="/admin/account" style="color: var(--c-purple)"
                                        >Upgrade
                                    </router-link>
                                </coPill>
                                <CoButton variant="subtle" label="Exit" @click="goToUserInterace()"></CoButton>
                            </div>
                        </div>
                    </CoNavBar>

                    <div class="px-3 px-sm-5 pt-4 admin-bg" style="padding-bottom: 2.25rem">
                        <router-view />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
@import '../../../src/assets/less/variables.less';

.admin-bg {
    background-color: @co-lightgrey;
}

.container-for-back-btn {
    width: 116px;
}

.admin-menu-item[id*='circles'] {
    background: linear-gradient(270deg, #ff7bac 0%, #fffbac 100%) !important;

    &:hover {
        background: @purple !important;
    }
}
</style>

<script>
import Vue from 'vue'; // initialize
import axios from 'axios';
import VueIntercom from 'vue-intercom';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoNavBar from '@/components/Molecules/co-navbar/CoNavBar.vue';
import CoBreadcrumbs from '@/components/Molecules/co-breadcrumbs/CoBreadcrumbs.vue';
import setCustomColors from '../../customColors';
import CoPill from '../../components/Atoms/co-pill/CoPill.vue';
import CoLink from '../../components/Atoms/co-link/CoLink.vue';

Vue.use(VueIntercom, { appId: 'kttwojyk' });
export default {
    name: 'AdminBase',
    components: {
        CoNavBar,
        CoButton,
        CoBreadcrumbs,
        CoPill,
        CoLink,
    },
    data() {
        return {
            collapsed: false,
            adminMenuOpen: true,
            selectedMenu: null,

            menu: {
                statistics: false,
                space: false,
                community: false,
                booking: false,
                event: false,
                monetization: false,
            },
            skipNoSlider: false,
            spacePlanName: null,
            spaceApplicationFee: null,
        };
    },
    computed: {
        isVisible() {
            return this.$intercom.visible;
        },
        burgerButtonIcon() {
            if (this.adminMenuOpen) {
                return 'x';
            }
            return 'list';
        },
        // any menu is open
        anyMenuOpen() {
            return this.skipNoSlider;
            // return Object.values(this.menu).some((value) => value);
        },
    },
    watch: {
        // set the path parameter for conditional styling
        $route: {
            handler(to, from) {
                if (window.innerWidth < 768) this.adminMenuOpen = false;

                setCustomColors([
                    {
                        name: 'purple',
                        value: '#5100ff',
                    },
                    {
                        name: 'red',
                        value: '#fd6969',
                    },
                    {
                        name: 'text',
                        value: '#212529',
                    },
                ]);
            },
            deep: true,
            immediate: true,
        },
        '$route.meta.breadcrumbs': {
            handler(value) {
                this.$store.commit('SET_BREADCRUMBS', value);
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        setCustomColors([
            {
                name: 'purple',
                value: '#5100ff',
            },
            {
                name: 'red',
                value: '#fd6969',
            },
            {
                name: 'text',
                value: '#212529',
            },
        ]);
        this.getPaymentState();
        this.getAdminSpaceSettings();
    },
    mounted() {
        if (this.$store && this.$store.state) {
            if (this.$store.state.space_configuration_admin) {
                this.spacePlanName = this.$store.state.space_configuration_admin.PlanName
                    ? this.$store.state.space_configuration_admin.PlanName.split(' ')[0]
                    : 'Legacy';
                this.spaceApplicationFee = this.$store.state.space_configuration_admin.ApplicationFee;
            }

            if (this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin')) {
                this.$intercom.boot({
                    user_id: this.$store.state.me.ID,
                    name: this.$store.state.me.Profile.Name,
                    email: this.$store.state.me.Email,
                    spaceID: this.$store.state.space.ID,
                    space: this.$store.state.space.Name,
                });
                this.$intercom.hide();
            }
        }

        this.breadcrumbs = this.$route.meta.breadcrumbs;

        this.$nextTick(function () {
            this.search = this.$route.query.term;
        });
    },
    beforeDestroy() {
        this.$intercom.shutdown();
    },
    methods: {
        // function check if there is a previous page and go back
        isTherePreviousPage() {
            if (this.$route.path === '/admin/dashboard/general') {
                return false;
            }
            return true;
        },

        back() {
            if (this.isTherePreviousPage()) {
                this.$router.go(-1);
            } else {
                this.$router.push('/');
            }
        },
        goToUserInterace() {
            this.$router.push('/');
        },
        menuHidden(menu) {
            this.menu[menu] = false;
            this.skipNoSlider = false;
        },

        showMenu(menu) {
            if (this.menu[menu]) {
                this.skipNoSlider = false;
                this.menu[menu] = false;
            } else {
                this.menu = {
                    statistics: false,
                    space: false,
                    community: false,
                    booking: false,
                    event: false,
                    pages: false,
                    monetization: false,
                };
                this.menu[menu] = true;
            }

            if (this.menu[menu]) {
                const submenu = document.getElementById(`${menu}-submenu-sidebar`);
                submenu.addEventListener('mouseleave', () => {
                    this.mouseLeave();
                });
                // get tooltip by ref for menu
                const tooltip = this.$refs[`${menu}_link_tooltip`];
                tooltip.$emit('close');

                setTimeout(() => {
                    this.skipNoSlider = true;
                }, 100);
            } else {
                const submenu = document.getElementById(`${menu}-submenu-sidebar`);
                submenu.removeEventListener('mouseleave', () => {});
            }
        },
        mouseLeave() {
            this.skipNoSlider = false;
            setTimeout(() => {
                this.menu = {
                    statistics: false,
                    space: false,
                    community: false,
                    booking: false,
                    event: false,
                    monetization: false,
                };
            }, 200);
        },
        // getPaymentState - returns payment service readyness state
        getPaymentState() {
            axios({
                method: 'GET',
                url: '/user/account/billing/payment-state',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.$store.commit('SET_PAYMENT_READY_STATE', response.data.ChargesEnabled);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getAdminSpaceSettings() {
            axios({
                method: 'GET',
                url: '/admin/space/settings',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.$store.commit('SET_SPACE_OBJECT_FOR_ADMIN', response.data);
                    }
                })
                .catch((error) => {});
        },
    },
};
</script>
