






import NotificationsPreferences from '../../../Templates/User/Account/NotificationsPreferences/NotificationsPreferences.vue';

export default {
    name: 'CoNotificationPreferences',
    components: {
        NotificationsPreferences,
    },
    data() {
        return {
            admin: this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin'),
        };
    },
};
