// translation for emoji search
// translates the keys from emojilist.js
// GERMAN

export default {
    'Frequently used': 'Häufig verwendet',
    People: 'Menschen',
    Objects: 'Objekte',
    Places: 'Orte',
    Symbols: 'Symbole',
    // Frequently used
    thumbs_up: 'daumen_hoch',
    '-1': '-1',
    smile: 'lächeln',
    stuck_out_tongue_winking_eye: 'rausgestreckte_zunge_mit_blitz',
    sob: 'weinen',
    heart_eyes: 'herzaugen',
    // people section
    smile: 'lächeln',
    smiley: 'lächeln',
    grinning: 'grinsen',
    blush: 'erröten',
    wink: 'zwinkern',
    heart_eyes: 'herzaugen',
    kissing_heart: 'küssendes_herz',
    kissing_closed_eyes: 'küssend_mit_geschlossenen_augen',
    kissing: 'küssen',
    kissing_smiling_eyes: 'küssend_mit_lächelnden_augen',
    stuck_out_tongue_winking_eye: 'rausgestreckte_zunge_mit_blitz',
    stuck_out_tongue_closed_eyes: 'rausgestreckte_zunge_mit_geschlossenen_augen',
    stuck_out_tongue: 'rausgestreckte_zunge',
    flushed: 'verlegen',
    grin: 'grinsen',
    pensive: 'nachdenklich',
    relieved: 'erleichtert',
    unamused: 'gelangweilt',
    disappointed: 'enttäuscht',
    persevere: 'durchhalten',
    cry: 'weinen',
    joy: 'freude',
    sob: 'weinen',
    sleepy: 'schläfrig',
    disappointed_relieved: 'enttäuscht_erleichtert',
    cold_sweat: 'kalter_schweiß',
    sweat_smile: 'schweiß_lächeln',
    sweat: 'schweiß',
    weary: 'ermüdet',
    tired_face: 'müdes_gesicht',
    fearful: 'ängstlich',
    scream: 'schreien',
    angry: 'wütend',
    rage: 'ausrasten',
    triumph: 'triumphieren',
    confounded: 'verwirrt',
    laughing: 'lachen',
    yum: 'lecker',
    mask: 'maske',
    sunglasses: 'sonnenbrille',
    sleeping: 'schlafen',
    dizzy_face: 'schwindelig',
    astonished: 'erstaunt',
    worried: 'besorgt',
    frowning: 'missmutig',
    anguished: 'gequält',
    imp: 'teufelchen',
    open_mouth: 'offener_mund',
    grimacing: 'grimassen_schneiden',
    neutral_face: 'neutral',
    confused: 'verwirrt',
    hushed: 'verstummt',
    smirk: 'grinsen',
    expressionless: 'ausdruckslos',
    man_with_gua_pi_mao: 'mann_mit_gua_pi_mao',
    man_with_turban: 'mann_mit_turban',
    cop: 'polizist',
    construction_worker: 'bauarbeiter',
    guardsman: 'gardist',
    baby: 'baby',
    boy: 'junge',
    girl: 'mädchen',
    man: 'mann',
    woman: 'frau',
    older_man: 'älterer_mann',
    older_woman: 'ältere_frau',
    person_with_blond_hair: 'person_mit_blonden_haaren',
    angel: 'engel',
    princess: 'prinzessin',
    smiley_cat: 'lächelnde_katze',
    smile_cat: 'lächelnde_katze',
    heart_eyes_cat: 'katze_mit_herzaugen',
    kissing_cat: 'küssende_katze',
    smirk_cat: 'grinsende_katze',
    scream_cat: 'schreiende_katze',
    crying_cat_face: 'weinende_katze',
    joy_cat: 'freudige_katze',
    pouting_cat: 'schmollende_katze',
    japanese_ogre: 'japanischer_ogre',
    japanese_goblin: 'japanischer_goblin',
    see_no_evil: 'nichts_sagen',
    hear_no_evil: 'nichts_hören',
    speak_no_evil: 'nichts_sagen',
    skull: 'schädel',
    alien: 'alien',
    hankey: 'haufen',
    fire: 'feuer',
    sparkles: 'glitzer',
    star2: 'stern',
    dizzy: 'schwindelig',
    boom: 'explosion',
    anger: 'wut',
    sweat_drops: 'schweißtropfen',
    droplet: 'tropfen',
    zzz: 'zzz',
    dash: 'rauch',
    ear: 'ohr',
    eyes: 'augen',
    nose: 'nase',
    tongue: 'zunge',
    lips: 'lippen',
    thumbs_up: 'daumen_hoch',
    '-1': '-1',
    ok_hand: 'ok_hand',
    facepunch: 'faust',
    fist: 'faust',
    wave: 'winken',
    hand: 'hand',
    open_hands: 'offene_hände',
    point_up_2: 'zeigender_finger_nach_oben',
    point_down: 'zeigender_finger_nach_unten',
    point_right: 'zeigender_finger_nach_rechts',
    point_left: 'zeigender_finger_nach_links',
    raised_hands: 'erhobene_hände',
    pray: 'beten',
    clap: 'klatschen',
    muscle: 'muskel',
    walking: 'gehen',
    runner: 'läufer',
    dancer: 'tänzer',
    couple: 'paar',
    family: 'familie',
    couplekiss: 'paarkuss',
    couple_with_heart: 'paar_mit_herz',
    dancers: 'tänzer',
    ok_woman: 'ok_frau',
    no_good: 'nein',
    information_desk_person: 'informationsschalter_person',
    raising_hand: 'hand_heben',
    massage: 'massage',
    haircut: 'haarschnitt',
    nail_care: 'nagelpflege',
    bride_with_veil: 'braut_mit_schleier',
    person_with_pouting_face: 'person_mit_schmollendem_gesicht',
    person_frowning: 'missmutige_person',
    bow: 'verbeugen',
    tophat: 'zylinder',
    crown: 'krone',
    womans_hat: 'damenhut',
    athletic_shoe: 'sportschuh',
    mans_shoe: 'herrenschuh',
    sandal: 'sandalen',
    high_heel: 'hoher_absatz',
    boot: 'stiefel',
    shirt: 'hemd',
    necktie: 'krawatte',
    womans_clothes: 'damenbekleidung',
    dress: 'kleid',
    running_shirt_with_sash: 'laufshirt_mit_band',
    jeans: 'jeans',
    kimono: 'kimono',
    bikini: 'bikini',
    school_satchel: 'schulranzen',
    pouch: 'tasche',
    purse: 'geldbörse',
    handbag: 'handtasche',
    briefcase: 'aktentasche',
    eyeglasses: 'brille',
    ribbon: 'schleife',
    closed_umbrella: 'geschlossener_regenschirm',
    lipstick: 'lippenstift',
    red_heart: 'rotes_herz',
    yellow_heart: 'gelbes_herz',
    blue_heart: 'blaues_herz',
    purple_heart: 'violettes_herz',
    green_heart: 'grünes_herz',
    broken_heart: 'gebrochenes_herz',
    heartpulse: 'herzpuls',
    heartbeat: 'herzschlag',
    two_hearts: 'zwei_herzen',
    sparkling_heart: 'funkendes_herz',
    revolving_hearts: 'sich_drehende_herzen',
    cupid: 'cupid',
    love_letter: 'liebesbrief',
    kiss: 'kuss',
    ring: 'ring',
    gem: 'edelstein',
    bust_in_silhouette: 'büste_im_schatten',
    speech_balloon: 'sprechblase',
    footprints: 'fußabdrücke',
    // nature
    dog: 'hund',
    wolf: 'wolf',
    cat: 'katze',
    mouse: 'maus',
    hamster: 'hamster',
    rabbit: 'hase',
    frog: 'frosch',
    tiger: 'tiger',
    koala: 'koala',
    bear: 'bär',
    pig: 'schwein',
    pig_nose: 'schweinsnase',
    cow: 'kuh',
    boar: 'eber',
    monkey_face: 'affe',
    monkey: 'affe',
    horse: 'pferd',
    sheep: 'schaf',
    elephant: 'elefant',
    panda_face: 'pandagesicht',
    penguin: 'pinguin',
    bird: 'vogel',
    baby_chick: 'küken',
    hatched_chick: 'geschlüpftesküken',
    hatching_chick: 'ausbrütendesküken',
    chicken: 'huhn',
    snake: 'schlange',
    turtle: 'schildkröte',
    bug: 'wanze',
    bee: 'biene',
    ant: 'ameise',
    beetle: 'käfer',
    snail: 'schnecke',
    octopus: 'krake',
    shell: 'muschel',
    tropical_fish: 'tropenfisch',
    fish: 'fisch',
    dolphin: 'delfin',
    whale: 'wal',
    racehorse: 'rennpferd',
    dragon_face: 'drachengesicht',
    blowfish: 'kugelfisch',
    camel: 'kamel',
    poodle: 'pudel',
    feet: 'füße',
    bouquet: 'blumenstrauß',
    cherry_blossom: 'kirschblüte',
    tulip: 'tulpe',
    four_leaf_clover: 'vierblättrigeskleeblatt',
    rose: 'rose',
    sunflower: 'sonnenblume',
    hibiscus: 'hibiskus',
    maple_leaf: 'ahornblatt',
    leaves: 'blätter',
    fallen_leaf: 'gefallenesblatt',
    herb: 'kraut',
    ear_of_rice: 'reisähre',
    mushroom: 'pilz',
    cactus: 'kaktus',
    palm_tree: 'palme',
    chestnut: 'kastanie',
    seedling: 'setzling',
    blossom: 'blüte',
    new_moon: 'neumond',
    first_quarter_moon: 'erstesquartalmond',
    moon: 'mond',
    full_moon: 'vollmond',
    first_quarter_moon_with_face: 'erstesquartalmondmitgesicht',
    crescent_moon: 'sichelmond',
    earth_asia: 'erdeasien',
    volcano: 'vulkan',
    milky_way: 'milchstraße',
    stars: 'sterne',
    partly_sunny: 'teilsbewölkt',
    snowman: 'schneemann',
    cyclone: 'zyklon',
    foggy: 'neblig',
    rainbow: 'regenbogen',
    ocean: 'ozean',
    // Objects section
    bamboo: 'bambus',
    gift_heart: 'geschenkherz',
    dolls: 'puppen',
    school_satchel: 'schulsatchel',
    mortar_board: 'talar',
    flags: 'flaggen',
    fireworks: 'feuerwerk',
    sparkler: 'wunderkerze',
    wind_chime: 'windspiel',
    rice_scene: 'reisszene',
    jack_o_lantern: 'kürbisfratze',
    ghost: 'geist',
    santa: 'weihnachtsmann',
    christmas_tree: 'weihnachtsbaum',
    gift: 'geschenk',
    tanabata_tree: 'tanabatabaum',
    tada: 'tada',
    confetti_ball: 'konfettiball',
    balloon: 'luftballon',
    crossed_flags: 'überkreuzteflaggen',
    crystal_ball: 'glaskugel',
    movie_camera: 'filmkamera',
    camera: 'kamera',
    video_camera: 'videokamera',
    vhs: 'vhs',
    cd: 'cd',
    dvd: 'dvd',
    minidisc: 'minidisc',
    floppy_disk: 'diskette',
    computer: 'computer',
    iphone: 'iphone',
    telephone_receiver: 'telefonhörer',
    pager: 'pager',
    fax: 'fax',
    satellite: 'satellit',
    tv: 'fernseher',
    radio: 'radio',
    loud_sound: 'lautegeräusche',
    bell: 'glocke',
    loudspeaker: 'lautsprecher',
    mega: 'mega',
    hourglass_flowing_sand: 'sanduhrfließend',
    hourglass: 'sanduhr',
    alarm_clock: 'wecker',
    watch: 'uhr',
    unlock: 'entsperren',
    lock: 'schloss',
    lock_with_ink_pen: 'schlossmitfüllfederhalter',
    closed_lock_with_key: 'geschlossenesschlossmitchlüssel',
    key: 'schlüssel',
    mag_right: 'magrechts',
    bulb: 'birne',
    flashlight: 'taschenlampe',
    electric_plug: 'stecker',
    battery: 'batterie',
    mag: 'lupe',
    bath: 'bad',
    toilet: 'toilette',
    wrench: 'schraubenschlüssel',
    nut_and_bolt: 'mutterundschraube',
    hammer: 'hammer',
    door: 'tür',
    smoking: 'rauchen',
    bomb: 'bombe',
    gun: 'pistole',
    hocho: 'messer',
    pill: 'pille',
    syringe: 'spritze',
    moneybag: 'geldtasche',
    yen: 'yen',
    dollar: 'dollar',
    credit_card: 'kreditkarte',
    money_with_wings: 'geldmitflügeln',
    calling: 'anrufen',
    'e-mail': 'e-mail',
    inbox_tray: 'eingangskorb',
    outbox_tray: 'ausgangskorb',
    envelope_with_arrow: 'umschlagmitpfeil',
    incoming_envelope: 'eingehenderumschlag',
    mailbox: 'briefkasten',
    mailbox_closed: 'geschlossenerbriefkasten',
    postbox: 'postfach',
    package: 'paket',
    memo: 'memo',
    page_facing_up: 'seiteoben',
    page_with_curl: 'seiteumgeblättert',
    bookmark_tabs: 'lesezeichenregisterkarten',
    bar_chart: 'balkendiagramm',
    chart_with_upwards_trend: 'diagrammmitsaufwärtstrend',
    chart_with_downwards_trend: 'diagrammmitsabwärtstrend',
    scroll: 'rolle',
    clipboard: 'zwischenablage',
    date: 'datum',
    calendar: 'kalender',
    card_index: 'kartei',
    file_folder: 'ordner',
    open_file_folder: 'geöffneterordner',
    pushpin: 'nadel',
    paperclip: 'büroklammer',
    straight_ruler: 'lineal',
    triangular_ruler: 'dreieckslineal',
    closed_book: 'geschlossenesbuch',
    green_book: 'grünesbuch',
    blue_book: 'blauesbuch',
    orange_book: 'orangefarbenesbuch',
    notebook: 'notizbuch',
    notebook_with_decorative_cover: 'notizbuchmitdekoeinband',
    ledger: 'buch',
    books: 'bücher',
    book: 'buch',
    bookmark: 'lesezeichen',
    name_badge: 'namensschild',
    newspaper: 'zeitung',
    art: 'kunst',
    clapper: 'klappe',
    microphone: 'mikrofon',
    headphones: 'kopfhörer',
    musical_score: 'notenblatt',
    musical_note: 'musiknote',
    notes: 'notizen',
    musical_keyboard: 'musikkeyboard',
    violin: 'geige',
    trumpet: 'trompete',
    saxophone: 'saxophon',
    guitar: 'gitarre',
    space_invader: 'spaceinvader',
    video_game: 'videospiel',
    black_joker: 'schwarzerjoker',
    flower_playing_cards: 'blumenspielkarten',
    mahjong: 'mahjong',
    game_die: 'würfel',
    dart: 'dart',
    football: 'fußball',
    basketball: 'basketball',
    soccer: 'fußball',
    baseball: 'baseball',
    tennis: 'tennis',
    '8ball': '8ball',
    bowling: 'bowling',
    golf: 'golf',
    checkered_flag: 'karierteflagge',
    trophy: 'trophäe',
    ski: 'ski',
    snowboarder: 'snowboarder',
    swimmer: 'schwimmer',
    surfer: 'surfer',
    fishing_pole_and_fish: 'angelundfisch',
    tea: 'tee',
    sake: 'sake',
    beer: 'bier',
    beers: 'biere',
    cocktail: 'cocktail',
    tropical_drink: 'tropischerdrink',
    wine_glass: 'weinglas',
    fork_and_knife: 'gabelundmesser',
    pizza: 'pizza',
    hamburger: 'hamburger',
    fries: 'pommes',
    poultry_leg: 'geflügelkeule',
    meat_on_bone: 'fleischaufknochen',
    spaghetti: 'spaghetti',
    curry: 'curry',
    fried_shrimp: 'gebratenegarnelen',
    bento: 'bento',
    sushi: 'sushi',
    fish_cake: 'fischkuchen',
    rice_ball: 'reisbällchen',
    rice_cracker: 'reiskuchen',
    rice: 'reis',
    ramen: 'ramen',
    stew: 'eintopf',
    oden: 'oden',
    dango: 'dango',
    egg: 'ei',
    bread: 'brot',
    doughnut: 'donut',
    custard: 'pudding',
    icecream: 'eis',
    ice_cream: 'eiscreme',
    shaved_ice: 'raspelseis',
    birthday: 'geburtstag',
    cake: 'kuchen',
    cookie: 'keks',
    chocolate_bar: 'schokoriegel',
    candy: 'bonbon',
    lollipop: 'lutscher',
    honey_pot: 'honigtopf',
    apple: 'apfel',
    green_apple: 'grünerapfel',
    tangerine: 'mandarine',
    cherries: 'kirschen',
    grapes: 'trauben',
    watermelon: 'wassermelone',
    strawberry: 'erdbeere',
    peach: 'pfirsich',
    melon: 'melone',
    banana: 'banane',
    pineapple: 'ananas',
    sweet_potato: 'süßkartoffel',
    eggplant: 'aubergine',
    tomato: 'tomate',
    corn: 'mais',
    // Places section
    house: 'haus',
    house_with_garden: 'hausmitgarten',
    school: 'schule',
    office: 'büro',
    post_office: 'postamt',
    hospital: 'krankenhaus',
    bank: 'bank',
    convenience_store: 'kleinladen',
    love_hotel: 'liebeshotel',
    hotel: 'hotel',
    wedding: 'hochzeit',
    church: 'kirche',
    department_store: 'kaufhaus',
    city_sunrise: 'stadtsunrise',
    city_sunset: 'stadtsunset',
    japanese_castle: 'japanischeschloss',
    european_castle: 'europäischeschloss',
    tent: 'zelt',
    factory: 'fabrik',
    tokyo_tower: 'tokyoturm',
    japan: 'japan',
    mount_fuji: 'fuji-berg',
    sunrise_over_mountains: 'sonnenaufgangüberbergen',
    sunrise: 'sonnenaufgang',
    night_with_stars: 'nachthimmelmitsternen',
    statue_of_liberty: 'freiheitsstatue',
    bridge_at_night: 'brückebeiacht',
    carousel_horse: 'karussellpferd',
    ferris_wheel: 'riesenrad',
    fountain: 'springbrunnen',
    roller_coaster: 'achterbahn',
    ship: 'schiff',
    boat: 'boot',
    speedboat: 'schnellboot',
    rocket: 'rakete',
    seat: 'sitz',
    station: 'bahnhof',
    bullettrain_side: 'schnellzugseite',
    bullettrain_front: 'schnellzugfront',
    metro: 'metro',
    railway_car: 'eisenbahnwagen',
    bus: 'bus',
    blue_car: 'blauesauto',
    car: 'auto',
    taxi: 'taxi',
    truck: 'lastwagen',
    rotating_light: 'drehlicht',
    police_car: 'polizeiauto',
    fire_engine: 'feuerwehrauto',
    ambulance: 'krankenwagen',
    bike: 'fahrrad',
    barber: 'friseur',
    busstop: 'bushaltestelle',
    ticket: 'fahrkarte',
    traffic_light: 'ampel',
    construction: 'bauarbeiten',
    beginner: 'anfänger',
    fuelpump: 'tankstelle',
    izakaya_lantern: 'izakaya-laterne',
    slot_machine: 'spielautomat',
    moyai: 'moyai',
    circus_tent: 'zirkuszelt',
    performing_arts: 'darstellende_künste',
    round_pushpin: 'runde_pushpin',
    triangular_flag_on_post: 'dreiecksflaggeamstiel',
    // Symbols section
    keycap_ten: 'zehn',
    1234: '1234',
    symbols: 'symbole',
    capital_abcd: 'großbuchstaben_abcd',
    abcd: 'abcd',
    abc: 'abc',
    arrow_up_small: 'pfeil_hoch_klein',
    arrow_down_small: 'pfeil_runter_klein',
    rewind: 'zurückspulen',
    fast_forward: 'vorspulen',
    arrow_double_up: 'pfeil_doppelt_hoch',
    arrow_double_down: 'pfeil_doppelt_runter',
    ok: 'ok',
    new: 'neu',
    up: 'hoch',
    cool: 'cool',
    free: 'frei',
    ng: 'ng',
    signal_strength: 'signalstärke',
    cinema: 'kino',
    koko: 'koko',
    u6307: 'u6307',
    u7a7a: 'u7a7a',
    u6e80: 'u6e80',
    u5408: 'u5408',
    u7981: 'u7981',
    ideograph_advantage: 'vorteil_schriftzeichen',
    u5272: 'u5272',
    u55b6: 'u55b6',
    u6709: 'u6709',
    u7121: 'u7121',
    restroom: 'toilette',
    mens: 'herren',
    womens: 'damen',
    baby_symbol: 'baby_symbol',
    wc: 'wc',
    no_smoking: 'rauchverbot',
    u7533: 'u7533',
    accept: 'annehmen',
    cl: 'cl',
    sos: 'sos',
    id: 'id',
    no_entry_sign: 'verbotsschild',
    underage: 'unter_18',
    no_entry: 'kein_eintritt',
    negative_squared_cross_mark: 'negatives_quadrat_kreuz',
    white_check_mark: 'weißer_haken',
    heart_decoration: 'herz_dekoration',
    vs: 'vs',
    vibration_mode: 'vibrationsmodus',
    mobile_phone_off: 'handy_aus',
    ab: 'ab',
    diamond_shape_with_a_dot_inside: 'diamant_mit_punkt',
    ophiuchus: 'ophiuchus',
    six_pointed_star: 'sechszackiger_stern',
    atm: 'geldautomat',
    chart: 'diagramm',
    heavy_dollar_sign: 'schweres_dollarzeichen',
    currency_exchange: 'währungsumtausch',
    x: 'x',
    exclamation: 'ausrufezeichen',
    question: 'fragezeichen',
    grey_exclamation: 'graues_ausrufezeichen',
    grey_question: 'graues_fragezeichen',
    o: 'o',
    top: 'top',
    end: 'ende',
    back: 'zurück',
    on: 'an',
    soon: 'bald',
    arrows_clockwise: 'pfeile_uhrzeigersinn',
    clock12: 'uhr12',
    clock1: 'uhr1',
    clock2: 'uhr2',
    clock3: 'uhr3',
    clock4: 'uhr4',
    clock5: 'uhr5',
    clock6: 'uhr6',
    clock7: 'uhr7',
    clock8: 'uhr8',
    clock9: 'uhr9',
    clock10: 'uhr10',
    clock11: 'uhr11',
    heavy_plus_sign: 'schweres_pluszeichen',
    heavy_minus_sign: 'schweres_minuszeichen',
    heavy_division_sign: 'schweres_teilenzeichen',
    white_flower: 'weiße_blume',
    100: '100',
    radio_button: 'radio_button',
    link: 'link',
    curly_loop: 'locken_loop',
    trident: 'dreizack',
    small_red_triangle: 'kleines_rotes_dreieck',
    black_square_button: 'schwarzer_quadrat_button',
    white_square_button: 'weißer_quadrat_button',
    red_circle: 'roter_kreis',
    large_blue_circle: 'großer_blauer_kreis',
    small_red_triangle_down: 'kleines_rotes_dreieck_runter',
    white_large_square: 'weißes_großes_quadrat',
    black_large_square: 'schwarzes_großes_quadrat',
    large_orange_diamond: 'großer_oranger_diamant',
    large_blue_diamond: 'großer_blauer_diamant',
    small_orange_diamond: 'kleiner_oranger_diamant',
    small_blue_diamond: 'kleiner_blauer_diamant',
};
