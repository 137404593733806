






























































































































































































import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoInput from '@/components/Molecules/co-input/CoInput.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoSelect from '@/components/Molecules/co-select/CoSelect.vue';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoFormGroup from '@/components/Molecules/co-form-group/CoFormGroup.vue';
import CoCheckbox from '@/components/Atoms/co-checkbox/CoCheckbox.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import Vuei18n from 'vue-i18n';
import CoModal from '../co-modal/CoModal.vue';
import EN from './CoAddPlanResourceLangEN.ts';

interface PlanResource {
    ID: string;
    Name: string;
    OverridePrice: boolean;
    PriceInCents: number;
    CreditHours: number;
    CashCredits: boolean;
    CreditCents: number;
}

interface PlanResourceGroup {
    Name: string;
    OverridePrice: boolean;
    PriceInCents: number;
    CashCredits: number;
    CreditHours: number;
    CreditCents: number;

    ListResources: PlanResource[];
}

export default {
    name: 'CoAddPlanResource',
    // `i18n` option, setup locale info for component
    i18n: {
        messages: {
            en: EN,
        },
    },
    components: {
        CoHeadline,
        CoInput,
        CoButton,
        CoSelect,
        CoModal,
        CoRoundButton,
        CoFormGroup,
        CoCheckbox,
        CoText,
    },
    props: {
        edit: {
            type: Boolean,
            default: false,
            required: false,
        },
        group: {
            type: Boolean,
            default: false,
            required: false,
        },

        buttonTitle: {
            type: String,
            default: '',
            required: false,
        },
        buttonVariant: {
            type: String,
            default: 'secondary',
            required: false,
        },
        buttonClasses: {
            type: String,
            default: '',
            required: false,
        },
        resources: {
            type: Array,
            default: () => [],
            required: false,
        },
        excludeResources: {
            type: Array,
            default: () => [],
            required: false,
        },

        preselectedResources: {
            type: Array,
            default: () => [],
            required: false,
        },
        preSetGroupName: {
            type: String,
            default: '',
            required: false,
        },
        preSetPriceOverride: {
            type: Boolean,
            default: false,
            required: false,
        },
        preSetPrice: {
            type: Number,
            default: 0,
            required: false,
        },
        preSetCredits: {
            type: Number,
            default: 0,
            required: false,
        },
        preSetCashCreditType: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data() {
        return {
            resource: null, // used if group is false
            selectedResouces: [], // if group is true then this is an array of resources and single resource is ignored
            priceOverride: false,
            price: 0,
            groupName: '',
            credits: 0,

            creditTypes: [
                { Name: 'Hours', Value: false },
                { Name: 'Cash', Value: true },
            ],
            selectedCreditType: false,

            searchInput: '',
            sortBySelected: false,

            rerenderListKey: Math.random() * 100,
        };
    },
    watch: {
        sortBySelected() {
            this.rerenderListKey += 1;
        },
        preselectedResources(_, newValue) {
            if (this.group) {
                this.selectedResouces = newValue;
            } else if (newValue && newValue.length > 0) {
                [this.resource] = newValue;
            }
        },
        preSetGroupName(_, newValue) {
            this.groupName = newValue;
        },
        preSetPriceOverride(_, newValue) {
            this.priceOverride = newValue;
        },
        preSetPrice(_, newValue) {
            this.price = (newValue / 100).toFixed(2);
        },
        preSetCredits(_, newValue) {
            this.credits = newValue;
        },
        preSetCashCreditType(_, newValue) {
            this.selectedCreditType = newValue;
            if (newValue) {
                this.credits = (this.preSetCredits / 100).toFixed(2);
            }
        },
    },
    computed: {
        currentSelectedCreditType() {
            return this.selectedCreditType ? { Name: 'Cash', Value: true } : { Name: 'Hours', Value: false };
        },
        currentlySelectedResouces() {
            if (!this.group && this.resource) {
                return { Value: this.resource.ID, Name: this.resource.Name };
            }
            return null;
        },
        selectedResourceString() {
            if (!this.selectedResouces || this.selectedResouces.length === 0) {
                return '0 resources selected';
            }

            if (this.selectedResouces.length === 1) {
                return `${this.selectedResouces[0].Name}`;
            }

            // more than 1 get first one name and add + number of resources selected
            return `${this.selectedResouces[0].Name} + ${this.selectedResouces.length - 1} resources selected`;
        },
        saveButtonState() {
            if (this.group) {
                return this.groupName && this.selectedResouces && this.selectedResouces.length > 0;
            }
            return this.resource !== null && this.resource !== undefined;
        },

        resourcesForSelector() {
            return this.resources.map((resource) => ({
                Value: resource.ID,
                Name: resource.Name,
                Disabled: this.excludeResources.some((ID) => ID === resource.ID),
            }));
        },

        filteredListOfResources() {
            let { resources } = this;

            if (this.searchInput) {
                resources = this.resources.filter((resource) =>
                    resource.Name.toLowerCase().includes(this.searchInput.toLowerCase())
                );
            }

            if (this.sortBySelected) {
                // sort resources by selected from this.resouces
                resources = resources.sort((a, b) => {
                    const aIndex = this.selectedResouces.findIndex((resource) => resource.ID === a.ID);
                    const bIndex = this.selectedResouces.findIndex((resource) => resource.ID === b.ID);

                    if (aIndex < bIndex) {
                        return 1;
                    }
                    if (aIndex > bIndex) {
                        return -1;
                    }
                    return 0;
                });
            }
            return resources;
        },
    },

    mounted() {
        if (this.group) {
            this.selectedResouces = this.preselectedResources;
        } else if (this.preselectedResources && this.preselectedResources.length > 0) {
            this.resource = this.preselectedResources[0];
        }

        if (this.preSetGroupName) {
            this.groupName = this.preSetGroupName;
        }

        if (this.preSetPriceOverride) {
            this.priceOverride = this.preSetPriceOverride;
        }

        if (this.preSetPrice) {
            this.price = (this.preSetPrice / 100).toFixed(2);
        }

        if (this.preSetCredits) {
            this.credits = this.preSetCredits;
        }

        if (this.preSetCashCreditType) {
            this.selectedCreditType = this.preSetCashCreditType;
            this.credits = (this.preSetCredits / 100).toFixed(2);
        }
    },
    methods: {
        discard() {
            this.sortBySelected = false;
            this.searchInput = '';
            this.selectedResouces = [];
            this.hideSelectResourcesModal();
        },
        isSelected(resource) {
            return this.selectedResouces.some((item) => item.ID === resource.ID);
        },
        selectSingleResource(item) {
            this.resource = this.resources.find((resource) => resource.ID === item.Value);
        },
        selectResource(event, item) {
            const e = event;
            if (e) {
                this.selectedResouces.push(item);
            } else {
                this.selectedResouces = this.selectedResouces.filter((resource) => resource.ID !== item.ID);
            }
        },
        show() {
            this.$refs['add-plan-resource-modal'].show();
        },
        hide() {
            this.$refs['add-plan-resource-modal'].hide();
            // clear modals fields
            this.groupName = '';
            this.selectedResouces = [];
            this.priceOverride = false;
            this.price = 0;
            this.credits = 0;
            this.currentlySelectedResouces = null;
            this.resource = null;
        },

        showSelectResourcesModal() {
            this.$refs['select-plan-resource-modal'].show();
        },
        hideSelectResourcesModal() {
            this.$refs['select-plan-resource-modal'].hide();
        },
        priceFormatter(value) {
            return this.trimTo2DecimalPlaces(value.replace(',', '.'));
        },
        selectCreditsType(item) {
            this.selectedCreditType = item.Value;
        },

        save() {
            if (this.group) {
                // make variable of type PlanResourceGroup
                const planResourceGroup: PlanResourceGroup = {
                    Name: this.groupName,
                    OverridePrice: this.priceOverride,
                    PriceInCents: Math.trunc(this.price * 100),
                    CashCredits: this.selectedCreditType,
                    CreditHours: this.credits,
                    CreditCents: this.credits * 100,
                    ListResources: [],
                };

                if (this.selectedCreditType) {
                    planResourceGroup.CreditHours = 0;
                    planResourceGroup.CreditCents = Math.trunc(this.credits * 100);
                } else {
                    planResourceGroup.CreditCents = 0;
                    planResourceGroup.CreditHours = Math.trunc(this.credits);
                }
                // create array of PlanResource from selectedResouces
                this.selectedResouces.forEach((resource) => {
                    const planResource: PlanResource = {
                        ID: resource.ID,
                        Name: resource.Name,
                        OverridePrice: this.priceOverride,
                        PriceInCents: Math.trunc(this.price * 100),
                        CashCredits: this.selectedCreditType,
                        CreditHours: this.credits,
                        CreditCents: this.credits * 100,
                    };

                    if (this.selectedCreditType) {
                        planResource.CreditHours = 0;
                        planResource.CreditCents = Math.trunc(this.credits * 100);
                    } else {
                        planResource.CreditCents = 0;
                        planResource.CreditHours = Math.trunc(this.credits);
                    }
                    planResourceGroup.ListResources.push(planResource);
                });

                this.$emit('saveGroup', planResourceGroup);
                this.hide();
                return;
            }

            const planResource: PlanResource = {
                ID: this.resource.ID,
                Name: this.resource.Name,
                OverridePrice: this.priceOverride,
                PriceInCents: Math.trunc(this.price * 100),
                CashCredits: this.selectedCreditType,
                CreditHours: this.credits,
                CreditCents: this.credits * 100,
            };

            if (this.selectedCreditType) {
                planResource.CreditHours = 0;
                planResource.CreditCents = Math.trunc(this.credits * 100);
            } else {
                planResource.CreditCents = 0;
                planResource.CreditHours = Math.trunc(this.credits);
            }
            this.$emit('saveResource', planResource);
            this.hide();
        },
    },
};
