









































































































































































































import EventBus from '@/eventBus';
import VueTagsInput from '@johmun/vue-tags-input';
import axios from 'axios';
import i18n from 'vue-i18n';

export default {
    i18n: {
        messages: {
            en: {
                pagepublishexplain: '@:labels.page activites will be visible to everyone in the community',
                linksexplain: 'Add @:labels.links to related information or shared files',
            },
            de: {
                pagepublishexplain:
                    'Diese @:labels.page und alle ihre Aktvitäten werden sichtbar für andere in der Community',
                linksexplain: 'Füge @:labels.links zu Informationen oder Dateien hinzu',
            },
        },
    },
    name: 'ProjectEdit',
    components: {
        VueTagsInput,
    },
    data() {
        return {
            description: '',
            form: {},
            deleting: false,
            url: null,
            file: null,
            showOverlay: false,

            tag: '',
            tags: [],
            autocompleteItems: [],
            debounce: null,
            separators: [';', ',', '#', ', #'],
            published: false,

            projectTypes: [],

            links: [''],
        };
    },
    props: ['project', 'title', 'variant'],
    mounted() {
        this.form = this.project;
        if (this.form.Published != null) {
            this.published = this.form.Published;
        }
        this.loadTags();
        this.listProjectTypes();

        if (!this.form.Links || this.form.Links.length === 0) {
            this.links = [''];
        } else {
            this.links = this.form.Links;
        }
    },
    watch: {
        tag: 'loadAutocompleteItems',
        published(newValue, oldValue) {
            console.log(newValue);
        },
    },
    methods: {
        setChannel(event) {},
        addLink() {
            this.links.push('');
        },
        removeLink(index) {
            this.links.splice(index, 1);
        },
        listProjectTypes() {
            this.projectTypes = [];
            axios({
                method: 'GET',
                url: '/project/project-type/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data && response.data.objects && response.data.objects.length != 0) {
                        let isCurrentTypeInResult = false;
                        response.data.objects.forEach((element) => {
                            if (this.form.Type === element.object.Value) {
                                isCurrentTypeInResult = true;
                            }
                            this.projectTypes.push(element.object);
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        cancel() {
            EventBus.$emit('CancelProjectEdit', null);
        },
        /**
         * addTag - creates new tag if user types custom one and hit enter
         */
        addTag(obj) {
            const tag = JSON.stringify({ Value: obj.tag.text });
            this.$store
                .dispatch('createTag', tag)
                .then((response) => {
                    const tagResponse = {
                        text: response.Value,
                        slug: response.Slug,
                    };
                    this.tags.push(tagResponse);
                    this.tag = '';
                    return true;
                })
                .catch((error) => {
                    const message = {
                        Message: `Failed to create tag \`${obj.tag.text}\``,
                        Details: error,
                    };
                    EventBus.$emit('ERROR', message);
                    return false;
                }, obj);
        },
        updateTags(newTags) {
            this.autocompleteItems = [];
            this.tags = newTags;
        },
        loadAutocompleteItems() {
            this.autocompleteItems = [];
            if (this.tag.length < 2) return;
            this.$store
                .dispatch('searchTag', this.tag)
                .then((response) => {
                    response.forEach(function (entry) {
                        const tag = {
                            text: entry.Slug,
                            slug: entry.Slug,
                        };
                        this.autocompleteItems.push(tag);
                    }, this);
                    return true;
                })
                .catch((error) => {
                    console.log('error: ', error);
                    return false;
                });
        },
        /**
         * loadTags - loads initial tags
         */
        loadTags() {
            if (!this.form.Hashtag || this.form.Hashtag.length === 0) {
                return;
            }
            const filtered = this.form.Hashtag.filter((el) => el !== '');
            const IDs = JSON.stringify({ IDs: filtered });
            this.$store
                .dispatch('getTagsByIDs', IDs)
                .then((response) => {
                    response.forEach(function (entry) {
                        const tag = {
                            text: entry.Value,
                            slug: entry.Slug,
                        };
                        this.tags.push(tag);
                    }, this);
                })
                .catch((error) => {
                    const message = {
                        Message: 'Failed to load tags for page',
                        Details: error,
                    };
                    EventBus.$emit('ERROR', message);
                });
        },

        preview(event) {
            const file = event.target.files[0];
            this.url = URL.createObjectURL(file);
            this.file = file;
        },

        save() {
            if (this.file != null) {
                this.uploadPreview();
            } else {
                this.saveChanges();
            }
        },

        uploadPreview() {
            this.showOverlay = true;
            this.$store
                .dispatch('imageUpload', {
                    file: this.file,
                    url: '/dashboard/image/projects-headers',
                })
                .then((response) => {
                    this.form.ImageURL = [response];
                    this.saveChanges();
                })
                .catch((error) => {
                    this.showOverlay = false;
                    this.url = null;
                    this.file = null;
                    const message = {
                        Message: 'Could not upload an image',
                        Details: error,
                    };
                    EventBus.$emit('ERROR', message);
                });
        },

        saveChanges() {
            this.showOverlay = true;

            if (this.tags.length > 0) {
                const hashtags = [];
                this.tags.forEach((entry) => {
                    hashtags.push(entry.slug);
                }, hashtags);

                this.form.Hashtag = hashtags;
            }
            delete this.form.LastModifyed;

            if (this.form.Published !== this.published && this.published) {
                this.form.Published = true;
                this.form.PublishedAt = Math.floor(Date.now() / 1000);
            } else if (this.form.Published !== this.published && this.published === false) {
                this.form.Published = false;
                this.form.PublishedAt = 0;
            }

            const filteredLinks = this.links.filter((el) => el !== '');
            this.form.Links = filteredLinks;

            this.$store
                .dispatch('updateProject', this.form)
                .then((response) => {
                    setTimeout(() => {
                        this.showOverlay = false;
                        const message = {
                            Message: 'Page updated',
                            Details: null,
                        };
                        EventBus.$emit('INFO', message);
                        this.$emit('saved');
                    }, 2000);
                })
                .catch((error) => {
                    this.showOverlay = false;
                    this.$emit('saved');
                    if (error.response.data && error.response.data.message) {
                        EventBus.$emit('ERROR', {
                            Message: error.response.data.message,
                            Details: error.response.data.details,
                        });
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: 'Could not create page update. Please, try again.',
                            Details: null,
                        });
                    }
                });
        },
        deleteProject(bvModalEvt) {
            // Prevent modal from closing
            // bvModalEvt.preventDefault()

            this.showOverlay = true;
            this.deleting = true;
            const req = JSON.stringify({
                ProjectID: this.project.ID,
                UserID: this.$store.state.me.ID,
            });
            this.$store
                .dispatch('deleteProject', req)
                .then((response) => {
                    setTimeout(() => {
                        this.deleting = false;
                        const message = {
                            Message: 'Page deleted',
                            Details: null,
                        };
                        EventBus.$emit('INFO', message);
                        this.$router.push('/profile/my-pages');
                        this.$bvModal.hide('deleting-modal');
                    }, 2000);
                })
                .catch((error) => {
                    this.deleting = false;
                    if (error.response.data && error.response.data.message) {
                        EventBus.$emit('ERROR', {
                            Message: error.response.data.message,
                            Details: error.response.data.details,
                        });
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: 'Could not delete page. Please try again later.',
                            Details: null,
                        });
                    }
                });
        },
    },
};
