<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <b-overlay :show="loading" class="round-unify-xs mb-3" style="overflow: visible !important">
        <div class="d-flex align-items-start p-2 round-unify-xs bg-yellow" style="overflow: visible !important">
            <div class="coapp-icon pt-3">
                <b-icon icon="geo"></b-icon>
            </div>
            <div
                class="
                    round-unify
                    border-secondary
                    d-flex
                    w-100
                    p-2
                    pl-0
                    ml-0 ml-0
                    justify-content-between
                    align-items-start
                "
            >
                <div class="w-100 pl-0">
                    <b-form @submit.stop.prevent="publishNote">
                        <Editor
                            id="textarea-default"
                            placeholder="Add Note"
                            taclass="m-0 comment-input"
                            rows="5"
                            class="customTiptap"
                            ref="tiptap"
                            v-model="note.Text"
                            is-post
                            :links="links"
                            @onFocus="onFocus = true"
                        >
                        </Editor>

                        <div class="text-right mt-3" v-if="onFocus">
                            <b-button @click="cancelNote" variant="outline-primary" class="mr-2">Cancel</b-button>
                            <b-button
                                type="submit"
                                variant="primary"
                                :disabled="note.Text === '' || note.Text === '<p></p>'"
                                >Add
                            </b-button>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </b-overlay>
</template>
<style lang="less" scoped>
.round-unify {
    overflow: visible;
}
</style>
<script>
import axios from 'axios';
import Vue from 'vue';
import EventBus from '@/eventBus';

export default {
    name: 'CreateNote',
    props: ['user'],
    data() {
        return {
            note: {
                Text: '',
            },

            loading: false,
            isEditorEmpty: true,

            onFocus: false,
            links: [],
            me: this.$store.state.me,
        };
    },
    methods: {
        setFocus() {
            this.$refs.tiptap.focus(true);
        },

        publishNote() {
            this.loading = true;
            this.note.UserID = this.user.ID;
            this.note.AuthorID = this.me.ID;
            this.note.AuthorName = this.me.Profile.Name;
            this.createNote();
        },
        cancelNote() {
            this.$refs.tiptap.clear(true, true);
            this.onFocus = false;
        },
        createNote() {
            this.note.Text = this.$refs.tiptap.editor.getHTML();
            const newNote = this.note;
            if (newNote.Text) {
                newNote.Text = newNote.Text.replace(/<p><\/p>/g, '<br>');
            }
            this.$store
                .dispatch('createUserNoteAdmin', newNote)
                .then((response) => {
                    const msg = {
                        Message: 'Note published',
                        Details: '',
                    };
                    EventBus.$emit('INFO', msg);

                    this.loading = false;

                    this.$refs.tiptap.clear(true, true);
                    this.onFocus = false;
                    this.$emit('created');
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response.data && error.response.data.message) {
                        EventBus.$emit('ERROR', {
                            Message: error.response.data.message,
                            Details: error.response.data.details,
                        });
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: 'Could not create note. Please try again later.',
                            Details: null,
                        });
                    }
                });
        },
    },
};
</script>
