import { VueRenderer } from '@tiptap/vue-2';
import tippy from 'tippy.js';
import axios from 'axios';
import MentionList from './MentionList.vue';

export default {
    name: 'MentionSuggestion',
    items: async ({ query }) => {
        if (!query) {
            return [];
        }

        const result = [];
        await axios({
            method: 'GET',
            url: `/search/user/circles/${query}`,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response && response.data) {
                    response.data.forEach((entry) => {
                        result.push({
                            name: entry.Profile.Name,
                            slug: entry.Slug,
                            id: entry.ID,
                        });
                    });
                }
            })
            .catch((error) => {});
        return result;
    },

    render: () => {
        let component;
        let popup;

        return {
            onStart: (props) => {
                component = new VueRenderer(MentionList, {
                    // using vue 2:
                    parent: this,
                    propsData: props,
                    // props,
                    editor: props.editor,
                });

                if (!props.clientRect) {
                    return;
                }

                popup = tippy('body', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => document.body,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start',
                });
            },

            onUpdate(props) {
                component.updateProps(props);

                if (!props.clientRect) {
                    return;
                }

                popup[0].setProps({
                    getReferenceClientRect: props.clientRect,
                });
            },

            onKeyDown(props) {
                if (props.event.key === 'Escape') {
                    popup[0].hide();

                    return true;
                }

                return component.ref.onKeyDown(props);
            },

            onExit() {
                popup[0].destroy();
                component.destroy();
            },

            command({ editor, range, props }) {
                console.log('command', editor, range, props);
            },
        };
    },
};
