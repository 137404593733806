<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <div class="">
        <div class="d-flex flex-column">
            <!-- header start -->
            <div class="d-flex justify-content-between align-items-center pl-2 mb-5">
                <div class="d-none d-md-block">
                    <b-button class="" variant="no-outline" style="color: #868685" @click="handleCancel"
                        >Discard
                    </b-button>
                    <b-button variant="primary" @click="handleCreateSubmit">Create</b-button>
                </div>
            </div>
            <!-- header end -->

            <div class="w-100 my-3 bg-white p-3 round-unify">
                <b-overlay :show="loading">
                    <b-form ref="createResource" class="d-flex flex-column">
                        <!-- header end -->

                        <b-form-group
                            id="input-group-name"
                            label="Name"
                            label-for="input-name"
                            aria-describedby="name-description"
                            label-class="p-2"
                            class="mb-2 mt-0 p-0"
                        >
                            <b-form-input
                                id="input-name"
                                v-model="form['Name']"
                                type="text"
                                required
                                placeholder="Type here"
                                class="rounded-pill"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-price"
                            :label="'Price in ' + form.Currency"
                            label-for="input-price"
                            aria-describedby="price-description"
                            label-class="p-2"
                            class="mb-2 mt-0 p-0"
                        >
                            <b-form-input
                                number
                                min="0.00"
                                v-model="price"
                                step="0.01"
                                class="rounded-pill grey-text"
                                required
                                @change="priceChange"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-tax-rate"
                            label="Tax Rate"
                            label-for="input-tax-rate"
                            label-class="p-2"
                            class="mb-2 mt-0 p-0"
                        >
                            <b-form-select
                                id="select-tax-rate"
                                v-model="form.TaxRateID"
                                class="form-control rounded-pill d-block"
                                @change="calculateFinalPrice($event)"
                                required
                            >
                                <template #first>
                                    <b-form-select-option :value="null" disabled selected
                                        >-- Please select default tax rate --
                                    </b-form-select-option>
                                </template>
                                <b-form-select-option v-for="item in taxRates" :value="item.ID" :key="item.ID"
                                    >{{ item.Percentage ? item.Percentage : 0 }}% -

                                    <span v-if="item.Inclusive">Inclusive</span>
                                    <span v-else>Exclusive</span>
                                    - {{ item.DisplayName }}
                                </b-form-select-option>
                            </b-form-select>
                            <b-form-text class="mt-2">
                                Final price for customer will be
                                <strong>{{ FinalPrice }} {{ form.Currency }}</strong>
                            </b-form-text>
                        </b-form-group>

                        <b-form-group
                            id="input-group-currency"
                            label="Currency"
                            label-for="input-currency"
                            description="Currency for time pass"
                            label-class="p-2"
                            class="mb-2 mt-0 p-0"
                            requ
                        >
                            <b-form-select
                                id="select-currency"
                                v-model="form['Currency']"
                                class="form-control rounded-pill d-block"
                                required
                                :disabled="true"
                            >
                                <b-form-select-option :value="form['Currency']"
                                    >{{ form['Currency'] }}
                                </b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                        <b-form-group
                            id="input-group-usage"
                            label="Quantity"
                            label-for="input-usage"
                            description="Number of times a user can apply this time pass"
                            label-class="p-2"
                            class="mb-2 mt-0 p-0"
                        >
                            <b-form-input
                                id="input-usage"
                                v-model.number="form['Number']"
                                type="number"
                                required
                                placeholder="Number"
                                class="rounded-pill"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            id="input-group-duration"
                            label="Duration (hours)"
                            label-for="input-duration"
                            aria-describedby="duration-description"
                            label-class="p-2"
                            class="mb-2 mt-0 p-0"
                        >
                            <b-form-input
                                id="input-duration"
                                v-model.number="form['Duration']"
                                type="number"
                                required
                                placeholder="Number"
                                class="rounded-pill"
                            ></b-form-input>
                        </b-form-group>
                        <div class="w-100 d-block d-md-none">
                            <div class="d-flex justify-content-end">
                                <b-button
                                    class="mr-3 ml-0 pl-0"
                                    variant="no-outline"
                                    style="color: #868685"
                                    @click="handleCancel"
                                    >Discard
                                </b-button>
                                <b-button @click="handleCreateSubmit" variant="primary">Create</b-button>
                            </div>
                        </div>
                    </b-form>
                </b-overlay>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';

export default {
    name: 'AdminCreateTimepass',
    data() {
        return {
            form: {
                Currency: 'EUR',
            },
            taxRates: [],
            loadingTaxRate: false,
            loading: false,
            FinalPrice: 0.0,
            price: 0.0,
        };
    },
    created() {
        this.getTaxRates();
        if (this.$store.state.space.Currency) {
            this.form.Currency = this.$store.state.space.Currency;
        }
    },

    methods: {
        getTaxRates() {
            this.loadingTaxRate = true;
            axios({
                method: 'GET',
                url: `/admin/tax-rate/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.taxRates = response.data;
                    }
                    this.loadingTaxRate = false;
                })
                .catch((error) => {
                    this.loadingTaxRate = false;
                    console.log(error);
                });
        },
        priceChange() {
            if (this.form.TaxRateID) {
                this.calculateFinalPrice(this.form.TaxRateID);
            }
        },
        calculateFinalPrice(taxID) {
            const tax = this.taxRates.find((item) => item.ID === taxID);
            if (tax.Inclusive) {
                this.FinalPrice = this.price;
            } else {
                this.FinalPrice = (this.price * (tax.Percentage / 100 + 1)).toFixed(2);
            }
        },
        handleCreateSubmit(event) {
            // validate form
            const { createResource } = this.$refs;
            if (createResource) {
                if (!createResource.checkValidity()) {
                    createResource.reportValidity();
                    return;
                }
            } else {
                return;
            }

            this.loading = true;
            this.form.PriceInCents = this.price * 100;

            this.$store
                .dispatch('createTimepass', this.form)
                .then((response) => {
                    const message = {
                        Message: 'Changes saved',
                        Details: '',
                    };
                    EventBus.$emit('INFO', message);
                    this.loading = false;
                    this.$router.push(`/admin/monetization/time-passes/view/${response.ID}`);
                })
                .catch((error) => {
                    const message = {
                        Message: 'Failed to create time pass',
                        Details: error,
                    };
                    this.loading = false;
                    EventBus.$emit('ERROR', message);
                });
            this.loading = false;
        },
        handleCancel() {
            this.$router.push(`/admin/monetization/time-passes`);
        },
    },
};
</script>
