


































































import axios from 'axios';

import EventBus from '@/eventBus';
import i18n from 'vue-i18n';

import { map } from 'lodash';
import { Page, User } from '../../components/Molecules/co-card-page/models.ts';
import CoCardPage from '../../components/Molecules/co-card-page/CoCardPage.vue';
import CoCardMember from '../../components/Molecules/co-card-member/CoCardMember.vue';
import CoInput from '../../components/Molecules/co-input/CoInput.vue';

export default {
    i18n: {
        messages: {
            en: {
                searching: "Searching for '{query}' ...",
                results: '1 result | {n} results',
                noresultsforkeyword: "We couldn't find anything for '{query}'.",
                searchhelp: 'Try different or less specific keywords.',
                noSearchInput: 'Please enter a search query',
            },
            de: {
                searching: "Suche nach '{query}' ...",
                results: '1 Ergebnis | {n} Ergebnisse',
                noresultsforkeyword: "Wir konnten nichts zu '{query}' finden.",
                searchhelp: 'Versuches mit einer anderen oder weniger spezifischen Suche.',
                noSearchInput: 'Bitte geben Sie einen Suchbegriff ein',
            },
        },
    },
    name: 'SearchInMobileApp',
    components: {
        CoCardPage,
        CoCardMember,
        CoInput,
    },
    data() {
        return {
            searchResults: false,
            searchLoading: true,
            search: '',
            keycode: '',
            feed: [],
            feedBank: [],
            feedAdd: [],
            results: 0,

            isMarketOn: this.$store.state.space.MarkeplaceOn,

            owners: {},
            channelList: null,
            me: this.$store.state.me,
            circlesOn: this.$store.state.circlesOn,
        };
    },
    computed: {
        query() {
            return this.$route.query.term;
        },
    },
    watch: {
        '$route.query.term': async function (term) {
            if (this.circlesOn) {
                await this.makeExperimentalSearch();
                this.searchLoading = false;
            } else {
                await this.makeSearch();
                this.searchLoading = false;
            }
        },
    },
    destroyed() {
        window.removeEventListener('scroll', this.scroll);
    },
    mounted() {
        this.scroll();
        this.runAsyncLoadingFunctions();
    },
    beforeDestroy() {
        this.feed = [];
        this.changeSearchTerm('');
    },
    methods: {
        async runAsyncLoadingFunctions() {
            this.searchLoading = true;
            if (this.circlesOn) {
                await Promise.all([this.makeExperimentalSearch(), this.getChannelList()]);
            } else {
                await Promise.all([this.makeSearch(), this.getChannelList()]);
            }
            this.searchLoading = false;
        },
        changeSearchTerm(value) {
            this.search = value;
            // update query param
            this.$router.push({ query: { term: value } });
        },

        objectHash(obj) {
            // convert object to string and hash it to use as key
            const str = JSON.stringify(obj);

            // hash the string
            let hash = 0;

            let char;
            for (let i = 0; i < str.length; i++) {
                char = str.charCodeAt(i);
                hash = (hash << 5) - hash + char;
                hash &= hash;
            }
            return hash;
        },

        viewItem(id, type) {
            if (type === 'project') {
                this.$router.push(`/project/${id}`);
            } else if (type == 'user') {
                this.$router.push(`/profile/${id}`);
            }
        },

        async makeSearch() {
            this.searchLoading = true;
            this.feed = [];
            this.feedBank = [];
            this.feedAdd = [];
            axios({
                method: 'GET',
                url: `/search/v2/${this.$route.query.term}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(async (response) => {
                    this.searchLoading = false;
                    if (response && response.data) {
                        if (response.data.objects) {
                            // filter out the jobs if the market is off
                            let { objects } = response.data;
                            if (!this.isMarketOn) {
                                objects = objects.filter((item) => item.type !== 'job');
                            }

                            // add key to each object
                            objects = objects.map((item) => {
                                item.key = Math.random();
                                return item;
                            });

                            this.$set(this.$data, 'feedBank', objects);
                            this.results = this.feedBank.length;
                            if (this.feedBank.length > 20) {
                                this.feedAdd.push(...this.feedBank.slice(0, 20));
                                this.feedBank = this.feedBank.slice(20);
                            } else {
                                this.feedAdd.push(...this.feedBank);
                                this.feedBank = [];
                            }
                        } else {
                            this.$set(this.$data, 'feedBank', []);
                            this.$set(this.$data, 'feedAdd', []);
                        }
                        this.getOwners(this.feedAdd);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async makeExperimentalSearch() {
            this.searchLoading = true;
            this.feed = [];
            this.feedBank = [];
            this.feedAdd = [];
            await axios({
                method: 'GET',
                url: `/search/tmp/${this.$route.query.term}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(async (response) => {
                    this.searchLoading = false;
                    if (response && response.data) {
                        if (response.data.objects) {
                            // filter out the jobs if the market is off
                            let { objects } = response.data;
                            if (!this.isMarketOn) {
                                objects = objects.filter((item) => item.type !== 'job');
                            }

                            // add key to each object
                            objects = objects.map((item) => {
                                item.key = Math.random();
                                return item;
                            });

                            this.$set(this.$data, 'feedBank', objects);
                            this.results = this.feedBank.length;
                            if (this.feedBank.length > 20) {
                                this.feedAdd.push(...this.feedBank.slice(0, 20));
                                this.feedBank = this.feedBank.slice(20);
                            } else {
                                this.feedAdd.push(...this.feedBank);
                                this.feedBank = [];
                            }
                        } else {
                            this.$set(this.$data, 'feedBank', []);
                            this.$set(this.$data, 'feedAdd', []);
                        }
                        this.getOwners(this.feed);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        async getOwners(feed) {
            const ownerIDMap = new Map();

            // Collect all unique owner IDs
            for (let i = 0; i < feed.length; i++) {
                const obj = feed[i].object;

                if (feed[i].type !== 'project') {
                    continue;
                }

                if (!this.owners[obj.ID]) {
                    obj.Owner.forEach((entry) => {
                        if (!ownerIDMap.has(entry)) {
                            ownerIDMap.set(entry, []);
                        }
                        ownerIDMap.get(entry).push(obj.ID);
                    });
                }
            }

            if (ownerIDMap.size > 0) {
                const ids = Array.from(ownerIDMap.keys()).map((ID) => ({ ID }));
                const ownersIDs = { IDS: ids };
                const data = JSON.stringify(ownersIDs);

                const response = await this.$store.dispatch('listUsersByIDs', { ids: data });

                if (response.Users) {
                    let owners = response.Users;

                    owners = owners.map((owner) => {
                        const tmp = {
                            id: owner.ID,
                            slug: owner.Slug,
                            name: `${owner.Profile.FirstName} ${owner.Profile.LastName}`,
                            image: owner.Profile.ImageURL,
                        };

                        if (!owner.Profile.FirstName) {
                            tmp.name = owner.Profile.Name;
                        }

                        return tmp;
                    });

                    // Map the owners to the respective objects
                    owners.forEach((owner) => {
                        ownerIDMap.get(owner.id).forEach((objID) => {
                            if (!this.owners[objID]) {
                                this.owners[objID] = [];
                            }
                            this.owners[objID].push(owner);
                        });
                    });
                }
            }

            await this.mapFeedPostToPage();
        },

        async getChannelList() {
            /* get the list of channels and save them in the channelList map with slug as keys */
            axios
                .get('/project/channel/list')
                .then((response) => {
                    if (response.data) {
                        // find channel title by slug
                        this.channelList = response.data.objects.map((channel) => channel.object);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(async () => {
                    await this.mapFeedPostToPage();
                });
        },

        async mapFeedPostToPage() {
            //  iterate over the feed and replace the project with the page object in the feed
            for (let i = 0; i < this.feedAdd.length; i++) {
                if (this.feedAdd[i].type !== 'project') {
                    continue;
                }
                let channelSlug = this.getChannelSlugByID(this.feedAdd[i].object.ChannelID);
                if (!channelSlug) {
                    channelSlug = 'pages';
                }
                const owners = this.owners[this.feedAdd[i].object.ID];
                const p: Page = {
                    id: this.feedAdd[i].object.ID,
                    title: this.feedAdd[i].object.Title,
                    description: this.feedAdd[i].object.Description,
                    image:
                        this.feedAdd[i].object.ImageURL && this.feedAdd[i].object.ImageURL[0]
                            ? this.feedAdd[i].object.ImageURL[0]
                            : null,
                    slug: this.feedAdd[i].object.Slug,
                    owners,
                    // Add the remaining properties here
                    channelSlug,
                    followed: !!(
                        this.feedAdd[i].object.FollowedBy && this.feedAdd[i].object.FollowedBy.indexOf(this.me.ID) > -1
                    ),
                    private: !this.feedAdd[i].object.Published,
                };

                if (this.feedAdd[i].space) {
                    p.space = this.mapSpaceObject(this.feedAdd[i].space);
                }

                this.feedAdd[i].page = p;

                if (!this.feedAdd[i].key) {
                    this.feedAdd[i].key = Math.random();
                } else {
                    this.feedAdd[i].key += 1;
                }
            }
            // add the feedAdd to the feed
            this.feed.push(...this.feedAdd);
            this.feedAdd = [];
            return new Promise((resolve) => {
                resolve(1);
            });
        },
        mapSpaceObject(spaceItem) {
            if (!spaceItem) {
                return null;
            }

            if (!spaceItem.ID) {
                return null;
            }

            if (spaceItem.ID === this.$store.state.space.ID) {
                return null;
            }
            const obj = {
                id: spaceItem.ID,
                name: spaceItem.Name,
                favicon: spaceItem.FaviconURL,
                primaryColor: spaceItem.PrimaryColor,
            };
            return obj;
        },

        getChannelSlugByID(ID) {
            // get channel slug by ID
            const slug = '';

            if (!this.channelList) {
                return slug;
            }

            // clone the channel list map to avoid mutation
            const channelList = JSON.parse(JSON.stringify(this.channelList));

            const channel = channelList.find((c) => c.ID === ID);
            return channel ? channel.Slug : slug;
        },

        follow(page) {
            const subs = JSON.stringify({ ProjectID: page.id });
            axios({
                method: 'POST',
                url: '/project/follow',
                data: subs,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    EventBus.$emit('INFO', {
                        Message: 'Page followed',
                        Details: null,
                    });

                    // update page in pages array
                    const index = this.feed.findIndex((p) => {
                        if (p.page) {
                            return p.page.id === page.id;
                        }
                        return false;
                    });
                    this.feed[index].page.followed = true;
                    this.feed[index].key += 1;
                })
                .catch((error) => {
                    console.log(error);
                    EventBus.$emit('ERROR', {
                        Message: 'Page follow failed',
                        Details: null,
                    });
                });
        },
        unfollow(page) {
            const subs = JSON.stringify({ ProjectID: page.id });
            axios({
                method: 'DELETE',
                url: '/project/follow',
                data: subs,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    EventBus.$emit('INFO', {
                        Message: 'Page unfollowed',
                        Details: null,
                    });

                    // update page in pages array
                    const index = this.feed.findIndex((p) => {
                        if (p.page) {
                            return p.page.id === page.id;
                        }
                        return false;
                    });
                    this.feed[index].page.followed = false;
                    this.feed[index].key += 1;
                })
                .catch((error) => {
                    console.log(error);
                    EventBus.$emit('ERROR', {
                        Message: 'Page unfollow failed',
                        Details: null,
                    });
                });
        },
        sendMessage(userObj: User) {
            if (!userObj || !userObj.id) return;
            const thread = {
                Participants: [this.$store.state.me.ID, userObj.id],
            };
            axios({
                method: 'POST',
                url: '/chat/t',
                withCredentials: true,
                data: JSON.stringify(thread),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.$router.push(`/messenger/${response.data.ID}`);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        scroll() {
            window.onscroll = () => {
                const distanceFromBottom = document.body.scrollHeight - window.innerHeight - window.scrollY;

                const percentage = (distanceFromBottom * 100) / (document.body.scrollHeight - window.innerHeight);

                if (percentage < 15) {
                    if (this.feedBank.length !== 0 && this.feedBank.length > 10) {
                        this.feed.push(...this.feedBank.slice(0, 10));
                        this.getOwners(this.feedBank.slice(0, 10));
                        this.feedBank = this.feedBank.slice(10);
                    } else if (this.feedBank.length !== 0 && this.feedBank.length <= 10) {
                        this.feed.push(...this.feedBank);
                        this.getOwners(this.feedBank);
                        this.feedBank = [];
                    }
                }
            };
        },
    },
};
