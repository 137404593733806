











import tippy, { roundArrow, hideAll } from 'tippy.js';
import 'tippy.js/dist/svg-arrow.css';

export default {
    name: 'CoTippy',
    props: {
        //pass options object according to the tippy.js documentation
        //docs: https://atomiks.github.io/tippyjs/
        tippyOptions: {
            type: Object,
            default() {
                return {};
            },
        },
        text: {
            type: String,
            default: null,
        },
        hideAllOnShow: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            id: null,
            tippyInstance: null,
            defaultStyles: this.$slots.content ? false : true,
            arrowElem: null,
        };
    },
    mounted() {
        this.id = `tippy${this._uid}`;
        const that = this;
        const options = {
            content: this.text || this.$refs.content,
            arrow: roundArrow,
            onShow(instance) {
                if (this.hideAllOnShow) {
                    hideAll({
                        duration: 0,
                        exclude: instance,
                    });
                }
                that.$emit('show', instance);
            },
            onShown(instance) {
                that.$emit('shown', instance);
            },
            onHide(instance) {
                that.$emit('hide', instance);
            },
            onHidden(instance) {
                that.$emit('hidden', instance);
            },
            appendTo: () => document.body, //make sure tippy is not cropped by parent elements
            duration: 0,
            theme: this.$slots.content ? 'custom' : 'default',
            ...this.tippyOptions,
        };
        this.$nextTick(() => {
            this.tippyInstance = tippy(`#${this.id}`, options)[0];
        });
    },
    beforeDestroy() {
        if (this.tippyInstance) {
            this.tippyInstance.destroy();
        }
    },
    methods: {
        show() {
            if (!this.tippyInstance) return;
            this.tippyInstance.show();
        },
        hide() {
            if (!this.tippyInstance) return;
            this.tippyInstance.hide();
        },
        hideAll() {
            if (!this.tippyInstance) return;
            this.tippyInstance.hide();
        },
    },
};
