




































































import { PropType } from 'vue';
import CoSkeleton from '@/components/Atoms/co-skeleton/CoSkeleton.vue';
import { User } from './models.ts';

import CoCard from '../co-card/CoCard.vue';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoThumbnail from '../co-thumbnail/CoThumbnail.vue';
import CoText from '../../Atoms/co-text/CoText.vue';
import CoMatch from '../co-match/CoMatch.vue';
import { size } from 'lodash';

export default {
    name: 'CoCardMember',
    components: {
        CoCard,
        CoHeadline,
        CoThumbnail,
        CoButton,
        CoText,
        CoSkeleton,
        CoMatch,
    },
    props: {
        member: {
            type: Object as PropType<User>,
            required: false,
            default: () => ({} as User),
        },
        hideMessageBtn: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        matchingOn: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            followedByUser: false,
            memberURL: `/profile/${this.member.slug}`,
        };
    },
    computed: {},
    methods: {
        randomPercents(from = 0, to = 100) {
            const percents = Math.floor(Math.random() * (to - from + 1) + from);
            return `${percents}%`;
        },
        view() {
            this.$router.push(`/profile/${this.member.slug}`);
        },
        message() {
            // emit event to parent
            this.$emit('message', this.member);
        },
        unfollow() {
            // emit event to parent
            // this.$emit('unfollow', this.page);
        },
    },
};
