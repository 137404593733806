<template>
    <div class="min-vh-100 py-4 d-flex bg justify-content-center align-items-md-center">
        <vue-headful
            :title="'Sign up: ' + this.$store.state.space.Name + ' Community Platform'"
            description=""
            :image="this.$store.state.space.LogoURL"
        />
        <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
            <div class="step-wrapper login-form p-4 bg-white round-unify text-left">
                <!-- Header Logo on all steps -->
                <div class="label-align mb-4 ml-0 w-100 text-center">
                    <img
                        v-if="this.$store.state.space.LogoURL"
                        :src="this.$store.state.space.LogoURL"
                        width="100"
                        height="auto"
                        :alt="this.$store.state.space.Name"
                        class="mt-2 mb-2"
                    />
                    <div v-else class="mt-2 mb-2">
                        <h2>{{ this.$store.state.space.Name }}</h2>
                    </div>
                </div>
                <!-- End Header Logo on all steps -->
                <div v-if="step == 1">
                    <div class="w-100 mt-4">
                        <div class="alert alert-danger bg-white text-orange" role="alert" v-if="error">
                            <p class="mb-0">{{ error.message }}</p>
                        </div>
                        <b-form ref="signupForm" @submit.prevent="signUp">
                            <b-form-group
                                id="input-group-0"
                                :label="$t('labels.salutation')"
                                label-for="input-0"
                                label-class="label-align"
                            >
                                <b-form-select
                                    id="input-0"
                                    class="form-control rounded-pill"
                                    v-model="signUpForm.Salutation"
                                    :options="SalutationOptions"
                                    :disabled="this.$store.state.invite && this.$store.state.invite.Salutation"
                                ></b-form-select>
                            </b-form-group>
                            <div class="d-flex">
                                <b-form-group
                                    id="input-group-1"
                                    :label="$t('labels.namefirst')"
                                    label-for="input-1"
                                    label-class="label-align"
                                    class="pr-1"
                                >
                                    <b-form-input
                                        id="input-1"
                                        v-model="signUpForm.FirstName"
                                        type="text"
                                        class="form-control rounded-pill"
                                        required
                                        :placeholder="$t('labels.namefirst')"
                                        :disabled="this.$store.state.invite && this.$store.state.invite.FirstName"
                                    ></b-form-input>
                                </b-form-group>

                                <b-form-group
                                    id="input-group-1"
                                    :label="$t('labels.namelast')"
                                    label-for="input-1"
                                    label-class="label-align"
                                    class="pl-1"
                                >
                                    <b-form-input
                                        id="input-1"
                                        v-model="signUpForm.LastName"
                                        type="text"
                                        class="form-control rounded-pill"
                                        required
                                        :placeholder="$t('labels.namelast')"
                                        :disabled="this.$store.state.invite && this.$store.state.invite.LastName"
                                    ></b-form-input>
                                </b-form-group>
                            </div>

                            <b-form-group
                                v-if="$unleash.isEnabled('ProfileBirthDate')"
                                id="input-group-1.3"
                                :label="$t('labels.birthdate')"
                                label-for="input-1.3"
                                label-class="label-align mb-0"
                                class="w-50"
                            >
                                <b-form-input
                                    id="input-1.3"
                                    v-model="signUpForm.BirthDate"
                                    type="date"
                                    class="form-control rounded-pill"
                                    required
                                    :max="maxDate"
                                ></b-form-input>
                            </b-form-group>

                            <b-form-group
                                id="input-group-2"
                                :label="$t('labels.email')"
                                label-for="input-2"
                                label-class="label-align"
                            >
                                <b-form-input
                                    id="input-2"
                                    v-model="signUpForm.Email"
                                    type="email"
                                    class="form-control rounded-pill"
                                    required
                                    :placeholder="$t('placeholders.email')"
                                    :disabled="this.$store.state.invite && this.$store.state.invite.Email"
                                ></b-form-input>
                            </b-form-group>

                            <b-form-group id="input-group-3">
                                <div class="mb-1">
                                    <span class="label-align">{{ $t('labels.password') }}</span
                                    ><span class="ml-2 clickable" @click="switchPasswordView"
                                        ><b-icon variant="primary" :icon="passwortViewIcon"></b-icon
                                    ></span>
                                </div>
                                <b-form-input
                                    id="input-3"
                                    v-model="signUpForm.Password"
                                    :type="passwortInputType"
                                    class="form-control rounded-pill"
                                    required
                                    :placeholder="$t('placeholders.password')"
                                    :formatter="checkLength"
                                ></b-form-input>
                            </b-form-group>

                            <!-- PrivacyPolicy agreement  -->
                            <b-form-checkbox
                                id="checkbox-3"
                                name="checkbox-3"
                                v-if="PrivacyPolicy"
                                v-model="signUpForm.AcceptPrivacyPolicy"
                                required
                            >
                                <i18n path="labels.acceptprivacy" tag="p" class="mb-0">
                                    <template>
                                        <a
                                            v-if="
                                                this.$store.state.space.PrivacyPolicyURL &&
                                                this.$store.state.space.PrivacyPolicyURL !=
                                                    'https://www.coapp.io/privacy-police' &&
                                                this.$store.state.space.PrivacyPolicyURL !=
                                                    'https://collaborates.io/datenschutz'
                                            "
                                            class="text-primary mb-0"
                                            :href="this.$store.state.space.PrivacyPolicyURL"
                                            target="_blank"
                                            :title="$t('labels.privacypolicy')"
                                            >{{ $t('labels.privacypolicy') }}</a
                                        >
                                        <a
                                            v-else
                                            class="text-primary mb-0"
                                            href="#"
                                            @click.stop="$bvModal.show('privacy-policy')"
                                            :title="$t('labels.privacypolicy')"
                                            >{{ $t('labels.privacypolicy') }}</a
                                        >
                                    </template>
                                </i18n>
                            </b-form-checkbox>
                            <!-- Terms And Conditions agreement  -->
                            <b-form-checkbox
                                id="checkbox-4"
                                name="checkbox-4"
                                class="mb-4"
                                v-if="TermsAndConditions"
                                v-model="signUpForm.AcceptTermsAndConditions"
                                required
                            >
                                <i18n path="labels.acceptterms" tag="p" class="mb-0">
                                    <template>
                                        <a
                                            class="text-primary mb-0"
                                            href="#"
                                            @click.stop="$bvModal.show('terms-and-conditions')"
                                            :title="$t('labels.termsandconditions')"
                                            >{{ $t('labels.termsandconditions') }}</a
                                        >
                                    </template>
                                </i18n>
                            </b-form-checkbox>
                            <div class="text-right">
                                <input
                                    :disabled="loading ? true : false"
                                    :value="loading ? $t('labels.loading') : $t('labels.signup')"
                                    class="btn btn-primary text-light"
                                    type="submit"
                                />
                            </div>
                        </b-form>
                    </div>
                </div>

                <div v-if="step == 3">
                    <div class="my-4 label-align">
                        <coHeadline :level="2" class="text-center pb-3">
                            {{ $t('messages.communityjoinsuccess', { space: this.$store.state.space.Name }) }}
                        </coHeadline>
                        <coAlert variant="grey" :text="$t('messages.emailverificationsent')"></coAlert>
                    </div>
                    <div class="d-flex">
                        <coButton
                            variant="primary"
                            :label="$t('labels.getstarted')"
                            class="mx-auto"
                            @click="$router.push('/')"
                        />
                    </div>
                </div>
            </div>
        </b-overlay>
        <!-- user login -->

        <!-- privacy policy modal-->
        <b-modal id="privacy-policy" hide-footer :title="$t('labels.privacypolicy')" size="xl">
            <div v-if="PrivacyPolicy" v-html="PrivacyPolicy.Content"></div>
        </b-modal>

        <!-- terms and conditions modal-->
        <b-modal id="terms-and-conditions" hide-footer :title="$t('labels.termsandconditions')" size="xl">
            <div v-if="TermsAndConditions" v-html="TermsAndConditions.Content"></div>
        </b-modal>
    </div>
</template>
<style lang="less" scoped>
// login view
.login-form {
    width: 100%;
    max-width: 500px;
}

input[type='text'].form-control::placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='text'].form-control::-webkit-input-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='text'].form-control:-moz-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='text'].form-control::-ms-input-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='text'].form-control::-moz-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='password'].form-control::placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='password'].form-control::-webkit-input-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='password'].form-control:-moz-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='password'].form-control::-ms-input-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='password'].form-control::-moz-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

.bg {
    background-color: #fff;
    @media (min-width: 768px) {
        background-color: #dedfde;
    }
}

.hfv-red-btn {
    margin-top: 35px;
}

.card-body {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
</style>

<script>
import smoothReflow from 'vue-smooth-reflow';
import axios from 'axios';
import Vuei18n from 'vue-i18n';
import { debounce } from 'lodash';
import EventBus from '@/eventBus';

export default {
    i18n: {
        messages: {
            en: {
                messages: {
                    communityjoinsuccess: 'Congratulations! You are now a part of this awesome community.',
                    emailverificationsent: 'Check your inbox, please. We have sent a link to verify your email.',
                    passwordlength: 'Password can not be longer than 56 characters',
                },
            },
            de: {
                messages: {
                    communityjoinsuccess: 'Herzlichen Glückwunsch! Du bist jetzt Teil dieser großartigen Community.',
                    emailverificationsent:
                        'Bitte schau in dein E-Mail-Postfach. Wir haben dir einen Bestätigungslink gesendet.',
                    passwordlength: 'Passwort darf nicht länger als 56 Zeichen sein',
                },
            },
        },
    },
    name: 'Register',
    mixins: [smoothReflow],
    data() {
        return {
            signUpForm: {
                Salutation: null,
                Name: '',
                Email: '',
                Password: '',
                BirthDate: '',
                Phone: '',
                SubscribeToNewsletter: false,
                AcceptPrivacyPolicy: false,
            },

            error: null,
            loading: false,
            step: 1,
            passwortInputType: 'password',
            passwortViewIcon: 'eye-slash',
            PrivacyPolicy: null,
            TermsAndConditions: null,
            maxDate: '',
        };
    },
    computed: {
        SalutationOptions() {
            return [
                { value: null, text: this.$t('placeholders.selectplease') },
                { value: 'm', text: this.$t('labels.salutationmale') },
                { value: 'f', text: this.$t('labels.salutationfemale') },
                { value: 'x', text: this.$t('labels.salutationnonbinary') },
            ];
        },
        state() {
            return this.$store.getters.state;
        },
        components() {
            return JSON.stringify(this.$options.components);
        },
    },
    created() {
        const today = new Date();
        const maxDate = new Date();
        maxDate.setFullYear(today.getFullYear() - 18);
        this.maxDate = maxDate.toISOString().slice(0, 10);
        this.getPrivacyPolicy();
        this.getTermsAndConditions();
        this.debouncedCheckLength = debounce(this.notifyPasswordLength, 500);

        // if invite data in store, use it
        if (this.$store.state.invite) {
            this.signUpForm.Email = this.$store.state.invite.Email;
            this.signUpForm.FirstName = this.$store.state.invite.FirstName;
            this.signUpForm.LastName = this.$store.state.invite.LastName;
            if (this.$store.state.invite.Salutation) {
                this.signUpForm.Salutation = this.$store.state.invite.Salutation;
            }
        }
    },
    mounted() {
        this.$smoothReflow({
            el: '.step-wrapper',
        });
        if (this.$route.query.step) {
            this.step = this.$route.query.step;
            if (this.step == 2 && this.$store.state.space.AuthBackend != 'collaborates') {
                this.getBillingUrl();
            } else if (this.step == 2) {
                this.step = 1;
            }
        }

        this.$nextTick(function () {
            window.setInterval(() => {
                if (this.step === 2) {
                    this.hasUserPaymentMethod();
                }
            }, 2000);
        });
    },
    methods: {
        signUp() {
            this.loading = true;
            this.signUpForm.Email = this.signUpForm.Email.toLowerCase();
            const inviteId = this.$route.query.invite;
            if (inviteId) {
                this.signUpForm.InviteID = inviteId;
            }
            const data = JSON.stringify(this.signUpForm);
            this.$store
                .dispatch('signup', data)
                .then((data) => {
                    this.$router.push('/signup-success');
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = error;
                });
        },
        switchPasswordView() {
            if (this.passwortInputType == 'password') {
                this.passwortInputType = 'text';
                this.passwortViewIcon = 'eye';
            } else {
                this.passwortInputType = 'password';
                this.passwortViewIcon = 'eye-slash';
            }
        },

        getPrivacyPolicy() {
            axios({
                method: 'GET',
                url: '/space/get-privacy-policy',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.PrivacyPolicy = response.data;
                    }
                })
                .catch((error) => {
                    if (
                        (!this.$store.state.space.PrivacyPolicyURL ||
                            this.$store.state.space.PrivacyPolicyURL === 'https://www.coapp.io/privacy-police') &&
                        !this.PrivacyPolicy
                    ) {
                        this.signUpForm.AcceptPrivacyPolicy = true;
                    }
                    console.log(error);
                });
        },

        getTermsAndConditions() {
            axios({
                method: 'GET',
                url: '/space/get-terms-and-conditions',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.TermsAndConditions = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        notifyPasswordLength() {
            EventBus.$emit(`ERROR`, {
                Message: this.$t('messages.passwordlength'),
            });
        },
        checkLength(value) {
            if (value.length > 56) {
                this.debouncedCheckLength();
                return value.substring(0, 56);
            }
            return value;
        },
    },
};
</script>
