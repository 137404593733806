































































import axios from 'axios';
import Vue from 'vue';
import VueIntercom from 'vue-intercom';
import CoThumbnail from '@/components/Molecules/co-thumbnail/CoThumbnail.vue';
import EventBus from '../../eventBus';

Vue.use(VueIntercom, { appId: 'kttwojyk' });

export default {
    name: 'CreatePost',
    components: { CoThumbnail },
    data() {
        return {
            post: {
                Text: '',
            },
            imageFiles: [],
            imageURLs: [],
            loading: false,
            isEditorEmpty: true,
            content: '',
            onFocus: false,
            links: [],
        };
    },

    watch: {
        imageURLs(newVal, oldVal) {
            if (newVal.length === this.imageFiles.length && this.loading) {
                this.createPost();
            }
        },
    },

    mounted() {
        if (this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin')) {
            this.$intercom.boot({
                user_id: this.$store.state.me.ID,
                name: this.$store.state.me.Profile.Name,
                email: this.$store.state.me.Email,
                spaceID: this.$store.state.space.ID,
                space: this.$store.state.space.Name,
                intro: this.$store.state.me.Intro,
                hide_default_launcher: this.$isMobile || this.$store.state.isInMobile, // hide if Vue.prototype.$isMobile flag is set
            });

            this.$intercom.hide();
        }
    },
    computed: {
        isVisible() {
            return this.$intercom.visible;
        },
    },
    beforeDestroy() {
        this.$intercom.shutdown();
    },
    methods: {
        setFocus() {
            this.$refs.editor.focus();
        },
        emitEventToIntercom(createdAt) {
            if (this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin')) {
                this.$intercom.trackEvent('created-post');
            }
        },

        uploadImages() {
            this.imageFiles.forEach(function (entry, index) {
                this.$store
                    .dispatch('imageUpload', {
                        file: entry.File,
                        url: '/upload/image/post',
                    })
                    .then((response) => {
                        this.imageURLs.push(response);
                    })
                    .catch((error) => {
                        this.loading = false;
                        const errorMsg = {
                            Message: 'Could not upload one or more images, please try again or report to team',
                            Details: error.response.data,
                        };
                        if (
                            error.response &&
                            error.response.data &&
                            error.response.data.message === 'Request Entity Too Large'
                        ) {
                            errorMsg.Message = 'The file you tried to upload is too large. Max 10 MB.';
                        }
                        EventBus.$emit('ERROR', errorMsg);
                    });
            }, this);
        },
        publishPost() {
            this.loading = true;

            if (this.imageFiles.length !== 0) {
                this.uploadImages();
            } else {
                this.createPost();
            }
        },
        cancelPost() {
            this.imageFiles = [];
            this.imageURLs = [];
            this.$refs.editor.clear(true, true);
            this.onFocus = false;
        },
        createPost() {
            this.post.Images = this.imageURLs;
            this.post.Text = this.content;
            this.post.Text = this.post.Text.replace(/<\/span><span/g, '</span> <span');
            this.post.Text = this.post.Text.replace(/<p><\/p>/g, '<br>');
            this.post.Version = '1';

            this.$store
                .dispatch('createPost', this.post)
                .then((response) => {
                    const msg = {
                        Message: this.$t('labels.postpublished'),
                        Details: '',
                    };
                    EventBus.$emit('INFO', msg);
                    const obj = {
                        object: JSON.parse(JSON.stringify(response)),
                        type: 'post',
                        score: response.CreatedAt,
                        createdBy: response.AuthorID,
                    };
                    this.$emit('created', obj);
                    this.loading = false;
                    this.imageFiles = [];
                    this.imageURLs = [];
                    this.$refs.editor.clear(true, true);
                    this.onFocus = false;
                    this.emitEventToIntercom(response.CreatedAt);
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response.data && error.response.data.message) {
                        EventBus.$emit('ERROR', {
                            Message: error.response.data.message,
                            Details: error.response.data.details,
                        });
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: 'Could not create post. Please try again later.',
                            Details: null,
                        });
                    }
                });
        },
    },
};
