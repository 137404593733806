






import ViewPage from '@/components/Templates/User/Channel/Page/View/ViewPage.vue';

export default {
    name: 'ViewPageWrapper',
    components: {
        ViewPage,
    },

    data() {
        return {
            slug: this.$route.params.slug,
            channelSlug: this.$route.params.channelSlug,
        };
    },
};
