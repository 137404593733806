































import Vue, { PropType } from 'vue';
import LazyLoadImg from '@/components/old-components/LazyLoadImg.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoHeading from '@/components/Atoms/co-heading/CoHeading.vue';
import CoPrice from '@/components/Atoms/co-price/coPrice.vue';
import { format } from 'date-fns';
import CoThumbnail from '../co-thumbnail/CoThumbnail.vue';

type Element = { text: string; id: string };

interface PlanData {
    Name: string;
    PriceInCents: number;
    ImageURL: string;
    Currency: string;
    TaxRate: {
        Percentage: number;
        DisplayName: string;
        Inclusive: boolean;
    };
    TimeInteval: number; // 0 for month, 1 for year 'TimeInteval' is a typo in the API response changing it would break the API and the frontend
    IntervalCount: number;
}

interface Extra {
    ID: string;
    Name: string;
    Description: string;
    PriceInCents: number;
    Currency: string;
    CreatedAt: number;
    UpdatedAt: number;
    Version: number;
    TaxRate: {
        ID: string;
        DisplayName: string;
        Inclusive: boolean;
        Percentage: number;
    };
}

interface Price {
    PriceInCents: number;
    Currency: string;
    TaxRate: number;
    TaxName: string;
    Inclusive: boolean;
}

export default Vue.extend({
    name: 'CoCardPlan',
    props: {
        plan: {
            type: Object as PropType<PlanData>,
            default: null,
        },

        showImage: {
            type: Boolean,
            default: false,
        },
        start: {
            type: Date,
            default: null,
        },
        end: {
            type: Date,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String,
            default: 'default',
            validator: (value: string) => ['default', 'grey'].includes(value),
        },

        // if true, the price will be shown in the card including the Extras
        showTotalPrice: {
            type: Boolean,
            default: false,
        },
        clickable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            extrasItems: [] as Price[] | null,
            planPrice: {} as Price | null,
        };
    },
    components: { CoPrice, CoHeading, CoText, CoCard, CoThumbnail },
    computed: {
        classes() {
            return {
                [this.variant]: true,
                disabled: this.disabled,
                clickable: this.clickable,
            };
        },
        unit() {
            if (!this.plan) return 'Month';

            if (this.plan.TimeInteval === 1) return 'Year';

            return 'Month';
        },
    },

    watch: {
        plan: {
            handler() {
                if (this.showTotalPrice && this.plan && this.plan.Extras) {
                    this.extrasItems = this.plan.Extras.map((extra: Extra) => {
                        const pln = {
                            PriceInCents: extra.PriceInCents,
                            Currency: extra.Currency,
                        } as any;

                        if (extra.TaxRate) {
                            pln.TaxRate = extra.TaxRate.Percentage;
                            pln.TaxName = extra.TaxRate.DisplayName;
                            pln.Inclusive = extra.TaxRate.Inclusive;
                        }

                        return pln;
                    });
                }
            },
            immediate: true,
        },
        showTotalPrice: {
            handler() {
                if (this.showTotalPrice && this.plan && this.plan.Extras) {
                    this.extrasItems = this.plan.Extras.map((extra: Extra) => {
                        const extr = {
                            PriceInCents: extra.PriceInCents,
                            Currency: extra.Currency,
                        } as any;

                        if (extra.TaxRate) {
                            extr.TaxRate = extra.TaxRate.Percentage;
                            extr.TaxName = extra.TaxRate.DisplayName;
                            extr.Inclusive = extra.TaxRate.Inclusive;
                        }
                        return extr;
                    });
                }
            },
            immediate: true,
        },
    },

    mounted() {
        this.planPrice = {
            PriceInCents: this.plan.PriceInCents,
            Currency: this.plan.Currency,
            Unit: this.unit,
        };

        if (this.plan.TaxRate) {
            this.planPrice.TaxRate = this.plan.TaxRate.Percentage;
            this.planPrice.TaxName = this.plan.TaxRate.DisplayName;
            this.planPrice.Inclusive = this.plan.TaxRate.Inclusive;
        }
        // next tick
        this.$nextTick(() => {
            if (this.showTotalPrice && this.plan && this.plan.Extras) {
                this.extrasItems = this.plan.Extras.map((extra: Extra) => {
                    const extr = {
                        PriceInCents: extra.PriceInCents,
                        Currency: extra.Currency,
                    } as any;

                    if (extra.TaxRate) {
                        extr.TaxRate = extra.TaxRate.Percentage;
                        extr.TaxName = extra.TaxRate.DisplayName;
                        extr.Inclusive = extra.TaxRate.Inclusive;
                    }
                    return extr;
                });
            }
        });

        // add the plan price to the priceItems array
    },
    methods: {
        clickEvent() {
            this.$emit('click', this.plan);
        },
        formatDate(date: Date): string {
            return format(date, 'dd/MM/yyyy');
        },
    },
});
