<template>
    <div>
        <!-- search and actions start -->
        <div class="px-sm-4 d-flex align-items-center justify-content-between">
            <span class="d-none d-xl-block"
                >Time passes allow your users to use a physical space for a time period</span
            >
            <b-button
                variant="primary"
                class="ml-auto"
                to="/admin/monetization/time-passes/create"
                :disabled="!$store.state.billingTurnedOn"
                >Create</b-button
            >
        </div>
        <!-- search and actions end -->

        <!-- plans start -->
        <div class="container-fluid p-0 m-0">
            <!-- if emtpy and not loading -->
            <div class="row text-center m-0 mt-5" v-if="timepasses.length === 0 && !loading">
                <div class="col">
                    <illustration type="projects" class="my-0 illu"></illustration>

                    <h2>Get started!</h2>
                    <div class="mb-4">Create a time pass for your community platform.</div>
                    <b-button
                        variant="primary"
                        to="/admin/monetization/time-passes/create"
                        :disabled="!$store.state.billingTurnedOn"
                        >create time pass</b-button
                    >
                </div>
            </div>
            <!-- if loading -->
            <div class="row text-center m-0 mt-5" v-else-if="timepasses.length === 0 && loading">
                <div class="col">
                    <b-spinner variant="primary" label="loading"></b-spinner>
                </div>
            </div>
            <div class="m-0 mt-4 px-sm-4">
                <b-alert show variant="danger" v-if="!$store.state.billingTurnedOn"
                    >Before creating Time passes connect Stripe and create at least one Tax rate</b-alert
                >
            </div>
            <div class="row m-0 w-100">
                <div class="col w-100 p-1 pr-md-1">
                    <div class="w-100" v-for="item in timepasses" :key="item.ID">
                        <TimepassCardAdmin id="SettingsCardPlan" :timepass="item" @deleted="removeTimePass">
                        </TimepassCardAdmin>
                    </div>
                </div>
            </div>
        </div>
        <!-- plans end -->
    </div>
</template>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

/deep/ .nav-full {
    border-bottom: 1.5px solid rgb(190, 190, 190);
}

/deep/ .nav-full a {
    background-color: transparent !important;
    color: black !important;
    text-decoration: none;
}

/deep/ .nav-tabs .nav-item {
    line-height: 0em !important;
}

/deep/ .nav-tabs .nav-item a {
    text-align: left !important;
}

/deep/ .nav-full .active {
    background-color: transparent !important;
    color: @purple !important;
    border-bottom: 1.5px solid @purple !important;
}

/deep/ .not-activeOne {
    background-color: transparent !important;
    color: black !important;
}

.bg-white {
    background-color: #ffffff;
}

.red-text {
    color: red;
}

.bg-white {
    background-color: #ffffff;
}

.view-switcher .btn {
    background-color: white;
    &.active {
        background-color: @purple;
        color: #ffffff;
    }
}

.search-bar-wrapper {
    max-width: 256px;
    @media (max-width: 768px) {
        max-width: 128px;
    }
}
</style>
<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<script>
import axios from 'axios';
import Vue from 'vue';
import { VueTagsInput } from '@johmun/vue-tags-input';
import EventBus from '@/eventBus';
import { McWysiwyg } from '@mycure/vue-wysiwyg';

export default {
    name: 'ListTimepasses',
    components: {},
    data() {
        return {
            form: {},
            tabIndex: 0,
            loading: false,
            timepasses: [],

            price: 0,
        };
    },
    mounted() {
        this.getTimePasses();
    },
    computed: {
        me() {
            return this.$store.state.me;
        },
    },
    methods: {
        removeTimePass(id) {
            this.timepasses = this.timepasses.filter((item) => item.ID !== id);
        },
        getTimePasses() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/time-pass/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        response.data.Passes.forEach((element) => {
                            element.Description = `Valid for ${element.Duration}`;
                            if (element.Duration > 1) {
                                element.Description += ' hours';
                            } else {
                                element.Description += ' hour';
                            }
                            this.timepasses.push(element);
                        });
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
    },
};
</script>
