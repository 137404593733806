<template>
    <b-form-group
        id="input-group-price"
        label="Price per Hour"
        label-for="input-price-per-hour"
        aria-describedby="price-description"
        label-class="label-align"
    >
        <CoFormInput
            :id="IdInput"
            :price="PricePerHour"
            type="number"
            @updated="handleChange"
            number
            step="0.01"
            :disabled="disabled"
        ></CoFormInput>
        <CoFormText :id="IdText" class="ml-3" :Text="Text"></CoFormText>
    </b-form-group>
</template>

<script>
import CoFormInput from '../../Atoms/co-form-input/CoFormInput.vue';
import CoFormText from '../../Atoms/co-form-text/CoFormText.vue';

export default {
    name: 'CoPriceFormGroup',
    components: {
        CoFormInput,
        CoFormText,
    },
    props: {
        IdInput: {
            type: String,
            default: '',
        },
        IdText: {
            type: String,
            default: '',
        },
        PricePerHour: {
            type: Number,
            default: 0,
        },
        Text: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleChange(value) {
            console.log(`Changed value: ${value}`);
            this.$emit('change', value);
        },
    },
};
</script>
