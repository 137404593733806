<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <div>
        <div class="container-fluid px-0 px-sm-4 m-0 pt-2">
            <div class="row m-0">
                <div class="col-lg p-0 pr-md-1">
                    <SettingsCard
                        id="SpaceAccessMenuIdentitiesCard"
                        title="Identities"
                        subtitle="Manage member space access"
                        to="/admin/access/identity"
                    >
                    </SettingsCard>
                    <SettingsCard
                        id="SpaceAccessMenuPoliciesCard"
                        title="Policies"
                        subtitle="Configure space access policies (user groups)"
                        to="/admin/access/policy"
                    >
                    </SettingsCard>
                </div>
                <div class="col-lg p-0 pl-md-1">
                    <SettingsCard
                        id="SpaceAccessMenuTagsCard"
                        title="Tags"
                        subtitle="Manage space access tags (transponder cards)"
                        to="/admin/access/tag"
                    >
                    </SettingsCard>
                    <SettingsCard
                        id="SpaceAccessMenuLocksCard"
                        title="Locks"
                        subtitle="Manage space locks"
                        to="/admin/access/lock"
                    >
                    </SettingsCard>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccessMenu',
    data() {
        return {
            space: this.$store.state.space,
        };
    },
    mounted() {},
};
</script>
