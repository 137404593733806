
















import Vue from 'vue';
import CoIcon from '../co-icon/CoIcon.vue';

export default Vue.extend({
    name: 'CoRoundButton',
    components: {
        CoIcon,
    },
    props: {
        variant: {
            type: String,
            default: 'primary',
            required: false,
            validator(value) {
                return (
                    [
                        'primary',
                        'secondary',
                        'secondary-light',
                        'warning',
                        'danger',
                        'success',
                        'light',
                        'dark',
                        'icon',
                        'outline',
                    ].indexOf(value) !== -1
                );
            },
        },
        to: {
            type: String,
            required: false,
            default: '',
        },

        badge: {
            type: Boolean,
            required: false,
            default: false,
        },

        icon: {
            type: String,
            required: false,
            default: 'bell',
        },

        label: {
            type: String,
            required: false,
            default: '',
        },
        iconSize: {
            type: String,
            required: false,
            default: '16px',
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    // emits: ['click'],

    data() {
        return {
            // isHovered: false
        };
    },

    methods: {
        click(event) {
            this.$emit('click', event);

            if (this.to) {
                this.$router.push(this.to);
            }
        },
    },
});
