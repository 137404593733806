<style lang="less" scoped>
h3 {
    font-weight: bold;
}
</style>

<template>
    <div class="">
        <!-- header start -->
        <Header :title="$t('labels.billing')" icon="credit-card"></Header>
        <!-- header end -->
        <div class="">
            <div class="row m-0">
                <div class="col-lg p-0 pr-md-1">
                    <SettingsCard
                        id="Invoices"
                        :title="$t('labels.invoices')"
                        :subtitle="$t('invoicessubtitle')"
                        to="/account/billing/invoices"
                    >
                    </SettingsCard>
                </div>
                <div class="col-lg p-0 pr-md-1">
                    <SettingsCard
                        id="Payment Methods"
                        :title="$t('labels.paymentmethods')"
                        :subtitle="$t('paymentmethodsubtitle')"
                        to="/account/billing/payment-methods"
                    >
                    </SettingsCard>
                </div>
            </div>
        </div>

        <!-- modals -->
        <b-modal id="create_new_payment_method" title="Add payment method" centered>
            <div id="message" class="text-center">
                <!-- Display status message to customers here -->
            </div>
            <form id="payment-form">
                <div id="payment-element">
                    <!-- Elements will create form elements here -->
                </div>
                <div id="error-message">
                    <!-- Display error message to customers here -->
                </div>
            </form>
        </b-modal>
        <!-- modals -->
    </div>
</template>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';
import i18n from 'vue-i18n';

export default {
    i18n: {
        messages: {
            en: {
                invoicessubtitle: 'View upcoming and past invoices',
                paymentmethodsubtitle: 'View and manage your payment methods',
            },
            de: {
                invoicessubtitle: 'Sieh dir zukünftige und vergangene Rechnungen an',
                paymentmethodsubtitle: 'Verwalte deine Zahlungsmethoden',
            },
        },
    },
    name: 'Billing',
    data() {
        return {
            paymentMethods: [],
            loading: false,
            errorMsg: null,

            publicKey: '',
            accountID: '',

            closeAfterSuccess: true,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.loading = true;
            this.$store
                .dispatch('getPaymentMethods')
                .then((paymentMethods) => {
                    this.loading = false;
                    this.paymentMethods = [];
                    this.paymentMethods = paymentMethods;
                    if (this.paymentMethods === null || this.paymentMethods.length === 0) {
                        this.errorMsg = "You don't have any payment method.";
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    console.error(error);
                    if (error.response && error.response.status && error.response.status === 404) {
                        this.errorMsg = "You don't have any payment method.";
                    }
                });
        },
        getPublicKey() {
            axios({
                method: 'GET',
                url: '/user/account/billing/public-key',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.publicKey = response.data.Key;
                        this.accountID = response.data.AccountID;
                        this.billingTurnedOn = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        getClientSecret() {
            axios({
                method: 'GET',
                url: '/user/account/billing/add-payment-method',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.publicKey = response.data.Key;
                        this.accountID = response.data.AccountID;
                        this.billingTurnedOn = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>
