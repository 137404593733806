<style type="text/css" scoped>
iframe {
    border: 0;
    width: 100%;
    height: calc(100vh - 150px);
    overflow: scroll;
}
</style>

<template>
    <div id="content">
        <b-overlay :show="!dashboardURL" class="px-sm-4">
            <div class="b-overlay-wrap position-relative" style="background: white; border-radius: 0.8rem">
                <iframe :src="dashboardURL" style="padding-top: 0.8rem; padding-bottom: 0.8rem"></iframe>
            </div>
        </b-overlay>
    </div>
</template>

<script>
export default {
    name: 'AdminDashboard',
    data() {
        return {
            dashboardURL: null,
        };
    },
    mounted() {
        this.$store.dispatch('getDashboardURL', 1).then((url) => {
            if (url === null || typeof url !== 'string') {
                return;
            }
            this.dashboardURL = url
                .replace('titled=false', 'titled=true') // add title
                .replace('bordered=true', 'bordered=false'); // remove border
        });
    },
};
</script>
