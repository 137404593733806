






import axios from 'axios';
import CoPost from '../../../Organisms/co-post/CoPost.vue';
import CoLoadingIndicator from '../../../Atoms/co-loading-indicator/coLoadingIndicator.vue';

export default {
    name: 'Feed',
    components: {
        CoPost,
        CoLoadingIndicator,
    },
    data() {
        return {
            feed: [],
            feedNextPage: null,
            loading: true,
        };
    },
    created() {
        window.addEventListener('scroll', this.scroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.scroll);
    },
    mounted() {
        this.getFeed();
    },
    computed: {},
    methods: {
        getFeed(offset) {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/dashboard/feed/',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.feed = response.data;
                })
                .catch((error) => {
                    this.error = JSON.stringify(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
