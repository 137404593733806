import { render, staticRenderFns } from "./CoChannelEdit.vue?vue&type=template&id=238683cd&scoped=true&"
import script from "./CoChannelEdit.vue?vue&type=script&lang=ts&"
export * from "./CoChannelEdit.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "238683cd",
  null
  
)

export default component.exports