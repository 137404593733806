<!-- dropdown template -->
<template>
    <div></div>
</template>
<script>
// get click outside event
import { directive as onClickaway } from 'vue-clickaway';
import EventBus from '@/eventBus';
import Vue from 'vue';

export default {
    name: 'NotificationsCenter',
    data() {
        return {
            dd: false,
            allNotification: false,
            successSVG:
                '<svg data-v-3d554246="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="check2 circle" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-check2-circle notif-svg"><g data-v-3d554246=""><path fill-rule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"></path><path fill-rule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z"></path></g></svg>',
            errorSVG:
                '<svg data-v-3d554246="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="x circle" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-x-circle notif-svg"><g data-v-3d554246=""><path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"></path><path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"></path></g></svg>',
        };
    },
    props: [],
    directives: {
        onClickaway,
    },
    mounted() {
        EventBus.$on(
            'ERROR',
            (payLoad) => {
                this.$notify({
                    group: 'app',
                    type: 'error',
                    duration: 5000,
                    // title: 'Error!',
                    text: this.createMarkup(payLoad.Message, this.errorSVG),
                });
            },
            this
        );
        EventBus.$on(
            'INFO',
            (payLoad) => {
                this.$notify({
                    group: 'app',
                    type: 'success',
                    duration: 5000,
                    // title: 'Success!',
                    text: this.createMarkup(payLoad.Message, this.successSVG),
                });
            },
            this
        );
    },
    methods: {
        createMarkup(text, svg) {
            return `<div class="d-flex align-items-center">
                <div class="ml-1 mr-2">${svg}</div>
                <div>${text}</div>
              </div>`;
        },
        // compile dynamic asset reference
        toggle() {
            this.dd = !this.dd;
        },
        hide() {
            this.dd = false;
        },
    },
};
</script>
