<template>
    <div class="">
        <!-- header start -->
        <div class="d-flex align-items-center justify-content-between p-3 mb-3">
            <CoHeadline :level="2" class="">{{ $t('labels.paymentmethodadd') }}</CoHeadline>
        </div>
        <!-- search and actions end -->
        <!-- payment method -->
        <div class="round-unify-xs bg-white p-3">
            <!-- if loading -->
            <div class="row text-center m-0 mt-5" v-if="loading">
                <div class="col">
                    <b-spinner variant="primary" label="loading"></b-spinner>
                </div>
            </div>
            <div class="m-0 mt-4">
                <div id="message" class="text-center">
                    <!-- Display status message to customers here -->
                </div>
                <form id="payment-form">
                    <div id="payment-element">
                        <!-- Elements will create form elements here -->
                    </div>
                    <div id="error-message">
                        <!-- Display error message to customers here -->
                    </div>
                </form>
            </div>

            <!-- footer -->
            <div class="mt-2 d-flex justify-content-between w-100">
                <b-button variant="outline-primary" @click="back">{{ $t('labels.back') }}</b-button>

                <b-button variant="primary" @click="confirmSetup">{{ $t('labels.save') }}</b-button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

let elements = null;

export default {
    name: 'AddPaymentMethod',
    data() {
        return {
            loading: false,
            errorMsg: null,

            publicKey: '',
            accountID: '',

            clientSecret: '',
            customerID: '',

            paymentElement: null,

            closeAfterSuccess: this.$route.query.closeAfterSuccess,
        };
    },
    created() {
        this.loading = true;
        this.getPublicKey();
    },
    methods: {
        back() {
            // if has history
            if (window.history.length > 2) {
                this.$router.go(-1);
            } else {
                this.$router.push('/account/billing');
            }
        },
        async confirmSetup() {
            let redirectUlr = `https://${this.$store.state.space.DomainName}/account/billing/add-payment-method?closeAfterSuccess=${this.closeAfterSuccess}`;

            const { error } = await this.stripe.confirmSetup({
                elements,
                confirmParams: {
                    return_url: redirectUlr,
                },
            });

            if (error) {
                // This point will only be reached if there is an immediate error when
                // confirming the payment. Show error to your customer (e.g., payment
                // details incomplete)
                const messageContainer = document.querySelector('#error-message');
                messageContainer.textContent = error.message;
            } else {
                // Your customer will be redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer will be redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
            }
        },
        getPublicKey() {
            axios({
                method: 'GET',
                url: '/user/account/billing/public-key',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.publicKey = response.data.Key;
                        this.accountID = response.data.AccountID;
                        this.stripe = Stripe(this.publicKey, {
                            stripeAccount: this.accountID,
                        });

                        const { setup_intent_client_secret } = this.$route.query;
                        if (setup_intent_client_secret) {
                            this.checkSetupIntentStatus(setup_intent_client_secret);
                        } else {
                            this.getClientSecretForNewMethod();
                        }
                    }
                })
                .catch((error) => {});
        },
        getClientSecretForNewMethod() {
            axios({
                method: 'GET',
                url: '/user/account/billing/add-payment-method',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.customerID = response.data.CustomerID;
                        this.clientSecret = response.data.Secret;
                        this.initPaymentView(this.clientSecret);
                    }
                })
                .catch((error) => {});
        },
        initPaymentView(secret) {
            const options = {
                clientSecret: secret,
                // Fully customizable with appearance API.
                appearance: {
                    /* ... */
                },
            };

            // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
            elements = this.stripe.elements(options);
            // Create and mount the Payment Element
            this.paymentElement = elements.create('payment');
            this.paymentElement.mount('#payment-element');

            const that = this;
            this.paymentElement.on('ready', (event) => {
                // Handle ready event
                that.loading = false;
            });
        },
        checkSetupIntentStatus(clientSecret) {
            this.loading = true;
            // Retrieve the "setup_intent_client_secret" query parameter appended to
            // your return_url by Stripe.js
            // const clientSecret = new URLSearchParams(window.location.search).get(
            //   'setup_intent_client_secret'
            // );

            // Retrieve the SetupIntent
            this.stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
                const message = document.querySelector('#message');

                // Inspect the SetupIntent `status` to indicate the status of the payment
                // to your customer.
                //
                // Some payment methods will [immediately succeed or fail][0] upon
                // confirmation, while others will first enter a `processing` state.
                //
                // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
                switch (setupIntent.status) {
                    case 'succeeded': {
                        message.innerText = 'Success! Your payment method has been saved.';
                        this.setBillingDetails({
                            CustomerID: this.customerID,
                            PaymentMethodID: setupIntent.payment_method,
                        });
                        this.loading = false;
                        if (this.closeAfterSuccess) {
                            window.close();
                        }
                        this.$router.push('/account/billing');
                        break;
                    }

                    case 'processing': {
                        message.innerText = "Processing payment details. We'll update you when processing is complete.";
                        this.loading = false;
                        setTimeout(() => {
                            this.checkSetupIntentStatus(clientSecret);
                        }, 100);
                        break;
                    }

                    case 'requires_payment_method': {
                        if (setupIntent.payment_method) {
                            message.innerText = 'Failed to process payment details. Please try another payment method.';

                            // Redirect your user back to your payment page to attempt collecting
                            // payment again
                        }
                        this.initPaymentView(clientSecret);

                        break;
                    }
                }
            });
        },
        setBillingDetails(data) {
            axios({
                method: 'POST',
                url: '/user/account/billing/payment-method/details',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            }).catch((error) => {
                console.error(error);
            });
        },
    },
};
</script>
