<template>
    <b-overlay :show="loading">
        <div class="container mt-4">
            <div class="d-flex align-items-center ml-3">
                <b-icon icon="tag" class="ml-2"></b-icon>
                <h2 class="ml-1">Buy Time Pass</h2>
            </div>
            <div v-if="step == 1">
                <BuyPassDetails :timepass="timepass" :taxRate="taxRate" @nextStep="next"></BuyPassDetails>
            </div>
            <div v-if="step == 2">
                <BuyPassReview
                    :timepass="timepass"
                    :taxRate="taxRate"
                    @buyPass="checkout()"
                    @prevStep="back()"
                ></BuyPassReview>
            </div>
        </div>
    </b-overlay>
</template>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';

export default {
    name: 'BuyPassProcess',
    data() {
        return {
            timepass: {},
            step: 0,
            me: this.$store.state.me,
            loading: false,
            taxRate: {},
        };
    },
    created() {
        const { ID } = this.$route.params;

        this.loading = true;
        axios({
            method: 'GET',
            url: `user/time-pass/${ID}`,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response && response.data) {
                    this.timepass = response.data;
                    this.step = 1;
                    this.loading = false;
                }
            })
            .catch((error) => {
                console.log(error);
                this.loading = false;
            });
    },
    mounted() {},
    methods: {
        next() {
            this.step += 1;
        },
        back() {
            if (this.step > 1) {
                this.step -= 1;
            } else {
                this.$router.push('/account/time-passes');
            }
        },
        getTaxRate(taxID) {
            axios({
                method: 'GET',
                url: `/space/tax/${taxID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.taxRate = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        checkout() {
            this.loading = true;
            axios({
                method: 'POST',
                url: 'me/buy-timepass',
                withCredentials: true,
                data: JSON.stringify(this.timepass),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Successfully bought Time Pass!',
                        Details: '',
                    };
                    EventBus.$emit('INFO', msg);
                    this.$router.push(`/account/time-passes`);
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to buy Time Pass',
                        Details: error,
                    };
                    EventBus.$emit('ERROR', msg);
                    console.log(error);
                });
        },
    },
};
</script>
