<template>
    <!-- <b-overlay show="true"> -->
    <b-overlay :show="!this.$store.state.socket.isConnected || this.thread == null">
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
                <div class="mt-3 text-center">{{ $t('connecting') }}</div>
                <small>{{ $t('connectinfo') }}</small>
            </div>
        </template>
        <div class="chat-wrapper d-flex flex-column" v-if="thread">
            <div class="d-flex p-2 align-items-center border-bottom">
                <div class="d-block d-sm-none">
                    <b-button @click="back()" class="ml-0 mr-2 burger-menu-btn btn-round">
                        <b-icon icon="arrow-left"></b-icon>
                    </b-button>
                </div>
                <div class="flex-grow-1 d-flex align-items-center justify-content">
                    <div class="">
                        <ProfileCircle
                            v-if="!thread.GroupChat && thread.Peer"
                            :ImageURL="thread.Peer.Profile.ImageURL"
                            :Slug="thread.Peer.Slug"
                        />
                        <ProfileCircle v-else-if="thread.GroupChat" :ImageURL="thread.ImageURL" />
                        <ProfileCircle v-else />
                    </div>
                    <div class="ml-3" v-if="!thread.GroupChat && thread.Peer">
                        <span>{{ thread.Peer.Profile.Name }}</span>
                        <!-- <small class="d-block text-body mt-1">Last message here....</small> -->
                    </div>
                    <div class="ml-3" v-else-if="thread.GroupChat">
                        <span>{{ thread.Title }}</span>
                        <small class="d-block text-body mt-1"
                            >{{ thread.Peers.length }} {{ $tc('labels.member', thread.Peers.length) }}</small
                        >
                    </div>
                    <div class="ml-3" v-else>
                        <span>{{ $t('messages.usernotexists') }}</span>
                    </div>
                </div>
                <div>
                    <b-icon
                        v-if="thread.GroupChat && $store.state.me.ID === thread.InitiatorID"
                        icon="gear"
                        class="pointer mr-2"
                        variant="primary"
                        scale="1.5"
                        @click="edit"
                    ></b-icon>
                </div>
            </div>
            <div class="flex-grow-1 chat px-2">
                <div :key="chatRenderKey" class="mt-3">
                    <Message
                        v-for="(message, index) in currentThreadMessages"
                        :key="message.id + message.status"
                        :message="message"
                        :author="participants[message.sender]"
                        :threadID="threadID"
                        :showAvatar="message.showProfile"
                        :isGroup="thread.GroupChat ? true : false"
                        @retry="send(index)"
                        v-observe-visibility="
                            (isVisible, entry) =>
                                markAsSeen(isVisible, entry, message.id, message.own, message['status'])
                        "
                    ></Message>
                </div>
            </div>
            <div class="p-3 bg-white round-unify-2">
                <div v-if="thread && !thread.GroupChat && thread.Peer && notificationsPaused" class="mb-2">
                    <small>{{ $t('hasnotificationsoff', { username: thread.Peer.Profile.Name }) }}</small>
                </div>
                <Editor
                    id="textarea-default"
                    :placeholder="$t('placeholders.messagecreate')"
                    taclass="m-0 comment-input"
                    ref="editor"
                    rows="5"
                    :withSendAction="true"
                    :withMentions="false"
                    :withHashtags="false"
                    v-model="inputText"
                    @action-send="createMessage"
                    :expanded="true"
                ></Editor>
            </div>
        </div>
    </b-overlay>
</template>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.chat-wrapper {
    height: calc(100vh - 186px);
    overflow: auto;

    @media (max-width: 768px) {
        max-height: calc(100vh - 180px);
        height: calc(100vh - 180px);
    }
}

.chat {
    overflow: auto;
}
</style>

<script>
import Vue from 'vue';
import moment from 'moment';
import axios from 'axios';
import VueObserveVisibility from 'vue-observe-visibility';
import linkify from 'vue-linkify';
import i18n from 'vue-i18n';
import EventBus from '../../eventBus';

Vue.directive('linkified', linkify);

Vue.use(VueObserveVisibility);

export default {
    i18n: {
        messages: {
            en: {
                connecting: 'Connecting',
                connectinfo: 'Refresh this page if it takes longer than a couple of seconds',
                hasnotificationsoff: '{username} has notifications turned off',
                senderror: 'Sending failed! Tap to retry.',
                sending: 'sending ...',
                sent: 'sent',
            },
            de: {
                connecting: 'Verbinde',
                connectinfo: 'Lade diese Seite neu wenn es länger als einige Sekunden dauert.',
                hasnotificationsoff: '{username} hat Benachrichtigungen deaktiviert',
                senderror: 'Senden fehlgeschlagen! Klicken zum erneuten Senden',
                sending: 'senden ...',
                sent: 'gesendet',
            },
        },
    },
    name: 'Chat',

    data() {
        return {
            loading: false,
            notificationsPaused: false,
            threadID: this.$route.params.slug,
            thread: null,
            participants: {},
            currentThreadUsers: [],
            chatRenderKey: 1,
            currentThreadMessages: [
                // {
                //   id: "1942",
                //   date: moment("2021-02-13 09:30"),
                //   text: "This is a test",
                //   sender: "",
                //   receiver: "",
                // },
            ],

            inputText: '',
        };
    },
    watch: {
        $route(to, from) {
            const lastThreadID = from.params.slug;
            EventBus.$off(`MESSAGE_${lastThreadID}`);

            this.threadID = to.params.slug;

            EventBus.$on(
                `MESSAGE_${this.threadID}`,
                (payLoad) => {
                    this.onMessage(payLoad);
                },
                this
            );

            this.getThreadByID(this.threadID);
        },
    },

    mounted() {
        this.getThreadByID(this.threadID);

        EventBus.$on(
            `MESSAGE_${this.threadID}`,
            (payLoad) => {
                this.onMessage(payLoad);
            },
            this
        );

        EventBus.$on(
            'THREAD_UPDATED',
            (payLoad) => {
                this.getThreadByID(this.threadID);
            },
            this
        );
    },
    beforeDestroy() {
        EventBus.$off(`MESSAGE_${this.threadID}`);
        EventBus.$off('THREAD_UPDATED');
    },
    methods: {
        checkIfNotificationsPaused(peer) {
            if (!this.thread.GroupChat && this.thread.Peer) {
                let now = new Date();
                now /= 1000;
                if (now < this.thread.Peer.NotificationsOffUntill) {
                    this.notificationsPaused = true;
                    if (this.thread.Peer.NotificationsOffMessage) {
                        const now = moment();

                        const msg = [
                            {
                                id: Math.random(),
                                date: now,
                                text: `<p><small>${this.$t('labels.awaynotice')}</small></p>${
                                    this.thread.Peer.NotificationsOffMessage
                                }`,
                                sender: this.thread.Peer.ID,
                                receiver: this.$store.state.me.ID,
                            },
                        ];

                        this.currentThreadMessages.unshift(...this.addDayDataV2(msg));
                    }
                } else {
                    this.notificationsPaused = false;
                }
            }
        },

        edit() {
            this.$router.push(`/messenger/edit/${this.threadID}`);
        },
        markAsSeen(visablity, entry, id, own, status) {
            if (!visablity || own || status === 'seen') {
                return;
            }
            const ids = JSON.stringify({
                IDs: [id],
            });
            axios({
                method: 'POST',
                url: '/chat/msgs/seen',
                withCredentials: true,
                data: ids,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.$store.commit('DECREMENT_UNSEEN_MESSAGES', this.threadID);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        back() {
            this.$router.push('/messenger');
        },
        onMessage(msg) {
            let messageObject = {
                id: msg.id,
                date: moment.unix(msg.timestamp),
                text: msg.text,
                sender: msg.from,
                receiver: msg.to,
            };

            messageObject = this.addDayDataV2([messageObject])[0];

            this.currentThreadMessages.push(messageObject);
            this.chatRenderKey++;
            setTimeout(() => {
                if (document.querySelector('.message-w:last-child') != null) {
                    document.querySelector('.message-w:last-child').scrollIntoView(false);
                }
            }, 200);
        },

        addDayDataV2(messages) {
            // this function adds a boolean to each message. The boolean "isNewDay" is true, when this message is on a different day then the previous one. If that is the case, we display the new day to the user.
            let dayOfLastMessage = moment('2021-01-01');
            if (this.currentThreadMessages && this.currentThreadMessages.length > 0) {
                dayOfLastMessage = moment.unix(
                    this.currentThreadMessages[this.currentThreadMessages.length - 1].date / 1000
                );
            }

            messages.forEach((element, index) => {
                const isSame = dayOfLastMessage.isSame(element.date, 'day');
                element.isNewDay = !isSame;
                dayOfLastMessage = element.date;
            }, dayOfLastMessage);

            for (let index = 0; index < messages.length; index++) {
                const element = messages[index];
                if (index === 0) {
                    messages[index].showProfile = true;
                } else if (
                    index === 0 &&
                    this.currentThreadMessages.length > 0 &&
                    this.currentThreadMessages[this.currentThreadMessages.length - 1].sender !== element.sender
                ) {
                    messages[index].showProfile = true;
                } else if (index !== 0 && messages[index - 1].sender !== element.sender) {
                    messages[index].showProfile = true;
                } else if (element.isNewDay) {
                    messages[index].showProfile = true;
                } else {
                    messages[index].showProfile = false;
                }
            }
            // this.chatRenderKey++;
            return messages;
        },
        createMessage(event) {
            if (this.inputText === '<p></p>') return;

            if (event) event.preventDefault();

            const inText = this.inputText.replace(/<p><\/p>/g, '<br>');

            let messageObject = {
                id: Math.random(),
                date: moment(),
                text: inText,
                sender: this.$store.state.me.ID,
                status: 'to be sent',
                own: true,
            };

            messageObject = this.addDayDataV2([messageObject])[0];

            if (!this.thread.GroupChat) {
                messageObject.receiver = this.thread.Peer.ID;
            }
            this.currentThreadMessages.push(messageObject);
            this.send(this.currentThreadMessages.length - 1);
            this.$refs.editor.clear(true, true);
            this.$refs.editor.focus();
            setTimeout(() => {
                if (document.querySelector('.message-w:last-child') != null) {
                    document.querySelector('.message-w:last-child').scrollIntoView(false);
                }
            }, 200);
        },
        send(index) {
            this.currentThreadMessages[index].status = this.$t('sending');
            this.currentThreadMessages[index].sendingFailed = false;
            const message = this.currentThreadMessages[index];
            const msg = {
                From: this.$store.state.me.ID,
                ThreadID: this.threadID,
                Text: message.text,
                SpaceID: this.$store.state.space.ID,
            };

            axios({
                method: 'POST',
                url: '/chat/msgs/send',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: msg,
            })
                .then((response) => {
                    setTimeout(() => {
                        this.currentThreadMessages[index].status = this.$t('sent');
                    }, 300);
                })
                .catch((error) => {
                    console.log(error);
                    setTimeout(() => {
                        this.currentThreadMessages[index].status = this.$t('senderror');
                        this.currentThreadMessages[index].sendingFailed = true;
                    }, 1000);
                });
        },

        loadMesssags(url) {
            if (url == null) {
                url = `/chat/msgs/list/${this.threadID}`;
            }
            axios({
                method: 'GET',
                url,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data != null && response.data.messages != null) {
                        const messages = [];
                        response.data.messages.forEach((msg) => {
                            // if sender is not in participants (removed) then add  manually, for history purpose
                            if (this.participants[msg.From] == null) {
                                this.getAuthor(msg.From);
                            }
                            const messageObject = {
                                status: msg.Status === 'sent' ? this.$t('sent') : msg.Status,
                                id: msg.ID,
                                date: moment.unix(msg.Timestamp),
                                text: msg.Text,
                                sender: msg.From,
                                receiver: msg.To,
                            };
                            messages.unshift(messageObject);
                        });
                        // if (this.messages != null) {
                        this.currentThreadMessages.unshift(...this.addDayDataV2(messages));
                        // }
                        setTimeout(() => {
                            if (document.querySelector('.message-w:last-child') != null) {
                                document.querySelector('.message-w:last-child').scrollIntoView(false);
                            }
                        }, 1);
                        if (response.data.next != null && response.data.next != '') {
                            this.loadMesssags(response.data.next);
                        }
                        this.$refs.editor.focus();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getAuthor(id) {
            const ids = JSON.stringify({ IDS: [{ ID: id }] });
            this.$store
                .dispatch('listUsersByIDs', { ids })
                .then((response) => {
                    if (response.Users != null) {
                        this.participants[id] = response.Users[0];
                    } else {
                        this.participants[id] = {
                            ID: id,
                            Profile: { ImageURL: '', Name: 'User not found' },
                        };
                    }
                })
                .catch((error) => {
                    this.participants[id] = {
                        ID: id,
                        Profile: { ImageURL: '', Name: 'User not found' },
                    };
                    console.log(error);
                    // todo show error
                });
        },
        getThreadByID(id) {
            axios({
                method: 'GET',
                url: `/chat/t/by-id/${id}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.thread = response.data;

                    this.currentThreadMessages = [];
                    this.checkIfNotificationsPaused();
                    this.thread.Peers.forEach((element) => {
                        this.participants[element.ID] = element;
                    });
                    this.participants[this.$store.state.me.ID] = this.$store.state.me;
                    this.loadMesssags();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>
