




















































import axios from 'axios';

import CoEditor from '../../Molecules/co-editor/CoEditor.vue';
import CoThumbnail from '../../Molecules/co-thumbnail/CoThumbnail.vue';
import CommentSection from '../../old-components/CommentSection.vue';
import CoComment from '../co-comment/CoComment.vue';
import Editor from '../Editor/Editor.vue';
import { get, map, uniq } from 'lodash';

import { Image, User } from '@/types/GlobalTypes';

interface Comment {
    Images: Image[];
    Author: User;
    PostID: string;
    PostType: string;
    Text: string;
    Version: string;
}

export default {
    components: { Editor, CommentSection, CoEditor, CoThumbnail, CoComment },
    name: 'CoComments',
    props: {
        Me: {
            type: Object as () => User,
            required: true,
        },
        PostID: String,
        PostType: String,
        PostSlug: String,
        highlightCommentID: String,
    },
    data() {
        return {
            comments: null,
            sending: false,
            imageFiles: [],
            imageURLs: [],
            newComment: false,
        };
    },
    computed: {
        comment() {
            return {
                Version: '1',
                PostID: this.PostID,
                PostType: this.PostType,
                PostSlug: this.PostSlug,
            };
        },
    },
    mounted() {
        this.getComments(this.PostID);
    },
    watch: {
        comments: {
            handler(newVal, oldVal) {},
            deep: true,
        },
    },
    methods: {
        getComments(postID, newComment = false) {
            axios({
                method: 'GET',
                url: `comment/list?postid=${postID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(async (response) => {
                    if (!response.data.objects || response.data.objects.length < 1) return;
                    // create a list of unique AuthorIDs
                    let userIDs = map(response.data.objects, (i) => i.object.AuthorID);
                    userIDs = uniq(userIDs);
                    // get the users
                    let users = await axios({
                        method: 'POST',
                        url: '/user/listbyids',
                        data: {
                            IDS: map(userIDs, (i) => {
                                return { ID: i };
                            }),
                        },
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    let usersMap = map(users.data.Users, (i) => {
                        return {
                            ...i,
                            ...i.Profile,
                        };
                    });
                    this.comments = map(response.data.objects, (i) => {
                        return {
                            ...i.object,
                            Author: usersMap.find((u) => u.ID === i.object.AuthorID),
                        };
                    });
                    //set flag for new comment
                    if (newComment) {
                        this.comments[0].newComment = true;
                        this.$nextTick(() => {
                            let comment = this.$refs[`comment-${this.comments[0].ID}`];
                            if (comment && comment[0])
                                comment[0].$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {});
        },
        uploadImages(files) {
            return new Promise((resolve, reject) => {
                if (!files || files.length === 0) resolve([]);
                var counter = 0;
                var images = [];

                files.forEach((file) => {
                    const data = new FormData();
                    data.append('file', file.file);
                    axios({
                        method: 'POST',
                        url: '/upload/image/comment',
                        data,
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'image/*',
                        },
                    })
                        .then((response) => {
                            counter++;
                            images.push(get(response, 'data.url'));
                            if (counter === files.length) {
                                resolve(images);
                            }
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            });
        },
        async createComment(commentData) {
            if (!commentData || typeof commentData !== 'object') return;
            this.sending = true;
            const images = await this.uploadImages(commentData.Files);

            let newComment = {
                ...this.comment,
                Text: commentData.Html,
                Images: images,
            };

            let data = JSON.stringify(newComment);

            axios({
                method: 'POST',
                url: '/comment',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    //update the comments
                    this.getComments(this.PostID);
                    //clear the editor
                    this.$refs.editor.clear(true);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.sending = false;
                });
        },
    },
};
