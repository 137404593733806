













































import axios from 'axios';
import CoResourcePreview from '../../Organisms/co-resource-preview/CoResourcePreview.vue';
import CoCard from '../co-card/CoCard.vue';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';

import CoPrice from '../../Atoms/co-price/coPrice.vue';
import CoThumbnail from '../co-thumbnail/CoThumbnail.vue';

interface Price {
    PriceInCents: number;
    Currency: string;
    TaxRate: number;
    TaxName: string;
    Inclusive: boolean;
    Unit?: string;
}

export default {
    name: 'CoCardResourceGroup',
    components: {
        CoResourcePreview,
        CoCard,
        CoHeadline,
        CoThumbnail,
        CoPrice,
    },
    props: {
        resourceGroup: {
            type: Object,
            required: true,
        },
        variant: {
            type: String,
            default: 'normal',
            validator: (value: string) => ['normal', 'gray'].includes(value),
        },
    },
    data() {
        return {
            imageURL: this.resourceGroup.ImageURL,
            name: '',
            windowWidth: window.innerWidth,
            Price: 0,
            bookingCredits: null,
            currency: '',
            resourceGroupPrice: '',
            availability: false,
            details: true,
            resourceGroupObject: {},
        };
    },
    created() {
        this.resourceGroupObject = this.resourceGroup;
    },
    methods: {
        priceObject() {
            const priceObj: Price = {
                PriceInCents: this.resourceGroup.PriceInCents,
                Currency: this.resourceGroup.Currency,
                Unit: 'hour',
                TaxName: '',
                TaxRate: 0,
                Inclusive: false,
            };

            if (this.resourceGroup.TaxRate) {
                priceObj.TaxRate = this.resourceGroup.TaxRate.Percentage;
                priceObj.TaxName = this.resourceGroup.TaxRate.Name;
                priceObj.Inclusive = this.resourceGroup.TaxRate.Inclusive;
            }
            return priceObj;
        },
        select(event) {
            // if event is not target child of CoResourcePreview component than stop
            if (event && event.target.closest('.CoResourcePreview')) {
                return;
            }
            const r = JSON.parse(JSON.stringify(this.resourceGroup));
            this.$emit('select', r);
        },

        resourceDetails(event) {
            event.preventDefault();

            this.$bvModal.show(`viewResourceModal-${this.resource.ID}`);
        },

        centsToWholeUnit(cents: number): string {
            const price = cents / 100;
            return price.toFixed(2);
        },
    },
};
