<template>
    <div>
        <div class="ml-md-4">
            <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
                <div class="mt-4 bg-white p-4 round-unify">
                    <h3>
                        Your custom JavaScript will be placed inside the <code>&lt;head&gt;</code>-Tag on all pages.<br />
                        <small
                            >That way you can use third-party software for customer support such as Intercom and
                            FreshDesk, web analytics like Google Analytics, Matomo and many other tools.</small
                        >
                    </h3>
                    <br />
                    <b-form @submit.stop.prevent="handleSubmit" ref="createResource" class="">
                        <b-form-group
                            id="input-group-JavaScript"
                            label="JavaScript snippet"
                            label-for="input-JavaScript"
                            description="type in without <script> tags"
                        >
                            <b-form-textarea
                                id="input-JavaScript"
                                placeholder="JavaScript code"
                                rows="3"
                                style="border-radius: 16px"
                                v-model="space['Javascript']"
                            ></b-form-textarea>
                        </b-form-group>
                        <p>
                            Placeholders <code v-pre>{{ name }}</code
                            >, <code v-pre>{{ user_id }}</code> and <code v-pre>{{ email }}</code> can be used to
                            prefill user data in you custom code. All placeholders will be inserted inside
                            <code>" "</code> to represent their correct Type (String) in JavaScript. When the user is
                            unknown i.e. on sign up pages the value for all placeholders is <code>null</code>
                        </p>
                        <div class="d-flex align-items-center justify-content-end">
                            <b-button variant="primary" type="submit">Save</b-button>
                        </div>
                    </b-form>
                </div>
            </b-overlay>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import EventBus from '../../../eventBus';

export default {
    name: 'JavaScript',

    data() {
        return {
            loading: false,
            space: null,
        };
    },
    created() {
        this.getSpaceSettings();
    },
    methods: {
        handleSubmit() {
            this.loading = true;

            const data = JSON.stringify(this.space);
            axios({
                method: 'PUT',
                url: '/admin/space/settings',
                withCredentials: true,
                data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data) {
                        this.space = response.data;
                        const msg = {
                            Message: 'Changes saved',
                            Details: '',
                        };
                        EventBus.$emit('INFO', msg);
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to save space settings',
                        Details: '',
                    };
                    EventBus.$emit('ERROR', msg);
                });
        },
        getSpaceSettings() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/admin/space/settings',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data) {
                        this.space = response.data;
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to get space settings',
                        Details: '',
                    };
                    EventBus.$emit('ERROR', msg);
                });
        },
    },
};
</script>
