
























































export default {
    name: 'MonetizationMenu',
    data() {
        return {
            space: this.$store.state.space,
        };
    },
};
