<style type="text/css" scoped>
iframe {
    border: 0;
    width: 100%;
    height: calc(100vh - 150px);
    overflow: scroll;
}
</style>

<template>
    <div id="content">
        <b-overlay :show="!dashboardURL" class="px-sm-4">
            <div class="b-overlay-wrap position-relative px-sm-4" style="background: white; border-radius: 0.8rem">
                <iframe :src="dashboardURL"></iframe>
            </div>
        </b-overlay>
    </div>
</template>

<script>
export default {
    name: 'AdminStatisticsCheckins',
    data() {
        return {
            dashboardURL: null,
        };
    },
    mounted() {
        this.$store.dispatch('getDashboardURL', 7).then((url) => {
            this.dashboardURL = url;
        });
    },
};
</script>
