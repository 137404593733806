<style lang="less" scoped>
.list-item {
    background: white;
    margin-bottom: 10px;
    cursor: pointer;

    .picture {
        width: 94px;
        height: 94px;
        padding: 4px;
        margin-left: 0px;

        @media (min-width: 768px) {
            width: 130px;
            height: 130px;
            padding: 8px;
            margin-left: -5px;
            object-fit: cover;
        }
    }

    .item-data {
        // height: 80px;

        font-size: 0.8rem;
        line-height: 0.8rem;

        .btn {
            padding: 0.2rem 0.8rem;
            font-size: 0.9rem;
            line-height: 0.9rem;
        }

        .small-icon {
            width: 25px !important;
            min-width: 25px !important;
            height: 25px !important;
            min-height: 25px !important;
        }

        .avatar {
            width: 32px !important;
            min-width: 32px !important;
            height: 32px !important;
            min-height: 32px !important;
            padding-top: 0.2rem;
        }

        @media (min-width: 768px) {
            // height: 120px;
            font-size: 1rem;
            line-height: 1rem;
            .btn {
                padding: 0.3rem 0.8rem;
                font-size: 1rem;
                line-height: 1rem;
            }

            .small-icon {
                width: 25px !important;
                min-width: 25px !important;
                height: 25px !important;
                min-height: 25px !important;
            }

            .avatar {
                width: 37px !important;
                min-width: 37px !important;
                height: 37px !important;
                min-height: 37px !important;
            }
        }
    }

    .title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    &:hover .picture {
        opacity: 0.7;
    }
}

a {
    color: inherit;
    text-decoration: inherit;
}
</style>

<template>
    <router-link
        class="list-item round-unify w-100 d-flex align-items-start"
        tag="a"
        :to="'/profile/' + object.object.Slug"
    >
        <div class="d-flex align-items-center picture">
            <div class="picture-info">
                <div class="picture">
                    <LazyLoadImg
                        :src="imageURL ? imageURL : this.object.object.Profile.ImageURL"
                        class="card-image-container"
                        profile-placeholder
                        dark-variant
                        round
                    >
                    </LazyLoadImg>
                </div>
            </div>
        </div>
        <div>
            <div class="d-flex w-100 flex-column align-items-start item-data pl-3 py-2">
                <h2 class="title mt-1 mb-2 mb-xs-3">
                    {{ object.object.Profile.Name }}
                </h2>
                <div class="d-flex align-items-center mb-2 mb-xs-3">
                    <div v-if="firstProject" class="ml-1 mr-2 avatar">
                        <CoThumbnail
                            v-if="firstProject"
                            :ImageURL="
                                firstProject.object.ImageURL && firstProject.object.ImageURL.length > 0
                                    ? firstProject.object.ImageURL[0]
                                    : ''
                            "
                            :Slug="firstProject.object.Slug"
                        ></CoThumbnail>
                    </div>
                    <div v-if="firstProject" class="ml-0">
                        <router-link
                            @click.prevent.stop.self="viewProject(firstProject.object.Slug)"
                            :to="'/project/' + firstProject.object.Slug"
                            >{{ trimString(firstProject.object.Title) }}
                        </router-link>
                    </div>
                    <div v-if="projects && projects.length > 1" class="mx-3">
                        <span class="mx-1 text-nowrap"> + {{ $tc('labels.morecount', projects.length - 1) }} </span>
                    </div>
                    <div v-if="projects && projects.length === 0" class="ml-1 d-flex">
                        <span class="ml-1 d-flex"
                            ><p><br /></p
                        ></span>
                    </div>
                </div>
                <div class="mt-1 mt-xl-0">
                    <!-- TODO: CTA-Action Button finish. Remove v-if in next line to show buttons -->
                    <b-button
                        v-if="
                            object.object.ID !== this.$store.state.me.ID &&
                            object.object.SpaceID === this.$store.state.space.ID
                        "
                        variant="primary "
                        @click="takeContact(object.object)"
                        :title="'Send a message to ' + object.object.Profile.Name"
                    >
                        <b-icon icon="envelope"></b-icon>
                        {{ $t('labels.message') }}
                    </b-button>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import Router from 'vue-router';
import LazyLoadImg from '@/components/old-components/LazyLoadImg.vue';
import CoThumbnail from '../Molecules/co-thumbnail/CoThumbnail.vue';

export default {
    name: 'CommunityCard',
    components: { LazyLoadImg, CoThumbnail },
    props: ['object'],
    data() {
        return {
            imageURL: this.object.object.Profile.ImageURL,
            name: '',
            windowWidth: window.innerWidth,
            user: {},
            projects: [],
        };
    },
    created() {
        this.getProjectsForUser(this.newUserID);
    },
    watch: {
        object(newVal, oldVal) {
            this.imageURL = this.object.object.ImageURL;
        },
    },
    computed: {
        isDesktop() {
            return this.windowWidth >= 768;
        },
        firstProject() {
            if (this.projects && this.projects.length > 0) {
                return this.projects[0];
            }
            return null;
        },
        newUserID() {
            return this.object.object.ID;
        },
    },

    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });
    },
    methods: {
        showModal(id) {
            this.$refs[id].show();
        },
        getProjectsForUser(userID) {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/user/profile/projects/${userID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    this.projects = response.data.objects; // todo support pagination
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
        takeContact(userObj) {
            const thread = {
                Participants: [this.$store.state.me.ID, userObj.ID],
            };
            axios({
                method: 'POST',
                url: '/chat/t',
                withCredentials: true,
                data: JSON.stringify(thread),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.$router.push(`/messenger/${response.data.ID}`);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

            // var msg = {"msg": ""};
        },
        trimString(string) {
            let newString = string;
            if (string == null) {
                return '';
            }
            if (string.length > 20) {
                newString = `${newString.substring(0, 20 - 1)}...`;
            }
            return newString;
        },
        viewProject(slug) {
            this.$router.push(`/project/${slug}`);
        },
    },
};
</script>
