











import CoIcon from '../co-icon/CoIcon.vue';
import { get } from 'lodash';
export default {
    name: 'CoFileInput',
    components: { CoIcon },
    props: {
        accept: {
            type: String,
            default: '', // i.e. 'image/*', '.png,.doc,.pdf' values according to https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: 'cloud-upload',
        },
    },
    methods: {
        fileSelect(event) {
            let files = get(event, 'target.files');
            this.$emit('change', files);
        },
    },
};
