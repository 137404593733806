<template>
    <div class="container">
        <div class="image-view" ref="image">
            <transition name="fade">
                <img
                    v-show="images != null"
                    :src="images[currentIndex]"
                    :key="currentIndex"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title=""
                    alt=""
                />
            </transition>
            <button v-show="images.length > 1" class="round float-l grey-bg left" v-on:click="previous">
                <span>←</span>
            </button>
            <button v-show="images.length > 1" class="round float-l grey-bg right" v-on:click="next">
                <span>→</span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Slider',
    props: ['images'],
    data() {
        return {
            timer: null,
            currentIndex: 0,
        };
    },

    mounted() {
        //   this.startSlide();
    },

    methods: {
        startSlide() {
            this.timer = setInterval(this.next, 5000);
        },
        next() {
            if (this.currentIndex == this.$props.images.length - 1) {
                this.currentIndex = 0;

                return;
            }
            this.currentIndex += 1;
        },
        previous() {
            if (this.currentIndex == 0) {
                this.currentIndex = this.$props.images.length - 1;
                return;
            }
            this.currentIndex -= 1;
        },
    },
};
</script>

<style scoped>
.container {
    height: 100%;
    width: 100%;
    padding: 0;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
.image-view {
    position: relative;
    height: 100%;
    width: 100%;
    /* padding-top: 66.66%; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.image-view img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    object-fit: cover;
}

.left {
    position: absolute;
    top: calc(50% - 18px);
    left: 0;
    /* display: none; */
}
.right {
    position: absolute;
    top: calc(50% - 18px);
    right: 0;
}

::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #ff0000;
}

@media (max-width: 1000px) {
    .container {
        margin-right: 0px !important;
        margin-left: 0px !important;
        width: 100%;

        max-width: unset;
    }
}
</style>
