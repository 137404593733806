


















































































































import EventBus from '../../../eventBus';
import axios from 'axios';

interface Resource {
    Name: String;
    Description: String;
    Location: String;
    ImageURL: String;
    Color: String;
    PriceInCents: Number;
    Currency: String;
    PriceInterval: String;
    Hidden: Boolean;
    BookingInterval: Boolean;
    FixedTimeSpan: Boolean;
}
export default {
    name: 'CoCreateResourceGeneralPage',
    props: {
        resource: {
            type: (Object as () => Resource) || null,
            default: null,
            required: true,
        },
    },

    data() {
        return {
            plan: null,
            me: this.$store.state.me,
            imageURL: '',
            taxRates: [],

            color: 'black',

            loading: false,
            uniqueNames: false,
            addedUniqueNames: [
                {
                    ID: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
                    Name: '',
                    Code: '',
                },
            ],
            loadingTaxRate: false,

            id: this.$route.params.ID, // current or upcoming
        };
    },
    created() {},
    mounted() {
        this.getTaxRates();
    },
    methods: {
        getTaxRates() {
            this.loadingTaxRate = true;
            axios({
                method: 'GET',
                url: `/admin/tax-rate/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.taxRates = response.data;
                    }

                    this.loadingTaxRate = false;
                })
                .catch((error) => {
                    this.loadingTaxRate = false;
                    console.log(error);
                });
        },

        selectColor(color) {
            this.color = color;
        },
        addUniqueNameLine() {
            const ID = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            this.addedUniqueNames.push({ ID: ID, Name: '', Code: '' });
        },
        removeUniqueNameLine(index) {
            this.addedUniqueNames.splice(index, 1);
        },
        selectPriceInterval(interval) {
            this.priceInterval = interval;
        },
        selectCurrency(currency) {
            this.resource.Currency = currency;
        },
        calculatePrice(price) {
            this.resource.PriceInCents = price * 100;
        },
        selectTaxRate(taxrate) {
            this.resource.TaxRate = taxrate;
        },
        addUniqueName(name, index) {
            this.addedUniqueNames[index].Name = name;
        },
        addAccessCode(code, index) {
            this.addedUniqueNames[index].Code = code;
        },
    },
};
