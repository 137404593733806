






























































import CoText from '../../Atoms/co-text/CoText.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';

export default {
    name: 'CoAlert',
    components: {
        CoText,
        CoIcon,
    },
    props: {
        variant: {
            type: String,
            default: 'red',
            validator(value) {
                return ['red', 'yellow', 'grey', 'green'].includes(value);
            },
        },
        text: {
            type: String,
            default: 'No text provided',
        },
        size: {
            type: String,
            default: 'md',
            validator(value) {
                return ['sm', 'md', 'lg'].includes(value);
            },
        },
        icon: {
            type: String,
            default: 'info-circle',
        },
        showIcon: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        classes() {
            return {
                [this.variant]: true,
                [this.size]: true,
            };
        },
    },
};
