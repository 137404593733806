<template>
    <div>
        <input
            :id="Id"
            v-model.lazy="PriceValue"
            type="number"
            @keydown="isNumber($event)"
            :placeholder="Placeholder"
            class="rounded-pill"
            :class="{ active: focused }"
            @focus="focused = true"
            @blur="focused = false"
            step="0.01"
            :disabled="Disabled"
        />
    </div>
</template>

<style lang="less" scoped>
@import './CoFormInput.less';
</style>
<script>
import Vue from 'vue';
import Vuex from 'vuex';

export default {
    name: 'CoFormInput',
    props: {
        Id: {
            type: String,
            default: '',
        },
        Price: {
            type: Number,
            default: 0,
        },
        Placeholder: {
            type: String,
            default: '',
        },
        Disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            focused: false,
        };
    },
    computed: {
        PriceValue: {
            get() {
                return this.Price;
            },
            set(val) {
                const num = parseFloat(parseFloat(val).toFixed(2));
                this.$emit('updated', num);
            },
        },
    },
    mounted() {
        // this.billingOn = this.$store.state.billingTurnedOn;
    },
    methods: {
        isNumber(evt) {
            const keysAllowed = [
                '0',
                '1',
                '2',
                '3',
                '4',
                '5',
                '6',
                '7',
                '8',
                '9',
                '.',
                'Backspace',
                'ArrowLeft',
                'ArrowRight',
                'Delete',
                'Tab',
            ];
            const keyPressed = evt.key;
            if (!keysAllowed.includes(keyPressed)) {
                evt.preventDefault();
            }
        },
    },
};
</script>
