






























import CoPost from '@/components/Organisms/co-post/CoPost.vue';
import CoPostEditor from '@/components/Organisms/co-post-editor/coPostEditor.vue';
import { get } from 'lodash';

export default {
    name: 'SinglePostPage',
    components: {
        CoPost,
        CoPostEditor,
    },
    props: {
        post: {
            type: Object,
            default: null,
            required: false,
        },
        me: {
            type: Object,
            default: null,
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            editedPost: this.post,
            meLocal: get(this, 'me', null) || get(this.$store, 'state.me', {}),
            rerenderKey: 0,
        };
    },
    methods: {
        postDeleted() {
            this.$router.push('/');
        },
        updatedPost(post: Object = null) {
            if (!post) return;
            this.editedPost = post;
            this.editedPost.object.wasUpdated = true;
            this.rerenderKey += 1;
        },
        editPost(post: Object = null) {
            if (!post) return;
            // map post structure to editor structure
            const postToEdit = {
                ...get(post, 'object', {}),
                Files: get(post, 'object.Images', []).map((image) => ({ url: image.ImageURL })),
                wasUpdated: true,
            };
            this.$refs.posteditor.show(postToEdit, true);
        },
    },
};
