<template>
    <span
        :title="title || text"
        class="co pill"
        :class="{
            outline: outline,
            truncate: truncate,
            [variant]: true,
        }"
        ><slot>{{ text }}</slot></span
    >
</template>
<style lang="less" scoped>
@import './CoPill.less';
</style>
<script>
export default {
    name: 'CoPill',
    props: {
        text: String,
        title: String,
        outline: {
            type: Boolean,
            default: false,
        },
        truncate: {
            type: Boolean,
            default: false,
        },
        variant: {
            default: 'normal',
            validators: (value) =>
                ['normal', 'mute', 'success', 'warning', 'primary', 'white', 'black', 'lightgrey', 'outline'].includes(
                    value
                ),
        },
    },
};
</script>
