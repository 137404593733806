<!-- eslint-disable prettier/prettier -->
<style lang="less">
@import '~@/assets/less/variables.less';

.footer-public-info {
    font-size: 0.75rem;
    color: var(--color-text-light);

    a {
        color: var(--color-text-light);
        text-decoration: none;

        &:hover {
            color: var(--color-text-light);
            text-decoration: underline;
        }
    }
}

.navbar.navbar-light.bg-gray {
    background-color: @co-lightgrey !important;
}
</style>
<template>
    <div class="px-0 animate-blur d-flex flex-column min-vh-100" :path="path">
        <b-navbar variant="gray" type="light" fixed="top">
            <b-navbar-brand tag="h1" class="mb-0" to="/">
                <img
                    v-if="this.$store.state.space.LogoURL"
                    :src="this.$store.state.space.LogoURL"
                    height="36px"
                    alt="coapp logo"
                />
                <div v-else>
                    <h2 class="m-0 p-0">{{ spaceName }}</h2>
                </div>
            </b-navbar-brand>
            <b-navbar-nav class="ml-auto d-none d-sm-flex align-content-center">
                <b-nav-item>
                    <b-button to="/login" variant="outline-primary">{{ $t('labels.login') }}</b-button>
                </b-nav-item>
            </b-navbar-nav>
        </b-navbar>

        <!-- spacer is necessary for vertical spacing for all router views not using UserMainGrid.vue -->
        <div class="spacer"></div>
        <!-- content -->
        <div class="flex-grow-1 px-1">
            <router-view ref="routerview" />
        </div>
        <div class="footer-public-info mt-auto ml-4 py-3">
            <router-link to="/legal/privacy-policy" target="_blank"
                >{{ $t('labels.imprint') }} & {{ $t('labels.privacypolicy') }}
            </router-link>
            <br />powered by
            <a target="_blank" href="https://www.coapp.io?utm_source=in-app&utm_medium=in-app&utm_campaign=powered-by"
                >coapp.io</a
            >
        </div>

        <!-- privacy policy modal start -->
        <b-modal id="privacy-policy-main" hide-footer title="Imprint & Privacy Policy" size="xl">
            <div v-if="PrivacyPolicy" v-html="PrivacyPolicy.Content"></div>
        </b-modal>
        <!-- privacy policy modal end -->
    </div>
</template>
<style lang="less" scoped>
@header-height: 3.875rem; //navbar height
@content-top-padding-desktop: 2.75rem; //spacing between navbar and content
@content-top-padding-mobile: 1rem; //spacing between navbar and content
.spacer {
    width: 100%;
    height: calc(@content-top-padding-desktop + @header-height);
}

@media screen and (max-width: 992px) {
    .spacer {
        height: calc(@header-height + @content-top-padding-mobile) !important;
    }
}
</style>
<script>
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import Vue from 'vue';
import axios from 'axios';
import EventBus from '@/eventBus.js';
import store from '@/store.js';

import { get } from 'lodash';

import setCustomColors from '../../customColors';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

export default {
    name: 'MainPublic',
    data() {
        return {
            path: this.$router.currentRoute.path.split('/').join(' '),
            PrivacyPolicy: null,
            spaceName: get(this, '$store.state.space.Name', null),
        };
    },
    watch: {
        // set the path parameter for conditional styling
        $route(to, from) {
            // check if url params 'm' is equal to 'mobile' and set the isInMobile flag in store
            if (to.query.m === 'mobile') {
                this.$store.commit('SET_IS_IN_MOBILE', true);
            } else if (to.query.m === 'desktop') {
                this.$store.commit('SET_IS_IN_MOBILE', false);
            }

            this.path = to.path.split('/').join(' ');
        },
    },

    computed: {
        query() {
            return this.$route.query.term;
        },
        isInMobile() {
            return get(this, '$store.state.isInMobile', false);
        },
    },

    created() {
        if (this.$route.query.m === 'mobile') {
            this.$store.commit('SET_IS_IN_MOBILE', true);
        } else if (this.$route.query.m === 'desktop') {
            this.$store.commit('SET_IS_IN_MOBILE', false);
        }

        if (
            this.$store &&
            this.$store.state &&
            this.$store.state.space &&
            !this.$router.currentRoute.path.startsWith('/admin')
        ) {
            const primaryColor = this.$store.state.space.PrimaryColor.replace(/\s/g, '');
            const secondaryColor = this.$store.state.space.SecondaryColor.replace(/\s/g, '');
            const textColor = this.$store.state.space.TextColor.replace(/\s/g, '');

            const colorData = [
                {
                    name: 'purple',
                    value: primaryColor,
                },
                {
                    name: 'red',
                    value: secondaryColor,
                },
                {
                    name: 'text',
                    value: textColor,
                },
            ];

            try {
                setCustomColors(colorData);
            } catch (error) {
                try {
                    setCustomColors([
                        {
                            name: 'purple',
                            value: '#5100ff',
                        },
                        {
                            name: 'red',
                            value: '#fd6969',
                        },
                        {
                            name: 'text',
                            value: '#212529',
                        },
                    ]);
                } catch (error2) {
                    console.log(error2);
                }
                console.log(error);
            }
        }

        this.getPrivacyPolicy();
    },

    methods: {
        goBack() {
            this.$router.go(-1);
        },
        getPrivacyPolicy() {
            axios({
                method: 'GET',
                url: '/space/get-privacy-policy',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.PrivacyPolicy = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>
