






























export default {
    name: 'CoPaymentSelectModal',

    props: {
        paymentMethod: {
            type: Object,
            default: null,
        },
        paymentMethods: {
            type: Array as () => Array<any>,
            default: () => [],
        },
    },
    watch: {},
    data() {
        return {
            showModal: false,
        };
    },
    mounted: function () {},
    methods: {
        show() {
            this.$refs['select-payment-method-modal'].show();
        },

        selectPaymentMethod(pm) {
            this.$refs['select-payment-method-modal'].hide();
            this.$emit('selectedPaymentMethod', pm);
        },
    },
};
