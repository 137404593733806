








export default {
    name: 'MonetizationBase',
};
