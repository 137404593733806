<template>
    <div class="container-fluid" v-bind:style="styleObject">
        <div class="d-flex align-items-center h-25">
            <img
                v-if="this.$store.state.space.LogoURL"
                :src="this.$store.state.space.LogoURL"
                width="350px"
                height="auto"
                :alt="this.$store.state.space.Name"
                class="ml-5"
            />
            <div v-else class="ml-5">
                <h2>{{ this.$store.state.space.Name }}</h2>
            </div>
        </div>
        <div class="d-block h-75 m-0">
            <!-- events -->
            <b-carousel
                ref="myCarousel"
                :interval="10000"
                v-if="slide"
                id="carousel-1"
                class="h-100"
                @sliding-end="onSlideEnd"
            >
                <div
                    v-for="(page, index) in slide"
                    :key="index"
                    class="carousel-item active row align-items-center row"
                >
                    <div v-for="(post, index2) in page" :key="index2" class="col-md-4 p-0 d-inline-block">
                        <div
                            class="ml-2"
                            :style="{
                                height: sliderHeight + 'px',
                            }"
                        >
                            <div
                                v-if="space === 'project'"
                                class="p-4 h-50"
                                :style="{
                                    background: 'white',
                                    overflow: 'hidden',
                                }"
                            >
                                <h8 v-bind:class="timeCssClasses(post.object.LastModifyed + '000')"
                                    >Last Updated {{ (post.object.LastModifyed + '000') | readabledate }}</h8
                                >
                                <h2 v-html="post.object.Title"></h2>
                                <p>
                                    {{ post.object.Description }}
                                </p>
                            </div>
                            <div
                                v-else
                                class="p-4 h-50"
                                :style="{
                                    background: 'white',
                                    overflow: 'hidden',
                                }"
                            >
                                <h4 v-bind:class="timeCssClasses(post.object.StartDate + '000')">
                                    {{ (post.object.StartDate + '000') | readabledate }}
                                </h4>
                                <h2 v-bind:class="timeCssClasses(post.object.StartDate + '000')">
                                    {{ (post.object.StartDate + '000') | readabletime }} -
                                    {{ (post.object.EndDate + '000') | readabletime }}
                                </h2>
                                <h2 v-html="post.object.Title"></h2>
                            </div>
                            <div
                                class="h-50"
                                :style="{
                                    'background-image': 'url(' + filterPictures(post.object.ImageURL) + ')',
                                    'background-size': 'cover',
                                    'background-position': 'center',
                                    height: '200px',
                                }"
                            ></div>
                        </div>
                    </div>
                </div>
            </b-carousel>
            <!-- space health -->
            <!-- Warning modal -->
            <b-modal
                centered
                id="warningModal"
                size="xl"
                title="Warning"
                content-class="gradient-bg mediocre text-center py-5"
                :hide-header="true"
                :hide-footer="true"
            >
                <p style="font-size: 8em">
                    <b-icon icon="exclamation-octagon-fill"></b-icon>
                </p>
                <h2>
                    Der CO<sub>2</sub>-Gehalt in der Luft ist hoch. Bitte lüfte diesen Raum bis diese Warnung nicht mehr
                    angezeigt wird.
                </h2>
                <h5>Es dauert bis zu 10 Minuten bis diese Anzeige aktualisiert wird.</h5>
            </b-modal>
            <!-- dashboad widgets -->
            <div v-if="spaceDetail" class="container-fluid">
                <div class="row px-3">
                    <!-- space name and time stamp -->
                    <div class="col-12">
                        <h1 style="font-size: 5em" class="mb-5">
                            {{ spaceDetail.health.station_name }}
                        </h1>
                        <p>
                            <small>
                                <b-icon icon="info-circle"></b-icon>
                                Time of measurement
                                {{ (spaceDetail.health.time_utc * 1000) | readabledate }},
                                {{ (spaceDetail.health.time_utc * 1000) | readabletime }}. Data is updated every 10
                                minutes.
                            </small>
                        </p>
                    </div>
                    <!-- build widgets -->
                    <div v-for="(item, key) in spaceDetail.health.items" :key="key" class="col-6 mt-4">
                        <div class="card value feedpost gradient-bg">
                            <div class="card-body py-5">
                                <p>
                                    <span class="h2" v-html="item.title"> </span>
                                </p>
                                <h1>
                                    <b-icon v-bind:icon="item.icon"></b-icon> {{ item.data }}
                                    {{ item.unit }}
                                </h1>
                                <div class="position-relative">
                                    <div
                                        class="d-inline position-absolute text-center"
                                        v-bind:style="{
                                            left: ((item.data - item.minValue) / item.range) * 100 + '%',
                                            width: '0px',
                                            marginLeft: '-.5em',
                                        }"
                                    >
                                        <b-icon icon="caret-down-fill"></b-icon>
                                    </div>
                                </div>
                                <!-- value indicator -->
                                <div v-if="item.limits" class="progress mt-5">
                                    <div
                                        v-for="(limit, index) in item.limits"
                                        :key="index"
                                        v-bind:style="[
                                            item.limits[index + 1]
                                                ? {
                                                      width:
                                                          ((item.limits[index + 1].value - limit.value) / item.range) *
                                                              100 +
                                                          '%',
                                                  }
                                                : {
                                                      width: ((item.maxValue - limit.value) / item.range) * 100 + '%',
                                                  },
                                        ]"
                                        v-bind:class="limit.class"
                                    ></div>
                                </div>
                                <!-- min max labels -->
                                <div v-if="item.minValue" class="row mt-4">
                                    <div class="col text-left">
                                        <small>{{ item.minValue }} {{ item.unit }}</small>
                                    </div>
                                    <div class="col text-right">
                                        <small>{{ item.maxValue }} {{ item.unit }}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- error handling -->
            <div v-if="error" class="alert-danger col-md-12">
                {{ error }}
            </div>
        </div>
    </div>
</template>
<style lang="less">
//background gradient animation

.gradient-bg.good {
    background-image: linear-gradient(270deg, #246655, #41c5a3);
    background-size: 400% 400%;
}
.gradient-bg.mediocre {
    background-image: linear-gradient(270deg, #8f7327, #ddda27);
    background-size: 400% 400%;
}
.gradient-bg.bad {
    background-image: linear-gradient(270deg, #8f2727, #ff0000);
    background-size: 400% 400%;
}

.gradient-bg {
    background-size: 400% 400%;

    -webkit-animation: AnimationName 3s ease infinite;
    -moz-animation: AnimationName 3s ease infinite;
    animation: AnimationName 3s ease infinite;
}

@-webkit-keyframes AnimationName {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
@-moz-keyframes AnimationName {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
@keyframes AnimationName {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
</style>

<style lang="less" scoped>
h1 {
    font-size: 3.5em;
    line-height: 1em;
}
h2 {
    font-size: 3em;
    line-height: 1em;
}

.slideshow {
    transform: translateY(1em);
    opacity: 0;
}
.slide-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.slide-enter,
.slide-enter-to-class,
.slide-enter-active {
    animation: slide-in 0.5s ease-in-out;
}

.slide-leave-active {
    animation: slide-in 0.5s reverse;
}

.card {
    font-size: 2em;
}

.card.value {
    height: 25;
}

.card.value .h1,
.card.value h1 {
    font-size: 3em;
}

@keyframes slide-in {
    0% {
        transform: translateY(1em);
        opacity: 0;
    }
    75% {
        opacity: 1;
    }
    100% {
        transform: translateY(0em);
        opacity: 1;
    }
}
</style>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
    name: 'Screen',
    data() {
        return {
            styleObject: {
                height: `${window.innerHeight}px`,
            },
            headerHeight: Math.floor(window.innerHeight * 0.25),
            sliderHeight: Math.floor(window.innerHeight * 0.75),
            location: this.$route.query.location,
            space: this.$route.query.space,
            spaceDetail: null,
            type: this.$route.query.type,
            token: this.$route.query.token,
            eventFeed: [],
            feedNextPage: null,
            slide: null,
            slideShow: true,
            slideCurrent: 0,
            slideTotal: null,
            slideIntervall: 5000,
            slideWidth: 500,
            error: false,
            itemsMax: 9,
            itemsPerSlide: 3,
            itemIndex: 0,
        };
    },
    methods: {
        timeCssClasses(value) {
            const time = parseInt(value);
            const timeDelta = time - Date.now();
            const dayInMs = 86400000;
            const result = [];

            if (moment(time).isSame(moment(), 'day')) {
                result.push('today');
            } else if (moment(time).isSame(moment().add(1, 'days'), 'day')) {
                result.push('tomorrow');
            }
            return result;
        },
        filterPictures(imageurl) {
            const randomItem = parseInt(Math.random() * 16) + 1;
            const images = require.context('../../assets/gradients/', true);
            if (!imageurl) {
                return images(`./Platform_Gradients-${randomItem}.jpg`);
            }
            return imageurl;
        },
        onSlideEnd(slideNum) {
            // refresh slide content when end of slideshow is reached
            if (slideNum === this.slide.length - 1) {
                this.getEvents(this.space);
            }
        },
        getEvents(content) {
            let url = '';
            const screenWidth = window.innerWidth;
            if (content) {
                url = `/public/${content}/list`;
            } else {
                url = '/public/event/list';
            }

            axios({
                method: 'GET',
                url,
                // withCredentials: true,
                headers: {
                    Authorization: `Bearer ${this.token}`,
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    // reset error object
                    this.error = null;

                    // exit early if no events are returned from the API
                    if (!response.data.objects || !response.data.objects.length) {
                        return true;
                    }

                    const eventsList = response.data.objects.splice(0, this.itemsMax);
                    let tmpEventsList = [];
                    const totalItems = 0;

                    // this.slideTotal = Math.ceil(this.eventFeed.length/this.itemsPerSlide);
                    let counter = 0;
                    if (eventsList.length) {
                        this.slide = [];
                    }

                    for (let i = 0; i < eventsList.length; i++) {
                        if (counter == this.itemsPerSlide) {
                            (counter = 0), this.slide.push(tmpEventsList);
                            tmpEventsList = [];
                        } else if (i == eventsList.length - 1) {
                            // tmpEventsList.push(eventsList[eventsList.length-1]);
                            this.slide.push(tmpEventsList);
                        }
                        tmpEventsList.push(eventsList[i]);
                        counter++;
                    }
                    this.slideTotal = Math.ceil(this.eventFeed.length / this.itemsPerSlide);
                })
                .catch((error) => {
                    throw new Error(error);
                    this.error = `error.message: ${error.message}`;
                });
        },
        getSpaceData(spaceName, maxPeople) {
            // get the sensor data from the API
            // function to get the data from api
            const updateData = () => {
                axios
                    .all([
                        axios.get(
                            `https://4tpp0r1b6a.execute-api.eu-central-1.amazonaws.com/v1/sensors?station_id=${encodeURI(
                                spaceName
                            )}`
                        ),
                        axios.get(
                            `https://4tpp0r1b6a.execute-api.eu-central-1.amazonaws.com/v1/checkins?location=${encodeURI(
                                spaceName
                            )}`
                        ),
                    ])
                    .then(
                        axios.spread((netatmo, checkins) => {
                            var netatmo = netatmo.data[0];
                            var checkins = checkins.data[0];

                            // set values for health widgets
                            const healthObject = {
                                station_name: netatmo.station_name,
                                time_utc: netatmo.time_utc,
                                items: [],
                            };

                            // warning modal if co2 value is to high
                            if (netatmo.co2 > 800) {
                                this.$bvModal.show('warningModal');
                            } else {
                                this.$bvModal.hide('warningModal');
                            }

                            // co2
                            healthObject.items.push({
                                title: 'CO<sub>2</sub> in ppm',
                                icon: 'cloud',
                                data: netatmo.co2,
                                maxValue: 1300,
                                limits: [
                                    {
                                        value: 200,
                                        class: 'bg-info',
                                    },
                                    {
                                        value: 400,
                                        class: 'bg-success',
                                    },
                                    {
                                        value: 800,
                                        class: 'bg-warning',
                                    },
                                    {
                                        value: 1000,
                                        class: 'bg-danger',
                                    },
                                ],
                            });
                            healthObject.items.push({
                                title: 'Temperature in °C',
                                icon: 'thermometer-half',
                                data: netatmo.temperature,
                                maxValue: 30,
                                limits: [
                                    {
                                        value: 13,
                                        class: 'bg-warning',
                                    },
                                    {
                                        value: 15,
                                        class: 'bg-success',
                                    },
                                    {
                                        value: 19,
                                        class: 'bg-info',
                                    },
                                    {
                                        value: 24,
                                        class: 'bg-success',
                                    },
                                    {
                                        value: 26,
                                        class: 'bg-warning',
                                    },
                                ],
                            });
                            healthObject.items.push({
                                title: 'Humidity',
                                icon: 'droplet',
                                data: netatmo.humidity,
                                unit: '%',
                                maxValue: 80,
                                limits: [
                                    {
                                        value: 10,
                                        class: 'bg-warning',
                                    },
                                    {
                                        value: 30,
                                        class: 'bg-success',
                                    },
                                    {
                                        value: 40,
                                        class: 'bg-info',
                                    },
                                    {
                                        value: 50,
                                        class: 'bg-success',
                                    },
                                    {
                                        value: 60,
                                        class: 'bg-warning',
                                    },
                                ],
                            });
                            healthObject.items.push({
                                title: 'Noise',
                                icon: 'soundwave',
                                data: netatmo.noise,
                                unit: 'dB',
                                maxValue: 100,
                                limits: [
                                    {
                                        value: 30,
                                        class: 'bg-info',
                                    },
                                    {
                                        value: 50,
                                        class: 'bg-success',
                                    },
                                    {
                                        value: 65,
                                        class: 'bg-warning',
                                    },
                                    {
                                        value: 70,
                                        class: 'bg-danger',
                                    },
                                ],
                            });
                            if (checkins) {
                                healthObject.items.push({
                                    title: 'People checked in today',
                                    icon: 'people',
                                    data: checkins.user_count,
                                    maxValue: maxPeople,
                                    limits: [
                                        {
                                            value: 1,
                                            class: 'bg-info',
                                        },
                                        {
                                            value: Math.round(maxPeople * 0.6),
                                            class: 'bg-success',
                                        },
                                        {
                                            value: Math.round(maxPeople * 0.8),
                                            class: 'bg-warning',
                                        },
                                    ],
                                });
                            }
                            // set min, max and range params accoring to limits
                            healthObject.items.forEach((item, key) => {
                                if (item.limits) {
                                    item.minValue = item.limits[0].value;
                                } else {
                                    return true;
                                }
                                item.range = item.maxValue - item.minValue;
                            });
                            this.spaceDetail
                                ? (this.spaceDetail.health = healthObject)
                                : (this.spaceDetail = { health: healthObject });

                            // reset error status
                            this.error = null;
                        })
                    )
                    .catch((error) => {
                        console.log(error);
                        this.error = error.message;
                    });
            };

            // initiate and get data update every minute
            updateData();
            setInterval(() => {
                updateData();
            }, 60000);
        },
    },
    created() {
        switch (this.type) {
            case 'status':
                this.type = 'space';
                this.getSpaceData(this.$route.query.space, this.$route.query.max_people);
                break;
            default:
                this.getEvents();
                break;
        }
    },
    mounted() {
        const itemIndex = 0;
    },
    computed: {},
};
</script>
