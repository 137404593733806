<template>
    <div class="">
        <SettingsCard
            title="Bookings"
            subtitle="Enable booking of rooms and resources"
            to="/admin/settings/features/bookings"
        >
        </SettingsCard>
        <SettingsCard
            title="Channels"
            subtitle="Channels provide order - create them for any project, topic or team"
            to="/admin/settings/features/channels"
            :isNew="true"
        >
        </SettingsCard>
        <SettingsCard title="Market" subtitle="Enable market feature" to="/admin/settings/features/market">
        </SettingsCard>
    </div>
</template>
<style lang="less" scoped></style>
<script>
import axios from 'axios';
import Vue from 'vue';
import EventBus from '../../../../eventBus';

export default {
    name: 'FeaturesMenu',

    data() {
        return {
            space: {},
            loading: false,
        };
    },
    created() {
        this.getSpaceSettings();
    },
    methods: {
        handleSubmit() {
            this.loading = true;

            const data = JSON.stringify(this.space);
            axios({
                method: 'PUT',
                url: '/admin/space/settings',
                withCredentials: true,
                data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data) {
                        this.space = response.data;
                        const msg = {
                            Message: 'Changes saved',
                            Details: '',
                        };
                        EventBus.$emit('INFO', msg);
                        // this.$router.go()
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to save space settings',
                        Details: '',
                    };
                    EventBus.$emit('ERROR', msg);
                });
        },
        getSpaceSettings() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/admin/space/settings',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data) {
                        this.space = response.data;
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to get space settings',
                        Details: '',
                    };
                    EventBus.$emit('ERROR', msg);
                });
        },
    },
};
</script>
