

























































import axios from 'axios';
import { get } from 'lodash';
import i18n from 'vue-i18n';

import { User } from '@/components/Molecules/co-card-member/models.ts';
import CoDropdown from '@/components/Molecules/co-dropdown/CoDropdown.vue';
import CoDropdownItem from '@/components/Molecules/co-dropdown-item/CoDropdownItem.vue';
import CoCardMember from '@/components/Molecules/co-card-member/CoCardMember.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import { de, tr } from 'date-fns/locale';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';

export default {
    name: 'CommunityMembers',
    components: { CoCardMember, CoCard, CoDropdown, CoDropdownItem, CoButton, CoText, CoIcon },
    i18n: {
        messages: {
            en: {
                labels: {
                    sortingByRelevance: 'Relevance',
                    sortingByLastActivity: 'Last Activity',
                    scopeMyCommunity: 'My Community',
                    scopeConnected: 'Connected Communities',
                },
            },
            de: {
                labels: {
                    sortingByRelevance: 'Relevanz',
                    sortingByLastActivity: 'Letzte Aktivität',
                    scopeMyCommunity: 'Meine Community',
                    scopeConnected: 'Verbundene Communities',
                },
            },
        },
    },
    data() {
        return {
            feed: [],
            feedNextPage: null, // used for single community
            loading: true,
            nextParams: null, // used for circles

            isCirclesOn: this.$store.state.circlesOn,
            // by default set to true if isCirclesOn is true, can be set to false. Only used for spaces within circles
            listingScopeConnnected: true,
            sortBy: 'lastactivity', // default sort by lastactivity can be changed to Score

            defaultSortingByRelenvance: this.$unleash.isEnabled('matching.default-user-listing-by-relevance'),
        };
    },
    watch: {
        $route(to, from) {
            // listingScopeConnnected
            // get scope from route query params
            if (to.query && to.query.scope) {
                if (to.query.scope === 'my-community') {
                    this.listingScopeConnnected = false;
                    this.sortBy = get(to, 'query.sortBy', 'lastactivity');
                    this.feed = [];
                    this.feedNextPage = null;
                    this.nextParams = null;
                    this.loading = true;
                    this.getCommunity();
                } else {
                    this.listingScopeConnnected = true;
                    this.sortBy = get(to, 'query.sortBy', 'lastactivity');
                    this.feed = [];
                    this.feedNextPage = null;
                    this.nextParams = null;
                    this.loading = true;
                    this.getCommunityForCircle();
                }
            }
        },
    },
    created() {
        if (this.$unleash.isEnabled('matching.default-user-listing-by-relevance')) {
            this.setSortingBy('score');
        }

        if (this.$route.query && this.$route.query.scope) {
            this.sortBy = get(this.$route, 'query.sortBy', 'lastactivity');

            if (this.$route.query.scope === 'my-community') {
                this.listingScopeConnnected = false;
            } else {
                this.listingScopeConnnected = true;
            }
        }
        window.addEventListener('scroll', this.scroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.scroll);
    },
    mounted() {
        this.scroll();
        if (this.isCirclesOn && this.listingScopeConnnected) {
            this.nextParams = null;
            this.getCommunityForCircle();
        } else {
            this.feedNextPage = null;
            this.getCommunity();
        }
    },
    computed: {
        me() {
            return this.$store.state.me;
        },
        listingScopeLabel() {
            if (this.listingScopeConnnected) {
                return this.$t('labels.scopeConnected');
            }
            return this.$t('labels.scopeMyCommunity');
        },
        sortByLabel() {
            if (this.sortBy === 'score') {
                return this.$t('labels.sortingByRelevance');
            }
            return this.$t('labels.sortingByLastActivity');
        },
    },
    methods: {
        setSortingBy(sortBy) {
            this.sortBy = sortBy;

            if (this.sortBy === 'score') {
                const queryParam = { ...this.$route.query, scope: 'my-community', sortBy: 'score' };
                this.$router.push({ query: queryParam });
            } else {
                const queryParam = { ...this.$route.query, sortBy: 'lastactivity' };
                this.$router.push({ query: queryParam });
            }
        },
        setScope(scope) {
            if (scope === 'my-community') {
                const queryParam = { ...this.$route.query, scope: 'my-community' };
                this.$router.push({ query: queryParam });
            } else {
                const queryParam = { ...this.$route.query, scope: 'connected', sortBy: 'lastactivity' };
                this.$router.push({ query: queryParam });
            }
        },
        getCommunityForCircle() {
            this.loading = true;
            const data = {
                Params: this.nextParams,
            };

            axios({
                method: 'POST',
                url: '/user/profile/list/circles',
                data: JSON.stringify(data),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data && response.data.Objects) {
                        let objs = response.data.Objects;
                        // sort objs by score
                        objs = objs.sort((a, b) => b.object.LastActivity - a.object.LastActivity);

                        // map feed items to Page type
                        const tmp = objs.map((item) => {
                            const u: User = {
                                id: item.object.ID,
                                name: item.object.Profile.Name,
                                headline: item.object.Profile.Bio,
                                image: item.object.Profile.ImageURL,
                                slug: item.object.Slug,
                                score: item.object.Score,
                            };

                            if (item && item.space && item.space.ID && item.space.ID !== this.$store.state.space.ID) {
                                u.space = {
                                    id: item.space.ID,
                                    name: item.space.Name,
                                    favicon: item.space.FaviconURL,
                                    primaryColor: item.space.PrimaryColor,
                                };
                            }
                            return u;
                        });

                        this.feed = [...this.feed, ...tmp];
                        this.nextParams = get(response, 'data.NextParams', null);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getCommunity() {
            this.loading = true;
            let url = '/user/profile/list';

            if (!this.listingScopeConnnected && this.sortBy === 'score') {
                url = '/user/profile/list?sortby=Score&direction=desc';
            }

            if (this.feedNextPage) {
                url = this.feedNextPage;
            }
            axios({
                method: 'GET',
                url,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.feedNextPage = response.data.next ? response.data.next : (this.feedNextPage = null);
                    if (!response.data.objects) return false;
                    // map feed items to Page type
                    response.data.objects.map((item) => {
                        const u: User = {
                            id: item.object.ID,
                            name: item.object.Profile.Name,
                            headline: item.object.Profile.Bio,
                            image: item.object.Profile.ImageURL,
                            slug: item.object.Slug,
                            score: item.object.Score,
                        };

                        if (item && item.space && item.space.ID) {
                            u.space = {
                                id: item.space.ID,
                                name: item.space.Name,
                                favicon: item.space.FaviconURL,
                                primaryColor: item.space.PrimaryColor,
                            };
                        }
                        this.feed.push(u);
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        scroll() {
            window.onscroll = () => {
                const distanceFromBottom = document.body.scrollHeight - window.innerHeight - window.scrollY;

                const percentage = (distanceFromBottom * 100) / (document.body.scrollHeight - window.innerHeight);

                if (percentage < 1) {
                    if (this.isCirclesOn && this.listingScopeConnnected && this.nextParams != null && !this.loading) {
                        this.getCommunityForCircle();
                    } else if (this.feedNextPage != null && !this.loading) {
                        this.getCommunity();
                    }
                }
            };
        },
        sendMessage(userObj) {
            if (!userObj || !userObj.id) return;
            const thread = {
                Participants: [this.$store.state.me.ID, userObj.id],
            };
            axios({
                method: 'POST',
                url: '/chat/t',
                withCredentials: true,
                data: JSON.stringify(thread),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.$router.push(`/messenger/${response.data.ID}`);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
