



























interface Item {
    value: number | string | object;
    displayText: string;
    color: string;
}

export default {
    name: 'CoDropdownList',
    props: {
        items: {
            type: Array as () => Item[],
            default: [],
            required: true,
        }, //
        firstItem: {
            default: null,
        }, //
        selected: {
            default: null,
        }, //
        loading: {
            type: Boolean,
            default: false,
        }, //
    },
};
