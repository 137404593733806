import { VueRenderer } from '@tiptap/vue-2';
import tippy from 'tippy.js';
import axios from 'axios';
import HashtagsList from './HashtagsList.vue';

export default {
    name: 'HashtagSuggestion',
    items: async ({ query }) => {
        if (!query) {
            return [];
        }

        const result = [];
        await axios({
            method: 'GET',
            url: `/tags/search?term=${query}`,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response && response.data) {
                    let tagForQueryNotExist = true;
                    response.data.forEach((entry) => {
                        if (entry.Slug === query.toLowerCase()) {
                            tagForQueryNotExist = false;
                            result.unshift({
                                name: entry.Slug,
                                slug: entry.Slug,
                                id: entry.ID,
                            });
                        } else {
                            result.push({
                                name: entry.Slug,
                                slug: entry.Slug,
                                id: entry.ID,
                            });
                        }
                    });

                    if (tagForQueryNotExist) {
                        result.unshift({
                            name: query,
                            slug: query,
                            id: query,
                        });
                    }
                } else {
                    result.unshift({
                        name: query,
                        slug: query,
                        id: query,
                    });
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 404) {
                    result.unshift({
                        name: query,
                        slug: query,
                        id: query,
                    });
                } else {
                    console.log(error);
                }
            });
        return result;
    },

    render: () => {
        let component;
        let popup;

        return {
            onStart: (props) => {
                component = new VueRenderer(HashtagsList, {
                    // using vue 2:
                    parent: this,
                    propsData: props,
                    // props,
                    editor: props.editor,
                });

                if (!props.clientRect) {
                    return;
                }

                popup = tippy('body', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => document.body,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start',
                });
            },

            onUpdate(props) {
                component.updateProps(props);

                if (!props.clientRect) {
                    return;
                }

                popup[0].setProps({
                    getReferenceClientRect: props.clientRect,
                });
            },

            onKeyDown(props) {
                if (props.event.key === 'Escape') {
                    popup[0].hide();

                    return true;
                }

                return component.ref.onKeyDown(props);
            },

            onExit() {
                popup[0].destroy();
                component.destroy();
            },

            command({ editor, range, props }) {
                console.log('command', editor, range, props);
            },
        };
    },
};
