




















































import axios from 'axios';
import { sortBy } from 'lodash';

import CoMenuItem from '@/components/Molecules/co-menu-item/CoMenuItem.vue';
import CoThumbnail from '@/components/Molecules/co-thumbnail/CoThumbnail.vue';

export default {
    name: 'ListChannels',
    components: {
        CoMenuItem,
        CoThumbnail,
    },
    props: {
        circlesOn: {
            type: Boolean,
            default: false,
            required: false,
        },
        currentSpaceID: {
            type: String,
            default: '',
            required: false,
        },
    },
    data() {
        return {
            channels: [],
        };
    },
    mounted() {
        if (this.circlesOn) {
            this.listChannelsForCircle();
        } else {
            this.listChannels();
        }
    },
    methods: {
        listChannelsForCircle() {
            axios
                .post('/channel/list/circles')
                .then((response) => {
                    if (response.data) {
                        const channels = response.data.Objects;
                        // map channels to menu items by adding URL and name
                        this.channels = channels.map((channel) => ({
                            id: channel.object.ID,
                            url: `/channels/${channel.object.Slug}?id=${channel.object.ID}`,
                            name: channel.object.Value,
                            space: channel.space,
                        }));

                        // sort channels alphabetically by name using lodash
                        this.channels = sortBy(this.channels, [(channel) => channel.name.toLowerCase()], ['desc']);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        listChannels() {
            axios
                .get('/project/channel/list')
                .then((response) => {
                    if (response.data) {
                        const channels = response.data.objects;
                        // map channels to menu items by adding URL and name
                        this.channels = channels.map((channel) => ({
                            url: `/channels/${channel.object.Slug}`,
                            name: channel.object.Value,
                        }));

                        // sort channels alphabetically by name using lodash
                        this.channels = sortBy(this.channels, [(channel) => channel.name.toLowerCase()], ['desc']);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
