
















































































































































































































































import Vue from 'vue';
import axios from 'axios';
import EventBus from '../../eventBus';

export default {
    name: 'PlanConfigurator',
    props: ['value', 'plans'],
    data() {
        return {
            plan: this.value,
            customized: false,

            totalPrice: 0.0,

            allExtras: [],
            loadingExtras: false,
            fields: [
                {
                    key: 'Name',
                    label: 'Name',
                    sortable: true,
                },
                {
                    key: 'Actions',
                    label: 'Actions',
                },
            ],

            taxRates: [],
            loadingTaxRate: false,
            taxRate: {},

            // all available resources
            bookingResources: [],
            // resource that will be add to plan
            newBookingResource: {
                ID: null,
                OverridePrice: false,
                Price: 0,
                Name: '',
                CreditHours: 0.0,
                CreditCents: 0,
            },
            newBookingResourceGroup: {
                ID: null,
                OverridePrice: false,
                Price: 0,
                Name: '',
                CreditHours: 0.0,
                ListResources: [],
                PriceOverride: 0.0,
                CreditCents: 0,
            },

            resource: null,
            resourceGroup: null,
        };
    },
    watch: {
        totalPrice(val) {
            this.plan.PriceInCents = Math.round(parseFloat(val) * 100);
        },
        plan(newVal, oldVal) {
            this.$emit('input', newVal);
        },
    },
    mounted() {
        this.listAllExtras();
        this.getTaxRates();
    },
    methods: {
        handleOk() {
            this.plan.PriceInCents = Number(this.totalPrice / 100);
            this.$emit('input', this.plan);
        },
        revertPlan() {
            this.$emit('input', this.plan);
        },
        show() {
            this.customized = true;
            this.$refs.planConfiguratorModal.show();
        },
        saveConfiguration() {
            this.$emit('save', this.plan);
        },
        addResource() {
            if (!this.plan.AvailableResources) {
                this.plan.AvailableResources = [];
            }
            this.$refs.addResourceModalComponent.showModal();
        },
        addResourceGroup() {
            if (!this.plan.AvailableResourceGroups) {
                this.plan.AvailableResourceGroups = [];
            }
            this.$refs.addResourceGroupModalComponent.showModal();
        },
        editResource(resource) {
            this.newBookingResource = resource;
            this.$refs.editResourceModalComponent.showModal(resource);
        },
        editResourceGroup(resourceGroup) {
            this.newBookingResourceGroup = resourceGroup;
            this.$refs.editResourceGroupModalComponent.showModal(resourceGroup);
        },
        deleteResource(resource) {
            const indexOfObject = this.plan.AvailableResources.findIndex((object) => object.ID === resource.ID);
            this.plan.AvailableResources.splice(indexOfObject, 1);
        },
        deleteResourceGroup(resourceGroup) {
            const indexOfObject = this.plan.AvailableResourceGroups.findIndex(
                (object) => object.Name === resourceGroup.Name
            );
            this.plan.AvailableResourceGroups.splice(indexOfObject, 1);
        },
        updatedResource(resource) {
            const indexOfObject = this.plan.AvailableResources.findIndex((object) => object.ID === resource.ID);
            this.plan.AvailableResources.splice(indexOfObject, 1);

            this.plan.AvailableResources.push(resource);

            // this.plan.AvailableResources.find((item) => item.ID === resource.ID)
        },
        addedResource(resource) {
            const indexOfObject = this.plan.AvailableResources.findIndex((object) => object.ID === resource.ID);

            if (indexOfObject > 0) {
                EventBus.$emit('ERROR', {
                    Message: 'Resource already listed',
                    Details: null,
                });
            } else {
                this.plan.AvailableResources.push(resource);
            }

            // this.plan.AvailableResources.find((item) => item.ID === resource.ID)
        },
        cancelAddResource() {
            this.newBookingResource = {
                ID: null,
                OverridePrice: false,
                Price: 0,
                Name: '',
                PricePerHour: 0,
                CreditHours: 0.0,
                CreditCents: 0,
            };

            this.$refs['modal-edit-resource'].hide();
        },
        selectResource(value) {
            const resource = this.bookingResources.find((item) => item.ID === value);

            if (resource.PricePerHour) {
                this.newBookingResource.Price = resource.PricePerHour;
                this.newBookingResource.Name = resource.Name;
            } else {
                this.newBookingResource.Price = 0.0;
                this.newBookingResource.Name = resource.Name;
            }
        },

        removeExtra(index) {
            this.plan.Extras.splice(index, 1);
        },
        addExtra(extra) {
            if (!this.plan.Extras) {
                this.plan.Extras = [extra];
            }
            this.plan.Extras.push(extra);
        },
        isExtraSelected(obj) {
            if (this.plan.Extras) {
                const index = this.plan.Extras.findIndex((object) => object.ID === obj.ID);
                return index > -1;
            }
            return false;
        },

        listAllExtras() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/extra/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.allExtras = response.data.Extras;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
        getTaxRates() {
            this.loadingTaxRate = true;
            axios({
                method: 'GET',
                url: `/admin/tax-rate/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.taxRates = response.data;
                    }

                    this.loadingTaxRate = false;
                })
                .catch((error) => {
                    this.loadingTaxRate = false;
                    console.log(error);
                });
        },
        updatedResourceGroup(resourceGroup) {
            const indexOfObject = this.plan.AvailableResourceGroups.findIndex(
                (object) => object.Name === resourceGroup.Name
            );
            this.plan.AvailableResourceGroups.splice(indexOfObject, 1);

            this.plan.AvailableResourceGroups.push(resourceGroup);

            // this.plan.AvailableResources.find((item) => item.ID === resource.ID)
        },
        addedResourceGroup(resourceGroup) {
            const indexOfObject = this.plan.AvailableResourceGroups.findIndex(
                (object) => object.Name === resourceGroup.Name
            );

            if (indexOfObject > 0) {
                EventBus.$emit('ERROR', {
                    Message: 'Resource group with same name already exists',
                    Details: null,
                });
            } else {
                this.plan.AvailableResourceGroups.push(resourceGroup);
            }

            // this.plan.AvailableResources.find((item) => item.ID === resource.ID)
        },

        centsToWholeUnit(cents: number): string {
            const price = cents / 100;
            return price.toFixed(2);
        },
    },
};
