
























import i18n from 'vue-i18n';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoTippy from '@/components/Atoms/co-tippy/CoTippy.vue';

export default {
    name: 'CoMatch',
    i18n: {
        messages: {
            en: {
                matchtooltip: 'This scale shows how well your profile matches this person.',
                matchtooltipmobile: 'Marks your top matches',
            },
            de: {
                matchtooltip: 'Diese Skala zeigt dir, wie gut dein Profil zu dieser Person passt.',
                matchtooltipmobile: 'Kenntzeichnet deine Top matches',
            },
        },
    },
    components: {
        CoIcon,
        CoTippy,
    },

    props: {
        score: {
            type: Number,
            required: false,
            default: 0,
        },

        size: {
            type: String,
            required: false,
            default: 'default',
            validator: (value) => ['default', 'small'].indexOf(value) !== -1,
        },
    },
};
