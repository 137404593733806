





























// replaces sidebar.vue to implement updated page grid
import { get } from 'lodash';
import CoLeftMainNavigation from '@/components/Organisms/co-left-main-navigation/CoLeftMainNavigation.vue';
import CoActiveMembersWidget from '@/components/Organisms/co-active-members-widget/CoActiveMembersWidget.vue';
import CoUpcomingEventsWidget from '@/components/Organisms/co-upcoming-events-widget/CoUpcomingEventsWidget.vue';

import UserMainGrid from '../../Templates/User/MainGrid/UserMainGrid.vue';

export default {
    name: 'UserMain',
    components: {
        UserMainGrid,
        CoLeftMainNavigation,
        CoActiveMembersWidget,
        CoUpcomingEventsWidget,
    },
    data() {
        return {
            asAdmin: get(this.$store, 'state.me.Permissions', []).includes('space_admin'),
            isCirclesOn: get(this.$store, 'state.circlesOn', null),
            spaceID: get(this.$store, 'state.space.ID', null),
            space: get(this.$store, 'state.space', {}),
            isInMobile: get(this.$store, 'state.isInMobile', false),
            metaGridOptions: {},
        };
    },
    watch: {
        // get route meta options for custom rendering of the main grid
        $route(to, from) {
            this.metaGridOptions = get(to, 'meta.mainGridOptions', {});
        },
    },
    mounted() {
        this.metaGridOptions = get(this.$route, 'meta.mainGridOptions', {});
    },
};
