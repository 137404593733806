<template>
    <div @click="reportBug">
        <slot>{{ $t('labels.reportbug') }}</slot>
    </div>
</template>

<script>
import i18n from 'vue-i18n';
import { initializeSdk, sdkOptions } from '../../../birdSdk';

export default {
    i18n: {
        messages: {
            en: {
                introductionScreen: {
                    title: '@:labels.reportbug',
                    message: 'Please show us what went wrong.',
                    takeScreenshotButton: 'Take a screenshot',
                    startRecordingButton: 'Start screen recording',
                    skipButton: '@:labels.skip',
                },
                recordingControls: {
                    starting: 'Starting...',
                    recording: 'Recording...',
                    recordingProgress: 'Recording for',
                    stopRecordingButton: 'Stop recording',
                },
                previewScreen: {
                    title: 'Report a bug',
                    titleInputPlaceholder: 'Add a title',
                    descriptionInputPlaceholder: 'Add a description',
                    emailInputPlaceholder: 'Add an email',
                    inputOptional: ' (optional)',
                    discardButton: 'Discard',
                    submitButton: 'Submit',
                    uploadError: 'Error. Try again',
                    uploadFinished: 'uploaded',
                    startScreenRecordingButton: 'Start screen recording',
                    visualProofMissingErrorMessage: 'Please add a visual proof to send your report.',
                    replaceVisualProofButton: 'Replace',
                    removeVisualProofButton: 'Remove',
                    confirmVisualProofRemovalButton: 'Confirm removal',
                    cancelButton: 'Cancel',
                },
                submitConfirmationScreen: {
                    title: 'Bug report submitted',
                    message:
                        'Thanks for reporting this issue to us. A member of the team will investigate this and get back to you soon.',
                    copyLink: 'Copy link to your bug report',
                    copiedLink: '&check; Link copied!',
                    confirmationButton: 'Done',
                },
            },
            de: {
                introductionScreen: {
                    title: '@:labels.reportbug',
                    message: 'Bitte zeige uns, was falsch gelaufen ist.',
                    takeScreenshotButton: 'Bildschirmfoto machen',
                    startRecordingButton: 'Bildschirmaufzeichnung machen',
                    skipButton: '@:labels.skip',
                },
                recordingControls: {
                    starting: 'Starten...',
                    recording: 'Aufzeichnen...',
                    recordingProgress: 'Aufzeichnung seit',
                    stopRecordingButton: 'Aufzeichnung stoppen',
                },
                previewScreen: {
                    title: '@:labels.reportbug',
                    titleInputPlaceholder: 'Titel hinzufügen',
                    descriptionInputPlaceholder: 'Beschreibung hinzufügen',
                    emailInputPlaceholder: 'E-Mail hinzufügen',
                    inputOptional: ' (optional)',
                    discardButton: '@:labels.discard',
                    submitButton: '@:labels.submit',
                    uploadError: 'Fehler. Erneut probieren',
                    uploadFinished: 'hochgeladen',
                    startScreenRecordingButton: 'Bildschirmaufnahme starten',
                    visualProofMissingErrorMessage:
                        'Bitte füge einen visuellen Beweis hinzu, um deinen Bericht zu versenden.',
                    replaceVisualProofButton: 'Ersetzen',
                    removeVisualProofButton: 'Entfernen',
                    confirmVisualProofRemovalButton: 'Entfernen bestätigen',
                    cancelButton: 'Abbrechen',
                },
                submitConfirmationScreen: {
                    title: 'Fehlerbericht gesendet',
                    message:
                        'Vielen Dank, dass du uns dieses Problem gemeldet hast. Ein Mitglied des Teams wird das Problem untersuchen und sich bald bei dir melden.',
                    copyLink: 'Link zu deinem Fehlerbericht kopieren',
                    copiedLink: '&check; Link kopiert!',
                    confirmationButton: 'Fertig',
                },
            },
        },
    },
    name: 'CoBugReportButton',
    data() {
        return {
            isCompatibleWithSdk: true,
        };
    },
    mounted() {
        this.initializeSdk();
    },
    methods: {
        initializeSdk() {
            if (window.birdeatsbug) return;

            const user = {
                email: '',
            };

            if (this.$store && this.$store.state && this.$store.state.me && this.$store.state.me.Email) {
                user.email = this.$store.state.me.Email;
            }

            initializeSdk({
                ...sdkOptions,
                user: {
                    ...user,
                },
                hooks: {
                    afterInit: ({ isBrowserSupported }) => (this.isCompatibleWithSdk = isBrowserSupported),
                },
            });
        },
        translateSdk() {
            const beb = window.birdeatsbug;
            if (!beb) return;
            // translate the ui
            beb.setOptions({
                ui: {
                    text: {
                        introductionScreen: {
                            title: this.$t('introductionScreen.title'),
                            message: this.$t('introductionScreen.message'),
                            takeScreenshotButton: this.$t('introductionScreen.takeScreenshotButton'),
                            startRecordingButton: this.$t('introductionScreen.startRecordingButton'),
                            skipButton: this.$t('introductionScreen.skipButton'),
                        },
                        recordingControls: {
                            starting: this.$t('recordingControls.starting'),
                            recording: this.$t('recordingControls.recording'),
                            recordingProgress: this.$t('recordingControls.recordingProgress'),
                            stopRecordingButton: this.$t('recordingControls.stopRecordingButton'),
                        },
                        previewScreen: {
                            title: this.$t('previewScreen.title'),
                            titleInputPlaceholder: this.$t('previewScreen.titleInputPlaceholder'),
                            descriptionInputPlaceholder: this.$t('previewScreen.descriptionInputPlaceholder'),
                            emailInputPlaceholder: this.$t('previewScreen.emailInputPlaceholder'),
                            inputOptional: this.$t('previewScreen.inputOptional'),
                            discardButton: this.$t('previewScreen.discardButton'),
                            submitButton: this.$t('previewScreen.submitButton'),
                            uploadError: this.$t('previewScreen.uploadError'),
                            uploadFinished: this.$t('previewScreen.uploadFinished'),
                            visualProofMissingErrorMessage: this.$t('previewScreen.visualProofMissingErrorMessage'),
                            startScreenRecordingButton: this.$t('previewScreen.startScreenRecordingButton'),
                            visualProofMissingErrorMessage: this.$t('previewScreen.visualProofMissingErrorMessage'),
                            replaceVisualProofButton: this.$t('previewScreen.replaceVisualProofButton'),
                            removeVisualProofButton: this.$t('previewScreen.removeVisualProofButton'),
                            confirmVisualProofRemovalButton: this.$t('previewScreen.confirmVisualProofRemovalButton'),
                            cancelButton: this.$t('previewScreen.cancelButton'),
                        },
                        submitConfirmationScreen: {
                            title: this.$t('submitConfirmationScreen.title'),
                            message: this.$t('submitConfirmationScreen.message'),
                            copyLink: this.$t('submitConfirmationScreen.copyLink'),
                            copiedLink: this.$t('submitConfirmationScreen.copiedLink'),
                            confirmationButton: this.$t('submitConfirmationScreen.confirmationButton'),
                        },
                    },
                },
            });
        },
        reportBug() {
            if (!window.birdeatsbug) this.initializeSdk();
            this.translateSdk();
            window.birdeatsbug.trigger();
        },
    },
};
</script>
