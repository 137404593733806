<!-- 
    <coTagList> 
    a list of tags
    'editable' flag enables user interactions with the list; removing and adding tags
    emits 'tagsChanged' event when the list of tags is edited by the user

 -->
<template>
    <div>
        <CoTag
            v-for="(item, index) in newItems"
            :key="index"
            :value="item"
            :title="item"
            :trailingIcon="editable ? 'x-lg' : false"
            class="clickable"
            @click.native="editable ? removeTag(item) : tagClicked(item)"
        />
        <CoInputWithAutoComplete
            v-if="editable"
            ref="coInput"
            type="text"
            icon="plus-lg"
            class="d-inline-block"
            :placeholder="placeholder"
            :autocompleteMethod="tagsAutocompleteMethod"
            @enter="addTag"
        />
    </div>
</template>

<script>
export default {
    name: 'CoMultipleSelectList',
    props: {
        placeholder: String,
        editable: Boolean,
        items: Array,
    },
    data() {
        return {
            newItems: this.items,
        };
    },
    methods: {
        tagsAutocompleteMethod(searchValue) {
            var items = items.filter(this.items.includes(searchValue));
            return items;
        },
        addTag(event) {
            const newValue = this.$refs.coInput.inputValue.replace(/[\s]+/gi, '-').toLowerCase();

            if (!newValue.length > 0) return;

            if (this.newItems.length < 1) {
                this.newItems = [newValue];
            } else {
                this.newItems.push(newValue);
            }

            this.$refs.coInput.resetValue();
            this.$emit('tagsChanged', this.newItems);
        },
        removeTag(tag) {
            const index = this.newItems.indexOf(tag);
            if (index > -1) {
                this.newItems.splice(index, 1);
            }
            this.$emit('tagsChanged', this.newItems);
        },
        tagClicked(event) {
            this.$emit('tagClicked', event);
        },
    },
};
</script>

<style scoped>
.clicable {
    cursor: pointer;
}
</style>
