<style lang="less" scoped>
@import './CoCreateResourceCancellationPage.less';

@import '~@/assets/less/variables.less';
.round-unify {
    border-radius: 1rem !important;
    overflow: hidden;
}

@media (min-width: 768px) {
    .col-image {
        height: 70vh;
    }
    .column {
        min-height: 70vh;
    }
}
</style>

<template>
    <div class="bg-white p-0 p-lg-3 w-100 round-unify pb-5" style="overflow-y: visible !important">
        <div><CoLabel text="Cancellation fee"></CoLabel></div>
        <div>
            <CoCheckbox name="Activate"></CoCheckbox>
        </div>
        <div v-for="(period, index) in cancelationPeriods" :key="index">
            <div class="mt-2">
                <CoLabel text="Cancelation period"></CoLabel>
            </div>
            <div class="d-flex align-items-center justify-content-between flex-wrap">
                <div class="d-flex align-items-center">
                    <div>
                        <CoInput type="number" value="24"></CoInput>
                    </div>
                    <div class="ml-2">
                        <CoSelect
                            :items="['Calendar days']"
                            value="Calendar days"
                            :listOfStrings="true"
                            :greyTheme="true"
                        ></CoSelect>
                    </div>
                </div>
                <div v-if="index != 0">
                    <div @click="removePeriod(index)">
                        <CoIcon name="trash" class="ml-2 pointer"></CoIcon>
                    </div>
                </div>
            </div>
            <div class="mt-2"><CoLabel text="Fee"></CoLabel></div>
            <div class="d-flex align-items-center">
                <div class="flex-grow-1">
                    <CoInput placeholder="Fee"> </CoInput>
                </div>
                <div class="ml-2">
                    <CoSelect :items="[{ Name: 'EUR', Value: 'EUR' }]" value="EUR" variant="grey"></CoSelect>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <CoButton
                class="text-muted"
                iconleft="plus"
                label="Add cancelation period"
                type="outline"
                @onClick="addCancelationPeriod"
            ></CoButton>
        </div>
    </div>
</template>

<script>
import EventBus from '../../../eventBus';
import axios from 'axios';

export default {
    name: 'CoCreateResourceCancellationPage',
    data() {
        return {
            me: this.$store.state.me,

            cancelationPeriods: [0],

            loading: false,
            index: 0,

            id: this.$route.params.ID, // current or upcoming
        };
    },
    created() {},

    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;

            if (this.windowWidth >= 768) {
                this.minWidth = true;
            } else {
                this.minWidth = false;
            }
        });
    },
    watch: {
        bookingInterval: function (newVal) {
            this.timeSpan = !newVal;
        },
        timeSpan: function (newVal) {
            this.bookingInterval = !newVal;
        },
    },
    methods: {
        addCancelationPeriod() {
            this.cancelationPeriods.push(this.index + 1);
            this.index += 1;
        },
        removePeriod(index) {
            this.cancelationPeriods.splice(index, 1);
        },
    },
};
</script>
