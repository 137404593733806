<template>
    <div class="min-vh-100 d-flex bg justify-content-center align-items-md-center">
        <div class="login-form mt-4 mt-md-0 p-4 bg-white round-unify text-center">
            <img :src="$store.state.space.LogoURL" alt="logo" height="64" />
            <div class="card-body w-100 py-4">
                <div v-if="loading" class="text-center">
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                    <p class="mt-2">Retrieving access token...</p>
                </div>

                <div v-else-if="!loading && error" class="alert bg-white">
                    <p class="pb-0">{{ error.message }}</p>
                    <small>{{ error.details }}</small> <br />
                    <small>Details: {{ error.response }}</small>
                </div>

                <div v-else class="alert bg-white">
                    <p class="pb-0">Access granted.</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { isNil } from 'lodash';

export default {
    data() {
        return {
            loading: false,
            error: null,

            code: this.$route.query.code,
            state: this.$route.query.state,
        };
    },
    mounted() {
        this.auth();
    },

    methods: {
        auth() {
            this.loading = true;
            this.$store
                .dispatch('authenticateWithOA2Provider', {
                    code: this.code,
                    state: this.state,
                })
                .then(({ isLogin }) => {
                    this.loading = false;
                    if (isLogin) {
                        this.commitLogin();
                    } else {
                        this.continueWithSignup();
                    }
                })
                .catch((err) => {
                    this.loading = false;
                    console.error(err);
                });
        },
        commitLogin() {
            this.$store
                .dispatch('getMe', null)
                .then((response) => {
                    this.loading = false;
                    this.$root.$data.$me = response.data;
                    this.$router.push('/feed');
                })
                .catch((error) => {
                    this.loading = false;
                    const errorMessage = String('missing or malformed jwt');
                    if (error.response.data.message === errorMessage) {
                        this.$store.commit('LOGOUT');
                    }
                });
        },
        continueWithSignup() {
            if (!isNil(this.$store.state.newMembership.Plan)) {
                //  continue with address
                this.$store.commit('SET_STEP', 3);
                this.$router.push('/registerv2');
            } else {
                // select plan
                this.$router.push('/plans');
            }
        },
    },
};
</script>
