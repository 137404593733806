




































































































import SettingsCard from '@/components/admin/SettingsCard.vue';

export default {
    name: 'SettingsMenu',
    components: { SettingsCard },
    data() {
        return {
            space: this.$store.state.space,
        };
    },
    created() {},
    mounted() {},
};
