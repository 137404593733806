var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:("reactions-" + _vm.uid + "-" + _vm.rerender),staticClass:"co-reactions d-flex flex-wrap"},[_vm._l((_vm.reactions),function(reaction,index){return _c('div',{key:(_vm.uid + "-" + index),staticClass:"reaction d-flex align-items-center",on:{"click":function($event){$event.stopPropagation();return _vm.reactionHandler(index)}}},[(reaction.Users && reaction.Users.length)?_c('co-tippy',{attrs:{"tippyOptions":{
                content: _vm.getUserNames(reaction.Users).join('<br>'),
                allowHTML: true,
                touch: 'hold',
            }}},[_c('div',{staticClass:"reaction-icon d-flex align-items-center",class:{
                    me: _vm.byMe(reaction.Users),
                }},[_vm._v(" "+_vm._s(index)),_c('span',{staticClass:"counter d-inline-block ml-1 text-left",style:({
                        width:
                            reaction.Users && reaction.Users.length
                                ? reaction.Users.length / 10 < 1
                                    ? '.7rem'
                                    : '1.4rem'
                                : null,
                    })},[_vm._v(_vm._s(reaction.Users ? reaction.Users.length : null))])])]):_vm._e()],1)}),_c('co-emoji-picker',{on:{"picked":_vm.reactionHandler}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }