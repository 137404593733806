




import CoModal from '../co-modal/CoModal.vue';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoCheckbox from '../../Atoms/co-checkbox/CoCheckbox.vue';
import CoDatePicker from '../../Atoms/co-date-picker/CoDatePicker.vue';

import { format, setHours, setMinutes, setSeconds } from 'date-fns';

export default {
    name: 'CoSubscriptionUpdateScheduleModal',
    components: {
        CoModal,
        CoButton,
        CoCheckbox,
        CoDatePicker,
    },
    props: {
        currentPeriodEnd: {
            type: Number,
            required: true,
            default: 0,
        },
    },
    data() {
        return {
            schedule: {
                type: Object,
                required: true,
            },
        };
    },
    methods: {
        updateSchedule() {
            this.$emit('update-schedule', this.schedule);
        },
    },
};
