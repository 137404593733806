







































































































































































































































































































































































import axios from 'axios';
import { get, filter } from 'lodash';
import EventBus from '../../../eventBus.js';

import CoTippy from '../../Atoms/co-tippy/CoTippy.vue';
import CoRoundButton from '../../Atoms/co-round-button/CoRoundButton.vue';
import CoDropdown from '../../Molecules/co-dropdown/CoDropdown.vue';
import CoDropdownItem from '../../Molecules/co-dropdown-item/CoDropdownItem.vue';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoInput from '../../Molecules/co-input/CoInput.vue';
import CoThumbnail from '../../Molecules/co-thumbnail/CoThumbnail.vue';
import CoBugReportButton from '../co-bug-report-button/CoBugReportButton.vue';
import CoLeftMainNavigation from '../co-left-main-navigation/CoLeftMainNavigation.vue';

export default {
    name: 'CoTopNavBarWeb',
    components: {
        CoTippy,
        CoRoundButton,
        CoDropdown,
        CoDropdownItem,
        CoButton,
        CoInput,
        CoThumbnail,
        CoBugReportButton,
        CoLeftMainNavigation,
    },
    props: {
        spaceName: {
            type: String,
        },
        logoURL: {
            type: String,
            default: null,
        },
        SupportURL: {
            type: String,
            default: null,
        },
        me: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            selected: null,
            PrivacyPolicy: null,
            isMarketOn: get(this.$store, 'state.space.MarkeplaceOn', false),
            isBookingOn: get(this.$store, 'state.space.BookingOn', false),
            isCirclesOn: get(this.$store, 'state.circlesOn', false),
            spaceID: get(this.$store, 'state.space.ID', null),
            privacyPolicyUrl: get(this.$store, 'state.space.PrivacyPolicyURL', null),
            space: get(this.$store, 'state.space', {}),
            isInMobile: get(this.$store, 'state.isInMobile', false),
            search: null,
            searchActive: false,
            sideBarShow: false,
            unleash: get(this, '$unleash', null),
            windowWidth: get(window, 'innerWidth', 100),
            menuYposition: 0,
            menuWidth: 0,
            customMenuItems: [],
            rerenderCreateMenu: 0,
            newNotificationsCount: 0,
            newMessagesCount: 0,
            newTasksCount: 0,
        };
    },
    mounted() {
        // load custom menu items
        this.getCustomMenuItems();
        // get the bounding rect of the navbar and calculate the position for the mobile menu accordingly
        const menuRect = this.$refs.TopBarWeb.getBoundingClientRect();
        this.menuYposition = get(menuRect, 'y', 0) + get(menuRect, 'height', 0);
        this.menuWidth = get(menuRect, 'width', 0);
        // subscribe to new messages and new notifications events
        EventBus.$on(
            `NEWMESSAGES`,
            (count) => {
                this.newMessagesCount = count;
            },
            this
        );
        EventBus.$on(
            `NEWNOTIFICATIONS`,
            (count) => {
                this.newNotificationsCount = count;
            },
            this
        );

        EventBus.$on(
            `NEWTASKS`,
            (count) => {
                this.newTasksCount = count;
            },
            this
        );
        // get new notifications
        this.getNewNotifications();
        // get new messages
        this.getNewMessages();
    },
    computed: {
        profileImage() {
            return get(this.me, 'Profile.ImageURL', null);
        },
        isAdmin() {
            return get(this.$store, 'state.me.Permissions', []).includes('space_admin');
        },
        notificationsPaused() {
            return get(this.me, 'NotificationsOffUntill', 0) > Date.now() / 1000;
        },
        query() {
            return get(this.$store, 'query.term', null);
        },
        createMenuItems() {
            return filter(get(this, 'customMenuItems', []), { Type: 2, Hidden: false });
        },
        userMenuItems() {
            return filter(get(this, 'customMenuItems', []), { Type: 1, Hidden: false });
        },
        isUserAllowedToCreateEvent() {
            return this.isAdmin || !get(this, '$store.state.permissions.EventsCreateAdminsOnly', false);
        },
    },
    watch: {
        sideBarShow(newVal, oldVal) {
            if (newVal) {
                document.body.classList.add('prevent-scroll');
            } else {
                this.$refs.mobileMenu.hide();
                document.body.classList.remove('prevent-scroll');
            }
        },
    },
    methods: {
        goToBookingCalendar() {
            this.$router.push('/booking-calendar').catch(() => {
                this.$root.$emit('ClearBookingCalendarFilters');
            });
        },
        onSearchBlur(event) {
            this.$refs.search.resetValue();
            this.$refs.search.blur();
            this.searchActive = false;
        },
        makeSearch(searchterm) {
            const suggestions = this.searchAutocompletes;
            if (searchterm) {
                this.search = searchterm;
                this.$router.push({
                    name: 'search',
                    query: {
                        term: searchterm,
                    },
                });
            } else if (this.search === '') {
                this.$router.push({
                    name: 'Timeline',
                });
            } else {
                this.$router.push({
                    name: 'search',
                    query: {
                        term: this.search,
                    },
                });
            }
        },
        /*
            logOut Function
        */
        logOut() {
            localStorage.removeItem('_authtoken');
            this.$router.go('/login');
            // this.$store.dispatch("logout").then(() => {
            // }).catch(error => {
            //   console.log(error);
            // });
        },

        getNewNotifications() {
            if (!this.$store) {
                return;
            }
            this.$store
                .dispatch('getGetNewNotifications')
                .then((response) => {}, this)
                .catch((error) => {});
        },
        getNewMessages() {
            if (!this.$store) {
                return;
            }
            this.$store
                .dispatch('getNewMessagesNumbers', null)
                .then((response) => {}, this)
                .catch((error) => {});
        },
        getCustomMenuItems() {
            axios
                .get('/dashboard/custom-menu-options/list')
                .then((response) => {
                    this.customMenuItems = get(response, 'data', []);
                    if (!this.customMenuItems || this.customMenuItems.length === 0) {
                        this.customMenuItems = [];
                    }
                })
                .catch((error) => {
                    this.customMenuItems = [];
                })
                .finally(() => {
                    this.rerenderCreateMenu += 1;
                });
        },
    },
};
