<template>
    <div
        @click="onClick"
        class="co-tag d-inline-flex"
        :class="{ [variant]: true, [size]: true, clickable: true, 'pl-0': leadingIcon, 'pr-0': trailingIcon }"
    >
        <CoIcon v-if="leadingIcon" :name="leadingIcon" class="align-self-center"></CoIcon>
        <span class="align-self-center flex-shrink-1">{{ value }}</span>
        <CoIcon v-if="trailingIcon" :name="trailingIcon" class="align-self-center flex-shrink-0"></CoIcon>
    </div>
</template>
<style lang="less" scoped>
@import '../../../assets/less/variables.less';
@import './CoTag.less';
</style>
<script>
import { size } from 'lodash';
import CoIcon from '../co-icon/CoIcon.vue';

export default {
    name: 'CoTag',
    components: {
        CoIcon,
    },
    props: {
        value: {
            type: String,
            default: '',
        },
        leadingIcon: {
            type: String,
            default: '',
        },
        trailingIcon: {
            type: String,
            default: '',
        },
        variant: {
            type: String,
            default: 'default',
            validator: (value) => ['default', 'secondary'].includes(value),
        },
        size: {
            type: String,
            default: 'md', // md = height: 36px, sm = height: 24px
            validator: (value) => ['sm', 'md'].includes(value),
        },

        clickable: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        onClick() {
            if (this.clickable) {
                this.$emit('click');
            }
        },
    },
};
</script>
