<template>
    <div>
        <div class="ml-md-4">
            <div class="d-md-flex align-items-center justify-content-between p-0">
                <h2>WiFi access (radius server)</h2>
            </div>

            <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
                <div class="mt-4 bg-white p-5 pround-unify">
                    <h3>Your radius server configuration</h3>
                    <br />
                    <div>
                        <p><b>Radius Server Host:</b> {{ this.space['RadiusServerHost'] }}</p>
                        <p><b>Radius Server Port:</b> {{ this.space['RadiusServerPort'] }}</p>
                        <p><b>Radius Server Secret:</b> {{ this.space['RadiusServerSecret'] }}</p>
                    </div>
                </div>
            </b-overlay>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import EventBus from '../../../eventBus';

export default {
    name: 'WiFiAccess',

    data() {
        return {
            loading: false,

            spaceID: '',
            nameState: null,
            space: null,
        };
    },
    computed: {},
    created() {
        this.getSpaceSettings();
    },
    mounted() {},
    watch: {},
    methods: {
        getSpaceSettings() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/admin/space/settings',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data) {
                        this.space = response.data;
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to get space settings',
                        Details: '',
                    };
                    EventBus.$emit('ERROR', msg);
                });
        },
    },
};
</script>
