









































































































































import Axios from 'axios';
import i18n from 'vue-i18n';

export default {
    i18n: {
        messages: {
            en: {
                resethelptext:
                    'Enter the email address you registered with and we will send you a password reset link to your inbox.',
                resetlinksent:
                    'Please check your email inbox. We have just sent you a link to reset your password.  If it doesn’t appear within a few minutes, check your spam folder.',
                errors: {
                    emailnotfound:
                        'This address is not a verified primary email or is not associated with a user account.',
                },
            },
            de: {
                resethelptext:
                    'Gib die E-Mail-Adresse ein, mit der du dich registriert hast, und wir schicken dir einen Link zum Zurücksetzen deines Passworts an deinen Posteingang.',
                resetlinksent:
                    'Bitte prüfe deinen E-Mail-Posteingang. Wir haben dir gerade einen Link zum Zurücksetzen deines Passworts geschickt.  Wenn er nicht innerhalb weniger Minuten erscheint, prüfe deinen Spam-Ordner.',
                errors: {
                    emailnotfound:
                        'Bei dieser Adresse handelt es sich nicht um eine verifizierte primäre E-Mail-Adresse oder sie ist nicht mit einem Benutzerkonto verbunden.',
                },
            },
        },
    },
    name: 'ResetPassword',
    data() {
        return {
            email: null,
            error: false,
            loading: false,
            step: 1,
        };
    },
    mounted() {},

    methods: {
        resetPassword() {
            if (!this.email || this.email.length < 1) return null;
            this.loading = true;
            this.error = null;
            const data = JSON.stringify({ Email: this.email.toLowerCase() });
            Axios({
                method: 'POST',
                url: '/user/reset-password',
                withCredentials: true,
                data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    this.step = 2;
                })
                .catch((error) => {
                    this.error = error;
                    this.loading = false;
                    console.log(error);
                });
        },
    },
};
