<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.no-style {
    color: black;
    text-decoration: none;
}
</style>

<template>
    <div>
        <!-- header start -->
        <Header :title="$t('labels.planchange')"></Header>
        <!-- header end -->
        <div class="" style="max-width: 600px">
            <b-overlay :show="loading">
                <div v-if="errorMsg" class="d-flex align-items-center pt-4 ml-3">
                    <b-alert show variant="danger">{{ errorMsg }}</b-alert>
                </div>
                <div v-else-if="plans && plans.length > 0">
                    <div v-for="plan in plans" :key="plan.ID" class="mb-3">
                        <CoCardPlan :plan="plan" clickable show-image @click="selectPlan(plan.ID)"></CoCardPlan>
                    </div>
                </div>

                <div v-else-if="!loading && (!plans || plans.length === 0)">
                    <div class="d-flex align-items-center pt-4 ml-3">
                        <span class="ml-3">{{ $t('noplanavailable') }}</span>
                    </div>
                </div>
            </b-overlay>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import i18n from 'vue-i18n';
import CoCardPlan from '@/components/Molecules/co-card-plan/CoCardPlan.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';

export default {
    i18n: {
        messages: {
            en: {
                noplanavailable: 'There are currently no plans available for you.',
            },
            de: {
                noplanavailable: 'Derzeit sind keine Tarife verfügbar.',
            },
        },
    },
    name: 'ChangePlan',
    components: {
        CoCardPlan,
        CoText,
    },
    data() {
        return {
            plans: [],
            loading: false,
            errorMsg: null,
            paymentMethods: [],
        };
    },
    created() {
        this.listPaymentMethods();
    },
    methods: {
        selectPlan(id) {
            this.$router.push(`/account/plan/change/${id}`);
        },
        listPlans() {
            this.loading = true;
            axios({
                method: 'GET',
                url: 'space/plan/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.plans = response.data;
                        this.loading = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        listPaymentMethods() {
            this.loading = true;
            this.$store
                .dispatch('getPaymentMethods')
                .then((paymentMethods) => {
                    this.loading = false;
                    this.paymentMethods = [];
                    this.paymentMethods = paymentMethods;
                    if (this.paymentMethods && this.paymentMethods.length != 0) {
                        this.listPlans();
                    } else {
                        this.errorMsg =
                            "You don't have any payment method. Please create one before subscribing to plan";
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    console.error(error);
                    if (error.response && error.response.status && error.response.status === 404) {
                        this.errorMsg =
                            "You don't have any payment method. Please create one before subscribing to plan";
                    } else {
                        this.errorMsg =
                            "You don't have any payment method. Please create one before subscribing to plan";
                    }
                });
        },
    },
};
</script>
