<template>
    <div>
        <div>
            <div class="d-md-flex align-items-center justify-content-between p-0">
                <h2>Event Types</h2>
                <b-button variant="primary" v-b-modal.modal-create
                    ><b-icon icon="plus"></b-icon>create new event type</b-button
                >
            </div>

            <div>
                <div class="mt-4 d-md-flex align-items-center justify-content-between">
                    <div class="filter-items">
                        <div class="d-flex w-100 align-items-center bg-white search-bar-wrapper rounded-pill">
                            <b-icon class="mr-2" icon="search"></b-icon>
                            <div class="search-input-wrapper">
                                <input
                                    class="search-input"
                                    type="search"
                                    v-model="tableFilter"
                                    placeholder="Filter items"
                                    aria-label="Search"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-4">
                    <b-table
                        striped
                        hover
                        :items="objects"
                        :fields="fields"
                        :per-page="perPage"
                        sort-icon-left
                        stacked="md"
                        :filter="tableFilter"
                        :current-page="currentPage"
                        @filtered="onFiltered"
                        class="table table-borderless table-responsive w-100 d-md-table"
                    >
                        <template v-slot:cell(Actions)="row">
                            <b-button size="sm" class="mr-2" variant="danger" @click="deleteEventType(row.item)"
                                >Delete</b-button
                            >
                        </template>
                    </b-table>
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        aria-controls="my-table"
                    ></b-pagination>
                </div>
            </div>
        </div>

        <b-modal
            id="modal-create"
            ref="modal"
            title="Create new type"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
        >
            <b-overlay :show="loading" spinner-variant="primary" class="p-0 m-0">
                <form ref="form" @submit.stop.prevent="handleSubmit">
                    <b-form-group
                        :state="nameState"
                        label="Name your event type"
                        label-for="name-input"
                        invalid-feedback="Name is required"
                    >
                        <b-form-input
                            id="name-input"
                            class="rounded-pill"
                            v-model="name"
                            :state="nameState"
                            required
                        ></b-form-input>
                    </b-form-group>
                </form>
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import EventBus from '../../../eventBus';

export default {
    name: 'EventTypes',
    data() {
        return {
            objects: [],
            name: '',
            nameState: null,
            submittedNames: [],
            loading: false,

            // table model
            tableFilter: '',
            perPage: 20,
            currentPage: 1,
            totalRows: 0,

            fields: [
                {
                    key: 'Value',
                    label: 'Name',
                    sortable: true,
                },
                {
                    key: 'Slug',
                    label: 'Slug',
                    sortable: true,
                },
                {
                    key: 'Actions',
                    label: 'Actions',
                },
            ],
        };
    },
    created() {},
    mounted() {
        this.getTypes();
    },
    methods: {
        deleteEventType(obj) {
            const data = JSON.stringify(obj);
            axios({
                method: 'DELETE',
                url: '/admin/event/type',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.objects = [];
                    this.getTypes();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        getTypes(next) {
            this.$store
                .dispatch('listEventTypes', next)
                .then((response) => {
                    if (response.objects) {
                        this.objects = this.objects.concat(response.objects);

                        this.totalRows = this.objects.length;

                        if (response.next != '' && response.next != null) {
                            this.getCategories(response.next);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // todo show error
                });
        },
        createType(name) {
            this.$store
                .dispatch('createEventType', name)
                .then((response) => {
                    this.objects.push(response);
                    this.loading = false;
                    this.$refs.modal.hide();
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                    if (error && error.response && error.response.status == 409) {
                        EventBus.$emit('ERROR', {
                            Message: 'Event type with this name already exists.',
                            Details: '',
                        });
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: 'Failed to create type due to internal error. Please, contact support',
                            Details: '',
                        });
                    }
                });
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity();
            this.nameState = valid;
            return valid;
        },
        resetModal() {
            this.name = '';
            this.nameState = null;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.handleSubmit();
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }
            // Push the name to submitted names
            this.loading = true;
            this.createType(this.name);
        },
    },
};
</script>
