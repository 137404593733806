<template>
    <div
        class="lzy-img-container round-unify-xs"
        :class="{
            'is-loaded': ready,
        }"
        :empty="error || !src"
    >
        <img
            class="lazy-load lzy-img-loader pointer"
            :src="imgSrc"
            width="auto"
            height="100"
            :ready="ready"
            @load="onLoad()"
            @error="onError()"
        />
    </div>
</template>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.lzy-img-container {
    position: relative;
    overflow: hidden;
    height: 100%;
}

img {
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

img[ready] {
    opacity: 1;
}
</style>
<script>
export default {
    name: 'LazyLoadImg',
    props: {
        src: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            ready: false,
            error: false,
            imgSrc: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
            imgSrcOriginal:
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
        };
    },

    watch: {
        src(newVal, oldVal) {
            this.ready = false;
            this.error = false;
            this.imgSrc = this.imgSrcOriginal;
            if (this.src == null || this.src == '') {
                // if no image source url is given with props, there is no need to observe the image for lazy loading
                // instead, just show the default image
                return;
            }

            /*
            event listener to load image
            only when entering the viewport
            */
            const that = this;
            const images = this.$el.querySelectorAll('img');
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((image) => {
                        if (image.isIntersecting) {
                            observer.unobserve(image.target);
                            that.ready = false;
                            that.imgSrc = that.$props.src;
                        }
                    });
                },
                {
                    root: null,
                    rootMargin: '0px 0px 200px 0px',
                }
            );

            images.forEach((image) => observer.observe(image));
        },
    },
    mounted() {
        if (this.src == null || this.src == '') {
            // if no image source url is given with props, there is no need to observe the image for lazy loading
            // instead, just show the default image
            return;
        }

        /*
        event listener to load image
        only when entering the viewport
        */
        const that = this;
        const images = this.$el.querySelectorAll('img');
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((image) => {
                    if (image.isIntersecting) {
                        observer.unobserve(image.target);
                        that.ready = false;
                        that.imgSrc = that.$props.src;
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px 0px 200px 0px',
            }
        );

        images.forEach((image) => observer.observe(image));
    },
    methods: {
        onLoad() {
            if (this.src == null || this.src == '') {
                // if no image source url is given with props, don't let the image set to ready because the base64 placeholder image is loaded.
                // instead, just show the default image
                return;
            }
            if (this.imgSrc == this.imgSrcOriginal) {
                // if the base64 defaultsrc is still there, then actual image has not loaded
                return;
            }

            this.ready = true;
        },
        onError() {
            this.error = true;
            this.ready = false;
        },
    },
};
</script>
