<template>
    <div>
        <!-- tax-rate start -->
        <div class="p-0 pl-sm-4 m-0">
            <b-overlay :show="loading" class="round-unify my-4">
                <div class="bg-white round-unify p-4">
                    <b-form ref="tax-rate-form" class="d-flex flex-column">
                        <b-form-group
                            id="input-group-tax-type"
                            label="Tax type"
                            label-for="tax-type-selector"
                            label-class="label-align"
                        >
                            <b-form-select
                                id="tax-type-selector"
                                class="rounded-pill"
                                v-model="form.Type"
                                :options="taxType"
                                required
                            ></b-form-select>
                        </b-form-group>

                        <b-form-group
                            id="input-group-name"
                            label="Name"
                            label-for="input-name"
                            aria-describedby="name-description"
                            label-class="label-align"
                        >
                            <b-form-input
                                id="input-name"
                                v-model="form['DisplayName']"
                                type="text"
                                required
                                placeholder="Enter tax rate name"
                                class="rounded-pill"
                            ></b-form-input>
                            <b-form-text id="name-description" class="ml-3"
                                >The display name of the tax rate, which will be shown to users. E.g. 'VAT'</b-form-text
                            >
                        </b-form-group>

                        <b-form-group
                            id="input-group-description"
                            label="Description (Optional)"
                            label-for="input-description"
                            label-class="label-align"
                        >
                            <b-form-textarea
                                id="input-description"
                                placeholder="Text (Optional)"
                                rows="3"
                                style="border-radius: 1rem"
                                v-model="form.Description"
                            ></b-form-textarea>
                            <b-form-text>
                                Details about the tax rate for your internal use only. It will not be visible to your
                                customers.
                            </b-form-text>
                        </b-form-group>

                        <b-form-group
                            id="input-group-rate"
                            label="Rate in %"
                            label-for="input-rate"
                            aria-describedby="rate-description"
                            label-class="label-align"
                        >
                            <b-form-input
                                id="input-rate"
                                v-model="form.Percentage"
                                number
                                required
                                class="rounded-pill"
                                step="0.01"
                            ></b-form-input>
                            <b-form-text id="rate-description" class="ml-3"
                                >This represents the tax rate percent out of 100.</b-form-text
                            >
                        </b-form-group>

                        <b-form-group
                            id="input-group-type"
                            label="Rate type"
                            label-for="type-selector"
                            aria-describedby="type-description"
                            label-class="label-align"
                        >
                            <b-form-select
                                id="type-selector"
                                class="rounded-pill"
                                v-model="form.Inclusive"
                                :options="rateType"
                                required
                            ></b-form-select>
                            <b-form-text id="type-description">
                                This specifies if the tax rate is inclusive or exclusive.
                            </b-form-text>
                        </b-form-group>

                        <b-form-group
                            id="input-group-region"
                            label="Region"
                            label-for="input-region"
                            label-class="label-align"
                        >
                            <b-form-select
                                id="select-region"
                                v-model="form.Country"
                                required
                                class="form-control rounded-pill d-block"
                            >
                                <template #first>
                                    <b-form-select-option :value="null" disabled selected
                                        >-- Please select a country --</b-form-select-option
                                    >
                                </template>
                                <b-form-select-option :value="item.Alpha2" v-for="(item, _) in countries">{{
                                    item.Name
                                }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>

                        <div class="d-flex justify-content-between mt-4">
                            <b-button
                                variant="outline-danger"
                                type="button"
                                @click="
                                    hasHistory()
                                        ? $router.go(-1)
                                        : $router.push('/admin/monetization/payments/tax-rate')
                                "
                                >Cancel</b-button
                            >
                            <b-button variant="primary" type="button" @click="create">Create</b-button>
                        </div>
                    </b-form>
                </div>
            </b-overlay>
        </div>
        <!-- tax-rate end -->
    </div>
</template>

<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';

export default {
    name: 'CreateTaxRate',
    components: {},
    data() {
        return {
            form: {
                Inclusive: false,
                Percentage: 19.0,
                Active: true,
                Type: 'VAT',
                Country: null,
            },
            loading: false,

            taxType: [{ value: 'VAT', text: 'VAT' }],
            rateType: [
                { value: true, text: 'Inclusive' },
                { value: false, text: 'Exclusive' },
            ],

            countries: [],
        };
    },
    mounted() {
        this.listContries();
    },

    methods: {
        hasHistory() {
            return window.history.length > 2;
        },
        create() {
            if (this.$refs['tax-rate-form'].checkValidity()) {
                this.postTaxRate();
            } else {
                this.$refs['tax-rate-form'].reportValidity();
            }
        },

        postTaxRate() {
            this.loading = true;
            const object = this.form;
            object.Jurisdiction = object.Country;

            axios({
                method: 'POST',
                url: '/admin/tax-rate',
                data: object,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    EventBus.$emit('INFO', {
                        Message: 'Tax rate created',
                        Details: '',
                    });
                    this.loading = false;
                    this.$router.push(`/admin/monetization/payments/tax-rate`);
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: 'Failed to create plan upgrade',
                        Details: error,
                    });
                    this.loading = false;
                });

            this.loading = false;
        },

        listContries() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/space/countries',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.countries = response.data;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: 'Failed to load contries',
                        Details: error,
                    });
                    this.loading = false;
                });
        },
    },
};
</script>
