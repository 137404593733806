<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <div>
        <!-- search and actions start -->
        <div class="pl-sm-4 d-flex align-items-center justify-content-between">
            <div></div>

            <div>
                <b-button v-if="loadingOAuth2URL" variant="primary">
                    <b-spinner small type="grow"></b-spinner>
                    <span>Loading...</span>
                </b-button>
                <b-button v-else variant="primary" @click="getGoogleCalendarOAuth2Url">Add Calendar Account</b-button>
            </div>
        </div>

        <!-- search and actions end -->
        <div class="pl-sm-4 mt-4">
            <div class="bg-white round-unify p-2">
                <div v-if="loading" class="d-flex justify-content-center pt-5">
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                </div>
                <div v-else>
                    <b-table
                        v-if="connectedAccounts && connectedAccounts.length > 0"
                        :items="connectedAccounts"
                        :fields="fields"
                        sort-icon-left
                        stacked="md"
                        class="table table-borderless table-responsive w-100 d-md-table"
                    >
                    </b-table>
                    <div v-else class="my-3 text-center">You don't have any connected calendar accounts yet.</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import EventBus from '@/eventBus';

export default {
    name: 'GoogleCalendar',
    data() {
        return {
            loading: false,
            loadingOAuth2URL: false,
            connectedAccounts: [],
            fields: [
                {
                    key: 'ID',
                    label: 'ID',
                    formatter: (value) => {
                        if (value.length > 16) {
                            return `${value.substring(0, 16)}...`;
                        }
                        return value;
                    },
                },
                {
                    key: 'CreatedAt',
                    label: 'Created at',
                    formatter: (value) => {
                        if (!value) {
                            return 'n/a';
                        }
                        return moment.unix(value).format('DD/MM/YYYY HH:mm');
                    },
                },
            ],
        };
    },
    mounted() {
        this.listTokens();
    },
    methods: {
        getGoogleCalendarOAuth2Url() {
            // immediately open a new window to avoid popup blockers
            const authWindow = window.open('', '_blank');
            this.loadingOAuth2URL = true;
            axios({
                method: 'GET',
                url: `/admin/oauth2/google/calendar`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        // update the url of the popup window
                        authWindow.location.href = response.data.url;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    let message = 'Error while getting google calendar auth url.';
                    if (authWindow === null) {
                        message = 'Error while opening auth popup, please allow popups for this website.';
                    } else {
                        authWindow.close();
                    }
                    EventBus.$emit('ERROR', {
                        message,
                        Details: error,
                    });
                })
                .finally(() => {
                    this.loadingOAuth2URL = false;
                });
        },

        listTokens() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/oauth2/google/calendar/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.connectedAccounts = response.data.Tokens;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
