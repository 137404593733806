var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message-w"},[(_vm.message.isNewDay)?_c('div',{staticClass:"d-flex justify-content-center mb-3"},[_c('div',[_c('small',[_c('coDate',{attrs:{"date":_vm.message.date._d,"format":"relativedate"}})],1)])]):_vm._e(),(!_vm.isMe && _vm.isGroup && _vm.showAvatar && _vm.author.Profile)?_c('div',{staticClass:"mt-3"},[_c('coText',{staticClass:"mb-1",staticStyle:{"padding-left":"2.625rem"},attrs:{"text":_vm.author.Profile.Name,"variant":"mute","type":"p3"}})],1):_vm._e(),_c('div',{staticClass:"d-flex w-100 mb-0 align-items-start mb-1",class:{
            'justify-content-end': _vm.isMe,
            'mt-3': _vm.showAvatar && !_vm.isGroup && !_vm.isMe,
        }},[(!_vm.author && _vm.showAvatar && !_vm.isMe)?_c('ProfileCircle'):(_vm.author && _vm.showAvatar && !_vm.isMe)?_c('ProfileCircle',{staticClass:"mr-1",attrs:{"ImageURL":_vm.author.Profile.ImageURL,"Slug":_vm.author.Slug}}):_vm._e(),_c('div',{staticClass:"message no-paragraph-margins py-1",class:{
                right: _vm.isMe,
                'message-group-left-margin': !_vm.showAvatar && !_vm.isMe,
                'message-group-right-margin': !_vm.showAvatar && _vm.isMe,
            }},[_c('div',{directives:[{name:"linkifytiptap",rawName:"v-linkifytiptap"}],staticClass:"d-flex flex-column",domProps:{"innerHTML":_vm._s(_vm.message.text)}}),_c('div',[(_vm.message.sendingFailed)?_c('span',{staticClass:"pointer message-time float-left small d-inline-block mb-1 text-danger",on:{"click":function($event){return _vm.retry()}}},[_vm._v(_vm._s(_vm.$t('senderror')))]):(_vm.isMe)?_c('span',{staticClass:"message-time float-left small d-inline-block mb-1"},[_vm._v(_vm._s(_vm.status))]):_vm._e(),_c('span',{staticClass:"message-time float-right small d-inline-block mb-1",class:{ 'ml-2': _vm.isMe }},[_c('coDate',{attrs:{"date":_vm.message.date._d,"format":"time"}})],1)])]),(!_vm.author && _vm.showAvatar && _vm.isMe)?_c('ProfileCircle'):_vm._e(),(_vm.showAvatar && _vm.showAvatar && _vm.isMe)?_c('ProfileCircle',{staticClass:"ml-1",attrs:{"ImageURL":_vm.author.Profile.ImageURL}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }