<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <router-link tag="a" :to="to" class="settings-card-wrapper">
        <div class="settings-card px-4 pt-3 pb-5 bg-white round-unify-xs">
            <div class="d-flex justify-content-between align-items-center mb-2">
                <div>
                    <h1>{{ title }}</h1>
                </div>
                <span v-if="isNew">
                    <b-badge variant="" class="bg-flamingo">New</b-badge>
                </span>

                <span v-else-if="beta">
                    <b-badge variant="danger">Beta</b-badge>
                </span>
            </div>
            <p>{{ subtitle }}</p>
        </div>
    </router-link>
</template>
<script>
export default {
    name: 'SettingsCard',
    props: {
        title: {
            type: String,
            default: 'Title',
        },
        subtitle: {
            type: String,
            default: '',
        },
        to: {
            type: String,
            default: '',
        },
        isNew: {
            type: Boolean,
            default: false,
        },
        beta: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
