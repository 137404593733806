































export default {
    name: 'CoInput',
    props: {
        label: String,
        value: String,
        type: String,
        placeholder: String,
        readonly: Boolean,
        icon: String,
    },
    data() {
        return {
            content: this.value,
        };
    },
    methods: {
        handleInput(e) {
            this.content = e.target.value;
            this.$emit('input', this.content);
        },
    },
};
