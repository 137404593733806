<template>
    <div class="container col-np-mobile">
        <b-overlay :show="isLoading" spinner-variant="primary" rounded="sm">
            <b-card>
                <h2>{{ $t('labels.marketitemcreate') }}</h2>
                <b-form @submit.stop.prevent="postItem">
                    <div class="mt-4 mb-2">{{ $t('labels.summary') }}</div>
                    <b-form-input
                        :placeholder="$t('placeholders.marketitemtitle')"
                        class="rounded-pill"
                        v-model="item.Title"
                        required
                        autofocus
                    ></b-form-input>

                    <div class="mt-4 mb-2">
                        {{ $t('labels.summary') }} ({{ $t('messages.maxcharacters', { count: '200' }) }})
                    </div>
                    <b-form-textarea
                        id="textarea-default"
                        :placeholder="$t('placeholders.marketitemsummary')"
                        rows="3"
                        class="create-post-textarea"
                        v-model="item.Summary"
                        required
                    ></b-form-textarea>

                    <div class="mt-4 mb-2">{{ $t('labels.description') }}</div>
                    <b-form-textarea
                        id="textarea-default"
                        :placeholder="$t('placeholders.marketitemdescription')"
                        rows="5"
                        class="create-post-textarea"
                        v-model="item.Description"
                        required
                    ></b-form-textarea>
                    <div class="mt-4 mb-2">{{ $t('labels.category') }}</div>
                    <b-form-group id="select-group-1">
                        <b-form-select
                            id="Category"
                            v-model="item.Category"
                            class="form-select rounded-pill"
                            :options="categoryOptions"
                            required
                        >
                        </b-form-select>
                    </b-form-group>
                    <div class="mt-4 mb-2">
                        {{
                            this.$unleash.isEnabled('frontend.tags-are-called-topics')
                                ? $t('labels.topics')
                                : $t('labels.tags')
                        }}
                    </div>
                    <div>
                        <vue-tags-input
                            id="tags"
                            v-model="tag"
                            :tags="tags"
                            :separators="separators"
                            :autocomplete-items="autocompleteItems"
                            :add-only-from-autocomplete="false"
                            @tags-changed="updateTags"
                            @before-adding-tag="addTag"
                        />
                    </div>

                    <div class="mt-4 mb-2">{{ $t('labels.contact') }}</div>
                    <b-form-input
                        :placeholder="$t('placeholders.marketitemcontact')"
                        class="rounded-pill"
                        v-model="item.ContactMethod"
                    ></b-form-input>

                    <div class="mt-4 mb-2">{{ $t('labels.image') }}</div>
                    <div
                        class="d-flex w-100 create-post-textarea border mb-4 align-items-center justify-content-between"
                        style="cursor: pointer"
                    >
                        <div class="text-muted p-2 mb-0">{{ $t('placeholders.image') }}</div>
                        <div class="upload">
                            <button class="btn">
                                <b-icon variant="primary" scale="1.5" icon="images"></b-icon>
                            </button>
                            <input
                                type="file"
                                id="picture"
                                name="picture"
                                @change="previewFiles"
                                accept=".jpg,.jpeg,.png"
                                class="form-control-file pointer"
                            />
                        </div>
                    </div>
                    <div class="mb-4">
                        <strong>{{ $t('labels.imageguidelines') }}</strong> {{ $t('messages.imageguidelines') }}
                    </div>

                    <div class="align-content-top d-flex position-relative" v-if="imageURL">
                        <b-img class="preview-img p-0" thumbnail fluid width="150" height="150" :src="imageURL"></b-img>
                        <div class="delete">
                            <b-icon
                                icon="x-circle"
                                variant="danger pointer"
                                scale="1.75"
                                v-on:click="deletePreview(index)"
                            ></b-icon>
                        </div>
                    </div>
                    <div class="text-right mt-3 mb-4 pb-4">
                        <b-button type="submit" variant="primary" :disabled="item.Title == ''"
                            >{{ $t('labels.create') }}
                        </b-button>
                    </div>
                </b-form>
            </b-card>
            <b-modal id="bv-modal-success" hide-footer centered size="sm" @close="viewItem" @hide="viewItem">
                <div class="d-block text-center">
                    <h2>{{ $t('successheader') }}</h2>
                    <br />
                    <h3>{{ $t('successsubline') }}</h3>
                </div>
                <b-button class="mt-3" block @click="viewItem">{{ $t('labels.ok') }}</b-button>
            </b-modal>
        </b-overlay>
    </div>
</template>
<style lang="less" scoped>
.create-post-textarea {
    border-radius: 18px;
    border: 1px solid #ced4da;
    position: relative;
}

.preview-img {
    border-radius: 18px;
    border-top-right-radius: 0px;
}

.upload {
    position: static;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
    min-width: 60px;
}

.upload input[type='file'] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    font-size: 36px;
    z-index: 2;
    cursor: pointer;
}

.attachments {
    display: block;
    width: 100%;
}

.attachments .item {
    margin: 0px 5px 0px 5px;
    overflow: hidden;
    background: #212121;
    display: inline-block;
    background-color: #cccccc;
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100px;
    padding-top: 100px;
    border-radius: 7px;
    position: relative;
}

.delete {
    position: relative;
    top: 8px;
    top: -12px;
    left: -29px;
    left: -9px;
    // right: 0;
    font-size: 1em !important;
    line-height: 1.5em !important;
}
</style>
<script>
import axios from 'axios';
import Vue from 'vue';
import VueTagsInput from '@johmun/vue-tags-input';
import VueIntercom from 'vue-intercom';
import i18n from 'vue-i18n';
import EventBus from '../../eventBus';

Vue.use(VueIntercom, { appId: 'kttwojyk' });

export default {
    i18n: {
        messages: {
            en: {
                successheader: 'Thanks for posting',
                successsubline:
                    'This market item will be active on the platform for the next 4 weeks. After this period it will be closed automatically.',
            },
            de: {
                successheader: 'Danke fürs Posten',
                successsubline:
                    'Dieser Marktartikel wird für die nächsten 4 Wochen auf der Plattform aktiv sein. Nach Ablauf dieses Zeitraums wird der Artikel automatisch geschlossen.',
            },
        },
    },
    name: 'CreateJob',
    components: {
        VueTagsInput,
    },
    data() {
        return {
            item: {
                Title: '',
                Summary: '',
                Description: '',
                Open: true,
                Published: true,
                ContactMethod: '',
                Category: 0,
            },
            image: null,
            imageURL: null,
            tag: '',
            tags: [],
            autocompleteItems: [],
            debounce: null,
            separators: [';', ',', '#', ', #'],
            isLoading: false,
            categoryOptions: [
                { value: 0, text: 'Job' },
                { value: 1, text: 'Service' },
                { value: 2, text: 'Product' },
            ],
        };
    },
    watch: {
        tag: 'loadAutocompleteItems',
    },
    computed: {
        isVisible() {
            return this.$intercom.visible;
        },
    },
    mounted() {
        if (this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin')) {
            this.$intercom.boot({
                user_id: this.$store.state.me.ID,
                name: this.$store.state.me.Profile.Name,
                email: this.$store.state.me.Email,
                spaceID: this.$store.state.space.ID,
                space: this.$store.state.space.Name,
                intro: this.$store.state.me.Intro,
                hide_default_launcher: this.$isMobile || this.$store.state.isInMobile, // hide if Vue.prototype.$isMobile flag is set
            });

            this.$intercom.hide();
        }
    },
    beforeDestroy() {
        this.$intercom.shutdown();
    },

    methods: {
        emitEventToIntercom() {
            if (this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin')) {
                this.$intercom.trackEvent(`created-market-item`);
            }
        },
        viewItem() {
            this.$router.push(`/job/${this.item.Slug}`);
        },

        /**
         * addTag - creates new tag if user types custom one and hit enter
         */
        addTag(obj) {
            const tag = JSON.stringify({ Value: obj.tag.text });
            this.$store
                .dispatch('createTag', tag)
                .then((response) => {
                    const tag = {
                        text: response.Value,
                        slug: response.Slug,
                    };
                    this.tags.push(tag);
                    this.tag = '';
                    return true;
                })
                .catch((error) => {
                    const message = {
                        Message: `Failed to create tag \`${obj.tag.text}\``,
                        Details: error,
                    };
                    EventBus.$emit('ERROR', message);
                    return false;
                }, obj);
        },

        updateTags(newTags) {
            this.autocompleteItems = [];
            this.tags = newTags;
        },

        loadAutocompleteItems() {
            this.autocompleteItems = [];
            if (this.tag.length < 2) return;
            this.$store
                .dispatch('searchTag', this.tag)
                .then((response) => {
                    response.forEach(function (entry) {
                        const tag = {
                            text: entry.Slug,
                            slug: entry.Slug,
                        };
                        this.autocompleteItems.push(tag);
                    }, this);
                    return true;
                })
                .catch((error) => {
                    console.log('error: ', error);
                    return false;
                });
        },

        create() {
            const tags = [];
            this.tags.forEach((entry) => {
                tags.push(entry.slug);
            }, tags);
            this.item.Tags = tags;

            axios({
                method: 'POST',
                url: '/job',
                data: JSON.stringify(this.item),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.isLoading = false;
                    const msg = {
                        Message: 'Item posted',
                        Details: '',
                    };
                    this.emitEventToIntercom();
                    EventBus.$emit('INFO', msg);
                    this.item = response.data;
                    this.$bvModal.show('bv-modal-success');
                })
                .catch((error) => {
                    this.isLoading = false;
                    if (error.response.data && error.response.data.message) {
                        EventBus.$emit('ERROR', {
                            Message: error.response.data.message,
                            Details: error.response.data.details,
                        });
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: 'Could not create market item. Please try again later.',
                            Details: null,
                        });
                    }
                });
        },

        uploadImages() {
            this.$store
                .dispatch('imageUpload', {
                    file: this.image,
                    url: '/upload/image/jobs',
                })
                .then((response) => {
                    this.item.ImageURL = response;
                    this.create();
                })
                .catch((error) => {
                    const errorMsg = {
                        Message: 'Could not upload one or more images, please try again or check details',
                        Details: error.response.data,
                    };
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.message === 'Request Entity Too Large'
                    ) {
                        errorMsg.Message = 'The file you tried to upload is too large. Max 10 MB.';
                    }
                    EventBus.$emit('ERROR', errorMsg);
                });
        },

        postItem(event) {
            this.isLoading = true;

            if (this.image) {
                this.uploadImages();
                return;
            }
            this.create();
        },

        previewFiles(event) {
            // set file preview
            (this.image = event.target.files[0]), (this.imageURL = URL.createObjectURL(event.target.files[0]));
        },
        deletePreview(index) {
            this.image = null;
            this.imageURL = null;
        },
    },
};
</script>
