





















import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import CoCheckbox from '@/components/Atoms/co-checkbox/CoCheckbox.vue';

export default {
    name: 'CoCheckboxSelection',
    components: { CoIcon, CoText, CoCheckbox },
    emits: ['selection-changed'],
    props: {
        defaultExpanded: {
            type: Boolean,
            default: false,
        },
        selections: {
            type: Array,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        classes: String,
    },
    data() {
        return {
            isExpanded: this.defaultExpanded,
            iconName: 'chevron-up',
            rerender: 0,
        };
    },
    watch: {
        isExpanded(val) {
            this.iconName = val ? 'chevron-up' : 'chevron-down';
            this.rerender += 1;
        },
    },
};
