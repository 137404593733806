<template>
    <div class="container h-100" style="max-height: 100vh; max-width: 100vw; position: absolute">
        <div class="row justify-content-center">
            <div class="col-lg-6 align-self-center text-center">
                <img
                    v-if="this.$store.state.space.LogoURL"
                    :src="this.$store.state.space.LogoURL"
                    width="145"
                    height="auto"
                    alt="Hafven Community"
                    class="m-3 mb-4"
                />
                <div v-else class="m-3 mb-4">
                    <h2>{{ this.$store.state.space.Name }}</h2>
                </div>
                <div class="check-in-now">
                    <!-- <div>
          <p v-if="this.$store.state.me.ID != null">Hi <b>{{this.$store.state.me.Profile.Name}}</b>.<br>Welcome back!</p>
          <p v-else>Hi Dear, welcome back!</p>
        </div> -->
                    <div class="text-center">
                        <div class="loader-container" v-if="loading">
                            <div class="loader">
                                <div class="circle-loader">
                                    <div class="checkmark draw"></div>
                                </div>
                            </div>
                            <div class="title text-center" v-if="success">
                                <h2>Successfully checked in!</h2>
                            </div>

                            <div class="">
                                <p>Members, projects, collaboration?</p>
                                <button class="btn btn-lg btn-primary" @click="goToPlatform">
                                    Visit the Community Platform
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- next community events -->
        <div class="row justify-content-center mt-3">
            <div class="col-8">
                <div v-for="(object, index) in events" :key="index">
                    <Card :object="object"></Card>
                </div>
            </div>
        </div>
        <!-- next community events -->
    </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import Router from 'vue-router';
import { readlink } from 'fs';

export default {
    data() {
        return {
            success: null,
            errorMsg: '',
            hideHeader: false,
            loading: false,
            readToCheckIn: false,
            readToBuyDayPass: false,
            confirmation: false,

            events: [],
        };
    },
    computed: {
        state() {
            return this.$store.getters.state;
        },
        components() {
            return JSON.stringify(this.$options.components);
        },
    },
    mounted() {
        this.getEvents();
        this.loading = true;
        setTimeout(() => {
            this.success = true;
            const el1 = this.$el.getElementsByClassName('circle-loader')[0];
            el1.classList.add('load-complete');
            const el2 = this.$el.getElementsByClassName('checkmark')[0];
            el2.style.display = 'block';
        }, 350);
    },
    methods: {
        goToPlatform() {
            this.$router.push({ path: '/' });
        },
        getEvents() {
            this.$store
                .dispatch('getEvents')
                .then((response) => {
                    console.log(response);
                    response.objects.splice(3);
                    this.events = response.objects;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>

<style lang="less" scoped>
.check-in-now {
    padding: 0 1em;
}

.check-in-now h1 {
    font-weight: 300;
}

.check-in-now h2 {
    text-align: center;
    font-weight: 300;
}

.buttons {
    align-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.buttons .green_bg:hover {
    color: rgb(255, 71, 19);
    color: white;
}
.buttons .green_bg:active {
    color: rgb(255, 71, 19);
    // color: white;
}

body {
    background-color: #dedfde;
}

@brand-success: #5cb85c;
@loader-size: 7em;
@check-height: @loader-size / 2;
@check-width: @check-height / 2;
@check-left: (@loader-size / 6 + @loader-size / 12);
@check-thickness: 3px;
@check-color: @brand-success;

.loader-container {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1024;
    align-content: center;
    display: block;
    align-items: center;
    justify-content: center;
}

.loader {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.circle-loader {
    // margin-bottom: @loader-size/2;
    border: 3px solid rgba(0, 0, 0, 0.2);
    border-left-color: @check-color;
    animation: loader-spin 1.2s infinite linear;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    width: @loader-size;
    height: @loader-size;
}

.load-complete {
    -webkit-animation: none;
    animation: none;
    border-color: @check-color;
    transition: border 500ms ease-out;
}

.checkmark {
    display: none;
    background-color: transparent;
}

.checkmark.draw::after {
    transform: scaleX(-1) rotate(135deg);
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmarkAnimation;
    content: '';
    display: block;
    background-color: transparent;
}
.checkmark::after {
    opacity: 1;
    height: @check-height;
    width: @check-width;
    transform-origin: left top;
    border-right: 3px solid @check-color;
    border-top: 3px solid @check-color;
    content: '';
    left: @check-left;
    top: @check-height;
    position: absolute;
    display: block;
    background-color: transparent;
}

.circle-loader.xmark {
    border-color: rgb(255, 71, 19);
}

@keyframes loader-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes checkmarkAnimation {
    0% {
        height: 0;
        width: 0;
        opacity: 1;
    }
    20% {
        height: 0;
        width: @check-width;
        opacity: 1;
    }
    40% {
        height: @check-height;
        width: @check-width;
        opacity: 1;
    }
    100% {
        height: @check-height;
        width: @check-width;
        opacity: 1;
    }
}

@keyframes xmarkanimation {
    0% {
        height: 0;
        width: 0;
        opacity: 1;
    }
    20% {
        height: 0;
        width: @check-width;
        opacity: 1;
    }
    40% {
        height: @check-height;
        width: @check-width;
        opacity: 1;
    }
    100% {
        height: @check-height;
        width: @check-width;
        opacity: 1;
    }
}
</style>
