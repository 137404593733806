


























































import CoCard from '../co-card/CoCard.vue';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoRoundButton from '../../Atoms/co-round-button/CoRoundButton.vue';
import CoInput from '../co-input/CoInput.vue';
import CoPrice from '../../Atoms/co-price/coPrice.vue';
import CoThumbnail from '../co-thumbnail/CoThumbnail.vue';

interface PlanUpgrade {
    ID: string;
    Name: string;
    Description: string;
    ImageURL: string;
    PriceInCents: number;
    Currency: string;
    CreatedAt: string;
    UpdatedAt: string;
    TaxRate: {
        ID: string;
        DisplayName: string;
        Percentage: number;
        Inclusive: boolean;
    };
    TimeInterval: number;
    IntervalCount: number;
}

interface Price {
    PriceInCents: number;
    Currency: string;
    TaxRate: number;
    TaxName: string;
    Inclusive: boolean;
    Unit?: string;
}

export default {
    name: 'CoCardUpgrade',
    components: {
        CoPrice,
        CoCard,
        CoHeadline,
        CoButton,
        CoInput,
        CoIcon,
        CoRoundButton,
        CoThumbnail,
    },
    props: {
        variant: {
            type: String,
            default: 'default',
            validator: (value) => ['default', 'white'].includes(value),
            required: false,
        },
        withDelete: {
            type: Boolean,
            default: false,
        },
        withAdd: {
            type: Boolean,
            default: false,
        },
        showAsAdded: {
            type: Boolean,
            default: false,
        },
        withPlus: {
            type: Boolean,
            default: false,
        },
        upgrade: {
            type: Object as () => PlanUpgrade,
            required: true,
        },
        timeInterval: {
            type: String,
            default: '',
            required: false,
        },
        preSelecteExtraNumber: {
            type: Number,
            default: 1,
        },
        showThumbnail: {
            type: Boolean,
            default: false,
            required: false,
        },
        clickable: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    computed: {
        classes() {
            return {
                clickable: this.clickable,
            };
        },
    },
    watch: {},
    data() {
        return {
            imageURL: this.upgrade.ImageURL,
            name: '',
            windowWidth: window.innerWidth,
            Price: 0,
            bookingCredits: null,
            currency: '',
            extraNumber: this.preSelecteExtraNumber,
            upgradePrice: '',
            availability: false,
            details: true,
            upgradeObject: {},
            minExtra: 1,
            maxExtra: 10,
            added: this.showAsAdded,
        };
    },
    created() {
        this.upgradeObject = this.upgrade;
        this.extraNumber = this.preSelecteExtraNumber;
    },

    methods: {
        priceObject() {
            const priceObj: Price = {
                PriceInCents: this.upgrade.PriceInCents,
                Currency: this.upgrade.Currency,
                Unit: this.timeInterval,
                TaxName: '',
                TaxRate: 0,
                Inclusive: false,
            };

            if (this.upgrade.TaxRate) {
                priceObj.TaxRate = this.upgrade.TaxRate.Percentage;
                priceObj.TaxName = this.upgrade.TaxRate.Name;
                priceObj.Inclusive = this.upgrade.TaxRate.Inclusive;
            }
            return priceObj;
        },
        deleteUpgrade() {
            this.$emit('deleteUpgrade', this.upgrade);
            this.added = false;
        },
        addUpgrade() {
            this.$emit('addUpgrade', this.upgrade.ID, [this.upgrade]);
            this.added = true;
        },
        updateExtraNumber(number) {
            this.extraNumber = number;
            const upgrades = [];
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < this.extraNumber; i++) {
                upgrades.push(this.upgrade);
            }
            this.$emit('addUpgrade', this.upgrade.ID, upgrades);
        },
        click(event) {
            // if event is not target child of CoupgradePreview component than stop
            if (event && event.target.closest('.CoUpgradePreview')) {
                return;
            }
            const r = JSON.parse(JSON.stringify(this.upgrade));
            this.$emit('click', r);
        },
    },
};
