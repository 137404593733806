<template>
    <b-overlay :show="loading">
        <div class="d-flex align-items-center pt-4 ml-3">
            <span class="ml-3">{{ $t('labels.ordercheckout') }}</span>
        </div>
        <div class="d-flex mt-4 w-100">
            <!-- content section -->
            <div class="row m-0 w-100" style="height: 100vh">
                <!-- plan upgrades -->
                <div v-if="plan" class="" style="height: 70vh">
                    <div class="bg-white round-unify-xs mh-100 h-100 d-flex flex-column align-items-end">
                        <!-- header -->
                        <div class="p-3 text-center border-bottom align-self-start w-100">
                            {{ $t('labels.orderreview') }}
                        </div>
                        <!-- body -->
                        <div class="align-self-start w-100 overflow-auto mh-100">
                            <div class="d-flex p-2 pl-3">
                                <div class="d-flex flex-column">
                                    <h3 class="grey">{{ $t('labels.plan') }}</h3>
                                    <div class="d-flex">
                                        {{ plan.Name }}
                                        <div v-if="selected.length > 0">
                                            <span class="ml-1"> + (</span>
                                            <span v-for="item in selected" :key="item.ID">{{ item.Name }}</span>
                                            <span>)</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="ml-auto d-flex">
                                    <div class="d-flex flex-column">
                                        <h3 class="grey">{{ $t('labels.total') }}</h3>
                                        <span
                                            >{{ $n(totalPrice(), 'currency') }} {{ plan.Currency }} /
                                            {{ $t(`labels.${plan.TimeInteval === 1 ? 'year' : 'month'}`) }}</span
                                        >
                                        <small class="grey mt-1"
                                            >{{ $t('labels.including') }}
                                            {{ plan.TaxRate.Percentage ? plan.TaxRate.Percentage : 0 }} %
                                            {{ plan.TaxRate.DisplayName }}</small
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex p-2 pl-3">
                                <div v-if="user" class="d-flex flex-column">
                                    <h3 class="grey">{{ $t('labels.account') }}</h3>
                                    <span>{{ user.Profile.Name }}</span>
                                    <span class="mt-1">{{ user.Email }}</span>
                                </div>
                                <div v-else class="d-flex flex-column">
                                    <h3 class="grey">{{ $t('labels.account') }}</h3>
                                    <span>{{ me.Profile.Name }}</span>
                                    <span class="mt-1">{{ me.Email }}</span>
                                </div>
                            </div>
                            <div class="d-flex p-2 pl-3">
                                <div class="d-flex flex-column">
                                    <h3 class="grey">{{ $t('labels.paymentmethod') }}</h3>
                                    <!-- Add current payment method display -->
                                    <span></span>
                                    <span class="mt-1"></span>
                                </div>
                            </div>
                            <div class="d-flex p-2 pl-3" v-if="!user">
                                <div class="d-flex flex-column">
                                    <span class="col-6 m-0 p-0">
                                        {{ $t('upgradepolicy') }}
                                    </span>
                                </div>
                            </div>
                            <div class="d-flex align-items-center pt-2 pl-3" v-if="TermsAndConditions && !user">
                                <b-form-checkbox
                                    id="checkbox-2"
                                    name="checkbox-2"
                                    v-model="AcceptTermsAndConditions"
                                    required
                                >
                                </b-form-checkbox>
                                <i18n path="labels.acceptterms" tag="p" class="mb-0">
                                    <a
                                        class="text-primary mb-0"
                                        href="#"
                                        @click.stop="$bvModal.show('terms-and-conditions')"
                                        :title="$t('labels.termsandconditions')"
                                        >{{ $t('labels.termsandconditions') }}</a
                                    >
                                </i18n>
                            </div>
                        </div>
                        <!-- footer -->
                        <div class="p-3 border-top d-flex justify-content-between mt-auto w-100">
                            <b-button variant="outline-primary" @click="back()">{{ $t('labels.back') }}</b-button>
                            <b-button variant="primary" @click="changePlan()" :disabled="!isReady && TermsAndConditions"
                                >{{
                                    $t(
                                        totalPrice() > 0
                                            ? 'labels.ordercompletesubcription'
                                            : 'labels.ordercompletesubcriptionfree'
                                    )
                                }}
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- membership state -->
        <b-modal id="terms-and-conditions" hide-footer title="Terms and conditions" size="xl">
            <div v-if="TermsAndConditions" v-html="TermsAndConditions.Content"></div>
        </b-modal>
    </b-overlay>
</template>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.grey {
    color: gray;
}

.small-text {
    font-size: 0.8rem;
}
</style>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';
import i18n from 'vue-i18n';

export default {
    i18n: {
        messages: {
            en: {
                upgradepolicy:
                    'Plan upgrades will take place immediately. You will receive a refund for the remaining days of the current plan.',
            },
            de: {
                upgradepolicy:
                    'Tarif-Upgrades werden sofort durchgeführt. Du erhältst eine Rückerstattung für die verbleibenden Tage des aktuellen Tarifs.',
            },
        },
    },
    name: 'ChangePlanUpgrades',
    props: {
        plan: {
            type: Object,
            default: null,
        },
        selected: {
            type: Array,
            default: [],
        },
        user: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            AcceptTermsAndConditions: false,
            TermsAndConditions: null,
            loading: false,
            me: this.$store.state.me,
        };
    },
    computed: {
        isReady() {
            return this.AcceptTermsAndConditions;
        },
    },
    created() {},
    mounted() {
        if (!this.user) {
            this.getTermsAndConditions();
        } else {
            this.AcceptTermsAndConditions = true;
        }
    },
    methods: {
        back() {
            this.$emit('prevStep');
        },
        changePlan() {
            this.$emit('changePlan');
        },
        calculatePrice(extra) {
            if (extra.TaxRateID === '' || extra.TaxRate.Inclusive) {
                const price = extra.PriceInCents / 100.0;
                return price;
            }
            const price = extra.PriceInCents / 100.0;
            const totalPrice = price * (extra.TaxRate.Percentage / 100 + 1);
            return totalPrice;
        },
        totalPrice() {
            let total = this.calculatePrice(this.plan);
            if (this.selected) {
                this.selected.forEach((element) => {
                    total += this.calculatePrice(element);
                });
            }
            return total.toFixed(2);
        },
        getTermsAndConditions() {
            axios({
                method: 'GET',
                url: '/space/get-terms-and-conditions',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.TermsAndConditions = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>
