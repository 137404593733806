<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <div>
        <!-- search and actions start -->
        <div class="px-sm-4 d-flex flex-column flex-sm-row align-items-center justify-content-between">
            <div
                class="align-self-start mb-2 mb-sm-0 d-flex align-items-center bg-white search-bar-wrapper rounded-pill"
            >
                <b-icon class="mr-2" icon="search"></b-icon>
                <div class="search-input-wrapper">
                    <input
                        class="search-input"
                        type="search"
                        v-model="tableFilter"
                        placeholder="Search"
                        aria-label="Search"
                    />
                </div>
            </div>

            <div class="d-block d-sm-flex">
                <b-button @click="showDeleteModal()" variant="outline-danger" class="m-1 my-sm-0" :disabled="!selected"
                    >Delete
                </b-button>
                <b-button variant="primary" class="m-1 my-sm-0 mr-sm-0" @click="createResource">Create</b-button>
            </div>
        </div>
        <!-- search and actions end -->

        <div class="px-sm-4">
            <b-overlay :show="loading" class="p-0 m-0">
                <div class="mt-4">
                    <b-table
                        striped
                        hover
                        :items="objects"
                        :fields="fields"
                        sort-icon-left
                        :per-page="perPage"
                        stacked="md"
                        :filter="tableFilter"
                        :current-page="currentPage"
                        @filtered="onFiltered"
                        class="table table-borderless table-responsive w-100 d-md-table"
                    >
                        <template v-slot:cell(Select)="data">
                            <b-form-radio v-model="selected" :value="data.item"></b-form-radio>
                        </template>
                        <template v-slot:cell(Name)="data2">
                            <CoLink
                                :to="`/admin/booking/resource/edit/${data2.item.ID}`"
                                :title="data2.item.Name"
                                styles="basic-link"
                            />
                        </template>
                        <template #cell(ID)="data"> {{ stripString(data.item.ID) }}...</template>
                        <template v-slot:cell(Category)="data">
                            <span v-if="data.item['Category']">
                                {{ data.item['Category']['Name'] }}
                            </span>
                        </template>

                        <template v-slot:cell(Menu)="data">
                            <span v-if="data.item['Menu']">
                                {{ data.item['Menu']['Name'] }}
                            </span>
                        </template>

                        <template v-slot:cell(Actions)="row" class="d-flex"></template>
                    </b-table>
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        aria-controls="my-table"
                    ></b-pagination>
                </div>
            </b-overlay>
        </div>

        <!-- deleting modal -->
        <b-modal
            id="deleting-modal"
            size="md"
            title="Delete resource"
            @ok="deleteResource"
            ok-title="Delete"
            ok-variant="danger"
            centered
        >
            <b-overlay :show="deleting" spinner-variant="primary" class="p-0 m-0">
                <p class="mb-1">Once you delete an resource, there is no going back. Please be certain.</p>
            </b-overlay>
        </b-modal>
        <!-- deleting modal -->
    </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import CoLink from '@/components/Atoms/co-link/CoLink.vue';
import EventBus from '../../../eventBus';

export default {
    name: 'BookingResources',
    components: { CoLink },
    data() {
        return {
            selected: null,

            objects: [],

            // table model
            tableFilter: '',
            perPage: 20,
            currentPage: 1,
            // searchFilterIncludesFields: ['object.title', 'Title', 'Name', 'title', 'name'],
            totalRows: 0,
            loading: false,
            deleting: false,
            toDelete: null,
            fields: [
                {
                    label: '',
                    key: 'Select',
                },
                {
                    key: 'Name',
                    label: 'Name',
                    sortable: true,
                },
                {
                    key: 'Capacity',
                    label: 'Capacity',
                    sortable: true,
                },
                {
                    key: 'Category',
                    label: 'Category',
                    sortable: true,
                },
            ],

            categories: [],
        };
    },
    created() {
        this.getCategories();
    },
    methods: {
        stripString(input) {
            input = input.substring(0, 4);
            return input;
        },
        showDeleteModal() {
            this.toDelete = this.selected;
            this.$bvModal.show('deleting-modal');
        },
        deleteResource() {
            const data = {};

            axios({
                method: 'DELETE',
                url: `/admin/booking/resource/${this.toDelete.ID}`,
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.deleting = false;
                    const index = this.objects.findIndex((item) => item.ID === this.toDelete.ID);
                    this.objects.splice(index, 1);
                })
                .catch((error) => {
                    this.deleting = false;
                    const index = this.objects.findIndex((item) => item.ID === this.toDelete.ID);
                    this.objects.splice(index, 1);
                });
        },

        listResources() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/admin/booking/resource/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response.data && response.data.Resources.length != 0) {
                        response.data.Resources.forEach((element) => {
                            const category = this.categories.find(
                                (item) => item.Resources && item.Resources.includes(element.ID)
                            );
                            element.Category = category;
                            this.objects.push(element);
                        });
                    }
                    this.requestFinished();
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        createResource() {
            this.$router.push('/admin/booking/resource/create');
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        requestFinished() {
            this.loading = false;
            this.totalRows = this.objects.length;
        },

        getCategories() {
            axios({
                method: 'GET',
                url: '/admin/booking/category/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data && response.data.Categories) {
                        this.categories = response.data.Categories;
                    }
                    this.listResources();
                })
                .catch((error) => {
                    this.listResources();
                    console.log(error);
                });
        },
    },
};
</script>
