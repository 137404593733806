





































































































export default {
    name: 'CoSkeleton',
    props: {
        variant: {
            type: String,
            default: 'text',
            validator: (value: string) => ['text', 'button', 'thumbnail', 'circle', 'image'].includes(value),
        },
        width: {
            type: String,
            default: '100%',
        },
        height: {
            type: String,
            default: '100%',
        },
        colorVariant: {
            type: String,
            default: 'default',
            validator: (value: string) => ['default', 'darkmode'].includes(value),
        },
    },
    computed: {
        classes() {
            return {
                [this.variant]: true,
                [this.colorVariant]: true,
            };
        },
    },
};
