<template>
    <!-- nested router views -->
    <router-view />
</template>
<script>
export default {
    name: 'Create',
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>
