<template>
    <div class="bg-white round-unify-xs mh-100 h-100 d-flex flex-column align-items-end">
        <!-- header -->
        <div class="p-3 text-center border-bottom align-self-start w-100">{{ $t('labels.paymentmethod') }}</div>
        <!-- body -->
        <b-spinner v-if="loading" variant="primary" class="align-self-center mt-2" label="Spinning"></b-spinner>
        <div class="align-self-start w-100 overflow-auto mh-100 p-3">
            <div id="message" class="text-center">
                <!-- Display status message to customers here -->
            </div>
            <form id="payment-form">
                <div id="payment-element">
                    <!-- Elements will create form elements here -->
                </div>
                <div id="error-message">
                    <!-- Display error message to customers here -->
                </div>
            </form>
        </div>

        <!-- footer -->
        <div class="p-3 border-top d-flex justify-content-between mt-auto w-100">
            <coButton variant="secondary" @click="back" :label="$t('labels.back')" />
            <coButton
                v-if="totalPrice === 0"
                variant="secondary"
                class="ml-auto mr-2"
                @click="next"
                :label="$t('labels.skip')"
            />
            <coButton variant="primary" @click="confirmSetup" :label="$t('labels.next')" />
        </div>
    </div>
</template>

<script>
import axios from 'axios';

let elements = null;
export default {
    name: 'PaymentCredentials',
    data() {
        return {
            loading: false,
            success: false,
            publicKey: '',
            accountID: '',
            stripe: null,
            paymentElement: null,
            totalPrice: 0,
        };
    },
    created() {
        if (this.$unleash.isEnabled('frontend.skip-payment')) {
            this.$store.commit('TO_REVIEW');
        }
        this.loading = true;
        this.getPublicKey();
        this.totalPrice = this.getTotalPrice();
    },
    methods: {
        next() {
            this.$store.commit('TO_REVIEW');
        },
        back() {
            this.$store.commit('PREVIOUS_STEP');
        },
        async confirmSetup() {
            if (this.$store.state.newMembership.PaymentMethodeID) {
                this.$store.commit('TO_REVIEW');
                return;
            }
            let urlStart = `https://${this.$store.state.space.DomainName}`;
            if (this.$store.state.space.DomainName === 'localhost:8080') {
                urlStart = 'http://localhost:8080';
            }
            const { error } = await this.stripe.confirmSetup({
                elements,
                confirmParams: {
                    return_url: `${urlStart}/registerv2?customerID=${this.$store.state.newMembership.CustomerID}`,
                },
            });

            if (error) {
                // This point will only be reached if there is an immediate error when
                // confirming the payment. Show error to your customer (e.g., payment
                // details incomplete)
                const messageContainer = document.querySelector('#error-message');
                messageContainer.textContent = error.message;
            } else {
                // Your customer will be redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer will be redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
            }
        },
        getPublicKey() {
            axios({
                method: 'GET',
                url: '/user/account/billing/public-key',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.publicKey = response.data.Key;
                        this.accountID = response.data.AccountID;
                        this.stripe = Stripe(this.publicKey, {
                            stripeAccount: this.accountID,
                        });

                        const { setup_intent_client_secret } = this.$route.query;

                        const { customerID } = this.$route.query;

                        if (setup_intent_client_secret && customerID) {
                            const data = {
                                CustomerID: customerID,
                                ClientSecret: setup_intent_client_secret,
                            };
                            this.$store.commit('SAVE_CUSTOMER_AND_CLIENT_SECRET', data);
                            this.checkSetupIntentStatus(setup_intent_client_secret);
                        } else if (
                            this.$store.state.newMembership.ClientSecret &&
                            this.$store.state.newMembership.ClientSecret !== '' &&
                            this.$store.state.newMembership.CustomerID &&
                            this.$store.state.newMembership.CustomerID !== ''
                        ) {
                            this.checkSetupIntentStatus(this.$store.state.newMembership.ClientSecret);
                        } else {
                            this.getClientSecret();
                        }
                    }
                })
                .catch((error) => {});
        },
        getClientSecret() {
            axios({
                method: 'GET',
                url: '/user/account/billing/client-secret',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        const data = {
                            CustomerID: response.data.CustomerID,
                            ClientSecret: response.data.Secret,
                        };
                        this.$store.commit('SAVE_CUSTOMER_AND_CLIENT_SECRET', data);
                        this.initPayment(data.ClientSecret);
                    }
                })
                .catch((error) => {});
        },
        initPayment(clientSecret) {
            const options = {
                clientSecret,
                // Fully customizable with appearance API.
                appearance: {
                    /* ... */
                },
            };

            // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
            elements = this.stripe.elements(options);
            // Create and mount the Payment Element
            this.paymentElement = elements.create('payment');
            this.paymentElement.mount('#payment-element');
            const that = this;
            this.paymentElement.on('ready', (event) => {
                // Handle ready event
                that.loading = false;
            });
        },

        checkSetupIntentStatus(clientSecret) {
            this.loading = true;
            // Retrieve the "setup_intent_client_secret" query parameter appended to
            // your return_url by Stripe.js
            // const clientSecret = new URLSearchParams(window.location.search).get(
            //   'setup_intent_client_secret'
            // );

            // Retrieve the SetupIntent
            this.stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
                const message = document.querySelector('#message');

                // Inspect the SetupIntent `status` to indicate the status of the payment
                // to your customer.
                //
                // Some payment methods will [immediately succeed or fail][0] upon
                // confirmation, while others will first enter a `processing` state.
                //
                // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
                switch (setupIntent.status) {
                    case 'succeeded': {
                        message.innerText = 'Success! Your payment method has been saved.';
                        this.loading = false;
                        this.success = true;
                        if (
                            !this.$store.state.newMembership.PaymentMethodeID ||
                            this.$store.state.newMembership.PaymentMethodeID === ''
                        ) {
                            this.$store.commit('SET_PAYMENT_METHOD_ID', setupIntent.payment_method);
                            this.$store.commit('TO_REVIEW');
                        }
                        break;
                    }

                    case 'processing': {
                        message.innerText = "Processing payment details. We'll update you when processing is complete.";
                        this.loading = false;
                        setTimeout(() => {
                            this.checkSetupIntentStatus(clientSecret);
                        }, 100);
                        break;
                    }

                    case 'requires_payment_method': {
                        if (setupIntent.payment_method) {
                            message.innerText = 'Failed to process payment details. Please try another payment method.';

                            // Redirect your user back to your payment page to attempt collecting
                            // payment again
                        }
                        this.initPayment(clientSecret);

                        break;
                    }
                }
            });
        },

        getTotalPrice() {
            if (this.$store.state.newMembership.Plan) {
                let total = this.calculatePrice(this.$store.state.newMembership.Plan);
                if (this.$store.state.newMembership.Extras) {
                    this.$store.state.newMembership.Extras.forEach((element) => {
                        total += this.calculatePrice(element);
                    });
                }
                return total;
            }
            return 0;
        },

        calculatePrice(item) {
            if (!item || !item.PriceInCents) return 0;

            let price = item.PriceInCents / 100.0;

            if (!item.TaxRate || (item.TaxRate && item.TaxRate.Inclusive)) return price;

            if (item.TaxRate && item.TaxRate.Percentage) {
                price = price * (item.TaxRate.Percentage / 100 + 1);
            }
            return price;
        },
    },
};
</script>
