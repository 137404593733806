<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <div>
        <!-- search and actions start -->
        <div class="px-sm-4 d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center bg-white search-bar-wrapper rounded-pill">
                <b-icon class="mr-2" icon="search"></b-icon>
                <div class="search-input-wrapper">
                    <input
                        class="search-input"
                        type="search"
                        v-model="tableFilter"
                        placeholder="Search"
                        aria-label="Search"
                    />
                </div>
            </div>

            <b-button variant="primary" v-b-modal.modal-create-menu-option>Add</b-button>
        </div>
        <!-- search and actions end -->

        <div class="px-md-4">
            <div>
                <div class="mt-4">
                    <b-table
                        striped
                        hover
                        :items="objects"
                        :fields="fields"
                        sort-icon-left
                        :per-page="perPage"
                        stacked="md"
                        :filter="tableFilter"
                        :current-page="currentPage"
                        @filtered="onFiltered"
                        primary-key="Name"
                        class="table table-borderless table-responsive w-100 d-md-table"
                    >
                        <template v-slot:cell(Name)="row">
                            <div class="d-flex align-items-center">
                                <div>
                                    <ProfileCircle
                                        :ImageURL="row.item.Profile.ImageURL"
                                        :Slug="row.item.Slug"
                                    ></ProfileCircle>
                                </div>
                                <div class="ml-3">
                                    <router-link style="color: #212529" :to="'/profile/' + row.item.Slug"
                                        >{{ row.item.Profile.Name }}
                                    </router-link>
                                </div>
                            </div>
                        </template>

                        <template v-slot:cell(Actions)="row">
                            <b-button
                                v-if="totalRows !== 1"
                                size="sm"
                                class="mr-2"
                                variant="danger"
                                @click="removeAdminRights(row.item)"
                                >Delete
                            </b-button>
                        </template>
                    </b-table>
                    <div v-if="objects.length === 0" class="my-3">You don't have any administrators yet. Add some.</div>
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        aria-controls="my-table"
                    ></b-pagination>
                </div>
            </div>
        </div>
        <!-- Modals -->
        <!-- Create -->
        <b-modal
            id="modal-create-menu-option"
            ref="modal"
            title="Add new administrator"
            @show="resetCreateModal"
            @hidden="resetCreateModal"
            @ok="handelCreateModalOk"
            centered
        >
            <form ref="createform" @submit.stop.prevent="handleCreateSubmit">
                <b-form-group
                    :state="nameState"
                    label="Search user"
                    label-for="name-input"
                    invalid-feedback="type username here"
                >
                    <b-form-input
                        id="name"
                        class="rounded-pill"
                        v-model="name"
                        placeholder="type user name"
                        required
                        @input="searchUsers()"
                    ></b-form-input>
                </b-form-group>
                <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
                    <b-table
                        striped
                        hover
                        :items="searchResult"
                        :fields="fields"
                        :current-page="currentPage"
                        sort-icon-left
                        :per-page="perPage"
                        stacked="md"
                        primary-key="Name"
                        class="table table-borderless table-responsive w-100 d-md-table"
                    >
                        <template v-slot:cell(Actions)="row">
                            <b-button v-if="isAlreadyAdmin(row.item.ID, objects)" disabled size="sm" class="mr-2"
                                >Already Admin
                            </b-button>
                            <b-button v-else size="sm" @click="makeUserAdmin(row.item)" class="mr-2 primary"
                                >Make Admin
                            </b-button>
                        </template>

                        <template v-slot:cell(Name)="row">
                            <div class="d-flex align-items-center">
                                <div>
                                    <ProfileCircle
                                        :ImageURL="row.item.Profile.ImageURL"
                                        :Slug="row.item.Slug"
                                    ></ProfileCircle>
                                </div>
                                <div class="ml-3">
                                    <CoLink
                                        :to="`/profile/${row.item.Slug}`"
                                        :title="row.item.Profile.Name"
                                        styles="basic-link"
                                    />
                                </div>
                            </div>
                        </template>
                    </b-table>
                    <p v-if="name !== '' && searchResult.length === 0">No users matching your search input found</p>
                </b-overlay>
            </form>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import CoLink from '@/components/Atoms/co-link/CoLink.vue';
import ProfileCircle from '@/components/old-components/ProfileCircle.vue';
import EventBus from '@/eventBus';

export default {
    name: 'AdministratorsList',
    components: { ProfileCircle, CoLink },
    data() {
        return {
            view: 1,
            tableFilter: '',
            perPage: 10,
            currentPage: 1,
            objects: [],
            searchResult: [],
            fields: [
                {
                    key: 'Name',
                    label: 'Name',
                    sortable: true,
                },
                {
                    key: 'Actions',
                    label: 'Actions',
                },
            ],

            totalRows: 0,
            loading: false,

            // create form data
            name: '',
            nameState: null,
        };
    },
    mounted() {
        this.listAdmins();
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        listAdmins() {
            axios({
                method: 'GET',
                url: '/admin/administrator/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data && response.data.Users.length !== 0) {
                        this.objects = response.data.Users;
                        this.totalRows = this.objects.length;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        makeUserAdmin(obj) {
            this.loading = true;
            const data = JSON.stringify(obj);

            axios({
                method: 'POST',
                url: '/admin/administrator/',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.name = '';
                    this.objects.push(obj);
                    this.totalRows = this.objects.length;
                    this.loading = false;
                    this.$nextTick(() => {
                        this.$bvModal.hide('modal-create-menu-option');
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },

        removeAdminRights(obj) {
            this.loading = true;
            const data = JSON.stringify(obj);

            axios({
                method: 'DELETE',
                url: '/admin/administrator/',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.listAdmins();
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                    const message = {
                        Message: "Can't remove admin rights",
                        Details: error.response.data,
                    };
                    EventBus.$emit('ERROR', message);
                });
        },

        searchUsers() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/search/v2/user/${this.name}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data) {
                        this.searchResult = response.data;
                        return;
                    }
                    this.searchResult = [];
                })
                .catch((error) => {
                    this.loading = false;
                    this.searchResult = [];
                });
        },

        resetCreateModal(event) {
            this.name = '';
            this.searchResult = [];
        },

        handelCreateModalOk(event) {
            // Prevent modal from closing
            event.preventDefault();
            // Trigger submit handler
            this.handleCreateSubmit();
        },
        handleCreateSubmit(event) {
            // Exit when the form isn't valid
            this.checkFormValidity();
        },
        checkFormValidity() {
            const valid = this.$refs.createform.checkValidity();
            this.nameState = valid;
            return valid;
        },
        isAlreadyAdmin(id, list) {
            for (let i = 0; i < list.length; i += 1) {
                if (list[i].ID === id) {
                    return true;
                }
            }
            return false;
        },
    },
};
</script>
