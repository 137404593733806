

















































import axios from 'axios';

import EventBus from '@/eventBus';
import CoButton from '../../../../Atoms/co-button/CoButton.vue';
import CoText from '../../../../Atoms/co-text/CoText.vue';
import CoCheckbox from '../../../../Atoms/co-checkbox/CoCheckbox.vue';
import CoHeader from '../../../../Molecules/co-header/CoHeader.vue';
import CoCard from '../../../../Molecules/co-card/CoCard.vue';
import CoLink from '../../../../Atoms/co-link/CoLink.vue';

export default {
    name: 'SpacePermissions',
    components: {
        CoButton,
        CoText,
        CoCheckbox,
        CoHeader,
        CoCard,
        CoLink,
    },
    data() {
        return {
            permissions: {
                EventsCreateAdminsOnly: false,
            },
            rerenderingKey: 0,

            loading: false,
        };
    },
    mounted() {
        this.getPermissions();
    },
    methods: {
        setEventsCreateEveryone(value) {
            this.permissions.EventsCreateAdminsOnly = false;
            this.rerenderingKey += 1;
        },
        setEventsCreateAdminsOnly(value) {
            this.permissions.EventsCreateAdminsOnly = true;
            this.rerenderingKey += 1;
        },
        getPermissions() {
            this.loading = true;
            axios
                .get('/admin/space/permissions')
                .then((response: any) => {
                    if (response.data) {
                        this.permissions = response.data;
                    }
                })
                .catch((error: any) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        setPermissions() {
            this.loading = true;
            axios
                .post('/admin/space/permissions', this.permissions)
                .then((response: any) => {
                    EventBus.$emit('INFO', {
                        Message: 'Permissions updated successfully',
                    });
                })
                .catch((error: any) => {
                    EventBus.$emit('ERROR', {
                        Message: 'Failed to update permissions',
                    });
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
