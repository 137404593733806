











































import CoCardUpgrade from '../../Molecules/co-card-upgrade/CoCardUpgrade.vue';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoModal from '../co-modal/CoModal.vue';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoRoundButton from '../../Atoms/co-round-button/CoRoundButton.vue';

export default {
    name: 'CoAddPlanUpgrade',
    components: {
        CoButton,
        CoCardUpgrade,
        CoModal,
        CoHeadline,
        CoRoundButton,
    },
    props: {
        allUpgrades: {
            type: Array,
            required: true,
        },
        selectedUpgrades: {
            type: Array,
            required: false,
            default: () => [],
        },
        ignoreSelected: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            listSelectedUpgrades: this.selectedUpgrades,
        };
    },

    methods: {
        checkForSelected(upgrade) {
            if (this.ignoreSelected) {
                return false;
            }

            // check if upgrade is in listSelectedUpgrades
            const { ID } = upgrade;
            const selected = this.listSelectedUpgrades.find((item) => item.ID === ID);
            if (selected) {
                return true;
            }
            return false;
        },
        addUpgrade(id, upgrades) {
            // for each upgrade in upgrades, check if there is taxrate object and rpelace it with taxrate id
            upgrades.forEach((upgrade) => {
                if (upgrade.TaxRate) {
                    upgrade.TaxRateID = upgrade.TaxRate.ID;
                }
                this.$emit('select', upgrade);
                this.listSelectedUpgrades.push(upgrade);
            });

            if (this.ignoreSelected) {
                this.hide();
            }
        },
        deleteUpgrade(upgrade) {
            this.$emit('delete', upgrade);
            const { ID } = upgrade;
            this.listSelectedUpgrades = this.listSelectedUpgrades.filter((item) => item.ID !== ID);
        },
        show(e) {
            this.$refs['add-plan-upgrade-modal'].show();
            // Prevents the click event from bubbling up to the parent element
            if (e) {
                e.stopPropagation();
            }
        },
        hide(e) {
            this.$refs['add-plan-upgrade-modal'].hide();
            // Prevents the click event from bubbling up to the parent element
            if (e) {
                e.stopPropagation();
            }
        },
    },
};
