




















export default {
    name: 'CoWeekdayCircle',
    props: {
        label: String,
        value: Boolean,
    },
    data() {
        return {
            selected: this.value,
        };
    },
    watch: {},
    mounted() {},
    created() {},
    methods: {
        selectWeekday() {
            this.selected = !this.selected;
            this.$emit('selecting');
        },
    },
};
