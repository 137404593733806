











































import axios from 'axios';
import { get, min, toNumber } from 'lodash';

import CoResourcePreview from '../../Organisms/co-resource-preview/CoResourcePreview.vue';
import CoCard from '../co-card/CoCard.vue';

import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoPrice from '../../Atoms/co-price/coPrice.vue';
import CoThumbnail from '../co-thumbnail/CoThumbnail.vue';

interface Price {
    PriceInCents: number;
    Currency: string;
    TaxRate: number;
    TaxName: string;
    Inclusive: boolean;
    Unit?: string;
    IsFrom?: boolean;
}

export default {
    name: 'CoCardResource',
    components: {
        CoResourcePreview,
        CoCard,
        CoHeadline,
        CoPrice,
        CoThumbnail,
    },
    props: {
        resource: {
            type: Object,
            required: true,
        },
        withDetails: {
            type: Boolean,
            required: false,
            default: true,
        },
        variant: {
            type: String,
            default: 'normal',
            validator: (value: string) => ['normal', 'gray'].includes(value),
        },
        isBillingTurnedOn: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            imageURL: this.resource.ImageURL,
            name: '',
            windowWidth: window.innerWidth,
            Price: 0,
            bookingCredits: null,
            currency: '',
            resourcePrice: '',
            availability: false,
            details: true,
            resourceObject: {},
        };
    },
    computed: {
        priceObject() {
            const priceObj: Price = {
                PriceInCents: this.resource.PriceInCents,
                Currency: this.resource.Currency,
                Unit: this.$tc('labels.hour', 1),
                TaxName: '',
                TaxRate: 0,
                Inclusive: false,
            };

            if (this.resource.PriceInCentsPerHour) {
                priceObj.PriceInCents = this.resource.PriceInCentsPerHour;
            }

            if (this.resource.TaxRate) {
                priceObj.TaxRate = this.resource.TaxRate.Percentage;
                priceObj.TaxName = this.resource.TaxRate.DisplayName;
                priceObj.Inclusive = this.resource.TaxRate.Inclusive;
            }

            if (this.resource.BookWholeSlot) {
                priceObj.Unit = this.$t('labels.booking').toLowerCase();
                if (!this.resource.PricePerSlot) {
                    // get a list of all booking slot durations
                    const bookingSlots = get(this.resource, 'BookingTimes', []).map((slot) => {
                        if (get(slot, 'From', null) && get(slot, 'To', null)) {
                            const from = new Date(`1970-01-01T${get(slot, 'From')}Z`);
                            const to =
                                this.resource.BookingSlotTo === '00:00'
                                    ? new Date(`1970-01-02T00:00:00Z`)
                                    : new Date(`1970-01-01T${get(slot, 'To')}Z`);
                            const durationInHours = (to.getTime() - from.getTime()) / 1000 / 60 / 60;
                            return durationInHours;
                        }
                    });
                    // return the smallest duration * price per hour as 'from' price per booking
                    priceObj.IsFrom = true;

                    const minRes = min(bookingSlots);
                    if (minRes && toNumber(minRes) >= 1) {
                        priceObj.PriceInCents *= toNumber(minRes);
                    }
                }
            }

            return priceObj;
        },
    },
    created() {
        this.resourceObject = this.resource;
    },

    methods: {
        select(event) {
            // if event is not target child of CoResourcePreview component than stop
            if (event && event.target.closest('.CoResourcePreview')) {
                return;
            }
            const r = JSON.parse(JSON.stringify(this.resource));
            this.$emit('select', r);
        },

        resourceDetails(event) {
            event.preventDefault();

            this.$bvModal.show(`viewResourceModal-${this.resource.ID}`);
        },

        centsToWholeUnit(cents: number): string {
            const price = cents / 100;
            return price.toFixed(2);
        },
    },
};
