


















































































import axios from 'axios';
import { cloneDeep } from 'lodash';

import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoRoundButton from '../../Atoms/co-round-button/CoRoundButton.vue';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoModal from '../co-modal/CoModal.vue';
import CoPlanList from '../co-plan-list/CoPlanList.vue';
import CoPlanSelectUpgrades from '../co-plan-select-upgrades/CoPlanSelectUpgrades.vue';
import CoPlanEdit from '../co-plan-edit/CoPlanEdit.vue';

export default {
    name: 'CoPlanSelectConfigCustomiseModal',
    components: {
        CoHeadline,
        CoRoundButton,
        CoIcon,
        CoModal,
        CoPlanList,
        CoPlanSelectUpgrades,
        CoPlanEdit,
        CoButton,
    },
    props: {
        plan: {
            type: Object,
            required: false,
            default: () => ({}),
        },

        mode: {
            type: String,
            required: false,
            default: 'list',
            validator: (value) => ['list', 'select', 'edit'].includes(value),
        },
    },

    data() {
        return {
            modeState: this.mode,
            currentPlan: null,
            currentPlanForSelectStep: null,
            plans: [],
            taxRates: [],
            upgrades: [],

            adjustingExistingPlan: false,

            loadingPlans: false,
            loadingTaxRates: false,
            loadingExtras: false,
            loadingResources: false,
            resources: [],
        };
    },
    watch: {
        plan: {
            handler(val) {
                if (val) {
                    this.currentPlanForSelectStep = val;
                    this.adjustingExistingPlan = true;
                }
            },
            deep: true,
        },
        currentPlan: {
            handler() {
                this.$emit('update', this.currentPlan);
            },
            deep: true,
        },
        loadingTaxRates: {
            handler() {
                if (!this.loadingTaxRates) {
                    this.getPlans();
                    this.getPlanUpgrades();
                    this.getResources();
                }
            },
        },
        mode: {
            handler(val) {
                this.modeState = val;
            },
        },
    },
    created() {
        this.getTaxRates();
    },
    mounted() {
        if (this.plan) {
            this.currentPlan = cloneDeep(this.plan);
            this.currentPlanForSelectStep = cloneDeep(this.plan);
            this.adjustingExistingPlan = true;
        } else {
            this.modeState = 'list';
        }

        // this.show(); // TODO: remove this
    },
    methods: {
        choose() {
            // choose emited from after choose button is clicked
            this.$emit('choose', this.currentPlan);
        },
        save() {
            // save emited from after save button is clicked
            this.$emit('save', this.currentPlan);
        },
        show() {
            this.$refs['co-plan-select-config-customise-modal'].show();
        },

        hide() {
            this.$refs['co-plan-select-config-customise-modal'].hide();
        },

        selectPlan(plan) {
            this.currentPlan = cloneDeep(plan);
            // remove extras from plan
            this.currentPlan.Extras = [];
            this.currentPlanForSelectStep = cloneDeep(plan);
            this.modeState = 'select';
            this.adjustingExistingPlan = false;
        },

        selectedPlanUpgrades(extras) {
            // flatten extras map to array of extras
            const keys = Object.keys(extras);
            const extrasArray = [];
            keys.forEach((key) => {
                // concat all extras into one array
                extrasArray.push(...extras[key]);
            });
            this.currentPlan.Extras = cloneDeep(extrasArray);
        },
        updatePlan(plan) {
            this.currentPlan = plan;
        },
        getPlanUpgrades() {
            // get all upgrades available for in space
            this.loadingExtras = true;
            axios({
                method: 'GET',
                url: `/admin/extra/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        const upgrades = response.data.Extras;
                        upgrades.forEach((upgrade) => {
                            const taxRate = this.taxRates.find((taxRate) => taxRate.ID === upgrade.TaxRateID);
                            if (taxRate) {
                                upgrade.TaxRate = taxRate;
                            }
                        });

                        this.upgrades = upgrades;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingExtras = false;
                });
        },
        getTaxRates() {
            // get all tax rates available for in space
            this.loadingTaxRates = true;
            axios({
                method: 'GET',
                url: `/admin/tax-rate/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.taxRates = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingTaxRates = false;
                });
        },
        getPlans() {
            // get only active plans available in space
            this.loadingPlans = true;
            axios({
                method: 'GET',
                url: `/admin/plan/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        let plans = response.data.Plans;

                        // filter, left only active plans
                        plans = plans.filter((plan) => plan.Active);

                        // check if taxrates  have taxrate for each plan.TaxRateID and add it to the plan object
                        plans.forEach((plan) => {
                            const taxRate = this.taxRates.find((taxRate) => taxRate.ID === plan.TaxRateID);
                            if (taxRate) {
                                plan.TaxRate = taxRate;
                            }
                        });

                        this.plans = plans;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingPlans = false;
                });
        },
        getResources() {
            // get all resources available for in space
            this.loadingResources = true;
            axios({
                method: 'GET',
                url: '/admin/booking/resource/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data && response.data.Resources) {
                        this.resources = response.data.Resources;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingResources = false;
                });
        },
    },
};
