



































import axios from 'axios';
import EventBus from '@/eventBus';
import { get, defaultTo } from 'lodash';
import CoPlanEdit from '@/components/Organisms/co-plan-edit/CoPlanEdit.vue';
import CoHeader from '@/components/Molecules/co-header/CoHeader.vue';

export default {
    name: 'PlanEdit',
    components: {
        CoPlanEdit,
        CoHeader,
    },
    data() {
        return {
            plan: null,
            loading: false,

            image: null,
            imageURL: '',

            // data for extras
            allExtras: [],

            // all available resources
            bookingResources: [],

            taxRates: [],
            saveClicked: false,
            loadingTaxRate: false,
            listNamesResources: [],
            extras: [],
            selectedResources: [],
            selectedResourceGroups: [],
        };
    },
    watch: {
        extras(newExtras) {
            this.plan.Extras = newExtras;
        },
        selectedResources(newResources) {
            this.plan.AvailableResources = newResources;
        },
        selectedResourceGroups(newGroupResources) {
            this.plan.AvailableResourceGroups = newGroupResources;
        },
    },
    computed: {
        planName() {
            return get(this.plan, 'Name', '');
        },
    },
    created() {
        this.getPlanByID(this.$route.params.ID);
    },
    methods: {
        cancel() {
            this.$router.push(`/admin/monetization/plan/${this.plan.ID}`);
        },
        getListNamesResources(bookingResources) {
            bookingResources.forEach((element) => this.listNamesResources.push(element.Name));
        },

        listResources() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/admin/booking/resource/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response.data && response.data.Resources.length !== 0) {
                        this.bookingResources = response.data.Resources;

                        this.getListNamesResources(this.bookingResources);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },

        listAllExtras() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/extra/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.allExtras = response.data.Extras;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },

        uploadImages(callback) {
            const infoMsg = {
                Message: 'Uploading images',
                Details: null,
            };
            EventBus.$emit('INFO', infoMsg);

            this.$store
                .dispatch('imageUpload', {
                    file: this.image,
                    url: '/dashboard/image/plans',
                })
                .then((response) => {
                    this.plan.ImageURL = response;
                    callback();
                })
                .catch((error) => {
                    console.log(error);
                    const errorMsg = {
                        Message: 'Could not upload one or more images, please try again or check details',
                        Details: '',
                    };
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.message === 'Request Entity Too Large'
                    ) {
                        errorMsg.Message = 'The file you tried to upload is too large. Max 10 MB.';
                    }
                    EventBus.$emit('ERROR', errorMsg);
                })
                .finally(() => {
                    this.loading = false;
                    this.saveClicked = false;
                });
        },

        update() {
            this.saveClicked = true;
            this.loading = true;

            // check if this.plan.ImageURL is a string or a file
            if (this.plan.ImageURL && typeof this.plan.ImageURL !== 'string') {
                this.image = this.plan.ImageURL;
            }
            if (this.image) {
                this.uploadImages(this.editPlan);
            } else {
                this.editPlan();
            }
        },

        getTaxRates() {
            this.loadingTaxRate = true;
            axios({
                method: 'GET',
                url: `/admin/tax-rate/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.taxRates = response.data;

                        if (this.plan.TaxRateID) {
                            const resultTaxRate = this.taxRates.find((taxRate) => taxRate.ID === this.plan.TaxRateID);
                            this.plan.TaxRate = {
                                Value: resultTaxRate,
                                Name: resultTaxRate.DisplayName,
                            };
                        }
                    }
                    this.loadingTaxRate = false;
                })
                .catch((error) => {
                    this.loadingTaxRate = false;
                    console.log(error);
                });
        },

        editPlan() {
            const planObject = this.plan;
            // temp fix for the plan object
            const editedPlan = planObject;
            delete editedPlan.LeastTermTimeUnit;
            delete editedPlan.LeastTerm;
            if (planObject.TaxRate && planObject.TaxRate.Value) {
                editedPlan.TaxRateID = planObject.TaxRate.Value.ID;
                editedPlan.TaxRate = null;
            }

            this.loading = true;
            this.$store
                .dispatch('editPlan', editedPlan)
                .then((response) => {
                    EventBus.$emit('INFO', {
                        Message: this.$t('labels.changessaved'),
                        Details: '',
                    });
                    this.plan = response;
                    this.$router.push(`/admin/monetization/plan/${this.plan.ID}`);
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: 'Failed to edit plan',
                        Details: error,
                    });
                })
                .finally(() => {
                    this.loading = false;
                    this.saveClicked = false;
                });
        },
        getPlanByID(id) {
            this.loading = true;
            this.$store
                .dispatch('getPlanByID', id)
                .then((response) => {
                    if (response) {
                        this.plan = response;
                        if (this.$store.state.space.Currency) {
                            this.plan.Currency = this.$store.state.space.Currency;
                        }

                        this.listAllExtras();
                        this.listResources();
                        this.getTaxRates();
                        this.extras = defaultTo(this.plan.Extras, []);
                        this.selectedResources = defaultTo(this.plan.AvailableResources, []);
                        this.selectedResourceGroups = defaultTo(this.plan.AvailableResourceGroups, []);

                        if (this.plan.TaxRateID) {
                            this.plan.TaxRate = this.taxRates.find((taxRate) => taxRate.ID === this.plan.TaxRateID);
                        }

                        if (!this.plan.TimeInteval) {
                            this.plan.TimeInteval = 0;
                        }

                        if (!this.plan.IntervalCount) {
                            this.plan.IntervalCount = 1;
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
