var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"height":"2.25rem"}}),_c('div',{staticClass:"search-input-wrapper"},[_c('CoInput',{attrs:{"placeholder":_vm.$t('search'),"icon":"search","no-border":""},on:{"change":_vm.changeSearchTerm},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.search.length !== 0 && _vm.feed.length == 0 && !_vm.searchLoading)?_c('div',{staticClass:"mt-3 text-center"},[_c('div',{},[_c('h3',[_c('strong',[_vm._v(_vm._s(_vm.$t('noresultsforkeyword', { query: _vm.query })))])]),_c('span',[_vm._v(_vm._s(_vm.$t('searchhelp')))])])]):_vm._e(),(_vm.searchLoading)?_c('div',{staticClass:"d-flex align-items-center p-1"},[_c('h3',[_vm._v(_vm._s(_vm.$t('searching', { query: this.$route.query.term })))]),_c('b-spinner',{staticClass:"ml-auto",attrs:{"variant":"primary"}})],1):_vm._e()],1),(_vm.feed.length != 0)?_c('div',{staticClass:"mt-3"},[_c('span',{staticClass:"pl-3"},[_vm._v(_vm._s(_vm.$tc('results', _vm.results)))]),_c('div',{staticClass:"mt-3"},_vm._l((_vm.feed),function(post,index){return _c('div',{key:(index + " " + (post.key)),staticClass:"mb-2"},[(post.type === 'project')?_c('CoCardPage',{attrs:{"page":post.page},on:{"follow":_vm.follow,"unfollow":_vm.unfollow}}):(post.type === 'event')?_c('EventCard',{attrs:{"object":post}}):(post.type === 'job')?_c('JobCard',{attrs:{"object":post}}):(post.type === 'user')?_c('co-card-member',{attrs:{"member":{
                        id: post.object.ID,
                        name: post.object.Profile.Name,
                        image: post.object.Profile.ImageURL,
                        headline: post.object.Profile.Bio,
                        slug: post.object.Slug,
                        space: _vm.mapSpaceObject(post.space),
                    },"hideMessageBtn":post.object.ID === _vm.$store.state.me.ID},on:{"message":_vm.sendMessage}}):_vm._e()],1)}),0)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }