

































































import { cloneDeep } from 'lodash';
import CoCard from '../../Molecules/co-card/CoCard.vue';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';

import CoPrice from '../../Atoms/co-price/coPrice.vue';
import CoCardUpgrade from '../../Molecules/co-card-upgrade/CoCardUpgrade.vue';
import CoCardResource from '../../Molecules/co-card-resource/CoCardResource.vue';
import CoCardResourceGroup from '../../Molecules/co-card-resource-group/CoCardResourceGroup.vue';
import CoFormGroup from '../../Molecules/co-form-group/CoFormGroup.vue';
import CoInput from '../../Molecules/co-input/CoInput.vue';
import CoTextReadmore from '../../Molecules/co-text-readmore/CoTextReadmore.vue';

interface Price {
    PriceInCents: number;
    Currency: string;
    TaxRate: number;
    TaxName: string;
    Inclusive: boolean;
    Unit?: string;
}

export default {
    name: 'CoPlanSelectUpgrades',
    components: {
        CoCard,
        CoCardResource,
        CoCardResourceGroup,
        CoHeadline,
        CoPrice,
        CoCardUpgrade,
        CoFormGroup,
        CoInput,
        CoTextReadmore,
    },
    props: {
        plan: {
            type: Object,
            required: true,
        },
        preSelectedExtras: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            selectedExtras: {},
            extrasKey: 1,
        };
    },
    computed: {
        filteredExtras() {
            const exts = cloneDeep(this.plan.Extras);
            // remove duplicates from plan.Extras
            const filteredExtras = exts.filter(
                (extra, index, self) => index === self.findIndex((t) => t.ID === extra.ID)
            );
            return filteredExtras;
        },
        priceObject() {
            const priceObj: Price = {
                PriceInCents: this.plan.PriceInCents,
                Currency: this.plan.Currency,
                Unit: 'Month',
                TaxName: '',
                TaxRate: 0,
                Inclusive: false,
            };

            if (this.plan.TimeInteval === 1) {
                priceObj.Unit = 'Year';
            }

            if (this.plan.TaxRate) {
                priceObj.TaxRate = this.plan.TaxRate.Percentage;
                priceObj.TaxName = this.plan.TaxRate.Name;
                priceObj.Inclusive = this.plan.TaxRate.Inclusive;
            }
            return priceObj;
        },
    },
    watch: {
        selectedExtras: {
            handler(val) {
                this.extrasKey += 1;
            },
            deep: true,
        },
        preSelectedExtras: {
            handler(val) {
                this.selectedExtras = {};
                this.extrasKey += 1;
                val.forEach((extra) => {
                    if (!this.selectedExtras[extra.ID]) {
                        this.selectedExtras[extra.ID] = [];
                    }
                    this.selectedExtras[extra.ID].push(extra);
                    this.extrasKey += 1;
                });
                this.extrasKey += 1;
            },
            deep: true,
        },
    },
    mounted() {
        // get list of keys in selectedExtras
        const keys = Object.keys(this.selectedExtras);
        this.selectedExtras = {};
        this.preSelectedExtras.forEach((extra) => {
            if (!this.selectedExtras[extra.ID]) {
                this.selectedExtras[extra.ID] = [];
            }
            this.selectedExtras[extra.ID].push(extra);
            this.extrasKey += 1;
        });
    },

    methods: {
        getNumberOfUpgrades(ID): number {
            // get the number of upgrades with the same ID
            const num = this.preSelectedExtras.filter((extra) => extra.ID === ID).length;
            return num;
        },

        deleteUpgrade(extra) {
            delete this.selectedExtras[extra.ID];
            // clone object using lodash
            const extras = cloneDeep(this.selectedExtras);

            this.$emit('selected', extras);
        },
        addUpgrade(id, extras) {
            this.selectedExtras[id] = extras;
            const extrs = cloneDeep(this.selectedExtras);

            this.$emit('selected', extrs);
        },
    },
};
