






















export default {
    name: 'CoBadge',
    props: {
        variant: {
            type: String,
            default: 'primary',
        },

        label: {
            type: String,
            default: '',
        },
    },
};
