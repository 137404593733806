<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>
<template>
    <div>
        <!-- search and actions start -->
        <div class="px-sm-4 d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center bg-white search-bar-wrapper rounded-pill">
                <b-icon class="mr-2" icon="search"></b-icon>
                <div class="search-input-wrapper">
                    <input
                        class="search-input"
                        type="search"
                        v-model="search"
                        placeholder="Search"
                        aria-label="Search"
                    />
                </div>
            </div>

            <div>
                <b-dropdown id="import_btn" text="Import" variant="primary" class="mr-2">
                    <b-dropdown-item @click="startImport">Start Import</b-dropdown-item>
                    <b-dropdown-item v-b-modal.modal-history>History and status</b-dropdown-item>
                </b-dropdown>
                <b-tooltip
                    label="Import identities(user) from Deister access
      system"
                    target="import_btn"
                    position="is-bottom"
                    >Imports identities (tokens/credentials) from Deister access system</b-tooltip
                >
                <b-button id="create_btn" variant="primary" to="/admin/access/identity/create">Create</b-button>
            </div>
        </div>
        <!-- search and actions end -->

        <!-- identities start -->
        <div class="container-fluid p-0 m-0">
            <!-- if emtpy and not loading -->
            <div class="row text-center m-0 mt-5" v-if="objects.length === 0 && !loading">
                <div class="col">
                    <!-- <illustration type="projects" class="my-0 illu"></illustration> -->

                    <h2>Get started!</h2>
                    <div class="mb-4">Import identities</div>
                </div>
            </div>
            <!-- if loading -->
            <div class="row text-center m-0 mt-5" v-else-if="objects.length === 0 && loading">
                <div class="col">
                    <b-spinner variant="primary" label="loading"></b-spinner>
                </div>
            </div>
            <div class="m-0 mt-4 px-sm-4">
                <div class="mb-3" v-for="(item, i) in filteredItems" :key="item.ID">
                    <AccessIdentityCard :object="item.object"></AccessIdentityCard>
                </div>
            </div>
        </div>
        <!-- identities end -->

        <!-- Modal -->
        <b-modal
            id="modal-history"
            ref="modal-history"
            title="Importing history and status"
            centered
            hide-footer
            @show="getHistory"
            class="h-100"
            size="xl"
        >
            <div class="overflow-auto mh-50 h-50">
                <div class="h-100">
                    <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
                        <b-table
                            striped
                            hover
                            :items="history"
                            :fields="fields"
                            sort-icon-left
                            primary-key="ID"
                            class="table table-borderless table-responsive w-100 d-md-table"
                        >
                            <template v-slot:cell(Status)="row">
                                <span v-if="row.item.Status">{{ status[row.item.Status] }}</span>
                                <span v-else>-</span>
                            </template>

                            <template v-slot:cell(Progress)="row">
                                <span v-if="row.item.Progress">{{ row.item.Progress.toFixed(1) }}%</span>
                                <span v-else>-</span>
                            </template>
                            <template v-slot:cell(StartedAt)="row">
                                <span>{{ row.item.StartedAt | timestampToString }}</span>
                            </template>

                            <template v-slot:cell(FinishedAt)="row">
                                <span>{{ row.item.FinishedAt | timestampToString }}</span>
                            </template>
                            <template v-slot:cell(Actions)="row">
                                <!-- <b-button
                  v-if="!row.item.FinishedAt"
                  @click="stopJob(row.item)"
                  class="mr-2 primary"
                  >Stop</b-button
                > -->
                            </template>
                        </b-table>
                        <p v-if="history && history.length === 0">No history to display</p>
                    </b-overlay>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import EventBus from '@/eventBus';
import { P } from 'vue-long-click';

export default {
    name: 'Identity',
    components: {},
    data() {
        return {
            tabIndex: 0,
            loading: false,
            objects: [],

            search: '',
            history: [],
            fields: [
                {
                    key: 'Status',
                    label: 'Status',
                    sortable: true,
                },
                {
                    key: 'Progress',
                    label: 'Progress',
                    sortable: true,
                },
                {
                    key: 'StartedAt',
                    label: 'Started At',
                    sortable: true,
                },
                {
                    key: 'FinishedAt',
                    label: 'Finished At',
                    sortable: true,
                },
                {
                    key: 'ErrorMessage',
                    label: 'Message',
                    sortable: true,
                },
                {
                    key: 'Actions',
                    label: '',
                },
            ],

            status: {
                0: 'Pending',
                1: 'Success',
                2: 'Failed',
                3: 'Running',
            },
        };
    },
    mounted() {
        this.list();
    },
    computed: {
        filteredItems() {
            const searchLowerCase = this.search.toLowerCase();
            return this.objects.filter((item) => {
                if (
                    item.object.DisplayName &&
                    item.object.DisplayName.toLowerCase().indexOf(searchLowerCase) > -1 == true
                ) {
                    return true;
                }
                if (
                    item.object.FirstName &&
                    item.object.FirstName.toLowerCase().indexOf(searchLowerCase) > -1 == true
                ) {
                    return true;
                }
                if (item.object.LastName && item.object.LastName.toLowerCase().indexOf(searchLowerCase) > -1 == true) {
                    return true;
                }
                if (item.object.Email && item.object.Email.toLowerCase().indexOf(searchLowerCase) > -1 == true) {
                    return true;
                }
                return false;
            });
        },
    },
    methods: {
        stopJob(item) {},
        getHistory() {
            this.loading = true;
            axios
                .get('/admin/space/access/identity/import/history')
                .then((response) => {
                    if (response && response.data) {
                        this.history = response.data;
                    } else {
                        this.history = [];
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        EventBus.$emit('ERROR', {
                            Message: error.response.data.message,
                        });
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: 'Something went wrong',
                        });
                    }
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        startImport() {
            this.loading = true;
            axios
                .get('/admin/space/access/identity/import')
                .then((response) => {
                    this.loading = false;
                    EventBus.$emit('INFO', {
                        Message: 'Import job started. Please check the status later.',
                    });
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        EventBus.$emit('ERROR', {
                            Message: error.response.data.message,
                        });
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: 'Something went wrong',
                        });
                    }
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        list(url) {
            let defaultUrl = '/admin/space/access/identity/list';
            if (url) {
                defaultUrl = url;
            }
            this.loading = true;
            axios
                .get(defaultUrl)
                .then((response) => {
                    if (response && response.data) {
                        this.objects.push(...response.data.objects);
                        if (response.data.next) {
                            this.list(response.data.next);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        back() {
            if (this.tabIndex > 0) {
                this.tabIndex -= 1;
            }
        },
        linkClass(idx) {
            if (this.tabIndex === idx) {
                return ['activeOne'];
            }
            return ['not-activeOne'];
        },
    },
};
</script>
