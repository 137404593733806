











































































import axios from 'axios';
import { get } from 'lodash';
import CoCard from '../../Molecules/co-card/CoCard.vue';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoSkeleton from '../../Atoms/co-skeleton/CoSkeleton.vue';

export default {
    name: 'CoLinkPreview',
    components: { CoCard, CoHeadline, CoIcon, CoSkeleton },
    props: {
        url: {
            type: String,
        },
        defaultClasses: {
            type: Object,
            default: null,
        },
        embedClasses: {
            type: Object,
            default: null,
        },
        videoClasses: {
            type: Object,
            default: null,
        },
        preventEmbedding: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            title: null,
            loading: false,
            embedHTML: null,
            media: null,
        };
    },
    computed: {},
    mounted() {
        if (!this.url) return;
        var id;

        if (this.preventEmbedding) {
            this.getLinkInfo();
            return;
        }

        switch (true) {
            case /youtube-nocookie\.com\/|youtube\.com\/|youtu\.be\//gi.test(this.url):
                id = this.url.match(/(?<=youtu.be\/|\?v=|embed\/)([^\s&?]+)/gi)[0];
                this.media = {
                    type: 'YouTube',
                    id: id,
                    embedurl: `https://www.youtube-nocookie.com/embed/${id}`,
                };
                break;
            case /vimeo\.com/gi.test(this.url):
                this.getVimeo();
                break;
            case /spotify\.com/gi.test(this.url):
                this.getSpotify();
                break;
            case /soundcloud\.com/gi.test(this.url):
                this.getSoundCloud();
                break;
            default:
                this.getLinkInfo();
        }
    },
    methods: {
        getSoundCloud() {
            this.media = {
                type: 'soundcloud',
                id: null,
                embedurl: null,
            };
            axios
                .get(`https://soundcloud.com/oembed?maxheight=166&format=json&url=${encodeURIComponent(this.url)}`)
                .then((response) => {
                    // this.media.id = response.data.video_id;
                    this.embedHTML = response.data.html;
                })
                .catch((err) => {
                    this.media = null;
                    this.getLinkInfo();
                    console.warn('could not load soundcloud data');
                });
        },
        getSpotify() {
            this.media = {
                type: 'spotify',
                id: null,
                embedurl: null,
            };
            axios
                .get(`https://open.spotify.com/oembed?url=${encodeURIComponent(this.url)}`)
                .then((response) => {
                    this.media.id = response.data.video_id;
                    // this.media.embedurl = `${response.data.html}`;
                    this.embedHTML = response.data.html;
                })
                .catch((err) => {
                    this.media = null;
                    this.getLinkInfo();
                    console.warn('could not load vimeo info');
                });
        },
        getVimeo() {
            this.media = {
                type: 'vimeo',
                id: null,
                embedurl: null,
            };
            axios
                .get(`https://vimeo.com/api/oembed.json?url=${this.url}`)
                .then((response) => {
                    this.media.id = response.data.video_id;
                    this.media.embedurl = `https:/player.vimeo.com/video/${response.data.video_id}`;
                })
                .catch((err) => {
                    this.media = null;
                    this.getLinkInfo();
                    console.warn('could not load vimeo info');
                });
        },
        getLinkInfo() {
            var url = new URL(this.url);
            this.loading = true;
            axios({
                method: 'GET',
                url: `/dashboard/og?url=${this.url}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.title = get(response, 'data.title');
                    this.title.length < 3 ? (this.title = url.hostname) : null;
                })
                .catch((error) => {
                    this.title = url.hostname;
                    console.log('error', error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        openURL(url) {
            if (!url) return false;
            window.open(url, '_blank').focus();
        },
    },
};
