<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>
<style lang="less" scoped>
a {
    color: inherit;
    text-decoration: inherit;
}
</style>

<template>
    <router-link
        class="bg-white round-unify w-100 d-flex align-items-start overflow-hidden p-3"
        tag="a"
        :to="`/admin/access/identity/edit/${object.ID}`"
    >
        <div class="w-100 d-flex justify-content-between">
            <div class="">
                <div class="mb-2">
                    <small>Display Name</small> <br />
                    <p v-if="object.DisplayName" class="">
                        {{ object.DisplayName }}
                    </p>
                    <p v-else class="">n/a</p>
                </div>

                <div class="d-flex">
                    <div class="mr-3">
                        <small>First name</small> <br />
                        <h3>
                            {{ object.FirstName }}
                        </h3>
                    </div>
                    <div class="mr-3">
                        <small>Last name</small> <br />
                        <h3 class="title">
                            {{ object.LastName }}
                        </h3>
                    </div>
                </div>

                <div class="mb-2">
                    <small>Email</small> <br />
                    <p>
                        {{ object.Email }}
                    </p>
                </div>

                <div class="d-flex">
                    <div class="mr-3">
                        <small>Source</small> <br />
                        <h3>
                            {{ sources[object.Source] }}
                        </h3>
                    </div>
                    <div class="mr-3">
                        <small>System ID</small> <br />
                        <h3 class="title">
                            {{ object.SystemUserID }}
                        </h3>
                    </div>
                    <div class="mr-3">
                        <small>System ID2</small> <br />
                        <h3 class="title">
                            {{ object.SystemUserID2 }}
                        </h3>
                    </div>
                </div>

                <div v-if="object.Policies && object.Policies.length !== 0" class="mb-2">
                    <small>Policies</small> <br />

                    <div class="d-flex">
                        <div v-for="(item, i) in object.Policies" :key="i" class="mr-2">
                            <p class="mb-0">
                                {{ item.Name }}
                            </p>
                        </div>
                    </div>
                </div>

                <div v-if="object.Tags && object.Tags.length !== 0" class="">
                    <small>Tags</small> <br />

                    <div class="d-flex">
                        <div v-for="(item, i) in object.Tags" :key="i" class="mr-2">
                            <p class="mb-0">
                                {{ item.Name }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="mt-2">
                    <small>
                        Created at {{ object.CreatedAt | timestampToString }} <br />
                        Updated at {{ object.UpdatedAt | timestampToString }}
                    </small>
                </div>
            </div>
            <div class="">
                <b-dropdown
                    variant="p-0"
                    class="d-menu"
                    menu-class="mt-0 border-1"
                    no-caret
                    right
                    round
                    style="position: relative"
                >
                    <template v-slot:button-content class="px-0">
                        <b-icon icon="three-dots" class="m-0" scale="1"></b-icon>
                    </template>
                    <b-dropdown-item class="d-action" @click="edit(object.ID)">Edit</b-dropdown-item>
                    <!-- <b-dropdown-item variant="danger" class="d-action" @click="deleteIdentity(object.ID)"
            >Delete</b-dropdown-item
          > -->
                </b-dropdown>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'AccessIdentityCard',
    props: ['object'],
    data() {
        return {
            sources: {
                0: 'Deister',
            },
        };
    },
    created() {},

    mounted() {},
    methods: {
        edit(id) {
            this.$router.push('/admin/access/identity/edit/${id}');
        },

        deleteIdentity(id) {
            this.$emit('deleteCallBack', id);
        },
    },
};
</script>
