import { render, staticRenderFns } from "./CoCreateResourceCheckoutPage.vue?vue&type=template&id=57ff7710&scoped=true&"
import script from "./CoCreateResourceCheckoutPage.vue?vue&type=script&lang=js&"
export * from "./CoCreateResourceCheckoutPage.vue?vue&type=script&lang=js&"
import style0 from "./CoCreateResourceCheckoutPage.vue?vue&type=style&index=0&id=57ff7710&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57ff7710",
  null
  
)

export default component.exports