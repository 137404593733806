





































export default {
    name: 'CoButtonGroup',

    mounted() {
        // next tick

        this.addFirstAndLastChildClasses();
    },
    methods: {
        addFirstAndLastChildClasses() {
            const buttonsGroup = this.$refs['buttons-group'];
            // get all children of button group
            const buttons = buttonsGroup.querySelectorAll('.button');

            if (!buttons) {
                return;
            }

            if (!buttons && buttons.length < 2) {
                return;
            }

            buttons[0].classList.add('first-child');
            buttons[buttons.length - 1].classList.add('last-child');
        },
    },
};
