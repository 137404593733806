<template>
    <div>
        <!-- payment methods start -->
        <div class="p-0 pl-sm-4 m-0">
            <b-overlay :show="loading" class="round-unify my-4">
                <div class="bg-white round-unify p-4">
                    <h2>Manage your payment methods</h2>
                    <p>
                        Turn on payment methods in stripe before turning them on here.
                        <a href="https://dashboard.stripe.com/settings/payment_methods" target="_blank"
                            >Stripe payment methods</a
                        >
                    </p>
                    <b-form ref="tax-rate-form" class="d-flex flex-column">
                        <b-form-group
                            id="input-group-card"
                            label="Cards"
                            label-for="input-cards"
                            label-class="label-align"
                        >
                            <b-form-checkbox v-model="form.Card" switch size="lg"></b-form-checkbox>
                        </b-form-group>
                        <b-form-group
                            id="input-group-sepa"
                            label="SEPA"
                            label-for="input-sepa"
                            label-class="label-align"
                        >
                            <b-form-checkbox v-model="form.SEPA" switch size="lg"></b-form-checkbox>
                        </b-form-group>
                        <co-alert variant="grey" size="md">
                            By default, SEPA direct debit transactions have a limit of EUR 10,000 each. For new
                            customers, there is an additional weekly total limit of EUR 10,000. For more information on
                            SEPA payment limits, see
                            <a
                                href="https://help.coapp.io/en/articles/8519182-important-considerations-when-accepting-sepa-direct-debit-payments-for-memberships"
                                target="_blank"
                                title="Read the Help Article"
                                >'Important considerations when accepting SEPA direct debit payments for memberships'</a
                            >
                            in our in our Help Center.
                        </co-alert>
                        <div class="d-flex justify-content-end mt-4">
                            <b-button variant="primary" type="button" @click="setPaymentMethods">Save</b-button>
                        </div>
                    </b-form>
                </div>
            </b-overlay>
        </div>
        <!-- payment methods end -->
    </div>
</template>

<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';
import CoAlert from '../../../../components/Molecules/co-alert/CoAlert.vue';

export default {
    name: 'PaymentMethods',
    components: { CoAlert },
    data() {
        return {
            form: {
                Card: false,
                SEPA: false,
            },
            loading: false,
        };
    },
    mounted() {
        this.getPaymentMethods();
    },

    methods: {
        getPaymentMethods() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/admin/payment/stripe/payment-methods',
                withCredentials: true,
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data) {
                        this.form = response.data;
                    }
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: 'Could not get payment methods settings.',
                        Details: error,
                    });
                    this.loading = false;
                });
        },

        setPaymentMethods() {
            if (!this.form.Card && !this.form.SEPA) {
                EventBus.$emit('ERROR', {
                    Message: 'At least one method should be on',
                });
                return;
            }
            this.loading = true;
            axios({
                method: 'PUT',
                url: '/admin/payment/stripe/payment-methods',
                withCredentials: true,
                data: this.form,
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data) {
                        this.form = response.data;
                    }
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: 'Could not update payment methods settings.',
                        Details: error,
                    });
                    this.loading = false;
                });
        },
    },
};
</script>
