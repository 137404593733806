+
<template>
    <div>
        <div class="dashboard-container-small col-np-mobile mb-5">
            <b-card>
                <b-form @submit.stop.prevent="submit">
                    <b-form-group
                        id="input-group-provider"
                        label="Provider"
                        label-for="input-provider"
                        class="mt-4"
                        label-class="label-align"
                    >
                        <b-form-select
                            class="ml-1 mr-1 rounded-pill"
                            required
                            v-model="authMethod.Provider"
                            :options="providerOptions"
                        ></b-form-select>
                    </b-form-group>

                    <b-form-group
                        id="input-group-provider-type"
                        label="Authentication Type"
                        label-for="input-provider-type"
                        class="mt-4"
                        label-class="label-align"
                        aria-describedby="provider-type-input-description"
                    >
                        <b-form-radio-group
                            v-model="authMethod.Type"
                            :options="authMethodTypes"
                            class="ml-2"
                            value-field="item"
                            text-field="name"
                            disabled-field="notEnabled"
                        ></b-form-radio-group>
                    </b-form-group>

                    <b-form-group
                        id="input-group-name"
                        label="Name"
                        label-for="input-name"
                        class="mt-4"
                        label-class="label-align"
                        aria-describedby="name-input-description"
                    >
                        <b-form-input
                            id="input-name"
                            v-model="authMethod.Name"
                            type="text"
                            required
                            placeholder="Enter a name"
                            class="rounded-pill"
                        ></b-form-input>
                        <b-form-text id="title-input-description" class="ml-3">Give it a name</b-form-text>
                    </b-form-group>
                    <b-form-group
                        id="input-group-client-id"
                        label="Client ID"
                        label-for="input-client-id"
                        class="mt-4"
                        label-class="label-align"
                        aria-describedby="client-id-input-description"
                    >
                        <b-form-input
                            id="input-client-id"
                            v-model="authMethod.ClientID"
                            type="text"
                            required
                            placeholder="Enter the Client ID"
                            class="rounded-pill"
                        ></b-form-input>
                        <b-form-text id="title-input-description" class="ml-3">Client ID of the provider</b-form-text>
                    </b-form-group>
                    <b-form-group
                        id="input-group-client-secret"
                        label="Client Secret"
                        label-for="input-client-secret"
                        class="mt-4"
                        label-class="label-align"
                        aria-describedby="client-secret-input-description"
                    >
                        <b-form-input
                            id="input-client-secret"
                            v-model="authMethod.ClientSecret"
                            type="text"
                            required
                            placeholder="Enter the Client Secret"
                            class="rounded-pill"
                        ></b-form-input>
                        <b-form-text id="title-input-description" class="ml-3"
                            >Client secret of the provider
                        </b-form-text>
                    </b-form-group>

                    <b-form-group
                        id="input-group-auth-url"
                        label="Auth URL"
                        label-for="input-auth-url"
                        class="mt-4"
                        label-class="label-align"
                        aria-describedby="auth-url-input-description"
                    >
                        <b-form-input
                            id="input-auth-url"
                            v-model="authMethod.AuthURL"
                            type="text"
                            required
                            placeholder="Enter the Auth URL"
                            class="rounded-pill"
                        ></b-form-input>
                        <b-form-text id="title-input-description" class="ml-3"
                            >Endpoint to get the authorization code
                        </b-form-text>
                    </b-form-group>

                    <b-form-group
                        id="input-group-token-url"
                        label="Token URL"
                        label-for="input-token-url"
                        class="mt-4"
                        label-class="label-align"
                        aria-describedby="token-url-input-description"
                    >
                        <b-form-input
                            id="input-token-url"
                            v-model="authMethod.TokenURL"
                            type="text"
                            required
                            placeholder="Enter the Token URL"
                            class="rounded-pill"
                        ></b-form-input>
                        <b-form-text id="title-input-description" class="ml-3"
                            >Endpoint to get the access token
                        </b-form-text>
                    </b-form-group>

                    <b-form-group
                        id="input-group-redirect-url"
                        label="Redirect URL"
                        label-for="input-redirect-url"
                        class="mt-4"
                        label-class="label-align"
                        aria-describedby="redirect-url-input-description"
                    >
                        <b-form-input
                            id="input-redirect-url"
                            v-model="authMethod.RedirectURL"
                            type="text"
                            required
                            readonly
                            @click="copyToClipboard"
                            class="rounded-pill"
                        ></b-form-input>
                        <b-form-text text-variant="danger" id="title-input-description" class="ml-3">
                            Copy this URL and paste in the settings of the provider
                        </b-form-text>
                    </b-form-group>

                    <b-form-group
                        id="input-group-scopes"
                        label="Scopes"
                        label-for="input-scopes"
                        class="mt-4"
                        label-class="label-align"
                        aria-describedby="scopes-input-description"
                    >
                        <b-form-input
                            id="input-scopes"
                            v-model="authMethod.Scopes"
                            type="text"
                            required
                            placeholder="Example: scope1,scope2,scope3"
                            class="rounded-pill"
                        ></b-form-input>
                        <b-form-text id="title-input-description" class="ml-3"
                            >The scopes should be comma separated
                        </b-form-text>
                    </b-form-group>

                    <b-form-group
                        id="input-group-login-cta-title"
                        label="Login CTA title"
                        label-for="input-LoginTitle"
                        class="mt-4"
                        label-class="label-align"
                    >
                        <b-form-input
                            id="input-LoginTitle"
                            v-model="authMethod.LoginTitle"
                            type="text"
                            required
                            placeholder="Enter a title for the login button"
                            class="rounded-pill"
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-group-SignupTitle"
                        label="Sing Up CTA Title"
                        label-for="input-SignupTitle"
                        class="mt-4"
                        label-class="label-align"
                    >
                        <b-form-input
                            id="input-SignupTitle"
                            v-model="authMethod.SignupTitle"
                            type="text"
                            required
                            placeholder="Enter a title for sing up button"
                            class="rounded-pill"
                        ></b-form-input>
                    </b-form-group>

                    <div class="text-right mt-4">
                        <b-button variant="outline-primary" class="mr-3" @click="cancel">Cancel</b-button>
                        <b-button variant="primary" type="submit">{{ isEditing ? 'Save' : 'Create' }}</b-button>
                    </div>
                </b-form>
            </b-card>
        </div>
    </div>
</template>

<script>
import { isNil, get } from 'lodash';
import axios from 'axios';
import EventBus from '../../../../eventBus';

export default {
    name: 'AuthenticationsProviderEditCreate',
    mounted() {
        this.authMethod.RedirectURL = `https://${this.$store.state.space.DomainName}/oauth2/exchange`;
        if (this.isEditing) {
            this.fetchAuthMethod();
        } else {
            this.loading = false;
        }
    },
    data() {
        return {
            loading: true,
            providerOptions: [
                {
                    value: 'LINKEDIN',
                    text: 'Linkedin',
                },
                {
                    value: 'CUSTOM',
                    text: 'Custom',
                },
            ],
            providerDefaultValues: {
                LINKEDIN: {
                    Name: 'Linkedin',
                    AuthURL: 'https://www.linkedin.com/oauth/v2/authorization',
                    TokenURL: 'https://www.linkedin.com/oauth/v2/accessToken',
                    Scopes: 'openid,profile,email',
                    LoginTitle: 'Sign in with Linkedin',
                    SignupTitle: 'Sign up with Linkedin',
                },
            },
            authMethodTypes: [{ item: 'OAUTH2', name: 'OAuth 2.0' }],
            authMethod: {
                Name: '',
                Type: 'OAUTH2', // TODO: change when more types are implemented
                Provider: '',
                ClientID: '',
                ClientSecret: '',
                AuthURL: '',
                TokenURL: '',
                RedirectURL: '',
                Scopes: '',
            },
        };
    },
    methods: {
        fetchAuthMethod() {
            axios({
                method: 'GET',
                url: `/admin/auth-methods/${this.$route.params.authMethodID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.authMethod = get(response, 'data', this.authMethod);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        createAuthMethod() {
            axios({
                method: 'POST',
                url: '/admin/auth-methods/',
                withCredentials: true,
                data: this.authMethod,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((_) => {
                    this.$router.push({ name: 'AuthenticationsProvidersMenu' });
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: 'Could not create auth method',
                        Details: error.response.data,
                    });
                });
        },
        updateAuthMethod() {
            axios({
                method: 'PUT',
                url: `/admin/auth-methods/${this.$route.params.authMethodID}`,
                withCredentials: true,
                data: this.authMethod,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((_) => {
                    this.$router.push({ name: 'AuthenticationsProvidersMenu' });
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: 'Could not update auth method',
                        Details: error.response.data,
                    });
                });
        },
        cancel() {
            this.$router.push({ name: 'AuthenticationsProvidersMenu' });
        },
        submit() {
            if (this.isEditing) {
                this.updateAuthMethod();
            } else {
                this.createAuthMethod();
            }
        },
        async copyToClipboard() {
            // Select the text inside the input
            const inputElement = document.getElementById('input-redirect-url');
            inputElement.select();

            try {
                // Use the Clipboard API to copy the text
                await navigator.clipboard.writeText(inputElement.value);
                EventBus.$emit('INFO', {
                    Message: 'URL copied to clipboard',
                    Details: '',
                });
            } catch (err) {
                console.error('Failed to copy URL: ', err);
            }
        },
    },
    computed: {
        isEditing() {
            return !isNil(this.$route.params.authMethodID);
        },
        headerText() {
            return this.isEditing ? 'Edit' : 'Create';
        },
    },
    watch: {
        // if it's not loaded from the store, set the default values
        'authMethod.Provider': function (val) {
            if (val === 'LINKEDIN' && !this.loading) {
                this.authMethod = {
                    ...this.authMethod,
                    ...this.providerDefaultValues[val],
                };
            }
        },
    },
};
</script>

<style scoped></style>
