<template>
    <div class="bg-white round-unify mh-100 h-100 d-flex flex-column align-items-end">
        <!-- header -->
        <div class="p-3 border-bottom align-self-start w-100">{{ $t('labels.summary') }}</div>
        <!-- body -->
        <div class="align-self-start w-100 overflow-auto mh-100">
            <div v-if="plan" class="border-bottom p-3">
                <div class="d-flex justify-content-between">
                    <p class="text-muted mb-0">{{ $t('labels.plan') }}</p>
                </div>
                <div class="d-flex justify-content-between">
                    <p class="mb-0">{{ plan.Name }}</p>
                    <p class="mb-0">
                        {{ $n(calculatePrice(plan).toFixed(2), 'currency') }} {{ plan.Currency }} /
                        {{ $t(`labels.${plan.TimeInteval === 1 ? 'year' : 'month'}`) }}
                    </p>
                </div>
                <div class="w-100 d-flex">
                    <small v-if="plan.TaxRate" class="text-right w-100"
                        >{{ $t('labels.including') }} {{ plan.TaxRate.Percentage ? plan.TaxRate.Percentage : 0 }}%
                        {{ plan.TaxRate.DisplayName }}
                    </small>
                </div>
            </div>
            <div v-for="(item, index) in selected" :key="item.ID" class="border-bottom p-3">
                <div class="d-flex justify-content-between">
                    <p class="text-muted mb-0">{{ $t('labels.upgrades') }}</p>
                </div>
                <div class="d-flex justify-content-between">
                    <p class="mb-0">{{ item.Name }}</p>
                    <p class="mb-0">
                        {{ $n(calculatePrice(item).toFixed(2), 'currency') }} {{ plan.Currency }} /
                        {{ $t(`labels.${plan.TimeInteval === 1 ? 'year' : 'month'}`) }}
                    </p>
                </div>
                <div class="w-100 d-flex">
                    <small v-if="item.TaxRate" class="text-right w-100">
                        {{ $t('labels.including') }} {{ item.TaxRate.Percentage }}%
                        {{ item.TaxRate.DisplayName }}
                    </small>
                </div>
            </div>
        </div>
        <!-- footer -->
        <div class="p-3 border-top d-flex justify-content-between mt-auto w-100">
            <div class="w-100 pb-2">
                <div class="d-flex justify-content-between w-100">
                    <p class="mb-0">{{ $t('labels.total') }}</p>
                    <div class="d-flex flex-column">
                        <p class="mb-0">
                            {{ $n(totalPrice(), 'currency') }} {{ $store.state.space.Currency }} /
                            {{ $t(`labels.${plan.TimeInteval === 1 ? 'year' : 'month'}`) }}
                        </p>
                    </div>
                </div>
                <div class="d-flex justify-content-end w-100">
                    <p class="text-muted mb-0"></p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ChangePlanMembership',

    props: ['plan', 'selected'],
    data() {
        return {};
    },
    created() {},
    computed: {},
    methods: {
        totalPrice() {
            let total = this.calculatePrice(this.plan);
            if (this.selected) {
                this.selected.forEach((element) => {
                    total += this.calculatePrice(element);
                });
            }
            return total.toFixed(2);
        },

        calculatePrice(item) {
            if (item.TaxRate.Inclusive) {
                const price = item.PriceInCents / 100.0;
                return price;
            }
            const price = item.PriceInCents / 100.0;
            const totalPrice = price * (item.TaxRate.Percentage / 100 + 1);
            return totalPrice;
        },
    },
};
</script>
