<style lang="less" scoped>
.list-item {
    background: white;
    margin-bottom: 10px;
    cursor: pointer;

    .picture {
        width: 96px;
        height: 96px;
        object-fit: cover;
        @media (min-width: 768px) {
            width: 142px;
            height: 142px;
            object-fit: cover !;
        }
    }

    .item-data-wrapper {
        height: 96px;
        @media (min-width: 768px) {
            height: 142px;
        }
    }
    .item-data {
        font-size: 0.8rem;
        line-height: 0.8rem;
        .btn {
            padding: 0.2rem 0.8rem;
            font-size: 0.8rem;
            line-height: 0.8rem;
        }

        .small-icon {
            width: 24px !important;
            min-width: 24px !important;
            height: 24px !important;
            min-height: 24px !important;
        }
        @media (min-width: 768px) {
            font-size: 1rem;
            line-height: 1rem;
            .btn {
                padding: 0.3rem 0.8rem;
                font-size: 1rem;
                line-height: 1rem;
            }

            .small-icon {
                width: 32px !important;
                min-width: 32px !important;
                height: 32px !important;
                min-height: 32px !important;
            }
        }
    }

    .title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    &:hover .picture {
        opacity: 0.7;
    }
}
a {
    color: inherit;
    text-decoration: inherit;
}
</style>

<template>
    <router-link
        class="list-item round-unify w-100 d-flex align-items-start"
        tag="a"
        :to="`/channels/${channel}/${object.object.Slug}`"
    >
        <div class="picture">
            <b-img :src="filterPictures(imageURL)" class="picture" rounded="left"></b-img>
        </div>
        <div class="item-data-wrapper">
            <div class="d-flex w-100 h-100 flex-column justify-content-between item-data pl-3 py-2 py-md-3">
                <div>
                    <h2 class="title">{{ object.object.Title }}</h2>
                </div>
                <div>
                    <div class="d-flex align-items-center">
                        <span class="mr-1 d-none d-xl-flex">{{ $t('labels.by') }}</span>

                        <div class="d-flex align-items-center">
                            <div v-if="owners && owners[0]" class="small-icon">
                                <CoThumbnail
                                    :ImageURL="owners[0].Profile.ImageURL"
                                    :Round="true"
                                    :Clickable="false"
                                    contentType="user"
                                />
                            </div>

                            <div v-if="owners && owners.length > 0" class="ml-1 project">
                                <router-link
                                    @click.prevent.stop.self="viewUser(owners[0].Slug)"
                                    :to="'/profile/' + owners[0].Slug"
                                    >{{ trimString(owners[0].Profile.FirstName) }}</router-link
                                >
                            </div>
                            <div class="d-none d-md-block d-lg-block d-xl-block mt-0">
                                <div v-if="amountContributors > 1" class="project">
                                    <span v-if="amountContributors > 1" class="ml-1"
                                        >{{ $t('labels.and') }} {{ $tc('labels.others', amountContributors - 1) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="">
                    <b-button
                        v-if="FollowedByMe == false"
                        v-on:click="follow"
                        variant="primary"
                        class="d-flex align-items-center"
                    >
                        {{ $t('labels.follow') }}
                    </b-button>
                    <b-button v-else variant="outline-primary" @click="unfollow" class="d-flex align-items-center">
                        {{ $t('labels.following') }}
                    </b-button>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';

import CoThumbnail from '../Molecules/co-thumbnail/CoThumbnail.vue';
export default {
    name: 'ProjectCard',
    components: {
        CoThumbnail,
    },
    props: ['object', 'channel'],
    data() {
        return {
            imageURL: this.object.object.ImageURL[0],
            owners: [],
            contributors: [],
            name: '',
            FollowedByMe: false,
            windowWidth: window.innerWidth,
        };
    },
    watch: {
        object(newVal, oldVal) {
            this.imageURL = this.object.object.ImageURL[0];
        },
    },
    computed: {
        icon() {
            this.iconMap[this.object.type];
        },
        description() {
            this.typeMap[this.object.type];
        },
        isDesktop() {
            return this.windowWidth >= 768;
        },
        amountContributors() {
            let contributorArray = this.object.object.Contributor;
            const ownerArray = this.object.object.Owner;
            // concat without duplicates
            contributorArray = this.arrayUnique(contributorArray.concat(ownerArray));
            return contributorArray.length;
        },
    },
    created() {
        if (this.object.object.FollowedBy != null) {
            this.FollowedByMe = this.object.object.FollowedBy.indexOf(this.$store.state.me.ID) > -1;
        }
    },
    mounted() {
        this.getOwners();
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });
    },
    methods: {
        follow(event) {
            event.preventDefault();
            const subs = JSON.stringify({ ProjectID: this.object.object.ID });
            axios({
                method: 'POST',
                url: '/project/follow',
                data: subs,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.FollowedByMe = true;
                    EventBus.$emit('INFO', {
                        Message: 'Project followed',
                        Details: null,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    EventBus.$emit('ERROR', {
                        Message: 'Project follow failed',
                        Details: null,
                    });
                });
        },

        unfollow(event) {
            event.preventDefault();
            const subs = JSON.stringify({ ProjectID: this.object.object.ID });
            axios({
                method: 'DELETE',
                url: '/project/follow',
                data: subs,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.FollowedByMe = false;
                    EventBus.$emit('INFO', {
                        Message: 'Project unfollowed',
                        Details: null,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    EventBus.$emit('ERROR', {
                        Message: 'Project unfollow failed',
                        Details: null,
                    });
                });
        },

        viewUser(slug) {
            this.$router.push(`/profile/${slug}`);
        },
        arrayUnique(array) {
            const a = array.concat();
            for (let i = 0; i < a.length; ++i) {
                for (let j = i + 1; j < a.length; ++j) {
                    if (a[i] === a[j]) a.splice(j--, 1);
                }
            }

            return a;
        },
        getOwners() {
            const ids = [];
            this.object.object.Owner.forEach((entry) => {
                ids.push({ ID: entry });
            }, ids);
            let ownersIDs = { IDS: ids };
            ownersIDs = JSON.stringify(ownersIDs);
            this.$store.dispatch('listUsersByIDs', { ids: ownersIDs }).then((response) => {
                if (response.Users) {
                    this.owners = response.Users;
                    const that = this;
                    if (this.owners) {
                        this.owners.sort((a, b) => {
                            if (that.$store.state.me.ID == a.ID) return -2;

                            return 0;
                        });
                    }
                }
            });
        },
        filterPictures(imageurl) {
            if (imageurl) {
                return imageurl;
            }

            const randomItem = parseInt(Math.random() * 16) + 1;
            const images = require.context('@/assets/gradients/', true);
            return images(`./Platform_Gradients-${randomItem}.jpg`);
        },
        trimString(string) {
            if (string == null) {
                return '';
            }
            if (string.length > 13) {
                string = `${string.substring(0, 13 - 1)}...`;
            }
            return string;
        },
    },
};
</script>
