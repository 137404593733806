

















































import CoText from '@/components/Atoms/co-text/CoText.vue';

export default {
    name: 'SaaSBase',
    components: {
        CoText,
    },
    created() {
        this.addCoappScripts();
    },
    mounted() {
        // boot the intercom help widget
        this.$intercom.boot({
            app_id: 'kttwojyk',
            hide_default_launcher: true,
        });
    },
    methods: {
        addCoappScripts() {
            // stripe price table
            const dynamicScript3 = document.createElement('script');
            dynamicScript3.setAttribute('src', 'https://js.stripe.com/v3/pricing-table.js');
            dynamicScript3.async = true;
            document.head.appendChild(dynamicScript3);

            // matomo tracking
            const dynamicScript4 = document.createElement('script');
            dynamicScript4.setAttribute('src', '/static/scripts/matomo.js');
            dynamicScript4.async = true;
            document.head.appendChild(dynamicScript4);
        },
    },
};
