+
<template>
    <div class="w-100 p-0 m-0">
        <CoHeader title="Identity providers" class="px-0 px-sm-3">
            <template v-slot:actions>
                <CoButton label="Add" variant="primary" @click="createNewAuthMethod" />
            </template>
        </CoHeader>
        <div class="row text-center m-0 mt-5" v-if="authMethods.length === 0">
            <b-col></b-col>
            <b-col cols="6">
                <b-card>
                    <h2>Get started!</h2>
                    <div class="mb-4">Connect a provider for the authentication</div>
                </b-card>
            </b-col>
            <b-col></b-col>
        </div>
        <div class="mt-3">
            <div class="" v-for="provider in authMethods" :key="provider.ID">
                <SettingsCard
                    :id="provider.ID"
                    :title="provider.Name"
                    :to="`/admin/settings/authentications/providers/${provider.ID}`"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { get } from 'lodash';
import axios from 'axios';

export default {
    name: 'AuthenticationsProviders',
    data() {
        return {
            authMethods: [],
        };
    },
    methods: {
        goToEdit(methodID) {
            this.$router.push({
                name: 'AuthenticationsProviderEdit',
                params: { authMethodID: methodID },
            });
        },
        createNewAuthMethod() {
            this.$router.push({
                name: 'AuthenticationsProviderCreate',
            });
        },
        fetchAuthMethods() {
            axios({
                method: 'GET',
                url: '/admin/auth-methods/',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.authMethods = get(response, 'data', []);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    },
    mounted() {
        this.fetchAuthMethods();
    },
};
</script>

<style scoped></style>
