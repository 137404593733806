





























































































































import axios from 'axios';
import i18n from 'vue-i18n';
import EventBus from '@/eventBus';

import { get, cloneDeep } from 'lodash';
import { Page, User } from '@/components/Molecules/co-card-page/models.ts';
import CoCardPage from '@/components/Molecules/co-card-page/CoCardPage.vue';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoConfirmation from '@/components/Molecules/co-confirmation/CoConfirmation.vue';

export default {
    name: 'PagesForChannel',
    components: {
        CoCardPage,
        CoRoundButton,
        CoButton,
        CoConfirmation,
    },
    i18n: {
        messages: {
            en: {
                ctaheader: 'Start to share what you know.',
                ctasubline: 'Create a page on your community platform.',
                createpagepermissiontitle: 'Who can add pages to this channel',
                everyone: 'Everyone',
                adminsonly: 'Admins only',
                channelDeleteFailed: 'Failed to delete channel',
                channelDeleted: 'Channel deleted',
                deleteChannel: 'Delete Channel',
            },
            de: {
                ctaheader: 'Fang an, dein Wissen weiterzugeben.',
                ctasubline: 'Erstelle eine Seite auf deiner Community-Plattform.',
                createpagepermissiontitle: 'Wer darf Seiten zu diesem Kanal hinzufügen',
                everyone: 'Jeder',
                adminsonly: 'Nur Admins',
                channelDeleteFailed: 'Kanal konnte nicht gelöscht werden',
                channelDeleted: 'Kanal gelöscht',
                deleteChannel: 'Kanal löschen',
            },
        },
    },
    data() {
        return {
            isAdmin: this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin'),
            feed: [],
            feedNextPage: null,
            loading: true,
            expand: false,
            windowWidth: window.innerWidth,
            channelSlug: this.$route.params.slug,
            channelID: this.$route.query.id,
            lastEvaluatedKey: null, // used for pagination
            channelList: null,
            owners: {},
            pages: Array<Page>(),
            listRerenderKey: 0,

            currentChannel: null,

            showChannelSetting: false,
            addPagesPermission: 0,
            addPagesPermissionEveryone: true,
            addPagesPermissionAdmins: false,
            channelSettingsLoading: false,
        };
    },
    watch: {
        $route(to, from) {
            this.feed = [];
            this.channelSlug = to.params.slug;
            this.channelID = to.query.id;
            this.lastEvaluatedKey = null;
            this.feedNextPage = null;

            this.getChannelList();
        },
        feed: {
            handler(newVal, oldVal) {
                this.mapFeedPostToPage();
            },
            deep: true,
        },

        owners: {
            handler(newVal, oldVal) {
                this.mapFeedPostToPage();
            },
            deep: true,
        },
        addPagesPermissionEveryone(val) {
            this.addPagesPermission = val ? 0 : 1;
            this.addPagesPermissionAdmins = !val;
        },
        addPagesPermissionAdmins(val) {
            this.addPagesPermission = val ? 1 : 0;
            this.addPagesPermissionEveryone = !val;
        },
    },
    computed: {
        me() {
            return this.$store.state.me;
        },
        isDesktop() {
            return this.windowWidth >= 768;
        },
    },
    created() {
        window.addEventListener('scroll', this.scroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.scroll);
    },
    mounted() {
        this.scroll();

        this.getChannelList();
    },
    methods: {
        get,
        discard() {
            this.currentChannel = cloneDeep(this.getCurrentChannel());
            this.addPagesPermissionAdmins = this.currentChannel && this.currentChannel.AddPagesPermission === 1;
            this.showChannelSetting = false;
        },
        deleteChannel() {
            this.channelSettingsLoading = true;
            axios({
                method: 'DELETE',
                url: '/admin/community/project-type',
                data: JSON.stringify({ ID: this.currentChannel.ID }),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.showChannelSetting = false;
                    EventBus.$emit('INFO', {
                        Message: this.$t('channelDeleted'),
                        Details: null,
                    });
                    this.$router.push('/');
                    this.$router.go();
                })
                .catch((error) => {
                    console.log(error);
                    EventBus.$emit('ERROR', {
                        Message: this.$t('channelDeleteFailed'),
                        Details: null,
                    });
                })
                .finally(() => {
                    this.channelSettingsLoading = false;
                });
        },

        saveChannelSettings() {
            this.channelSettingsLoading = true;
            let data = this.currentChannel;
            data.AddPagesPermission = this.addPagesPermission ?? 0;

            data = JSON.stringify(data);

            axios({
                method: 'PUT',
                url: '/admin/community/project-type',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.showChannelSetting = false;
                    EventBus.$emit('INFO', {
                        Message: this.$t('labels.changessaved'),
                        Details: null,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    EventBus.$emit('ERROR', {
                        Message: this.$t('labels.failedToSaveChanges'),
                        Details: null,
                    });
                })
                .finally(() => {
                    this.channelSettingsLoading = false;
                });
        },
        create() {
            this.$router.push(`/create/page?channel=${this.channelSlug}`);
        },
        mapSpaceObject(spaceItme) {
            if (!spaceItme) {
                return null;
            }

            if (!spaceItme.ID) {
                return null;
            }

            if (spaceItme.ID === this.$store.state.space.ID) {
                return null;
            }
            const obj = {
                id: spaceItme.ID,
                name: spaceItme.Name,
                favicon: spaceItme.FaviconURL,
                primaryColor: spaceItme.PrimaryColor,
            };
            return obj;
        },

        mapFeedPostToPage() {
            // map feed items to Page type
            const tmp = this.feed.map((feedpost) => {
                const owners = this.owners[feedpost.object.ID];
                const p: Page = {
                    id: feedpost.object.ID,
                    title: feedpost.object.Title,
                    description: feedpost.object.Description,
                    image: feedpost.object.ImageURL && feedpost.object.ImageURL[0] ? feedpost.object.ImageURL[0] : null,
                    slug: feedpost.object.Slug,
                    owners,
                    // Add the remaining properties here
                    channelSlug: this.channelSlug,
                    followed: !!(feedpost.object.FollowedBy && feedpost.object.FollowedBy.indexOf(this.me.ID) > -1),
                    private: !feedpost.object.Published,
                };
                if (feedpost.space) {
                    p.space = this.mapSpaceObject(feedpost.space);
                }

                return p;
            });
            this.pages = tmp;
        },
        reset() {
            this.feedNextPage = null;
            this.feed = [];
        },
        getOwners(feed) {
            // loop through feed
            for (let i = 0; i < feed.length; i++) {
                // get owner id
                const obj = feed[i].object;

                // if obj.ID is already in owners map, skip
                if (this.owners[obj.ID]) {
                    continue;
                }
                const ids = obj.Owner.map((entry) => ({ ID: entry }));
                const ownersIDs = { IDS: ids };
                const data = JSON.stringify(ownersIDs);
                this.$store.dispatch('listUsersByIDs', { ids: data }).then((response) => {
                    if (response.Users) {
                        let owners = response.Users;

                        owners = owners.map((owner) => {
                            // add owner to feed item
                            const tmp: User = {
                                id: owner.ID,
                                slug: owner.Slug,
                                name: `${owner.Profile.FirstName} ${owner.Profile.LastName}`,
                                image: owner.Profile.ImageURL,
                            };

                            if (!owner.Profile.FirstName) {
                                tmp.name = owner.Profile.Name;
                            }
                            return tmp;
                        });

                        this.owners[obj.ID] = owners;
                        this.mapFeedPostToPage();
                    }
                });
            }
        },
        getProjectsByNewest(next) {
            // this.feedNextPage = null;
            let url = `/project/list/${this.channelSlug}`;
            if (this.feedNextPage) {
                url = this.feedNextPage;
            }

            this.selected = 'Newest';
            this.loading = true;
            // get projects for channel
            axios
                .get(url, {})
                .then((response) => {
                    if (response.data.objects) {
                        this.loading = false;

                        response.data.objects.forEach(function (entry) {
                            this.feed.push(entry);
                        }, this);

                        if (response.data.next != '' && response.data.next != null) {
                            this.feedNextPage = response.data.next;
                        } else {
                            this.feedNextPage = null;
                        }

                        if (this.feed.length < 10 && this.feedNextPage != null) {
                            this.getProjectsByNewest(this.feedNextPage);
                        } else {
                            this.loading = false;
                        }
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);

                    // todo show error
                })
                .finally(() => {
                    this.loading = false;
                    this.getOwners(this.feed);
                });
        },

        listPages() {
            this.loading = true;
            // get projects for channel
            const data = {
                ChannelID: this.channelID,
                LastEvaluatedKey: this.lastEvaluatedKey,
            };
            axios({
                method: 'POST',
                url: '/page/list',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data.Objects) {
                        this.loading = false;

                        response.data.Objects.forEach(function (entry) {
                            this.feed.push(entry);
                        }, this);

                        if (
                            response.data.LastEvaluatedKey &&
                            response.data.LastEvaluatedKey !== '' &&
                            response.data.LastEvaluatedKey !== null
                        ) {
                            this.lastEvaluatedKey = response.data.LastEvaluatedKey;
                        } else {
                            this.lastEvaluatedKey = null;
                        }

                        if (this.feed.length < 10 && this.lastEvaluatedKey != null) {
                            this.listPages(this.feedNextPage);
                        } else {
                            this.loading = false;
                        }
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                    // todo show error
                })
                .finally(() => {
                    this.loading = false;
                    this.getOwners(this.feed);
                });
        },

        scroll() {
            window.onscroll = () => {
                const distanceFromBottom = document.body.scrollHeight - window.innerHeight - window.scrollY;

                const percentage = (distanceFromBottom * 100) / (document.body.scrollHeight - window.innerHeight);

                if (percentage < 1) {
                    if (this.channelID && this.lastEvaluatedKey && !this.loading) {
                        this.listPages();
                    }
                }
            };
        },
        getChannelList() {
            /* get the list of channels and save them in the channelList map with slug as keys */
            axios
                .post('/channel/list/circles')
                .then((response) => {
                    if (response.data && response.data.Objects) {
                        // find channel title by slug
                        this.channelList = response.data.Objects;
                        this.currentChannel = cloneDeep(this.getCurrentChannel());

                        if (!this.currentChannel) {
                            this.$router.push('/error404');
                        }

                        this.channelID = this.currentChannel.ID;
                        this.addPagesPermissionAdmins =
                            this.currentChannel && this.currentChannel.AddPagesPermission === 1;
                        this.listPages();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getCurrentChannel() {
            if (!this.channelList) {
                return null;
            }

            if (this.channelID) {
                const channel = this.channelList.filter((channel) => channel.object.ID === this.channelID)[0];
                if (channel) {
                    return channel.object;
                }
            }
            const channle = this.channelList.filter((channel) => channel.object.Slug === this.channelSlug)[0];
            if (!channle) {
                return null;
            }

            if (channle.object.SpaceID !== this.$store.state.space.ID) {
                return null;
            }

            return channle.object;
        },

        follow(page) {
            const subs = JSON.stringify({ ProjectID: page.id });
            axios({
                method: 'POST',
                url: '/project/follow',
                data: subs,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    EventBus.$emit('INFO', {
                        Message: 'Page followed',
                        Details: null,
                    });

                    // update page in pages array
                    const index = this.pages.findIndex((p) => p.id === page.id);
                    this.pages[index].followed = true;
                    // this.listRerenderKey += 1;
                })
                .catch((error) => {
                    console.log(error);
                    EventBus.$emit('ERROR', {
                        Message: 'Page follow failed',
                        Details: null,
                    });
                });
        },
        unfollow(page) {
            const subs = JSON.stringify({ ProjectID: page.id });
            axios({
                method: 'DELETE',
                url: '/project/follow',
                data: subs,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    EventBus.$emit('INFO', {
                        Message: 'Page unfollowed',
                        Details: null,
                    });

                    // update page in pages array
                    const index = this.pages.findIndex((p) => p.id === page.id);
                    this.pages[index].followed = false;
                    // this.listRerenderKey += 1;
                })
                .catch((error) => {
                    console.log(error);
                    EventBus.$emit('ERROR', {
                        Message: 'Page unfollow failed',
                        Details: null,
                    });
                });
        },
    },
};
