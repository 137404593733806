var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CoModal',{ref:"postmodal",attrs:{"size":"md","fullScreen":_vm.isMobile,"closeOnClickAway":false},scopedSlots:_vm._u([{key:"header",fn:function(slotProps){return [_c('div',{staticClass:"d-flex"},[_c('CoIcon',{staticStyle:{"cursor":"pointer"},attrs:{"title":_vm.$t('labels.close'),"name":"x-lg"},nativeOn:{"click":function($event){return _vm.discard($event)}}}),_c('div',{staticClass:"d-flex align-items-center ml-auto"},[_c('CoThumbnail',{attrs:{"ImageURL":_vm.user.ImageURL,"alt":_vm.user.Name,"round":"","contentType":"user","clickable":false}})],1),_c('CoButton',{staticClass:"ml-2",attrs:{"label":_vm.editMode ? _vm.$t('labels.save') : _vm.$t('labels.postbtn'),"variant":"primary","disabled":!_vm.hasContent || _vm.loading,"loading":_vm.loading},on:{"click":function($event){_vm.hasContent && !_vm.loading ? _vm.postSubmit(_vm.editMode) : null}}})],1)]}},{key:"body",fn:function(){return [(_vm.parent && _vm.parentType)?_c('div',{staticClass:"post-parent-wrapper mb-3",class:{
                'is-mobile': _vm.isMobile,
            }},[_c('CoContentPreview',{attrs:{"content":Object.assign({}, _vm.parent,
                    {Type: _vm.parentType}),"variant":"compact"}})],1):_vm._e(),_c('div',{staticClass:"co-editor__post d-flex align-content-stretch",class:{
                'is-mobile': _vm.isMobile,
            }},[_c('CoEditor',{ref:"posteditor",staticClass:"flex-fill",staticStyle:{"min-height":"0"},attrs:{"border":false,"allowStyling":false,"variant":"full","actionsPosition":_vm.isMobile ? 'top-left' : 'bottom-left',"multipleFiles":"","focusOnStart":"","hideSendButton":"","prefilledContent":_vm.prefilledContent,"loading":_vm.loading,"hideLoadingIndicator":""},on:{"onEscape":_vm.discard},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1),((_vm.links && _vm.links.length > 0) || (_vm.featured && _vm.featured.length > 0))?_c('div',{staticClass:"featured-content-wrapper mt-3",class:{
                'is-mobile': _vm.isMobile,
            },staticStyle:{"margin-left":"-1rem","margin-right":"-1rem"}},[_vm._l((_vm.featured),function(link,index){return _c('div',{key:("post-editor-featured-" + index),staticClass:"content-preview-wrapper"},[(index === 0 && !(_vm.content.Files && _vm.content.Files.length > 0))?_c('CoContentPreview',{attrs:{"fetch":{
                        Slug: link.href,
                    }}}):_c('CoLinkPreview',{attrs:{"url":link.href,"preventEmbedding":""}})],1)}),_vm._l((_vm.links),function(link,index){return _c('div',{key:("post-editor-link-" + index)},[_c('CoLinkPreview',{staticClass:"mt-3",attrs:{"preventEmbedding":(_vm.content.Files && _vm.content.Files.length > 0) || _vm.featured.length > 0,"url":link.href}})],1)})],2):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }