<template>
    <div class="minievent">
        <div class="d-flex w-100">
            <div class="mr-3">
                <b-skeleton type="avatar" class="profile-avatar"></b-skeleton>
            </div>

            <div class="w-100">
                <p class="mb-0 small">
                    <b-skeleton animation="wave" width="55%"></b-skeleton>
                </p>
                <b-skeleton animation="wave" width="85%"></b-skeleton>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MiniEventCardSkeleton',
    data() {
        return {};
    },

    mounted() {},
};
</script>
