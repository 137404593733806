













































































































































import axios from 'axios';
import { get, cloneDeep } from 'lodash';
import EventBus from '../../../eventBus';

import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoRoundButton from '../../Atoms/co-round-button/CoRoundButton.vue';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoModal from '../co-modal/CoModal.vue';
import CoPlanList from '../co-plan-list/CoPlanList.vue';
import CoPlanSelectUpgrades from '../co-plan-select-upgrades/CoPlanSelectUpgrades.vue';
import CoPlanEdit from '../co-plan-edit/CoPlanEdit.vue';
import CoFormGroup from '../../Molecules/co-form-group/CoFormGroup.vue';
import CoSelectUser from '../co-selectUser/CoSelectUser.vue';
import CoThumbnail from '../../Molecules/co-thumbnail/CoThumbnail.vue';
import CoText from '../../Atoms/co-text/CoText.vue';
import CoAlert from '../../Molecules/co-alert/CoAlert.vue';

export default {
    name: 'CoPlanChildMemberSelectModal',
    components: {
        CoAlert,
        CoHeadline,
        CoRoundButton,
        CoIcon,
        CoSelectUser,
        CoModal,
        CoPlanList,
        CoPlanSelectUpgrades,
        CoPlanEdit,
        CoButton,
        CoFormGroup,
        CoThumbnail,
        CoText,
    },
    props: {
        plan: {
            type: Object,
            required: false,
            default: () => ({}),
        },

        mode: {
            type: String,
            required: false,
            default: 'member-select',
            validator: (value) => ['member-select', 'list', 'select', 'edit'].includes(value),
        },
    },

    data() {
        return {
            modeState: this.mode,
            currentPlan: null,
            currentPlanForSelectStep: null,
            plans: [],
            taxRates: [],
            upgrades: [],
            childPlan: {
                plan: null,
                user: null,
            },

            adjustingExistingPlan: false,

            loadingUserMemberships: false,
            userHasMembership: false,

            loadingPlans: false,
            loadingTaxRates: false,
            loadingExtras: false,
            resources: [],
        };
    },
    computed: {
        // filteredExtras() {
        //     const exts = cloneDeep(this.plan.Extras);
        //     // remove duplicates from plan.Extras
        //     const filteredExtras = exts.filter(
        //         (extra, index, self) => index === self.findIndex((t) => t.ID === extra.ID)
        //     );
        //     return filteredExtras;
        // },
    },
    watch: {
        plan: {
            handler(val) {
                if (val) {
                    // check val if its empty object
                    if (Object.keys(val).length === 0) {
                        this.modeState = 'member-select';
                    } else {
                        this.currentPlan = cloneDeep(val.plan);
                        this.currentPlanForSelectStep = cloneDeep(val.plan);
                        this.adjustingExistingPlan = true;
                        this.childPlan.plan = val;
                        this.getUser(val.userID);
                        this.modeState = 'edit';
                    }
                }
            },
            deep: true,
        },
        currentPlan: {
            handler(val) {
                this.childPlan.plan = val;
            },
            deep: true,
        },
        childPlan: {
            handler(value) {
                if (value.user) {
                    this.getUserMembership(value.user.ID);
                }
                this.$emit('update', value);
            },
            deep: true,
        },
        loadingTaxRates: {
            handler() {
                if (!this.loadingTaxRates) {
                    this.getPlans();
                    this.getPlanUpgrades();
                    this.getResources();
                }
            },
        },
        mode: {
            handler(val) {
                this.modeState = val;
            },
        },
    },
    created() {
        this.getTaxRates();
    },
    mounted() {
        if (this.plan) {
            // check val if its empty object
            if (Object.keys(this.plan).length === 0) {
                this.modeState = 'member-select';
            } else {
                this.currentPlan = cloneDeep(this.plan.plan);
                this.currentPlanForSelectStep = cloneDeep(this.plan.plan);
                this.adjustingExistingPlan = true;
                this.childPlan.plan = this.plan;
                this.getUser(this.plan.userID);
                this.modeState = 'edit';
            }
        }
        // this.show(); // TODO: remove this
    },
    methods: {
        searchUsers(query, source) {
            // search for users
            return new Promise((resolve, reject) => {
                //exit if query is less than 2 characters
                if (!query || query.length < 2) {
                    resolve([]);
                    return;
                }
                // fetch the search results
                axios({
                    method: 'GET',
                    url: `/admin/member/search/${query}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    cancelToken: source ? source.token : null,
                })
                    .then((response) => {
                        const results = get(response, 'data.objects', []);
                        const mapped = results.map((el) => {
                            return {
                                label: get(el, 'object.Profile.Name', ''),
                                disabled: false,
                                value: el,
                            };
                        });
                        resolve(mapped);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        selectUser(user) {
            this.childPlan.user = get(user, 'value.object', null);
        },
        unselectUser() {
            this.userHasMembership = false;
            this.childPlan.user = null;
        },
        choose() {
            this.childPlan.plan = this.currentPlan;
            // choose emited from after choose button is clicked
            this.$emit('choose', this.childPlan);
        },
        save() {
            this.childPlan.plan = this.currentPlan;
            // save emited from after save button is clicked
            this.$emit('save', this.childPlan);
        },
        show() {
            this.$refs['co-plan-select-config-customise-modal'].show();
        },

        hide() {
            this.$refs['co-plan-select-config-customise-modal'].hide();
        },

        resetModal() {
            this.$emit('close');
            this.unselectUser();
            this.modeState = 'member-select';
            this.childPlan = {
                plan: null,
                user: null,
            };
        },

        selectPlan(plan) {
            this.currentPlan = cloneDeep(plan);
            // remove extras from plan
            this.currentPlan.Extras = [];
            this.currentPlanForSelectStep = cloneDeep(plan);
            this.modeState = 'select';
            this.adjustingExistingPlan = false;
        },

        selectedPlanUpgrades(extras) {
            // flatten extras map to array of extras
            const keys = Object.keys(extras);
            const extrasArray = [];
            keys.forEach((key) => {
                // concat all extras into one array
                extrasArray.push(...extras[key]);
            });
            this.currentPlan.Extras = cloneDeep(extrasArray);
        },
        updatePlan(plan) {
            this.currentPlan = plan;
        },
        getPlanUpgrades() {
            // get all upgrades available for in space
            this.loadingExtras = true;
            axios({
                method: 'GET',
                url: `/admin/extra/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        const upgrades = response.data.Extras;
                        upgrades.forEach((upgrade) => {
                            const taxRate = this.taxRates.find((taxRateFound) => taxRateFound.ID === upgrade.TaxRateID);
                            if (taxRate) {
                                upgrade.TaxRate = taxRate;
                            }
                        });

                        this.upgrades = upgrades;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingExtras = false;
                });
        },
        getTaxRates() {
            // get all tax rates available for in space
            this.loadingTaxRates = true;
            axios({
                method: 'GET',
                url: `/admin/tax-rate/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.taxRates = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingTaxRates = false;
                });
        },
        getPlans() {
            // get only active plans available in space
            this.loadingPlans = true;
            axios({
                method: 'GET',
                url: `/admin/plan/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        let plans = response.data.Plans;

                        // filter, left only active plans
                        plans = plans.filter((plan) => plan.Active);

                        // check if taxrates  have taxrate for each plan.TaxRateID and add it to the plan object
                        plans.forEach((plan) => {
                            const taxRate = this.taxRates.find((taxRateFound) => taxRateFound.ID === plan.TaxRateID);
                            if (taxRate) {
                                plan.TaxRate = taxRate;
                            }
                        });

                        this.plans = plans;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingPlans = false;
                });
        },
        getUserMembership(ID) {
            this.userHasMembership = false;
            this.loadingUserMemberships = true;
            axios({
                method: 'GET',
                url: `/admin/community/member/membership/${ID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        // cast response to UserMembership
                        const membership = response.data;
                        if (membership.currentPlan || membership.upcomingPlan) {
                            this.userHasMembership = true;
                        }
                    }
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: 'Error checking user membership',
                    });
                    console.log(error);
                })
                .finally(() => {
                    this.loadingUserMemberships = false;
                });
        },
        getUser(id) {
            const ids = JSON.stringify({ IDS: [{ ID: id }] });
            this.$store
                .dispatch('listUsersByIDs', { ids })
                .then((response) => {
                    this.childPlan.user = response.Users[0];
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getResources() {
            // get all resources available for in space
            this.loadingResources = true;
            axios({
                method: 'GET',
                url: '/admin/booking/resource/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data && response.data.Resources) {
                        this.resources = response.data.Resources;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingResources = false;
                });
        },
    },
};
