






































































































import { format, fromUnixTime } from 'date-fns';

import CoRoundButton from '../../Atoms/co-round-button/CoRoundButton.vue';
import CoModal from '../co-modal/CoModal.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoHeading from '../../Atoms/co-heading/CoHeading.vue';
import CoText from '../../Atoms/co-text/CoText.vue';

interface UserMembership {
    subscriptionStatus: string;
    stripeCustomerLink: string;
    stripeCustomerID: string;

    currentBillingPeriodStart: number;
    currentBillingPeriodEnd: number;

    billingCycleAnchor: number;

    subscriptionCancelAt: number; // timestamp when subscription will be cancelled
    subscriptionCanceledAt: number; // timestamp when subscription was cancelled
    subscriptionStartDate: number; // timestamp when subscription was created
    subscriptionEndedAt: number; // timestamp when subscription was ended
    subscriptionCreated: number; // timestamp when subscription was created

    collectionMethod: string;
}

export default {
    name: 'CoSubscriptionStatusModal',
    components: {
        CoRoundButton,
        CoModal,
        CoIcon,
        CoText,
        CoHeading,
    },
    props: {
        userMembership: {
            type: Object as () => UserMembership,
            required: true,
        },
    },
    computed: {
        icon() {
            switch (this.userMembership.subscriptionStatus) {
                case 'ACTIVE':
                    return 'check-lg';
                case 'TRIALING':
                    return 'hourglass-top';
                case 'PAST_DUE':
                    return 'exclamation-triangle';
                case 'UNPAID':
                    return 'exclamation-triangle';
                case 'CANCELLED':
                    return 'exclamation-triangle';
                case 'INCOMPLETE':
                    return 'exclamation-triangle';
                case 'INCOMPLETE_EXPIRED':
                    return 'exclamation-triangle';
                case 'UNSPECIFIED':
                    return 'exclamation-triangle';
                default:
                    return 'exclamation-triangle';
            }
        },
        variant() {
            switch (this.userMembership.subscriptionStatus) {
                case 'ACTIVE':
                    return 'success';
                case 'TRIALING':
                    return 'success';
                case 'PAST_DUE':
                    return 'warning';
                case 'UNPAID':
                    return 'danger';
                case 'CANCELLED':
                    return 'danger';
                case 'INCOMPLETE':
                    return 'warning';
                case 'INCOMPLETE_EXPIRED':
                    return 'danger';
                case 'UNSPECIFIED':
                    return 'danger';
                default:
                    return 'danger';
            }
        },
        statusExplanation() {
            switch (this.userMembership.subscriptionStatus) {
                case 'ACTIVE':
                    return '';
                case 'TRIALING':
                    return 'Subscription is in trial period and will be active after trial period ends.';
                case 'PAST_DUE':
                    return 'It means one or more invoices has not been paid in time. Adding a valid payment method can fix that.';
                case 'UNPAID':
                    return 'It means one or more invoices have not been paid and all due dates have passed. In this state no subsequent invoices will be attempted (invoices will be created, but then immediately automatically closed). After receiving updated payment information from a customer, you may choose to reopen and pay their closed invoices.';
                case 'CANCELLED':
                    return 'It was cancelled eather by request or by Stripe. It can be reactivated by assigning a plan to the user.';
                case 'INCOMPLETE':
                    return 'It means that the initial payment attempt failed. Once the first invoice is paid, the subscription moves into an active state.';
                case 'INCOMPLETE_EXPIRED':
                    return 'It means that the initial payment attempt failed and invoice due date has passed. This is a terminal state, the open invoice will be voided and no further invoices will be generated. It can be recreated after correct payment method is created.';
                case 'UNSPECIFIED':
                    return 'Subscription does not exist. It can be created by assigning a plan to the user.';
                default:
                    return 'Subscription does not exist. It can be created by assigning a plan to the user.';
            }
        },
    },
    mounted() {},
    methods: {
        openModal() {
            this.$refs['subscription-status-modal'].show();
        },
        closeModal() {
            this.$refs['subscription-status-modal'].hide();
        },
        format(date: number) {
            return format(fromUnixTime(date), 'yyyy.MM.dd HH:mm');
        },
    },
};
