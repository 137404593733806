<template>
    <a href="#" :title="title" @click.stop="exportAsCSV(data)">
        <b-icon v-if="icon" :icon="icon"> </b-icon>
        {{ title }}
    </a>
</template>

<script>
export default {
    name: 'ExportDataAsCsv',
    data() {
        return {};
    },
    props: {
        title: String,
        data: [Array, Object],
        icon: String,
    },
    methods: {
        exportAsCSV(data) {
            const columns = {};
            const rows = [];
            const csvList = [];

            const flattenObject = (obj, row) => {
                if (!row) row = {};

                for (const i in obj) {
                    if (typeof obj[i] === 'object' && !Array.isArray(obj[i])) {
                        const temp = flattenObject(obj[i]);
                        for (const j in temp) {
                            // Store temp in result
                            row[`${i}.${j}`] = temp[j];
                        }
                    } else if (Array.isArray(obj[i])) {
                        // handle arrays
                        row[i] = obj[i].join(' ');
                    } else {
                        row[i] = obj[i];
                    }
                }
                return row;
            };

            this.data.map((element) => {
                // flatten each item and add to list of rows
                const row = flattenObject(element);
                rows.push(row);

                // collect all occurring keys in keys Object
                Object.keys(row).map((i) => {
                    columns[i] = true;
                });
            });

            // combine the data in one array
            // 1. add the colum names sorted
            csvList.push(Object.keys(columns));
            // 2. map each row to the columns
            rows.map((row) => {
                const line = csvList[0].map((i) => row[i]);
                csvList.push(line);
            });

            // create the csv file
            const csvContent = `data:text/csv;charset=utf-8,${csvList
                .map(
                    (row) => row.join(';').replace(/(\n)+/g, ' ')
                    // remove all new-lines
                    // important to use regex "+" to match multiple occurances in one line
                )
                .join('\n')}`;

            // initiate file download in the clients browser
            const encodedUri = encodeURI(csvContent);
            window.open(encodedUri);
        },
    },
};
</script>

<style></style>
