







import ViewProject from '@/views/projects/ViewProject.vue';
import CoViewPage from './co-view-page.vue';

export default {
    name: 'CoViewPageWrapper',
    components: {
        ViewProject,
        CoViewPage,
    },
};
