<template>
    <!-- content block -->
    <div class="dashboard-container-small col-np-mobile mt-3 px-2">
        <b-card>
            <h2>{{ $t('labels.pagecreate') }}</h2>
            <small>{{ $t('pagecreateinfo') }}</small>
            <div class="mt-3">
                <b-input
                    type="text"
                    class="rounded-pill"
                    v-model="title"
                    :placeholder="$t('pagenameplaceholder')"
                    required
                    @keydown.enter="postProject"
                />
            </div>
            <div class="my-4">
                <label for="project-type" class="mb-2">{{ $t('labels.channel') }}</label>
                <div>
                    <b-select
                        v-model="form['ChannelID']"
                        class="rounded-pill"
                        id="project-type"
                        @change="selectChannel"
                    >
                        <option
                            v-for="(selectOption, indexOpt) in projectTypes"
                            :key="indexOpt"
                            :value="selectOption.ID"
                        >
                            {{ selectOption.Value }}
                        </option>
                    </b-select>
                </div>
            </div>
            <div class="text-right mt-3">
                <b-button
                    variant="outline-primary"
                    class="mr-3"
                    @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                    >{{ $t('labels.cancel') }}
                </b-button>
                <b-button variant="primary" :disabled="!title || !channel" @click="postProject">
                    {{ $t('labels.create') }}
                </b-button>
            </div>
        </b-card>
    </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import Router from 'vue-router';
import VueIntercom from 'vue-intercom';
import i18n from 'vue-i18n';
import EventBus from '../../eventBus';

Vue.use(Router);
Vue.use(VueIntercom, { appId: 'kttwojyk' });

export default {
    i18n: {
        messages: {
            en: {
                pagecreateinfo: 'You can change the name any time.',
                pagenameplaceholder: "Your @:labels.page's name",
            },
            de: {
                pagecreateinfo: 'Du kannst den Namen jederzeit ändern.',
                pagenameplaceholder: 'Name deiner @:labels.page',
            },
        },
    },
    name: 'CreateProject',
    data() {
        return {
            isAdmin: this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin'),
            title: '',
            form: {},
            projectTypes: [],
            channel: this.$route.query.channel,
        };
    },
    computed: {
        me() {
            return this.$store.state.me;
        },
        isVisible() {
            return this.$intercom.visible;
        },
    },
    mounted() {
        this.$nextTick(() => {
            // Code that will run only after the
            // entire view has been rendered
            this.listProjectTypes();
        });

        if (this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin')) {
            this.$intercom.boot({
                user_id: this.$store.state.me.ID,
                name: this.$store.state.me.Profile.Name,
                email: this.$store.state.me.Email,
                spaceID: this.$store.state.space.ID,
                space: this.$store.state.space.Name,
                intro: this.$store.state.me.Intro,
                hide_default_launcher: this.$isMobile || this.$store.state.isInMobile, // hide if Vue.prototype.$isMobile flag is set
            });

            this.$intercom.hide();
        }
    },
    beforeDestroy() {
        this.$intercom.shutdown();
    },
    methods: {
        selectChannel() {
            this.channel = this.projectTypes.find((x) => x.ID === this.form.ChannelID).Slug;
        },
        listProjectTypes() {
            this.projectTypes = [];
            axios({
                method: 'GET',
                url: '/project/project-type/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data && response.data.objects && response.data.objects.length !== 0) {
                        if (this.isAdmin) {
                            this.projectTypes = response.data.objects.map((element) => element.object);
                        } else {
                            this.projectTypes = response.data.objects
                                .filter((element) => element.object.AddPagesPermission !== 1)
                                .map((element) => element.object);
                        }

                        const selectedChannel = this.projectTypes.find((element) => element.Slug === this.channel);
                        if (selectedChannel) {
                            this.form.ChannelID = selectedChannel.ID;
                        } else {
                            this.form.ChannelID = this.projectTypes[0].ID;
                            this.channel = this.projectTypes[0].Slug;
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        hasHistory() {
            return window.history.length > 2;
        },
        emitEventToIntercom() {
            if (this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin')) {
                this.$intercom.trackEvent('created-project');
            }
        },
        // getRandomInt return random num in range from 1 to max inclusive max
        getRandomInt(max) {
            return Math.floor(Math.random() * Math.floor(max)) + 1;
        },
        // compile dynamic asset reference
        getGradients(pic) {
            const images = require.context('../../assets/gradients/', true);
            return images(`./${pic}`);
        },
        publishEventToBus(payLoad) {
            const message = {
                Message: payLoad,
                Details: null,
            };
            EventBus.$emit('INFO', message);
        },
        /*
      postProject() makes post request with 'form' var from data to project create endpoint of backend
      */
        postProject() {
            if (this.title === '') {
                return;
            }
            const imageURL = this.getGradients(`Platform_Gradients-${this.getRandomInt(16)}.jpg`);
            this.$data.form.UserID = this.$store.state.me.ID;
            this.$data.form.Contributor = [this.$store.state.me.ID];
            this.$data.form.Owner = [this.$store.state.me.ID];
            this.$data.form.Title = this.title;

            if (this.$unleash.isEnabled('frontend.newpageview')) {
                this.$data.form.ImageURL = [];
            } else {
                this.$data.form.ImageURL = [imageURL];
            }
            axios({
                method: 'POST',
                url: '/project',
                data: this.$data.form,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.emitEventToIntercom();
                    this.publishEventToBus('You successfully created new page');
                    setTimeout(() => {
                        this.$router.push(`/channels/${this.channel}/${response.data.Slug}`);
                    }, 300);
                })
                .catch((error) => {
                    const message = {
                        Message: `Could not create page. Error: ${error.response.data}`,
                        Details: error.response.data,
                    };
                    EventBus.$emit('ERROR', message);
                });
        },
    },
};
</script>
