













































































































import { PropType } from 'vue';
import { get } from 'lodash';
import { Page } from './models.ts';

import CoCard from '../co-card/CoCard.vue';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoThumbnail from '../co-thumbnail/CoThumbnail.vue';
import CoText from '../../Atoms/co-text/CoText.vue';
import CoLink from '../../Atoms/co-link/CoLink.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoSkeleton from '../../Atoms/co-skeleton/CoSkeleton.vue';

export default {
    name: 'CoCardPage',
    components: {
        CoCard,
        CoHeadline,
        CoThumbnail,
        CoButton,
        CoLink,
        CoText,
        CoIcon,
        CoSkeleton,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        page: {
            type: Object as PropType<Page>,
            required: true,
            default: () => ({} as Page),
        },
    },

    data() {
        return {
            followedByUser: false,
        };
    },
    computed: {
        pageURL() {
            if (this.page.channelSlug) {
                return `/channels/${this.page.channelSlug}/${this.page.slug}`;
            }
            return `/project/${this.page.slug}`;
        },
        isOwner() {
            if (!this.page || !this.page.owners) {
                return false;
            }
            return this.page.owners.some((owner) => owner.id === this.$store.state.me.ID);
        },
    },
    watch: {
        page: {
            handler() {
                this.followedByUser = this.page.followed;
            },
            deep: true,
        },
    },
    created() {
        this.followedByUser = this.page.followed;
    },
    methods: {
        randomPercents(from = 0, to = 100) {
            const percents = Math.floor(Math.random() * (to - from + 1) + from);
            return `${percents}%`;
        },
        // getFirst owner using lodash
        get(args: any, args2: string, args3: any) {
            return get(args, args2, args3);
        },
        view() {
            this.$router.push(this.pageURL);
        },
        follow() {
            // emit event to parent
            this.$emit('follow', this.page);
        },
        unfollow() {
            // emit event to parent
            this.$emit('unfollow', this.page);
        },
    },
};
