






import Permissions from '../../../../Templates/Admin/Settings/Permissions/Permissions.vue';

export default {
    name: 'SpacePermissions',
    components: {
        Permissions,
    },
};
