var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
        height: !_vm.hasButton ? '0' : 'auto',
        overflow: !_vm.hasButton ? 'hidden' : 'initial',
    }),on:{"click":_vm.propogationControl}},[_c('CoTippy',{ref:"tippy",staticClass:"co-dropdown-button",attrs:{"tippyOptions":Object.assign({}, {trigger: _vm.disabled ? 'manual' : 'click',
            interactive: true,
            delay: 0,
            duration: [0, 0],
            placement: _vm.placement,
            arrow: false,
            popperOptions: {
                modifiers: [
                    {
                        name: 'flip', // disable automatic flipping of menu 'https://popper.js.org/docs/v2/modifiers/flip/#fallbackplacements'
                        options: {
                            fallbackPlacements: [],
                        },
                    } ],
            },
            zIndex: 9000}, // make sure dropdowns are below tooltips (default is 9999)
            _vm.tippyOptions),"hideAllOnShow":true}},[(_vm.hasButton)?_vm._t("button",[(_vm.hasButton)?_c('CoButton',{attrs:{"disabled":_vm.disabled,"variant":_vm.variant,"label":_vm.text,"iconright":_vm.iconright,"stopEventPropagation":false}}):_vm._e()]):_vm._e(),_c('template',{slot:"content"},[_c('ul',{ref:"dropdown-menu",staticClass:"co-dropdown-menu",class:_vm.menuClasses,on:{"click":_vm.hideDropdown}},[(_vm.loading)?_c('CoDropdownItem',{attrs:{"title":" ","disabled":""}},[_c('CoLoadingIndicator')],1):_vm._t("default")],2)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }