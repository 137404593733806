<template>
    <div>
        <PublicViewEvent :me="$store.state.me" :slug="$route.params.slug" />
    </div>
</template>

<script>
import PublicViewEvent from '@/components/Templates/User/Event/View/PublicEventView.vue';

export default {
    name: 'CoViewPublicEvent',
    components: {
        PublicViewEvent,
    },
};
</script>
