+
<template>
    <div>
        <div class="container-fluid p-0 m-0">
            <!--      TODO: content here -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'AuthenticationsLogin',
    data() {
        return {};
    },
};
</script>

<style scoped></style>
