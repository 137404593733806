<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <div>
        <SettingsCard
            id="SettingsCardSignUpStrategy"
            title="Sign Up Strategy"
            subtitle="Choose how users can sign up to your community"
            to="/admin/setup/signup-strategy"
            :isNew="true"
        ></SettingsCard>
        <SettingsCard
            id="SettingsCardAuthenticationIdentityProviders"
            title="Identity Providers"
            subtitle="Connect your existing community"
            to="/admin/settings/authentications/providers"
        />
    </div>
</template>

<script>
import SettingsCard from '@/components/admin/SettingsCard.vue';

export default {
    name: 'AuthenticationsSettings',
    components: { SettingsCard },
    data() {
        return {};
    },
};
</script>
