
































import Vue from 'vue';
import { BAspect } from 'bootstrap-vue';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoText from '../../Atoms/co-text/CoText.vue';
import CoCard from '../../Molecules/co-card/CoCard.vue';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoRoundButton from '../../Atoms/co-round-button/CoRoundButton.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoModal from '../co-modal/CoModal.vue';

import CoPrice from '../../Atoms/co-price/coPrice.vue';
import CoPlanSelectUpgrades from '../co-plan-select-upgrades/CoPlanSelectUpgrades.vue';

Vue.component('b-aspect', BAspect);

interface Price {
    PriceInCents: number;
    Currency: string;
    TaxRate: number;
    TaxName: string;
    Inclusive: boolean;
    Unit?: string;
}

export default {
    name: 'CoPlanModal',
    components: {
        CoText,
        CoCard,
        CoHeadline,
        CoRoundButton,
        CoPrice,
        CoIcon,
        CoModal,
        CoButton,
        CoPlanSelectUpgrades,
    },
    props: {
        plan: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            windowWidth: window.innerWidth,
        };
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;

            if (this.windowWidth <= 992) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        });
    },
    computed: {
        isMobile: function () {
            return this.windowWidth <= 992;
        },
    },
    methods: {
        show(e) {
            this.$refs['plan-preview-modal'].show();
            // Prevents the click event from bubbling up to the parent element
            if (e) {
                e.stopPropagation();
            }
        },
        hide(e) {
            this.$refs['plan-preview-modal'].hide();
            if (e) {
                e.stopPropagation();
            }
        },
    },
};
