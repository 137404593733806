




import EventBus from '@/eventBus';
import MemberPlanView from '../../../../Templates/Admin/Membership/MemberPlanView/MemberPlanView.vue';

export default {
    name: 'CoAdminMembersPlan',
    components: {
        MemberPlanView,
    },
    data() {
        return {
            userId: this.$route.params.ID,
            name: '',
        };
    },
    mounted() {
        this.$store
            .dispatch('adminGetUserByID', this.$route.params.ID)
            .then((response) => {
                this.user = response;
                if (!this.user.Profile) {
                    this.name = this.user.Email;
                    return;
                }
                this.name = `${this.user.Profile.FirstName} ${this.user.Profile.LastName}`;
                if (this.name === ' ') {
                    this.name = this.user.Profile.Name;
                }

                if (this.name === ' ') {
                    this.name = this.user.Email;
                }
                this.setBreadcrumbs();
            })
            .catch((e) => {
                this.name = 'User not found';
                const message = {
                    Message: 'Can`t get user data',
                    Details: '',
                };
                EventBus.$emit('ERROR', message);
            });
    },
    methods: {
        setBreadcrumbs() {
            this.$store.commit('SET_BREADCRUMBS', [
                {
                    text: 'Manage',
                    to: '/admin',
                },
                {
                    text: 'Members',
                    to: '/admin/community/member/list',
                },
                {
                    text: this.name,
                    to: `/admin/community/member/view/${this.$route.params.ID}`,
                },
                {
                    text: 'Plan',
                },
            ]);
        },
    },
};
