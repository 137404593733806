







import CoBookingStatistics from '../../../components/Organisms/co-booking-statistics/CoBookingStatistics.vue';

export default {
    components: {
        CoBookingStatistics,
    },
    data() {
        return {};
    },
};
