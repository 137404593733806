
















import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';

export default {
    name: 'CoHeader',
    components: {
        CoHeadline,
    },
    props: {
        title: {
            type: String,
            default: 'Title',
            required: false,
        },
    },
};
