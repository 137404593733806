var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('coCard',{attrs:{"image":_vm.plan.ImageURL}},[_c('div',{staticClass:"d-flex"},[(_vm.hasHeaderTitleSlot)?_c('coHeadline',{staticClass:"flex-grow-1",attrs:{"level":2}},[_vm._v(_vm._s(_vm.plan.Name))]):_vm._e(),(_vm.hasHeaderButtonSlot)?_c('div',{staticClass:"flex-shrink-0"},[_vm._t("header-button")],2):_vm._e()],1),(_vm.plan)?_c('coHeadline',{attrs:{"level":3}},[_c('CoPrice',{attrs:{"priceObj":{
                    PriceInCents: _vm.plan.PriceInCents,
                    Currency: _vm.plan.Currency, // To do: using values from plan object because params do not exist in resources yet
                    TaxName: _vm.plan.TaxRate && _vm.plan.TaxRate.DisplayName ? _vm.plan.TaxRate.DisplayName : '',
                    TaxRate: _vm.plan.TaxRate && _vm.plan.TaxRate.Percentage ? _vm.plan.TaxRate.Percentage : 0,
                    Inclusive: _vm.plan.TaxRate && _vm.plan.TaxRate.Inclusive ? _vm.plan.TaxRate.Inclusive : false,
                    Unit: _vm.plan.TimeInteval === 1 ? 'Year' : 'Month',
                }}})],1):_vm._e(),(_vm.plan.Description)?_c('coTextReadmore',{staticStyle:{"padding":"0 0.5em","white-space":"pre-wrap"},attrs:{"text":_vm.plan.Description,"limit":200}}):_vm._e(),_c('coInput',{attrs:{"label":_vm.$t('labels.cancellationnotice'),"value":(_vm.plan.CancelationNotice ? _vm.plan.CancelationNotice : 0) +
                ' ' +
                _vm.$tc('labels.day', _vm.plan.CancelationNotice),"readonly":true}}),(_vm.upgrades && _vm.upgrades.length > 0)?_c('coHeadline',{attrs:{"level":3}},[_vm._v(_vm._s(_vm.$t('labels.upgrades')))]):_vm._e(),_vm._l((_vm.upgrades),function(upgrade){return _c('coCard',{key:upgrade.ID,attrs:{"variant":"gray"}},[_c('coHeadline',{attrs:{"level":2}},[_vm._v(_vm._s(upgrade.Name))]),_c('coText',[_c('CoPrice',{attrs:{"priceObj":{
                        PriceInCents: upgrade.PriceInCents,
                        Currency: _vm.plan.Currency, // To do: using values from plan object because params do not exist in resources yet
                        TaxName: upgrade.TaxRate ? upgrade.TaxRate.DisplayName : '',
                        TaxRate: upgrade.TaxRate ? upgrade.TaxRate.Percentage : 0,
                        Unit: _vm.plan.TimeInteval === 1 ? 'Year' : 'Month', // use plan interval for upgrades because it is not available in upgrade object
                    }}})],1)],1)}),(
                (_vm.plan.AvailableResources && _vm.plan.AvailableResources.length > 0) ||
                (_vm.plan.AvailableResourceGroups && _vm.plan.AvailableResourceGroups.length > 0)
            )?_c('coHeadline',{attrs:{"level":3}},[_vm._v(_vm._s(_vm.$t('labels.bookingconditions'))+" ")]):_vm._e(),_vm._l((_vm.plan.AvailableResources),function(item){return (_vm.plan.AvailableResources)?_c('coCard',{key:item.ID,attrs:{"variant":"gray"}},[_c('coHeadline',{attrs:{"level":2}},[_vm._v(_vm._s(item.Name))]),_c('div',{staticClass:"d-flex"},[(item.OverridePrice)?_c('coText',{staticClass:"flex-grow-1"},[_c('CoPrice',{attrs:{"priceObj":{
                            PriceInCents: item.PriceInCents,
                            Currency: _vm.plan.Currency, // To do: using values from plan object because params do not exist in resources yet
                            TaxName: _vm.plan.TaxRate && _vm.plan.TaxRate.DisplayName ? _vm.plan.TaxRate.DisplayName : '',
                            TaxRate: _vm.plan.TaxRate && _vm.plan.TaxRate.Percentage ? _vm.plan.TaxRate.Percentage : 0,
                            Inclusive: _vm.plan.TaxRate && _vm.plan.TaxRate.Inclusive ? _vm.plan.TaxRate.Inclusive : false,
                        },"unit":_vm.$t('labels.hourshort')}})],1):_vm._e(),(item.CreditCents > 0)?_c('coText',{staticClass:"flex-shrink-0"},[_vm._v(_vm._s(_vm.$t('labels.credits'))+" "+_vm._s(_vm.$n(_vm.centsToWholeUnit(item.CreditCents), 'currency'))+" "+_vm._s(_vm.plan.Currency)+" ")]):(item.CreditHours)?_c('coText',{staticClass:"flex-shrink-0"},[_vm._v(_vm._s(_vm.$t('labels.credits'))+" "+_vm._s(item.CreditHours)+"h ")]):_vm._e()],1)],1):_vm._e()}),_vm._l((_vm.plan.AvailableResourceGroups),function(item){return (_vm.plan.AvailableResourceGroups)?_c('coCard',{key:item.ID,attrs:{"variant":"gray"}},[_c('coHeadline',{attrs:{"level":2}},[_vm._v(_vm._s(item.Name))]),_c('div',{staticClass:"d-flex"},[(item.OverridePrice)?_c('coText',{staticClass:"flex-grow-1"},[_c('CoPrice',{attrs:{"priceObj":{
                            PriceInCents: item.PriceInCents,
                            Currency: _vm.plan.Currency, // To do: using values from plan object because params do not exist in resources yet
                            TaxName: _vm.plan.TaxRate && _vm.plan.TaxRate.DisplayName ? _vm.plan.TaxRate.DisplayName : '',
                            TaxRate: _vm.plan.TaxRate && _vm.plan.TaxRate.Percentage ? _vm.plan.TaxRate.Percentage : 0,
                            Inclusive: _vm.plan.TaxRate && _vm.plan.TaxRate.Inclusive ? _vm.plan.TaxRate.Inclusive : false,
                        },"unit":_vm.$t('labels.hourshort')}})],1):_vm._e(),(item.CreditCents > 0)?_c('coText',{staticClass:"flex-shrink-0"},[_vm._v(_vm._s(_vm.$t('labels.credits'))+" "+_vm._s(_vm.$n(_vm.centsToWholeUnit(item.CreditCents), 'currency'))+" "+_vm._s(_vm.plan.Currency)+" ")]):(item.CreditHours)?_c('coText',{staticClass:"flex-shrink-0"},[_vm._v("Credit "+_vm._s(item.CreditHours)+"h")]):_vm._e()],1),_c('coText',_vm._l((item.ListResources),function(li){return _c('span',{key:li.ID},[_vm._v(" "+_vm._s(li.Name)),_c('br')])}),0)],1):_vm._e()})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }