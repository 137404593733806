<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>
<template>
    <div>
        <!-- search and actions start -->
        <div class="px-sm-4 d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center bg-white search-bar-wrapper rounded-pill">
                <b-icon class="mr-2" icon="search"></b-icon>
                <div class="search-input-wrapper">
                    <input
                        class="search-input"
                        type="search"
                        v-model="search"
                        placeholder="Search"
                        aria-label="Search"
                    />
                </div>
            </div>

            <b-button id="import_btn" variant="primary" :disabled="!1" @click="importLocks">Import </b-button>
            <b-tooltip
                label="Import tags (tokens/creddentials) from Deister access
      system"
                target="import_btn"
                position="is-bottom"
                >Imports tags (tokens/credentials) from Deister access system
            </b-tooltip>
        </div>
        <!-- search and actions end -->

        <!-- tags start -->
        <div class="container-fluid p-0 m-0">
            <!-- if emtpy and not loading -->
            <div class="row text-center m-0 mt-5" v-if="objects.length === 0 && !loading">
                <div class="col">
                    <!-- <illustration type="projects" class="my-0 illu"></illustration> -->

                    <h2>Get started!</h2>
                    <div class="mb-4">Import locks</div>
                </div>
            </div>
            <!-- if loading -->
            <div class="row text-center m-0 mt-5" v-else-if="loading">
                <div class="col">
                    <b-spinner variant="primary" label="loading"></b-spinner>
                </div>
            </div>
            <div class="m-0 mt-4 px-sm-4">
                <div class="mb-3" v-for="(item, i) in filteredItems" :key="item.ID">
                    <div class="bg-white round-unify w-100 d-flex align-items-start overflow-hidden p-3">
                        <div class="w-100 d-flex justify-content-between">
                            <div class="">
                                <div class="mb-2">
                                    <small>Name</small> <br />
                                    <h2 class="title">
                                        {{ item.Name }}
                                    </h2>
                                </div>
                                <div class="d-flex">
                                    <div class="mr-3">
                                        <small>Provider</small> <br />
                                        <p class="mb-0">{{ item.Provider }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- tags end -->
    </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import EventBus from '@/eventBus';

export default {
    name: 'Locks',
    components: {},
    data() {
        return {
            tabIndex: 0,
            loading: false,
            objects: [],
            search: '',
        };
    },
    mounted() {
        this.listLocks();
    },
    computed: {
        filteredItems() {
            return this.objects.filter((item) => {
                if (item.Name.toLowerCase().indexOf(this.search.toLowerCase()) > -1) {
                    return true;
                }
                return item.Provider.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
            });
        },
    },
    methods: {
        listLocks() {
            this.loading = true;
            axios
                .get('/admin/space/access/lock/list')
                .then((response) => {
                    if (response && response.data) {
                        this.objects = response.data;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        importLocks() {
            this.loading = true;
            axios
                .get('/admin/space/access/lock/import')
                .then((response) => {
                    if (response && response.data) {
                        this.objects = response.data;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        back() {
            if (this.tabIndex > 0) {
                this.tabIndex -= 1;
            }
        },
        linkClass(idx) {
            if (this.tabIndex === idx) {
                return ['activeOne'];
            }
            return ['not-activeOne'];
        },
    },
};
</script>
