




































































































































import axios from 'axios';
import Vue from 'vue';
import EventBus from '@/eventBus';
import CoCardPlan from '@/components/Molecules/co-card-plan/CoCardPlan.vue';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoDropdown from '@/components/Molecules/co-dropdown/CoDropdown.vue';
import CoDropdownItem from '@/components/Molecules/co-dropdown-item/CoDropdownItem.vue';

export default {
    name: 'PlansList',
    components: {
        CoCardPlan,
        CoRoundButton,
        CoDropdown,
        CoDropdownItem,
    },
    data() {
        return {
            loading: false,
            feed: [],
            search: '',
            filter: 'Active',
        };
    },
    mounted() {
        this.getPlans();
    },
    computed: {
        me() {
            return this.$store.state.me;
        },
        filteredItems() {
            return this.feed.filter((item) => {
                if (item.Name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 == true) {
                    if (!this.filter || typeof this.filter !== 'string') {
                        return true;
                    }
                    if (this.filter.length > 1 && this.filter.substring(0, 1) === '!') {
                        return !item[this.filter.substring(1)];
                    }
                    return item[this.filter];
                }
                // if (item.PriceInCents.indexOf(this.search) > -1 == true) {
                //     return true;
                // }
                return false;
            });
        },
    },
    methods: {
        copyValueToClipboard(id) {
            const link = `https://${this.$store.state.space.DomainName}/plans/${id}`;
            navigator.clipboard.writeText(link);
            EventBus.$emit('INFO', {
                Message: 'Copied to clipboard',
                Details: '',
            });
        },
        viewPlan(id) {
            this.$router.push(`/admin/monetization/plan/${id}`);
        },
        editPlan(id) {
            this.$router.push(`/admin/monetization/plan/edit/${id}`);
        },
        duplicate(item) {
            const duplicatePlan = JSON.parse(JSON.stringify(item));

            duplicatePlan.ID = null;
            duplicatePlan.Name = `${duplicatePlan.Name} (copy)`;
            duplicatePlan.ImageURL = null;
            duplicatePlan.Hidden = true;

            axios({
                method: 'POST',
                url: '/admin/plan/create',
                data: duplicatePlan,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    EventBus.$emit('INFO', {
                        Message: 'Plan duplicate created',
                        Details: '',
                    });
                    this.loading = false;
                    this.$router.push(`/admin/monetization/plan/${response.data.ID}`);
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: 'Failed to create plan duplicate',
                        Details: error,
                    });
                    this.loading = false;
                });

            this.loading = false;
        },
        // compile dynamic asset reference
        getPlans() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/plan/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        response.data.Plans.forEach((element) => {
                            this.feed.push(element);
                        });
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
    },
};
