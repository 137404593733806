/*
vue-i18n (v8.x) guide
https://kazupon.github.io/vue-i18n/guide/formatting.html
*/
import { formattedDate } from './dateTimeFormatter.ts';

const enUS = {
    labels: {
        about: 'About',
        aboutme: 'About me',
        acceptprivacy: 'I have read and accept the {0}',
        acceptterms: 'I have read and accept the {0}',
        accepttermsandprivacy: 'I have read and accept the {privacypolicy} and the {termsandconditions}',
        account: 'Account',
        accountsettings: 'Account settings',
        activity: 'Activity',
        activemembers: 'Active members',
        add: 'Add',
        address: 'Address',
        addressadditionalinformation: 'Additional information',
        addsection: 'Add Section',
        all: 'All',
        textSection: 'Text Section',
        imageSection: 'Image Section',
        amount: 'Amount',
        and: 'and',
        announcement: 'Announcement',
        announcementmake: 'Make @.lower:labels.announcement',
        announcementsentby: '@:labels.announcement sent by {name}',
        attend: 'Attend',
        attendees: 'Attendees',
        author: 'Author',
        availability: 'Availability',
        awaynotice: 'Away notice',
        back: 'Back',
        birthdate: 'Date of birth',
        billing: 'Billing',
        billingaddress: 'Billing address',
        billingaddressedit: '@:labels.edit @.lower:labels.billingaddress',
        book: 'Book',
        booking: 'Booking',
        bookingcancel: '@:labels.cancel @.lower:labels.booking',
        bookingconditions: '@:labels.booking conditions',
        bookingconfirm: 'Book now',
        bookingconfirmpaid: 'Complete paid booking',
        bookings: 'Bookings',
        bookingsmy: 'My Bookings',
        by: 'By',
        byme: 'by me',
        cancel: 'Cancel',
        cancellationnotice: 'Cancellation notice',
        cancellationpolicy: 'Cancellation policy',
        changes: 'Changes',
        changessaved: '@:labels.changes @.lower:labels.saved',
        channel: 'Channel',
        channelname: '@:labels.channel @.lower:labels.name',
        channelnew: '@:labels.new @.lower:labels.channel',
        channeladd: '@:labels.add @.lower:labels.channel',
        channels: 'Channels',
        chat: 'Chat',
        chatnew: '@:labels.new @.lower:labels.chat',
        category: 'Category',
        city: 'City',
        clear: 'Clear',
        clearall: 'Clear all',
        close: 'Close',
        closed: 'Closed',
        comment: 'Comment',
        commentdelete: '@:labels.delete @.lower:labels.comment',
        comments: 'Comments',
        community: 'Community',
        communityrecentlyseen: 'Recently seen online',
        company: 'Company',
        complete: 'Complete',
        confirm: 'Confirm',
        configuration: 'Configuration',
        contact: 'Contact',
        contributor: 'Contributor',
        contributers: 'Contributors',
        contributersadd: 'Add contributors',
        copy: 'Copy',
        couponcode: 'Coupon code',
        country: 'Country',
        create: 'Create',
        creator: 'Creator',
        created: 'Created',
        createdat: '@:labels.created at',
        createdon: '@:labels.created on',
        credit: 'Credit',
        credits: 'Credits',
        day: 'Day | Days',
        date: 'Date',
        dateempty: 'No date selected',
        description: 'Description',
        delete: 'Delete',
        details: 'Details',
        discard: 'Discard',
        draft: 'Draft',
        dragdropimage: 'Drag an image here or click to select.',
        edit: 'Edit',
        email: 'Email',
        enabled: 'enabled',
        enddate: 'End date',
        endtime: 'End time',
        ended: 'Ended',
        event: 'Event',
        eventcreate: '@:labels.create @.lower:labels.event',
        eventcreated: '@:labels.event @.lower:labels.created',
        eventdelete: '@:labels.delete @.lower:labels.event',
        eventedit: '@:labels.edit @.lower:labels.event',
        eventtime: 'Time',
        events: 'Events',
        eventsupcoming: 'Upcoming events',
        eventsnext: 'Next events',
        eventOngoing: 'Ongoing',
        eventyour: 'your @.lower:labels.event',
        failedGeneric: 'An error occurred. Please try again.',
        failedToSaveChanges: 'Failed to save changes',
        feed: 'Feed',
        finish: 'Finish',
        follow: 'Follow',
        followerrole: 'Follower',
        follower: 'no followers | one follower | {n} followers',
        followers: 'Followers',
        following: 'Following',
        free: 'free',
        from: 'From',
        general: 'General',
        getstarted: 'Get started',
        going: 'Going',
        goingcount: 'is going | are going',
        group: 'Group',
        groupmember: 'Group member | Group members',
        groupnew: '@:labels.new @.lower:labels.group',
        hashtag: 'Hashtag',
        hashtagadd: '@:labels.add @.lower:labels.hashtag',
        headline: 'Headline',
        help: 'Help',
        hour: 'hour | hours',
        hourshort: 'h',
        imprint: 'Imprint',
        image: 'Image',
        imageadd: '@:labels.add @.lower:labels.image',
        imageguidelines: 'Image Guidelines',
        including: 'incl.',
        information: 'Information',
        interested: "I'm interested",
        interestedpeople: 'Interested',
        interestedsent: 'Request sent',
        invitemember: 'Invite member',
        invoice: 'Invoice',
        invoices: 'Invoices',
        invoicepreview: 'Invoice preview',
        itemscount: 'no items | 1 item | {n} items',
        join: 'Join',
        letstalkabout: "Let's talk about",
        link: 'Link',
        linkadd: '@:labels.add @.lower:labels.link',
        linkcopy: '@:labels.copy @.lower:labels.link',
        linkInvalid: 'Invalid invitation link',
        linkInvalidDescription:
            'The link has expired, has been used before or has been deleted. Please try again. If the issue keeps happening, contact a Community Manager.',
        links: 'Links',
        loading: 'loading',
        location: 'Location',
        login: 'Log in',
        logout: 'Log out',
        markallasread: 'Mark all as read',
        market: 'Market',
        marketitem: 'Market item',
        marketitems: 'Market items',
        marketitemyour: 'your @.lower:labels.marketitem',
        marketitemclose: '@:labels.close @.lower:labels.marketitem',
        marketitemcreate: '@:labels.create @.lower:labels.marketitem',
        marketitemdelete: '@:labels.delete @.lower:labels.marketitem',
        marketitemedit: '@:labels.edit @.lower:labels.marketitem',
        month: 'Month',
        morecount: '{count} more',
        mentionsmo: 'Mention someone',
        member: 'Member | Members',
        members: 'Members',
        membership: 'Membership',
        membershipyour: 'Your @.lower:labels.membership',
        message: 'Message',
        messagenew: '@:labels.new @:labels.message',
        messenger: 'Messenger',
        my: 'My',
        mybookings: '@:labels.my @.lower:labels.bookings',
        myevents: '@:labels.my @.lower:labels.events',
        mypages: '@:labels.my @.lower:labels.pages',
        mywallet: '@:labels.my @.lower:labels.wallet',
        name: 'Name',
        namefirst: 'First name',
        namelast: 'Last name',
        next: 'Next',
        new: 'New',
        no: 'No',
        notificationpreferences: 'Notification preferences',
        notifications: 'Notifications',
        notificationspause: 'Pause notifications',
        notificationspaused: 'Notifications paused',
        notifymewhen: 'Notify me when',
        notifymewhenasadmin: 'Me as an admin',
        of: 'of',
        ok: 'OK',
        or: 'or',
        orderconfirm: 'Confirm order',
        ordercompletesubcription: 'Complete paid subscription',
        ordercompletesubcriptionfree: 'Complete free subscription',
        orderreview: 'Review',
        ordercheckout: 'Checkout',
        organizer: 'Organizer',
        organizers: 'Organizers',
        organizersadd: '@:labels.add @:labels.organizers',
        others: 'no one | one other | {n} others',
        owner: 'Owner',
        owners: 'Owners',
        ownersadd: 'Add owners',
        pagecreate: '@:labels.create @.lower:labels.page',
        pagedelete: '@:labels.delete @.lower:labels.page',
        pageedit: '@:labels.edit @.lower:labels.page',
        pagepublish: '@:labels.publish @.lower:labels.page',
        pagepublished: '@:labels.page @.lower:labels.published ',
        page: 'Page',
        pages: 'Pages',
        pageyour: 'your @.lower:labels.page',
        pagesmy: '@:labels.mypages',
        paid: 'paid',
        password: 'Password',
        passwordreset: 'Reset password',
        passwordsave: 'Save password',
        passwordsetnew: 'Set a new password',
        pause: 'Pause',
        payment: 'Payment',
        paymentmethod: 'Payment method',
        paymentmethodadd: '@:labels.add @.lower:labels.paymentmethod',
        paymentmethods: 'Payment methods',
        phoneNumber: 'Phone number',
        pay: 'Pay',
        plan: 'Plan',
        plancancel: 'Cancel plan',
        planchange: 'Change plan',
        planchangecancel: 'Cancel plan change',
        planchoose: 'Choose plan',
        plancurrent: 'Your @.lower:labels.plan',
        planreactivate: 'Undo cancellation',
        planselection: 'Choose your plan',
        planupcoming: 'Upcoming plan',
        planupgrades: 'Plan upgrades',
        planupgradechose: 'Select your plan upgrades',
        post: 'Post',
        postpublished: '@:labels.post @.lower:labels.published',
        postyour: 'your @.lower:labels.post',
        postalcode: 'ZIP code',
        postbtn: '@:labels.post',
        postedit: '@:labels.edit @.lower:labels.post',
        preview: 'Preview',
        previous: 'Previous',
        profile: 'Profile',
        profileedit: '@:labels.edit @:labels.profile',
        profilepublish: '@:labels.publish @.lower:labels.profile ',
        profilepublished: '@.lower:labels.profile @:labels.published',
        profileupdated: '@:labels.profile @.lower:labels.updated',
        profilename: 'Profile name',
        pricesubtotal: 'Subtotal',
        pricetotal: 'Total',
        pricevaries: 'prices vary',
        privacypolicy: 'Privacy Policy',
        publish: 'publish',
        published: 'published',
        pushnotification: 'Push notification',
        push: 'Push',
        quantity: 'Quantity',
        readless: 'read less',
        readmore: 'read more',
        redeem: 'Redeem',
        refresh: 'Refresh',
        remaining: 'left',
        remove: 'Remove',
        reopen: 'Reopen',
        reopenItem: 'Reopen item',
        reply: 'Reply',
        reportbug: 'Report a bug',
        reset: 'Reset',
        resolve: 'Resolve',
        resource: 'Resource',
        resources: 'Resources',
        review: 'Review',
        salutation: 'Salutation',
        salutationfemale: 'Ms.',
        salutationmale: 'Mr.',
        salutationnonbinary: 'Mrx.',
        save: 'Save',
        saved: 'saved',
        seeall: 'See all',
        send: 'Send',
        share: 'Share',
        skip: 'Skip',
        signup: 'Sign up',
        start: 'Start',
        startdate: 'Start date',
        starttime: 'Start time',
        status: 'Status',
        submit: 'Submit',
        subscriptioncreate: 'Create subscription',
        suggestedcontent: 'Suggested content',
        summary: 'Summary',
        tags: 'Tags',
        tax: 'Tax',
        terms: 'Terms',
        termsandconditions: 'Terms and Conditions',
        ticketurl: 'Ticket link',
        time: 'Time',
        timepass: 'Time pass',
        timezone: 'Timezone',
        title: 'Title',
        to: 'To',
        today: 'Today',
        topics: 'Topics',
        total: 'Total',
        tryagain: 'Try again',
        type: 'Type',
        unfollow: 'Unfollow',
        unsavedchanges: 'Unsaved changes',
        updated: 'Updated',
        updatedat: '@:labels.updated at',
        updatedon: '@:labels.updated on',
        update: 'Update',
        updatecreate: '@:labels.create @.lower:labels.update',
        updatedelete: '@:labels.delete @.lower:labels.update',
        updateedit: '@:labels.edit @.lower:labels.update',
        updates: 'Updates',
        upgrades: 'Upgrades',
        vatid: 'VAT ID',
        view: 'View',
        week: 'Week',
        wallet: 'Wallet',
        yes: 'Yes',
        year: 'Year',
        you: 'You',
    },
    placeholders: {
        address: 'Street and number',
        addressadditionalinformation: "e.g. 'Appartment No. 1'",
        autocompleteempty: 'No results',
        comment: 'Leave a comment',
        couponcode: 'Enter coupon code',
        date: 'Chose a date',
        email: 'mail@example.com',
        file: 'No file selected',
        image: 'Click to change the image',
        marketitemcontact: 'link to a contact page or email',
        marketitemdescription:
            'The description of your item will be displayed on the detail page of your item and should describe it in detail.',
        marketitemtitle: 'Chose a good title :)',
        marketitemsummary: 'The summary of your item will be displayed in the feed and in the market list.',
        messagecreate: 'Write a message',
        optional: 'optional',
        password: 'enter your password',
        post: '@:labels.create @.lower:labels.post',
        postalcode: '12345',
        search: 'search',
        searchmembers: 'Search for members',
        select: '@:placeholders.selectplease',
        selectplease: 'Please select an option',
        tags: 'Start typing',
        updatecreate: '@:labels.create @.lower:labels.update',
        ticketurlplaceholder: 'External ticket link',
    },
    messages: {
        accountexists: 'Already have an account?',
        cookiebanner:
            'This site uses cookies 🍪 to store information on your computer. Some are essential to make our site work; others help us improve the user experience. By using this site, you consent to the placement of these cookies. Read our {privacypolicy} to learn more.',
        copyshareablelink: 'Copy shareable link',
        copiedtoclipboard: 'Copied to clipboard',
        deletewarning: 'Once you delete this, there is no going back. Please be certain.',
        deleteImageHeaderWarning: 'Are you sure you want to delete this image?',
        event: {
            attendanceCancellation: 'Are you sure about this? This event would be much nicer with you!',
        },
        imageguidelines:
            'Please avoid having text inside your image. The recommended image aspect ratio is 3x2 and the recommended image size is 1800x1200.',
        marketiterestsubmit: 'We will inform the author about your interest.',
        maxcharacters: 'max. {count} characters',
        searchnomatches: 'No results match your search criteria',
        unsavedwarning: 'Your changes will be lost. Are you sure?',
        usernotexists: 'User does not exist or was deleted',
        userstatus: {
            3: 'Your account is currently suspended. This is probably due to a payment problem. Please contact customer service to clarify the situation.',
            4: 'Your account is currently cancelled. Please subscribe to a plan to reactivate your membership.',
            5: 'Your account is currently waiting to be approved by an admin. As soon as your account has been approved, you will receive an email. Please make sure that you have confirmed your e-mail address. You have already received a separate email for this purpose.',
        },
        imageUploadError: 'An error occurred while uploading the image. Please try again.',
        imageUploadSizeError: 'The image is too large. Please choose a smaller image. Max 10 MB.',
        copyright: '© 2022 coapp GmbH. All rights reserved.',
    },
    social: {
        facebook: 'Facebook',
        instagram: 'Instagram',
        linkedin: 'LinkedIn',
        twitter: 'Twitter/X',
        youtube: 'YouTube',
        reddit: 'Reddit',
    },
    datetime: ({ named }) =>
        formattedDate(named('date'), named('format'), named('locale') ? named('locale') : null, named('customFormat')),
};
export default enUS;
