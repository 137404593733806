<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>
<style lang="less" scoped>
a {
    color: inherit;
    text-decoration: inherit;
}
</style>

<template>
    <div class="bg-white round-unify w-100 d-flex align-items-start overflow-hidden p-3">
        <div class="w-100 d-flex justify-content-between">
            <div class="">
                <div class="mb-2">
                    <small>Name</small> <br />
                    <h2 class="title">
                        {{ object.Name }}
                    </h2>
                </div>

                <div class="d-flex">
                    <div class="mr-3">
                        <small>HEX Value</small> <br />
                        <h3 class="title">
                            {{ object.Value }}
                        </h3>
                    </div>
                    <div class="">
                        <small>Decimal Value</small> <br />
                        <h3 class="title">
                            {{ hexToDecimal(object.Value) }}
                        </h3>
                    </div>
                </div>

                <div class="d-flex">
                    <div class="mr-3">
                        <small>Valid From</small> <br />
                        <p class="mb-0">{{ object.ValidFrom | timestampToString }}</p>
                    </div>
                    <div class="">
                        <small>To</small> <br />
                        <p class="mb-0">{{ object.ValidTo | timestampToString }}</p>
                    </div>
                </div>

                <div class="mt-2">
                    <small>
                        Created at {{ object.CreatedAt | timestampToString }} <br />
                        Updated at {{ object.UpdatedAt | timestampToString }}
                    </small>
                </div>
            </div>
            <!-- <div class="">
        <b-dropdown
          variant="px-2"
          class="d-menu"
          menu-class="mt-0 border-1"
          no-caret
          right
          round
          style="position: relative"
        >
          <template v-slot:button-content>
            <b-icon icon="three-dots" class="m-0" scale="1"></b-icon>
          </template>
          <b-dropdown-item class="d-action" @click="edit(object.ID)"
            >Edit</b-dropdown-item
          >
        </b-dropdown>
      </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccessTagCard',
    props: ['object', 'to'],
    data() {
        return {};
    },
    beforeCreate() {},
    created() {},

    mounted() {},
    methods: {
        edit(id) {
            this.$emit('editCallBack', id);
        },

        hexToDecimal(hex) {
            return parseInt(hex, 16);
        },
    },
};
</script>
