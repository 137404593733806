<!-- Intro Reminder -->
<template>
    <div>
        <b-modal
            id="introReminder"
            size="lg"
            scrollable
            centered
            modal-class="test"
            class="test2"
            hide-header
            hide-footer
            no-close-on-backdrop
            no-close-on-esc
        >
            <template v-slot:default style="min-height: 600px">
                <b-overlay :show="saving">
                    <b-carousel
                        ref="carouselIntro"
                        style=""
                        v-bind:interval="sliderInterval"
                        no-hover-pause
                        no-touch
                        no-wrap
                        @sliding-end="updateSlide"
                    >
                        <b-carousel-slide v-if="steps[2] != null">
                            <template v-slot:img>
                                <div class="step-description">
                                    <h2>{{ steps[2].Title }}</h2>
                                    <p>{{ steps[2].Subtitle }}</p>
                                </div>
                                <div class="step-image overflow-hidden">
                                    <ProfileEdit
                                        :user="me"
                                        ref="profileSavePic"
                                        hide-visibility
                                        hide-bio
                                        hide-tags
                                        hide-button
                                    >
                                    </ProfileEdit>
                                </div>
                            </template>
                        </b-carousel-slide>
                        <b-carousel-slide v-if="steps[5] != null">
                            <template v-slot:img>
                                <div class="step-description">
                                    <h2>{{ steps[5].Title }}</h2>
                                    <p>{{ steps[5].Subtitle }}</p>
                                </div>
                                <div class="step-image">
                                    <ProfileEdit
                                        :user="me"
                                        ref="profileSaveAbout"
                                        hide-picture
                                        hide-visibility
                                        hide-tags
                                        hide-button
                                        :placeholder="steps[5]['Placeholder']"
                                    >
                                    </ProfileEdit>
                                </div>
                            </template>
                        </b-carousel-slide>
                        <b-carousel-slide v-if="steps[4] != null">
                            <template v-slot:img>
                                <div class="step-description">
                                    <h2>{{ steps[4].Title }}</h2>
                                    <p>{{ steps[4].Subtitle }}</p>
                                </div>
                                <div class="step-image">
                                    <ProfileEdit
                                        :user="me"
                                        ref="profileSaveTags"
                                        hide-picture
                                        hide-visibility
                                        hide-bio
                                        hide-button
                                        in-intro
                                    >
                                    </ProfileEdit>
                                </div>
                            </template>
                        </b-carousel-slide>
                    </b-carousel>
                    <div class="d-block w-100 m-0 pb-md-0 pb-4 mb-4 mt-4">
                        <div class="d-inline-block w-50"></div>
                        <div class="d-inline-block w-50 text-right">
                            <b-button
                                @click="saveSlide(slides[selectedSlide].method)"
                                :disabled="saving"
                                variant="primary"
                            >
                                <span>{{ slides[selectedSlide].text }}</span>
                            </b-button>
                        </div>
                    </div>
                </b-overlay>
            </template>
        </b-modal>
        <b-modal id="reminderModal" size="md" title="Complete your profile" centered hide-footer @close="closeForever">
            <p class="mb-1">
                You still have {{ slides.length }} {{ stepsText }} left to finally complete your profile.
            </p>
            <div class="d-block w-100 m-0 pb-md-0 pb-3 mb-4 mt-4">
                <b-dropdown variant="primary" text="Options">
                    <b-dropdown-item @click="completeProfile()">Complete now</b-dropdown-item>
                    <b-dropdown-item @click="remindMeLater()">Remind me tomorrow</b-dropdown-item>
                    <b-dropdown-item @click="closeForever()">Don't show this anymore</b-dropdown-item>
                </b-dropdown>
            </div>
        </b-modal>
    </div>
</template>
<script>
import * as moment from 'moment';
import { get } from 'lodash';
import EventBus from '@/eventBus';

export default {
    name: 'IntroReminder',
    props: ['intro'],
    data() {
        return {
            saving: false,
            sliderInterval: 10000000,
            steps: {},
            slides: [],
            selectedSlide: 0,
        };
    },
    mounted() {
        this.me = this.$store.state.me;
        const incompleteFields = this.getIncompleteFields(this.me.Profile);

        if (
            this.me.Intro &&
            !this.me.DontShowIntroReminder &&
            this.wasLastActivity1WeekAgo(this.me.LastActivity) &&
            incompleteFields.length > 0 &&
            this.shouldBeRemindedAgain()
        ) {
            const that = this;
            this.setSteps(incompleteFields);
            this.setSlides(incompleteFields);
            this.$bvModal.show('reminderModal');
            EventBus.$on(
                'INFO',
                (payLoad) => {
                    if (payLoad.Source && payLoad.Source === 'Profile Update') {
                        that.saving = false;

                        const incompleteFields = this.getIncompleteFields(this.$store.state.me);
                        if (this.slides.length === this.selectedSlide + 1) {
                            this.closeForever();
                        } else {
                            that.$refs.carouselIntro.next();
                        }
                    }
                },
                this
            );
        }
    },
    computed: {
        stepsText() {
            return this.slides.length > 1 ? 'steps' : 'step';
        },
    },

    methods: {
        setSteps(fields) {
            this.steps = this.intro
                .filter((elm) => fields.includes(elm.Step))
                .reduce(
                    (obj, elm) => ({
                        ...obj,
                        [elm.Step]: elm,
                    }),
                    {}
                );
        },
        setSlides(fields) {
            const slideActions = {
                2: {
                    text: 'Save picture',
                    method: 'profileSavePic',
                },
                4: {
                    text: 'Save tags',
                    method: 'profileSaveTags',
                },
                5: {
                    text: 'Save bio',
                    method: 'profileSaveAbout',
                },
            };

            this.slides = [...fields.map((fieldNumber) => slideActions[fieldNumber])];
        },
        shouldBeRemindedAgain() {
            return !this.$cookie.get('sleepReminderFor1Day');
        },
        wasLastActivity1WeekAgo(unixTime) {
            const lastActivity = moment.unix(unixTime);
            return moment().diff(lastActivity, 'week') >= 1;
        },
        getIncompleteFields(profile) {
            const fieldsAndSteps = {
                ImageURL: 2,
                Tags: 4,
                Interests: 5,
            };
            const keys = ['ImageURL', 'Interests', 'Tags.length'];

            return keys.filter((k) => !get(profile, k)).map((k) => fieldsAndSteps[k.replace('.length', '')]);
        },
        updateSlide() {
            this.selectedSlide += 1;
        },
        saveSlide(slideMethod) {
            if (slideMethod === 'closeForever') {
                this.closeForever();
                return;
            }
            this.saving = true;
            this.$refs[slideMethod].saveChanges();
        },
        close() {
            this.$bvModal.hide('introReminder');
        },
        completeProfile() {
            this.$bvModal.hide('reminderModal');
            this.$bvModal.show('introReminder');
        },
        remindMeLater() {
            this.$bvModal.hide('reminderModal');
            this.$cookie.set('sleepReminderFor1Day', true, 1);
        },
        closeForever() {
            this.$bvModal.hide('reminderModal');
            this.$bvModal.hide('introReminder');
            this.me.DontShowIntroReminder = true;
            this.$store
                .dispatch('updateUser', this.me)
                .then(() => {})
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.step-description {
    min-height: 100px;
    overflow: hidden;
    padding: 20px 0px;
}

.step-image {
    min-height: 250px;

    @media (min-width: 768px) {
        min-height: 400px;
    }

    img {
        height: 250px;

        @media (min-width: 768px) {
            height: 400px;
        }

        width: 100%;
        object-fit: cover;
        object-position: top center;
        border-radius: 7px;
    }
}

.tips {
    p {
        font-size: 18px;
        line-height: 2;
    }

    .menu-icon {
        margin: 0px 10px;
    }
}

.modal-content {
    height: 50%;
}

@media (max-width: 575.98px) {
    .modal-content {
        height: 70%;
    }
}
</style>
