<style lang="less" scoped>
@import '~@/assets/less/manage-space/base.less';
@import '~@/assets/less/variables.less';

.clear-button {
    cursor: pointer;
    background-color: transparent;
    border: transparent;
    color: #1d2124;
}

.page {
    height: 60vh !important;
    overflow: auto;
}

.scrollable {
    overflow-y: scroll !important;
}
</style>
<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>
<template>
    <div>
        <b-modal
            id="modal-add-resource-group"
            ref="modal-add-resource-group"
            title="Add booking resource group"
            @hidden="cancelAddResourceGroup"
        >
            <!-- select resource -->

            <b-form-group id="input-group-resource-group" label-class="label-align">
                <b-form-input
                    v-model="newBookingResourceGroup.Name"
                    class="form-control rounded-pill d-block"
                    placeholder="Name resource group"
                    required
                ></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-resource-group" label-class="label-align">
                <div class="mb-2">
                    <b-button variant="primary" v-b-modal.modal-add-select-resources-group>Select resources</b-button>
                    <div
                        class="d-flex w-100 mt-2 justify-content-between pl-3 pr-3"
                        v-for="(resource, index) in listSelectedResources"
                        :key="resource.ID"
                    >
                        {{ resource.Name }}
                        <b-icon icon="trash" scale="1" @click="removeResourceFromGroup(index, resource)"></b-icon>
                    </div>
                </div>
            </b-form-group>

            <!-- override price toggle -->
            <b-form-group id="input-group-override-price-switch">
                <div class="d-flex align-items-center">
                    <b-form-checkbox
                        id="override-price-switch"
                        v-model="overridePrice"
                        switch
                        size="lg"
                        :value="true"
                    ></b-form-checkbox>
                    <span>Overwrite price</span>
                </div>
            </b-form-group>

            <!-- override price input  -->
            <b-form-group
                class="mt-4"
                id="input-group-override-price"
                label-class="label-align"
                description="Net price per cycle. To calculate gross price will be used Tax rate associated with this resource."
            >
                <b-form-input
                    :disabled="!overridePrice"
                    number
                    min="0.00"
                    v-model="priceOverride"
                    step="0.01"
                    class="form-control rounded-pill d-block"
                ></b-form-input>
            </b-form-group>

            <!-- credits input  -->
            <div class="d-flex flex-wrap mb-3">
                <CoInput
                    :label="`Set credits amount (optional)`"
                    :required="true"
                    :disabled="false"
                    type="number"
                    :min="0"
                    :step="1"
                    v-model="creditsAmount"
                ></CoInput>
                <div class="align-self-end ml-3 flex-grow-1">
                    <CoSelect
                        :items="creditTypes"
                        :value="selectedCreditType"
                        :withConfirmation="false"
                        :listOfStrings="true"
                        :greyOutline="true"
                        @select="selectCreditsType"
                    ></CoSelect>
                </div>
            </div>

            <template #modal-footer>
                <div class="d-flex justify-content-between w-100 m-0">
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <div class="d-flex">
                        <b-button
                            size="sm"
                            variant="primary"
                            @click="addResourceGroup()"
                            :disabled="!newBookingResourceGroup.Name"
                            >Save
                        </b-button>
                    </div>
                    <b-button size="sm" class="text-grey" @click="cancelAddResourceGroup">Cancel</b-button>
                </div>
            </template>
        </b-modal>
        <b-modal
            id="modal-add-select-resources-group"
            ref="modal-add-select-resources-group"
            title="Select resources"
            size="lg"
            @hide="cancelSelection()"
        >
            <b-form @submit="saveSelection()">
                <div class="d-flex justify-content-between w-100 align-items-center small grey mb-3">
                    <div class="d-flex align-items-center">
                        <b-form-checkbox v-model="sort" switch></b-form-checkbox>
                        <span>Sort by selected</span>
                    </div>
                    <div class="d-flex w-100 align-items-center search-bar-wrapper rounded-pill">
                        <b-icon class="mr-2" icon="search"></b-icon>

                        <b-form-input
                            class="search-input"
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                            v-model="searchText"
                            @input="filterResources"
                        />
                    </div>
                </div>
                <div v-if="filteredBookingResources.length > 0">
                    <div
                        class="d-flex w-100 justify-content-between mb-3 pr-3"
                        v-for="(resource, index) in filteredBookingResources"
                        :key="resource.ID"
                    >
                        <div>{{ resource.Name }}</div>
                        <div>
                            <b-form-checkbox
                                class="form-check-input p-0 m-0"
                                :id="'checkbox-' + resource.ID"
                                v-model="filteredBookingResources[index].Selected"
                                @change="sortBySelected()"
                            >
                            </b-form-checkbox>
                        </div>
                    </div>
                </div>
            </b-form>
            <!-- select resource -->
            <template #modal-footer>
                <div class="d-flex w-100 m-0">
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <div class="d-flex">
                        <b-button size="sm" variant="primary" @click="saveSelection()">Save</b-button>
                    </div>
                    <b-button size="sm" class="text-grey ml-2" @click="cancelSelection()">Cancel</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import EventBus from '@/eventBus';
import Vue from 'vue';
import VueMoment from 'vue-moment';
import axios from 'axios';
import CoInput from '@/components/Molecules/co-input/CoInput.vue';
import CoSelect from '@/components/Molecules/co-select/CoSelect.vue';

const moment = require('moment');
Vue.use(require('vue-moment'), {
    moment,
});

export default {
    name: 'AddResourceGroupModal',
    components: { CoSelect, CoInput },
    emits: ['addedResourceGroup'],

    data() {
        return {
            cashCreditsEnabled: true,
            loading: false,
            sort: false,
            searchText: '',
            overridePrice: false,
            priceOverride: 0,
            price: 0,
            listNamesResources: [],
            listPrices: [],
            taxRate: {},

            // all available resources
            bookingResources: [],
            filteredBookingResources: [],
            listSelectedResources: [],

            // resource that will be add to plan

            newBookingResourceGroup: {
                ID: null,
                OverridePrice: false,
                Price: 0,
                Name: '',
                CreditHours: 0.0,
                ListResources: [],
                PriceInCents: 0.0,
                CreditCents: 0,
                CashCredits: false,
            },
            resource: null,

            creditTypes: [
                { Name: 'Hours', Value: false },
                { Name: 'Cash', Value: true },
            ],
            selectedCreditType: { Name: 'Hours', Value: false },

            creditsAmount: 0,
        };
    },
    mounted() {},
    watch: {
        sort(newVal, oldVal) {
            if (newVal == true) {
                this.sortBySelected();
            } else {
                this.filteredBookingResources.sort((a, b) => a.Name.localeCompare(b.Name));
            }
        },
    },
    methods: {
        showModal() {
            this.listResources();

            this.$refs['modal-add-resource-group'].show();
        },
        saveSelection() {
            const listResources = [];

            this.bookingResources.forEach((item) => {
                if (item.Selected) {
                    listResources.push({ ID: item.ID, Name: item.Name });
                }
            });

            this.listSelectedResources = listResources;

            this.$refs['modal-add-select-resources-group'].hide();
            this.sort = false;
        },
        getListNamesResources(bookingResources) {
            bookingResources.forEach((element) => this.listNamesResources.push(element.Name));
        },

        sortBySelected() {
            if (this.sort) {
                this.filteredBookingResources.sort((a, b) => b.Selected - a.Selected);
            }
        },
        cancelSelection() {
            this.sort = false;

            const listSelectedResources = this.listSelectedResources.map((resource) => resource.ID);

            this.bookingResources.forEach((item) => {
                if (listSelectedResources.includes(item.ID)) {
                    item.Selected = true;
                } else {
                    item.Selected = false;
                }
            });
            this.$refs['modal-add-select-resources-group'].hide();
        },
        cancelAddResourceGroup() {
            this.$refs['modal-add-resource-group'].hide();

            this.newBookingResourceGroup = {
                ID: null,
                OverridePrice: false,
                Price: 0,
                Name: '',
                CreditHours: 0.0,
                ListResources: [],
                PriceInCents: 0.0,
                CreditCents: 0,
                CashCredits: false,
            };
        },

        listResources() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/admin/booking/resource/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response.data && response.data.Resources.length !== 0) {
                        this.bookingResources = response.data.Resources;
                        this.getListNamesResources(this.bookingResources);
                        this.bookingResources.sort((a, b) => a.Name.localeCompare(b.Name));

                        this.bookingResources.map((item) => (item.Selected = false));

                        this.filteredBookingResources = this.bookingResources;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },

        calculatePrice(item) {
            if (item.PriceInCents > 0) {
                if (this.taxRate && this.taxRate.Inclusive) {
                    const price = item.PriceInCents / 100.0;

                    return price.toFixed(0);
                }
                const price = item.PriceInCents / 100.0;
                const totalPrice = price * (this.taxRate.Percentage / 100 + 1);
                return totalPrice.toFixed(0);
            }
            return 0;
        },
        filterResources() {
            const searchText = this.searchText.toLowerCase();

            this.filteredBookingResources = this.bookingResources.filter((item) => {
                const resource = item.Name.toLowerCase();
                return resource.includes(searchText);
            });
        },
        removeResourceFromGroup(index, resource) {
            this.listSelectedResources.splice(index, 1);

            this.bookingResources.forEach((item) => {
                if (resource.ID === item.ID) {
                    item.Selected = false;
                }
            });

            this.newBookingResourceGroup.Price = this.calculateResourceGroupPrice();
        },

        addResourceGroup() {
            this.newBookingResourceGroup.PriceInCents = this.priceOverride * 100;
            this.newBookingResourceGroup.OverridePrice = this.overridePrice;
            this.newBookingResourceGroup.ListResources = this.listSelectedResources;

            if (this.selectedCreditType.Name === 'Cash') {
                this.newBookingResourceGroup.CashCredits = true;
                this.newBookingResourceGroup.CreditHours = 0.0;
                this.newBookingResourceGroup.CreditCents = this.creditsAmount * 100;
            } else {
                this.newBookingResourceGroup.CashCredits = false;
                this.newBookingResourceGroup.CreditHours = this.creditsAmount;
                this.newBookingResourceGroup.CreditCents = 0;
            }

            this.$emit('addedResourceGroup', this.newBookingResourceGroup);

            this.$refs['modal-add-resource-group'].hide();

            this.newBookingResourceGroup = {
                ID: null,
                OverridePrice: false,
                Price: 0,
                PriceInCents: 0,
                Name: '',
                CreditHours: 0.0,
                ListResources: [],
                CreditCents: 0,
                CashCredits: false,
            };
            this.listSelectedResources = [];
            this.priceOverride = 0.0;
            this.overridePrice = false;
            this.creditsAmount = 0.0;
        },

        selectCreditsType(item) {
            this.selectedCreditType = item;
        },
    },
};
</script>
