
































export default {
    name: 'CoNavBar',
    props: {
        position: {
            type: String,
            default: 'sticky',
            validator(value: string) {
                return ['sticky', 'fixed'].includes(value);
            },
        },
    },
};
