<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <div>
        <div class="container-fluid px-0 px-sm-4 m-0 pt-2">
            <div class="row m-0">
                <div class="col-lg p-0 pr-md-1">
                    <!-- class="list-item round-unify w-100 d-flex align-items-start" -->
                    <router-link
                        id="stripe-link"
                        tag="a"
                        to="/admin/monetization/payments/stripe"
                        class="settings-card-wrapper"
                    >
                        <div class="settings-card px-4 pt-4 pb-5 bg-white round-unify">
                            <div class="d-flex justify-content-between">
                                <h1>Stripe</h1>
                            </div>
                            <p>Connect Stripe to start accepting payments</p>
                        </div>
                    </router-link>

                    <router-link
                        id="payment-methods-link"
                        tag="a"
                        to="/admin/monetization/payments/payment-methods"
                        class="settings-card-wrapper"
                        v-if="$store.state.billingTurnedOn"
                    >
                        <div class="settings-card px-4 pt-4 pb-5 bg-white round-unify">
                            <div class="d-flex justify-content-between">
                                <h1>Payment Methods</h1>
                            </div>
                            <p>Manage allowed payment methods for your members</p>
                        </div>
                    </router-link>

                    <router-link
                        id="tax-rate-link"
                        tag="a"
                        to="/admin/monetization/payments/tax-rate"
                        class="settings-card-wrapper"
                        v-if="$store.state.billingTurnedOn"
                    >
                        <div class="settings-card px-4 pt-4 pb-5 bg-white round-unify">
                            <div class="d-flex justify-content-between">
                                <h1>Tax Rate</h1>
                            </div>
                            <p>Define tax rates for plans, upgrades and resources</p>
                        </div>
                    </router-link>
                    <router-link
                        id="currency-link"
                        tag="a"
                        to="/admin/monetization/payments/currency"
                        class="settings-card-wrapper"
                    >
                        <div class="settings-card px-4 pt-4 pb-5 bg-white round-unify">
                            <div class="d-flex justify-content-between">
                                <h1>Currency</h1>
                                <b-badge variant="" class="bg-flamingo align-self-start">New</b-badge>
                            </div>
                            <p>
                                Define the currency that will be used in the pricing of plans, upgrades, and resources.
                            </p>
                        </div>
                    </router-link>
                </div>
                <!-- <div class="col-lg">
          <div class="settings-card px-4 pt-4 pb-5 mb-3 bg-white round-unify">
            <h1>Reports</h1>
            <p>See your number</p>
          </div>
        </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PaymentMethods',
    data() {
        return {};
    },
};
</script>
