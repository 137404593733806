<template>
    <div class="co input">
        <CoLabel v-if="label" class="d-block">
            {{ label }}
        </CoLabel>
        <div class="d-inline-flex">
            <CoIcon v-if="icon" class="icon align-self-center" :name="icon"></CoIcon>
            <input
                ref="coInputElem"
                class="align-self-center"
                :class="{
                    'has-icon': icon,
                    disabled: disabled,
                }"
                :type="type"
                :value="value"
                :placeholder="placeholder"
                v-model="inputValue"
                @keydown="getAutoCompleteItems"
                @blur="resetValue"
                @input="$emit('input', inputValue)"
                :disabled="disabled"
            />
            <CoDropdownList
                v-if="(autocompleteItems || autocompleteLoading) && !objects"
                :firstItem="inputValue"
                :loading="autocompleteLoading"
                :items="autocompleteItems"
                :selected="selected"
                @itemClicked="autoCompleteSelected"
            ></CoDropdownList>
            <CoDropdownListObjects
                v-if="autocompleteItems && objects"
                :items="autocompleteItems"
                @itemClicked="autoCompleteSelectedObject"
            >
            </CoDropdownListObjects>
        </div>
    </div>
</template>
<style lang="less" scoped>
@import './CoInputWithAutoComplete.less';
</style>

<script>
import { debug } from 'velocity-animate';
import ProfileCircleVue from '../../old-components/ProfileCircle.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoDropdownListObjects from '../../Molecules/co-dropdown-list/CoDropdownListObjects.vue';
import CoDropdownList from '../../Molecules/co-dropdown-list/CoDropdownList.vue';
import CoLabel from '../../Atoms/co-label/CoLabel.vue';

export default {
    name: 'CoInput',
    components: {
        CoIcon,
        CoDropdownListObjects,
        CoDropdownList,
        CoLabel,
    },
    props: {
        label: String,
        value: String,
        type: String,
        icon: String,
        autocompleteMethod: Function, // accepts searchValue<String> must return array of strings
        placeholder: String,
        strings: {
            type: Boolean,
            required: false,
            default: true,
        },

        objects: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            inputValue: this.value || '',
            timer: null,
            autocompleteItems: null,
            autocompleteLoading: null,
            selected: null,
        };
    },
    methods: {
        resetValue() {
            this.inputValue = '';
            this.autocompleteItems = null;
        },
        inputEnter(event) {
            this.$emit('enter', event);
        },
        getAutoCompleteItems(event) {
            switch (event.code) {
                case 'ArrowDown':
                    this.selectNext();
                    return false;
                case 'ArrowUp':
                    this.selectPrev();
                    return false;
                case 'Enter':
                    if (Array.isArray(this.autocompleteItems)) {
                        this.inputValue = this.autocompleteItems[this.selected] || this.inputValue;
                    }
                    this.$emit('enter', this.inputValue);
                    clearTimeout(this.timer);
                    this.timer = null;
                    this.autocompleteLoading = false;
                    return false;
                default:
                    this.selected = null;
                    break;
            }
            // return if this.autocomplete is not set
            if (this.autocompleteMethod && this.$refs.coInputElem.value && this.$refs.coInputElem.value.length > 1) {
                // indicate loading status
                this.autocompleteLoading = true;
                // delay api requests to reduce queries

                if (this.timer) {
                    clearTimeout(this.timer);
                    this.timer = null;
                }
                this.timer = setTimeout(() => {
                    // reset autocomplete items

                    // get autocomplete items
                    try {
                        this.autocompleteMethod(this.inputValue)
                            .then((items) => {
                                this.autocompleteLoading = false;
                                this.autocompleteItems = items;
                            })
                            .catch((err) => {
                                throw new Error(err);
                            });
                    } catch (err) {
                        this.autocompleteItems = ['error getting autocomplete items'];
                        console.warn(err);
                    }
                }, 500); // request delay in ms
            }
        },
        autoCompleteSelected(event) {
            this.inputValue = event;
            this.$emit('enter', event);
        },
        autoCompleteSelectedObject(event) {
            this.inputValue = event.Profile.Name;
            this.$emit('enter', event);
        },
        selectNext(event) {
            if (!this.autocompleteItems) return false;
            if (this.selected !== null && this.selected < this.autocompleteItems.length - 1) {
                this.selected++;
            } else {
                this.selected = 0;
            }
        },
        selectPrev(event) {
            if (!this.autocompleteItems) return false;
            if (this.selected > 0) {
                this.selected--;
            } else {
                this.selected = this.autocompleteItems.length - 1;
            }
        },
    },
};
</script>
