<template>
    <div class="min-vh-100 d-flex bg justify-content-center align-items-md-center">
        <vue-headful title="Email Confirmation" description="" :image="this.$store.state.space.LogoURL" />
        <!-- user login -->
        <div class="login-form">
            <div class="mt-4 mt-md-0 p-4 bg-white round-unify text-left">
                <div class="text-center">
                    <img
                        v-if="this.$store.state.space.LogoURL"
                        :src="this.$store.state.space.LogoURL"
                        width="145"
                        height="auto"
                        alt="Community Logo"
                        class="my-2"
                    />
                    <div v-else class="my-2">
                        <h2>{{ this.$store.state.space.Name }}</h2>
                    </div>
                </div>
                <div v-if="!confirmed" class="my-4 text-center">
                    <h2 class="my-1 primary">Email Confirmation</h2>
                    <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
                </div>
                <div class="w-100 py-2" v-if="error">
                    <div class="alert alert-danger bg-white text-orange" role="alert" v-if="error">
                        <p class="mb-0">{{ error.message }}</p>
                    </div>
                </div>
                <div v-if="confirmed" class="text-center">
                    <div class="my-4 label-align">
                        <h2>Congratulations!</h2>
                        <p>Your email was verified.</p>
                    </div>
                    <p><b-button to="/" variant="primary">To community</b-button></p>
                </div>
            </div>
        </div>
        <!-- user login -->
    </div>
</template>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

// login view
.login-form {
    width: 100%;
    max-width: 400px;
}

.bg {
    background-color: #fff;
    @media (min-width: 768px) {
        background-color: #dedfde;
    }
}
.hfv-red-btn {
    margin-top: 35px;
}

.d-block {
    margin-left: 0.8em !important;
    font-weight: 600 !important;
    color: black !important;
    font-size: 18px !important;
}
.card-body {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
</style>

<script>
import axios from 'axios';

export default {
    name: 'EmailConfirmation',
    data() {
        return {
            error: null,
            loading: false,
            code: this.$route.params.code,
            confirmed: false,
        };
    },
    mounted() {
        this.confirmEmail();
    },

    methods: {
        confirmEmail() {
            this.loading = true;
            const data = {
                OTP: this.code,
            };
            axios({
                method: 'POST',
                url: `/user/confirm-email`,
                data: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    const token = response.headers.Authorization;
                    localStorage.setItem('_authtoken', token);
                    this.confirmed = true;
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = {
                        message: 'Link is invalid or expired.',
                    };
                    console.log(error);
                });
        },
    },
};
</script>
