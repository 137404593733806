


































































import { PropType } from 'vue';
import axios from 'axios';
import CoSkeleton from '../../Atoms/co-skeleton/CoSkeleton.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoText from '../../Atoms/co-text/CoText.vue';
import CoRoundButton from '../../Atoms/co-round-button/CoRoundButton.vue';
import CoThumbnail from '../../Molecules/co-thumbnail/CoThumbnail.vue';
import CoDate from '../../Molecules/co-date/CoDate.vue';

export default {
    name: 'CoUpcomingEventsWidget',
    components: {
        CoSkeleton,
        CoIcon,
        CoText,
        CoRoundButton,
        CoThumbnail,
        CoDate,
    },
    props: {
        isCirclesOn: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: true,
            events: [],
        };
    },
    mounted() {
        if (this.isCirclesOn) {
            this.listEventsCircle();
        } else {
            this.listEvents();
        }
    },
    methods: {
        isOngoing(event) {
            const todayTimestamp = Math.round(new Date().getTime() / 1000);
            return event.StartDate <= todayTimestamp && event.EndDate >= todayTimestamp;
        },
        listEvents() {
            axios
                .get('/event/list')
                .then((response) => {
                    if (response && response.data && response.data.objects && response.data.objects.length > 0) {
                        let events = response.data.objects;
                        if (events.length > 4) {
                            events = events.slice(0, 4);
                        }
                        this.events = events;

                        this.loading = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        listEventsCircle() {
            const todayTimestamp = Math.round(new Date().getTime() / 1000);

            const data = {
                Params: this.nextParams,
                // Desc: true,
                From: todayTimestamp,
                To: todayTimestamp + 60 * 60 * 24 * 5000,
            };
            axios({
                method: 'POST',
                url: '/event/list/circles/v2',
                withCredentials: true,
                data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data && response.data.Objects && response.data.Objects.length > 0) {
                        let events = response.data.Objects;
                        if (events.length > 4) {
                            events = events.slice(0, 4);
                        }
                        this.events = events;

                        this.loading = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
