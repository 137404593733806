<style lang="less" scoped>
@import './CoCreateResourceCheckoutPage.less';

@import '~@/assets/less/variables.less';
.round-unify {
    border-radius: 1rem !important;
    overflow: hidden;
}

@media (min-width: 768px) {
    .col-image {
        height: 70vh;
    }
    .column {
        min-height: 70vh;
    }
}
</style>

<template>
    <div class="bg-white p-0 p-lg-3 w-100 round-unify pb-5 column" style="overflow-y: visible !important">
        <div>
            <CoInput label="Create heading (optional)" v-model="checkout.Heading"></CoInput>
        </div>
        <div class="mt-2">
            <CoLabel text="Add information (optional)"></CoLabel>
            <CoTextArea
                @input="addCheckoutInfo"
                :value="checkout.Information"
                placeholder="Add extra information about the resource"
            >
            </CoTextArea>
        </div>

        <div class="mt-2">
            <CoInput label="Button title (optional)" placeholder="Enter button title" v-model="checkout.ButtonTitle">
            </CoInput>
        </div>
        <div class="mt-2">
            <CoInput label="Button URL (optional)" placeholder="Enter button URL" v-model="checkout.ButtonURL">
            </CoInput>
        </div>

        <div class="mt-3 pb-3">
            <span class="ml-2">Terms and Conditions</span>
            <div class="mt-2">
                <CoCheckbox name="Use default" v-model="defaultTermsAndConditions"></CoCheckbox>
            </div>
            <div class="" v-if="!defaultTermsAndConditions">
                <CoLabel text="Terms and conditions"></CoLabel>
                <CoTextArea
                    :value="checkout.TermsAndConditions"
                    placeholder="Add terms and conditions for this resource"
                    @input="addTermsAndConditions"
                >
                </CoTextArea>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from '../../../eventBus';
import axios from 'axios';

export default {
    name: 'CoCreateResourceCheckoutPage',
    data() {
        return {
            me: this.$store.state.me,

            windowWidth: window.innerWidth,
            minWidth: true,
            defaultTermsAndConditions: false,
            TermsAndConditions: null,

            loading: false,
            checkout: {
                Information: 'ifshdifdsi',
            },

            id: this.$route.params.ID, // current or upcoming
        };
    },
    created() {},

    mounted() {
        this.listResources();
        this.getTermsAndConditions();

        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;

            if (this.windowWidth >= 768) {
                this.minWidth = true;
            } else {
                this.minWidth = false;
            }
        });
    },
    watch: {
        defaultTermsAndConditions: function (newVal) {
            if (newVal) {
                this.checkout.TermsAndConditions = this.TermsAndConditions;
            } else {
                this.checkout.TermsAndConditions = null;
            }
        },
    },
    methods: {
        capacityInput(item, index) {
            console.log(item);
        },
        selectedItem(item, index) {
            this.linkedResources[index].Resource = item;
        },
        selectBookOrLink(item, index) {
            this.linkedResources[index].Book = item;
        },
        addCheckoutInfo(e) {
            this.checkout.Information = e;
        },
        addTermsAndConditions(e) {
            this.checkout.TermsAndConditions = e;
        },
        addLinkedResource() {
            this.linkedResources.push({
                Name: '',
                Resource: {},
                Capacity: '',
                Book: '',
                ID: '',
            });
        },
        getTermsAndConditions() {
            axios({
                method: 'GET',
                url: '/space/get-terms-and-conditions',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.TermsAndConditions = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        listResources() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/admin/booking/resource/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response.data && response.data['Resources'].length != 0) {
                        this.resources = response.data['Resources'];

                        this.resources.sort(function (a, b) {
                            return a.Name.localeCompare(b.Name);
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
    },
};
</script>
