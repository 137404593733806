


















import CoCardPlan from '../../Molecules/co-card-plan/CoCardPlan.vue';

export default {
    name: 'CoPlanList',
    components: {
        CoCardPlan,
    },
    props: {
        plans: {
            type: Array,
            required: true,
        },
    },
    methods: {
        selectPlan(plan) {
            this.$emit('select', plan);
        },
    },
};
