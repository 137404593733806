import en from './en.js';
import enUS from './en-US.js';
import deDE from './de-DE.js';

const messages = {
    en: en,
    'en-US': enUS,
    de: deDE,
};

const numbers = {
    en: {
        currency: {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
    },
    de: {
        currency: {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
    },
};

export { messages, numbers };
