



















import EventBus from '../../../eventBus.js';

export default {
    name: 'CoSharableInput',
    props: {
        label: String,
        value: String,
        type: String,
        placeholder: String,
        readonly: Boolean,
        icon: String,
    },
    methods: {
        copyValueToClipboard() {
            navigator.clipboard.writeText(this.value);
            EventBus.$emit('INFO', {
                Message: 'Copied to clipboard',
                Details: '',
            });
        },
    },
};
