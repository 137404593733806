<template>
    <div class="min-vh-100 d-flex bg justify-content-center align-items-md-center">
        <vue-headful
            :title="'Login: ' + this.$store.state.space.Name + ' Community Platform'"
            description=""
            :image="this.$store.state.space.LogoURL"
        />
        <!-- user login -->
        <div class="login-form">
            <div class="mt-4 mt-md-0 p-4 bg-white round-unify text-left">
                <div class="text-center">
                    <img
                        v-if="this.$store.state.space.LogoURL"
                        :src="this.$store.state.space.LogoURL"
                        width="145"
                        height="auto"
                        alt="Community Logo"
                        class="my-2"
                    />
                    <div v-else class="my-2">
                        <h2>{{ this.$store.state.space.Name }}</h2>
                    </div>
                </div>

                <div class="w-100 py-4 my-3">
                    <div class="alert alert-danger bg-white text-orange" role="alert" v-if="error">
                        <p class="mb-0" v-if="error.response && error.response.status === 401">
                            {{ $t('messages.invalidcredentials') }}
                        </p>
                        <p class="mb-0" v-else>{{ error.message }}</p>
                    </div>

                    <b-form @submit.prevent="login" v-if="show">
                        <b-form-group
                            id="input-group-1"
                            :label="$t('labels.email')"
                            label-for="input-1"
                            label-class="label-align"
                            :disabled="loading"
                        >
                            <b-form-input
                                id="input-1"
                                v-model="username"
                                type="text"
                                class="form-control rounded-pill"
                                required
                                :placeholder="$t('placeholders.email')"
                                :disabled="loading"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-2">
                            <div class="mb-1">
                                <span class="label-align">{{ $t('labels.password') }}</span
                                ><span class="ml-2 clickable" @click="switchPasswordView">
                                    <b-icon variant="primary" :icon="passwortViewIcon"></b-icon>
                                </span>
                            </div>
                            <b-form-input
                                id="input-2"
                                v-model="password"
                                :type="passwortInputType"
                                class="form-control rounded-pill"
                                required
                                :placeholder="$t('placeholders.password')"
                                :disabled="loading"
                                :formatter="checkLength"
                            ></b-form-input>
                        </b-form-group>
                        <div class="d-flex justify-content-end">
                            <CoButton :disabled="loading" variant="primary" type="submit" id="login-btn"
                                >{{ $t('labels.login') }}
                            </CoButton>
                        </div>
                    </b-form>
                </div>
                <div
                    class="my-4"
                    v-if="
                        this.$store.state.space.ID ===
                        'c97c5a2253a56e8e9027881658c37c54a458bd1a2b5d59a81bacde6a52f42af5'
                    "
                >
                    <h3 class="my-1">{{ messageHeading }}</h3>
                    <p class="text-muted mb-1">{{ messageText }}</p>
                    <a :href="btnLink" class="text-primary" :title="btnTitle">{{ btnText }}</a>
                </div>
                <div class="mb-4 mt-0" v-if="!this.inviteOnly && this.showSignup">
                    <h3 class="my-1">
                        {{ $t('messages.newhere', { space: this.$store.state.space.Name }) }}
                        <a
                            v-if="$store.state.billingTurnedOn"
                            href="/plans"
                            class="text-primary"
                            :title="$t('labels.signup')"
                            >{{ $t('messages.signup') }}</a
                        >
                        <a v-else href="/signup" class="text-primary" :title="$t('labels.signup')">{{
                            $t('messages.signup')
                        }}</a>
                    </h3>
                </div>
                <div>
                    <a href="/reset-password" target="_blank" :title="$t('labels.passwordreset')"
                        ><small>{{ $t('labels.passwordreset') }}</small></a
                    >
                </div>
                <!-- <div>
<a
v-if="this.$store.state.space['TermsOfUseURL']"
:href="this.$store.state.space['TermsOfUseURL']"
target="_blank"
title="Terms and Conditions"
><small>Terms and Conditions</small></a
>
</div>
<div>
<a
v-if="this.$store.state.space['PrivacyPolicyURL']"
:href="this.$store.state.space['PrivacyPolicyURL']"
target="_blank"
title="Privacy policy"
><small>Privacy policy</small></a
>
</div> -->
            </div>

            <div class="d-flex justify-content-center">
                <OA2Providers :is-login="true" padding-left="5rem" padding-right="5rem" back-ground-color="white">
                </OA2Providers>
            </div>
        </div>
        <!-- user login -->

        <!-- modal alert about invalid link -->
        <CoModal ref="invalidInviteLinkMessageModal" background="white">
            <template v-slot:header>
                <CoHeadline :level="2" class="px-2">{{ $t('labels.linkInvalid') }}</CoHeadline>
            </template>
            <template v-slot:body>
                <CoText class="px-2">
                    {{ $t('labels.linkInvalidDescription') }}
                </CoText>
            </template>
            <template v-slot:footer>
                <div class="d-flex justify-content-between align-items-center">
                    <CoButton variant="text" @click="removeInvalidInviteLink()">Close</CoButton>
                    <CoButton variant="primary" @click="retryInvalidInviteLink()">Try again</CoButton>
                </div>
            </template>
        </CoModal>
    </div>
</template>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

// login view
.login-form {
    width: 100%;
    max-width: 400px;
}

input[type='text'].form-control::placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='text'].form-control::-webkit-input-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='text'].form-control:-moz-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='text'].form-control::-ms-input-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='text'].form-control::-moz-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='password'].form-control::placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='password'].form-control::-webkit-input-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='password'].form-control:-moz-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='password'].form-control::-ms-input-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='password'].form-control::-moz-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

.bg {
    background-color: #fff;

    @media (min-width: 768px) {
        background-color: #dedfde;
    }
}

.hfv-red-btn {
    margin-top: 35px;
}

.d-block {
    margin-left: 0.8em !important;
    font-weight: 600 !important;
    color: black !important;
    font-size: 18px !important;
}

.card-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
</style>

<script>
import { debounce } from 'lodash';
import EventBus from '../../eventBus';
import OA2Providers from '../../components/auth_methods/OA2Providers.vue';

import CoButton from '../../components/Atoms/co-button/CoButton.vue';
import CoModal from '../../components/Organisms/co-modal/CoModal.vue';
import CoHeadline from '../../components/Atoms/co-headline/CoHeadline.vue';
import CoText from '../../components/Atoms/co-text/CoText.vue';

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Login',
    i18n: {
        // `i18n` option, setup component specific language and translations
        messages: {
            en: {
                messages: {
                    invalidcredentials:
                        'This combination of email and password does not exist. Please double-check and try again.',
                    newhere: 'New to {space}?',
                    signup: 'Sign up here.',
                },
            },
            de: {
                messages: {
                    invalidcredentials:
                        'Diese Kombination aus E-Mail und Passwort existiert nicht. Bitte überprüfe die Eingaben und versuche es erneut.',
                    newhere: 'Neu bei {space}?',
                    signup: 'Registriere dich hier.',
                },
            },
        },
    },
    components: {
        OA2Providers,
        CoButton,
        CoModal,
        CoHeadline,
        CoText,
    },
    data() {
        return {
            username: null,
            password: null,
            error: null,
            show: true,
            loading: false,
            redirect: null,
            showMessage: true,
            passwortInputType: 'password',
            passwortViewIcon: 'eye-slash',
            messageHeading: 'Du bist noch nicht Mitglied?',
            messageText: 'Einfach ab 15 € monatlich ausprobieren',
            btnText: 'Mitglied werden',
            btnTitle: 'Hafven Mitgliedschaftstarife ansehen',
            btnLink:
                'https://hafven.de/tarife?utm_source=community%20web%20app&utm_medium=login%20screen&utm_campaign=mitglied%20werden',
            // landing : {
            //   isUser : this.$route.query.redirect ? this.$route.query.redirect.match(/\/profile\/([\b\D\-]+)\-\w+$/gi) : null,
            //   isProject : this.$route.query.redirect ? this.$route.query.redirect.match(/\/project\/([\b\D\-]+)\-\w+$/gi) : null,
            //   isSearch : this.$route.query.redirect ? this.$route.query.redirect.match(/\/search\/([\b\D\-]+)\-\w+$/gi) : null,
            //   term : null
            // }
            showSignup: false,
            // hideSignupLink: this.$unleash.isEnabled('frontend.login.hide-signup-link'),
            invalidInviteLink: this.$route.query.invalidInviteLink,
            signUpStrategy: null,
            inviteOnly: false,
        };
    },
    computed: {
        state() {
            return this.$store.getters.state;
        },
        components() {
            return JSON.stringify(this.$options.components);
        },
    },
    created() {
        this.debouncedCheckLength = debounce(this.notifyPasswordLength, 500);
        this.$unleash.on('update', () => {
            if (this.$unleash.isEnabled('frontend.login.hide-signup-link')) {
                this.showSignup = false;
            } else {
                this.showSignup = true;
            }
        });
        if (this.$unleash.isEnabled('frontend.login.hide-signup-link')) {
            this.showSignup = false;
        } else {
            this.showSignup = true;
        }
        this.getSignUpStrategy();
    },
    mounted() {
        this.$store.commit('RESET_MEMBERSHIP_PROVIDER');

        if (this.invalidInviteLink) {
            this.$refs.invalidInviteLinkMessageModal.show();
        }
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            // eslint-disable-next-line no-param-reassign
            vm.redirect = to.query.redirect;
        });
    },
    methods: {
        getSignUpStrategy() {
            this.$store
                .dispatch('getSignupSettings')
                .then((response) => {
                    if (response && response.data) {
                        this.signUpStrategy = response.data;

                        if (this.signUpStrategy.signUpStrategy === 'invite-only') {
                            this.inviteOnly = true;
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        removeInvalidInviteLink() {
            this.invalidInviteLink = null;
            // remove from url
            this.$router.replace({ query: {} });
            this.$refs.invalidInviteLinkMessageModal.hide();
        },
        retryInvalidInviteLink() {
            this.$refs.invalidInviteLinkMessageModal.hide();
            this.$router.go(this.invalidInviteLink);
        },

        switchPasswordView() {
            if (this.passwortInputType === 'password') {
                this.passwortInputType = 'text';
                this.passwortViewIcon = 'eye';
            } else {
                this.passwortInputType = 'password';
                this.passwortViewIcon = 'eye-slash';
            }
        },

        login() {
            this.loading = true;
            this.$store
                .dispatch('loginV2', {
                    email: this.username.toLowerCase(),
                    password: this.password,
                })
                .then(() => {
                    this.$store
                        .dispatch('getMe', null)
                        .then((response2) => {
                            this.$root.$data.$me = response2.data;
                            this.loading = false;
                            if (this.redirect && this.redirect !== '%2F' && this.redirect !== '/') {
                                this.$router.push(this.redirect);
                            } else {
                                this.$router.push('/');
                            }
                        })
                        .catch((error) => {
                            this.$router.push('/');
                            const { message } = error.response.data;
                            const err = String('missing or malformed jwt');

                            if (message === err) {
                                this.$store.commit('LOGOUT');
                            }
                        });
                })
                .catch((error) => {
                    // if status is 401 and error code 10003 redirect to password reset
                    this.loading = false;

                    this.error = error;
                    console.log(error.response);
                    if (
                        error.response &&
                        error.response.status === 401 &&
                        error.response.data &&
                        error.response.data.Code === '10003'
                    ) {
                        this.$router.push('/force-password-reset');
                    }

                    if (
                        error.response &&
                        error.response.status === 401 &&
                        error.response.data.contains('must change password')
                    ) {
                        this.$router.push('/force-password-reset');
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        notifyPasswordLength() {
            EventBus.$emit(`ERROR`, {
                Message: this.$t('messages.passwordlength'),
            });
        },
        checkLength(value) {
            if (value.length > 56) {
                this.debouncedCheckLength();
                return value.substring(0, 56);
            }
            return value;
        },
    },
};
</script>
