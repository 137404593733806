<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.emojiList .emoji {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.emoji {
    font-size: 21px;
    cursor: pointer;
}
</style>
<template>
    <div class="d-flex align-items-center w-100">
        <div class="d-flex w-100 flex-wrap align-items-center">
            <div class="d-flex align-items-center" v-for="(item, emoji, j) in emojiObject" v-bind:key="emoji + j">
                <span
                    @mousedown="start()"
                    @touchstart="start()"
                    @mouseup="stop()"
                    @touchend="stop()"
                    class="emoji"
                    :id="object.ID + emoji"
                    @click="insertEmoji(emoji)"
                    >{{ emoji }}</span
                >
                <b-badge class="emoji-count-badge pr-1" variant="primary" v-if="item.Users">{{
                    item.Users.length
                }}</b-badge>
            </div>

            <div v-for="(index, emoji, j) in toolTipTitles" v-bind:key="object.ID + emoji + j">
                <b-tooltip :target="object.ID + emoji" rel="tooltip" triggers="hover" delay="0">
                    <span>
                        {{ toolTipTitles[emoji] }}
                    </span>
                    <span> reacted with <br />"{{ emoji }}"</span>
                </b-tooltip>
            </div>
        </div>

        <div class="d-flex ml-auto">
            <emoji-picker
                :emojiTable="myemojiTable"
                ref="emo"
                class="d-flex align-items-end mr-2"
                @emoji="insertEmoji"
                :search="search"
            >
                <div
                    class="emoji-invoker h-100 w-100 coapp-icon"
                    slot="emoji-invoker"
                    slot-scope="{ events: { click: clickEvent } }"
                    @click.stop="clickEvent"
                >
                    <div class="action-emoji">
                        <img src="../../assets/emoji-smile.svg" style="cursor: pointer" width="auto" height="21px" />
                    </div>
                </div>

                <div slot="emoji-picker" slot-scope="{ emojis, insert, display }">
                    <div class="emoji-picker" :style="{ bottoms: display.y + 'px', left: '0px', top: 'auto' }">
                        <!-- <h5>Search</h5> -->
                        <div class="emoji-scroll">
                            <div v-if="$language && !$language.match('de')" class="emoji-picker__search">
                                <input type="text" :placeholder="$t('placeholders.search')" v-model="search" />
                            </div>
                            <div>
                                <div v-for="(emojiGroup, category) in emojis" :key="category">
                                    <h5>{{ category }}</h5>
                                    <div class="emojis">
                                        <span
                                            v-for="(emoji, emojiName) in emojiGroup"
                                            class="emoji"
                                            :key="emojiName"
                                            @click="insertEmoji(emoji)"
                                            :title="emojiName"
                                            >{{ emoji }}</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </emoji-picker>
        </div>
        <b-modal
            :id="'apply-modal-reactions-' + object.ID"
            :ref="'apply-modal-reactions-' + object.ID"
            modal-class="reactions-modal modal"
            size="sm"
            title="User reactions"
            centered
            hide-footer
            scrollable
        >
            <b-overlay :show="loadingForReaction" spinner-variant="primary" rounded="sm">
                <div class="mb-4 wrap">
                    <b-tabs class="reactions-tabs-nav" scrollable no-fade>
                        <div class="d-flex flex-column" v-for="(item, emoji, j) in emojiObject" v-bind:key="emoji + j">
                            <b-tab
                                v-if="emojiObject[emoji].Users"
                                :title="emoji + ' ' + emojiObject[emoji].Users.length"
                                style="color: black"
                            >
                                <div v-for="(item2, user) in emoji" v-bind:key="user + item2">
                                    <span v-if="emojiObject[emoji].Users[user]" class="d-flex w-100 pt-3">
                                        <router-link :to="'/profile/' + emojiObject[emoji].Users[user].ID">{{
                                            emojiObject[emoji].Users[user].Name
                                        }}</router-link>
                                    </span>
                                </div>
                            </b-tab>
                        </div>
                    </b-tabs>
                </div>
            </b-overlay>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';
import EmojiPicker from 'vue-emoji-picker';
import { mixin as clickaway } from 'vue-clickaway';
import Vue from 'vue';
import { longClickDirective } from 'vue-long-click';
import myemojis from './myemojis.js';

const longClickInstance = longClickDirective({ delay: 400, interval: 50 });
Vue.directive('longclick', longClickInstance);

import { keys } from 'lodash';
import i18n from 'vue-i18n';

export default {
    i18n: {
        messages: {
            de: {
                'Frequently used': 'Häufig verwendet',
                People: 'Menschen',
                Objects: 'Objekte',
                Places: 'Orte',
                Symbols: 'Symbole',
            },
        },
    },
    name: 'Reactions',
    components: {
        EmojiPicker,
    },
    props: ['object', 'objectType'],
    data() {
        return {
            emojiObject: {},
            toolTipTitles: {},
            me: this.$store.state.me,
            search: '',
            loadingForReaction: false,
            timer: undefined,
            elapsedTime: 0,
        };
    },
    created() {
        // uncomment for functioning
        if (this.object.Reactions) {
            this.emojiObject = this.object.Reactions;
        }
        const that = this;
        Object.keys(that.emojiObject).forEach((key) => {
            that.toolTipUpdate(key);
        });
    },
    mounted() {},
    computed: {
        formattedElapsedTime() {
            const date = new Date(null);
            date.setSeconds(this.elapsedTime / 1000);
            const utc = date.toUTCString();
            return utc.substr(utc.indexOf(':') - 2, 8);
        },
        myemojiTable() {
            // translate the emoji table!
            let titles = keys(myemojis);
            let emojis = {};
            titles.map((item) => {
                emojis[this.$t(item)] = myemojis[item];
            });
            return emojis;
        },
    },
    methods: {
        showListReactions() {
            this.$refs[`apply-modal-reactions-${this.object.ID}`].show();
        },
        didUserReactedWithThisEmoji(id, emoji) {
            if (this.emojiObject[emoji] && this.emojiObject[emoji].Users) {
                const users = this.emojiObject[emoji].Users.filter((item) => item.ID === id);
                return users.length > 0;
            }
            return false;
        },
        insertEmoji(emoji) {
            this.$root.$emit('bv::hide::tooltip');
            const me = { ID: this.me.ID, Name: this.me.Profile.Name };
            const result = this.didUserReactedWithThisEmoji(me.ID, emoji);

            if (result == true) {
                const newArrayOfUsers = this.emojiObject[emoji].Users.filter((item) => item.ID !== me.ID); // filter return all users that match condition `item.ID !== id`
                if (newArrayOfUsers.length <= 0) {
                    Vue.delete(this.emojiObject, emoji);
                    this.toolTipUpdate(emoji);
                } else {
                    this.emojiObject[emoji].Users = newArrayOfUsers;
                    this.toolTipUpdate(emoji);
                }
                this.deleteEmoji(emoji);
            } else {
                if (Object.keys(this.emojiObject).includes(emoji) && this.emojiObject[emoji].Users) {
                    const that = this;
                    that.emojiObject[emoji].Users.push(me);
                } else {
                    Vue.set(this.emojiObject, emoji, { Users: [me] });
                }
                this.toolTipUpdate(emoji);
                this.addEmoji(emoji);
            }
            this.$refs.emo.hide();
        },
        addEmoji(emoji) {
            this.loadingForReaction = true;
            const updateEmoji = {
                Emoji: emoji,
                ObjectID: this.object.ID,
                ObjectType: this.objectType,
            };

            axios({
                method: 'POST',
                url: '/reactions',
                data: JSON.stringify(updateEmoji),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loadingForReaction = false;
                })
                .catch((error) => {
                    this.loadingForReaction = false;
                });
        },
        deleteEmoji(emoji) {
            this.loadingForReaction = true;
            const updateEmoji = {
                Emoji: emoji,
                ObjectID: this.object.ID,
                ObjectType: this.objectType,
            };

            axios({
                method: 'DELETE',
                url: '/reactions',
                data: JSON.stringify(updateEmoji),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loadingForReaction = false;
                })
                .catch((error) => {
                    this.loadingForReaction = false;
                });
        },
        toolTipUpdate(emoji) {
            let tooltip = '';
            this.toolTipTitles[emoji] = '';
            if (!this.emojiObject[emoji] || !this.emojiObject[emoji].Users) {
                return;
            }
            const arrayLength = this.emojiObject[emoji].Users.length;
            const that = this;

            if (arrayLength > 0) {
                for (let i = 0; i < this.emojiObject[emoji].Users.length; i++) {
                    if (i < arrayLength - 2) {
                        tooltip += `${this.emojiObject[emoji].Users[i].Name}, `;
                    } else if (i < arrayLength - 1) {
                        tooltip += `${this.emojiObject[emoji].Users[i].Name} and `;
                    } else {
                        tooltip += this.emojiObject[emoji].Users[i].Name;
                    }
                }
                this.toolTipTitles[emoji] = tooltip;
            } else {
                Vue.set(this.toolTipTitles, emoji, [this.me.Profile.Name]);
            }
        },
        start() {
            this.timer = setInterval(() => {
                this.elapsedTime += 100;
                if (this.elapsedTime > 500) {
                    this.stop();
                    this.showListReactions();
                }
            }, 100);
        },
        stop() {
            clearInterval(this.timer);
            this.elapsedTime = 0;
        },
    },
};
</script>
