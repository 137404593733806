




































import axios from 'axios';
import { cloneDeep } from 'lodash';
import CoCheckbox from '@/components/Atoms/co-checkbox/CoCheckbox.vue';
import CoFormGroup from '@/components/Molecules/co-form-group/CoFormGroup.vue';
import EventBus from '../../../eventBus.js';

import CoModal from '../co-modal/CoModal.vue';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoInput from '../../Molecules/co-input/CoInput.vue';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoRoundButton from '../../Atoms/co-round-button/CoRoundButton.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';

export default {
    components: {
        CoFormGroup,
        CoCheckbox,
        CoModal,
        CoHeadline,
        CoInput,
        CoButton,
        CoRoundButton,
        CoIcon,
    },
    name: 'CoChannelEdit',
    props: {
        channel: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            channelName: null,
            originalChannel: {},
            isLoading: false,
            addPagesPermission: 0,
            addPagesPermissionEveryone: true,
            addPagesPermissionAdmins: false,
        };
    },
    computed: {
        hasChanged() {
            if (this.originalChannel) {
                return (
                    this.channelName !== this.originalChannel.Value ||
                    this.addPagesPermission !== this.originalChannel.AddPagesPermission
                );
            }
            return false;
        },
    },
    watch: {
        addPagesPermissionEveryone(val) {
            this.addPagesPermission = val ? 0 : 1;
            this.addPagesPermissionAdmins = !val;
        },
        addPagesPermissionAdmins(val) {
            this.addPagesPermission = val ? 1 : 0;
            this.addPagesPermissionEveryone = !val;
        },
        channel() {
            this.channelName = this.channel ? cloneDeep(this.channel.Value) : null;
            this.channel.AddPagesPermission = this.channel.AddPagesPermission ?? 0;
            this.originalChannel = cloneDeep(this.channel);
            this.addPagesPermissionAdmins = this.channel && this.channel.AddPagesPermission === 1;
        },
    },
    methods: {
        discard() {
            this.$refs.mymodal.hide();
        },
        show() {
            this.$refs.mymodal.show();
        },
        hide() {
            this.$refs.mymodal.hide();
        },
        saveChannelName() {
            this.channel.Value = this.channelName;
            this.channel.AddPagesPermission = this.addPagesPermission;
            console.log(this.channel);
            const data = JSON.stringify(this.channel);
            console.log(data);
            this.isLoading = true;
            axios({
                method: 'PUT',
                url: '/admin/community/project-type',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    EventBus.$emit('INFO', { Message: 'Channel saved' });
                    this.$emit('submit'); // emit submit event when saved
                    this.discard();
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', { Message: 'Error while saving the channel name' });
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
