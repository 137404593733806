<template>
    <div>
        <!-- if at least on image -->
        <div v-if="images.length != 0">
            <b-aspect aspect="2:1">
                <b-img-lazy
                    :src="images[0]"
                    alt="Left image"
                    fluid-grow
                    style="height: 100% !important; object-fit: cover"
                    class="mb-0 mt-1"
                >
                </b-img-lazy>
            </b-aspect>
        </div>
        <div v-if="images.length == 3" style="height: 150px" class="d-flex">
            <img
                :src="images[1]"
                alt="Left image"
                style="width: 100%; height: 100%; object-fit: cover"
                class="mb-0 mt-1 d-inline"
            />
            <img
                :src="images[1]"
                alt="Left image"
                style="width: 100%; height: 100%; object-fit: cover"
                class="mb-0 mt-1 d-inline"
            />
        </div>
        <div v-if="images.length >= 3" style="height: 150px">
            <div class="d-flex w-100">
                <img
                    :src="images[1]"
                    alt="Left image"
                    style="width: 100%; height: 100%; object-fit: cover"
                    class="mb-0 mt-1 d-inline"
                />
                <div>
                    <img
                        :src="images[2]"
                        alt="Left image"
                        style="width: 100%; height: 100%; object-fit: cover"
                        class="mb-0 mt-1 d-inline"
                    />
                </div>
                <img
                    :src="images[3]"
                    alt="Left image"
                    style="width: 100%; height: 100%; object-fit: cover"
                    class="mb-0 mt-1 d-inline"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PhotoGrid',
    props: ['images'],
    data() {
        return {
            timer: null,
            currentIndex: 0,
        };
    },

    mounted() {},

    methods: {},
};
</script>

<style lang="less" scoped></style>
