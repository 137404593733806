<template>
    <div>
        <!-- OG/meta -->
        <vue-headful title="Create your community" description="" />
        <div class="py-4 px-3 d-flex justify-content-center align-items-md-center">
            <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
                <div class="d-flex flex-column p-3 bg-white round-unify-xs text-left creation-card">
                    <div class="label-align mb-4 ml-0 w-100 text-center">
                        <CoHeadline :level="2" class="mt-2">{{ $t('createyourcommunity') }}</CoHeadline>
                        <CoHeadline :level="2" muted>{{ $t('14daysfreetrial') }}</CoHeadline>
                        <CoText type="p3" variant="mute" class="pt-4">{{ $t('youcanchange') }}</CoText>
                    </div>
                    <div v-if="step == 1">
                        <div class="w-100">
                            <b-form @submit.stop.prevent="account">
                                <div class="d-flex">
                                    <b-form-group
                                        :label="$t('labels.namefirst')"
                                        label-for="firstname-input"
                                        label-class="label-align"
                                        class="pr-1 flex-grow-1"
                                    >
                                        <b-form-input
                                            id="firstname-input"
                                            v-model="signUpForm.FirstName"
                                            type="text"
                                            class="form-control rounded-pill"
                                            required
                                            :placeholder="$t('labels.namefirst')"
                                        ></b-form-input>
                                    </b-form-group>

                                    <b-form-group
                                        :label="$t('labels.namelast')"
                                        label-for="lastname-input"
                                        label-class="label-align"
                                        class="pl-1 flex-grow-1"
                                    >
                                        <b-form-input
                                            id="lastname-input"
                                            v-model="signUpForm.LastName"
                                            type="text"
                                            class="form-control rounded-pill"
                                            required
                                            :placeholder="$t('labels.namelast')"
                                        ></b-form-input>
                                    </b-form-group>
                                </div>

                                <b-form-group
                                    :label="$t('labels.email')"
                                    label-for="email-input"
                                    label-class="label-align"
                                >
                                    <b-form-input
                                        id="email-input"
                                        v-model="signUpForm.Email"
                                        type="email"
                                        class="form-control rounded-pill"
                                        required
                                        :placeholder="$t('placeholders.email')"
                                    ></b-form-input>
                                </b-form-group>

                                <b-form-group>
                                    <div class="mb-1">
                                        <span class="label-align">{{ $t('labels.password') }}</span
                                        ><span class="ml-2 clickable" @click="switchPasswordView"
                                            ><b-icon variant="primary" :icon="passwortViewIcon"></b-icon
                                        ></span>
                                    </div>
                                    <b-form-input
                                        id="password-input"
                                        v-model="signUpForm.Password"
                                        :type="passwortInputType"
                                        class="form-control rounded-pill"
                                        required
                                        :placeholder="$t('placeholders.password')"
                                    ></b-form-input>
                                </b-form-group>

                                <b-form-checkbox
                                    name="privacy-policy-checkbox"
                                    class="mb-4"
                                    v-model="signUpForm.AcceptPrivacyPolicy"
                                    required
                                >
                                    <i18n path="labels.acceptterms" tag="p" class="mb-0">
                                        <a
                                            class="text-primary mb-0"
                                            href="https://www.coapp.io/privacy-policy"
                                            target="_blank"
                                            :title="$t('labels.privacypolicy')"
                                            >{{ $t('labels.privacypolicy') }}</a
                                        >
                                    </i18n>
                                </b-form-checkbox>

                                <div class="text-right">
                                    <b-button variant="primary" type="submit">{{ $t('labels.next') }}</b-button>
                                </div>
                            </b-form>
                        </div>
                    </div>

                    <div v-if="step == 2">
                        <div class="w-100">
                            <div class="alert alert-danger bg-white text-orange" role="alert" v-if="error">
                                <p class="mb-0">{{ error.message }}</p>
                            </div>
                            <div>
                                <b-form-group
                                    :label="$t('communityname')"
                                    label-for="space-name-input"
                                    label-class="label-align"
                                >
                                    <b-form-input
                                        id="space-name-input"
                                        v-model="spaceItem.Name"
                                        type="text"
                                        class="form-control rounded-pill"
                                        required
                                        placeholder="e.g. Intergalactic community"
                                    ></b-form-input>
                                </b-form-group>

                                <b-form-group
                                    :label="$t('labels.timezone')"
                                    label-for="space-timezone-input"
                                    label-class="label-align"
                                >
                                    <b-form-select
                                        v-model="spaceItem.Timezone"
                                        :options="timeZonesList"
                                        class="form-control rounded-pill"
                                    ></b-form-select>
                                </b-form-group>

                                <div class="text-right">
                                    <b-button variant="outline-primary" class="mr-2" @click="back" type="button"
                                        >{{ $t('labels.back') }}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        type="submit"
                                        @click="payment"
                                        :disabled="!spaceItem.Name || spaceItem.Name.trim() === ''"
                                        >{{ $t('labels.next') }}
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="step === 3 && !paymentCheckoutError"
                        class="price-table-wrapper"
                        v-html="this.getPriceTable()"
                    ></div>
                    <div v-else-if="step === 3 && paymentCheckoutError" class="price-table-wrapper">
                        <CoAlert variant="yellow" class="mb-4">
                            <CoText type="p1">{{ $t('errorpayment') }}</CoText>
                        </CoAlert>
                    </div>
                </div>
            </b-overlay>
            <!-- user login -->
        </div>
    </div>
</template>
<style lang="less" scoped>
.creation-card {
    min-width: 450px;
    @media (max-width: 576px) {
        min-width: 0;
        width: calc(100vw - 0.5rem);
    }
}

.price-table-wrapper {
    width: calc(100vw - 256px);
    @media (max-width: 576px) {
        width: calc(100vw - 40px);
    }
}

// login view
.login-form {
    width: calc(100vw - 40px);
    @media (min-width: 576px) {
        width: calc(100vw - 40px);
    }

    /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
    @media (min-width: 768px) {
        width: calc(100vw / 2) !important;
    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {
        width: calc(100vw / 2) !important;
    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {
        width: calc(100vw / 3) !important;
    }
}

input[type='text'].form-control::placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='text'].form-control::-webkit-input-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='text'].form-control:-moz-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='text'].form-control::-ms-input-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='text'].form-control::-moz-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='email'].form-control::placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='email'].form-control::-webkit-input-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='email'].form-control:-moz-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='email'].form-control::-ms-input-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='email'].form-control::-moz-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='password'].form-control::placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='password'].form-control::-webkit-input-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='password'].form-control:-moz-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='password'].form-control::-ms-input-placeholder {
    color: #e1e1e1;
    opacity: 1;
}

input[type='password'].form-control::-moz-placeholder {
    color: #e1e1e1;
    opacity: 1;
}
</style>

<script>
import Vue from 'vue';
import smoothReflow from 'vue-smooth-reflow';
import axios from 'axios';
import momentTZ from 'moment-timezone';
import VueIntercom from 'vue-intercom';
import EventBus from '../../eventBus';
import i18n from 'vue-i18n';
import { debounce, get } from 'lodash';

import CoHeadline from '../../components/Atoms/co-headline/CoHeadline.vue';
import CoText from '../../components/Atoms/co-text/CoText.vue';
import CoAlert from '../../components/Molecules/co-alert/CoAlert.vue';

Vue.use(VueIntercom, { appId: 'kttwojyk' });

export default {
    name: 'CreateSpace',
    mixins: [smoothReflow],
    i18n: {
        messages: {
            de: {
                createyourcommunity: 'Erstelle deine Community',
                '14daysfreetrial': '14 Tage kostenlos testen',
                communityhelp: 'Dieser Name wird überall in deiner Community-Plattform verwendet',
                communitytimezone: 'Wähle die Zeitzone für deine Community',
                communityname: 'Community-Name',
                youcanchange: 'Du kannst alle Angaben später noch ändern',
                selectplan: 'Wähle deinen Tarif',
                errorpayment:
                    'Es ist Fehler beim Zahlungsvorgang aufgetreten. Bitte kontaktiere den Support über help.coapp.io.',
            },
            en: {
                createyourcommunity: 'Create your Community',
                '14daysfreetrial': '14 days free trial',
                communityhelp: 'This name will be used everywhere in your community platform',
                communitytimezone: 'Select the timezone for your community',
                communityname: 'Community Name',
                youcanchange: 'You can always change this information later',
                selectplan: 'Select your Plan',
                errorpayment: 'An error occurred during the payment process. Please contact support via help.coapp.io.',
            },
        },
    },
    components: {
        CoHeadline,
        CoText,
        CoAlert,
    },
    data() {
        return {
            timeZonesList: momentTZ.tz.names(),
            signUpForm: {
                FirstName: '',
                LastName: '',
                Email: '',
                Password: '',
                AcceptPrivacyPolicy: false,
            },
            spaceItem: {
                Name: '',
                Timezone: momentTZ.tz.guess(),
            },

            requirePayment: true,
            plan: get(this.$route, 'query.plan', 'default'),

            error: null,
            loading: false,
            step: 1,

            passwortInputType: 'password',
            passwortViewIcon: 'eye-slash',

            createdSpace: null,

            priceTable: null,
            pbKey: null,

            paymentCheckoutError: false,
            FullName: '',
        };
    },
    computed: {
        isVisible() {
            return this.$intercom.visible;
        },
    },
    beforeCreate() {
        window.onload = (event) => {
            // log matomo event
            if (window && window._paq) {
                const newSpace = sessionStorage.getItem('new-space');
                if (!newSpace) {
                    window._paq.push(['trackEvent', 'community-creation', 'community-creation-started']);
                }
            }
        };
    },
    created() {
        const newSpace = sessionStorage.getItem('new-space');
        if (newSpace) {
            const data = JSON.parse(newSpace);
            this.spaceItem = data.Space;
            delete data.Space;
            this.signUpForm = data;
            this.step = 2;
            this.plan = this.plan !== 'default' ? this.plan : get(data, 'Plan', 'default');
        }
    },
    watch: {
        step(newVal) {
            history && history.replaceState ? history.replaceState({}, '', `?step=${newVal}`) : null;
            // log matomo event
            if (window && window._paq) {
                window._paq.push(['trackEvent', 'community-creation', `community-creation-step-${newVal}`]);
            }
            // log intercom event
            this.$intercom.trackEvent(`create-community-step-${newVal}`, {
                plan: this.plan,
            });
        },
        // update intercom user data on changed form fields
        'signUpForm.FirstName'(newVal) {
            this.FullName = `${get(this, 'signUpForm.FirstName', '').trim()} ${get(
                this,
                'signUpForm.LastName',
                ''
            ).trim()}`;
        },
        'signUpForm.LastName'(newVal) {
            this.FullName = `${get(this, 'signUpForm.FirstName', '').trim()} ${get(
                this,
                'signUpForm.LastName',
                ''
            ).trim()}`;
        },
        'signUpForm.Email'(newVal) {
            this.updateIntercom();
        },
        FullName(newVal) {
            this.updateIntercom();
        },
        // show the intercom chat window if payment fails
        paymentCheckoutError(newVal) {
            if (newVal) {
                this.$intercom.trackEvent('space-creation-checkout-payment-error');
                this.$intercom.show();
            }
        },
    },
    mounted() {
        history && history.replaceState ? history.replaceState({}, '', `?step=${this.step}`) : null;
        this.$smoothReflow({
            el: '.step-wrapper',
        });
        // log intercom event
        this.$intercom.trackEvent(`create-community-started`, {
            plan: this.plan,
        });
    },
    beforeDestroy() {
        this.$intercom.shutdown();
    },
    methods: {
        getPriceTable() {
            const html = `
                <stripe-pricing-table  
                    pricing-table-id="${this.priceTable}" 
                    publishable-key="${this.pbKey}"
                    customer-email="${get(this, 'signUpForm.Email')}"
                ></stripe-pricing-table>`;
            return html;
        },
        back() {
            this.step = 1;
        },
        account() {
            this.step = 2;
        },
        switchPasswordView() {
            if (this.passwortInputType === 'password') {
                this.passwortInputType = 'text';
                this.passwortViewIcon = 'eye';
            } else {
                this.passwortInputType = 'password';
                this.passwortViewIcon = 'eye-slash';
            }
        },
        async showPlanSelection() {
            this.loading = true;

            var response = {};

            try {
                response = await axios.get('/new-space/payment/pricetable');
            } catch (error) {
                // display error message
                this.paymentCheckoutError = true;
                console.warn('Failed to get pricing table.', error);
            }

            this.priceTable = get(response, 'data.StripePriceTableID');
            this.pbKey = get(response, 'data.StripePublicKey');

            this.step = 3;

            this.loading = false;
        },
        async payment() {
            this.loading = true;
            const newSpace = this.signUpForm;
            newSpace.Space = this.spaceItem;
            newSpace.Plan = this.plan;
            sessionStorage.setItem('new-space', JSON.stringify(newSpace));

            // set the priceid for the checkout plan
            const isTest = /^(staging-app\.coapp\.io|app\.localhost)/gi.test(get(window, 'location.hostname', ''));
            let priceid = '';
            switch (this.plan) {
                case 'business':
                    priceid = isTest
                        ? 'price_1PxkGZExnt3Y3HzFIfHnd6JY' // test product business
                        : 'price_1Pxk5VExnt3Y3HzFrEuoiwZO'; // live product business
                    break;
                default:
                    priceid = isTest
                        ? 'price_1PtC6NExnt3Y3HzFSW6iaf73' // test product default professional
                        : 'price_1PxjxhExnt3Y3HzFSIOgm8Rz'; // live product default professional
                    break;
            }

            // get checkout url for plan
            var response = {};
            try {
                response = await axios({
                    // endpoint currently supported query params:  priceid, email, currency
                    method: 'GET',
                    url: `/new-space/payment/checkout/v3?priceid=${priceid}${
                        get(this, 'signUpForm.Email', false)
                            ? `&email=${encodeURIComponent(this.signUpForm.Email)}`
                            : ''
                    }${this.currency ? `&currency=${this.currency}` : ''}`,
                });
            } catch (error) {
                console.warn('Failed to get checkout URL', error);
            }
            if (get(response, 'status', 0) === 200 && get(response, 'data.URL', null)) {
                // log intercom event
                this.$intercom.trackEvent(`create-community-payment-started`, {
                    plan: this.plan,
                });
                // log matomo event
                if (window && window._paq) {
                    window._paq.push(['trackEvent', 'community-creation', 'community-creation-payment-started']);
                }
                // redirect to checkout URL
                window.location = response.data.URL;
            } else {
                // display price table as fallback
                this.showPlanSelection();
            }
        },
        updateIntercom: debounce(function (e) {
            const email = get(this, 'signUpForm.Email', '').trim();
            if (email.length && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/gi.test(email)) {
                this.$intercom.update({
                    email: email,
                    name: this.FullName,
                });
            }
        }, 500),
    },
};
</script>
