
















import { get } from 'lodash';
import { User } from '../../../types/GlobalTypes';
import CoThumbnail from '../co-thumbnail/CoThumbnail.vue';
import CoSkeleton from '../../Atoms/co-skeleton/CoSkeleton.vue';

export default {
    name: 'CoPeople',
    components: {
        CoThumbnail,
        CoSkeleton,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
        people: {
            type: Array as () => User[],
            required: false,
            default: () => [],
        },
        size: {
            type: Number,
            default: 3,
        },
    },
    computed: {
        sizeClass() {
            return `size-${this.size}`;
        },
    },
    methods: {
        get,
    },
};
