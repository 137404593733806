<template>
    <div>
        <div ref="commentel" v-if="object" :class="{ highlight: highlight }" class="d-flex align-items-center mb-3">
            <div v-if="author" class="mt-1">
                <ProfileCircle v-if="author" :ImageURL="author.Profile.ImageURL" :Slug="author.Slug"></ProfileCircle>
            </div>
            <div v-if="author" class="d-flex ml-2 w-100 align-items-baseline flex-grow-1">
                <div class="d-flex flex-column">
                    <router-link :to="'/profile/' + author.Slug" class="mt-1">{{ author.Profile.Name }}</router-link>

                    <small class="mt-1"><CoDate :date="object.CreatedAt" format="relative" /> </small>
                </div>
                <small class="ml-auto">
                    <span
                        class="link d-inline-block"
                        v-if="me.ID == author.ID || isAdmin"
                        v-b-modal="'deleting-modal' + object.ID"
                    >
                        <b-icon font-scale="1" class="mr-1 ml-4" icon="x-circle"></b-icon>{{ $t('labels.delete') }}
                    </span>
                </small>
            </div>
        </div>
        <div class="d-flex mb-2">
            <div class="d-flex ml-2 image-col"></div>
            <template>
                <div class="comment-text m-0" v-html="object.Text" v-linkifytiptap></div>
            </template>
        </div>

        <div class="d-flex align-items-center w-100">
            <div class="d-flex image-col"></div>
            <div
                v-if="object.Images && object.Images.length > 0"
                :ref="`outerBox-${object.ID}`"
                class="d-flex photos round-unify-xs overflow-hidden ml-1 mb-4"
            >
                <div class="d-flex innerBox" :ref="`innerBox-${object.ID}`">
                    <div class="d-flex">
                        <a
                            v-for="(item, index) in object.Images"
                            :key="index"
                            @click="showSlider(index)"
                            style="height: 204px; width: 100%"
                            class="mr-1"
                        >
                            <LazyLoadImgComment :src="item"></LazyLoadImgComment>
                        </a>
                    </div>
                </div>
            </div>
            <div v-show="manyPictures">
                <b-button
                    variant="primary"
                    class="btn-round mt-1 mt-lg-0 ml-4 align-items-center"
                    @click="showSlider(0)"
                >
                    <b-icon
                        icon="three-dots"
                        scale="1"
                        variant="white"
                        style="position: relative; bottom: 1px"
                        class="m-0"
                    >
                    </b-icon>
                </b-button>
            </div>
        </div>
        <!-- deleting modal -->
        <b-modal
            :id="'deleting-modal' + object.ID"
            size="sm"
            :title="$t('labels.commentdelete')"
            @ok="deleteComment"
            :ok-title="$t('labels.delete')"
            ok-variant="primary"
            :cancel-title="$t('labels.cancel')"
            centered
            :busy="deleting"
            no-fade
        >
            <b-overlay :show="deleting">
                <p class="mb-1">{{ $t('messages.deletewarning') }}</p>
            </b-overlay>
        </b-modal>
        <!-- deleting modal -->
        <!-- image slider modal -->
        <b-modal
            v-if="object.Images"
            :id="'slider' + object.ID"
            size="md"
            class="w-100"
            title=""
            hide-footer
            hide-headers
            centered
            no-fade
            content-class="bg-transparent gallery-modal"
        >
            <b-carousel
                id="carousel-1"
                v-model="slide"
                :interval="4000"
                :controls="object.Images.length > 1"
                ref="imageslider"
            >
                <b-carousel-slide :img-src="item" v-for="(item, index) in object.Images" :key="index">
                </b-carousel-slide>
            </b-carousel>
        </b-modal>
        <!-- image slider modal -->
    </div>
</template>
<style lang="less" scoped>
// styling for event time-date
//colors
@import '~@/assets/less/variables.less';

@orange: #ff4713;
@blue: #0055b8;

* {
    scroll-behavior: smooth;
}

.photos {
    overflow: hidden;
    height: 204px;
    width: 80%;
}

.highlight {
    // background: #ccc;
    border-radius: 8px;
    padding: 10px;
    margin: -10px;
    margin-top: 1rem;
    border: 1px solid @purple;
}

.photos {
    margin: 0px -1.25rem;
}

.image-col {
    width: 36px !important;
    min-width: 36px !important;
}
</style>

<script>
import Vue from 'vue';
import EventBus from '@/eventBus';
import moment from 'moment';
import linkify from 'vue-linkify';
import CoDate from '../Molecules/co-date/CoDate.vue';

Vue.directive('linkified', linkify);

export default {
    components: { CoDate },
    name: 'Comment',
    props: ['object', 'highlight'],
    data() {
        return {
            imageURLs: [],
            author: null,
            me: this.$store.state.me,
            deleting: false,
            loaded: false,
            isAdmin: false,
            slide: 0,
            manyPictures: false,
        };
    },
    mounted() {
        this.getAuthor(this.object.AuthorID);
        this.isAdmin = this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin');

        if (this.object && this.object.Images) {
            // check for images width when object is loaded
            this.checkWidth();
        }
    },
    updated() {
        if (this.author) {
            this.loaded = true;
            this.$emit('loaded');
        }
    },

    methods: {
        // time interval function that checks whether the width of total images exceed the outerbox width.
        // clear interval when clientWidth has loaded
        checkWidth() {
            const that = this;
            var interval = setInterval(() => {
                const innerBox = that.$refs[`innerBox-${that.object.ID}`];
                const outerBox = that.$refs[`outerBox-${that.object.ID}`];
                if (innerBox && outerBox) {
                    if (innerBox.clientWidth > outerBox.clientWidth) {
                        that.manyPictures = true;
                        clearInterval(interval);
                    } else if (innerBox.clientWidth <= outerBox.clientWidth) {
                        that.manyPictures = false;
                        clearInterval(interval);
                    }
                }
            }, 500);
        },
        deleteComment(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();

            this.deleting = true;
            const req = JSON.stringify(this.object);
            if (this.me.ID == this.author.ID) {
                this.$store
                    .dispatch('deleteComment', req)
                    .then((response) => {
                        this.deleting = false;
                        const message = {
                            Message: 'Comment deleted',
                            Details: null,
                        };
                        EventBus.$emit('INFO', message);
                        // EventBus.$emit('RELOADCOMMENTS', null);
                        this.$emit('comment-deleted');
                        this.$bvModal.hide(`deleting-modal${this.object.ID}`);
                    })
                    .catch((error) => {
                        this.deleting = false;
                        if (error.response.data && error.response.data.message) {
                            EventBus.$emit('ERROR', {
                                Message: error.response.data.message,
                                Details: error.response.data.details,
                            });
                        } else {
                            EventBus.$emit('ERROR', {
                                Message: 'Could not delete comment. Please try again later.',
                                Details: null,
                            });
                        }
                    });
            } else if (this.isAdmin) {
                this.$store
                    .dispatch('deleteCommentAdmin', req)
                    .then((response) => {
                        this.deleting = false;
                        const message = {
                            Message: 'Comment deleted',
                            Details: null,
                        };
                        EventBus.$emit('INFO', message);
                        // EventBus.$emit('RELOADCOMMENTS', null);
                        this.$emit('comment-deleted');
                        this.$bvModal.hide(`deleting-modal${this.object.ID}`);
                    })
                    .catch((error) => {
                        this.deleting = false;
                        if (error.response.data && error.response.data.message) {
                            EventBus.$emit('ERROR', {
                                Message: error.response.data.message,
                                Details: error.response.data.details,
                            });
                        } else {
                            EventBus.$emit('ERROR', {
                                Message: 'Could not delete comment. Please try again later.',
                                Details: null,
                            });
                        }
                    });
            }
        },
        getAuthor(id) {
            const ids = JSON.stringify({ IDS: [{ ID: id }] });
            this.$store
                .dispatch('listUsersByIDs', { ids })
                .then((response) => {
                    if (response.Users) {
                        this.author = response.Users[0];
                    } else {
                        this.author = {
                            ID: '',
                            Slug: '',
                            Profile: {
                                Name: 'Unknown',
                            },
                        };
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // todo show error
                });
        },
        showSlider(index) {
            this.slide = index;
            this.$bvModal.show(`slider${this.object.ID}`);
        },
    },
};
</script>
