


























































































































































import axios from 'axios';
import { get, remove } from 'lodash';
import { MarketItem, User } from '../../../types/GlobalTypes';
import CoImage from '../../Atoms/co-image/CoImage.vue';
import CoText from '../../Atoms/co-text/CoText.vue';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoSkeleton from '../../Atoms/co-skeleton/CoSkeleton.vue';
import CoThumbnail from '../../Molecules/co-thumbnail/CoThumbnail.vue';
import CoDate from '../co-date/CoDate.vue';
import CoPill from '../../Atoms/co-pill/CoPill.vue';
import CoLink from '../../Atoms/co-link/CoLink.vue';
import EventBus from '../../../eventBus';
import CoConfirmation from '../co-confirmation/CoConfirmation.vue';

export default {
    components: {
        CoSkeleton,
        CoHeadline,
        CoButton,
        CoText,
        CoImage,
        CoThumbnail,
        CoDate,
        CoPill,
        CoLink,
        CoConfirmation,
    },
    name: 'CoMarketItem',
    props: {
        marketItem: {
            type: Object as () => MarketItem,
            required: true,
        },
        variant: {
            type: String,
            validator: (value: string) => ['default', 'micro', 'list', 'full'].includes(value),
            default: 'default',
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
        me: {
            type: Object,
            required: false,
        },
        link: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            meLocal: get(this, 'me', null) || {
                ...get(this.$store, 'state.me', {}),
                ...get(this.$store, 'state.me.Profile', {}),
            },
            isMine: false,
        };
    },
    computed: {
        amIinterested() {
            return (
                this.meLocal &&
                this.marketItem.Interests &&
                this.marketItem.Interests.some((interest) => interest.UserID === this.meLocal.ID)
            );
        },
    },
    mounted() {
        //set amIattending and amIorganising
        // this.amIinterested = this.meLocal && this.event.Participants && this.event.Participants.some((user) => user.ID === this.meLocal.ID);
        this.isMine = this.meLocal && get(this.marketItem, 'AuthorID', null) === this.meLocal.ID;
    },
    methods: {
        get(object, path, defaultValue?: String) {
            return get(object, path, defaultValue);
        },
        submitInterest() {
            if (!this.meLocal) return;

            this.$emit('submit', this.marketItem);
            this.marketItem.Interests
                ? this.marketItem.Interests.unshift(this.meLocal)
                : (this.marketItem.Interests = [this.meLocal]);
            axios({
                method: 'POST',
                url: '/job/interest',
                data: {
                    UserID: get(this.meLocal, 'ID', ''),
                    JobID: get(this.marketItem, 'ID', ''),
                },
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    EventBus.$emit('INFO', {
                        Message: this.$t('labels.interestedsent'),
                    });
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: 'Your request could not be saved',
                        Details: '',
                    });
                });
        },
    },
};
