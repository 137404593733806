var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"co input"},[(_vm.label)?_c('CoLabel',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"d-inline-flex"},[(_vm.icon)?_c('CoIcon',{staticClass:"icon align-self-center",attrs:{"name":_vm.icon}}):_vm._e(),((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"coInputElem",staticClass:"align-self-center",class:{
                'has-icon': _vm.icon,
                disabled: _vm.disabled,
            },attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"type":"checkbox"},domProps:{"value":_vm.value,"checked":Array.isArray(_vm.inputValue)?_vm._i(_vm.inputValue,_vm.value)>-1:(_vm.inputValue)},on:{"keydown":_vm.getAutoCompleteItems,"blur":_vm.resetValue,"input":function($event){return _vm.$emit('input', _vm.inputValue)},"change":function($event){var $$a=_vm.inputValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputValue=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"coInputElem",staticClass:"align-self-center",class:{
                'has-icon': _vm.icon,
                disabled: _vm.disabled,
            },attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"type":"radio"},domProps:{"value":_vm.value,"checked":_vm._q(_vm.inputValue,_vm.value)},on:{"keydown":_vm.getAutoCompleteItems,"blur":_vm.resetValue,"input":function($event){return _vm.$emit('input', _vm.inputValue)},"change":function($event){_vm.inputValue=_vm.value}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"coInputElem",staticClass:"align-self-center",class:{
                'has-icon': _vm.icon,
                disabled: _vm.disabled,
            },attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"type":_vm.type},domProps:{"value":_vm.value,"value":(_vm.inputValue)},on:{"keydown":_vm.getAutoCompleteItems,"blur":_vm.resetValue,"input":[function($event){if($event.target.composing){ return; }_vm.inputValue=$event.target.value},function($event){return _vm.$emit('input', _vm.inputValue)}]}}),((_vm.autocompleteItems || _vm.autocompleteLoading) && !_vm.objects)?_c('CoDropdownList',{attrs:{"firstItem":_vm.inputValue,"loading":_vm.autocompleteLoading,"items":_vm.autocompleteItems,"selected":_vm.selected},on:{"itemClicked":_vm.autoCompleteSelected}}):_vm._e(),(_vm.autocompleteItems && _vm.objects)?_c('CoDropdownListObjects',{attrs:{"items":_vm.autocompleteItems},on:{"itemClicked":_vm.autoCompleteSelectedObject}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }