<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <div>
        <!-- search and actions start -->
        <div class="px-sm-4 d-flex align-items-center justify-content-between">
            <div class="align-self-start d-flex align-items-center bg-white search-bar-wrapper rounded-pill">
                <b-icon class="mr-2" icon="search"></b-icon>
                <div class="search-input-wrapper">
                    <input
                        class="search-input"
                        type="search"
                        v-model="tableFilter"
                        placeholder="Search"
                        aria-label="Search"
                    />
                </div>
            </div>

            <div class="d-block d-sm-flex">
                <b-button variant="primary" @click="createCategory">Create</b-button>
            </div>
        </div>
        <!-- search and actions end -->

        <div class="px-sm-4">
            <div class="mt-4">
                <b-table
                    striped
                    hover
                    :items="objects"
                    :fields="fields"
                    :per-page="perPage"
                    sort-icon-left
                    stacked="md"
                    :filter="tableFilter"
                    :current-page="currentPage"
                    @filtered="onFiltered"
                    class="table table-borderless table-responsive w-100 d-md-table"
                >
                    <template v-slot:cell(Actions)="row">
                        <b-button size="sm" @click="editCategory(row.item.ID)" class="mr-2">View</b-button>
                        <b-button size="sm" class="mr-2" variant="danger" @click="deleteCategory(row.item)"
                            >Delete</b-button
                        >
                    </template>
                </b-table>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    aria-controls="my-table"
                ></b-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';

export default {
    name: 'ResourceCategories',
    data() {
        return {
            objects: [],
            name: '',
            nameState: null,
            submittedNames: [],
            loading: false,

            // table model
            tableFilter: '',
            perPage: 20,
            currentPage: 1,
            // searchFilterIncludesFields: ['object.title', 'Title', 'Name', 'title', 'name'],
            totalRows: 0,

            fields: [
                {
                    key: 'Name',
                    label: 'Name',
                    sortable: true,
                },
                {
                    key: 'Actions',
                    label: 'Actions',
                },
            ],
        };
    },
    created() {
        this.getCategories();
    },
    mounted() {},
    methods: {
        createCategory() {
            this.$router.push('/admin/booking/categories/create');
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        getCategories() {
            axios({
                method: 'GET',
                url: '/admin/booking/category/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data && response.data.Categories) {
                        this.objects = response.data.Categories;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        editCategory(id) {
            this.$router.push(`/admin/booking/categories/edit/${id}`);
        },

        deleteCategory(obj) {
            this.loading = true;

            const data = JSON.stringify(obj);
            axios({
                method: 'DELETE',
                url: '/admin/booking/category',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    const message = {
                        Message: 'Booking category deleted',
                        Details: '',
                    };
                    EventBus.$emit('INFO', message);

                    this.getCategories();
                })
                .catch((error) => {
                    console.log(error);
                    // todo error processing
                    this.loading = false;
                    const message = {
                        Message: 'Could not delete booking category',
                        Details: '',
                    };
                    EventBus.$emit('ERROR', message);
                });
        },
    },
};
</script>
