<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <div class="d-flex pb-5">
        <div class="mx-auto flex-grow-1" style="max-width: 600px">
            <!-- search and actions start -->
            <div class="px-sm-4 d-flex align-items-center justify-content-between">
                <coInput icon="search" type="text" placeholder="type to search" v-model="search" />
                <b-button variant="primary" v-b-modal.modal-create-project-type>Add channel</b-button>
            </div>
            <!-- search and actions end -->
            <div>
                <div v-for="object in filtered" :key="object.ID" class="bg-white round-unify p-3 mt-3 overflow-visible">
                    <div class="d-flex">
                        <h2>{{ object.Value }}</h2>
                        <b-dropdown
                            variant="ml-2 p-0"
                            class="d-menu ml-auto"
                            menu-class="mt-0 border-1"
                            no-caret
                            right
                            round
                            style="position: relative"
                        >
                            <template v-slot:button-content>
                                <b-icon icon="three-dots" class="m-0" scale="1.2"></b-icon>
                            </template>
                            <b-dropdown-item class="d-danger" @click="editChannel(object)">Edit</b-dropdown-item>
                            <b-dropdown-item class="d-danger" @click="showDeleteModal(object)">Delete</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
                <div v-if="search && filtered.length == 0">
                    <div class="my-3">No results found for "{{ search }}"</div>
                </div>
                <div v-if="objects.length == 0" class="my-3">You don't have any channel yet. Create some.</div>
            </div>
            <!-- Modals -->
            <!-- Create -->
            <b-modal
                id="modal-create-project-type"
                ref="modal"
                title="New Channel"
                @show="resetCreateModal"
                @hidden="resetCreateModal"
                @ok="handelCreateModalOk"
                centered
            >
                <form ref="createform" @submit.stop.prevent="handleCreateSubmit">
                    <b-form-group
                        :state="nameState"
                        label="Channel name"
                        label-for="name-input"
                        label-class="label-align"
                        invalid-feedback="Name is required"
                    >
                        <b-form-input
                            id="name"
                            v-model="name"
                            placeholder="Name (Project, StartUp, MeetUp)"
                            class="rounded-pill"
                            required
                        ></b-form-input>
                    </b-form-group>
                </form>
                <b-spinner v-if="loading" variant="success" label="Spinning"></b-spinner>
            </b-modal>

            <!-- deleting modal -->
            <b-modal
                id="deleting-modal"
                size="md"
                title="Delete channel"
                @ok="deleteProjectType"
                ok-title="Delete"
                ok-variant="primary"
                centered
                :busy="deleting"
                no-fade
            >
                <b-overlay :show="deleting">
                    <p class="mb-1">
                        Once you delete a channel, there is no going back. Please move all pages that use this channel
                        to another channel.
                    </p>
                </b-overlay>
            </b-modal>
            <!-- deleting modal -->
            <!-- editing modal -->
            <coChannelEdit ref="channelEdit" :channel="toEdit" @submit="listProjectTypes()" />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { sortBy } from 'lodash';
import Vue from 'vue';

export default {
    name: 'ProjectTypes',

    data() {
        return {
            search: '',
            filtered: [],
            objects: [],

            loading: false,
            deleting: false,

            // create form data
            name: '',
            nameState: null,

            toDelete: null,
            toEdit: null,
        };
    },
    computed: {},
    created() {},
    mounted() {
        this.listProjectTypes();
    },
    watch: {
        search() {
            this.filter();
        },
    },
    methods: {
        filter() {
            this.filtered = this.objects.filter((obj) => obj.Value.toLowerCase().includes(this.search.toLowerCase()));
        },
        editChannel(obj) {
            this.toEdit = obj;
            this.$refs.channelEdit.show();
        },
        showDeleteModal(obj) {
            this.toDelete = obj;
            this.$bvModal.show('deleting-modal');
        },

        listProjectTypes() {
            this.objects = [];
            axios({
                method: 'GET',
                url: '/admin/community/project-type/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data && response.data.objects && response.data.objects.length != 0) {
                        response.data.objects.forEach((element) => {
                            this.objects.push(element.object);
                        });
                        this.objects = sortBy(this.objects, [(channel) => channel.Value.toLowerCase()], ['desc']);
                        this.filter();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        deleteProjectType(object) {
            object.preventDefault();
            this.deleting = true;
            const data = JSON.stringify(this.toDelete);
            axios({
                method: 'DELETE',
                url: '/admin/community/project-type',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.deleting = false;
                    this.$bvModal.hide('deleting-modal');
                    this.listProjectTypes();
                })
                .catch((error) => {
                    this.deleting = false;
                    this.$bvModal.hide('deleting-modal');
                    console.log(error);
                });
        },

        createProjectType(obj) {
            const data = JSON.stringify(obj);

            axios({
                method: 'POST',
                url: '/admin/community/project-type',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.name = '';
                    this.listProjectTypes();

                    this.$nextTick(() => {
                        this.$bvModal.hide('modal-create-project-type');
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        resetCreateModal(event) {
            this.name = '';
        },

        handelCreateModalOk(event) {
            // Prevent modal from closing
            event.preventDefault();
            // Trigger submit handler
            this.handleCreateSubmit();
        },
        handleCreateSubmit(event) {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }

            this.createProjectType({ Value: this.name });
        },
        checkFormValidity() {
            const valid = this.$refs.createform.checkValidity();
            this.nameState = valid;
            return valid;
        },
    },
};
</script>
