




















export default {
    name: 'CoDate',
    props: {
        date: {
            type: [Date, Number, String],
        },
        format: {
            type: String,
        },
        locale: {
            type: String,
        },
        customFormat: {
            type: String,
        },
    },
};
