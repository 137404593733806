<template>
    <div class="container col-np-mobile pb-5">
        <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
            <b-card>
                <h2>{{ $t('labels.marketitemedit') }}</h2>
                <b-form @submit.stop.prevent="update">
                    <div class="mt-4 mb-2">{{ $t('labels.summary') }}</div>
                    <b-form-input
                        :placeholder="$t('placeholders.marketitemtitle')"
                        class="rounded-pill"
                        v-model="item.Title"
                        required
                        autofocus
                    ></b-form-input>

                    <div class="mt-4 mb-2">
                        {{ $t('labels.summary') }} ({{ $t('messages.maxcharacters', { count: '200' }) }})
                    </div>
                    <b-form-textarea
                        id="textarea-default"
                        :placeholder="$t('placeholders.marketitemsummary')"
                        rows="3"
                        class="create-post-textarea"
                        v-model="item.Summary"
                        required
                    ></b-form-textarea>

                    <div class="mt-4 mb-2">{{ $t('labels.description') }}</div>
                    <b-form-textarea
                        id="textarea-default"
                        :placeholder="$t('placeholders.marketitemdescription')"
                        rows="5"
                        class="create-post-textarea"
                        v-model="item.Description"
                        required
                    ></b-form-textarea>
                    <div class="mt-4 mb-2">{{ $t('labels.category') }}</div>
                    <b-form-group id="select-group-1">
                        <b-form-select
                            id="Category"
                            v-model="category"
                            class="form-select rounded-pill"
                            :options="categoryOptions"
                            required
                        >
                        </b-form-select>
                    </b-form-group>
                    <div class="mt-4 mb-2">
                        {{
                            this.$unleash.isEnabled('frontend.tags-are-called-topics')
                                ? $t('labels.topics')
                                : $t('labels.tags')
                        }}
                    </div>
                    <div>
                        <vue-tags-input
                            id="tags"
                            v-model="tag"
                            :tags="tags"
                            :separators="separators"
                            :autocomplete-items="autocompleteItems"
                            :add-only-from-autocomplete="false"
                            @tags-changed="updateTags"
                            @before-adding-tag="addTag"
                        />
                    </div>

                    <div class="mt-4 mb-2">{{ $t('labels.contact') }}</div>
                    <b-form-input
                        :placeholder="$t('placeholders.marketitemcontact')"
                        class="rounded-pill"
                        v-model="item.ContactMethod"
                    ></b-form-input>

                    <div class="mt-4 mb-2">{{ $t('labels.image') }}</div>
                    <div
                        class="d-flex w-100 create-post-textarea border mb-4 align-items-center justify-content-between"
                        style="cursor: pointer"
                    >
                        <div class="text-muted p-2 mb-0">{{ $t('placeholders.image') }}</div>
                        <div class="upload">
                            <button class="btn">
                                <b-icon variant="primary" scale="1.5" icon="images"></b-icon>
                            </button>
                            <input
                                type="file"
                                id="picture"
                                name="picture"
                                @change="previewFiles"
                                accept=".jpg,.jpeg,.png"
                                class="form-control-file pointer"
                            />
                        </div>
                    </div>
                    <div class="mb-4">
                        <small>{{ $t('messages.imageguidelines') }} </small>
                    </div>

                    <div class="align-content-top d-flex position-relative" v-if="imageURL">
                        <b-img class="preview-img p-0" thumbnail fluid width="150" height="150" :src="imageURL"></b-img>
                        <div class="delete">
                            <b-icon
                                icon="x-circle"
                                variant="danger pointer"
                                scale="1.75"
                                v-on:click="deletePreview(index)"
                            ></b-icon>
                        </div>
                    </div>

                    <div class="d-flex justify-content-end mt-3 mb-4 pb-4">
                        <!-- <b-button variant="outline-danger"
                  @click="alert('todo')">close</b-button> -->
                        <div>
                            <b-button variant="outline-primary" class="mr-md-2 mr-1" @click="cancel"
                                >{{ $t('labels.cancel') }}
                            </b-button>
                            <b-button type="submit" variant="primary" :disabled="item.Title == ''"
                                >{{ $t('labels.save') }}
                            </b-button>
                        </div>
                    </div>
                </b-form>
            </b-card>
        </b-overlay>
    </div>
</template>
<style lang="less" scoped>
.create-post-textarea {
    border-radius: 18px;
    border: 1px solid #ced4da;
    position: relative;
}

.preview-img {
    border-radius: 18px;
    border-top-right-radius: 0px;
}

.upload {
    position: static;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
    min-width: 60px;
}

.upload input[type='file'] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    font-size: 36px;
    z-index: 2;
    cursor: pointer;
}

.attachments {
    display: block;
    width: 100%;
}

.attachments .item {
    margin: 0px 5px 0px 5px;
    overflow: hidden;
    background: #212121;
    display: inline-block;
    background-color: #cccccc;
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100px;
    padding-top: 100px;
    border-radius: 7px;
    position: relative;
}

.delete {
    position: relative;
    top: 8px;
    top: -12px;
    left: -29px;
    left: -9px;
    // right: 0;
    font-size: 1em !important;
    line-height: 1.5em !important;
}
</style>
<script>
import axios from 'axios';
import Vue from 'vue';
import VueTagsInput from '@johmun/vue-tags-input';
import EventBus from '../../eventBus';

export default {
    name: 'EditItem',
    components: {
        VueTagsInput,
    },
    data() {
        return {
            item: {
                Title: '',
                Summary: '',
                Description: '',
                ContactMethod: '',
            },
            image: null,
            category: '',
            imageURL: null,
            tag: '',
            tags: [],
            autocompleteItems: [],
            debounce: null,
            separators: [';', ',', '#', ', #'],
            loading: false,
            categoryOptions: [
                { value: 0, text: 'Job' },
                { value: 1, text: 'Service' },
                { value: 2, text: 'Product' },
            ],
        };
    },
    watch: {
        tag: 'loadAutocompleteItems',
    },
    mounted() {
        this.getItem();
    },
    computed: {},
    methods: {
        cancel() {
            this.$router.push(`/job/${this.$route.params.slug}`);
        },
        getItem() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/job/${this.$route.params.slug}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.item = response.data;
                    if (this.item.ImageURL) {
                        this.imageURL = this.item.ImageURL;
                    }
                    this.loading = false;

                    if (this.item.Category === undefined) {
                        this.category = 0;
                    } else {
                        this.category = this.item.Category;
                    }

                    this.loadTags();
                })
                .catch((error) => {
                    if (error.message.includes('404')) {
                        this.$router.replace('/feed/error404');
                    }
                });
        },

        updateItem() {
            const tags = [];
            this.tags.forEach((entry) => {
                tags.push(entry.slug);
            }, tags);
            this.item.Tags = tags;
            this.item.Category = this.category;

            axios({
                method: 'PUT',
                url: '/job',
                data: JSON.stringify(this.item),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Market item updated',
                        Details: '',
                    };
                    EventBus.$emit('INFO', msg);
                    this.$router.push(`/job/${this.item.Slug}`);
                })
                .catch((error) => {
                    this.loading = false;
                    EventBus.$emit('ERROR', {
                        Message: 'Could not update market item! Please, report to team',
                        Details: null,
                    });
                });
        },

        uploadImages() {
            this.$store
                .dispatch('imageUpload', {
                    file: this.image,
                    url: '/upload/image/jobs',
                })
                .then((response) => {
                    this.item.ImageURL = response;
                    this.updateItem();
                })
                .catch((error) => {
                    const errorMsg = {
                        Message: 'Could not upload one or more images, please try again or check details',
                        Details: error.response.data,
                    };
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.message === 'Request Entity Too Large'
                    ) {
                        errorMsg.Message = 'The file you tried to upload is too large. Max 10 MB.';
                    }
                    EventBus.$emit('ERROR', errorMsg);
                });
        },

        update(event) {
            event.preventDefault();
            this.loading = true;

            if (this.image) {
                this.uploadImages();
                return;
            }
            this.updateItem();
        },

        previewFiles(event) {
            // set file preview
            (this.image = event.target.files[0]), (this.imageURL = URL.createObjectURL(event.target.files[0]));
        },
        deletePreview(index) {
            this.image = null;
            this.imageURL = null;
        },
        /**
         * addTag - creates new tag if user types custom one and hit enter
         */
        addTag(obj) {
            const tag = JSON.stringify({ Value: obj.tag.text });
            this.$store
                .dispatch('createTag', tag)
                .then((response) => {
                    const tag = {
                        text: response.Value,
                        slug: response.Slug,
                    };
                    this.tags.push(tag);
                    this.tag = '';
                    return true;
                })
                .catch((error) => {
                    const message = {
                        Message: `Failed to create tag \`${obj.tag.text}\``,
                        Details: error,
                    };
                    EventBus.$emit('ERROR', message);
                    return false;
                }, obj);
        },
        updateTags(newTags) {
            this.autocompleteItems = [];
            this.tags = newTags;
        },
        loadAutocompleteItems() {
            this.autocompleteItems = [];
            if (this.tag.length < 2) return;
            this.$store
                .dispatch('searchTag', this.tag)
                .then((response) => {
                    response.forEach(function (entry) {
                        const tag = {
                            text: entry.Slug,
                            slug: entry.Slug,
                        };
                        this.autocompleteItems.push(tag);
                    }, this);
                    return true;
                })
                .catch((error) => {
                    console.log('error: ', error);
                    return false;
                });
        },
        /**
         * loadTags - loads initial tags
         */
        loadTags() {
            if (this.item.Tags == null) {
                return;
            }
            const filtered = this.item.Tags.filter((el) => el != '');
            const IDs = JSON.stringify({ IDs: filtered });
            this.$store
                .dispatch('getTagsByIDs', IDs)
                .then((response) => {
                    response.forEach(function (entry) {
                        const tag = {
                            text: entry.Value,
                            slug: entry.Slug,
                        };
                        this.tags.push(tag);
                    }, this);
                })
                .catch((error) => {
                    const message = {
                        Message: 'Failed to load tags for market item',
                        Details: error,
                    };
                    EventBus.$emit('ERROR', message);
                });
        },
    },
};
</script>
