const EN = {
    messages: {
        addPlanResourceCTA: 'Add Resource',
        addPlanResourceGroupCTA: 'Add Resource Group',
        addPlanResourceModalTitle: 'Add booking resource',
        addPlanResourceModalTitleGroup: 'Add booking resource group',
        noResourcesFound: 'No resources found',
    },
};
export default EN;
