<style lang="less" scoped>
@import '~@/assets/less/variables.less';

.message-time {
    position: relative;
    top: 4px;
}

.message-text {
    display: block;
    line-height: 1.375;
}

.message-group-left-margin {
    margin-left: 2.5rem;
}

.message-group-right-margin {
    margin-right: 2.5rem;
}

.username {
    padding-left: 10px;
    padding-right: 10px;
    align-self: start;

    &.right {
        align-self: end;
    }
}

.message {
    background: @co-lightgrey;
    padding: 10px;
    border-radius: 8px;
    align-self: start;

    @media (min-width: 500px) {
        max-width: 80%;
    }

    &.right {
        align-self: end;
    }
}
</style>
<style>
.message pre {
    white-space: pre-wrap;
    color: unset;
}
</style>
<template :key="message.ID">
    <div class="message-w">
        <div class="d-flex justify-content-center mb-3" v-if="message.isNewDay">
            <div>
                <small>
                    <coDate :date="message.date._d" format="relativedate" />
                </small>
            </div>
        </div>
        <div v-if="!isMe && isGroup && showAvatar && author.Profile" class="mt-3">
            <coText :text="author.Profile.Name" variant="mute" type="p3" class="mb-1" style="padding-left: 2.625rem" />
        </div>
        <div
            class="d-flex w-100 mb-0 align-items-start mb-1"
            :class="{
                'justify-content-end': isMe,
                'mt-3': showAvatar && !isGroup && !isMe,
            }"
        >
            <ProfileCircle v-if="!author && showAvatar && !isMe" />
            <ProfileCircle
                v-else-if="author && showAvatar && !isMe"
                :ImageURL="author.Profile.ImageURL"
                :Slug="author.Slug"
                class="mr-1"
            />
            <div
                class="message no-paragraph-margins py-1"
                :class="{
                    right: isMe,
                    'message-group-left-margin': !showAvatar && !isMe,
                    'message-group-right-margin': !showAvatar && isMe,
                }"
            >
                <div class="d-flex flex-column" v-html="message.text" v-linkifytiptap></div>
                <div>
                    <span
                        @click="retry()"
                        class="pointer message-time float-left small d-inline-block mb-1 text-danger"
                        v-if="message.sendingFailed"
                        >{{ $t('senderror') }}</span
                    >
                    <span class="message-time float-left small d-inline-block mb-1" v-else-if="isMe">{{ status }}</span>
                    <span class="message-time float-right small d-inline-block mb-1" :class="{ 'ml-2': isMe }">
                        <coDate :date="message.date._d" format="time" />
                    </span>
                </div>
            </div>
            <ProfileCircle v-if="!author && showAvatar && isMe"></ProfileCircle>
            <ProfileCircle v-if="showAvatar && showAvatar && isMe" :ImageURL="author.Profile.ImageURL" class="ml-1">
            </ProfileCircle>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import i18n from 'vue-i18n';

export default {
    i18n: {
        messages: {
            en: {
                senderror: 'Sending failed! Tap to retry.',
            },
            de: {
                senderror: 'Senden fehlgeschlagen! Klicken zum erneuten Senden',
            },
        },
    },
    name: 'Message',
    props: {
        message: {
            type: Object,
            required: true,
        },
        author: {
            type: Object | null | undefined,
            required: true,
        },
        threadID: {
            type: String,
            required: true,
        },
        showAvatar: {
            type: Boolean,
            default: false,
        },
        sendMessage: {
            type: Boolean,
        },
        isGroup: {
            type: Boolean,
        },
    },

    computed: {
        isMe() {
            if (this.message) {
                return this.message.sender === this.$store.state.me.ID;
            }
            return false;
        },
        status() {
            return this.message.status;
        },
    },
    data() {
        return {};
    },
    methods: {
        retry() {
            this.$emit('retry');
        },
    },
};
</script>
