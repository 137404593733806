// this function can be used to update the css color variables on :root selector
export default function (data) {
    // the following function works in the same way as LESS/SASS darken / lighten functions.
    // Colors can be made darker or lighter with it, by giving parameter p a value between -1.0 (dark) and 1.0 (light)
    // We use the function in the code after it, to generate a darker and a lighter version of every color, for example for hover effects.
    // For a complete documentation, check out: https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)
    function pSBC(p, c0, c1, l) {
        let r;
        let g;
        let b;
        let P;
        let f;
        let t;
        let h;
        const i = parseInt;
        const m = Math.round;
        let a = typeof c1 === 'string';
        if (
            typeof p !== 'number' ||
            p < -1 ||
            p > 1 ||
            typeof c0 !== 'string' ||
            (c0[0] != 'r' && c0[0] != '#') ||
            (c1 && !a)
        )
            return null;
        if (!window.pSBCr)
            window.pSBCr = (d) => {
                let n = d.length;
                const x = {};
                if (n > 9) {
                    ([r, g, b, a] = d = d.split(',')), (n = d.length);
                    if (n < 3 || n > 4) return null;
                    (x.r = i(r[3] == 'a' ? r.slice(5) : r.slice(4))),
                        (x.g = i(g)),
                        (x.b = i(b)),
                        (x.a = a ? parseFloat(a) : -1);
                } else {
                    if (n == 8 || n == 6 || n < 4) return null;
                    if (n < 6) d = `#${d[1]}${d[1]}${d[2]}${d[2]}${d[3]}${d[3]}${n > 4 ? d[4] + d[4] : ''}`;
                    d = i(d.slice(1), 16);
                    if (n == 9 || n == 5)
                        (x.r = (d >> 24) & 255),
                            (x.g = (d >> 16) & 255),
                            (x.b = (d >> 8) & 255),
                            (x.a = m((d & 255) / 0.255) / 1000);
                    else (x.r = d >> 16), (x.g = (d >> 8) & 255), (x.b = d & 255), (x.a = -1);
                }
                return x;
            };
        (h = c0.length > 9),
            (h = a ? (c1.length > 9 ? true : c1 == 'c' ? !h : false) : h),
            (f = window.pSBCr(c0)),
            (P = p < 0),
            (t =
                c1 && c1 != 'c'
                    ? window.pSBCr(c1)
                    : P
                    ? { r: 0, g: 0, b: 0, a: -1 }
                    : { r: 255, g: 255, b: 255, a: -1 }),
            (p = P ? p * -1 : p),
            (P = 1 - p);
        if (!f || !t) return null;
        if (l) (r = m(P * f.r + p * t.r)), (g = m(P * f.g + p * t.g)), (b = m(P * f.b + p * t.b));
        else
            (r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5)),
                (g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5)),
                (b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5));
        (a = f.a), (t = t.a), (f = a >= 0 || t >= 0), (a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0);
        if (h) return `rgb${f ? 'a(' : '('}${r},${g},${b}${f ? `,${m(a * 1000) / 1000}` : ''})`;
        return `#${(4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0))
            .toString(16)
            .slice(1, f ? undefined : -2)}`;
    }

    // function to calculate HSL value from HEX
    // HSL is used to generate foreground colors
    // to make sure text on coloured backgrounds is always readable
    function toHSL(hex) {
        if (!hex) return false;

        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        var r = parseInt(result[1], 16);
        var g = parseInt(result[2], 16);
        var b = parseInt(result[3], 16);

        (r /= 255), (g /= 255), (b /= 255);
        var max = Math.max(r, g, b),
            min = Math.min(r, g, b);
        var h,
            s,
            l = (max + min) / 2;

        if (max == min) {
            h = s = 0; // achromatic
        } else {
            var d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
            h /= 6;
        }

        s = s * 100;
        s = Math.round(s);
        l = l * 100;
        l = Math.round(l);
        h = Math.round(360 * h);

        var colorInHSL = 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
        return {
            string: colorInHSL,
            h: h,
            s: s,
            l: l,
        };
    }

    try {
        // same as :root selector, where the css color variables are stored
        const root = document.documentElement;

        // for each color given in the input array
        data.forEach((element) => {
            //  if element or element.value is missing, we skip the iteration
            if (!element || !element.value) return;
            // we calculate the HSL value of the color
            const color = element.value.replace(/\s/g, '');
            const hsl = toHSL(color) || false;

            // we update the corresponding css variable (see variables.less) with the new color value
            root.style.setProperty('--c-' + element.name, color);
            // provide HSL values to be able to calculate coresponding foreground color with high contrast (black/white)
            if (hsl) {
                root.style.setProperty('--c-' + element.name + '-hsl', hsl.string);
                root.style.setProperty('--c-' + element.name + '-h', hsl.h + '%');
                root.style.setProperty('--c-' + element.name + '-s', hsl.s + '%');
                root.style.setProperty('--c-' + element.name + '-l', hsl.l + '%');
            }
            // we generate dark and light versions of the color value and update the corresponding css variables
            root.style.setProperty('--c-' + element.name + '-dark', pSBC(-0.4, color));
            root.style.setProperty('--c-' + element.name + '-light', pSBC(0.4, color));
        });
    } catch (error) {
        console.warn('Error while setting custom colors', error);
    }
}
