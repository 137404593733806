<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <router-link tag="a" :to="'/admin/monetization/time-passes/view/' + timepass.ID" class="timepass-card-wrapper">
        <div class="timepass-card px-4 pt-3 pb-2 round-unify-xs">
            <div class="d-flex justify-content-between align-items-center mb-2">
                <div class="d-flex w-100 justify-content-between">
                    <div>
                        <h1>{{ timepass.Name }}</h1>
                    </div>
                </div>
                <b-dropdown
                    variant="ml-2 p-0"
                    class="d-menu ml-auto"
                    menu-class="mt-0 border-1"
                    no-caret
                    right
                    round
                    style="position: relative"
                >
                    <template v-slot:button-content>
                        <b-icon icon="three-dots" class="m-0" scale="1.2"></b-icon>
                    </template>
                    <b-dropdown-item class="d-danger" v-b-modal="'deleting-modal-admin-' + timepass.ID"
                        >Delete</b-dropdown-item
                    >
                    <b-dropdown-item class="d-danger" :to="'/admin/monetization/time-passes/edit/' + timepass.ID"
                        >Edit</b-dropdown-item
                    >
                </b-dropdown>
            </div>
            <p class="pt-2">{{ timepass.Description }}</p>
        </div>
        <b-modal
            :id="'deleting-modal-admin-' + timepass.ID"
            size="md"
            title="Delete Time Pass"
            @ok="handleDelete"
            ok-title="Delete"
            ok-variant="primary"
            centered
            :busy="deleting || deleted"
        >
            <b-overlay :show="deleting || deleted">
                <p class="mb-1">Once you delete this update, there is no going back. Please be certain.</p>
            </b-overlay>
            <!-- <div class="my-2" v-if="deleting">
          <span><b-spinner label="Spinning"></b-spinner></span>
        </div> -->
        </b-modal>
    </router-link>
</template>
<script>
import axios from 'axios';

import EventBus from '../../eventBus';

export default {
    name: 'TimepassCardAdmin',
    props: {
        timepass: {
            type: Object || null,
            default: null,
        },
    },
    data() {
        return {
            deleting: false,
            deleted: false,
        };
    },
    methods: {
        handleDelete() {
            this.loading = true;
            this.$store
                .dispatch('deleteTimepass', this.timepass)
                .then((response) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Successfully Deleted Time Pass',
                        Details: '',
                    };
                    EventBus.$emit('INFO', msg);
                    this.$emit('deleted', this.timepass.ID);
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to Delete Time Pass',
                        Details: error,
                    };
                    EventBus.$emit('ERROR', msg);
                    console.log(error);
                });
            this.$bvModal.hide(`deleting-modal-admin-${this.timepass.ID}`);
        },
    },
};
</script>
