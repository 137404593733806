import Vue from 'vue';
import Router from 'vue-router';
import AuthCallback from './views/SaaS/AuthCallback.vue';
import AuthCallbackV2 from './views/SaaS/AuthCallbackV2.vue';
import CreateSpace from './views/SaaS/CreateSpace.vue';
import CreateSpaceSuccess from './views/SaaS/CreateSpaceSuccess.vue';
import CreateSpaceAfterPayment from './views/SaaS/CreateSpaceAfterPayment.vue';
import SaaSBase from './views/SaaS/Base.vue';

Vue.use(Router);
const redirectorToLanding = (to) => {
    window.open('https://coapp.io', '_self');
};

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '',
            component: SaaSBase,
            children: [
                {
                    path: '/create/success/:domain',
                    name: 'CreateSpaceSuccess',
                    component: CreateSpaceSuccess,
                },
                {
                    path: '/create/payment/success',
                    name: 'CreateSpaceAfterPayment',
                    component: CreateSpaceAfterPayment,
                },
                {
                    path: '/create',
                    name: 'Create Community',
                    component: CreateSpace,
                },
                {
                    path: '/oauth2/google/callback',
                    name: 'OAuth2 Callback',
                    component: AuthCallbackV2,
                },
                {
                    path: '/oauth2/token/callback',
                    name: 'Generic OAuth2 Callback',
                    component: AuthCallbackV2,
                },
                {
                    path: '/auth/callback',
                    name: 'Cobot Connect Callback',
                    component: AuthCallback,
                },

                {
                    // redirect all unresolved urls to dashboard
                    path: '*',
                    name: 'Redirect',
                    beforeEnter: redirectorToLanding,
                },
            ],
        },
    ],
});
