<template>
    <div>
        <div class="co payment-details">
            <CoText type="p2" class="mb-0" :text="name" />
            <CoText type="p2" class="mb-0" v-if="defaultMethod" variant="mute" text="standard" />
        </div>
        <CoText type="p2" class="mb-0" :text="details" />
    </div>
</template>

<script>
import CoText from '../../Atoms/co-text/CoText.vue';
import './CoPaymentDetails.less';

export default {
    name: 'CoPaymentDetails',
    components: {
        CoText,
    },
    props: {
        defaultMethod: Boolean,
        name: {
            type: String,
            default: '',
        },
        details: {
            type: String,
            default: '',
        },
    },
};
</script>
