






























































import { PropType } from 'vue';
import axios from 'axios';
import CoSkeleton from '../../Atoms/co-skeleton/CoSkeleton.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoText from '../../Atoms/co-text/CoText.vue';
import CoRoundButton from '../../Atoms/co-round-button/CoRoundButton.vue';
import CoThumbnail from '../../Molecules/co-thumbnail/CoThumbnail.vue';
import CoUserInvite from '../co-user-invite/CoUserInvite.vue';

export default {
    name: 'CoActiveMembersWidget',
    components: {
        CoSkeleton,
        CoIcon,
        CoText,
        CoRoundButton,
        CoUserInvite,
        CoThumbnail,
    },
    props: {
        asAdmin: {
            type: Boolean as PropType<boolean>,
            required: false,
            default: false,
        },
    },
    data: () => ({
        loading: true,
        members: [],
    }),

    mounted() {
        this.listMembers();
    },
    methods: {
        goToMembers() {
            this.$emit('goToMembers');
        },
        listMembers() {
            axios
                .get('/user/profile/list')
                .then((response) => {
                    if (response && response.data && response.data.objects && response.data.objects.length > 0) {
                        let members = response.data.objects;
                        if (members.length > 4) {
                            members = members.slice(0, 4);
                        }
                        this.members = members;

                        this.loading = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
