




















































































































































import axios from 'axios';
import { get, sortBy } from 'lodash';
import CoMenuItem from '../../Molecules/co-menu-item/CoMenuItem.vue';
import CoThumbnail from '../../Molecules/co-thumbnail/CoThumbnail.vue';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoText from '../../Atoms/co-text/CoText.vue';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoSkeleton from '../../Atoms/co-skeleton/CoSkeleton.vue';

export default {
    name: 'CoLeftMainNavigation',
    components: {
        CoMenuItem,
        CoThumbnail,
        CoButton,
        CoIcon,
        CoText,
        CoHeadline,
        CoSkeleton,
    },
    props: {
        spaceName: {
            type: String,
            default: null,
        },
        logoURL: {
            type: String,
            default: null,
        },
        spaceID: {
            type: String,
            default: null,
        },
        isAdmin: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            localAdmin: get(this.$store, 'state.me.Permissions', null)
                ? get(this.$store, 'state.me.Permissions', []).includes('space_admin')
                : this.isAdmin,
            thisSpaceID: get(this.$store, 'state.space.ID', null) || this.spaceID,
            menuLoading: false,
            channels: [],
            customMenuItems: [],
            channelName: null,
            isMarketOn: get(this.$store, 'state.space.MarkeplaceOn', false),
        };
    },
    async mounted() {
        this.menuLoading = true;
        await this.getMenuContent();
        this.menuLoading = false;
    },
    methods: {
        getMenuContent() {
            return Promise.all([this.listChannels(), this.getCustomMenuItems()]);
        },
        listChannels() {
            const circlesOn = get(this.$store, 'state.circlesOn', false);
            if (circlesOn) {
                return this.listForCircles();
            }
            return new Promise((resolve, reject) => {
                axios
                    .get('/project/channel/list')
                    .then((response) => {
                        const channels = get(response, 'data.objects', []);
                        // map channels to menu items by adding URL and name
                        this.channels = channels.map((channel) => ({
                            id: channel.object.ID,
                            url: `/channels/${channel.object.Slug}`,
                            name: get(channel, 'object.Value', null),
                            space: get(channel, 'space', {}),
                        }));

                        // sort channels alphabetically by name using lodash
                        this.channels = sortBy(this.channels, [(channel) => channel.name.toLowerCase()], ['desc']);
                        resolve(true);
                    })
                    .catch((error) => {
                        resolve(true);
                    });
            });
        },
        listForCircles() {
            return new Promise((resolve, reject) => {
                const circlesOn = get(this.$store, 'state.circlesOn', false);
                axios
                    .post('/channel/list/circles')
                    .then((response) => {
                        const channels = get(response, 'data.Objects', []);
                        // map channels to menu items by adding URL and name
                        this.channels = channels.map((channel) => ({
                            id: channel.object.ID,
                            url: `/channels/${channel.object.Slug}?id=${channel.object.ID}`,
                            name: get(channel, 'object.Value', null),
                            space: get(channel, 'space', {}),
                        }));

                        // sort channels alphabetically by name using lodash
                        this.channels = sortBy(this.channels, [(channel) => channel.name.toLowerCase()], ['desc']);
                        resolve(true);
                    })
                    .catch((error) => {
                        resolve(true);
                    });
            });
        },
        getCustomMenuItems() {
            return new Promise((resolve, reject) => {
                axios
                    .get('/dashboard/custom-menu-options/list')
                    .then((response) => {
                        this.customMenuItems = get(response, 'data', []).filter(
                            (item) => (!item.Type || item.Type === 0) && !item.Hidden
                        );
                        if (!this.customMenuItems) {
                            this.customMenuItems = [];
                        }
                        resolve(true);
                    })
                    .catch((error) => {
                        this.customMenuItems = [];
                        resolve(true);
                    });
            });
        },
        getPrivacyPolicy() {
            axios({
                method: 'GET',
                url: '/space/get-privacy-policy',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.PrivacyPolicy = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        createChannel() {
            const data = JSON.stringify({ Value: this.channelName });

            axios({
                method: 'POST',
                url: '/admin/community/project-type',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(() => {
                    this.channelName = '';
                    this.listChannels();
                    this.$nextTick(() => {
                        this.$refs['modal-create-project-type'].hide();
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
