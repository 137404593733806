<template>
    <b-overlay :show="loading">
        <div class="d-flex align-items-center pt-4 ml-3">
            <span class="ml-3">{{ $t('labels.planupgradechose') }}</span>
        </div>
        <div class="d-flex mt-4 w-100 mb-5">
            <!-- content section -->
            <div class="row m-0 w-100">
                <!-- plan upgrades -->
                <div class="col-12 col-lg-6 column">
                    <div class="bg-white round-unify-xs mh-100 h-100 d-flex flex-column align-items-end">
                        <!-- header -->
                        <div class="p-3 text-center border-bottom align-self-start w-100">
                            {{ $t('labels.upgrades') }}
                        </div>
                        <!-- body -->
                        <div class="align-self-start w-100 overflow-auto mh-100">
                            <div v-if="plan && plan.Extras && plan.Extras.length !== 0">
                                <div
                                    v-for="(item, index) in plan.Extras"
                                    :key="item.ID"
                                    class="p-3"
                                    :class="{ 'border-bottom': index !== plan.Extras.length - 1 }"
                                >
                                    <div class="d-flex justify-content-between align-items-start">
                                        <div>
                                            <h3 class="mb-0">{{ item.Name }}</h3>
                                            <p class="text-muted" v-if="item.Description">
                                                {{ item.Description }}
                                            </p>
                                            <p class="mb-0 text-muted">
                                                {{ $n(calculatePrice(item), 'currency') }}
                                                {{ item.Currency }} /
                                                {{ $t(`labels.${plan.TimeInteval === 1 ? 'year' : 'month'}`) }}
                                            </p>
                                            <small v-if="item.TaxRate"
                                                >{{ $t('labels.including') }} {{ item.TaxRate.Percentage }}%
                                                {{ item.TaxRate.DisplayName }}
                                            </small>
                                        </div>
                                        <div>
                                            <b-button
                                                variant="outline-danger btn-round"
                                                v-if="isExtraSelected(item)"
                                                @click="removeExtra(item)"
                                            >
                                                <b-icon
                                                    icon="x"
                                                    class="m-0"
                                                    scale="1.3"
                                                    style="position: relative; bottom: 1px"
                                                ></b-icon>
                                            </b-button>
                                            <b-button
                                                v-else
                                                variant="outline-primary btn-round"
                                                @click="setExtras(item)"
                                            >
                                                <b-icon
                                                    icon="plus"
                                                    class="m-0"
                                                    scale="1.3"
                                                    style="position: relative; bottom: 1px"
                                                ></b-icon>
                                            </b-button>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between"></div>
                                </div>
                            </div>
                            <div v-else class="p-3 text-center text-muted">{{ $t('noupgrades') }}</div>
                        </div>
                        <!-- footer -->
                        <div class="d-none d-lg-flex p-3 border-top justify-content-between mt-auto w-100">
                            <b-button variant="outline-primary" @click="back()">{{ $t('labels.back') }}</b-button>
                            <b-button variant="primary" @click="next()">{{ $t('labels.next') }}</b-button>
                        </div>
                    </div>
                </div>

                <!-- membership state -->

                <div class="col-12 col-lg-6 mt-4 mt-lg-0" v-if="plan">
                    <div class="w-100 bg-white round-unify-xs">
                        <ChangePlanMembership :plan="plan" :selected="selectedExtras"></ChangePlanMembership>
                        <div class="d-flex d-lg-none p-3 border-top justify-content-between mt-auto w-100">
                            <b-button variant="outline-primary" @click="back()">{{ $t('labels.back') }}</b-button>
                            <b-button variant="primary" @click="next()">{{ $t('labels.next') }}</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-overlay>
</template>
<style lang="less" scoped>
@import '~@/assets/less/variables.less';

@media (min-width: 996px) {
    .column {
        height: 65vh;
    }
}
</style>

<script>
import i18n from 'vue-i18n';

export default {
    i18n: {
        messages: {
            en: {
                noupgrades: 'There are no upgrades available in this plan',
            },
            de: {
                noupgrades: 'In diesem Tarif sind keine Upgrades verfügbar.',
            },
        },
    },
    name: 'ChangePlanUpgrades',
    props: {
        plan: {
            type: Object,
            default: null,
        },
        member: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            loading: false,
            selectedExtras: [],
        };
    },
    computed: {},
    created() {},
    methods: {
        back() {
            if (this.member) {
                this.$router.push(`/admin/community/member/plan/change/${this.member.ID}`);
            } else {
                this.$router.push('/account/plan/change');
            }
        },
        calculatePrice(extra) {
            if (extra.TaxRateID === '' || extra.TaxRate.Inclusive) {
                const price = extra.PriceInCents / 100.0;
                return price;
            }
            const price = extra.PriceInCents / 100.0;
            const totalPrice = price * (extra.TaxRate.Percentage / 100 + 1);
            return totalPrice;
        },
        isExtraSelected(obj) {
            const item = this.selectedExtras.find((element) => element.ID === obj.ID);
            if (item) {
                return true;
            }
            return false;
        },
        removeExtra(extra) {
            const id = this.selectedExtras.indexOf(extra);
            this.selectedExtras.splice(id, 1);
        },
        setExtras(item) {
            this.selectedExtras.push(item);
        },
        next() {
            this.$emit('nextStep', this.selectedExtras);
        },
        totalPrice() {
            let total = this.calculatePrice(this.plan);
            if (this.selectedExtras) {
                this.selectedExtras.forEach((element) => {
                    total += this.calculatePrice(element);
                });
            }
            return total.toFixed(2);
        },
    },
};
</script>
