














export default {
    name: 'CoBookingResourcesList',
    props: {
        resources: {
            type: Array,
            required: true,
        },
        isBillingTurnedOn: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        selectResource(resource) {
            this.$emit('select', resource);
        },
    },
};
