














































































































































































import axios from 'axios';
import { get, remove } from 'lodash';
import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import { Page, User } from '../../../types/GlobalTypes';
import CoImage from '../../Atoms/co-image/CoImage.vue';
import CoText from '../../Atoms/co-text/CoText.vue';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoSkeleton from '../../Atoms/co-skeleton/CoSkeleton.vue';
import CoThumbnail from '../co-thumbnail/CoThumbnail.vue';
import CoDate from '../co-date/CoDate.vue';
import CoPill from '../../Atoms/co-pill/CoPill.vue';
import CoLink from '../../Atoms/co-link/CoLink.vue';
import EventBus from '../../../eventBus';
import CoConfirmation from '../co-confirmation/CoConfirmation.vue';

export default {
    components: {
        CoIcon,
        CoSkeleton,
        CoHeadline,
        CoButton,
        CoText,
        CoImage,
        CoThumbnail,
        CoDate,
        CoPill,
        CoLink,
        CoConfirmation,
    },
    name: 'CoPage',
    props: {
        page: {
            type: Object as () => Page,
            required: true,
            default: () => ({}),
        },
        variant: {
            type: String,
            validator: (value: string) => ['default', 'micro', 'list', 'full'].includes(value),
            default: 'default',
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
        me: {
            type: Object,
            required: false,
        },
        link: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {
            meLocal: get(this, 'me', null) || {
                ...get(this.$store, 'state.me', {}),
                ...get(this.$store, 'state.me.Profile', {}),
            },
            // isMine : false,
            // amIfollowing : false,
            isAdmin: false,
        };
    },
    computed: {
        followers: {
            get() {
                return get(this.page, 'FollowedBy', []);
            },
            set(value) {
                this.page = {
                    ...this.page,
                    FollowedBy: value,
                };
            },
        },
        isMine() {
            const meID = get(this.meLocal, 'ID', '');
            return get(this.page, 'Owners', []).some((owner) => owner.ID === meID);
        },
        amIfollowing() {
            const meID = get(this.meLocal, 'ID', '');
            return get(this.page, 'FollowedBy', []).includes(meID);
        },
        getImage() {
            return get(this.page, 'Images[0].ImageURL', null);
        },
    },
    // watch: {
    //     page: {
    //         handler(newVal) {
    //             let meID = get(this.meLocal, 'ID', '');

    //             // this.isMine = get(newVal, 'Owners', []).some((owner) => owner.ID === meID);
    //             // this.amIfollowing = get(newVal, 'FollowedBy', []).includes(meID);

    //         },
    //         deep: true,
    //     },
    // },
    mounted() {
        // this.isMine = this.meLocal && get(this.page, 'AuthorID', null) === this.meLocal.ID;
        // this.amIfollowing = this.meLocal && this.page.FollowedBy && this.page.FollowedBy.includes(this.meLocal.ID);
        this.isAdmin = get(this.$store, 'state.me.Permissions', []).includes('space_admin');
    },
    methods: {
        get(object, path, defaultValue?: string) {
            return get(object, path, defaultValue);
        },
        followPage() {
            if (!this.meLocal) return;
            this.$emit('follow', this.page);
            this.followers.length > 0 ? this.followers.unshift(this.meLocal.ID) : (this.followers = [this.meLocal.ID]);
            axios({
                method: 'POST',
                url: '/project/follow',
                data: {
                    ProjectID: this.page.ID,
                },
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            }).catch((error) => {
                this.page.FollowedBy.pop();
                EventBus.$emit('ERROR', {
                    Message: 'Project follow failed',
                    Details: null,
                });
            });
        },
    },
};
