














































import CoButton from '../../../components/Atoms/co-button/CoButton.vue';
import CoHeadline from '../../../components/Atoms/co-headline/CoHeadline.vue';
import CoImage from '../../../components/Atoms/co-image/CoImage.vue';
import CoText from '../../../components/Atoms/co-text/CoText.vue';
import CoCard from '../../../components/Molecules/co-card/CoCard.vue';

export default {
    components: {
        CoCard,
        CoHeadline,
        CoText,
        CoButton,
        CoImage,
    },
    name: 'Circles',
};
