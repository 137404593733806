<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <div>
        <div class="pl-sm-4 mt-4">
            <div class="bg-white round-unify p-3">
                <div>
                    <div class="d-flex w-100 justify-content-between">
                        <h2>Backfill</h2>
                        <div>
                            <b-button variant="primary" class="ml-3" @click="backfill">BackFill</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';

export default {
    name: 'CustomerSettings',
    data() {
        return {
            space: null,
        };
    },
    mounted() {},
    methods: {
        backfill() {
            const data = {
                OverrideStartNumber: false,
            };
            axios({
                method: 'POST',
                url: '/admin/customer/backfill',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    console.log(response);
                    const msg = {
                        Message: 'Successfully backfilled customer numbers',
                        Details: '',
                    };
                    EventBus.$emit('INFO', msg);
                })
                .catch((error) => {
                    console.log(error);
                    const msg = {
                        Message: 'Failed to backfill customer numbers',
                        Details: '',
                    };
                    EventBus.$emit('ERROR', msg);
                })
                .finally(() => {
                    console.log('finally');
                });
        },
    },
};
</script>
