<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <!-- header start -->
    <div class="px-sm-4 mb-4">
        <b-breadcrumb class="mb-4 p-0" :items="breadcrumbs"></b-breadcrumb>
        <div class="d-flex flex-wrap align-items-center justify-content-begin">
            <ProfileCircle v-if="imageURL != ''" :ImageURL="imageURL"></ProfileCircle>
            <b-icon v-else-if="icon" :icon="icon" scale="1" class="mr-2"></b-icon>
            <h2 class="mr-auto">{{ title }}</h2>
            <slot name="actions" class=""> </slot>
        </div>
        <h3 v-if="subline" class="mt-1">{{ subline }}</h3>
    </div>
    <!-- header end -->
</template>
<script>
export default {
    name: 'Header',
    props: {
        title: {
            type: String,
            default: '',
        },
        subline: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
        breadcrumbs: {
            type: Array,
            default() {
                return [];
            },
        },
        imageURL: {
            type: String,
            default: '',
        },
    },
};
</script>
