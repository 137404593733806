<template>
    <div>
        <div class="container p-0 p-md-3">
            <CoCard variant="border" class="mb-3">
                <div class="d-flex justify-content-end align-items-center w-100 mb-0">
                    <CoText class="d-md-none mr-auto">{{ $t('labels.notifications') }}</CoText>
                    <CoHeadline class="d-none d-md-block mr-auto" :level="2" trunc
                        >{{ $t('labels.notifications') }}
                    </CoHeadline>

                    <div class="d-flex align-items-end ml-auto">
                        <co-button
                            :label="$t('labels.markallasread')"
                            @click="markAllAsRead()"
                            class="ml-auto d-none d-sm-inline-block"
                            variant="blacksecondary"
                            :loading="markLoading"
                        />
                        <CoRoundButton
                            icon="gear"
                            variant="light"
                            @click="$router.push('/settings/notifications')"
                            class="ml-2"
                        />
                    </div>
                </div>
                <!-- button for mobile views -->
                <co-button
                    :label="$t('labels.markallasread')"
                    @click="markAllAsRead()"
                    class="d-block d-sm-none w-100 mt-3"
                    variant="blacksecondary"
                    :loading="markLoading"
                    truncate
                />
            </CoCard>
            <div v-if="!loading && notifications.length === 0" class="justify-content-between text-center">
                {{ $t('notificationsempty') }}
            </div>
            <div v-else>
                <!-- agreements -->
                <div v-if="this.$unleash.isEnabled('frontend.agreements') && agreements && agreements.length > 0">
                    <CoText style="margin-left: 1.5rem; margin-right: 1.5rem" variant="mute">
                        {{ $tc('pendingtasksmessage', agreements.length) }}
                    </CoText>
                    <CoCard
                        v-for="(item, ii) in agreements"
                        :key="'agreement' + ii"
                        class="mb-2 mb-md-3"
                        style="cursor: pointer"
                        @click.native="
                            selectedAgreement = item;
                            $refs['agreement-modal'].show();
                        "
                    >
                        <div class="d-flex align-items-center">
                            <CoIcon name="hourglass" class="mr-2" />
                            <CoText>{{ $t('reviewandconfirm', { title: item.title }) }}</CoText>
                            <CoButton
                                class="ml-auto"
                                :label="$t('labels.complete')"
                                @click.native="
                                    selectedAgreement = item;
                                    $refs['agreement-modal'].show();
                                "
                            />
                        </div>
                    </CoCard>
                    <hr class="mt-0 mb-3" />
                </div>
                <!-- notifications -->
                <co-notification v-for="(item, i) in notifications" :key="i" :item="item" class="mb-2 mb-md-3" />
            </div>
            <div v-if="loading">
                <!-- skelleton on load -->
                <co-notification v-for="n in 5" :key="n" loading class="mb-2 mb-md-3" />
            </div>
        </div>
        <!-- agreement modal -->
        <CoModal
            ref="agreement-modal"
            @close="
                agreementAccepted = null;
                selectedAgreement = {};
            "
            :title="selectedAgreement.title"
        >
            <template v-slot:body>
                <div v-html="selectedAgreement.Content"></div>
            </template>
            <template v-slot:footer>
                <CoCheckbox variant="checkbox" v-model="agreementAccepted" :name="$t('acceptterms')" class="mb-3" />
                <CoButton
                    :disabled="!agreementAccepted"
                    :label="$t('labels.save')"
                    variant="primary"
                    @click="saveAgreement"
                />
            </template>
        </CoModal>
    </div>
</template>

<style lang="less" scoped>
.notpreflink {
    margin-bottom: 7px;
}
</style>

<script>
import Vue from 'vue';
import VueObserveVisibility from 'vue-observe-visibility';
import i18n from 'vue-i18n';
import axios from 'axios';
import { get } from 'lodash';
import EventBus from '../../eventBus';
import CoText from '../../components/Atoms/co-text/CoText.vue';
import CoIcon from '../../components/Atoms/co-icon/CoIcon.vue';
import CoButton from '../../components/Atoms/co-button/CoButton.vue';
import CoNotification from '../../components/Organisms/co-notification/coNotification.vue';
import CoRoundButton from '../../components/Atoms/co-round-button/CoRoundButton.vue';
import CoLoadingIndicator from '../../components/Atoms/co-loading-indicator/coLoadingIndicator.vue';

Vue.use(VueObserveVisibility);

export default {
    components: {
        CoText,
        CoIcon,
        CoButton,
        CoNotification,
        CoLoadingIndicator,
        CoRoundButton,
    },
    i18n: {
        messages: {
            en: {
                notificationsempty: "You haven't received any notifications yet.",
                reviewandconfirm: 'Please review and confirm the new version of the {title}.',
                pendingtasksmessage: 'You have {n} tasks to complete',
                acceptterms: 'I have read and accept these terms',
            },
            de: {
                notificationsempty: 'Du hast noch keine Benachrichtigungen erhalten.',
                reviewandconfirm: 'Bitte lese und bestätige die neue Version der {title}.',
                pendingtasksmessage: 'Du hast {n} offene Aufgabe | Du hast {n} offene Aufgaben',
                acceptterms: 'Ich habe diese Bedingungen gelesen und akzeptiere sie',
            },
        },
    },
    name: 'Notifications',

    data() {
        return {
            notifications: [],
            next: null,
            loading: false,
            markLoading: false,
            // agreements
            agreements: [],
            selectedAgreement: {},
            agreementAccepted: null,
        };
    },
    beforeCreate() {},
    created() {
        window.addEventListener('scroll', this.scroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.scroll);
    },
    mounted() {
        this.getNotifications();
        this.getPendingAgreements();
    },
    computed: {},
    methods: {
        async markAllAsRead() {
            this.markLoading = true;

            const notificationPromises = [];
            const created = get(this.notifications, '[0].CreatedAt', Date.now() / 1000);

            try {
                const response = await this.$store.dispatch('getGetNewNotifications');
                if (response.Notifications) {
                    response.Notifications.forEach((entry) => {
                        if (entry.CreatedAt <= created) {
                            const promise = axios({
                                method: 'POST',
                                url: '/notification',
                                data: {
                                    NotificationID: entry.ID,
                                },
                            }).catch((error) => {});

                            notificationPromises.push(promise);
                        } else {
                            this.newNotifications.push(entry);
                        }
                    });
                }
            } catch (error) {
                // console.log(error);
            }
            await Promise.all(notificationPromises);
            this.notifications = [];
            this.next = null;
            this.getNotifications();
            this.$store.dispatch('getGetNewNotifications');
            this.markLoading = false;
        },

        getNotifications() {
            this.loading = true;
            this.$store
                .dispatch('getAllNotifications', this.next)
                .then((response) => {
                    if (response.objects) {
                        response.objects.forEach(function (entry) {
                            this.notifications.push(entry);
                        }, this);

                        if (response.next != '' && response.next != null) {
                            this.next = response.next;
                            const distanceFromBottom = document.body.scrollHeight - window.innerHeight - window.scrollY;
                            if (distanceFromBottom === 0) {
                                this.getNotifications();
                            }
                        } else {
                            this.next = null;
                        }
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    // todo show error
                });
        },
        getPendingAgreements() {
            axios
                .get('/user/new-agreements')
                .then((response) => {
                    this.agreements = get(response, 'data', []).map((item) => {
                        return {
                            ...item,
                            title: item.TypeOfAgreement
                                ? this.$t('labels.termsandconditions')
                                : this.$t('labels.privacypolicy'),
                            action: () => {
                                (this.agreementTitle = item.TypeOfAgreement
                                    ? this.$t('labels.termsandconditions')
                                    : this.$t('labels.privacypolicy')),
                                    (this.agreementContent = item.Content);
                                this.$refs['agreement-modal'].show();
                                // this.$router.push(`/agreement/${item.ID}`);
                            },
                        };
                    });
                })
                .catch((error) => {
                    console.log('error fetching agreements', error);
                });
        },
        saveAgreement() {
            axios
                .post('/user/accept-agreement', {
                    version: get(this, 'selectedAgreement.CreatedAt', 0),
                })
                .then((response) => {
                    this.getPendingAgreements();
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: 'Error saving agreement',
                    });
                    console.log('error saving agreement', error);
                })
                .finally(() => {
                    this.$refs['agreement-modal'].hide();
                    //refresh notifications count
                    this.$store.dispatch('getGetNewNotifications');
                });
        },
        scroll() {
            window.onscroll = () => {
                const distanceFromBottom = document.body.scrollHeight - window.innerHeight - window.scrollY;

                const percentage = (distanceFromBottom * 100) / (document.body.scrollHeight - window.innerHeight);

                if (percentage < 1) {
                    if (this.next != null && !this.loading) {
                        this.getNotifications();
                    }
                }
            };
        },
    },
};
</script>
