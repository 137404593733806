<style lang="less" scoped>
.project-header-image {
    object-fit: cover;
}
</style>

<template>
    <div class="pb-5 px-2">
        <b-overlay :show="loading || deleting" spinner-variant="primary" rounded="sm">
            <div class="d-lg-none" v-if="item">
                <b-aspect class="d-lg-none" :aspect="2">
                    <div class="position-relative h-100">
                        <img
                            class="project-header-image round-unify position-absolute w-100 d-block img-fluid h-100"
                            :src="filterPictures(item.ImageURL)"
                            img-blank-color="#ffffff"
                            alt
                        />
                    </div>
                </b-aspect>
            </div>
            <div class="container" v-if="item">
                <div class="d-none d-lg-block">
                    <b-aspect :aspect="4">
                        <div class="position-relative h-100">
                            <img
                                class="project-header-image round-unify position-absolute w-100 d-block img-fluid h-100"
                                :src="filterPictures(item.ImageURL)"
                                img-blank-color="#ffffff"
                                alt
                            />
                        </div>
                    </b-aspect>
                </div>
                <nav class>
                    <div class="row mt-4 mb-2 mb-lg-4">
                        <div class="col-12 col-sm">
                            <h1>{{ item.Title }}</h1>
                        </div>
                        <div class="order-2 order-lg-0 col col-auto mt-1 mb-2">
                            <b-button
                                v-if="iAmAuthor"
                                @click="editItem"
                                variant="outline-primary"
                                class="btn-round mt-1 mt-lg-0"
                                alt="Edit"
                            >
                                <b-icon
                                    icon="pencil"
                                    class="m-0"
                                    style="position: relative; bottom: 1px"
                                    scale="1"
                                ></b-icon>
                            </b-button>
                            <b-button
                                v-if="isAdmin"
                                v-b-modal="'deleteJobModal'"
                                variant="outline-danger"
                                class="btn-round ml-2 mt-1 mt-lg-0"
                                alt="Delete"
                            >
                                <b-icon
                                    icon="trash"
                                    class="m-0"
                                    style="position: relative; bottom: 1px"
                                    scale="1"
                                ></b-icon>
                            </b-button>

                            <b-button
                                v-if="iAmAuthor && item.Open"
                                v-b-modal="'close-offer-modal'"
                                variant="outline-danger"
                                class="ml-2 mt-1 mt-lg-0"
                                >{{ $t('labels.close') }}
                            </b-button>
                            <b-button
                                v-if="iAmAuthor && !item.Open"
                                @click="$refs['reopen-modal'].show()"
                                variant="outline-success"
                                class="ml-2 mt-1 mt-lg-0"
                                >{{ $t('labels.reopen') }}
                            </b-button>
                            <b-button
                                v-b-modal="'apply-modal'"
                                v-if="item.Open && !amIInterested && !iAmAuthor"
                                variant="primary"
                                class="ml-2 mt-1 mt-lg-0"
                                >{{ $t('labels.interested') }}
                            </b-button>
                            <b-button
                                v-else-if="amIInterested && !iAmAuthor"
                                disabled
                                variant="outline-success"
                                class="ml-2 mt-1 mt-lg-0"
                                >{{ $t('labels.interestedsent') }}
                            </b-button>
                            <b-button
                                v-else-if="!item.Open"
                                disabled
                                variant="outline-danger"
                                class="ml-2 mt-1 mt-lg-0 disabled"
                                >{{ $t('labels.closed') }}
                            </b-button>
                        </div>
                    </div>
                </nav>

                <div class="row">
                    <div class="col-np-mobile col-12 col-lg-8 wrap">
                        <b-tabs justified v-model="tabIndex" nav-class="d-flex justify-content-center">
                            <b-tab>
                                <template #title>
                                    <span class="d-none d-sm-block"> {{ $t('labels.description') }}</span>
                                    <b-icon class="d-sm-none" icon="shop"></b-icon>
                                </template>
                                <b-card>
                                    <h3 class="d-sm-none mb-4">{{ $t('labels.description') }}</h3>
                                    <div
                                        v-if="item"
                                        class="mb-1"
                                        v-linkified
                                        style="white-space: pre-wrap"
                                        v-html="item.Description"
                                    ></div>
                                    <div
                                        v-if="item && item.Tags && item.Tags.length > 0"
                                        class="mt-4 d-none d-lg-block"
                                    >
                                        <h3 class="">
                                            {{
                                                this.$unleash.isEnabled('frontend.tags-are-called-topics')
                                                    ? $t('labels.topics')
                                                    : $t('labels.tags')
                                            }}
                                        </h3>
                                        <div>
                                            <span
                                                v-for="(tag, index) in item.Tags"
                                                :key="index"
                                                class="color-2-link mr-2 mb-2"
                                                @click.stop="$router.push('/search?term=' + tag)"
                                                >#{{ tag }}
                                            </span>
                                        </div>
                                    </div>
                                </b-card>
                            </b-tab>
                            <b-tab>
                                <template #title>
                                    <span class="d-none d-sm-block"
                                        >{{ $t('labels.comments') }} ({{ commentCount }})</span
                                    >
                                    <b-icon class="d-sm-none" icon="chat-square-dots"></b-icon>
                                </template>
                                <b-card>
                                    <h3 class="d-sm-none mb-4">{{ $t('labels.comments') }} ({{ commentCount }})</h3>
                                    <CommentSection
                                        v-if="this.item"
                                        post-type="job"
                                        :PostID="this.item.ID"
                                        :profileDisplayed="this.$store.state.me.Profile"
                                        :ShowComments="true"
                                        :highlightCommentID="$route.query.commentid"
                                        @comment-count-update="commentCount = $event"
                                        @show-comments="showComments = true"
                                    >
                                    </CommentSection>
                                </b-card>
                            </b-tab>
                        </b-tabs>
                    </div>

                    <div
                        class="col-np-mobile col-12 col-lg-4 mt-md-2"
                        :class="{
                            'mt-2': $isMobile,
                        }"
                    >
                        <b-card :header="typeCatHeader" header-class="d-none d-lg-block">
                            <div v-if="item['ContactMethod']" class="mb-4">
                                <h3>{{ $t('labels.contact') }}</h3>
                                <div v-html="item['ContactMethod']" v-linkified />
                                <!-- <p>{{job['ContactMethod']}}</p> -->
                            </div>
                            <div>
                                <!-- <h3 class="mb-4">About the project</h3> -->
                                <h3>{{ $t('labels.author') }}</h3>

                                <div v-if="author" class="hfv-project-users-list mt-3">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <ProfileCircle
                                            :ImageURL="author.Profile.ImageURL"
                                            :Slug="author.Slug"
                                            :Name="author.Profile.Name"
                                        ></ProfileCircle>
                                    </div>
                                </div>
                                <span v-else>Could not find author</span>
                            </div>
                            <div v-if="iAmAuthor" class="mt-4">
                                <h3>{{ $t('labels.interestedpeople') }}</h3>
                                <small class="my-1" v-if="interested.length > 0">{{ $t('interestedinfo') }}</small>
                                <small class="my-1" v-else>{{ $t('interestedempty') }}</small>
                                <ul class="hfv-project-users-list">
                                    <li v-for="(item, i) in interested" class="hfv-project-users-list-item" :key="i">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <ProfileCircle
                                                :ImageURL="item.User.Profile.ImageURL"
                                                :Slug="item.User.Slug"
                                                :Name="item.User.Profile.Name"
                                            ></ProfileCircle>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="mt-4">
                                <p class="mb-0">
                                    {{ $t('labels.created') }}
                                    <coDate :date="item.CreatedAt" format="relative" />
                                </p>
                            </div>
                        </b-card>
                    </div>
                </div>
            </div>
        </b-overlay>
        <!-- modals -->
        <b-modal
            id="apply-modal"
            size="sm"
            title="I'm interested"
            @ok="iAmInterested()"
            ok-title="Send"
            ok-variant="primary"
            centered
        >
            <b-overlay :show="loadingForInterest" spinner-variant="primary" rounded="sm">
                <p class="mb-1">
                    {{ $t('messages.marketiterestsubmit') }}
                </p>
            </b-overlay>
        </b-modal>
        <b-modal
            v-if="isAdmin"
            id="deleteJobModal"
            ref="deleteJobModal"
            size="sm"
            :title="$t('labels.marketitemdelete')"
            centered
            @ok="deleteJobAdmin"
            :ok-title="$t('labels.delete')"
            :cancel-title="$t('labels.cancel')"
            ok-variant="primary"
            :busy="deleting"
        >
            <b-overlay :show="deleting">
                <p class="mb-1">{{ $t('messages.deletewarning') }}</p>
            </b-overlay>
        </b-modal>
        <b-modal
            id="close-offer-modal"
            size="md"
            :title="$t('labels.marketitemclose')"
            @ok="closeOffer()"
            :ok-title="$t('labels.close')"
            :cancel-title="$t('labels.cancel')"
            ok-variant="primary"
            centered
        >
            <b-overlay :show="loadingForInterest" spinner-variant="primary" rounded="sm">
                <p class="mb-1">{{ $t('closewarning') }}</p>

                <div class="mt-2">
                    <b-form-checkbox
                        v-if="item"
                        id="checkbox-1"
                        v-model="item['Success']"
                        name="checkbox-1"
                        class="mt-3"
                        size="lg"
                        switch
                        >{{ $t('wassuccessful') }}
                    </b-form-checkbox>
                </div>
                <div class="mt-2" v-if="item && item['Success']">
                    <b-form-checkbox
                        id="checkbox-2"
                        v-model="item['FoundOnPlatform']"
                        name="checkbox-2"
                        class="mt-3"
                        size="lg"
                        switch
                        >{{ $t('foundhere') }}
                    </b-form-checkbox>
                </div>
            </b-overlay>
        </b-modal>
        <CoModal ref="reopen-modal">
            <template v-slot:header>
                <CoHeadline is="h2">{{ $t('labels.reopen') }}</CoHeadline>
            </template>
            <template v-slot:body>
                <CoText>
                    {{ $t('reopenwarning') }}
                </CoText>
            </template>
            <template v-slot:footer>
                <div class="d-flex justify-content-between w-100">
                    <CoButton
                        :label="$t('labels.cancel')"
                        variant="secondary"
                        @click="$refs['reopen-modal'].hide()"
                        :disabled="loadingReopen"
                    />
                    <CoButton
                        :label="$t('labels.reopenItem')"
                        variant="primary"
                        @click="reopenItem()"
                        :loading="loadingReopen"
                    />
                </div>
            </template>
        </CoModal>

        <!-- modals -->
    </div>
</template>
<script>
import Vue from 'vue';
import axios from 'axios';
import linkify from 'vue-linkify';
import i18n from 'vue-i18n';
import CommentSection from '@/components/old-components/CommentSection.vue';
import ProfileCircle from '@/components/old-components/ProfileCircle.vue';
import CoDate from '@/components/Molecules/co-date/CoDate.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoModal from '@/components/Organisms/co-modal/CoModal.vue';
import EventBus from '../../eventBus';

const moment = require('moment');

Vue.directive('linkified', linkify);

Vue.use(require('vue-moment'), {
    moment,
});

export default {
    components: { CoModal, CoButton, CoText, CoHeadline, CoDate, ProfileCircle, CommentSection },
    i18n: {
        messages: {
            en: {
                interestedinfo: 'Visit the profiles and write a message to get in touch.',
                interestedempty: 'As soon as someone is interested, their profiles will show up here.',
                closewarning: "This item will be closed. Users won't be able to apply anymore",
                wassuccessful: 'Was your search successful?',
                foundhere: 'Did you find candidate here?',
                reopenwarning: 'Market item will be reopened for 4 weeks',
            },
            de: {
                interestedinfo: 'Besuche die Profile und schreibe eine Nachricht, um in Kontakt zu treten.',
                interestedempty: 'Sobald jemand Interesse bekundet hat, wird das Profil hier angezeigt.',
                closewarning:
                    'Dieser Artikel wird geschlossen. Mitglieder werden nicht mehr ihr Interesse bekunden können.',
                wassuccessful: 'War deine Suche erfolgreich?',
                foundhere: 'Hast du Interessierte in dieser Community gefunden?',
                reopenwarning: 'Deine Anzeige wird für 4 Wochen geöffnet sein.',
            },
        },
    },
    name: 'ViewJob',
    data() {
        return {
            tabIndex: 0,

            item: null,
            iAmAuthor: false,
            isAdmin: false,
            amIInterested: false,
            author: null,
            interested: [],
            loading: false,
            deleting: false,
            loadingForInterest: false,
            loadingReopen: false,
            prevRoute: null,
            interest: {
                UserID: '',
                JobID: '',
            },
            commentCount: 0,
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.prevRoute = from;
        });
    },
    mounted() {
        this.getItem();
    },
    computed: {
        category() {
            // it's done in reverse so old objects with undefined Category would automatically have Job Category
            if (this.item && this.item.Category === 2) {
                return 'Product';
            }
            if (this.item && this.item.Category === 1) {
                return 'Service';
            }
            return 'Job';
        },
        typeCatHeader() {
            return this.category;
        },
    },
    methods: {
        closeOffer() {
            const itemCloseRequest = {
                job_id: this.item.ID,
                success: this.item.Success,
                found_on_platform: this.item.FoundOnPlatform,
            };
            this.loadingForInterest = true;
            axios({
                method: 'DELETE',
                url: '/job',
                data: JSON.stringify(itemCloseRequest),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loadingForInterest = false;
                    const msg = {
                        Message: 'Item closed',
                        Details: '',
                    };
                    EventBus.$emit('INFO', msg);
                    this.item.Open = false;
                })
                .catch((error) => {
                    this.loadingForInterest = false;
                    EventBus.$emit('ERROR', {
                        Message: 'Could not close item! Please, report to team',
                        Details: null,
                    });
                });
        },
        getUsersInterestedInItem(itemID) {
            axios({
                method: 'GET',
                url: `/job/get-interested-users/${itemID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    const userIDs = [];
                    response.data.Interests.forEach(function (entry) {
                        const ids = JSON.stringify({ IDS: [{ ID: entry.UserID }] });

                        // if user already interested hide button
                        if (this.$store.state.me.ID === entry.UserID) {
                            this.amIInterested = true;
                        }

                        this.$store
                            .dispatch('listUsersByIDs', { ids })
                            .then((response) => {
                                if (response.Users) {
                                    entry.User = response.Users[0];
                                    this.interested.push(entry);
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }, this);
                })
                .catch((error) => {});
        },
        iAmInterested() {
            this.loadingForInterest = true;
            this.interest.JobID = this.item.ID;
            axios({
                method: 'POST',
                url: '/job/interest',
                data: JSON.stringify(this.interest),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loadingForInterest = false;
                    const msg = {
                        Message: this.$t('labels.interestedsent'),
                        Details: '',
                    };
                    EventBus.$emit('INFO', msg);
                    // this.$router.push('jobs')
                })
                .catch((error) => {
                    this.loadingForInterest = false;
                    EventBus.$emit('ERROR', {
                        Message: 'Your request failed with internal error. Please, report to team.',
                        Details: '',
                    });
                });
        },
        getItem() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/job/${this.$route.params.slug}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.item = response.data;
                    this.loading = false;

                    const created = new Date(this.item.CreatedAt * 1000);
                    const updated = new Date(this.item.LastUpdate * 1000);
                    this.item.CreatedAtStr = moment(created).format('DD.MM.YY');
                    this.item.LastUpdateStr = moment(updated).format('DD.MM.YY');

                    this.$store.state.me.ID === this.item.AuthorID ? (this.iAmAuthor = true) : (this.iAmAuthor = false);
                    this.isAdmin =
                        this.$store.state.me.Permissions &&
                        this.$store.state.me.Permissions.includes('space_admin') &&
                        this.$store.state.me.SpaceID === this.item.SpaceID;

                    this.getUser(this.item.AuthorID);
                    this.getUsersInterestedInItem(this.item.ID);
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.message.includes('404')) {
                        this.$router.replace('/feed/error404');
                    }
                });
        },
        editItem() {
            this.$router.push(`/job/edit/${this.item.Slug}`);
        },

        viewUser(slug) {
            this.$router.push(`/profile/${slug}`);
        },

        getUser(id) {
            const ids = JSON.stringify({ IDS: [{ ID: id }] });
            this.$store
                .dispatch('listUsersByIDs', { ids })
                .then((response) => {
                    this.author = response.Users[0];
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        filterPictures(imageurl) {
            const randomItem = parseInt(Math.random() * 16) + 1;
            const images = require.context('../../assets/gradients/', true);
            if (!imageurl) {
                return images(`./Platform_Gradients-${randomItem}.jpg`);
            }
            return imageurl;
        },
        deleteJobAdmin(bvModalEvt) {
            // Prevent modal from closing
            // bvModalEvt.preventDefault()

            this.showOverlay = true;
            this.deleting = true;
            const req = JSON.stringify(this.item);
            this.$store
                .dispatch('deleteJobAdmin', req)
                .then((response) => {
                    setTimeout(() => {
                        this.deleting = false;
                        const message = {
                            Message: 'Job deleted',
                            Details: null,
                        };
                        EventBus.$emit('INFO', message);
                        this.$router.push('/jobs');
                        this.$bvModal.hide('deleteJobModal');
                    }, 2000);
                })
                .catch((error) => {
                    this.deleting = false;
                    if (error.response.data && error.response.data.message) {
                        EventBus.$emit('ERROR', {
                            Message: error.response.data.message,
                            Details: error.response.data.details,
                        });
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: 'Could not delete job. Please try again later.',
                            Details: null,
                        });
                    }
                });
        },
        reopenItem() {
            this.loadingReopen = true;
            axios({
                method: 'POST',
                url: '/job/open',
                data: JSON.stringify(this.item),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loadingReopen = false;
                    const msg = {
                        Message: 'Item reopened',
                        Details: '',
                    };
                    EventBus.$emit('INFO', msg);
                    this.$set(this.item, 'Open', true);
                    this.$refs['reopen-modal'].hide();
                })
                .catch((error) => {
                    this.loadingReopen = false;
                    EventBus.$emit('ERROR', {
                        Message: 'Could not reopen item! Please, try again later or report to team',
                        Details: null,
                    });
                });
        },
    },
};
</script>
