<template>
    <div class="">
        <b-overlay :show="isLoading">
            <div class="mb-3">
                <Editor
                    id="textarea-default"
                    :placeholder="$t('placeholders.updatecreate')"
                    rows="5"
                    class=""
                    ref="tiptap"
                    v-model="text"
                    with-image
                    v-bind:imageFiles="attachments"
                ></Editor>
            </div>

            <div class="row justify-content-end">
                <b-button class="my-2 mx-1" variant="outline-primary" @click="hide">{{ $t('labels.cancel') }}</b-button>

                <b-button
                    class="my-2 mx-1"
                    variant="primary"
                    v-on:click="saveChanges"
                    :disable="!text || text === '<p></p>'"
                >
                    <span class="align-items-center">{{ $t('labels.save') }}</span>
                </b-button>
            </div>
        </b-overlay>
    </div>
</template>

<script>
import EventBus from '@/eventBus';
import Vue from 'vue';

export default {
    name: 'EditProjectUpdate',
    data() {
        return {
            isLoading: false,
            attachments: [],
            attachmentsURLs: [],
            text: '',
            update: {},
        };
    },
    props: ['user', 'projectID', 'oldUpdate', 'hide'],
    computed: {},

    watch: {
        // previous update text
        oldUpdate(newVal, oldVal) {
            this.update = newVal;
            if (newVal) {
                this.text = newVal.Text;
            }
        },

        attachmentsURLs(newVal, oldVal) {
            if (newVal.length == this.attachments.length) {
                this.postUpdate();
            }
        },
    },

    mounted() {
        this.update = JSON.parse(JSON.stringify(this.oldUpdate));
        this.text = this.update.Text;
        if (this.update.ImageURLs != null) {
            this.update.ImageURLs.forEach((element) => {
                this.attachments.push({
                    Preview: element,
                });
            });
        }

        this.$nextTick(() => {
            this.$refs.tiptap.focus();
        });
    },
    updated() {},
    methods: {
        uploadImages() {
            this.attachments.forEach(function (entry, index) {
                if (entry.File != null) {
                    this.$store
                        .dispatch('imageUpload', {
                            file: entry.File,
                            url: '/upload/image/project-update',
                        })
                        .then((response) => {
                            this.attachmentsURLs.push(response);
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            const errorMsg = {
                                Message: 'Could not upload one or more images, please try again or check details',
                                Details: error.response.data,
                            };
                            if (
                                error.response &&
                                error.response.data &&
                                error.response.data.message === 'Request Entity Too Large'
                            ) {
                                errorMsg.Message = 'The file you tried to upload is too large. Max 10 MB.';
                            }
                            EventBus.$emit('ERROR', errorMsg);
                        });
                } else {
                    this.attachmentsURLs.push(entry.Preview);
                }
            }, this);
        },

        saveChanges(event) {
            this.isLoading = true;
            this.update.Text = this.text.replace(/<p><\/p>/g, '<br>');
            this.update.Version = '1';

            if (this.attachments.length !== 0) {
                this.uploadImages();
            } else {
                this.postUpdate();
            }
        },

        postUpdate() {
            this.update.ImageURLs = this.attachmentsURLs;
            const update = JSON.stringify(this.update);
            this.$store
                .dispatch('updateProjectUpdate', update)
                .then((response) => {
                    setTimeout(() => {
                        this.isLoading = false;

                        const infoMsg = {
                            Message: this.$t('labels.changessaved'),
                            Details: null,
                        };
                        EventBus.$emit('INFO', infoMsg);
                        EventBus.$emit('UPDATEEDITSAVED', null);
                        this.hide();
                    }, 200);
                }, this)
                .catch((error) => {
                    const errorMsg = {
                        Message: 'Could not upload update, please try again or check details',
                        Details: error.response.data,
                    };
                    EventBus.$emit('ERROR', errorMsg);
                });
        },
    },
};
</script>

<style scoped>
.upload {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.upload input[type='file'] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    font-size: 36px;
}

.attachments {
    display: block;
    width: 100%;
}

.attachments .item {
    margin: 0px 5px 0px 5px;
    overflow: hidden;
    background: #212121;
    display: inline-block;
    background-color: #cccccc;
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100px;
    padding-top: 100px;
    position: relative;
}

.delete {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.2em !important;
    line-height: 1.5em !important;
}
</style>
