<template>
    <div>
        <!-- plan upgrade start -->
        <div class="p-0 pl-sm-4 m-0">
            <b-overlay :show="loading" class="round-unify my-4">
                <div class="bg-white round-unify p-4">
                    <div class="d-flex justify-content-between align-items-start">
                        <div class="mb-2">
                            <p class="mb-0"><small>Name</small></p>
                            <p>{{ form.Name }}</p>
                        </div>
                        <CoDropdown to-right disable-propagation>
                            <template v-slot:button>
                                <CoRoundButton icon="three-dots" variant="icon" />
                            </template>
                            <CoDropdownItem @click="edit(form.ID)">Edit</CoDropdownItem>
                        </CoDropdown>
                    </div>
                    <div class="mb-2">
                        <p class="mb-0"><small>Description</small></p>
                        <p v-if="form.Description">{{ form.Description }}</p>
                        <p v-else>not defined</p>
                    </div>
                    <div class="mb-2">
                        <p class="mb-0"><small>Price</small></p>
                        <p v-if="FinalPrice">{{ FinalPrice }} {{ form.Currency }}</p>
                        <p v-else>0 EUR</p>
                    </div>

                    <div class="mb-2">
                        <p class="mb-0"><small>Created at</small></p>
                        <p>
                            {{ (form.CreatedAt + '000') | readabledate }},
                            {{ (form.CreatedAt + '000') | readabletime }}
                        </p>
                    </div>
                    <div class="mb-2">
                        <p class="mb-0"><small>Updated at</small></p>
                        <p>
                            {{ (form.UpdatedAt + '000') | readabledate }},
                            {{ (form.UpdatedAt + '000') | readabletime }}
                        </p>
                    </div>
                </div>
                <div
                    v-if="form.Versions && form.Versions.length !== 0"
                    class="bg-white round-unify px-4 py-3 mt-4 d-flex justify-content-between align-items-center"
                >
                    <h2 class="">Change history</h2>
                    <b-button variant="border-0" @click="showHistory = !showHistory">
                        <b-icon v-if="showHistory" icon="chevron-up" class="m-0" scale="1.2"></b-icon>
                        <b-icon v-else icon="chevron-down" class="m-0" scale="1.2"></b-icon>
                    </b-button>
                </div>
                <div v-if="showHistory" class="">
                    <div class="bg-white round-unify p-4 mt-2" v-for="(item, i) in form.Versions" :key="item.ID + i">
                        <div class="mb-2">
                            <p class="mb-0"><small>Name</small></p>
                            <p>{{ item.Name }}</p>
                        </div>
                        <div class="mb-2">
                            <p class="mb-0"><small>Description</small></p>
                            <p v-if="item.Description">{{ item.Description }}</p>
                            <p v-else>not defined</p>
                        </div>
                        <div class="mb-2">
                            <p class="mb-0"><small>Price</small></p>
                            <p v-if="FinalPrice">{{ FinalPrice }} {{ item.Currency }}</p>
                            <p v-else>0 EUR</p>
                        </div>

                        <div class="mb-2">
                            <p class="mb-0"><small>Version from</small></p>
                            <p>
                                {{ (item.UpdatedAt + '000') | readabledate }},
                                {{ (item.UpdatedAt + '000') | readabletime }}
                            </p>
                        </div>
                    </div>
                </div>
            </b-overlay>
        </div>
        <!-- plan upgrades end -->
    </div>
</template>

<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';

import CoDropdown from '@/components/Molecules/co-dropdown/CoDropdown.vue';
import CoDropdownItem from '@/components/Molecules/co-dropdown-item/CoDropdownItem.vue';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';

export default {
    name: 'ViewPlanUpgrade',
    components: { CoDropdown, CoDropdownItem, CoRoundButton },
    data() {
        return {
            showHistory: false,
            id: this.$route.params.ID,
            Price: 0.0,
            FinalPrice: null,
            TaxRate: 19.0,
            form: {
                Currency: 'EUR',
            },
            loading: false,
        };
    },
    mounted() {
        this.getPlanUpgrade(this.id);
    },

    methods: {
        hasHistory() {
            return window.history.length > 2;
        },
        edit(id) {
            this.$router.push(`/admin/monetization/plan-upgrade/edit/${id}`);
        },
        getPlanUpgrade(id) {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/extra/${id}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.form = response.data;
                        if (response.data.PriceInCents) {
                            this.Price = response.data.PriceInCents / 100.0;
                        } else {
                            this.Price = 0.0;
                        }
                    }
                    this.getTaxRates();
                    this.loading = false;
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: 'Failed to get plan upgrade',
                        Details: error,
                    });
                    this.loading = false;
                });
        },
        getTaxRates() {
            this.loadingTaxRate = true;
            axios({
                method: 'GET',
                url: `/admin/tax-rate/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.taxRates = response.data;
                    }
                    this.priceChange();
                    this.loadingTaxRate = false;
                })
                .catch((error) => {
                    this.loadingTaxRate = false;
                    console.log(error);
                });
        },
        priceChange() {
            if (this.form.TaxRateID) {
                this.calculateFinalPrice(this.form.TaxRateID);
            }
        },
        calculateFinalPrice(taxID) {
            const tax = this.taxRates.find((item) => item.ID === taxID);
            if (tax.Inclusive) {
                this.FinalPrice = this.Price;
            } else {
                this.FinalPrice = (this.Price * (tax.Percentage / 100 + 1)).toFixed(2);
            }
        },
    },
};
</script>
