<template>
    <div>
        <CoLabel style="margin-left: 0.5em" :text="label" />
        <CoTextArea v-model="Interests" :placeholder="placeholder" @input="valueChanged" :rows="rows" />
    </div>
</template>

<script>
import EventBus from '@/eventBus';

import CoLabel from '@/components/Atoms/co-label/CoLabel';
import CoTextArea from '@/components/Molecules/co-TextArea/CoTextArea';

import { clone } from 'lodash';

export default {
    name: 'CoUserBioEdit',
    props: {
        user: Object,
        placeholder: String,
        label: String,
        rows: Number,
    },
    data() {
        return {
            Interests: '',
        };
    },
    emits: ['saved'],
    components: {
        CoTextArea,
        CoLabel,
    },
    mounted() {
        // next tick to make sure the value is set before the input is focused
        this.$nextTick(() => {
            this.Interests = this.user.Profile.Interests;
        });
    },
    emits: ['saved'],
    components: {
        CoTextArea,
        CoLabel,
    },
    methods: {
        valueChanged(e) {
            this.Interests = e;
        },
        saveChanges() {
            if (!this.Interests) {
                this.Interests = '';
            } else {
                this.Interests = this.Interests.trim();
            }

            const user = clone(this.user);

            user.Profile.Interests = this.Interests;

            this.$store
                .dispatch('updateUser', user)
                .then((response) => {
                    const message = {
                        Message: this.$t('labels.profileupdated'),
                        Details: '',
                        Source: 'Profile Update',
                    };
                    EventBus.$emit('INFO', message);
                    this.$emit('saved', this.Interests);
                })
                .catch((error) => {
                    // const message = {
                    //     Message: `Could not update profile. Error: ${error.response.data}`,
                    //     Details: error.response.data,
                    // };
                    // EventBus.$emit('ERROR', message);
                    this.$emit('failed', this.Interests);
                })
                .finally(() => {
                    this.$emit('saved', this.Interests);
                });
        },
    },
};
</script>

<style></style>
