


















import CoText from '@/components/Atoms/co-text/CoText.vue';
import CoLink from '@/components/Atoms/co-link/CoLink.vue';

// CoBreadcrumbItem class
interface CoBreadcrumbItem {
    text: string;
    to: string;
    disabled: boolean;
    active: boolean;
}

export default {
    name: 'CoBreadcrumbs',
    components: {
        CoText,
        CoLink,
    },
    props: {
        items: {
            type: Array as () => Array<CoBreadcrumbItem>,
            default: () => [],
        },
    },
    computed: {
        breadcrumbs() {
            // if items are more than 3, then show last 3 items
            if (this.items.length > 3) {
                const items = this.items.slice(this.items.length - 3, this.items.length);
                // get 4th item from the last
                const fourthItem = this.items[this.items.length - 4];
                fourthItem.text = '...';
                return [fourthItem, ...items];
            }
            return this.items;
        },
    },
    methods: {
        goto(item: CoBreadcrumbItem) {
            if (item.disabled) {
                return;
            }
            this.$router.push(item.to);
        },
    },
};
