










































import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoLabel from '../../Atoms/co-label/CoLabel.vue';

export default {
    name: 'CoFileSelect',
    components: {
        CoIcon,
        CoLabel,
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            type: [String, Object, File, null],
            default: null,
            required: false,
        },
        type: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            content: null,
            imageURL: '',
        };
    },
    methods: {
        initFileSelect() {
            if (this.readonly) return;

            document.getElementById('file-input').click();
        },
        handleInput(e) {
            if (e && e.target && e.target.files && e.target.files[0]) {
                this.content = e.target.files[0];
                this.imageURL = this.content.name;
                this.$emit('input', this.content);
            }
        },
    },
};
