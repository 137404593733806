<template>
    <div>
        <nav class="navbar navbar-expand-lg navbar-light d-flex align-items-center p-0 my-3">
            <h1>Event editing</h1>
        </nav>
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-10 my-3 bg-white p-5 round-unify">
                    <b-form>
                        <b-form-group
                            id="input-group-title"
                            label="Title:"
                            label-for="input-title"
                            description="Title for event."
                        >
                            <b-form-input
                                id="input-title"
                                v-model="form.Title"
                                class="rounded-pill"
                                type="text"
                                required
                                placeholder="Enter event title"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="description"
                            label="Description:"
                            label-for="textarea-description"
                            description="Event`s description"
                        >
                            <div class="bg-white">
                                <Editor
                                    id="textarea-default"
                                    placeholder="Description"
                                    taclass="m-0 comment-input"
                                    rows="5"
                                    ref="tiptap"
                                    v-model="form['Description']"
                                    expanded
                                ></Editor>
                            </div>
                            <!-- <b-form-textarea
id="textarea-description"
v-model="form.Description"
placeholder="Enter some description..."
rows="3"
max-rows="6"
no-auto-shrink

></b-form-textarea> -->
                        </b-form-group>
                        <b-form-group
                            id="input-group-organizers"
                            label="Organizers:"
                            label-for="input-organizers"
                            description="Event's organizers"
                        >
                            <div class="d-inline-block mb-2" v-if="organizers.length > 0">
                                <b-form-tag
                                    v-on:click.stop
                                    v-for="organizer in organizers"
                                    @remove="removeOrganizer(organizer)"
                                    :key="organizer.ID"
                                    variant="primary"
                                    class="mr-1"
                                >
                                    <ProfileCircleWithoutRedirect
                                        :ImageURL="organizer.Profile.ImageURL"
                                        :Slug="organizer.Slug"
                                        :Name="organizer.Profile.Name"
                                    ></ProfileCircleWithoutRedirect>
                                </b-form-tag>
                            </div>
                            <input
                                type="text"
                                ref="usersSearchInput"
                                @input="searchUsers"
                                placeholder="Add a new organizer"
                                class="form-control rounded-pill d-block"
                            />
                            <b-list-group class="mt-2">
                                <b-list-group-item
                                    href="#"
                                    @click="addOrganizer(user)"
                                    v-for="user in searchedUsers"
                                    :key="user.ID"
                                >
                                    <ProfileCircle
                                        :ImageURL="user.Profile.ImageURL"
                                        :Name="user.Profile.Name"
                                    ></ProfileCircle>
                                </b-list-group-item>
                            </b-list-group>
                        </b-form-group>

                        <b-form-group
                            id="input-group-location"
                            label="Location:"
                            label-for="input-location"
                            description="Event`s location"
                        >
                            <b-form-select v-model="form.Location" class="rounded-pill">
                                <option :value="null" selected>Select location</option>
                                <option
                                    v-for="(selectOption, indexOpt) in locations"
                                    :key="indexOpt"
                                    :value="selectOption.Name"
                                >
                                    {{ selectOption.Name }}
                                </option>
                            </b-form-select>
                        </b-form-group>

                        <b-form-group
                            id="input-group-action-url"
                            label="Booking url:"
                            label-for="input-action-url"
                            description="URL to eventbrite or meetup "
                        >
                            <b-form-input
                                id="input-action-url"
                                v-model="form.FullURL"
                                type="text"
                                class="rounded-pill"
                                placeholder="Enter event booking url"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            id="input-group-tags"
                            label="Tags:"
                            label-for="input-tags"
                            description="Event tags"
                        >
                            <vue-tags-input
                                id="input-tags"
                                class="mt-0"
                                v-model="tag"
                                :tags="tags"
                                placeholder="type anything"
                                :separators="separators"
                                :autocomplete-items="autocompleteItems"
                                :add-only-from-autocomplete="false"
                                @tags-changed="updateTags"
                                @before-adding-tag="addTag"
                            />
                            <p>
                                Tags:
                                <span v-for="(item, i) in tags" :key="i">{{ item.text }}, </span>
                            </p>
                        </b-form-group>

                        <b-form-group
                            id="input-group-event-type"
                            label="Type"
                            label-for="select-type"
                            description="Type of event"
                        >
                            <b-form-select
                                id="select-type"
                                v-model="selectedType"
                                :options="eventTypes"
                                class="rounded-pill"
                            >
                                <b-form-select-option :value="null">Please select a type</b-form-select-option>
                            </b-form-select>
                        </b-form-group>

                        <b-form-group
                            id="input-group-event-category"
                            label="Category"
                            label-for="select-category"
                            description="Category of event"
                        >
                            <b-form-select
                                id="select-category"
                                v-model="selectedCategory"
                                :options="eventCategories"
                                class="rounded-pill"
                            >
                                <b-form-select-option :value="null">Please select a category</b-form-select-option>
                            </b-form-select>
                        </b-form-group>

                        <b-form-group
                            id="input-group-event-start"
                            label="Start date"
                            label-for="select-start"
                            description="Start date of event"
                        >
                            <b-form-datepicker
                                id="select-start"
                                v-model="startDate"
                                class="mb-2 rounded-pill"
                            ></b-form-datepicker>
                        </b-form-group>
                        <b-form-group
                            id="input-group-start-time"
                            label="Start time:"
                            label-for="input-start-time"
                            description="Event start time in 24 hour format"
                        >
                            <b-form-input
                                id="input-start-time"
                                v-model="startTime"
                                type="time"
                                class="rounded-pill"
                                required
                            >
                            </b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-event-end"
                            label="End date"
                            label-for="select-end"
                            description="End date of event"
                        >
                            <b-form-datepicker
                                id="select-end"
                                v-model="endDate"
                                class="mb-2 rounded-pill"
                            ></b-form-datepicker>
                        </b-form-group>
                        <b-form-group
                            id="input-group-end-time"
                            label="End time:"
                            label-for="input-end-time"
                            description="Event end time in 24 hour format"
                        >
                            <b-form-input
                                id="input-end-time"
                                v-model="endTime"
                                type="time"
                                class="rounded-pill"
                                required
                            >
                            </b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-featured"
                            label="Published on Hafven.de"
                            label-for="checkbox-featured"
                            description="If on event will be synced with Hafven.de"
                            v-if="
                                this.$store.state.space.ID ===
                                'c97c5a2253a56e8e9027881658c37c54a458bd1a2b5d59a81bacde6a52f42af5'
                            "
                        >
                            <b-form-checkbox id="checkbox-featured" v-model="form['Featured']" name="featured">
                                I want to publish this event on Hafven.de
                            </b-form-checkbox>
                        </b-form-group>

                        <b-form-group id="input-group-image" label="Image:" label-for="input-image">
                            <b-form-file
                                accept="image/*"
                                id="input-image"
                                @change="previewImage"
                                ref="featuredimage"
                            ></b-form-file>
                            <div class="mb-1">
                                <small
                                    ><strong>Image Guidelines:</strong> Please avoid having text inside your image. The
                                    recommended image aspect ratio is 3x2 and the recommended image size is 1800x1200.
                                    Maximal filesize is 2MB.
                                </small>
                            </div>
                            <b-img v-if="form['ImageURL']" thumbnail fluid :src="form['ImageURL']"></b-img>
                        </b-form-group>

                        <b-button variant="primary" @click="update">Update</b-button>
                    </b-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import VueTagsInput from '@johmun/vue-tags-input';
import { McWysiwyg } from '@mycure/vue-wysiwyg';
import VueMoment from 'vue-moment';
import EventBus from '../../../eventBus';

const moment = require('moment');
Vue.use(require('vue-moment'), {
    moment,
});

export default {
    name: 'EventEdit',
    components: {
        VueTagsInput,
        McWysiwyg,
    },
    data() {
        return {
            loading: false,
            startDate: null,
            startTime: null,
            endDate: null,
            endTime: null,
            form: {},

            eventTypes: [],
            eventCategories: [],
            selectedType: null,
            selectedCategory: null,
            date: null,

            // variables for tag input
            tag: '',
            tags: [],
            autocompleteItems: [],
            separators: [';', ',', '#', ', #'],
            debounce: null,
            // ---

            // images
            featuredImage: null,
            featuredImageURL: '',

            locations: [],
            searchedUsers: [],
            organizers: [],
            organizersIDs: [],
        };
    },
    created() {
        this.getTypes();
        this.getCategories();
        this.getEvent();
        this.listLocations();
    },
    watch: {
        tag: 'loadAutocompleteItems',
    },
    methods: {
        listLocations() {
            axios({
                method: 'GET',
                url: '/admin/location/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data && response.data.Locations.length !== 0) {
                        this.locations = response.data.Locations;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        // method load categories
        getCategories(next) {
            this.$store
                .dispatch('listEventCategories', next)
                .then((response) => {
                    if (response.objects) {
                        response.objects.forEach(function (entry) {
                            const tag = {
                                text: entry.Value,
                                slug: entry.Slug,
                                value: entry.Slug,
                            };

                            this.eventCategories.push(tag);
                        }, this);
                        if (response.next !== '' && response.next != null) {
                            this.getCategories(response.next);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // todo show error
                });
        },

        // method load types
        getTypes(next) {
            this.$store
                .dispatch('listEventTypes', next)
                .then((response) => {
                    if (response.objects) {
                        response.objects.forEach(function (entry) {
                            const tag = {
                                text: entry.Value,
                                slug: entry.Slug,
                                value: entry.Slug,
                            };

                            this.eventTypes.push(tag);
                        }, this);
                        if (response.next !== '' && response.next != null) {
                            this.getTypes(response.next);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // todo show error
                });
        },

        // methode for images
        uploadImages(callback) {
            const infoMsg = {
                Message: 'Uploading images',
                Details: null,
            };
            EventBus.$emit('INFO', infoMsg);

            this.$store
                .dispatch('imageUpload', {
                    file: this.featuredImage,
                    url: '/dashboard/image/events',
                })
                .then((response) => {
                    this.form.ImageURL = response;
                    this.form.Images = [response];
                    callback();
                })
                .catch((error) => {
                    console.log(error);
                    const errorMsg = {
                        Message: 'Could not upload one or more images, please try again or check details',
                        Details: '',
                    };
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.message === 'Request Entity Too Large'
                    ) {
                        errorMsg.Message = 'The file you tried to upload is too large. Max 10 MB.';
                    }
                    EventBus.$emit('ERROR', errorMsg);
                });
        },

        // method for event
        update() {
            this.loading = true;

            if (this.featuredImage) {
                this.uploadImages(this.saveChanges);
            } else {
                this.saveChanges();
            }
        },

        getEvent() {
            this.$store
                .dispatch('getEventBySlug', this.$route.params.slug)
                .then((response) => {
                    this.form = response;
                    this.loadTags(this.form.Tags);

                    this.startDate = new Date(response.StartDate * 1000);
                    this.endDate = new Date(response.EndDate * 1000);

                    this.startTime = moment(this.startDate).format('HH:mm');
                    this.endTime = moment(this.endDate).format('HH:mm');

                    if (response.EventType && response.EventType.length !== 0) {
                        this.selectedType = response.EventType[0];
                    }

                    if (response.EventCategory && response.EventCategory.length !== 0) {
                        this.selectedCategory = response.EventCategory[0];
                    }
                    this.getOrganizers(response.Organizers);
                })
                .catch((error) => {
                    if (error.message.includes('404')) {
                        this.$router.replace('/feed/error404');
                    }
                });
        },
        getOrganizers(userIds) {
            if (userIds.length > 0) {
                this.$store
                    .dispatch('listUsersByIDs', {
                        ids: { IDS: userIds.map((id) => ({ ID: id })) },
                    })
                    .then((response) => {
                        this.organizers = response.Users;
                        this.organizersIDs = this.organizers.map((org) => org.ID);
                    });
            }
        },
        saveChanges() {
            const object = this.form;

            if (object.Description) {
                object.Description = object.Description.replace(/<p><\/p>/g, '<br>');
            }

            const hashtags = [];
            this.tags.forEach((entry) => {
                hashtags.push(entry.slug);
            }, hashtags);

            object.Tags = hashtags;
            if (this.selectedCategory) {
                object.EventCategory = [this.selectedCategory];
            }

            if (this.selectedType) {
                object.EventType = [this.selectedType];
            }

            let startDate = moment(this.startDate);
            let endDate = moment(this.endDate);
            const startTime = this.startTime.split(':');
            const endTime = this.endTime.split(':');

            // object["StartDate"] = new Date(

            startDate.hours(parseInt(startTime[0], 10));
            startDate.minutes(parseInt(startTime[1], 10));

            startDate = startDate.unix().toString();
            object.StartDate = startDate;

            endDate.hours(parseInt(endTime[0], 10));
            endDate.minutes(parseInt(endTime[1], 10));

            endDate = endDate.unix().toString();
            object.EndDate = endDate;
            object.Organizers = this.organizersIDs;

            this.$store
                .dispatch('adminUpdateEvent', object)
                .then((response) => {
                    const message = {
                        Message: 'Event updated',
                        Details: '',
                    };
                    EventBus.$emit('INFO', message);
                    this.form = response;
                    this.loading = false;
                })
                .catch((error) => {
                    const message = {
                        Message: 'Event updated failed',
                        Details: error,
                    };
                    EventBus.$emit('ERROR', message);
                    this.loading = false;
                });
        },

        // methodes for images
        previewImage(event) {
            this.featuredImage = event.target.files[0];
        },
        // ---

        // methods for tag input
        loadTags(slugs) {
            if (slugs == null) {
                return false;
            }
            const newArray = slugs.filter((el) => el !== '');
            const IDs = JSON.stringify({ IDs: newArray });
            this.$store
                .dispatch('getTagsByIDs', IDs)
                .then((response) => {
                    response.forEach(function (entry) {
                        const tag = {
                            text: entry.Value,
                            slug: entry.Slug,
                        };
                        this.tags.push(tag);
                    }, this);
                    return true;
                })
                .catch((error) => {
                    console.log('error: ', error);
                    return false;
                });
            return false;
        },

        addTag(obj) {
            const tag = JSON.stringify({ Value: obj.tag.text });
            this.$store
                .dispatch('createTag', tag)
                .then((response) => {
                    const tag2 = {
                        text: response.Value,
                        slug: response.Slug,
                    };
                    this.tags.push(tag2);
                    this.tag = '';
                    return true;
                })
                .catch((error) => {
                    console.log('error: ', error);
                    return false;
                });
        },

        updateTags(newTags) {
            this.autocompleteItems = [];
            this.tags = newTags;
        },

        loadAutocompleteItems() {
            this.autocompleteItems = [];
            if (this.tag.length < 2) return;
            this.$store
                .dispatch('searchTag', this.tag)
                .then((response) => {
                    response.forEach(function (entry) {
                        const tag = {
                            text: entry.Slug,
                            slug: entry.Slug,
                        };
                        this.autocompleteItems.push(tag);
                    }, this);
                    return true;
                })
                .catch((error) => {
                    console.log('error: ', error);
                    return false;
                });
        },
        searchUsers(e) {
            if (e.target.value.length < 2) {
                this.searchedUsers = [];
                return;
            }
            this.$store
                .dispatch('searchUser', { input: e.target.value })
                .then((response) => {
                    this.searchedUsers = response.data.filter((us) => !this.organizersIDs.includes(us.ID));
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        addOrganizer(user) {
            this.organizers = [...this.organizers, user];
            this.organizersIDs = [...this.organizersIDs, user.ID];
            this.searchedUsers = [];
            this.$refs.usersSearchInput.value = '';
        },
        removeOrganizer(organizer) {
            this.organizers = this.organizers.filter((o) => o.ID !== organizer.ID);
            this.organizersIDs = this.organizersIDs.filter((id) => id !== organizer.ID);
        },
    },
};
</script>
