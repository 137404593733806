<style lang="less" scoped>
.list-item {
    background: white;
    margin-bottom: 20px;
    cursor: pointer;

    @media (min-width: 768px) {
        padding: 20px;
    }

    .picture {
        width: 100%;
        @media (min-width: 768px) {
            width: 120px;
            height: 120px;
        }
    }

    .badg {
        font-weight: 400;
        background: transparent;
    }
    .past {
        outline: 1px solid var(--c-red);
        color: var(--c-red);
    }

    .ongoing {
        outline: 1px solid var(--c-purple);
        color: var(--c-purple);
    }

    .item-data {
        padding: 20px;
        @media (min-width: 768px) {
            padding: 0px;
        }
    }

    .info {
        margin-left: 20px;
        @media (min-width: 768px) {
            margin-left: 0px;
        }
    }

    &:hover .picture {
        opacity: 0.7;
    }
}
</style>

<template>
    <div
        class="list-item round-unify"
        v-bind:class="{
            knowledge: isKnowledge,
            event: isEvent,
            community: isCommunity,
            projects: isProject,
            job: isJob,
            withImage:
                (isProject && object.object.ImageURL) ||
                (object.type === 'user' && object.object.Profile && object.object.Profile.ImageURL) ||
                (object.type === 'event' && object.object.ImageURL) ||
                (object.type === 'knowledge' && object.object.PictureURL),
            profileWithImage:
                isCommunity &&
                object.object.Profile &&
                object.object.Profile.ImageURL &&
                object.object.Profile.ImageURL !=
                    'https://s3.eu-central-1.amazonaws.com/hafven-community-app-uploads/uploads/avatars/-medium.jpg',
        }"
        v-on:click="
            viewItem(object.object.ID, object.type, object.object.FullURL, object.object.ActionLink, object.object.Slug)
        "
        style="width: 100%"
    >
        <div class="d-md-flex align-items-start">
            <div class="picture-info">
                <div class="picture">
                    <LazyLoadImg
                        :src="imageURL"
                        :relativeHeight="isDesktop ? 100 : 100"
                        class="card-image-container"
                        :profilePlaceholder="isCommunity"
                        :darkVariant="isCommunity"
                        :round="isCommunity && isDesktop"
                        alt=""
                        style=""
                    >
                    </LazyLoadImg>
                </div>
            </div>
            <div class="item-data flex-grow-1 ml-md-4">
                <div class="d-flex w-100 flex-column justify-content-between align-items-start">
                    <div class="d-flex w-100 align-items-start">
                        <h2 class="ml-0">{{ name }}</h2>
                        <div class="ml-auto mr-0" v-if="pastEvent()">
                            <h5>
                                <b-badge pill variant="primary" class="ml-auto mr-0 pl-3 pr-3 badg past">Past</b-badge>
                            </h5>
                        </div>
                        <div class="ml-auto mr-0" v-if="onGoingEvent()">
                            <h5>
                                <b-badge pill disabled variant="succes" class="ml-auto pl-3 pr-3 mr-0 badg ongoing"
                                    >Ongoing</b-badge
                                >
                            </h5>
                        </div>
                    </div>

                    <div class="info-mobile small mb-3 text-md-center">
                        <b-icon class="" :icon="this.iconMap[this.object.type]"></b-icon
                        ><span class="ml-1">{{ this.typeMap[this.object.type] }} </span>
                    </div>

                    <!-- TODO: CTA-Action Button finish. Remove v-if in next line to show buttons -->
                    <div v-if="false" class="list-cta mb-3 mb-md-0 mt-md-0">
                        <!-- <span v-if="isCommunity" class="cta-1 mr-3 link text-primary"
            @click.prevent.stop="doAction('add action for message here')"
            ><b-icon class="mr-1" icon="envelope"></b-icon>message</span> -->

                        <!-- <span v-if="isJob" class="cta-1 mr-3 link text-primary"
            @click.prevent.stop="doAction('add action for interest here')"
            ><b-icon class="mr-2" icon="envelope"></b-icon>I'm interested</span> -->

                        <span class="cta-2 link text-primary"><b-icon class="mr-1" icon="eye"></b-icon>view</span>

                        <!-- <b-button v-if="isCommunity" variant="outline-primary" class="mr-3"><b-icon icon="envelope"></b-icon> message</b-button> -->
                        <!-- <b-button variant="outline-primary"><b-icon icon="eye"></b-icon> view</b-button> -->
                    </div>
                </div>

                <div class="" v-if="isJob">
                    <div class="p">{{ trimString2(object.object.Summary) }}</div>
                </div>

                <div class="" v-if="isCommunity">
                    <div class="p"><span></span>{{ trimString(object.object.Profile.Interests) }}</div>
                    <div v-if="object.object.Profile.Tags" class="p mt-3">
                        <span
                            v-for="(tag, index) in object.object.Profile.Tags.slice(0, 10)"
                            :key="index"
                            class="color-2-link mr-2 mb-1"
                            @click.stop="$router.push('/search?term=' + tag)"
                            >#{{ tag }}
                        </span>
                    </div>
                </div>

                <div class="" v-if="isEvent">
                    <div class="">
                        <span></span>{{ (object.object.StartDate + '000') | readabledate }} |
                        {{ (object.object.StartDate + '000') | readabletime }}
                    </div>
                    <div v-if="object.object.Excerpt" class="p mt-3">
                        <span class=""></span> {{ object.object.Excerpt }}
                    </div>
                </div>

                <div class="" v-if="isKnowledge">
                    <div v-if="object.object.Description" class="p">
                        <span class=""></span> {{ trimString2(object.object.Description) }}
                    </div>
                </div>

                <div class="" v-if="isProject">
                    <div v-if="object.object.Description" class="p">
                        <span class=""></span> {{ trimString2(object.object.Description) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'Card',
    props: ['object'],
    data() {
        return {
            timer: null,
            currentIndex: 0,
            imageURL: 'default_picture',
            iconMap: {
                job: 'briefcase',
                knowledge: 'file-text',
                user: 'person-plus',
                event: 'soundwave',
                project: 'gem',
            },
            typeMap: {
                job: 'Job',
                knowledge: 'Knowledge',
                user: 'Community',
                event: 'Event',
                project: 'Project',
            },
            name: '',
            windowWidth: window.innerWidth,
        };
    },

    watch: {
        object(newVal, oldVal) {
            this.setCardImgUrl();
        },
    },

    computed: {
        icon() {
            this.iconMap[this.object.type];
        },
        description() {
            this.typeMap[this.object.type];
        },
        isJob() {
            return this.object.type == 'job';
        },
        isCommunity() {
            return this.object.type == 'user';
        },
        isKnowledge() {
            return this.object.type == 'knowledge';
        },
        isProject() {
            return this.object.type == 'project';
        },
        isEvent() {
            return this.object.type == 'event';
        },
        isDesktop() {
            return this.windowWidth >= 768;
        },
        tagsAsString() {
            // `this` points to the vm instance
            let tags = '';
            if (this.object.object.Profile.Tags) {
                this.object.object.Profile.Tags.forEach((entry) => {
                    tags = `${tags + entry}, `;
                }, tags);
            }
            return tags;
        },
    },

    mounted() {
        this.setCardImgUrl();
        this.setCardData();
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });
    },
    methods: {
        pastEvent() {
            const endEvent = `${this.object.object.EndDate}000`;
            const currentDate = Date.now();
            if (endEvent < currentDate) {
                return true;
            }
            return false;
        },
        onGoingEvent() {
            const endEvent = `${this.object.object.EndDate}000`;
            const startEvent = `${this.object.object.StartDate}000`;
            const currentDate = Date.now();
            if (endEvent > currentDate && startEvent < currentDate) {
                return true;
            }
            return false;
        },
        timeCssClasses(value) {
            const time = parseInt(value);
            const timeDelta = time - Date.now();
            const dayInMs = 86400000;
            const result = [];

            if (moment(time).isSame(moment(), 'day')) {
                result.push('today');
            } else if (moment(time).isSame(moment().add(1, 'days'), 'day')) {
                result.push('tomorrow');
            }
            return result;
        },
        trimString(string) {
            if (string == null) {
                return '';
            }
            if (string.length > 180) {
                string = `${string.substring(0, 180 - 1)}...`;
            }
            return string;
        },
        trimString2(string) {
            if (string == null) {
                return '';
            }
            if (string.length > 280) {
                string = `${string.substring(0, 280 - 1)}...`;
            }
            return string;
        },
        setCardData() {
            if (this.object.type === 'project') {
                this.name = this.object.object.Title;
            } else if (this.object.type === 'event') {
                this.name = this.object.object.Title;
            } else if (this.object.type === 'user') {
                this.name = this.object.object.Profile.Name;
            } else if (this.object.type === 'knowledge') {
                this.name = this.object.object.Title;
            } else if (this.object.type === 'job') {
                this.name = this.object.object.Title;
            } else {
                this.name = 'unnamed unicorn pizza';
            }
        },
        setCardImgUrl() {
            if (this.object.type === 'project' && this.object.object.ImageURL) {
                this.imageURL = this.object.object.ImageURL[0];
            } else if (this.object.type === 'event' && this.object.object.ImageURL) {
                this.imageURL = this.object.object.ImageURL;
            } else if (this.object.type === 'job' && this.object.object.ImageURL) {
                this.imageURL = this.object.object.ImageURL;
            } else if (
                this.object.type === 'user' &&
                this.object.object.Profile &&
                this.object.object.Profile.ImageURL &&
                this.object.object.Profile.ImageURL !=
                    'https://s3.eu-central-1.amazonaws.com/hafven-community-app-uploads/uploads/avatars/-medium.jpg'
            ) {
                this.imageURL = this.object.object.Profile.ImageURL;
            } else if (this.object.type === 'knowledge' && this.object.object.PictureURL) {
                this.imageURL = this.object.object.PictureURL;
            } else {
                this.imageURL = 'default_picture';
            }
        },
        cardHover(event) {
            // var parent = event.target.parent;
            // event.target.height = parent.height - (parent.height - event.target.height);
        },
        formatDate(value, format) {
            console.log(value, format);

            return moment(value).format('dd, DD.MM.YY, h:mm:ss');
        },
        viewItem(id, type, fullURL, actionLink, slug) {
            if (type === 'project') {
                this.$router.push(`/project/${slug}`);
            } else if (type === 'user') {
                this.$router.push(`/profile/${slug}`);
            } else if (type === 'event') {
                this.$router.push(`/event/${slug}`);
            } else if (type === 'knowledge') {
                window.open(actionLink);
            } else if (type === 'job') {
                this.$router.push(`/job/${slug}`);
            }
        },
        doAction(action) {
            alert(action);
        },
        filterPictures(imageurl) {
            const randomItem = parseInt(Math.random() * 16) + 1;
            const images = require.context('../../assets/gradients/', true);
            if (imageurl && imageurl.match(/\/$/gi)) {
                return images(`./Platform_Gradients-${randomItem}.jpg`);
            }
            return imageurl;
        },
    },
};
</script>
