









































































import Vuei18n from 'vue-i18n';
export default {
    i18n: {
        messages: {
            en: {
                messages: {
                    communityjoinsuccess: 'Congratulations! You are now a part of this awesome community.',
                    approvalpending: 'Thank you for signing up. Your account is pending approval.',
                    emailverificationsent: 'Check your inbox, please. We have sent a link to verify your email.',
                    // message to ask user to check email for verification so approval can be completed
                    emailverificationrequired:
                        'Please check your email for a verification link. You need to verify your email to continue the signup process.',
                },
            },
            de: {
                messages: {
                    communityjoinsuccess: 'Herzlichen Glückwunsch! Du bist jetzt Teil dieser großartigen Community.',
                    emailverificationsent:
                        'Bitte schau in dein E-Mail-Postfach. Wir haben dir einen Bestätigungslink gesendet.',
                    // message to ask user to check email for verification so approval can be completed
                    emailverificationrequired:
                        'Bitte überprüfe deine E-Mails auf einen Bestätigungslink. Du musst deine E-Mail-Adresse bestätigen, um den Anmeldeprozess fortzusetzen.',

                    approvalpending: 'Vielen Dank für deine Anmeldung. Dein Konto wird noch überprüft.',
                },
            },
        },
    },
    name: 'SignUpSuccess',
    data() {
        return {
            loading: true,
            signUpSettings: {},
        };
    },
    created() {
        this.getSignUpSettings();
    },
    methods: {
        getSignUpSettings() {
            // get signup settings
            this.$store
                .dispatch('getSignupSettings')
                .then((response) => {
                    this.signUpSettings = response.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
