




import EventBus from '@/eventBus.js';
import MembershipUpdate from '../../../../Templates/Admin/Membership/MembershipUpdate/MembershipUpdate.vue';

export default {
    name: 'CoAdminMembersPlanUpdate',
    components: {
        MembershipUpdate,
    },
    data() {
        return {
            userId: this.$route.params.ID,
            userName: 'User',
        };
    },
    created() {
        this.adminGetUserByID(this.userId);
    },
    methods: {
        adminGetUserByID(ID: string) {
            this.$store
                .dispatch('adminGetUserByID', ID)
                .then((response) => {
                    const user = response;

                    if (user.Profile && user.Profile.Name) {
                        this.userName = user.Profile.Name;
                    }
                })
                .catch((error) => {
                    const message = {
                        Message: 'Can`t get user data',
                        Details: error.response.data,
                    };
                    EventBus.$emit('ERROR', message);
                });
        },
    },
};
