<style>
/* To remove arrows in number input*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
</style>
<template>
    <div class="bg-white round-unify-xs mh-110 h-110 d-flex flex-column align-items-end">
        <!-- header -->
        <div class="p-3 text-center border-bottom align-self-start w-100">{{ $t('labels.billingaddress') }}</div>
        <!-- body -->
        <div class="align-self-start w-100 overflow-auto mh-100 p-3">
            <b-form ref="address_form">
                <b-form-group
                    id="company-name-input-group"
                    :label="$t('labels.company')"
                    label-for="company-name-input"
                    label-class="label-align mb-0"
                >
                    <b-form-input
                        id="company-name-input"
                        v-model="billingAddress.Company"
                        type="text"
                        class="form-control rounded-pill"
                        placeholder="Optional"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    id="additional-info-input-group"
                    :label="$t('labels.addressadditionalinformation')"
                    label-for="additional-info-input"
                    label-class="label-align mb-0"
                >
                    <b-form-input
                        id="additional-info-input"
                        v-model="billingAddress.AdditionalInformation"
                        type="text"
                        class="form-control rounded-pill"
                        placeholder="Optional"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    id="address-input-group"
                    :label="$t('labels.address')"
                    label-for="address-input"
                    label-class="label-align mb-0"
                >
                    <b-form-input
                        id="address-input"
                        v-model="billingAddress.Address"
                        type="text"
                        required
                        class="form-control rounded-pill"
                        :placeholder="$t('placeholders.address')"
                    ></b-form-input>
                </b-form-group>
                <div class="d-flex">
                    <b-form-group
                        id="postal-code-input-group"
                        :label="$t('labels.postalcode')"
                        label-for="postal-code-input"
                        label-class="label-align mb-0"
                        class="pr-1"
                    >
                        <b-form-input
                            id="postal-code-input"
                            v-model="billingAddress.PostalCode"
                            type="text"
                            class="form-control rounded-pill"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="city-input-group"
                        :label="$t('labels.city')"
                        label-for="city-input"
                        label-class="label-align mb-0"
                        class="pl-1"
                    >
                        <b-form-input
                            id="city-input"
                            v-model="billingAddress.City"
                            type="text"
                            class="form-control rounded-pill"
                            required
                        ></b-form-input>
                    </b-form-group>
                </div>

                <b-form-group
                    id="input-group-region"
                    :label="$t('labels.country')"
                    label-for="input-region"
                    label-class="label-align"
                >
                    <b-form-select
                        id="select-region"
                        v-model="billingAddress.Country"
                        required
                        class="form-control rounded-pill d-block"
                    >
                        <template #first>
                            <b-form-select-option :value="null" disabled selected>{{
                                $t('placeholders.selectplease')
                            }}</b-form-select-option>
                        </template>
                        <b-form-select-option
                            v-for="(item, key) in countries"
                            :value="item.Name"
                            :key="'country' + key"
                            >{{ item.Name }}</b-form-select-option
                        >
                    </b-form-select>
                </b-form-group>
                <div class="d-flex">
                    <b-form-group
                        id="postal-code-input-group"
                        :label="$t('labels.vatid')"
                        label-for="postal-code-input"
                        label-class="label-align mb-0"
                        class="pr-1"
                    >
                        <b-form-input
                            id="postal-code-input"
                            v-model="billingAddress.VAT"
                            class="form-control rounded-pill"
                            :placeholder="$t('placeholders.optional')"
                        ></b-form-input>
                    </b-form-group>
                </div>
            </b-form>
        </div>

        <!-- footer -->
        <div class="p-3 border-top d-flex justify-content-between mt-auto w-100">
            <coButton variant="secondary" @click="back" :label="$t('labels.back')" />
            <coButton variant="primary" @click="next" :label="$t('labels.next')" />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';

export default {
    name: 'BillingAddress',
    data() {
        return {
            billingAddress: {
                Company: '',
                Address: '',
                AdditionalInformation: '',
                PostalCode: '',
                City: '',
                Country: 'Germany',
                VAT: '',
            },
            countries: [],
        };
    },
    created() {
        this.listContries();
        if (this.$store.state.newMembership.Address) {
            this.billingAddress = this.$store.state.newMembership.Address;
        }
    },
    methods: {
        back() {
            this.$store.commit('PREVIOUS_STEP');
        },
        next() {
            if (this.$refs.address_form.checkValidity()) {
                this.$store.commit('SET_ADDRESS', this.billingAddress);
            } else {
                this.$refs.address_form.reportValidity();
            }
        },
        listContries() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/space/countries',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.countries = response.data;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: 'Failed to load countries',
                        Details: error,
                    });
                    this.loading = false;
                });
        },
    },
};
</script>
