<template>
    <div class="d-flex align-items-center">
        <a
            class="profile-avatar"
            :class="{ 'userlist-profile-link': isUserlist }"
            :href="IgnoreSlugPrefix ? Slug : '/profile/' + Slug"
            @click="goToThing($event)"
            v-if="show"
        >
            <LazyLoadImg
                :src="url"
                :relativeHeight="0"
                class="card-image-container h-100"
                round
                profile-placeholder
                dark-variant
                alt=""
                style=""
            >
            </LazyLoadImg>
            <!-- <span v-else class=""
            v-on:click="viewUser(Slug)"></span> -->
        </a>
        <span
            v-if="Name"
            class="ml-3 d-flex align-items-center pointer"
            :class="{ 'shorten-name': isUserlist }"
            v-on:click="viewUser(Slug)"
            >{{ Name }}</span
        >
    </div>
    <!-- v-if='ImageURL != null && ImageURL != "" && ImageURL != "https://s3.eu-central-1.amazonaws.com/hafven-community-app-uploads/uploads/avatars/-medium.jpg"' -->
</template>

<script>
import LazyLoadImg from './LazyLoadImg.vue';

export default {
    name: 'ProfileCircle',
    components: { LazyLoadImg },
    data() {
        return {
            show: true,
            url: null,
        };
    },

    props: {
        isUserlist: {
            type: Boolean,
            default: false,
        },
        ImageURL: {
            type: String,
            default: '',
        },
        Slug: {
            type: String,
            default: '',
        },
        Name: {
            type: String,
            default: '',
        },
        IgnoreSlugPrefix: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        ImageURL(newVal, oldVal) {
            this.show = false;
            this.show = true;
            this.url = newVal;
        },
    },

    mounted() {
        this.url = this.ImageURL;
    },

    methods: {
        viewUser(slug) {
            if (slug != null && slug != '') {
                this.IgnoreSlugPrefix ? this.$router.push(`${slug}`) : this.$router.push(`/profile/${slug}`);
            }
        },
        goToThing(ev) {
            if (this.Slug === null || this.Slug === undefined || this.Slug === '') {
                ev.preventDefault();
            }
        },
    },
};
</script>

<style>
.shorten-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
}

.userlist-profile-link {
    min-width: 36px !important;
}
</style>
