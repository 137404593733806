<style lang="less" scoped>
h3 {
    font-weight: bold;
}
</style>

<template>
    <div class="">
        <!-- header start -->
        <div class="d-flex align-items-center justify-content-between p-3">
            <CoHeadline :level="2" class="">{{ $t('labels.paymentmethods') }}</CoHeadline>
            <CoButton
                variant="primary"
                @click="$router.push('/account/billing/add-payment-method')"
                class="d-none d-md-block"
                :label="$t('labels.add')"
            />
        </div>
        <!-- header end -->
        <!-- payment methods -->
        <div class="container-fluid p-0 m-0">
            <!-- if emtpy and not loading -->
            <div class="row text-center m-0 mt-5" v-if="errorMsg && !loading">
                <div class="col">
                    <div class="mb-4">{{ errorMsg }}</div>
                </div>
            </div>
            <!-- if loading -->
            <div class="row text-center m-0 mt-5" v-else-if="loading">
                <div class="col">
                    <b-spinner variant="primary" label="loading"></b-spinner>
                </div>
            </div>
            <div class="m-0 mt-2 px-sm-4">
                <PaymentMethod
                    v-for="pm of paymentMethods"
                    :key="pm.ID"
                    :object="pm"
                    class="mt-2"
                    @detach="deleteMethodFromList"
                    :userID="accountID"
                    @setDefault="refresh"
                ></PaymentMethod>
                <CoButton
                    variant="primary"
                    @click="$router.push('/account/billing/add-payment-method')"
                    class="d-md-none mt-3"
                    :label="$t('labels.add')"
                />
            </div>
        </div>
        <!-- payment methods -->

        <!-- modals -->
        <b-modal id="create_new_payment_method" :title="$t('labels.paymentmethodadd')" centered>
            <div id="message" class="text-center">
                <!-- Display status message to customers here -->
            </div>
            <form id="payment-form">
                <div id="payment-element">
                    <!-- Elements will create form elements here -->
                </div>
                <div id="error-message">
                    <!-- Display error message to customers here -->
                </div>
            </form>
        </b-modal>
        <!-- modals -->
    </div>
</template>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';
import i18n from 'vue-i18n';

export default {
    i18n: {
        messages: {
            en: {
                nopaymentmethod: "You don't have any payment methods.",
            },
            de: {
                nopaymentmethod: 'Du hast noch keine Zahlungsmethoden.',
            },
        },
    },
    name: 'Billing',
    data() {
        return {
            paymentMethods: [],
            loading: false,
            errorMsg: null,

            publicKey: '',
            accountID: '',
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.loading = true;
            this.$store
                .dispatch('getPaymentMethods')
                .then((paymentMethods) => {
                    this.loading = false;
                    this.paymentMethods = [];
                    this.paymentMethods = paymentMethods;
                    if (this.paymentMethods === null || this.paymentMethods.length === 0) {
                        this.errorMsg = this.$t('nopaymentmethod');
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    console.error(error);
                    if (error.response && error.response.status && error.response.status === 404) {
                        this.errorMsg = this.$t('nopaymentmethod');
                    }
                });
        },
        getPublicKey() {
            axios({
                method: 'GET',
                url: '/user/account/billing/public-key',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.publicKey = response.data.Key;
                        this.accountID = response.data.AccountID;
                        this.billingTurnedOn = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        getClientSecret() {
            axios({
                method: 'GET',
                url: '/user/account/billing/add-payment-method',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.publicKey = response.data.Key;
                        this.accountID = response.data.AccountID;
                        this.billingTurnedOn = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        deleteMethodFromList(id) {
            this.paymentMethods = this.paymentMethods.filter((pm) => pm.ID !== id);
        },
    },
};
</script>
