export default {
    inserted: (el: any) => {
        function loadImage() {
            const imageElement = Array.from(el.children).find(
                (elm: Element) => elm.nodeName === 'IMG'
            ) as HTMLImageElement;
            if (imageElement) {
                el.classList.remove('loaded');
                el.classList.remove('failed');
                imageElement.addEventListener('load', () => {
                    setTimeout(() => {
                        el.classList.add('loaded');
                        // emit custom loaded event
                        const event = new CustomEvent('imageloaded');
                        imageElement.dispatchEvent(event);
                    }, 100);
                });
                imageElement.addEventListener('error', (error) => {
                    el.classList.add('failed');
                });
                imageElement.src = imageElement.dataset.url;
            }
        }

        function handleIntersect(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
            entries.forEach((entry: IntersectionObserverEntry) => {
                if (entry.isIntersecting) {
                    loadImage();
                    observer.unobserve(el);
                }
            });
        }

        function createObserver() {
            const options: IntersectionObserverInit = {
                root: null,
                threshold: [0],
            };
            const observer = new IntersectionObserver(handleIntersect, options);
            observer.observe(el);
        }

        if (window.IntersectionObserver) {
            createObserver();
        } else {
            loadImage();
        }

        el.reloadImage = loadImage;
    },
};
