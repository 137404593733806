

























import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoText from '../../Atoms/co-text/CoText.vue';

export default {
    name: 'CoDropdownItem',
    components: {
        CoText,
        CoIcon,
    },
    props: {
        to: {
            type: String,
            default: '',
            required: false,
        },
        href: {
            type: String,
            default: '',
            required: false,
        },
        target: {
            type: String,
            default: '_blank',
            required: false,
            validator: (value: string) => ['_blank', '_self', '_parent', '_top'].includes(value),
        },
        title: {
            type: String,
            default: '',
            required: false,
        },
        icon: {
            type: String,
            default: '',
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        divider: {
            type: Boolean,
            default: false,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        clicked() {
            if (this.disabled || this.divider) return;
            this.$emit('click');

            if (this.to) {
                this.$router.push(this.to);
            }
        },
    },
};
