




























































































































import Vue from 'vue';
import { BAspect } from 'bootstrap-vue';
import { get, min, toNumber } from 'lodash';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoText from '../../Atoms/co-text/CoText.vue';
import CoCard from '../../Molecules/co-card/CoCard.vue';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoRoundButton from '../../Atoms/co-round-button/CoRoundButton.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoModal from '../co-modal/CoModal.vue';
import CoPrice from '../../Atoms/co-price/coPrice.vue';

Vue.component('BAspect', BAspect);

interface Price {
    PriceInCents: number;
    Currency: string;
    TaxRate: number;
    TaxName: string;
    Inclusive: boolean;
    Unit?: string;
    IsFrom?: boolean; // means price Starts from some value
}

export default {
    name: 'CoResourcePreview',
    components: {
        CoText,
        CoCard,
        CoHeadline,
        CoRoundButton,
        CoPrice,
        CoIcon,
        CoModal,
        CoButton,
    },
    props: {
        resource: {
            type: Object,
            required: true,
        },
        isBillingTurnedOn: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data() {
        return {};
    },

    computed: {
        modalSize() {
            if (!this.resource.ImageURL && !this.resource.Description) {
                return 'sm';
            }
            return 'lg';
        },
    },
    methods: {
        priceObject() {
            const priceObj: Price = {
                PriceInCents: this.resource.PriceInCentsPerHour,
                Currency: this.resource.Currency,
                Unit: this.$tc('labels.hour', 1),
                TaxName: '',
                TaxRate: 0,
                Inclusive: false,
            };

            if (this.resource.TaxRate) {
                priceObj.TaxRate = this.resource.TaxRate.Percentage;
                priceObj.TaxName = this.resource.TaxRate.DisplayName;
                priceObj.Inclusive = this.resource.TaxRate.Inclusive;
            }

            if (this.resource.BookWholeSlot) {
                priceObj.Unit = this.$t('labels.booking').toLowerCase();
                if (!this.resource.PricePerSlot) {
                    // get a list of all booking slot durations
                    const bookingSlots = get(this.resource, 'BookingTimes', []).map((slot) => {
                        if (get(slot, 'From', null) && get(slot, 'To', null)) {
                            const from = new Date(`1970-01-01T${get(slot, 'From')}Z`);
                            const to =
                                this.resource.BookingSlotTo === '00:00'
                                    ? new Date(`1970-01-02T00:00:00Z`)
                                    : new Date(`1970-01-01T${get(slot, 'To')}Z`);
                            const durationInHours = (to.getTime() - from.getTime()) / 1000 / 60 / 60;
                            return durationInHours;
                        }
                    });

                    priceObj.IsFrom = true;

                    const minRes = min(bookingSlots);
                    if (minRes && toNumber(minRes) >= 1) {
                        priceObj.PriceInCents *= toNumber(minRes);
                    }
                }
            }
            return priceObj;
        },
        show(e) {
            this.$refs['resource-preview-modal'].show();
            // Prevents the click event from bubbling up to the parent element
            if (e) {
                e.stopPropagation();
            }
        },
        hide(e) {
            this.$refs['resource-preview-modal'].hide();
            if (e) {
                e.stopPropagation();
            }
        },
        randomKey() {
            return Math.random().toString(36).substring(7);
        },
        // weekdayForNumber returns a string of the weekday number 1-7 to the weekday name
        weekdayForNumber(weekday: number) {
            return this.$t('datetime', { date: weekday, format: 'dayname' });
        },
        selectResource() {
            this.$emit('select', this.resource);
            this.hide();
        },
    },
};
