
































import Vue from 'vue';
import axios from 'axios';
import get from 'lodash';
import { Event } from '@/components/Molecules/co-card-event/models.ts';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoCardEvent from '@/components/Molecules/co-card-event/CoCardEvent.vue';

export default {
    name: 'MyEvents',
    data() {
        return {
            events: [],
            me: this.$store.state.me,
            loading: false,
        };
    },
    components: {
        CoCardEvent,
        CoRoundButton,
        CoButton,
        CoCard,
    },
    mounted() {
        this.getMyEvents();
    },
    methods: {
        mapEventStructure(object) {
            const event = object.object;
            const spaceItem = object.space;
            const e: Event = {
                id: event.ID,
                title: event.Title,
                description: event.Description,
                start: event.StartDate,
                end: event.EndDate,
                slug: event.Slug,
                image: event.ImageURL,
            };

            const participants = event.Participants;
            if (participants && participants.includes(this.me.ID)) {
                e.attend = true;
            }

            if (spaceItem && spaceItem.ID !== this.$store.state.space.ID) {
                e.space = {
                    id: spaceItem.ID,
                    name: spaceItem.Name,
                    favicon: spaceItem.FaviconURL,
                    primaryColor: spaceItem.PrimaryColor,
                };
            }

            return e;
        },
        getMyEvents() {
            this.loading = true;
            axios({
                method: 'GET',
                url: 'event/list/my',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data.Objects) {
                        response.data.Objects.forEach((entry) => {
                            const event = this.mapEventStructure(entry);
                            this.events.push(event);
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
