













import CoButton from '../../Atoms/co-button/CoButton.vue';

import CoText from '../../Atoms/co-text/CoText.vue';
export default {
    name: 'CoTextReadmore',
    components: {
        CoText,
        CoButton,
    },
    props: {
        text: {
            type: String,
            required: true,
        },
        limit: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            trimmedString: true,
            readMore: true,
        };
    },
    methods: {
        trimString(string) {
            if (string == null) {
                return '';
            }
            if (string.length > this.limit) {
                string = `${string.substring(0, this.limit - 1)}...`;
            }
            return string;
        },
        toggleReadMore() {
            this.readMore = !this.readMore;
        },
    },
};
