





















































































































































































































































































































































































































import axios from 'axios';

import { cloneDeep } from 'lodash';
// eslint-disable-next-line import/extensions
import EventBus from '@/eventBus.js';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoInput from '../../Molecules/co-input/CoInput.vue';
import CoTextArea from '../../Molecules/co-TextArea/CoTextArea.vue';
import CoFileSelect from '../../Molecules/co-file-select/CoFileSelect.vue';
import CoAlert from '../../Molecules/co-alert/CoAlert.vue';
import CoCard from '../../Molecules/co-card/CoCard.vue';
import CoPrice from '../../Atoms/co-price/coPrice.vue';
import CoText from '../../Atoms/co-text/CoText.vue';
import CoRoundButton from '../../Atoms/co-round-button/CoRoundButton.vue';

import CoCardUpgrade from '../../Molecules/co-card-upgrade/CoCardUpgrade.vue';

import CoAddPlanUpgrade from '../co-add-plan-upgrade/CoAddPlanUpgrade.vue';
import CoHeading from '../../Atoms/co-heading/CoHeading.vue';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';

import CoFormGroup from '../../Molecules/co-form-group/CoFormGroup.vue';
import CoSelect from '../../Molecules/co-select/CoSelect.vue';

import CoAddPlanResource from '../co-add-plan-resource/CoAddPlanResource.vue';
import CoDropdown from '../../Molecules/co-dropdown/CoDropdown.vue';
import CoDropdownItem from '../../Molecules/co-dropdown-item/CoDropdownItem.vue';

interface Plan {
    Name: string;
    Description: string;
    AvailableResourceGroups: Array<any>;
    AvailableResources: Array<any>;
    Currency: string;
    ImageURL: string | null | undefined | any;
    ID: string;
    PriceInCents: number;
    Price: '';
    RedirectURL: '';
    TaxRate: object;

    TimeInteval: number;
    IntervalCount: number;

    Hidden: boolean;
    Active: boolean;
    CancelationNotice: number;
    Extras: Array<any>;

    InterfaceStringCustomisations: Array<{ Key: number; Value: string }>;
}

export default {
    name: 'CoPlanEdit',
    components: {
        CoSelect,
        CoIcon,
        CoInput,
        CoTextArea,
        CoCardUpgrade,
        CoHeading,
        CoFormGroup,
        CoFileSelect,
        CoAlert,
        CoAddPlanUpgrade,
        CoCard,
        CoHeadline,
        CoPrice,
        CoText,
        CoAddPlanResource,
        CoDropdown,
        CoDropdownItem,
        CoRoundButton,
    },
    props: {
        // eslint-disable-next-line vue/require-default-prop
        plan: {
            type: Object as () => Plan,
            required: false,
        },

        taxRates: {
            type: Array as () => Array<any> | null,
            default: () => [],
        },
        upgrades: {
            type: Array as () => Array<any> | null,
            default: () => [],
        },

        resources: {
            type: Array as () => Array<any> | null,
            default: () => [],
        },

        excludeResources: {
            type: Array as () => Array<string> | null,
            default: () => [],
        },

        allowMultipleExtras: {
            // 'Allow multiple extras of the same type to be added to a plan, important for plan customization for a user',
            type: Boolean,
            default: false,
            required: false,
        },

        customisation: {
            type: Boolean,
            default: false,
        },

        isWithCustomSignButton: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            allExtras: [],
            editedPlanVersion: {} as Plan,

            sections: {
                general: true,
                price: false,
                upgrades: false,
                resources: false,
            },
            // editedPlanVersionExtrasKey random number to force rerender of extras
            editedPlanVersionExtrasKey: Math.random() * 100,

            rerenderResourceListKey: Math.random() * 100,

            selectedTaxRate: null,
            creatSubButtonTitleCustomization: '',
        };
    },
    computed: {
        taxRateList() {
            return this.taxRates.map((taxrate) => ({
                Value: taxrate,
                Name: `${taxrate.Percentage ? taxrate.Percentage : 0}% - ${
                    taxrate.Inclusive ? 'Inclusive' : 'Exclusive'
                } - ${taxrate.DisplayName}`,
            }));
        },
    },

    watch: {
        plan: {
            handler() {
                this.editedPlanVersion = this.plan;
                this.editedPlanVersion.IntervalCount = 1;
                if (
                    this.editedPlanVersion.InterfaceStringCustomisations &&
                    this.editedPlanVersion.InterfaceStringCustomisations[0] &&
                    this.editedPlanVersion.InterfaceStringCustomisations[0].Value !== ''
                ) {
                    this.creatSubButtonTitleCustomization =
                        this.editedPlanVersion.InterfaceStringCustomisations[0].Value;
                }

                this.getSelectedTaxRate();
            },
            deep: true,
        },
        taxRates: {
            handler() {
                this.getSelectedTaxRate();
            },
            deep: true,
        },

        editedPlanVersion: {
            handler() {
                this.$emit('update', this.editedPlanVersion);
            },
            deep: true,
        },
        creatSubButtonTitleCustomization: {
            handler() {
                this.editedPlanVersion.InterfaceStringCustomisations = [
                    {
                        Key: 0,
                        Value: this.creatSubButtonTitleCustomization,
                    },
                ];
            },
        },
    },
    created() {
        this.editedPlanVersion = this.plan;
        if (this.editedPlanVersion) {
            if (this.editedPlanVersion.AvailableResources) {
                this.editedPlanVersion.AvailableResources.forEach((resource) => {
                    this.excludeResources.push(resource.ID);
                });
            }
            if (this.editedPlanVersion.AvailableResourceGroups) {
                this.editedPlanVersion.AvailableResourceGroups.forEach((group) => {
                    group.ListResources.forEach((resource) => {
                        this.excludeResources.push(resource.ID);
                    });
                });
            }
        }
        this.editedPlanVersion.IntervalCount = 1;
    },
    mounted() {
        if (
            this.editedPlanVersion.InterfaceStringCustomisations &&
            this.editedPlanVersion.InterfaceStringCustomisations[0] &&
            this.editedPlanVersion.InterfaceStringCustomisations[0].Value !== ''
        ) {
            this.creatSubButtonTitleCustomization = this.editedPlanVersion.InterfaceStringCustomisations[0].Value;
        }

        this.getSelectedTaxRate();
    },

    methods: {
        getSelectedTaxRate() {
            if (this.plan && this.taxRates.length > 0) {
                if (this.plan.TaxRate) {
                    this.selectedTaxRate = this.plan.TaxRate;
                } else if (this.plan.TaxRateID) {
                    const selectTaxRate = this.taxRates.find((taxrate) => taxrate.ID === this.plan.TaxRateID);
                    this.selectedTaxRate = {
                        Value: selectTaxRate,
                        Name: `${selectTaxRate.Percentage ? selectTaxRate.Percentage : 0}% - ${
                            selectTaxRate.Inclusive ? 'Inclusive' : 'Exclusive'
                        } - ${selectTaxRate.DisplayName}`,
                    };
                } else if (this.plan.ID == null) {
                    this.selectedTaxRate = this.taxRates[0];
                }
            }
        },
        getValueForTimeIntervalID(id) {
            return this.timeIntervals().find((interval) => interval.Value === id);
        },
        selectTimeInterval(interval) {
            this.editedPlanVersion.TimeInteval = interval.Value;
            this.editedPlanVersion.IntervalCount = 1;
        },
        timeIntervals() {
            return [
                { Name: 'Month', Value: 0 },
                { Name: 'Year', Value: 1 },
            ];
        },
        groupResources(rsrcs) {
            const resources = this.resources.filter((resource) => rsrcs.find((r) => r.ID === resource.ID));
            return resources;
        },
        showAddEditResourcesTopPlanModal(ref) {
            const elms = this.$refs[ref];
            if (elms && elms.length > 0) {
                elms[0].show();
            }
        },
        addResourceToPlan(resource) {
            this.editedPlanVersion.AvailableResources
                ? this.editedPlanVersion.AvailableResources.push(cloneDeep(resource))
                : (this.editedPlanVersion.AvailableResources = [cloneDeep(resource)]);
            this.excludeResources.push(resource.ID);
            this.rerenderResourceListKey += 1;
        },
        deleteResourceFromPlan(item) {
            this.editedPlanVersion.AvailableResources = this.editedPlanVersion.AvailableResources.filter(
                (resource) => resource.ID !== item.ID
            );
            this.excludeResources = this.excludeResources.filter((ID: string) => ID !== item.ID);
            this.rerenderResourceListKey += 1;
        },
        updateResourceInPlan(resource) {
            const index = this.editedPlanVersion.AvailableResources.findIndex((item) => item.ID === resource.ID);
            if (index > -1) {
                this.editedPlanVersion.AvailableResources[index] = resource;
            }
            this.rerenderResourceListKey += 1;
        },

        deleteResourceGroupFromPlan(item) {
            this.editedPlanVersion.AvailableResourceGroups = this.editedPlanVersion.AvailableResourceGroups.filter(
                (resource) => resource.Name !== item.Name
            );
            for (let i = 0; i < item.ListResources.length; i++) {
                this.excludeResources = this.excludeResources.filter((ID: string) => ID !== item.ListResources[i].ID);
            }
            this.rerenderResourceListKey += 1;
        },
        updateReourceGroupInPlan(item) {
            const index = this.editedPlanVersion.AvailableResourceGroups.findIndex(
                (element) => element.Name === item.Name
            );
            if (index > -1) {
                this.editedPlanVersion.AvailableResourceGroups[index] = item;
            }
            this.rerenderResourceListKey += 1;
        },
        addResourceGroupToPlan(item) {
            this.editedPlanVersion.AvailableResourceGroups
                ? this.editedPlanVersion.AvailableResourceGroups.push(cloneDeep(item))
                : (this.editedPlanVersion.AvailableResourceGroups = [cloneDeep(item)]);
            for (let i = 0; i < item.ListResources.length; i++) {
                this.excludeResources.push(item.ListResources[i].ID);
            }
            this.rerenderResourceListKey += 1;
        },
        selectStatus(status) {
            this.editedPlanVersion.Active = status.Value;
        },
        selectVisibility(visibility) {
            this.editedPlanVersion.Hidden = visibility.Value;
        },
        selectTaxRate(taxrate) {
            this.editedPlanVersion.TaxRate = taxrate;
        },

        calculatePrice(price) {
            this.editedPlanVersion.PriceInCents = price * 100;
        },

        changeIndex(index) {
            this.index = index;
        },

        selectUpgrade(upgrade) {
            // if allowMultipleExtras is false, do not allow adding the same upgrade twice

            if (!this.allowMultipleExtras) {
                if (this.editedPlanVersion && this.editedPlanVersion.Extras) {
                    const alreadyAdded = this.editedPlanVersion.Extras.find((extra) => extra.ID === upgrade.ID);
                    if (alreadyAdded) {
                        return;
                    }
                }
            }

            if (!this.editedPlanVersion.Extras) {
                this.editedPlanVersion.Extras = [];
            }

            this.editedPlanVersion.Extras.push(upgrade);
            this.editedPlanVersionExtrasKey += 1;
        },
        deleteUpgrade(index) {
            this.editedPlanVersion.Extras.splice(index, 1);
            this.editedPlanVersionExtrasKey += 1;
        },

        creditsString(resource) {
            if (resource.CreditCents > 0) {
                return `Credit: ${(resource.CreditCents / 100).toFixed(2)} ${this.plan.Currency}`;
            }

            if (resource.CreditHours > 0) {
                return `Credit: ${resource.CreditHours}h`;
            }
            return '';
        },

        getPriceObjectForResourceID(id, priceOverride, newPrice) {
            const resource = this.resources.find((resource) => resource.ID === id);
            if (resource) {
                const priceObj = {
                    PriceInCents: resource.PriceInCents,
                    Currency: resource.Currency,
                    Unit: 'hour',
                    TaxName: '',
                    TaxRate: 0,
                    Inclusive: false,
                };

                if (resource.PriceInCentsPerHour) {
                    priceObj.PriceInCents = resource.PriceInCentsPerHour;
                }

                if (priceOverride) {
                    priceObj.PriceInCents = newPrice;
                }

                if (resource.TaxRate) {
                    priceObj.TaxRate = resource.TaxRate.Percentage;
                    priceObj.TaxName = resource.TaxRate.DisplayName;
                    priceObj.Inclusive = resource.TaxRate.Inclusive;
                }
                return priceObj;
            }

            if (priceOverride && newPrice) {
                const priceObj = {
                    PriceInCents: newPrice,
                    Currency: this.plan.Currency,
                    Unit: 'hour',
                    TaxName: '',
                    TaxRate: 0,
                    Inclusive: false,
                };
                return priceObj;
            }
            return null;
        },
    },
};
