<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';

a {
    color: inherit;
    text-decoration: inherit;
}
</style>

<template>
    <router-link
        class="bg-white round-unify w-100 d-flex align-items-start overflow-hidden"
        tag="a"
        :to="`/admin/monetization/plan-upgrade/${object.ID}`"
    >
        <b-aspect aspect="1:1">
            <div class="card-picture bg-primary"></div>
        </b-aspect>
        <div class="d-flex w-100 flex-column align-items-start item-data pl-3 py-2">
            <div class="d-flex flex-column w-100 justify-content-around">
                <div class="d-flex w-100 justify-content-between">
                    <h2 class="title mt-1 mt-lg-2 mb-2 mb-xs-3">
                        {{ object.Name }}
                    </h2>
                    <div class="d-block float-right">
                        <b-dropdown
                            variant="px-2"
                            class="d-menu"
                            menu-class="mt-0 border-1"
                            no-caret
                            right
                            round
                            style="position: relative"
                        >
                            <template v-slot:button-content>
                                <b-icon icon="three-dots" class="m-0" scale="1.2"></b-icon>
                            </template>
                            <b-dropdown-item class="d-action" @click="edit(object.ID)">Edit plan</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
                <div class="d-flex text-muted">
                    <p v-if="FinalPrice">{{ FinalPrice }} {{ object.Currency }}</p>
                    <p v-else>0 {{ $store.state.space.Currency }}</p>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
import axios from 'axios';

export default {
    name: 'PlanUpgradeCard',
    props: ['object'],
    data() {
        return {
            FinalPrice: null,
            taxRates: [],
            loadingTaxRate: false,
        };
    },
    beforeCreate() {},
    created() {},

    mounted() {
        this.getTaxRates();
    },
    methods: {
        edit(id) {
            this.$emit('editCallBack', id);
        },
        getTaxRates() {
            this.loadingTaxRate = true;
            axios({
                method: 'GET',
                url: `/admin/tax-rate/list`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.taxRates = response.data;
                    }
                    if (this.object.TaxRateID) {
                        this.calculateFinalPrice(this.object.TaxRateID);
                    }
                    this.loadingTaxRate = false;
                })
                .catch((error) => {
                    this.loadingTaxRate = false;
                    console.log(error);
                });
        },
        calculateFinalPrice(taxID) {
            const tax = this.taxRates.find((item) => item.ID === taxID);
            if (tax.Inclusive) {
                this.FinalPrice = (this.object.PriceInCents / 100).toFixed(2);
            } else {
                const finalPrice = (this.object.PriceInCents / 100) * (tax.Percentage / 100 + 1);
                this.FinalPrice = finalPrice.toFixed(2);
            }
        },
    },
};
</script>
