























































































































































































































































































































































































import Vue from 'vue';
import moment, { Moment } from 'moment';
import axios from 'axios';
import { P } from 'vue-long-click';
import { get } from 'lodash';
import { preventDefault } from '@fullcalendar/common';
import { getUnixTime } from 'date-fns';

import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoAlert from '../../Molecules/co-alert/CoAlert.vue';
import CoFormGroup from '../../Molecules/co-form-group/CoFormGroup.vue';
import CoCheckbox from '../../Atoms/co-checkbox/CoCheckbox.vue';
import CoText from '../../Atoms/co-text/CoText.vue';

class BookingCandidate {
    constructor(
        public resource: object,
        public start: Date, // 00:00
        public end: Date,
        public showResourceSelector: boolean
    ) {}
}

interface bookingRequest {
    start: number;
    end: number;
    resourceid: string;
    for: string;
    couponIDs: string[];
}

interface createBookingRequest {
    start: number;
    end: number;
    resourceid: string;
    for: string;
    couponIDs: string[];

    overridePrice: boolean;
    overridePriceInCents: number;

    bookingTitle: string;
    bookingNotes: string;
}

export default {
    name: 'CoBookingCheckoutForAdmin',
    components: {
        CoButton,
        CoAlert,
        CoFormGroup,
        CoCheckbox,
        CoText,
    },
    props: {
        bookingCandidate: {
            type: Object as () => BookingCandidate,
            required: true,
        },
    },
    data() {
        return {
            booking: {
                start: moment(this.bookingCandidate.start).format('YYYY-MM-DD'),
                end: moment(this.bookingCandidate.end).format('YYYY-MM-DD'),
                startTime: moment(this.bookingCandidate.start).format('HH:mm'),
                endTime: moment(this.bookingCandidate.end).format('HH:mm'),
                resource: this.bookingCandidate.resource,
                user: null,
                overwritePrice: false,
            },
            overwritePriceInEuro: 0,
            me: this.$store.state.me,
            forUser: null,

            loading: false,
            success: false,
            checkToObject: null,
            error: false,
            bookingTimes: {},

            priceSum: {
                sum: 0.0, // sum of all prices
                usedCredits: 0,
                leftCredits: 0,
                total: 0.0,
            },
            defaultPrice: null,

            listWeekdays: [],
            sameTime: false,
            taxRate: null,

            timeError: '',
            requestedWeekday: 0,
            weekdays: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
            bookingPrice: null,
            bookingPriceLoading: false,

            isSlotExoustedCheckLoading: false,
            isSlotExousted: false,
            bookResourceAnyway: false, // if the slot is exousted, admin can still book the resource if this is true
            isBillingTurnedOn: this.$store.state.billingTurnedOn,
        };
    },
    mounted() {
        this.$bvModal.show('makebookingmodal');

        this.booking = {
            start: moment(this.bookingCandidate.start).format('YYYY-MM-DD'),
            end: moment(this.bookingCandidate.end).format('YYYY-MM-DD'),
            startTime: moment(this.bookingCandidate.start).format('HH:mm'),
            endTime: moment(this.bookingCandidate.end).format('HH:mm'),
        };

        this.getTaxRate(this.bookingCandidate.resource.TaxRateID);
    },
    watch: {
        booking(newVal, oldVal) {
            this.checkTimes();

            this.timeError = '';
        },
    },
    methods: {
        handleOverwritePriceInEuroChange(value) {
            this.overwritePriceInEuro = value;
        },
        // convert cents to euro/dollar/...
        convertPrice(price: number) {
            if (!price) {
                return 0;
            }

            return (price / 100).toFixed(2);
        },

        // checkIfSlotCapacityExousted checks if the capacity of the slot is exousted to give a warning to admin
        checkIfSlotCapacityExousted() {
            this.isSlotExoustedCheckLoading = true;
            this.isSlotExousted = false;
            this.bookResourceAnyway = false;

            const req = {
                resourceid: this.bookingCandidate.resource.ID,
                start: this.getBookingStart().unix(),
                end: this.getBookingEnd().unix(),
            };

            axios({
                method: 'POST',
                url: `/booking/is-slot-exausted`,
                withCredentials: true,
                data: req,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    const tmp = response.data;
                    if (response && response.data && response.data.IsExhausted) {
                        this.isSlotExousted = true;
                    } else {
                        this.isSlotExousted = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.isSlotExoustedCheckLoading = false;
                });
        },

        previewBookingPrice() {
            this.checkIfSlotCapacityExousted();
            this.bookingPriceLoading = true;

            if (!this.isBillingTurnedOn) {
                this.bookingPrice = null;
                this.bookingPriceLoading = false;
                return;
            }

            const req: bookingRequest = {
                resourceid: this.bookingCandidate.resource.ID,
                start: this.getBookingStart().unix(),
                end: this.getBookingEnd().unix(),
                for: '',
                couponIDs: [], // TODO: add coupon
            };

            if (this.forUser) {
                req.for = this.forUser.ID;
            }

            axios({
                method: 'POST',
                url: `/admin/booking/preview`,
                withCredentials: true,
                data: req,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.bookingPrice = response.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.bookingPriceLoading = false;
                });
        },

        checkTimes() {
            if (this.bookingTimes) {
                this.timeError = '';
                if (this.booking.startTime < this.bookingTimes.From || this.booking.startTime > this.bookingTimes.To) {
                    this.booking.startTime = this.bookingTimes.From;
                    this.timeError = `The booking times for this day are from ${this.bookingTimes.From} to ${this.bookingTimes.To}`;
                }
                if (this.booking.endTime > this.bookingTimes.To || this.booking.endTime < this.bookingTimes.From) {
                    this.booking.endTime = this.bookingTimes.To;
                    this.timeError = `The booking times for this day are from ${this.bookingTimes.From} to ${this.bookingTimes.To}`;
                }

                if (!this.getBookingStart().isBefore(this.getBookingEnd())) {
                    this.timeError = 'The start time must be before the end time';
                }
                if (this.timeError === '') {
                    this.previewBookingPrice();
                } else {
                    this.bookingPrice = null;
                }
            }
        },

        getBookingStart(): Moment | undefined {
            const timeStart = this.booking.startTime.split(':');
            const from = moment(this.booking.start);
            if (!from) {
                return undefined;
            }
            from.hours(parseInt(timeStart[0]));
            from.minutes(parseInt(timeStart[1]));

            return from;
        },

        getBookingEnd(): Moment | undefined {
            const timeEnd = this.booking.endTime.split(':');
            const to = moment(this.booking.end);
            if (!to) {
                return undefined;
            }
            to.hours(parseInt(timeEnd[0]));
            to.minutes(parseInt(timeEnd[1]));

            return to;
        },

        hideModals() {
            this.success = false;
            this.error = false;
            this.loading = false;
            this.$emit('hideModals');
            setTimeout(() => {
                this.$bvModal.hide('makebookingmodal'), 200;
            });
        },
        selectUser(user) {
            this.forUser = user;
            this.booking.title = `${this.bookingCandidate.resource.Name} booking for ${user.Profile.Name}`;
            this.previewBookingPrice();
        },
        unselectUserFor() {
            this.forUser = null;
            this.booking.title = `${this.bookingCandidate.resource.Name} booking`;
            this.bookingPrice = null;
        },

        makeBooking() {
            // start loader
            this.loading = true;

            const req: createBookingRequest = {
                start: this.getBookingStart().unix(),
                end: this.getBookingEnd().unix(),
                resourceid: this.bookingCandidate.resource.ID,

                bookingTitle: this.booking.title,
                bookingNotes: '',
                couponIDs: this.booking.coupons,

                for: '',
                overridePrice: false,
                overridePriceInCents: 0,
            };

            if (this.forUser) {
                req.for = this.forUser.ID;
            }

            if (this.booking.overwritePrice) {
                req.overridePrice = true;
                req.overridePriceInCents = Number(this.overwritePriceInEuro * 100);
            }

            // add current day to time
            const data = JSON.stringify(req);

            axios({
                method: 'POST',
                url: '/admin/booking',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.$emit('bookingCreated');
                    setTimeout(() => {
                        this.error = false;
                        this.success = true;
                        this.$emit('created');
                    }, 300);
                })
                .catch((error) => {
                    setTimeout(() => {
                        this.error = error.response.data;
                        this.success = false;
                    }, 300);
                });
        },

        getTaxRate(taxID) {
            axios({
                method: 'GET',
                url: `/space/tax/${taxID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.taxRate = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
