<style lang="less" scoped>
@import './CoCreateResourceRangePage.less';

@import '~@/assets/less/variables.less';
.round-unify {
    border-radius: 1rem !important;
    overflow: hidden;
}

@media (min-width: 768px) {
    .col-image {
        height: 70vh;
    }
    .column {
        min-height: 70vh;
    }
}
</style>

<template>
    <div class="bg-white p-0 p-lg-3 w-100 round-unify pb-5" style="overflow-y: visible !important">
        <div class="mt-2">
            <CoLabel text="Members can schedule..."></CoLabel>
            <div class="d-flex align-items-center flex-wrap">
                <div class="col-6 col-md-0 p-0 m-0">
                    <CoCheckboxInput v-model="timeInterval" input="60" class="d-flex mr-2"></CoCheckboxInput>
                </div>
                <div class="mr-2 mt-2 mt-md-0">
                    <div class="col-6 col-md-0 p-0 m-0">
                        <CoSelect
                            :minWidth="true"
                            :items="['Calendar days']"
                            :value="'Calendar days'"
                            :listOfStrings="true"
                        ></CoSelect>
                    </div>
                </div>
                <div class="mt-2 mt-md-0">into the future</div>
            </div>
            <div class="mt-1 pl-2">
                <CoCheckbox v-model="withinDateRange" name="Within a date range"> </CoCheckbox>
            </div>
            <div class="mt-2 pl-2">
                <CoCheckbox v-model="indefinitely" name="Indefinitely into the future"> </CoCheckbox>
            </div>
        </div>
        <div class="mt-2">
            <CoLabel text="Minimum time before the appointment start that it can be booked"></CoLabel>
            <div class="d-flex align-items-center">
                <div>
                    <CoCheckboxInput v-model="minimumTimeCheck" input="0" class="d-flex mr-2"></CoCheckboxInput>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from '../../../eventBus';
import axios from 'axios';

export default {
    name: 'CoCreateResourceRangePage',
    data() {
        return {
            plan: null,
            me: this.$store.state.me,
            imageURL: '',
            changedCategories: [],
            categories: [],
            color: 'black',
            hideResource: false,
            windowWidth: window.innerWidth,
            minWidth: true,

            loading: false,
            locations: [],
            location: null,
            intervals: [0],

            timeInterval: true,
            withinDateRange: false,
            indefinitely: false,
            minimumTimeCheck: false,

            id: this.$route.params.ID, // current or upcoming
        };
    },
    created() {},

    mounted() {
        this.listLocations();
        this.listCategories();

        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;

            if (this.windowWidth >= 768) {
                this.minWidth = true;
            } else {
                this.minWidth = false;
            }
        });
    },
    watch: {
        timeInterval: function (newVal) {
            if (newVal) {
                this.withinDateRange = !newVal;
                this.indefinitely = !newVal;
            }
            if (!newVal && !this.withinDateRange && !this.indefinitely) {
                newVal = true;
            }
        },
        indefinitely: function (newVal) {
            if (newVal) {
                this.withinDateRange = !newVal;
                this.timeInterval = !newVal;
            }
            if (!newVal && !this.withinDateRange && !this.timeInterval) {
                this.timeInterval = true;
            }
        },
        withinDateRange: function (newVal) {
            if (newVal) {
                this.indefinitely = !newVal;
                this.timeInterval = !newVal;
            }
            if (!newVal && !this.indefinitely && !this.timeInterval) {
                this.timeInterval = true;
            }
        },
    },
    methods: {
        categoriesChanged(categories) {
            this.changedCategories = categories;
        },
        selectLocation(event) {
            this.location = event;
        },
        searchLocation(e) {
            return new Promise((resolve, reject) => {
                var items = this.locations;

                items.filter((l) => items.includes(e));

                resolve(items);
            });
        },
        listCategories() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/booking/v2/category/list',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data) {
                        this.categories = response.data;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        listLocations() {
            this.$store
                .dispatch('listLocations')
                .then((locations) => {
                    this.locations = locations;
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        selectColor(color) {
            this.color = color;
        },
        addInterval() {
            this.intervals.push(this.intervals.length);
        },
        removeInterval(index) {
            this.intervals.splice(index, 1);
        },
    },
};
</script>
