<style lang="less" scoped>
@import '~@/assets/less/manage-space/base.less';

.form-control {
    border: none !important;
    border-bottom: 1px solid @coapp-darkgrey !important;
    &:focus {
        border: none !important;
        outline: none !important;
        border-bottom: 1px solid @purple !important;
    }
}

.mh-50 {
    min-height: 50vh !important;
}
.h-50 {
    height: 50vh !important;
}
</style>

<template>
    <div class="mb-4">
        <div class="px-sm-4">
            <b-overlay :show="loading" class="h-100">
                <div class="bg-white round-unify px-3 py-3 h-100">
                    <b-form ref="form0" class="col col-md-6 pl-2">
                        <b-form-group
                            id="input-group-user"
                            label="User (optional)"
                            label-for="input-user"
                            label-class="m-0 p-0"
                        >
                            <b-form-input
                                v-if="selectedUser"
                                id="input-user"
                                ref="input-user"
                                v-model="selectedUser.Profile.Name"
                                type="text"
                                class="underline-input px-0 py-2"
                                @click="showSelectUserModal"
                            ></b-form-input>
                            <b-form-input
                                v-else
                                id="input-user"
                                ref="input-user"
                                type="text"
                                placeholder="Select user"
                                class="underline-input px-0 py-2"
                                @click="showSelectUserModal"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-first-name"
                            label="First Name"
                            label-for="input-first-name"
                            label-class="m-0 p-0"
                        >
                            <b-form-input
                                id="input-first-name"
                                v-model="identity.FirstName"
                                type="text"
                                required
                                placeholder="Enter user first name"
                                class="underline-input px-0 py-2"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-last-name"
                            label="Last Name"
                            label-for="input-last-name"
                            label-class="m-0 p-0"
                        >
                            <b-form-input
                                id="input-last-name"
                                v-model="identity.LastName"
                                type="text"
                                required
                                placeholder="Enter user last name"
                                class="underline-input px-0 py-2"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-email"
                            label="Email"
                            label-for="input-email"
                            label-class="m-0 p-0"
                        >
                            <b-form-input
                                id="input-email"
                                v-model="identity.Email"
                                type="email"
                                required
                                placeholder="Enter email"
                                class="underline-input px-0 py-2"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            id="input-group-SystemUserID"
                            label="System User ID"
                            label-for="input-SystemUserID"
                            label-class="m-0 p-0"
                        >
                            <div class="underline-input px-0 py-2 text-muted">
                                <p class="mb-0" v-if="identity.SystemUserID">
                                    {{ identity.SystemUserID }}
                                </p>
                                <p class="mb-0" v-else>System user id is not defined</p>
                            </div>
                        </b-form-group>

                        <b-form-group
                            id="input-group-SystemUserID2"
                            label="System User ID 2"
                            label-for="input-SystemUserID2"
                            label-class="m-0 p-0"
                        >
                            <div class="underline-input px-0 py-2 text-muted">
                                <p class="mb-0" v-if="identity.SystemUserID2">
                                    {{ identity.SystemUserID2 }}
                                </p>
                                <p class="mb-0" v-else>System user id 2 is not defined</p>
                            </div>
                        </b-form-group>

                        <b-form-group
                            id="input-group-source"
                            label="Source"
                            label-for="input-source"
                            label-class="m-0 p-0"
                        >
                            <div class="underline-input px-0 py-2">
                                <p class="mb-0">Deister</p>
                            </div>
                        </b-form-group>

                        <b-form-group
                            id="input-group-valid-from-date"
                            label="Valid From"
                            label-for="input-valid-from-date"
                            label-class="m-0 p-0"
                        >
                            <b-form-datepicker v-model="ValidFrom" class="mb-2"></b-form-datepicker>
                        </b-form-group>

                        <b-form-group
                            id="input-group-valid-to-date"
                            label="Valid To"
                            label-for="input-valid-to-date"
                            label-class="m-0 p-0"
                        >
                            <b-form-datepicker v-model="ValidTo" class="mb-2"></b-form-datepicker>
                        </b-form-group>

                        <b-form-group id="input-group-tags" label="Tags" label-for="input-tags" label-class="m-0 p-0">
                            <div class="underline-input px-0 py-2">
                                <div v-if="identity.Tags && identity.Tags.length !== 0">
                                    <div
                                        v-for="(item, i) in identity.Tags"
                                        :key="item.ID"
                                        class="d-flex align-items-center px-2 mb-2"
                                    >
                                        <p class="mb-0">{{ item.Name }} - {{ item.Value }}</p>
                                        <b-button variant="outline-danger" class="ml-2" @click="unassignTag(item)"
                                            >Unassign</b-button
                                        >
                                    </div>
                                </div>
                                <p v-else class="">no tags assigned yet</p>
                                <b-button v-b-modal.modal-select-tag>Assign Tag</b-button>
                            </div>
                        </b-form-group>

                        <b-form-group
                            id="input-group-policies"
                            label="Policies"
                            label-for="input-policies"
                            label-class="m-0 p-0"
                        >
                            <div class="underline-input px-0 py-2">
                                <div v-if="identity.Policies && identity.Policies.length !== 0">
                                    <div
                                        v-for="(item, i) in identity.Policies"
                                        :key="item.ID"
                                        class="d-flex align-items-center px-2 mb-2"
                                    >
                                        <p class="mb-0">{{ item.Name }}</p>
                                        <b-button variant="outline-danger" class="ml-2" @click="unassignPolicy(item)"
                                            >Unassign</b-button
                                        >
                                    </div>
                                </div>
                                <p v-else class="">no policies assigned yet</p>
                                <b-button v-b-modal.modal-select-policy>Assign Policy</b-button>
                            </div>
                        </b-form-group>
                    </b-form>

                    <div class="pt-2">
                        <b-button type="submit" variant="primary" class="mr-2" @click="updateIdentity"> Save </b-button>
                        <b-button type="submit" variant="secondary" @click="cancel"> Cancel </b-button>
                    </div>
                </div>
                <div class="bg-secondary p-3 round-unify mt-3 text-muted">
                    <span> Created at {{ identity.CreatedAt | timestampToString }} </span>
                    <span class="ml-1"> Updated at {{ identity.UpdatedAt | timestampToString }} </span>
                </div>
            </b-overlay>
        </div>
        <!-- Forms end -->

        <!-- Modals -->
        <!-- user select modal start -->
        <b-modal
            id="modal-select-user"
            ref="modal-select-user"
            title="Select user linked with identity"
            centered
            hide-footer
        >
            <form ref="" @submit.stop.prevent="">
                <b-form-group label="Search user" label-for="name-input" invalid-feedback="Type username here">
                    <b-form-input
                        id="name"
                        class="underline-input px-0 py-2"
                        v-model="searchInput"
                        placeholder="Type user name"
                        required
                        @input="searchUsers(searchInput)"
                    ></b-form-input>
                </b-form-group>
                <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
                    <b-table
                        striped
                        hover
                        :items="searchResult"
                        :fields="fields"
                        :current-page="currentPage"
                        sort-icon-left
                        :per-page="perPage"
                        stacked="md"
                        primary-key="Name"
                        class="table table-borderless table-responsive w-100 d-md-table"
                    >
                        <template v-slot:cell(Name)="row">
                            <div class="d-flex align-items-center">
                                <div>
                                    <ProfileCircle
                                        :ImageURL="row.item.Profile.ImageURL"
                                        :Slug="row.item.Slug"
                                    ></ProfileCircle>
                                </div>
                                <div class="ml-3">
                                    <router-link style="color: #212529" :to="'/profile/' + row.item.Slug">{{
                                        row.item.Profile.Name
                                    }}</router-link>
                                </div>
                            </div>
                        </template>

                        <template v-slot:cell(Actions)="row">
                            <b-button
                                v-if="selectedUser && row.item.ID === selectedUser.ID"
                                variant="primary"
                                @click="unselectUser(row.item)"
                            >
                                Unselect</b-button
                            >
                            <b-button v-else @click="selectUser(row.item)" class="mr-2 primary">Select</b-button>
                        </template>
                    </b-table>
                    <p v-if="searchInput !== '' && searchResult.length === 0">
                        No users matching your search input found
                    </p>
                </b-overlay>
            </form>
        </b-modal>
        <!-- user select modal end -->

        <!-- tag select modal start -->
        <b-modal
            id="modal-select-tag"
            ref="modal-select-tag"
            title="Select tag to
    assign"
            centered
            hide-footer
            @show="listTags"
            class="h-100"
        >
            <form ref="" @submit.stop.prevent="">
                <b-form-group label="Search tag" label-for="name-input" invalid-feedback="Type tag here">
                    <b-form-input
                        id="name"
                        class="underline-input px-0 py-2"
                        v-model="tagSearchInput"
                        placeholder="Type tag name"
                        required
                    ></b-form-input>
                </b-form-group>
                <div class="overflow-auto mh-50 h-50">
                    <div class="h-100">
                        <b-overlay :show="tagsLoading" spinner-variant="primary" rounded="sm">
                            <b-table
                                striped
                                hover
                                :items="filteredTags"
                                :fields="fields"
                                :current-page="currentPage"
                                sort-icon-left
                                stacked="md"
                                primary-key="ID"
                                class="table table-borderless table-responsive w-100 d-md-table"
                            >
                                <template v-slot:cell(Actions)="row">
                                    <b-button @click="assignTag(row.item)" class="mr-2 primary">Select</b-button>
                                </template>
                            </b-table>
                            <p v-if="tagSearchInput !== '' && filteredTags.length === 0">
                                No tags matching your search input found
                            </p>
                        </b-overlay>
                    </div>
                </div>
            </form>
        </b-modal>
        <!-- tag select modal end -->

        <!-- policy select modal start -->
        <b-modal
            id="modal-select-policy"
            ref="modal-select-policy"
            title="Select policy to
    assign"
            centered
            hide-footer
            @show="listPolicies"
            class="h-100"
        >
            <form ref="" @submit.stop.prevent="">
                <b-form-group label="Search policy" label-for="name-input" invalid-feedback="Type policy here">
                    <b-form-input
                        id="name"
                        class="underline-input px-0 py-2"
                        v-model="policySearchInput"
                        placeholder="Type policy name"
                        required
                    ></b-form-input>
                </b-form-group>
                <div class="overflow-auto mh-50 h-50">
                    <div class="h-100">
                        <b-overlay :show="policyLoading" spinner-variant="primary" rounded="sm">
                            <b-table
                                striped
                                hover
                                :items="filteredPolicies"
                                :fields="fields"
                                :current-page="currentPage"
                                sort-icon-left
                                stacked="md"
                                primary-key="ID"
                                class="table table-borderless table-responsive w-100 d-md-table"
                            >
                                <template v-slot:cell(Actions)="row">
                                    <b-button @click="assignPolicy(row.item)" class="mr-2 primary">Select</b-button>
                                </template>
                            </b-table>
                            <p v-if="policySearchInput !== '' && filteredPolicies.length === 0">
                                No policies matching your search input found
                            </p>
                        </b-overlay>
                    </div>
                </div>
            </form>
        </b-modal>
        <!-- tag select modal end -->
    </div>
</template>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';

export default {
    name: 'IdentityEdit',
    data() {
        return {
            loading: false,
            identity: {
                FirstName: '',
                LastName: '',
                Email: '',
                SystemUserID: '',
                SystemUserID2: '',
                CreatedAt: 0,
                UpdatedAt: 0,
                UserID: '',
                Source: 0,
                ValidFrom: null,
                ValidTo: null,

                Tags: [],
                Policy: [],
            },
            ValidFrom: null,
            ValidTo: null,
            searchInput: '',
            searchResult: [],
            fields: [
                {
                    key: 'Name',
                    label: 'Name',
                    sortable: true,
                },
                {
                    key: 'Actions',
                    label: '',
                },
            ],
            perPage: 10,
            currentPage: 1,
            totalRows: 0,

            selectedUser: null,

            // tags
            tags: [],
            tagSearchInput: '',
            tagsLoading: false,
            filteredTags: [],
            cancelTokenSource: null,

            // policy
            policies: [],
            policySearchInput: '',
            policyLoading: false,
        };
    },
    watch: {
        tagSearchInput(val) {
            if (val && val.length >= 2) {
                this.searchTag(val);
            } else {
                this.filteredTags = this.tags;
            }
        },
    },
    computed: {
        filteredPolicies() {
            return this.policies.filter((item) => {
                if (item.Name.toLowerCase().indexOf(this.policySearchInput.toLowerCase()) > -1 == true) {
                    return true;
                }
                if (item.Value.indexOf(this.policySearchInput) > -1 == true) {
                    return true;
                }
                if (item.DecimalValue.indexOf(this.policySearchInput) > -1 == true) {
                    return true;
                }
                return false;
            });
        },
    },
    mounted() {
        this.loading = true;
        this.get(this.$route.params.id);
    },
    methods: {
        searchTag(input) {
            if (!input) {
                this.filteredTags = this.tags;
                return;
            }

            this.tagsLoading = true;
            this.filteredTags = [];

            if (this.cancelTokenSource) {
                this.cancelTokenSource.cancel('Previous was request aborted due to a new request.');
            }
            this.cancelTokenSource = axios.CancelToken.source();
            axios({
                cancelToken: this.cancelTokenSource.token,
                method: 'GET',
                url: `/admin/space/access/tag/search/${input}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.tagsLoading = false;
                    if (response && response.data && response.data.Tags) {
                        const objects = response.data.Tags;
                        this.filteredTags = objects.map((item) => {
                            item.DecimalValue = String(parseInt(item.Value, 16));
                            return item;
                        });
                    } else {
                        this.filteredTags = [];
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (!axios.isCancel(error)) {
                        EventBus.$emit('ERROR', {
                            Message: 'Can`t get tags for search input',
                        });
                        this.filteredTags = [];
                        this.loatagsLoadinging = false;
                    }
                });
        },

        listPolicies() {
            this.policyLoading = true;
            axios
                .get('/admin/space/access/policy/list')
                .then((response) => {
                    if (response && response.data) {
                        this.policies = response.data;
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        EventBus.$emit('ERROR', {
                            Message: error.response.data.message,
                        });
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: 'Something went wrong',
                        });
                    }
                    console.log(error);
                })
                .finally(() => {
                    this.policyLoading = false;
                });
        },

        listTags() {
            this.tagsLoading = true;
            axios
                .get('/admin/space/access/tag/list')
                .then((response) => {
                    if (response && response.data) {
                        const objects = response.data.Tags;
                        this.tags = objects.map((item) => {
                            item.DecimalValue = String(parseInt(item.Value, 16));
                            return item;
                        });

                        this.filteredTags = this.tags;
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        EventBus.$emit('ERROR', {
                            Message: error.response.data.message,
                        });
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: 'Something went wrong',
                        });
                    }
                    console.log(error);
                })
                .finally(() => {
                    this.tagsLoading = false;
                });
        },

        /*
         * assignTag
         */
        assignTag(tag) {
            this.loading = true;
            const data = {
                TagID: tag.ID,
                IdentityID: this.identity.ID,
            };
            axios
                .post('/admin/space/access/identity/tag', data)
                .then((response) => {
                    if (response && response.data) {
                        this.identity = response.data;
                        EventBus.$emit('INFO', {
                            Message: `Tag ${tag.Name} assigned to ${this.identity.FirstName}`,
                        });
                    }
                    this.$refs['modal-select-tag'].hide();
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        EventBus.$emit('ERROR', {
                            Message: error.response.data.message,
                        });
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: 'Something went wrong',
                        });
                    }
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /*
         * unassignTag
         */
        unassignTag(tag) {
            this.loading = true;
            const data = {
                TagID: tag.ID,
                IdentityID: this.identity.ID,
            };
            axios({
                method: 'DELETE',
                url: '/admin/space/access/identity/tag',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.identity = response.data;
                        EventBus.$emit('INFO', {
                            Message: `Tag ${tag.Name} unassigned from ${this.identity.FirstName}`,
                        });
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        EventBus.$emit('ERROR', {
                            Message: error.response.data.message,
                        });
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: 'Something went wrong',
                        });
                    }
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /*
         * assignPolicy
         */
        assignPolicy(policy) {
            this.loading = true;
            const data = {
                PolicyID: policy.ID,
                IdentityID: this.identity.ID,
            };
            axios
                .post('/admin/space/access/identity/policy', data)
                .then((response) => {
                    if (response && response.data) {
                        this.identity = response.data;
                        EventBus.$emit('INFO', {
                            Message: `Policy ${policy.Name} assigned to ${this.identity.FirstName}`,
                        });
                    }
                    this.$refs['modal-select-policy'].hide();
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        EventBus.$emit('ERROR', {
                            Message: error.response.data.message,
                        });
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: 'Something went wrong',
                        });
                    }
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /*
         * unassignPolicy
         */
        unassignPolicy(policy) {
            this.loading = true;
            const data = {
                PolicyID: policy.ID,
                IdentityID: this.identity.ID,
            };
            axios({
                method: 'DELETE',
                url: '/admin/space/access/identity/policy',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.identity = response.data;
                        EventBus.$emit('INFO', {
                            Message: `Policy ${policy.Name} unassigned from ${this.identity.FirstName}`,
                        });
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        EventBus.$emit('ERROR', {
                            Message: error.response.data.message,
                        });
                    } else {
                        EventBus.$emit('ERROR', {
                            Message: 'Something went wrong',
                        });
                    }
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        get(id) {
            axios
                .get(`/admin/space/access/identity/${id}`)
                .then((response) => {
                    this.identity = response.data;
                    if (this.identity.ValidFrom) {
                        const from = new Date(this.identity.ValidFrom);
                        this.ValidFrom = from.toISOString().split('T')[0];
                    }
                    if (this.identity.ValidTo) {
                        const to = new Date(this.identity.ValidTo);
                        this.ValidTo = to.toISOString().split('T')[0];
                    }
                    if (this.identity.UserID) {
                        this.adminGetUserByID(this.identity.UserID);
                    }
                    this.loading = false;
                })
                .catch((error) => {})
                .finally(() => {
                    this.loading = false;
                });
        },

        updateIdentity() {
            this.loading = true;
            const data = this.identity;
            if (this.selectedUser) {
                data.UserID = this.selectedUser.ID;
            } else {
                data.UserID = null;
            }

            if (this.ValidFrom) {
                var date = Date.parse(this.ValidFrom);
                data.ValidFrom = date;
            }

            if (this.ValidTo) {
                var date = Date.parse(this.ValidTo);
                data.ValidTo = date;
            }

            axios
                .put('/admin/space/access/identity', this.identity)
                .then(() => {
                    EventBus.$emit('INFO', {
                        Message: `Identity for ${data.FirstName} updated`,
                    });
                })
                .catch((error) => {
                    EventBus.$emit('ERROR', {
                        Message: "Can't update identity",
                        Details: error,
                    });
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        adminGetUserByID(ID) {
            this.$store
                .dispatch('adminGetUserByID', ID)
                .then((response) => {
                    this.selectedUser = response;
                })
                .catch((error) => {
                    console.log(error);
                    const message = {
                        Message: 'Can`t get linked user data',
                    };
                    EventBus.$emit('ERROR', message);
                });
        },

        createIdentity() {
            this.loading = true;
        },
        cancel() {
            this.$router.push({ name: 'Identities' });
        },

        showSelectUserModal() {
            if (this.selectedUser) {
                this.searchResult.push(this.selectedUser);
            }
            this.$refs['modal-select-user'].show();
        },
        unselectUser() {
            this.selectedUser = null;
            this.searchInput = '';
            this.searchResult = [];
            this.$refs['input-user'].blur();
            this.$refs['modal-select-user'].hide();
        },

        selectUser(item) {
            this.selectedUser = item;
            this.identity.Email = item.Email;
            if (item.Profile) {
                this.identity.FirstName = item.Profile.FirstName;
                this.identity.LastName = item.Profile.LastName;
            }
            this.searchInput = '';
            this.searchResult = [];
            this.$refs['input-user'].blur();
            this.$refs['modal-select-user'].hide();
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        /*
         * Search users by name
         */
        searchUsers(input) {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/member/search/${input}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data && response.data.objects) {
                        this.searchResult = response.data.objects.map((item) => item.object);
                    } else {
                        this.searchResult = [];
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    this.searchResult = [];
                });
        },
    },
};
</script>
