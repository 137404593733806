







import CoLabel from '../../Atoms/co-label/CoLabel.vue';

export default {
    name: 'CoFormGroup',
    components: { CoLabel },

    props: {
        label: {
            type: String,
            default: '',
            require: true,
        },
    },
    data() {
        return {};
    },
};
