<template>
    <div class="container h-100" v-if="this.space">
        <!-- This panel displayed when page just loaded and system tries to perform checkin by hardware session -->
        <div v-if="showHardwareSessionPanel" class="row justify-content-center align-content-center h-100">
            <div class="col-lg-6 col-md-8 col-sm-10 col-11 bg-white p-4">
                <div class="d-flex justify-content-center p-5">
                    <img
                        v-if="this.space['LogoURL']"
                        :src="this.space['LogoURL']"
                        width="auto"
                        height="65"
                        alt="Hafven Community"
                        class=""
                    />
                </div>
                <div class="d-flex justify-content-center mb-4">
                    <h2>Internet Access</h2>
                </div>

                <div v-if="errorTitle">
                    <b-alert :show="errorTitle != null" variant="danger" class="rounded-4 border-0">
                        <h4 class="text-center text-dark">
                            <b-icon class="mr-1" icon="exclamation-octagon-fill"></b-icon>
                            {{ this.errorTitle }}
                        </h4>
                        <h5 v-if="errorSubtitle" class="text-center text-dark">
                            {{ this.errorSubtitle }}
                        </h5>
                        <p v-if="errorDetails" class="text-center text-dark">
                            {{ this.errorDetails }}
                        </p>
                    </b-alert>
                </div>

                <div class="text-center">
                    <h2 v-if="this.$store.state.me.ID != null">
                        Hi {{ this.$store.state.me.Profile.Name }}, welcome back!
                    </h2>
                    <h2 v-else>Hi Dear, welcome back!</h2>
                    <h2 v-if="showHardwareSessionCheckinSuccessMsg" class="mt-3 text-success">
                        Successfull checkin!<br />Waiting for internet connection!
                    </h2>
                </div>

                <div class="d-flex justify-content-center">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="text-center mt-5">
                    <a href="https://mitglieder.hafven.de/password/new" target="_blank" class="d-block text-muted"
                        >Forgot password</a
                    >
                    <a href="https://hafven.de/datenschutz" target="_blank" class="d-block text-muted">Datenschutz</a>
                </div>
            </div>
        </div>
        <!-- Panel end -->

        <!-- This panel with login mask used to validate user credentials and perform login&checkin -->
        <div v-if="showLoginMaskPanel" class="row justify-content-center align-content-center h-100">
            <div class="col-lg-6 col-md-8 col-sm-10 col-11 bg-white p-4">
                <div class="d-flex justify-content-center my-4">
                    <img
                        v-if="this.space['LogoURL']"
                        :src="this.space['LogoURL']"
                        width="auto"
                        height="65"
                        alt="Hafven Community"
                        class="mb-2"
                    />
                </div>
                <div class="d-flex justify-content-center mb-4">
                    <h2>Internet Access</h2>
                </div>
                <div v-if="errorTitle">
                    <b-alert :show="errorTitle != null && locked != true" variant="danger" class="rounded-4 border-0">
                        <h4 class="text-center text-dark">
                            <b-icon class="mr-1" icon="exclamation-octagon-fill"></b-icon>
                            {{ this.errorTitle }}
                        </h4>
                        <h5 v-if="errorSubtitle" class="text-center text-dark">
                            {{ this.errorSubtitle }}
                        </h5>
                        <p v-if="errorDetails" class="text-center text-dark">
                            {{ this.errorDetails }}
                        </p>
                    </b-alert>
                </div>
                <div class="d-flex justify-content-center">
                    <b-overlay :show="loading" variant="white" spinner-variant="primary">
                        <b-form @submit.prevent="loginAndCheckin">
                            <div class="mb-1">
                                <span class="label-align">Email</span>
                            </div>
                            <b-form-group
                                id="input-group-email"
                                label-for="input-email"
                                :disabled="loading ? true : false"
                            >
                                <b-form-input
                                    id="input-email"
                                    v-model="email"
                                    type="email"
                                    required
                                    placeholder="mail@example.com"
                                    class="rounded-pill"
                                    :disabled="loading ? true : false"
                                ></b-form-input>
                            </b-form-group>
                            <div class="mb-1">
                                <span class="label-align"
                                    >Password
                                    <span class="ml-2 clickable" @click="showPassword = !showPassword"
                                        ><b-icon v-if="!showPassword" icon="eye" class="m-0"></b-icon>
                                        <b-icon v-else icon="eye-slash" class="m-0"></b-icon
                                    ></span>
                                </span>
                            </div>
                            <b-input-group id="input-group-password" label-for="input-password">
                                <b-form-input
                                    id="input-password"
                                    v-model="password"
                                    :type="showPassword ? 'text' : 'password'"
                                    class="rounded-pill"
                                    required
                                    placeholder="password"
                                    :disabled="loading ? true : false"
                                >
                                </b-form-input>
                            </b-input-group>
                            <div class="d-flex justify-content-end mt-2">
                                <b-button
                                    :disabled="loading ? true : false"
                                    class="mt-2"
                                    variant="primary"
                                    type="submit"
                                >
                                    <span v-if="loading">logging in ...</span>
                                    <span v-else>Login</span>
                                </b-button>
                            </div>
                        </b-form>
                    </b-overlay>
                </div>
                <div class="text-center mt-5">
                    <a href="https://mitglieder.hafven.de/password/new" target="_blank" class="d-block text-muted"
                        >Forgot password</a
                    >
                    <a href="https://hafven.de/datenschutz" target="_blank" class="d-block text-muted">Datenschutz</a>
                </div>
            </div>
        </div>
        <!-- Panel end -->

        <!-- Panel to buy day pass -->
        <div v-if="showBuyDayPassPanel" class="row justify-content-center align-content-center h-100">
            <div class="col-lg-6 col-md-8 col-sm-10 col-11 bg-white p-4">
                <div class="d-flex justify-content-center">
                    <img
                        v-if="this.space['LogoURL']"
                        :src="this.space['LogoURL']"
                        width="auto"
                        height="65"
                        alt="Hafven Community"
                        class="mb-2"
                    />
                </div>
                <div class="d-flex justify-content-center mb-4">
                    <h2>Internet Access</h2>
                </div>

                <div v-if="errorTitle">
                    <b-alert :show="errorTitle != null" variant="danger" class="rounded-4 border-0">
                        <h4 class="text-center text-dark">
                            <b-icon class="mr-1" icon="exclamation-octagon-fill"></b-icon>
                            {{ this.errorTitle }}
                        </h4>
                        <h5 v-if="errorSubtitle" class="text-center text-dark">
                            {{ this.errorSubtitle }}
                        </h5>
                        <p v-if="errorDetails" class="text-center text-dark">
                            {{ this.errorDetails }}
                        </p>
                    </b-alert>
                </div>

                <div class="d-flex justify-content-center text-center">
                    <b-overlay :show="loading" variant="white" spinner-variant="primary">
                        <h2>Your dont have any left Time Passes. <br />Please, buy one to continue checkin process.</h2>
                        <div class="d-flex justify-content-center">
                            <b-button class="btn mt-2 btn-primary">Buy Time Pass for a day</b-button>
                        </div>
                    </b-overlay>
                </div>
                <div class="text-center mt-5">
                    <a href="https://mitglieder.hafven.de/password/new" target="_blank" class="d-block text-muted"
                        >Forgot password</a
                    >
                    <a href="https://hafven.de/datenschutz" target="_blank" class="d-block text-muted">Datenschutz</a>
                </div>
            </div>
        </div>
        <!-- Panel end -->

        <!-- Checkin panel -->
        <div v-if="showCheckinPanel" class="row justify-content-center align-content-center h-100">
            <div class="col-lg-6 col-md-8 col-sm-10 col-11 bg-white p-4">
                <div class="d-flex justify-content-center">
                    <img
                        v-if="this.space['LogoURL']"
                        :src="this.space['LogoURL']"
                        width="auto"
                        height="65"
                        alt="Hafven Community"
                        class="mb-2"
                    />
                </div>
                <div class="d-flex justify-content-center mb-4">
                    <h2>Internet Access</h2>
                </div>
                <div class="d-flex justify-content-center text-center">
                    <b-overlay :show="loading" variant="white" spinner-variant="primary">
                        <h2 v-if="this.$store.state.me.ID != null">
                            Hi {{ this.$store.state.me.Profile.Name }}, welcome back!
                        </h2>
                        <h2 v-else>Hi Dear, welcome back!</h2>

                        <div class="d-flex justify-content-center">
                            <b-button class="btn mt-2 btn-primary" @click="hardwareSessionCheckin">Checkin</b-button>
                        </div>
                    </b-overlay>
                </div>
                <div class="text-center mt-5">
                    <a href="https://mitglieder.hafven.de/password/new" target="_blank" class="d-block text-muted"
                        >Forgot password</a
                    >
                    <a href="https://hafven.de/datenschutz" target="_blank" class="d-block text-muted">Datenschutz</a>
                </div>
            </div>
        </div>
        <!-- Panel end -->
    </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import Router from 'vue-router';
import { readlink } from 'fs';
import $ from 'jquery';

export default {
    data() {
        return {
            locked: false,
            errorTitle: null,
            errorDetails: null,
            errorSubtitle: null,

            isCNA: false,

            email: null,
            password: null,
            showPassword: false,
            loading: false,

            showCheckinPanel: true,
            showHardwareSessionPanel: false,
            showHardwareSessionCheckinSuccessMsg: false,

            showLoginMaskPanel: false,
            showBuyDayPassPanel: false,

            redirectURL: this.$route.query.url,
            wifiEvent: {
                ID: this.$route.query.id,
                Timestamp: this.$route.query.t,
                Location: this.$route.params.location,
                APMacAddress: this.$route.query.ap,
                SSID: this.$route.query.ssid,
            },

            space: null,
        };
    },
    computed: {
        state() {
            return this.$store.getters.state;
        },
        components() {
            return JSON.stringify(this.$options.components);
        },
    },
    beforeCreate() {
        this.$store
            .dispatch('getSpaceSettings', null)
            .then((response) => {
                this.space = response;
            })
            .catch((error) => {
                console.log('getSpaceSettings request failed with err:', error);
                if (error.response && error.response.data) {
                    console.log(error.response.data);
                }
            });
    },
    mounted() {
        this.isCNA = !this.isLocalStorage();
    },
    methods: {
        // isLocalStorage checks if local storage available to detect wheather it is CNA popup or not
        isLocalStorage() {
            const test = 'test';
            try {
                localStorage.setItem(test, test);
                localStorage.removeItem(test);
                return true;
                // available
            } catch (e) {
                return false;
                // unavailable
            }
        },

        // checking using hardware session
        hardwareSessionCheckin() {
            this.showCheckinPanel = false;
            this.showHardwareSessionPanel = true;

            const wifiEvent = JSON.stringify(this.wifiEvent);
            this.$store
                .dispatch('loginWithHardwareSession', wifiEvent)
                .then((response) => {
                    // hardware session was successfuly used to create checkin
                    // show user loader and check if internet already available
                    console.log('response', response);
                    this.showHardwareSessionCheckinSuccessMsg = true;
                    this.amIOnline();
                }, this)
                .catch((error) => {
                    this.showHardwareSessionPanel = false;
                    this.showLoginMaskPanel = true;
                });
        },

        // wifi checkin
        loginAndCheckin() {
            this.errorTitle = null;
            this.errorDetails = null;
            this.loading = true;
            let wifiEvent = {
                Email: this.email,
                Password: this.password,
                UserMacAddress: this.$route.query.id,
                Timestamp: this.$route.query.t,
                Location: this.$route.params.location,
                APMacAddress: this.$route.query.ap,
            };
            wifiEvent = JSON.stringify(wifiEvent);

            this.showLoginMask = false;
            this.$store
                .dispatch('logincheckin', wifiEvent)
                .then((response) => {
                    this.amIOnline();
                }, this)
                .catch((error) => {
                    if (
                        error.response &&
                        error.response.status === 401 &&
                        error.response.data &&
                        error.response.data.Code === '10003'
                    ) {
                        this.errorTitle = 'To keep your account secure, we have initiated a password reset.';
                        this.errorSubtitle =
                            'Please check your email for a link to reset your password. If you do not receive our message within the next few minutes, check your spam folder or try to reset your password again.';
                    } else if (error.response.status == 402) {
                        // no left day passes
                        this.showLoginMaskPanel = false; // hide mask keep pasword and email
                        this.showBuyDayPassPanel = true; // show daypass
                    } else if (error.response.status === 404) {
                        this.errorTitle = 'Incorrect email or password';
                        this.errorSubtitle = 'The email or password you entered is incorrect. Please try again';
                        this.errorDetails = error.response.data;
                    } else if (error.response.status === 400) {
                        this.errorTitle = 'Bad request';
                        this.errorDetails = error.response.data;
                    } else if (error.response.status === 423) {
                        this.locked = true;
                    } else if (error.response && error.response.data) {
                        this.errorTitle = error.response.data.message;
                        this.errorDetails = error.response.data.details;
                    }
                    this.loading = false;
                }, this);
        },

        // check internet connection function
        checkConnection(callback) {
            console.log('checkConnection');
            const imgSrc =
                'http://s3.eu-central-1.amazonaws.com/hafven-community-app-uploads/static-files/never-delete-connection-test.png';
            const randomNum = Math.round(Math.random() * 100000000);
            const testImage = $('<img id="testImage">');

            testImage
                .appendTo('body')
                .hide()
                .on('error', () => {
                    try {
                        testImage.remove(); // remove image from dom
                        callback(true); // error callback
                    } catch (err) {}
                })
                .on('load', () => {
                    try {
                        callback(false, true); // success callback
                        testImage.remove(); // remove image from dom
                    } catch (err) {}
                })
                .attr('src', `${imgSrc}?rand=${randomNum}`);
        },
        amIOnline() {
            console.log('amIOnline');
            var testConnection = function (thiss) {
                setTimeout(() => {
                    thiss.checkConnection((error, success) => {
                        console.log('success', success);
                        console.log('error', error);

                        if (error) {
                            testConnection(); // try again
                        } else {
                            // success
                            console.log('amIOnline');
                            thiss.$router.push('/guest/wifisuccess');
                            thiss.$router.go();
                            console.log('amIOnline');
                        }
                    }, thiss);
                }, 1000); // wait for 1 secs
            };
            testConnection(this);
        },

        buyDayPass() {
            this.resetToDefaults();
            this.loading = true;
            this.errorMsg = 'Requesting new Day Pass...';
            this.$store
                .dispatch('buyDayPass')
                .then((response) => {}, this)
                .catch((error) => {}, this);
        },

        goToPlatform() {
            this.$router.push({ path: '/dashboard' });
        },

        resetToDefaults() {
            this.success = null;
            this.error = null;
            this.errorMsg = '';
            this.loading = false;
        },
    },
};
</script>
