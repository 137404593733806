


































































































































































































import EmojiPicker from 'vue-emoji-picker';
import tippy, { sticky } from 'tippy.js';
import { Editor } from '@tiptap/core';
import { EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Mention from '@tiptap/extension-mention';
import Cohashtag from '@coapp/cohashtag';
import Placeholder from '@tiptap/extension-placeholder';
import { HardBreak } from '@tiptap/extension-hard-break';
import myemojis from './myemojis.js';

import MentionSuggestion from './mentions/MentionSuggestion';
import HashtagSuggestion from './hashtags/HashtagSuggestion';

import { keys } from 'lodash';
import i18n from 'vue-i18n';

export default {
    i18n: {
        messages: {
            de: {
                'Frequently used': 'Häufig verwendet',
                People: 'Menschen',
                Objects: 'Objekte',
                Places: 'Orte',
                Symbols: 'Symbole',
            },
        },
    },
    name: 'Editor',
    props: {
        id: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: 'Write something...',
        },
        rows: {
            type: String,
            default: '',
        },
        taclass: {
            type: String,
            default: '',
        },
        withSendAction: {
            type: Boolean,
            default: false,
        },
        withMentions: {
            type: Boolean,
            default: true,
        },
        withHashtags: {
            type: Boolean,
            default: true,
        },
        withImage: {
            type: Boolean,
            default: false,
        },
        imageFiles: {
            type: Array,
            default() {
                return [];
            },
        },
        value: {
            type: String,
            default: '',
        },
        expandOnStart: {
            type: Boolean,
            default: false,
        },
        isPost: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        EmojiPicker,
        EditorContent,
        Cohashtag,
    },

    data() {
        return {
            expanded: {
                type: Boolean,
                default: false,
            },
            search: '',
            editor: null,

            query: null,
            filteredItems: [],

            isEditorEmpty: {
                type: Boolean,
                default: true,
            },

            links: {
                type: Array,
                default() {
                    return [];
                },
            },

            content: {
                type: String,
                default: '',
            },
        };
    },
    computed: {
        myemojiTable() {
            // translate the emoji table!
            let titles = keys(myemojis);
            let emojis = {};
            titles.map((item) => {
                emojis[this.$t(item)] = myemojis[item];
            });
            return emojis;
        },
    },
    mounted() {
        this.expanded = this.expandOnStart;

        const that = this;
        const editorElement = this.$refs.editor;
        this.editor = new Editor({
            element: editorElement,
            content: this.value,
            extensions: [
                StarterKit,
                Placeholder.configure({
                    includeChildren: true,
                    placeholder: ({ node }) => this.placeholder,
                }),
                Mention.configure({
                    HTMLAttributes: {
                        class: 'mention',
                    },
                    suggestion: MentionSuggestion,
                }),
                Cohashtag.configure({
                    HTMLAttributes: {
                        class: 'cohashtag',
                    },
                    suggestion: HashtagSuggestion,
                }),
                HardBreak,
            ],
            onFocus({ editor, event }) {
                // The editor is focused.
                that.onFocus();
            },
            onBlur({ editor, event }) {
                // The editor isn’t focused anymore.
                that.onBlur();
            },
            onUpdate({ editor }) {
                // The editor content has changed.
                const content = editor.getHTML();
                that.findAllLinks(content);
                that.$emit('input', content);
                that.content = content;
            },
        });
    },
    watch: {
        // goal of this watcher to pass parent content to tiptap editor
        value(val, old) {
            if (this.editor && val !== this.content) {
                // prevent infinite loop when setting value
                this.editor.commands.setContent(val);
            }
        },
    },
    methods: {
        findAllLinks(text: string) {
            this.links = [];
            if (!text) {
                return;
            }

            const regex =
                /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
            const str = text;
            let m;

            while ((m = regex.exec(str)) !== null) {
                // This is necessary to avoid infinite loops with zero-width matches
                if (m.index === regex.lastIndex) {
                    regex.lastIndex++;
                }

                // The result can be accessed through the `m`-variable.
                m.forEach((match, groupIndex) => {
                    this.links.push(m[0]);
                });
            }
        },

        /**
         * Clear the editor content
         * @param {boolean} emit - Emit the input event
         * @param {boolean} collapse - Collapse the editor
         * @return {void}
         */
        clear(collapse = false, emit = true) {
            this.editor.chain().clearContent(true).blur().run();

            if (collapse) {
                this.collapse();
            }

            if (emit) {
                this.$emit('input', '');
            }
        },

        /**
         * Focus the editor
         * @return {void}
         */
        focus(expand = false) {
            this.editor.commands.focus();
            if (expand) {
                this.expand();
            }
        },

        /**
         * Collapse the editor
         * @return {void}
         */
        collapse() {
            this.expanded = false;
        },

        /**
         * Expand the editor
         * @return {void}
         */
        expand() {
            this.expanded = true;
        },
        /**
         * Function called when the editor is focused (onFocus)
         * Used to expand the editor if it's collapsed and to emit the focus event
         * @return {void}
         */
        onFocus() {
            this.$emit('onFocus');
            this.expanded = true;
        },

        /**
         * Function called when the editor is blurred (onBlur)
         * Used to to emit the blur event
         * @return {void}
         */
        onBlur() {
            this.$emit('onBlur');
        },

        startImagePicker() {
            this.$refs['image-input'].click();
        },

        previewFiles(event) {
            Array.from(event.target.files).forEach((element?: Blob | MediaSource) => {
                const attachment = {
                    File: element,
                    Preview: URL.createObjectURL(element),
                };
                this.imageFiles.push(attachment);
            });
        },

        deletePreview(index) {
            this.imageFiles.splice(index, 1);
        },

        textAreaClass() {
            const result = [];

            if (this.taclass) result.push(this.taclass.split(' '));

            if (this.expanded) result.push('active');

            return result;
        },

        insertEmoji(emoji) {
            this.editor.chain().focus().insertContent(emoji).run();
            this.$refs.emo.hide();
            const that = this;
            setTimeout(() => {
                that.expanded = true;
            }, 1);
        },

        mention() {
            this.editor.chain().focus().insertContent(' @').run();
            this.editor.focus();
        },
        hashtag() {
            this.editor.chain().focus().insertContent(' #').run();
            this.editor.focus();
        },
    },
    beforeDestroy() {
        this.editor.destroy();
    },
};
