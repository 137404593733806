<template>
    <div
        v-if="og"
        class="border rounded d-flex justify-content-begin overflow-hidden pointer linkPreview"
        @click="openLink"
    >
        <b-img
            v-if="og['image'] != null && og['image'].length != 0 && og.image[0]['url'] != null"
            :src="og.image[0].url"
            class="border-right image"
            rounded="left"
        ></b-img>
        <div v-else class="border-right image bg-grey"></div>
        <div class="p-2">
            <small class="text-dark">{{ og['title'] }}</small>
            <small class="linkPreviewDescription">{{ og['description'] }}</small>
            <a :href="verifyedURL" v-on:click.prevent><b-icon icon="paperclip"></b-icon>{{ host }}</a>
        </div>
    </div>
</template>

<style lang="less" scoped>
@import '~@/assets/less/variables.less';
.linkPreview:hover {
    background-color: @co-lightgrey;
}
.linkPreview .image {
    height: 65px;
    min-width: 64px;
    width: 64px;
    object-fit: cover;
}

.linkPreviewDescription {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
</style>

<script>
export default {
    name: 'LinkPreview',
    data() {
        return {
            og: null,
            host: '',
            loading: false,
        };
    },
    props: {
        url: {
            type: String,
            default: '',
        },
    },
    computed: {
        verifyedURL() {
            if (this.url == null || this.url.length === 0) {
                return '';
            }
            // if url does not start with http or https, add https
            if (!this.url.match(/^(http|https):\/\//)) {
                return `https://${this.url}`;
            }
            return this.url;
        },
    },
    watch: {
        url(newVal, oldVal) {
            this.getLocation();
            this.getOG();
        },
    },
    mounted() {
        this.getLocation();
        this.getOG();
    },
    methods: {
        openLink() {
            window.open(this.verifyedURL, '_blank').focus();
        },
        getLocation() {
            const l = document.createElement('a');
            l.href = this.verifyedURL;
            this.host = l.hostname;
        },
        getOG() {
            this.loading = true;

            this.$store
                .dispatch('getOpenGraph', this.verifyedURL)
                .then((response) => {
                    if (response != null) {
                        this.og = response;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
    },
};
</script>
