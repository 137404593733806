

















import CoThumbnail from '@/components/Molecules/co-thumbnail/CoThumbnail.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';

export default {
    name: 'UserList',
    components: {
        CoThumbnail,
        CoText,
    },
    data() {
        return {
            isUserlist: true,
        };
    },
    props: {
        items: Array,
    },
    methods: {},
};
