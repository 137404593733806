


































































































































import { PropType } from 'vue';
import axios from 'axios';
import EventBus from '@/eventBus';
import CoModal from '@/components/Organisms/co-modal/CoModal.vue';
import { MarketItem } from './models.ts';

import CoCard from '../co-card/CoCard.vue';
import CoHeadline from '../../Atoms/co-headline/CoHeadline.vue';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoThumbnail from '../co-thumbnail/CoThumbnail.vue';
import CoText from '../../Atoms/co-text/CoText.vue';
import CoLink from '../../Atoms/co-link/CoLink.vue';
import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoPill from '../../Atoms/co-pill/CoPill.vue';
import CoSkeleton from '../../Atoms/co-skeleton/CoSkeleton.vue';

export default {
    i18n: {
        messages: {
            en: {
                marketiterestfailed: 'Your request failed with an error. Please, report to team.',
            },
            de: {
                marketiterestfailed: 'Deine Anfrage ist fehlgeschlagen. Bitte melde dich beim Team.',
            },
        },
    },
    name: 'CoCardMarketItem',
    components: {
        CoModal,
        CoCard,
        CoHeadline,
        CoThumbnail,
        CoButton,
        CoLink,
        CoText,
        CoIcon,
        CoPill,
        CoSkeleton,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        marketItem: {
            type: Object as PropType<MarketItem>,
            required: true,
            default: () => ({} as MarketItem),
        },
        userId: {
            type: String,
            required: true,
            default: () => ({} as string),
        },
    },

    data() {
        return {
            followedByUser: false,
            interest: {
                UserID: this.userId,
                JobID: '',
            },
            amIInterested: false,
            interestLoaded: false,
            isMobile: false,
        };
    },
    mounted() {
        this.checkScreenSize();
        window.addEventListener('resize', this.checkScreenSize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkScreenSize);
    },
    computed: {
        marketItemURL() {
            return `/job/${this.marketItem.slug}`;
        },
        iAmAuthor() {
            return this.userId === this.marketItem.authorId;
        },
        modalId() {
            return `apply-modal-${this.marketItem.id}`;
        },
    },
    watch: {
        marketItem: {
            handler() {
                this.getUsersInterestedInJob();
            },
            deep: true,
        },
    },
    created() {
        if (this.marketItem && this.marketItem.title) {
            this.getUsersInterestedInJob();
        }
    },
    methods: {
        randomPercents(from = 0, to = 100) {
            const percents = Math.floor(Math.random() * (to - from + 1) + from);
            return `${percents}%`;
        },
        checkScreenSize() {
            this.isMobile = window.innerWidth < 576;
        },
        showModal(id) {
            this.$refs[id].show();
        },
        view() {
            this.$router.push(this.marketItemURL);
        },
        getUsersInterestedInJob() {
            axios({
                method: 'GET',
                url: `/job/get-interested-users/${this.marketItem.id}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response.data.Interests) {
                        response.data.Interests.forEach(function (entry) {
                            if (this.userId === entry.UserID) {
                                this.amIInterested = true;
                            }
                        }, this);
                    }
                })
                .finally(() => {
                    this.interestLoaded = true;
                });
        },
        iAmInterested() {
            this.interest.JobID = this.marketItem.id;
            axios({
                method: 'POST',
                url: '/job/interest',
                data: JSON.stringify(this.interest),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(() => {
                    const msg = {
                        Message: this.$t('labels.interestedsent'),
                        Details: '',
                    };
                    EventBus.$emit('INFO', msg);
                    this.amIInterested = true;
                    this.$refs[this.modalId].hide();
                })
                .catch(() => {
                    EventBus.$emit('ERROR', {
                        Message: { $t: 'messages.marketiterestfailed' },
                        Details: '',
                    });
                    this.$refs[this.modalId].hide();
                });
        },
    },
};
