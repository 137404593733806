var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid",style:(_vm.styleObject)},[_c('div',{staticClass:"d-flex align-items-center h-25"},[(this.$store.state.space.LogoURL)?_c('img',{staticClass:"ml-5",attrs:{"src":this.$store.state.space.LogoURL,"width":"350px","height":"auto","alt":this.$store.state.space.Name}}):_c('div',{staticClass:"ml-5"},[_c('h2',[_vm._v(_vm._s(this.$store.state.space.Name))])])]),_c('div',{staticClass:"d-block h-75 m-0"},[(_vm.slide)?_c('b-carousel',{ref:"myCarousel",staticClass:"h-100",attrs:{"interval":10000,"id":"carousel-1"},on:{"sliding-end":_vm.onSlideEnd}},_vm._l((_vm.slide),function(page,index){return _c('div',{key:index,staticClass:"carousel-item active row align-items-center row"},_vm._l((page),function(post,index2){return _c('div',{key:index2,staticClass:"col-md-4 p-0 d-inline-block"},[_c('div',{staticClass:"ml-2",style:({
                            height: _vm.sliderHeight + 'px',
                        })},[(_vm.space === 'project')?_c('div',{staticClass:"p-4 h-50",style:({
                                background: 'white',
                                overflow: 'hidden',
                            })},[_c('h8',{class:_vm.timeCssClasses(post.object.LastModifyed + '000')},[_vm._v("Last Updated "+_vm._s(_vm._f("readabledate")((post.object.LastModifyed + '000'))))]),_c('h2',{domProps:{"innerHTML":_vm._s(post.object.Title)}}),_c('p',[_vm._v(" "+_vm._s(post.object.Description)+" ")])],1):_c('div',{staticClass:"p-4 h-50",style:({
                                background: 'white',
                                overflow: 'hidden',
                            })},[_c('h4',{class:_vm.timeCssClasses(post.object.StartDate + '000')},[_vm._v(" "+_vm._s(_vm._f("readabledate")((post.object.StartDate + '000')))+" ")]),_c('h2',{class:_vm.timeCssClasses(post.object.StartDate + '000')},[_vm._v(" "+_vm._s(_vm._f("readabletime")((post.object.StartDate + '000')))+" - "+_vm._s(_vm._f("readabletime")((post.object.EndDate + '000')))+" ")]),_c('h2',{domProps:{"innerHTML":_vm._s(post.object.Title)}})]),_c('div',{staticClass:"h-50",style:({
                                'background-image': 'url(' + _vm.filterPictures(post.object.ImageURL) + ')',
                                'background-size': 'cover',
                                'background-position': 'center',
                                height: '200px',
                            })})])])}),0)}),0):_vm._e(),_c('b-modal',{attrs:{"centered":"","id":"warningModal","size":"xl","title":"Warning","content-class":"gradient-bg mediocre text-center py-5","hide-header":true,"hide-footer":true}},[_c('p',{staticStyle:{"font-size":"8em"}},[_c('b-icon',{attrs:{"icon":"exclamation-octagon-fill"}})],1),_c('h2',[_vm._v(" Der CO"),_c('sub',[_vm._v("2")]),_vm._v("-Gehalt in der Luft ist hoch. Bitte lüfte diesen Raum bis diese Warnung nicht mehr angezeigt wird. ")]),_c('h5',[_vm._v("Es dauert bis zu 10 Minuten bis diese Anzeige aktualisiert wird.")])]),(_vm.spaceDetail)?_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row px-3"},[_c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"mb-5",staticStyle:{"font-size":"5em"}},[_vm._v(" "+_vm._s(_vm.spaceDetail.health.station_name)+" ")]),_c('p',[_c('small',[_c('b-icon',{attrs:{"icon":"info-circle"}}),_vm._v(" Time of measurement "+_vm._s(_vm._f("readabledate")((_vm.spaceDetail.health.time_utc * 1000)))+", "+_vm._s(_vm._f("readabletime")((_vm.spaceDetail.health.time_utc * 1000)))+". Data is updated every 10 minutes. ")],1)])]),_vm._l((_vm.spaceDetail.health.items),function(item,key){return _c('div',{key:key,staticClass:"col-6 mt-4"},[_c('div',{staticClass:"card value feedpost gradient-bg"},[_c('div',{staticClass:"card-body py-5"},[_c('p',[_c('span',{staticClass:"h2",domProps:{"innerHTML":_vm._s(item.title)}})]),_c('h1',[_c('b-icon',{attrs:{"icon":item.icon}}),_vm._v(" "+_vm._s(item.data)+" "+_vm._s(item.unit)+" ")],1),_c('div',{staticClass:"position-relative"},[_c('div',{staticClass:"d-inline position-absolute text-center",style:({
                                        left: ((item.data - item.minValue) / item.range) * 100 + '%',
                                        width: '0px',
                                        marginLeft: '-.5em',
                                    })},[_c('b-icon',{attrs:{"icon":"caret-down-fill"}})],1)]),(item.limits)?_c('div',{staticClass:"progress mt-5"},_vm._l((item.limits),function(limit,index){return _c('div',{key:index,class:limit.class,style:([
                                        item.limits[index + 1]
                                            ? {
                                                  width:
                                                      ((item.limits[index + 1].value - limit.value) / item.range) *
                                                          100 +
                                                      '%',
                                              }
                                            : {
                                                  width: ((item.maxValue - limit.value) / item.range) * 100 + '%',
                                              } ])})}),0):_vm._e(),(item.minValue)?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col text-left"},[_c('small',[_vm._v(_vm._s(item.minValue)+" "+_vm._s(item.unit))])]),_c('div',{staticClass:"col text-right"},[_c('small',[_vm._v(_vm._s(item.maxValue)+" "+_vm._s(item.unit))])])]):_vm._e()])])])})],2)]):_vm._e(),(_vm.error)?_c('div',{staticClass:"alert-danger col-md-12"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }