var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex pb-5"},[_c('div',{staticClass:"mx-auto flex-grow-1 mw-100"},[_c('CoTable',{attrs:{"items":_vm.pages,"itemsPerPage":50,"loading":this.loading,"resizableColumns":"","columns":[
                { key: 'Title', title: 'Page Name' },
                { key: 'Published', title: 'Published', style: 'width:120px' },
                { key: 'Channel.Value', title: 'Channel' },
                { key: 'Owners', title: 'Owners', notSortable: true, style: 'padding-left: .75rem' },
                { key: 'LastModifyed', title: 'Updated' },
                { key: 'Created', title: 'Created' } ]},scopedSlots:_vm._u([{key:"Title",fn:function(slotProps){return [_c('CoLink',{attrs:{"title":slotProps.item.Title,"to":_vm.get(slotProps.item, 'Channel.Slug', null)
                            ? '/channels/' + _vm.get(slotProps.item, 'Channel.Slug') + '/' + slotProps.item.Slug
                            : '/project/' + slotProps.item.Slug,"styles":"co-link primary truncate","newTab":""}})]}},{key:"Published",fn:function(slotProps){return [(!slotProps.item.Published)?_c('span',[_vm._v("No")]):_c('span',[_vm._v("Yes")])]}},{key:"Channel\.Value",fn:function(slotProps){return [(_vm.get(slotProps.item, 'Channel.Value', null))?_c('CoLink',{attrs:{"title":_vm.get(slotProps.item, 'Channel.Value'),"to":("/channels/" + (_vm.get(slotProps.item, 'Channel.Slug', 'unknown'))),"styles":"co-link primary truncate","newTab":""}}):_c('span',[_vm._v("none")])]}},{key:"Owners",fn:function(slotProps){return [(_vm.get(slotProps.item, 'Owners', []).length > 0)?_c('div',_vm._l((_vm.get(slotProps.item, 'Owners', null)),function(user,index){return _c('CoLink',{key:_vm.get(slotProps.item, 'ID') + index,attrs:{"title":_vm.get(user, 'Profile.Name', 'Unknown'),"to":("/admin/community/member/view/" + (_vm.get(user, 'ID', null))),"styles":"co-link primary truncate","newTab":""}})}),1):_c('div',[_vm._v("none")])]}},{key:"Created",fn:function(slotProps){return [_c('CoDate',{attrs:{"date":slotProps.item.Created,"format":"relative"}})]}},{key:"LastModifyed",fn:function(slotProps){return [_c('CoDate',{attrs:{"date":slotProps.item.LastModifyed,"format":"relative"}})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }