var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.feed.length == 0 && !_vm.searchLoading)?_c('div',[_c('div',{staticClass:"mt-3 text-center"},[_c('h3',[_c('strong',[_vm._v(_vm._s(_vm.$t('noresultsforkeyword', { query: _vm.query })))])]),_c('span',[_vm._v(_vm._s(_vm.$t('searchhelp')))])])]):_vm._e(),(_vm.searchLoading)?_c('div',_vm._l((10),function(i){return _c('CoCardPage',{key:i,staticClass:"mb-2 mb-md-3",attrs:{"loading":"","page":{}}})}),1):_vm._e(),(_vm.feed.length != 0)?_c('div',[_c('span',{staticClass:"pl-3"},[_vm._v(_vm._s(_vm.$tc('results', _vm.results)))]),_c('div',{staticClass:"mt-3"},_vm._l((_vm.feed),function(post,index){return _c('div',{key:(index + " " + (post.key))},[(post.type === 'project')?_c('CoCardPage',{staticClass:"mb-2",attrs:{"page":post.page},on:{"follow":_vm.follow,"unfollow":_vm.unfollow}}):(post.type === 'event')?_c('CoCardEvent',{staticClass:"mb-2",attrs:{"event":post.event}}):(post.type === 'job')?_c('CoCardMarketItem',{staticClass:"pb-2",attrs:{"marketItem":post.marketItem,"user-id":_vm.$store.state.me.ID}}):(post.type === 'user')?_c('co-card-member',{staticClass:"pb-2",attrs:{"member":{
                        id: post.object.ID,
                        name: post.object.Profile.Name,
                        image: post.object.Profile.ImageURL,
                        headline: post.object.Profile.Bio,
                        slug: post.object.Slug,
                        space: _vm.mapSpaceObject(post.space),
                    },"hideMessageBtn":post.object.ID === _vm.$store.state.me.ID},on:{"message":_vm.sendMessage}}):_vm._e()],1)}),0)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }