/*
vue-i18n (v8.x) guide
https://kazupon.github.io/vue-i18n/guide/formatting.html
*/
import { formattedDate } from './dateTimeFormatter.ts';

const enUS = {
    labels: {
        about: 'About',
        aboutme: 'About me',
        acceptprivacy: 'I have read and accept the {0}',
        acceptterms: 'I have read and accept the {0}',
        accepttermsandprivacy: 'I have read and accept the {privacypolicy} and the {termsandconditions}',
        account: 'Account',
        accountsettings: 'Account settings',
        activity: 'Activity',
        add: 'Add',
        address: 'Address',
        addressadditionalinformation: 'Additional information',
        amount: 'Amount',
        and: 'and',
        announcement: 'Announcement',
        announcementmake: 'Make @.lower:labels.announcement',
        announcementsentby: '@:labels.announcement sent by {name}',
        attendees: 'Attendees',
        author: 'Author',
        availability: 'Availability',
        awaynotice: 'Away notice',
        back: 'Back',
        billing: 'Billing',
        billingaddress: 'Billing address',
        billingaddressedit: '@:labels.edit @.lower:labels.billingaddress',
        booking: 'Booking',
        bookingcancel: '@:labels.cancel @.lower:labels.booking',
        bookingconditions: '@:labels.booking conditions',
        bookingconfirm: 'Book now',
        bookingconfirmpaid: 'Complete paid booking',
        bookings: 'Bookings',
        bookingsmy: 'My Bookings',
        by: 'By',
        cancel: 'Cancel',
        cancellationnotice: 'Cancellation notice',
        cancellationpolicy: 'Cancellation policy',
        channel: 'Channel',
        channels: 'Channels',
        chat: 'Chat',
        chatnew: '@:labels.new @.lower:labels.chat',
        category: 'Category',
        city: 'City',
        clear: 'Clear',
        clearall: 'Clear all',
        close: 'Close',
        closed: 'Closed',
        comment: 'Comment',
        commentdelete: '@:labels.delete @.lower:labels.comment',
        comments: 'Comments',
        community: 'Community',
        communityrecentlyseen: 'Recently seen online',
        company: 'Company',
        confirm: 'Confirm',
        configuration: 'Configuration',
        contact: 'Contact',
        contributers: 'Contributors',
        contributersadd: 'Add contributors',
        couponcode: 'Coupon code',
        country: 'Country',
        create: 'Create',
        created: 'Created',
        createdat: '@:labels.created at',
        createdon: '@:labels.created on',
        credit: 'Credit',
        credits: 'Credits',
        day: 'day | days',
        description: 'Description',
        delete: 'Delete',
        details: 'Details',
        draft: 'Draft',
        edit: 'Edit',
        email: 'Email',
        enabled: 'enabled',
        enddate: 'End date',
        endtime: 'End time',
        event: 'Event',
        eventcreate: '@:labels.create @.lower:labels.event',
        eventdelete: '@:labels.delete @.lower:labels.event',
        eventedit: '@:labels.edit @.lower:labels.event',
        eventtime: 'Time',
        events: 'Events',
        eventsupcoming: 'Upcoming events',
        eventyour: 'your @.lower:labels.event',
        failedToSaveChanges: 'Failed to save changes',
        feed: 'Feed',
        finish: 'Finish',
        follow: 'Follow',
        follower: 'no followers | one follower | {n} followers',
        followers: 'Followers',
        following: 'Following',
        free: 'free',
        from: 'From',
        general: 'General',
        getstarted: 'Get started',
        going: 'Going',
        goingcount: 'is going | are going',
        group: 'Group',
        groupmember: 'Group member | Group members',
        groupnew: '@:labels.new @.lower:labels.group',
        hashtag: 'Hashtag',
        hashtagadd: '@:labels.add @.lower:labels.hashtag',
        headline: 'Headline',
        help: 'Help',
        hour: 'hour | hours',
        hourshort: 'h',
        imprint: 'Imprint',
        image: 'Image',
        imageadd: '@:labels.add @.lower:labels.image',
        imageguidelines: 'Image Guidelines',
        including: 'incl.',
        information: 'Information',
        interested: "I'm interested",
        interestedpeople: 'Interested',
        interestedsent: 'Request sent',
        invoice: 'Invoice',
        invoices: 'Invoices',
        invoicepreview: 'Invoice preview',
        join: 'Join',
        letstalkabout: "Let's talk about",
        link: 'Link',
        linkadd: '@:labels.add @.lower:labels.link',
        links: 'Links',
        loading: 'loading',
        location: 'Location',
        login: 'Log in',
        logout: 'Log out',
        markallasread: 'Mark all as read',
        market: 'Market',
        marketitem: 'Market item',
        marketitems: 'Market items',
        marketitemyour: 'your @.lower:labels.marketitem',
        marketitemclose: '@:labels.close @.lower:labels.marketitem',
        marketitemcreate: '@:labels.create @.lower:labels.marketitem',
        marketitemdelete: '@:labels.delete @.lower:labels.marketitem',
        marketitemedit: '@:labels.edit @.lower:labels.marketitem',
        month: 'month',
        morecount: '{count} more',
        mentionsmo: 'Mention someone',
        member: 'Member | Members',
        membership: 'Membership',
        membershipyour: 'Your @.lower:labels.membership',
        message: 'Message',
        messagenew: '@:labels.new @:labels.message',
        messenger: 'Messenger',
        my: 'My',
        mybookings: '@:labels.my @.lower:labels.bookings',
        myevents: '@:labels.my @.lower:labels.events',
        mypages: '@:labels.my @.lower:labels.pages',
        name: 'Name',
        namefirst: 'First name',
        namelast: 'Last name',
        next: 'Next',
        new: 'New',
        no: 'No',
        notificationpreferences: 'Notification preferences',
        notifications: 'Notifications',
        notificationspause: 'Pause notifications',
        notificationspaused: 'Notifications paused',
        notifymewhen: 'Notify me when',
        notifymewhenasadmin: 'Me as an admin',
        ok: 'OK',
        or: 'or',
        orderconfirm: 'Confirm order',
        ordercompletesubcription: 'Complete paid subscription',
        ordercompletesubcriptionfree: 'Complete free subscription',
        orderreview: 'Review',
        ordercheckout: 'Checkout',
        organizers: 'Organizers',
        organizersadd: '@:labels.add @:labels.organizers',
        others: 'no one | one other | {n} others',
        owner: 'Owner',
        owners: 'Owners',
        ownersadd: 'Add owners',
        pagecreate: '@:labels.create @.lower:labels.page',
        pagedelete: '@:labels.delete @.lower:labels.page',
        pageedit: '@:labels.edit @.lower:labels.page',
        pagepublish: '@:labels.publish @.lower:labels.page',
        pagepublished: '@:labels.page @.lower:labels.published ',
        page: 'Page',
        pages: 'Pages',
        pageyour: 'your @.lower:labels.page',
        pagesmy: '@:labels.mypages',
        paid: 'paid',
        password: 'Password',
        passwordreset: 'Reset password',
        pause: 'Pause',
        paymentmethod: 'Payment method',
        paymentmethodadd: '@:labels.add @.lower:labels.paymentmethod',
        paymentmethods: 'Payment methods',
        phoneNumber: 'Phone number',
        plan: 'Plan',
        plancancel: 'Cancel plan',
        planchange: 'Change plan',
        planchangecancel: 'Cancel plan change',
        planchoose: 'Choose plan',
        plancurrent: 'Your @.lower:labels.plan',
        planreactivate: 'Undo cancellation',
        planselection: 'Choose your plan',
        planupcoming: 'Upcoming plan',
        planupgrades: 'Plan upgrades',
        planupgradechose: 'Select your plan upgrades',
        post: 'Post',
        postyour: 'your @.lower:labels.post',
        postalcode: 'ZIP code',
        postbtn: '@:labels.post',
        postedit: '@:labels.edit @.lower:labels.post',
        previous: 'Previous',
        profile: 'Profile',
        profileedit: '@:labels.edit @:labels.profile',
        profilepublish: '@:labels.publish @.lower:labels.profile ',
        profilename: 'Profile name',
        pricesubtotal: 'Subtotal',
        pricetotal: 'Total',
        pricevaries: 'prices vary',
        privacypolicy: 'Privacy Policy',
        publish: 'publish',
        published: 'published',
        pushnotification: 'Push notification',
        push: 'Push',
        quantity: 'Quantity',
        readmore: 'read more',
        redeem: 'Redeem',
        refresh: 'Refresh',
        reportbug: 'Report a bug',
        remaining: 'left',
        remove: 'Remove',
        resource: 'Resource',
        resources: 'Resources',
        review: 'Review',
        salutation: 'Salutation',
        salutationfemale: 'Ms.',
        salutationmale: 'Mr.',
        salutationnonbinary: 'Mrx.',
        save: 'Save',
        seeall: 'See all',
        send: 'Send',
        skip: 'Skip',
        signup: 'Sign up',
        startdate: 'Start date',
        starttime: 'Start time',
        subscriptioncreate: 'Create subscription',
        suggestedcontent: 'Suggested content',
        summary: 'Summary',
        tags: 'Tags',
        tax: 'Tax',
        termsandconditions: 'Terms and Conditions',
        ticketurl: 'Ticket link',
        time: 'Time',
        timepass: 'Time pass',
        timepasses: 'Time passes',
        title: 'Title',
        to: 'To',
        today: 'Today',
        total: 'Total',
        tryagain: 'Try again',
        unfollow: 'Unfollow',
        unsavedchanges: 'Unsaved changes',
        updated: 'Updated',
        updatedat: '@:labels.updated at',
        updatedon: '@:labels.updated on',
        update: 'Update',
        updatecreate: '@:labels.create @.lower:labels.update',
        updatedelete: '@:labels.delete @.lower:labels.update',
        updateedit: '@:labels.edit @.lower:labels.update',
        updates: 'Updates',
        upgrades: 'Upgrades',
        vatid: 'VAT ID',
        week: 'Week',
        yes: 'Yes',
        year: 'year',
    },
    placeholders: {
        address: 'Street and number',
        addressadditionalinformation: "e.g. 'Appartment No. 1'",
        autocompleteempty: 'No results',
        comment: 'Leave a comment',
        couponcode: 'Enter coupon code',
        email: 'mail@example.com',
        file: 'No file selected',
        image: 'Click to change the image',
        marketitemcontact: 'link to a contact page or email',
        marketitemdescription:
            'The description of your item will be displayed on the detail page of your item and should describe it in detail.',
        marketitemtitle: 'Chose a good title :)',
        marketitemsummary: 'The summary of your item will be displayed in the feed and in the market list.',
        messagecreate: 'Write a message',
        optional: 'optional',
        password: 'enter your password',
        post: '@:labels.create @.lower:labels.post',
        postalcode: '12345',
        search: 'search',
        searchmembers: 'Search for members',
        select: '@:placeholders.selectplease',
        selectplease: 'Please select an option',
        tags: 'Start typing',
        updatecreate: '@:labels.create @.lower:labels.update',
    },
    messages: {
        accountexists: 'Already have an account?',
        cookiebanner:
            'This site uses cookies 🍪 to store information on your computer. Some are essential to make our site work; others help us improve the user experience. By using this site, you consent to the placement of these cookies. Read our {privacypolicy} to learn more.',
        deletewarning: 'Once you delete this, there is no going back. Please be certain.',
        imageguidelines:
            'Please avoid having text inside your image. The recommended image aspect ratio is 3x2 and the recommended image size is 1800x1200.',
        marketiterestsubmit: 'We will inform the author about your interest.',
        maxcharacters: 'max. {count} characters',
        searchnomatches: 'No results match your search criteria',
        unsavedwarning: 'Your changes will be lost. Are you sure?',
        usernotexists: 'User does not exist or was deleted',
    },
    datetime: ({ named }) =>
        formattedDate(
            named('date'),
            named('format'),
            named('locale') ? named('locale') : 'en-US',
            named('customFormat')
        ),
};
export default enUS;
