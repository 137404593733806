


















































































































































































































































































































































import Vue from 'vue';
import axios from 'axios';
import Router from 'vue-router';
// eslint-disable-next-line import/no-extraneous-dependencies
import i18n from 'vue-i18n';
import CoPost from '@/components/Organisms/co-post/CoPost.vue';
import { set, clone, uniq, map, get, uniqueId, isArray, update } from 'lodash';
import EventBus from '../../eventBus';
import CoThumbnail from '../../components/Molecules/co-thumbnail/CoThumbnail.vue';
import CoText from '../../components/Atoms/co-text/CoText.vue';
import CoButton from '../../components/Atoms/co-button/CoButton.vue';
import CoMatch from '../../components/Molecules/co-match/CoMatch.vue';
import CoHeadline from '../../components/Atoms/co-headline/CoHeadline.vue';

Vue.use(Router);
export default {
    i18n: {
        messages: {
            en: {
                joined: 'joined the community',
                notags: '{username} has not yet added tags.',
                noabout: '{username} has not yet submitted an about text.',
            },
            de: {
                joined: 'ist der Community beigetreten',
                notags: '{username} hat noch keine Tags.',
                noabout: '{username} hat noch keinen Text eingegeben.',
            },
        },
    },
    name: 'ViewProfile',
    components: {
        CoThumbnail,
        CoPost,
        CoText,
        CoButton,
        CoMatch,
        CoHeadline,
    },
    data() {
        return {
            user: {},
            showErrors: false,
            url: null,
            isMe: false,
            loader: false,
            tags: null,

            // feed
            feed: [],
            feedBank: [],
            feedNextPage: null,
            loading: false,
            loadingFeed: false,

            projects: [],
            jobs: null,

            isMarketOn: this.$store.state.space.MarkeplaceOn,

            focusPostID: get(this, '$route.query.focuspostid', ''),
        };
    },
    watch: {
        user(newVal, oldVal) {
            if (newVal) {
                this.feed = [];
                this.feedBank = [];
                this.getFeedForUser(newVal.ID);
                this.getProjectsForUser(newVal.ID);
                this.getJobsForUser(newVal.ID);
            }
            if (newVal.Profile.Tags) {
                this.loadTags(newVal.Profile.Tags);
            }
        },
    },
    beforeRouteUpdate(to, from, next) {
        // called when the route that renders this component has changed,
        // but this component is reused in the new route.
        // For example, for a route with dynamic params `/foo/:id`, when we
        // navigate between `/foo/1` and `/foo/2`, the same `Foo` component instance
        // will be reused, and this hook will be called when that happens.
        // has access to `this` component instance.
        const { slug } = to.params;
        this.getUserData(slug);
        next();
    },
    created() {
        const { slug } = this.$route.params;
        this.getUserData(slug);
    },
    mounted() {
        window.addEventListener('scroll', this.scroll);
        this.loading = true;
        this.feed = [];
        this.feedBank = [];
        this.getFeedForUser(this.user.ID);
    },

    destroyed() {
        window.removeEventListener('scroll', this.scroll);
    },
    methods: {
        get,
        getUserData(slug) {
            if (slug === 'me') {
                this.$data.isMe = true;
                this.$store
                    .dispatch('getMe', null)
                    .then((response) => {
                        this.user = response.data;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else if (slug.length >= 88) {
                const ids = JSON.stringify({ IDS: [{ ID: slug }] });
                this.$store
                    .dispatch('listUsersByIDs', { ids })
                    .then((response) => {
                        this.$data.user = response.Users[0];
                        this.$router.push(`/profile/${this.$data.user.Slug}`);
                    })
                    .catch((error) => {
                        console.log('error', error);
                    });
            } else {
                this.$store
                    .dispatch('getUserBySlug', slug)
                    .then((response) => {
                        this.$data.user = response;
                    })
                    .catch((error) => {
                        console.log('error ', error);
                        if (error.message.includes('404')) {
                            this.$router.replace('/feed/error404');
                        }
                    });
            }
        },

        getFeedForUser(userID) {
            this.loadingFeed = true;
            axios({
                method: 'GET',
                url: `/user/profile/feed/${userID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(async (response) => {
                    if (!response || !response.data || !response.data.objects) {
                        this.loadingFeed = false;
                        return;
                    }

                    let posts = response.data.objects;

                    // filter out the jobs if the market is disabled
                    if (!this.isMarketOn) {
                        posts = posts.filter((item) => item.type !== 'job');
                    }

                    // map content to post object structure
                    const fetchUsers = [];
                    posts.forEach((entry) => {
                        // skip if entry.object does not exist
                        if (!entry.object) return;

                        this.feedBank.push(entry);
                        if (entry.object.AuthorID || entry.object.CreatedBy || entry.object.UserID) {
                            fetchUsers.push(entry.object.AuthorID || entry.object.CreatedBy || entry.object.UserID);
                        }
                    }, this);

                    // fetch and populate post author info
                    let users = null;
                    try {
                        users = await axios({
                            method: 'POST',
                            url: '/user/listbyids',
                            data: { IDS: map(uniq(fetchUsers), (id) => ({ ID: id })) },
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        });
                        users = map(users.data.Users, (user) => ({
                            ID: get(user, 'ID'),
                            Name: get(user, 'Profile.Name'),
                            Slug: get(user, 'Slug'),
                            ImageURL: get(user, 'Profile.ImageURL'),
                            Bio: get(user, 'Profile.Bio'),
                        }));
                    } catch (error) {
                        console.log(error);
                    }

                    // map post object to co-post structure
                    this.feedBank.forEach((entry) => {
                        // skip if entry.object does not exist
                        if (!entry.object) return;
                        // assign author infos
                        if (entry.object.AuthorID || entry.object.CreatedBy || entry.object.UserID) {
                            entry.object.Author = users.find(
                                (user) =>
                                    user.ID === entry.object.AuthorID ||
                                    user.ID === entry.object.CreatedBy ||
                                    user.ID === entry.object.UserID
                            );
                        }
                        // assign space infos
                        entry.space ? (entry.object.Space = entry.space) : null;
                        // assign images for posts and page-updates (project-updates)
                        let tmpimages = get(entry, 'object.Images', null) || get(entry, 'object.ImageURLs', []);
                        entry.object.Images = map(tmpimages, (i) => ({ ImageURL: i }));
                        // map type
                        if (entry.type === 'Post') {
                            entry.type = 'post';
                        }
                        entry.object.Type = entry.type;
                        // assign parent content
                        if (entry.type !== 'post') {
                            // generate post text for non post content
                            entry.object.Text ? null : (entry.object.Text = null);
                            // parent content default mapping
                            entry.object.ParentContent = {
                                ...entry.object,
                                Type: entry.type,
                            };

                            // specific parent content mapping
                            switch (entry.object.ParentContent.Type) {
                                case 'job':
                                case 'event':
                                case 'market-item':
                                    // remove images from post content if content is not post or project-update
                                    entry.object.Images = null;
                                    break;
                                case 'project':
                                    entry.object.ParentContent.Images = isArray(entry.object.ParentContent.ImageURL)
                                        ? map(entry.object.ImageURL, (i) => {
                                              if (typeof i === 'string' && i.match('/img/Platform_Gradients')) {
                                                  return false;
                                              }
                                              return { ImageURL: i };
                                          })
                                        : null;
                                    entry.object.CreatedAt = entry.object.Created;
                                    break;
                                case 'project-update':
                                    entry.object.ParentContent.Fetch = {
                                        ID: entry.object.ProjectID,
                                        Type: 'project',
                                    };
                                    entry.object.CreatedAt = entry.object.Created;
                                    break;
                                default:
                                    break;
                            }
                        }
                    }, this);

                    this.feedBank.sort((a, b) => a.object.Score - b.object.Score);
                    if (this.feedBank.length > 10) {
                        this.feed.push(...this.feedBank.slice(0, 10));
                        this.feedBank = this.feedBank.slice(10);
                    } else {
                        this.feed.push(...this.feedBank);
                        this.feedBank = [];
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loadingFeed = false;
                });
        },

        getProjectsForUser(userID) {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/user/profile/projects/${userID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    this.projects = response.data.objects; // todo support pagination
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },

        getJobsForUser(userID) {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/user/profile/jobs/${userID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    this.jobs = response.data.objects; // todo support pagination
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },

        loadTags(slugs) {
            const newArray = slugs.filter((el) => el != '');
            const IDs = JSON.stringify({ IDs: newArray });
            this.$store
                .dispatch('getTagsByIDs', IDs)
                .then((response) => {
                    this.tags = response;
                    return true;
                })
                .catch((error) => {
                    console.log('error: ', error);
                    return false;
                });
            return false;
        },
        takeContact(userObj) {
            const thread = {
                Participants: [this.$store.state.me.ID, userObj.ID],
            };
            axios({
                method: 'POST',
                url: '/chat/t',
                withCredentials: true,
                data: JSON.stringify(thread),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.$router.push(`/messenger/${response.data.ID}`);
                    }
                    // this.$router.push(`/messenger`);
                })
                .catch((error) => {
                    console.log(error);
                    //  this.$router.push(`/messenger`);
                });

            // var msg = {"msg": ""};
        },

        uploadProfileImage(event) {
            // set file preview
            this.loader = true;
            const file = event.target.files[0];
            this.url = URL.createObjectURL(file);
            this.$store
                .dispatch('imageUpload', {
                    file,
                    url: '/upload/image/user-profile-image',
                })
                .then((response) => {
                    // set new image to profile
                    this.user.Profile.ImageURL = response;
                    this.saveChanges();
                })
                .catch((error) => {
                    this.$data.loader = false;
                    this.url = null;
                    const message = {
                        Message: 'Could not upload',
                        Details: error.response.data,
                    };
                    if (error.status && error.status === 413) {
                        message.Message = 'The image file is too large to upload. Must be max. 2MB.';
                    } else if (error.status && error.status === 424) {
                        message.Message = 'Could not upload. Problem with dependency service.';
                    } else if (
                        error.response &&
                        error.response.data &&
                        error.response.data.message === 'Request Entity Too Large'
                    ) {
                        message.Message = 'The file you tried to upload is too large. Max 10 MB.';
                    }
                    EventBus.$emit('ERROR', message);
                });
        },

        saveChanges() {
            this.$store
                .dispatch('updateUser', this.user)
                .then((response) => {
                    // todo delete previous file
                    this.$data.loader = false;
                    this.url = null;
                    const message = {
                        Message: 'Profile image saved',
                        Details: '',
                    };
                    EventBus.$emit('INFO', message);
                })
                .catch((error) => {
                    const message = {
                        Message: `Could update profile. Error: ${error.response.data}`,
                        Details: error.response.data,
                    };
                    EventBus.$emit('ERROR', message);
                    this.$data.loader = false;
                    this.url = null;
                });
        },

        editPost(post: Object = null) {
            if (!post) return;
            // map post structure to editor structure
            const postToEdit = {
                ...get(post, 'object', {}),
                Files: get(post, 'object.Images', []).map((image) => {
                    return { url: image.ImageURL };
                }),
                wasUpdated: true,
            };
            this.$refs.posteditor.show(postToEdit, true);
        },

        scrollRefIntoView(ref) {
            const el = get(this, `$refs[${ref}][0]`, {});
            if (el.$el) el.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        },

        scroll() {
            window.onscroll = () => {
                const feedContainer = this.$refs.container;
                if (!feedContainer) return;

                // get the distance from the bottom of the page to the bottom of the feed container
                const distanceFromBottom = feedContainer.getBoundingClientRect().bottom - window.innerHeight;

                const percentage = (distanceFromBottom * 100) / feedContainer.clientHeight;

                if (percentage < 10) {
                    if (this.feedBank.length !== 0 && this.feedBank.length > 10) {
                        this.feed.push(...this.feedBank.slice(0, 10));
                        this.feedBank = this.feedBank.slice(10);
                    } else if (this.feedBank.length !== 0 && this.feedBank.length <= 10) {
                        this.feed.push(...this.feedBank);
                        this.feedBank = [];
                    } else if (
                        this.feedBank.length === 0 &&
                        (this.feedNextPage != null || this.nextParams != null) &&
                        !this.loading
                    ) {
                        this.getFeed();
                    }
                }
            };
        },
    },
};
