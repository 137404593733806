






















import CoIcon from '../../Atoms/co-icon/CoIcon.vue';
import CoImage from '../../Atoms/co-image/CoImage.vue';

export default {
    name: 'CoThumbnail',
    components: {
        CoIcon,
        CoImage,
    },
    props: {
        ImageURL: {
            type: String,
            default: '',
        },
        Link: {
            type: String,
            default: '',
        },
        Round: {
            type: Boolean,
            default: false,
        },
        Clickable: {
            type: Boolean,
            default: true,
        },
        contentType: {
            type: String,
            default: 'image',
            validator: (value: string) => ['image', 'user', 'page', 'event', 'color'].includes(value),
        },
        color: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: 'md',
            validator: (value: string) => ['xs', 'sm', 'ms', 'md', 'lg', 'xl'].includes(value),
        },
        deletable: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            return {
                clickable: this.Clickable,
                deletable: this.deletable,
                round: this.Round,
                [`size-${this.size}`]: true,
            };
        },
        placeholderSize() {
            switch (this.size) {
                case 'sm':
                    return '.6rem';
                case 'ms':
                    return '.67rem';
                default:
                    return '1rem';
            }
        },
    },
    methods: {
        click(event) {
            if (this.Link) {
                // stop the click event from propagating to the parent element
                event.stopPropagation();
                this.$router.push(this.Link);
                return;
            }

            if (this.Clickable) {
                this.$emit('click', event);
            }
        },
    },
};
