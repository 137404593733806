






























































































































import { sortBy } from 'lodash';
import axios from 'axios';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoModal from '@/components/Organisms/co-modal/CoModal.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import CoText from '@/components/Atoms/co-text/CoText.vue';
import { Chained } from 'webpack-chain';

export default {
    components: { CoText, CoHeadline, CoModal, CoButton },
    data() {
        return {
            channels: [],
            isAdmin: false,
            channelName: '',

            isMarketOn: this.$store.state.space.MarkeplaceOn,
        };
    },
    props: {
        menuOpen: Boolean,
        PrivacyPolicy: Object,
    },
    computed: {
        Chained() {
            return Chained;
        },
        mainMenuItems() {
            return this.$parent.mainMenuItems;
        },
    },
    watch: {
        menuOpen(newValue) {
            const body = document.querySelector('body');

            if (newValue) {
                body.style.overflow = 'hidden';
            } else {
                body.style.overflow = 'auto';
            }
        },
    },
    mounted() {
        this.listChannels();
        this.isAdmin = this.$store.state.me.Permissions && this.$store.state.me.Permissions.includes('space_admin');
    },
    methods: {
        listChannels() {
            axios
                .get('/project/channel/list')
                .then((response) => {
                    if (response.data) {
                        const channels = response.data.objects;
                        // map channels to menu items and add to menu items
                        this.channels = channels.map((channel) => ({
                            name: channel.object.Value,
                            routeName: 'Channels',
                            params: {
                                slug: channel.object.Slug,
                            },
                        }));
                        // sort channels alphabetically by name using lodash
                        this.channels = sortBy(this.channels, [(channel) => channel.name.toLowerCase()], ['desc']);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        createChannel() {
            const data = JSON.stringify({ Value: this.channelName });

            axios({
                method: 'POST',
                url: '/admin/community/project-type',
                data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(() => {
                    this.channelName = '';
                    this.listChannels();

                    this.$nextTick(() => {
                        this.$refs['modal-create-project-type'].hide();
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
