





















import axios from 'axios';
import EventBus from '../../../../eventBus';

export default {
    name: 'FeaturesMarket',

    data() {
        return {
            space: {},
            loading: false,
        };
    },
    created() {
        this.getSpaceSettings();
    },
    methods: {
        handleSubmit() {
            this.loading = true;

            const data = JSON.stringify(this.space);
            axios({
                method: 'PUT',
                url: '/admin/space/settings',
                withCredentials: true,
                data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data) {
                        this.space = response.data;
                        const msg = {
                            Message: 'Changes saved',
                            Details: '',
                        };
                        EventBus.$emit('INFO', msg);
                        // this.$router.go()
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to save space settings',
                        Details: '',
                    };
                    EventBus.$emit('ERROR', msg);
                });
        },
        getSpaceSettings() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/admin/space/settings',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data) {
                        this.space = response.data;
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to get space settings',
                        Details: '',
                    };
                    EventBus.$emit('ERROR', msg);
                });
        },
    },
};
