<template>
    <div class="video" v-if="media">
        <iframe
            type="text/html"
            width="640"
            height="360"
            frameborder="0"
            :data-id="media.id"
            :data-type="media.type"
            :src="media.embedurl"
        ></iframe>
    </div>
</template>
<style lang="less" scoped>
.video {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}
</style>
<script>
export default {
    name: 'CoMedia',
    props: {
        source: {
            type: String,
            default: '',
        },
    },
    data() {
        return {};
    },
    computed: {
        media() {
            if (!this.source) return null;
            var mediaObj = {
                id: null,
                type: 'link',
                embedurl: null,
            };
            switch (true) {
                case /youtube-nocookie\.com\/|youtube\.com\/|youtu\.be\//gi.test(this.source):
                    mediaObj.type = 'youtube';
                    mediaObj.id = this.source.match(/(?<=youtu.be\/|\?v=|embed\/)([^\s&?]+)/gi)[0];
                    mediaObj.embedurl = `https://www.youtube-nocookie.com/embed/${mediaObj.id}`;
                    return mediaObj;
                default:
                    return null;
            }
        },
    },
};
</script>
