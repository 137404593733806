<template>
    <div class="min-vh-100 d-flex bg justify-content-center align-items-md-center">
        <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
            <div class="login-form mt-4 mt-md-0 p-4 bg-white round-unify text-center">
                <h1>Collaborates.io</h1>
                <h3>Cobot Callback</h3>
                <br />
                <h1 style="font-family: HafvenAdapterThin; display: inline-block"></h1>
                <div class="card-body w-100 py-4">
                    <div class="alert alert-danger bg-white text-orange" role="alert" v-if="error">
                        <p class="pb-0">{{ error.message }}</p>
                        <small>Details {{ error.details }}</small>
                    </div>

                    <b-button variant="primary" v-if="url" :href="url">
                        <b-icon icon="plus"></b-icon>Back to Admin page
                    </b-button>
                </div>
            </div>
            <template #overlay>
                <div class="text-center">
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                    <p id="cancel-label">Retrieving access token...</p>
                </div>
            </template>
        </b-overlay>
    </div>
    <!-- code=3dc6fbfb425fcf3a80db771cb8a2cfd9&state=localhost%3A8080 -->
</template>
<script>
import axios from 'axios';

export default {
    data() {
        return {
            loading: true,
            error: null,

            url: null,
            code: this.$route.query.code,
            state: this.$route.query.state,
        };
    },
    mounted() {
        this.getUrl();
    },

    methods: {
        getUrl() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/webhook/cobot/callback/${this.code}/${this.state}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data) {
                        this.url = response.data.URL;
                        window.location.href = `${this.url}/admin/settings/integrations/cobot`;
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    this.error = error;
                    if (error.response && error.response.data) {
                        this.error = error.response.data;
                    }
                });
        },
    },
};
</script>
