































import { get } from 'lodash';
import UserMainGrid from '../../components/Templates/User/MainGrid/UserMainGrid.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';

export default {
    name: 'LegalBase',
    components: {
        UserMainGrid,
        CoHeadline,
    },
    data() {
        return {
            spaceName: get(this, '$store.state.space.Name', null),
            logoURL: get(this, '$store.state.space.LogoURL', null),
        };
    },
};
