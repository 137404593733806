
























import CoIcon from '@/components/Atoms/co-icon/CoIcon.vue';
import { truncate } from 'lodash';

export default {
    name: 'CoButton',
    components: {
        CoIcon,
    },
    props: {
        type: {
            type: String,
            default: 'button',
            required: false,
        },
        label: {
            type: String,
            required: false,
            default: '',
        },
        iconleft: {
            type: String,
            required: false,
            default: '',
        },
        iconright: {
            type: String,
            required: false,
            default: '',
        },
        variant: {
            type: String,
            default: 'default',
            validator(value) {
                return (
                    [
                        'default',
                        'subtle',
                        'primary',
                        'secondary',
                        'danger',
                        'success',
                        'warning',
                        'text',
                        'black',
                        'blacksecondary',
                        'info',
                    ].indexOf(value) !== -1
                );
            },
        },
        active: {
            type: Boolean,
            default: true,
        },
        size: {
            type: String,
            default: 'medium',
            validator(value) {
                return ['small', 'medium', 'large'].indexOf(value) !== -1;
            },
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
        externalLink: {
            type: String,
            default: null,
        },
        stopEventPropagation: {
            type: Boolean,
            default: true,
        },
        truncate: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            foregroundColor: '',
            colorOverridden: false,
        };
    },
    computed: {
        classes() {
            return {
                [`${this.variant}`]: true,
                inactive: !this.active,
                active: this.active,
                [`${this.size}`]: true,
                iconleft: this.iconleft,
                iconright: this.iconright,
                disabled: this.disabled,
                loading: this.loading,
                truncate: this.truncate,
            };
        },
    },
    mounted() {
        this.assignContrastColors();
    },
    updated() {
        this.$nextTick(function () {
            // Code that will run only after the
            // entire view has been re-rendered
            if (!this.disabled && !this.colorOverridden) {
                // //  exclude disabled state
                this.assignContrastColors();
            }
        });
    },
    watch: {
        variant() {
            this.foregroundColor = null; // reset color override
        },
        disabled() {
            this.foregroundColor = null; // reset color override
        },
        loading() {
            this.foregroundColor = null; // reset color override
        },
    },
    methods: {
        onClick(event) {
            if (this.disabled) {
                return;
            }
            if (this.externalLink) {
                window.open(this.externalLink, '_blank').focus();
            }
            if (this.stopEventPropagation) {
                event.stopPropagation();
                this.$emit('onClick');
                this.$emit('click');
            } else {
                this.$emit('click', event);
                this.$emit('onClick', event);
            }
        },
        RGBAtoRGB(RGBA, bg) {
            try {
                const alpha = 1 - RGBA.alpha;
                const red = Math.round((RGBA.alpha * (RGBA.red / 255) + alpha * (bg.red / 255)) * 255);
                const green = Math.round((RGBA.alpha * (RGBA.green / 255) + alpha * (bg.green / 255)) * 255);
                const blue = Math.round((RGBA.alpha * (RGBA.blue / 255) + alpha * (bg.blue / 255)) * 255);

                return `rgb(${red},${green},${blue})`;
            } catch (err) {
                console ? console.warn(err.Message) : null;
            }
        },
        luminance(rgbArray) {
            if (!rgbArray || rgbArray.length != 3) {
                return null;
            }
            const [lumR, lumG, lumB] = rgbArray.map((component) => {
                const proportion = component / 255;

                return proportion <= 0.03928 ? proportion / 12.92 : Math.pow((proportion + 0.055) / 1.055, 2.4);
            });

            return 0.2126 * lumR + 0.7152 * lumG + 0.0722 * lumB;
        },
        sanitizedColorObject(colorString) {
            if (!colorString || typeof colorString !== 'string') {
                try {
                    console.warn(`colorString is expected to be of type string but is ${typeof colorString}`);
                } catch (err) {}
            }

            const colorValues = colorString.match(/\d{1,3}/g);

            if (!colorValues || colorValues.length < 3) {
                try {
                    console.warn(`colorString is expected to be rgb or rgba format but is ${colorString}`);
                } catch (err) {}
                return false;
            }

            let red = colorValues[0] ? parseInt(colorValues[0]) : null;
            let green = colorValues[1] ? parseInt(colorValues[1]) : null;
            let blue = colorValues[2] ? parseInt(colorValues[2]) : null;
            let alpha = colorValues[3] ? parseFloat(colorValues[3]) : null; //Important parseFloat for alpha!
            let luminance = null;
            let rgb = null;
            let rgba = alpha || alpha === 0 ? `rgba(${red},${green},${blue},${alpha})` : null;
            let rgbReducedOnBlack = null;
            let rgbReducedOnWhite = null;
            const originalString = colorString;

            //
            if (alpha || alpha === 0) {
                rgbReducedOnBlack = this.RGBAtoRGB({ red, green, blue, alpha }, { red: 0, green: 0, blue: 0 });
                rgbReducedOnBlack = this.sanitizedColorObject(rgbReducedOnBlack);
                rgbReducedOnWhite = this.RGBAtoRGB({ red, green, blue, alpha }, { red: 255, green: 255, blue: 255 });
                rgbReducedOnWhite = this.sanitizedColorObject(rgbReducedOnWhite);
            } else {
                // get the rgb-color's luminance
                luminance = this.luminance([red, green, blue]);
                alpha = null;
                rgb = `rgb(${red},${green},${blue})`;
            }

            return {
                red,
                green,
                blue,
                alpha,
                luminance,
                rgb,
                rgba,
                rgbReducedOnBlack,
                rgbReducedOnWhite,
                originalString,
            };
        },
        assignContrastColors() {
            // check button color/background color contrast and override if contrast is below thresholt
            const buttonEl = this.$refs.button as HTMLBodyElement;
            const contrastThresholt = 0.65;
            // get computed background color (will be either rgb, rgba, hsl, hsla, hwb)
            // https://stackoverflow.com/questions/67005331/is-color-format-specified-in-the-spec-for-getcomputedstyle
            const { backgroundColor, color } = window.getComputedStyle(buttonEl);
            // get sanitized and processed collorObj (only supports rgb and rgba strings)
            const backgroundObj = this.sanitizedColorObject(backgroundColor);
            const colorObj = this.sanitizedColorObject(color);
            // compare luminance
            const luminanceDiff =
                (colorObj.rgba ? colorObj.rgbReducedOnWhite.luminance : colorObj.luminance) -
                (backgroundObj.rgba ? backgroundObj.rgbReducedOnWhite.luminance : backgroundObj.luminance);

            if (luminanceDiff && Math.abs(luminanceDiff) < contrastThresholt) {
                console.warn(
                    `button color-contrast below ${contrastThresholt} overriding button text color`,
                    luminanceDiff,
                    buttonEl,
                    colorObj,
                    backgroundObj
                );
                // DISABLED color overrides as it currently leads to unexpected results
                return;
                // override foreground color if luminance difference is below thresholt
                if (luminanceDiff > 0) {
                    // to black if foregroundColor is lighter than bg
                    this.foregroundColor = '#000';
                } else {
                    // to white if foregroundColor bg is lighter than foreground
                    this.foregroundColor = '#FFF';
                }
                this.colorOverridden = true;
            }
        },
    },
};
