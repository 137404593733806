<style lang="less" scoped>
.suggested-card {
    margin-right: 2%;
    min-width: 28%;
    display: block;
    color: black;
}
</style>

<template>
    <router-link :to="'/' + object.object.slug" class="round-unify bg-white suggested-card">
        <div>
            <LazyLoadImg :src="object.object.Profile.ImageURL" :relativeHeight="100" dark-variant alt="" style="">
            </LazyLoadImg>
        </div>
        <div class="p-3 d-flex align-items-center">
            <div class="menu-icon small-icon border-dark text-dark">
                <b-icon scale=".8" icon="person-plus"></b-icon>
            </div>
            <div class="ml-2">
                {{ trimString(object.object.Profile.Name) }}
            </div>
        </div>
    </router-link>
</template>

<script>
import axios from 'axios';
import EventBus from '@/eventBus';

export default {
    name: 'SuggestedCommunity',
    props: ['object'],
    data() {
        return {};
    },

    watch: {},

    computed: {},

    mounted() {},
    methods: {
        trimString(string) {
            if (string == null) {
                return '';
            }
            if (string.length > 27) {
                string = `${string.substring(0, 27 - 1)}...`;
            }
            return string;
        },
    },
};
</script>
