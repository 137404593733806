<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>
<style lang="less" scoped>
a {
    color: inherit;
    text-decoration: inherit;
}
</style>

<template>
    <div class="bg-white round-unify w-100 d-flex align-items-start overflow-hidden p-3">
        <div class="w-100 d-flex justify-content-between">
            <div class="">
                <div class="mb-2">
                    <small>Name</small> <br />
                    <h2 class="title">
                        {{ object.Name }}
                    </h2>
                </div>

                <div class="mb-2">
                    <small>Description</small> <br />
                    <p>
                        {{ object.Description }}
                    </p>
                </div>

                <div class="d-flex">
                    <div class="mr-3">
                        <small>Source</small> <br />
                        <h3>
                            {{ sources[object.Source] }}
                        </h3>
                    </div>
                    <div class="mr-3">
                        <small>System ID</small> <br />
                        <h3 class="title">
                            {{ object.SystemPolicyID }}
                        </h3>
                    </div>
                </div>

                <div class="mt-2">
                    <small>
                        Created at {{ object.CreatedAt | timestampToString }} <br />
                        Updated at {{ object.UpdatedAt | timestampToString }}
                    </small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccessPolicyCard',
    props: ['object'],
    data() {
        return {
            sources: {
                0: 'Deister',
            },
        };
    },
    beforeCreate() {},
    created() {},

    mounted() {},
    methods: {},
};
</script>
