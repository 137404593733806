

















































































import './CoPlanCancelAdmin.less';

import axios from 'axios';
import { format, setHours, setMinutes, setSeconds } from 'date-fns';
import { PropType } from 'vue';
import CoAlert from '@/components/Molecules/co-alert/CoAlert.vue';
import CoCard from '../../Molecules/co-card/CoCard.vue';
import CoHeading from '../../Atoms/co-heading/CoHeading.vue';
import CoCheckbox from '../../Atoms/co-checkbox/CoCheckbox.vue';
import CoButton from '../../Atoms/co-button/CoButton.vue';
import CoDatePicker from '../../Atoms/co-date-picker/CoDatePicker.vue';
import EventBus from '../../../eventBus';

interface MembershipCancelRequest {
    CancelToTheEndOfBillingCycleWithCancellationPeriod: boolean;
    CancelToTheEndOfBillingCycle: boolean;
    CancelOnCustomDate: boolean;
    CancelNow: boolean;
    CustomDate: number;
    Prorate: boolean;
}

export default {
    name: 'CoPlanCancelAdmin',
    components: {
        CoAlert,
        CoCard,
        CoHeading,
        CoCheckbox,
        CoButton,
        CoDatePicker,
    },
    props: {
        userID: {
            type: String,
            required: true,
        },
        userName: {
            type: String,
            required: true,
        },
        show: {
            type: Boolean,
            required: false,
            default: false,
        },
        classes: {
            type: String,
            required: false,
            default: '',
        },
        planStartAt: {
            type: Number,
            required: false,
            default: 0,
        },
        currentBillingPeriodEndAt: {
            type: Number,
            required: false,
            default: 0,
        },

        test: {
            type: Array as PropType<string[]>,
        },
    },
    data() {
        return {
            cardStyle: '',
            checkboxColor: '#d0000',
            customDate: format(new Date(), 'yyyy-MM-dd'),
            loading: false,
            minDate: format(new Date(), 'yyyy-MM-dd'),
            possibleCancelAt: 0,
            request: {
                CancelToTheEndOfBillingCycleWithCancellationPeriod: false,
                CancelToTheEndOfBillingCycle: true,
                CancelOnCustomDate: false,
                CancelNow: false,
                CustomDate: 0,
                Prorate: false,
            } as MembershipCancelRequest,
            requestCustomDate: 0,
            visible: false,
            cancelDateLoading: true,
        };
    },

    watch: {
        customDate(val) {
            this.requestCustomDate = new Date(val).getTime() / 1000;
        },
        'request.CancelNow': function (val) {
            if (val) {
                this.requestCustomDate = new Date().getTime() / 1000;
            }
        },
        'request.CancelOnCustomDate': function (val) {
            if (val) {
                this.requestCustomDate = new Date(this.customDate).getTime() / 1000;
            }
        },
        'request.CancelToTheEndOfBillingCycle': function (val) {
            if (val) {
                this.requestCustomDate = this.currentBillingPeriodEndAt;
            }
        },
        'request.CancelToTheEndOfBillingCycleWithCancellationPeriod': function (val) {
            if (val) {
                this.requestCustomDate = this.possibleCancelAt;
            }
        },
        show(val) {
            this.visible = val;
            if (val) {
                this.cardStyle = 'd-block';
            } else {
                this.cardStyle = 'd-none';
            }
        },
    },

    mounted() {
        this.requestCustomDate = this.currentBillingPeriodEndAt;
        this.getCancelPossibleDate();
    },

    methods: {
        cancelMembership() {
            this.loading = true;

            if (this.request.CancelOnCustomDate || this.request.CancelToTheEndOfBillingCycleWithCancellationPeriod) {
                let date = new Date(this.requestCustomDate * 1000);

                const anchordate = new Date(this.planStartAt);

                date = setHours(date, anchordate.getHours());
                date = setMinutes(date, anchordate.getMinutes());
                date = setSeconds(date, anchordate.getSeconds());

                this.request.CustomDate = date.getTime() / 1000;
            }

            const data = JSON.stringify(this.request);
            axios({
                method: 'DELETE',
                url: `/admin/community/member/${this.userID}/plan`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
                data,
            })
                .then((response) => {
                    const message = {
                        Message: 'Membership cancelled',
                    };
                    EventBus.$emit('INFO', message);
                    this.$emit('close');
                })
                .catch((error) => {
                    const message = {
                        Message: 'Error cancelling membership',
                        Details: error.response.data,
                    };
                    EventBus.$emit('ERROR', message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        discard() {
            this.$emit('close');
        },
        format,
        getCancelPossibleDate() {
            this.cancelDateLoading = true;
            axios
                .get(`/admin/community/member/${this.userID}/plan/cancel-date`)
                .then((response) => {
                    if (response && response.data && response.data.CancelPossibleAt) {
                        this.possibleCancelAt = response.data.CancelPossibleAt;
                    }
                    this.cancelDateLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        setType(type: string, event: boolean) {
            this.request[type] = event;
            if (type === 'CancelOnCustomDate' && event) {
                this.request.CancelToTheEndOfBillingCycleWithCancellationPeriod = false;
                this.request.CancelToTheEndOfBillingCycle = false;
                this.request.CancelNow = false;
            } else if (type === 'CancelToTheEndOfBillingCycle' && event) {
                this.request.CancelToTheEndOfBillingCycleWithCancellationPeriod = false;
                this.request.CancelOnCustomDate = false;
                this.request.CancelNow = false;
            } else if (type === 'CancelNow' && event) {
                this.request.CancelToTheEndOfBillingCycleWithCancellationPeriod = false;
                this.request.CancelOnCustomDate = false;
                this.request.CancelToTheEndOfBillingCycle = false;
            } else if (type === 'CancelToTheEndOfBillingCycleWithCancellationPeriod' && event) {
                this.request.CancelToTheEndOfBillingCycle = false;
                this.request.CancelOnCustomDate = false;
                this.request.CancelNow = false;
            }
        },
    },
};
