<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>
<template>
    <div>
        <!-- search and actions start -->
        <div class="px-sm-4 d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center bg-white search-bar-wrapper rounded-pill">
                <b-icon class="mr-2" icon="search"></b-icon>
                <div class="search-input-wrapper">
                    <input
                        class="search-input"
                        type="search"
                        v-model="search"
                        placeholder="Search"
                        aria-label="Search"
                    />
                </div>
            </div>

            <b-button id="import_btn" variant="primary" :disabled="!1" @click="importTags">Import</b-button>
            <b-tooltip
                label="Import tags (tokens/creddentials) from Deister access
      system"
                target="import_btn"
                position="is-bottom"
                >Imports tags (tokens/credentials) from Deister access system</b-tooltip
            >
        </div>
        <!-- search and actions end -->

        <!-- tags start -->
        <div class="container-fluid p-0 m-0">
            <!-- if search result is empty -->
            <div class="row text-center m-0 mt-5" v-if="search.length >= 2 && !loading && objects.length === 0">
                <div class="col">
                    <div class="mb-4">Coundn't find any access tags for given search input.</div>
                </div>
            </div>
            <!-- if emtpy and not loading -->
            <div class="row text-center m-0 mt-5" v-else-if="objects.length === 0 && !loading">
                <div class="col">
                    <!-- <illustration type="projects" class="my-0 illu"></illustration> -->

                    <h2>Get started!</h2>
                    <div class="mb-4">Import tags</div>
                </div>
            </div>
            <!-- if loading -->
            <div class="row text-center m-0 mt-5" v-else-if="loading">
                <div class="col">
                    <b-spinner variant="primary" label="loading"></b-spinner>
                </div>
            </div>
            <div class="m-0 mt-4 px-sm-4">
                <div class="mb-3" v-for="(item, i) in objects" :key="item.ID">
                    <AccessTagCard :object="item" to="/"></AccessTagCard>
                </div>
            </div>
        </div>
        <!-- tags end -->
    </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import EventBus from '@/eventBus';

export default {
    name: 'AccessTags',
    components: {},
    data() {
        return {
            tabIndex: 0,
            loading: false,
            objects: [
                // {
                //   ID: 'test',
                //   Name: 'test',
                //   Value: 'D47',
                //   DecimalValue: parseInt('D47', 16),
                //   Type: 0,
                //   CreatedAt: 1649934457,
                //   UpdatedAt: 1649934457,
                //   ValidFrom: 1649904457,
                //   ValidTo: 1641034457,
                // },
            ],

            listingResult: [],
            PagingKey: null,
            search: '',
            searchResult: [],
            cancelTokenSource: null,
        };
    },
    mounted() {
        this.listTags();
    },
    watch: {
        search(val) {
            if (val && val.length >= 2) {
                this.searchTag(val);
            } else {
                this.objects = this.listingResult;
            }
        },
    },

    methods: {
        searchTag(input) {
            if (!input) {
                this.searchResult = [];
                this.objects = this.listingResult;
                return;
            }

            this.loading = true;
            this.objects = [];

            if (this.cancelTokenSource) {
                this.cancelTokenSource.cancel('Previous was request aborted due to a new request.');
            }
            this.cancelTokenSource = axios.CancelToken.source();
            axios({
                cancelToken: this.cancelTokenSource.token,
                method: 'GET',
                url: `/admin/space/access/tag/search/${input}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data && response.data.Tags) {
                        const objects = response.data.Tags;
                        this.searchResult = objects.map((item) => {
                            item.DecimalValue = String(parseInt(item.Value, 16));
                            return item;
                        });

                        this.objects = this.searchResult;
                    } else {
                        this.objects = [];
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (!axios.isCancel(error)) {
                        EventBus.$emit('ERROR', {
                            Message: 'Can`t get tags for search input',
                        });
                        this.objects = [];
                        this.loading = false;
                    }
                });
        },
        listTags() {
            this.loading = true;
            axios
                .get('/admin/space/access/tag/list')
                .then((response) => {
                    if (response && response.data) {
                        const objects = response.data.Tags;
                        this.listingResult = objects.map((item) => {
                            item.DecimalValue = String(parseInt(item.Value, 16));
                            return item;
                        });

                        if (response.data.PagingKey) {
                            this.PagingKey = response.data.PagingKey;
                        } else {
                            this.PagingKey = null;
                        }

                        this.objects = this.listingResult;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        importTags() {
            this.loading = true;
            axios
                .get('/admin/space/access/tag/import')
                .then((response) => {
                    if (response && response.data) {
                        const objects = response.data.Tags;
                        this.objects = objects.map((item) => {
                            item.DecimalValue = String(parseInt(item.Value, 16));
                            return item;
                        });
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        back() {
            if (this.tabIndex > 0) {
                this.tabIndex -= 1;
            }
        },
        linkClass(idx) {
            if (this.tabIndex === idx) {
                return ['activeOne'];
            }
            return ['not-activeOne'];
        },
    },
};
</script>
