<template>
    <div class="bg-white round-unify mh-100 h-100 d-flex flex-column align-items-end">
        <!-- header -->
        <div class="p-3 border-bottom align-self-start w-100">{{ $t('labels.membershipyour') }}</div>
        <!-- body -->
        <div class="align-self-start w-100 overflow-auto mh-100">
            <div v-if="plan" class="border-bottom p-3">
                <div class="d-flex justify-content-between">
                    <p class="text-muted mb-0">{{ $t('labels.plan') }}</p>
                </div>
                <div class="d-flex justify-content-between">
                    <p class="mb-0">{{ plan.Name }}</p>
                    <p class="mb-0 flex-shrink-0">
                        {{ calculatePrice(plan).toFixed(2) }} {{ plan.Currency }} /
                        <span v-if="plan.TimeInteval === 1">{{ $t('labels.year') }}</span>
                        <span v-else>{{ $t('labels.month') }}</span>
                    </p>
                </div>
                <div class="w-100 d-flex">
                    <small v-if="plan.TaxRate" class="text-right w-100"
                        >{{ $t('labels.including') }} {{ plan.TaxRate.Percentage ? plan.TaxRate.Percentage : 0 }}%
                        {{ plan.TaxRate.DisplayName }}
                    </small>
                </div>
            </div>
            <div v-for="(item, index) in this.$store.state.newMembership.Extras" :key="index" class="border-bottom p-3">
                <div class="d-flex justify-content-between">
                    <p class="text-muted mb-0">{{ $t('labels.upgrades') }}</p>
                </div>
                <div class="d-flex justify-content-between">
                    <p class="mb-0">{{ item.Name }}</p>
                    <p class="mb-0 flex-shrink-0">
                        {{ calculatePrice(item).toFixed(2) }} {{ plan.Currency }} /
                        <span v-if="plan.TimeInteval === 1">{{ $t('labels.year') }}</span>
                        <span v-else>{{ $t('labels.month') }}</span>
                    </p>
                </div>
                <div class="w-100 d-flex">
                    <small v-if="item.TaxRate" class="text-right w-100"
                        >{{ $t('labels.including') }} {{ plan.TaxRate.Percentage ? plan.TaxRate.Percentage : 0 }}%
                        {{ item.TaxRate.DisplayName }}
                    </small>
                </div>
            </div>

            <div class="border-bottom p-3" v-if="coupon">
                <div class="d-flex justify-content-between">
                    <p class="text-muted mb-0">Coupon</p>
                </div>
                <div class="d-flex justify-content-between">
                    <p class="mb-0">{{ coupon.Name }}</p>
                    <p class="mb-0" v-if="coupon.DurationInMonths && coupon.DurationInMonths > 0">
                        Duration in months: {{ coupon.DurationInMonths }}
                    </p>
                </div>
                <div class="w-100 d-flex">
                    <small v-if="coupon.PercentageOff" class="text-right w-100">{{ coupon.PercentageOff }}% off </small>
                    <small v-if="coupon.AmountOff" class="text-right w-100">
                        {{ (coupon.AmountOff / 100).toFixed(2) }} {{ coupon.Currency }} off
                    </small>
                </div>
            </div>

            <!-- coupon -->
            <div class="p-3 w-100">
                <b-form-group
                    id="fieldset-1"
                    label-for="input-1"
                    label-size="sm"
                    label-class="mb-0"
                    class="text-left mb-0"
                >
                    <b-form-input
                        type="text"
                        class="form-control rounded-pill"
                        v-model="couponID"
                        @update="updateCoupon"
                        :placeholder="$t('placeholders.couponcode')"
                    >
                    </b-form-input>
                    <b-button v-if="coupon" variant="outline-danger" class="mt-2" @click="removeCoupon"
                        >{{ $t('labels.remove') }}
                    </b-button>
                    <b-button v-else variant="outline-primary" class="mt-2" @click="applyCoupon"
                        >{{ $t('labels.redeem') }}
                    </b-button>
                </b-form-group>
            </div>
        </div>

        <!-- footer -->
        <div class="p-3 border-top d-flex justify-content-between mt-auto w-100">
            <div class="w-100 pb-2">
                <div class="d-flex justify-content-between w-100">
                    <p class="mb-0">{{ $t('labels.total') }}</p>
                    <p class="mb-0">
                        {{ totalPrice() }} EUR /
                        <span v-if="plan.TimeInteval === 1">{{ $t('labels.year') }}</span>
                        <span v-else>{{ $t('labels.month') }}</span>
                    </p>
                </div>
                <div class="d-flex justify-content-end w-100">
                    <p class="text-muted mb-0"></p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import EventBus from '@/eventBus';

export default {
    name: 'Membership',

    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    data() {
        return {
            taxRates: [],
            couponID: '',
            coupon: null,
        };
    },
    mounted() {
        if (this.$store.state.newMembership.Coupon) {
            this.couponID = this.$store.state.newMembership.Coupon;
            this.applyCoupon();
        }
    },
    computed: {
        plan() {
            return this.$store.state.newMembership.Plan;
        },
        extras() {
            return this.$store.state.newMembership.Extras;
        },
    },
    methods: {
        applyCoupon() {
            axios
                .get(`/space/coupon/${this.couponID}`)
                .then((res) => {
                    this.coupon = res.data;

                    this.$store.commit('SET_COUPON', {
                        Coupon: this.coupon.ID,
                    });
                    this.totalPrice();
                })
                .catch((err) => {
                    this.coupon = null;
                    EventBus.$emit('ERROR', {
                        Message: 'Coupon code is invalid',
                    });
                    console.log(err);
                });
        },
        updateCoupon() {
            if (!this.couponID) {
                this.coupon = null;
                this.$store.commit('SET_COUPON', {
                    Coupon: '',
                });
                this.totalPrice();
            }
        },

        removeCoupon() {
            this.coupon = null;
            this.couponID = '';
            this.$store.commit('SET_COUPON', {
                Coupon: '',
            });
            this.totalPrice();
        },
        totalPrice() {
            let total = this.calculatePrice(this.$store.state.newMembership.Plan);
            if (this.$store.state.newMembership.Extras) {
                this.$store.state.newMembership.Extras.forEach((element) => {
                    total += this.calculatePrice(element);
                });
            }

            if (this.coupon) {
                if (this.coupon.AmountOff > 0) {
                    total -= this.coupon.AmountOff / 100;
                } else if (this.coupon.PercentageOff != 0.0) {
                    total *= 1 - this.coupon.PercentageOff / 100;
                }

                if (total < 0) {
                    total = 0;
                }
            }
            return total.toFixed(2);
        },

        calculatePrice(item) {
            if (!item || !item.PriceInCents) return 0;

            let price = item.PriceInCents / 100.0;

            if (!item.TaxRate || (item.TaxRate && item.TaxRate.Inclusive)) return price;

            if (item.TaxRate && item.TaxRate.Percentage) {
                price = price * (item.TaxRate.Percentage / 100 + 1);
            }
            return price;
        },
    },
};
</script>
