



























import { size } from 'lodash';
import Vue from 'vue';

import SvgIcon from 'vue-svgicon';

Vue.use(SvgIcon, {
    tagName: 'svgicon',
});

export default {
    name: 'CoIcon',
    props: {
        name: {
            type: String,
            default: '',
        },
        top: {
            type: Boolean,
            default: false,
        },
        noPadding: {
            type: Boolean,
            default: false,
        },
        fill: {
            type: String,
            default: '',
            validator(value) {
                return ['co-darkgrey', 'co-midgrey', 'black', ''].includes(value);
            },
        },
        background: {
            type: String,
            default: '',
            validator(value) {
                return ['grey', ''].includes(value);
            },
        },
        size: {
            type: String,
            default: '16px',
            required: false,
        },
    },
    data() {
        return {
            icon: '',
            rerender: 0,
            png: false,
            image: '',
        };
    },
    watch: {
        name() {
            this.loadIcon();
        },
    },
    created() {
        this.loadIcon();
    },
    mounted() {
        this.loadIcon();
    },
    methods: {
        loadIcon() {
            if (!this.name) {
                return;
            }

            if (this.name.startsWith('custom-png-')) {
                this.png = true;
                this.image = require(`../../custom-icons/png/${this.name.split('custom-png-')[1]}.png`);
                this.rerender += 1;
                return;
            }

            // if the icon has a 'custom-' name prefix, import it from 'custom-icons' folder instead of 'bootstrap-icons'
            if (this.name && this.name.startsWith('custom-')) {
                this.icon = this.name.split('custom-')[1];
                import(`../../custom-icons/${this.icon}.js`);
                this.rerender += 1;
                return;
            }

            this.icon = this.name;
            import(`../../bootstrap-icons/${this.icon}.js`);
            this.rerender += 1;
        },
    },
};
