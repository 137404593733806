<style lang="less" scoped src="@/assets/less/manage-space/base.less"></style>

<template>
    <div>
        <!-- search and actions start -->
        <div class="pl-sm-4 mt-4">
            <div class="bg-white round-unify p-3">
                <div>
                    <div class="d-flex w-100 justify-content-between">
                        <h2>Connect your NUKI Web Account</h2>
                        <div>
                            <b-button v-if="loading" variant="primary">
                                <b-spinner small type="grow"></b-spinner>
                                <span>Loading...</span>
                            </b-button>
                            <b-button
                                v-else
                                variant="primary"
                                @click="getNukiOAuth2Url"
                                :disabled="isConnected"
                                :variant="isConnected ? 'outline-success' : 'primary'"
                            >
                                <b-icon v-if="isConnected" icon="check-circle"></b-icon>
                                {{ isConnected ? 'Connected' : 'Connect' }}
                            </b-button>
                        </div>
                    </div>
                    <p class="mt-4">Import your smart lockers 3.0 to use in your resources.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { get } from 'lodash';
import axios from 'axios';
import EventBus from '@/eventBus';

export default {
    name: 'NukiIntegration',
    data() {
        return {
            loading: false,
            isConnected: false,
        };
    },
    methods: {
        getNukiOAuth2Url() {
            // immediately open a new window to avoid popup blockers
            const authWindow = window.open('', '_blank');
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/oauth2/nuki`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        // update the url of the popup window
                        authWindow.location.href = response.data.url;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    let message = 'Error while getting nuki auth url.';
                    if (authWindow === null) {
                        message = 'Please allow popups for this website.';
                    } else {
                        authWindow.close();
                    }
                    EventBus.$emit('ERROR', {
                        message,
                        Details: error,
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        isNukiConnected() {
            this.loading = true;
            axios({
                method: 'GET',
                url: `/admin/oauth2/nuki/verify`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.isConnected = get(response, 'data.connected', false);
                })
                .catch((error) => {
                    console.log(error);
                    EventBus.$emit('ERROR', {
                        Message: 'Cannot verify if the NUKI account is already connected',
                        Details: error,
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.isNukiConnected();
    },
};
</script>
