




















































































































































































































import axios from 'axios';

import CoCard from '@/components/Molecules/co-card/CoCard.vue';
import CoHeading from '@/components/Atoms/co-heading/CoHeading.vue';
import CoCardPlan from '@/components/Molecules/co-card-plan/CoCardPlan.vue';
import CoAlert from '@/components/Molecules/co-alert/CoAlert.vue';
import CoTable from '@/components/Molecules/co-table/CoTable.vue';
import CoButton from '@/components/Atoms/co-button/CoButton.vue';
import CoLink from '@/components/Atoms/co-link/CoLink.vue';
import CoPlanDetail from '@/components/Organisms/co-plan-detail/CoPlanDetail.vue';
import CoThumbnail from '@/components/Molecules/co-thumbnail/CoThumbnail.vue';
import CoPlanCancelAdmin from '@/components/Organisms/co-plan-cancel-admin/CoPlanCancelAdmin.vue';
import CoModal from '@/components/Organisms/co-modal/CoModal.vue';
import CoRoundButton from '@/components/Atoms/co-round-button/CoRoundButton.vue';
import CoHeadline from '@/components/Atoms/co-headline/CoHeadline.vue';
import EventBus from '../../../../../eventBus';

interface PlanData {
    plan: {
        Name: string;
        PriceInCents: number;
        ImageURL: string;
        Currency: string;
        TaxRate: {
            Percentage: number;
            DisplayName: string;
            Inclusive: boolean;
        };
    };
    populatedPlan: {
        Name: string;
        PriceInCents: number;
        ImageURL: string;
        Currency: string;
        TaxRate: {
            Percentage: number;
            DisplayName: string;
            Inclusive: boolean;
        };
    };

    userID: string;
    userName: string;
    startsAt: number;
    cancelledAt: number;
    createdAt: number;
    createdByID: string;
    createdByName: string;
}

interface UserMembership {
    subscriptionStatus: string;
    stripeCustomerLink: string;
    stripeCustomerID: string;

    currentBillingPeriodStart: number;
    currentBillingPeriodEnd: number;

    billingCycleAnchor: number;

    subscriptionCancelAt: number; // timestamp when subscription will be cancelled
    subscriptionCanceledAt: number; // timestamp when subscription was cancelled
    subscriptionStartDate: number; // timestamp when subscription was created
    subscriptionEndedAt: number; // timestamp when subscription was ended
    subscriptionCreated: number; // timestamp when subscription was created

    collectionMethod: string;

    currentPlan: PlanData;
    childPlans: PlanData[];
    parentPlanUserID: string;

    upcomingPlan: PlanData;
}

export default {
    name: 'MemberPlanView',
    components: {
        CoHeadline,
        CoRoundButton,
        CoPlanDetail,
        CoModal,
        CoPlanCancelAdmin,
        CoCardPlan,
        CoCard,
        CoHeading,
        CoAlert,
        CoTable,
        CoButton,
        CoLink,
        CoThumbnail,
    },
    props: {
        userId: {
            type: String,
            required: true,
        },
        userName: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            showCancelPlanModal: false,
            ID: this.userId,
            Membership: {} as UserMembership | null | undefined,
            childPlanTableColumns: [
                {
                    title: 'Name',
                    key: 'userName',
                },
                {
                    title: 'Plan',
                    key: 'plan.Name',
                },
                {
                    title: 'Plan details',
                    key: 'plan',
                },
            ],

            parentUser: null as any,
            renewLoading: false,
            cancelUpcomingLoading: false,
            membershipLoading: false,

            modalPlan: null as any,
        };
    },
    computed: {},
    mounted() {
        this.getUserMembership(this.ID);
    },

    methods: {
        updateSubscription() {
            this.$router.push(`/admin/member/${this.ID}/plan/update`);
        },
        createSubscription() {
            this.$router.push(`/admin/member/${this.ID}/plan/create`);
        },
        membershipCanceled() {
            this.showCancelPlanModal = false;
            this.getUserMembership(this.ID);
        },
        cancelSubscription() {
            this.showCancelPlanModal = true;
        },
        cancelUpcomingSubscription() {
            this.$refs.CancelScheduledChangeModal.show();
        },
        cancelUserUpcomingPlan() {
            this.cancelUpcomingLoading = true;
            this.$refs.CancelScheduledChangeModal.hide();
            const id = this.userId;
            axios({
                method: 'DELETE',
                url: `/admin/community/member/${id}/plan/upcoming`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    const message = {
                        Message: 'Upcoming plan was cancelled',
                    };
                    EventBus.$emit('INFO', message);
                    this.getUserMembership(id);
                })
                .catch((error) => {
                    console.log(error);
                    // emit error
                    const message = {
                        Message: 'Can`t cancel upcoming plan',
                        Details: error.response.data,
                    };
                    EventBus.$emit('ERROR', message);
                })
                .finally(() => {
                    this.cancelUpcomingLoading = false;
                });
        },
        discardUpcomingCancelling() {
            this.$refs.CancelScheduledChangeModal.hide();
        },
        renewSubscription() {
            const { ID } = this;
            this.renewLoading = true;
            axios({
                method: 'POST',
                url: `/admin/community/member/${ID}/plan/renew/V2`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(() => {
                    const message = {
                        Message: 'Plan renewed',
                    };
                    EventBus.$emit('INFO', message);
                    this.getUserMembership(ID);
                })
                .catch((error) => {
                    console.log(error);
                    const message = {
                        Message: 'Plan renew failed',
                        Details: error.response.data,
                    };
                    EventBus.$emit('ERROR', message);
                })
                .finally(() => {
                    this.renewLoading = false;
                });
        },
        planStatusColor(status: string) {
            if (!status) {
                return 'red';
            }

            // switch over the status

            switch (status) {
                case 'ACTIVE':
                    return 'green';
                case 'TRIALING':
                    return 'green';
                case 'PAST_DUE':
                    return 'yellow';
                case 'INCOMPLETE':
                    return 'yellow';
                case 'INCOMPLETE_EXPIRED':
                    return 'red';
                case 'CANCELED':
                    return 'red';
                case 'UNSPECIFIED':
                    return 'red';
                default:
                    return 'red';
            }
        },
        formatDate(timestamp: number) {
            return new Date(timestamp * 1000).toLocaleDateString();
        },
        getUserMembership(ID) {
            this.membershipLoading = true;
            axios({
                method: 'GET',
                url: `/admin/community/member/membership/${ID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        // cast response to UserMembership
                        this.Membership = response.data as UserMembership;
                        if (this.Membership.parentPlanUserID) {
                            this.adminGetUserByID(this.Membership.parentPlanUserID);
                        }
                    }
                })
                .catch((error) => {
                    this.Membership = {
                        status: 'UNSPECIFIED',
                        stripeCustomerLink: null,
                        stripeCustomerID: '',

                        currentBillingPeriodStart: null, // unix timestamp in seconds when the current billing period started
                        currentBillingPeriodEnd: null, // unix timestamp in seconds when the current billing period ends
                        billingCycleAnchor: null, // unix timestamp in seconds when the billing cycle anchor was set
                        cancelAt: null, // unix timestamp in seconds when the subscription will be canceled
                        canceledAt: null, // unix timestamp in seconds when the subscription was canceled
                        startDate: null, // unix timestamp in seconds when the subscription was created
                        endedAt: null, // unix timestamp in seconds when the subscription ended
                        created: null, // unix timestamp in seconds when the membership was created
                    };
                    console.log(error);
                })
                .finally(() => {
                    this.membershipLoading = false;
                });
        },

        adminGetUserByID(ID) {
            axios({
                method: 'GET',
                url: `/admin/community/member/${ID}`,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    if (response && response.data) {
                        this.parentUser = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        showParentPlanDetailsModal() {
            this.modalPlan = this.Membership.currentPlan.populatedPlan;
            this.$refs['plan-details-modal'].show();
        },
        showChildPlanDetailsModal(userId: string) {
            this.modalPlan = this.Membership.childPlans.find((plan: PlanData) => plan.userID === userId).populatedPlan;
            this.$refs['plan-details-modal'].show();
        },
    },
};
