






















import { PropType } from 'vue';
import { get } from 'lodash';

interface Price {
    PriceInCents: number;
    Currency: string;
    TaxRate: number;
    TaxName: string;
    Inclusive: boolean;
    Unit?: string; // Unit is optional can be month or year or null
    isFrom?: boolean; // isFrom flag is optional ads a "from" in front of the price string
}

export default {
    name: 'CoPrice',
    props: {
        priceObj: {
            type: Object as PropType<Price>,
            default: {} as Price,
        },
        items: {
            type: Array as PropType<Price[]>,
            default: () => [],
            required: false,
        },
        inheritTextSizing: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    computed: {
        price() {
            let price = 0;

            if (this.items.length > 0) {
                // filter out items with no price (e.g. free items)
                const items = this.items.filter((item) => item.PriceInCents > 0);
                price = items.reduce((acc, item) => acc + item.PriceInCents, 0) / 100;
            }

            if (this.priceObj.PriceInCents) {
                price += this.priceObj.PriceInCents / 100;
                return this.$n(price, 'currency');
            }
            return this.$n(0, 'currency');
        },
        currency() {
            switch (this.priceObj.Currency) {
                case 'EUR':
                case 'eur':
                    return '€';
                case 'USD':
                    return '$';
                case 'GBP':
                    return '£';
                default:
                    return this.priceObj.Currency;
            }
        },
    },
    methods: {
        get,
    },
};
