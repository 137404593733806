<template>
    <div class="bg-white round-unify-xs mh-100 h-100 d-flex flex-column align-items-end">
        <!-- header -->
        <div class="p-3 text-center border-bottom align-self-start w-100">{{ $t('labels.planupgradechose') }}</div>
        <!-- body -->
        <div class="align-self-start w-100 overflow-auto mh-100">
            <div v-if="plan && plan.Extras && plan.Extras.length !== 0">
                <div
                    v-for="(item, index) in plan.Extras"
                    :key="index"
                    class="p-3"
                    :class="{ 'border-bottom': index !== plan.Extras.length - 1 }"
                >
                    <div class="d-flex justify-content-between align-items-start">
                        <div>
                            <h3 class="mb-0">{{ item.Name }}</h3>
                            <p class="text-muted" v-if="item.Description">
                                {{ item.Description }}
                            </p>
                            <p class="mb-0 text-muted">
                                {{ calculatePrice(item) }}
                                {{ item.Currency }} /
                                <span v-if="plan.TimeInteval === 1">{{ $t('labels.year') }}</span>
                                <span v-else>{{ $t('labels.month') }}</span>
                            </p>
                            <small v-if="item.TaxRate">
                                {{ $t('labels.including') }} {{ item.TaxRate.Percentage }}%
                                {{ item.TaxRate.DisplayName }}
                            </small>
                        </div>
                        <div>
                            <b-button
                                variant="outline-danger btn-round"
                                v-if="isExtraSelected(item)"
                                @click="removeExtra(item)"
                            >
                                <b-icon
                                    icon="x"
                                    class="m-0"
                                    scale="1.3"
                                    style="position: relative; bottom: 2px"
                                ></b-icon>
                            </b-button>
                            <b-button v-else variant="outline-primary btn-round" @click="setExtras(item)">
                                <b-icon
                                    icon="plus"
                                    class="m-0"
                                    scale="1.3"
                                    style="position: relative; bottom: 2px"
                                ></b-icon>
                            </b-button>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between"></div>
                </div>
            </div>
            <div v-else class="p-3 text-center text-muted">This plan does not have any upgrades</div>
        </div>
        <!-- footer -->
        <div class="p-3 border-top d-flex justify-content-between mt-auto w-100">
            <coButton variant="secondary" @click="$router.push('/plans')" :label="$t('labels.back')" />
            <coButton variant="primary" @click="next" :label="$t('labels.next')" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'Upgrades',
    data() {
        return {
            selected: [],
        };
    },
    computed: {
        plan() {
            return this.$store.state.newMembership.Plan;
        },
    },
    created() {
        if (
            this.$store.state.newMembership.Plan &&
            (!this.$store.state.newMembership.Plan.Extras || this.$store.state.newMembership.Plan.Extras.length === 0)
        ) {
            this.selected = [];
            this.next();
        }
        if (this.$store.state.newMembership.Extras) {
            this.selected = this.$store.state.newMembership.Extras;
        }
    },
    methods: {
        calculatePrice(extra) {
            if (extra.TaxRateID === '' || extra.TaxRate.Inclusive) {
                const price = extra.PriceInCents / 100.0;
                return price.toFixed(2);
            }
            const price = extra.PriceInCents / 100.0;
            const totalPrice = (price * (extra.TaxRate.Percentage / 100 + 1)).toFixed(2);
            return totalPrice;
        },
        isExtraSelected(obj) {
            const item = this.selected.find((element) => element.ID === obj.ID);
            if (item) {
                return true;
            }
            return false;
        },

        removeExtra(extra) {
            this.selected = this.selected.filter((item) => item !== extra);
            this.$store.commit('SET_EXTRAS', this.selected);
        },
        setExtras(item) {
            this.selected.push(item);
            this.$store.commit('SET_EXTRAS', this.selected);
        },
        next() {
            this.$store.commit('SAVE_EXTRAS', this.selected);
        },
    },
};
</script>
