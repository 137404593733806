<template>
    <div>
        <div class="ml-md-4">
            <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
                <div class="mt-4 bg-white p-4 round-unify" v-if="space">
                    <h3>Build-in domain</h3>
                    <p class="text-primary">{{ space['DomainName'] }}</p>
                    <p>This is your built-in domain. Your community can always be accessed from this domain.</p>
                    <b-form @submit.stop.prevent="handleSubmit" ref="createResource" class="mt-3">
                        <b-form-group
                            id="input-group-domain"
                            label="Customize built-in domain"
                            label-for="input-domain"
                            class="col-12 col-sm-6 col-md-6 p-0"
                        >
                            <div class="d-flex align-items-center">
                                <b-form-input
                                    id="input-domain"
                                    class="form-control rounded-pill"
                                    v-model="newDomain"
                                    :state="isDomainFree"
                                    required
                                    ref="inputDomain"
                                    @update="handleDomainState()"
                                    placeholder="e.g. best-community"
                                    aria-describedby="input-domain-feedback"
                                    trim
                                ></b-form-input>
                                <div class="ml-2">
                                    <b-spinner v-if="checkingAvailability" label="Loading..."></b-spinner>
                                </div>
                            </div>
                            <p class="mt-2 mb-1 text-muted">Preview: {{ newDomain }}.coapp.io</p>
                            <p v-if="!isDomainValid" class="text-danger" id="input-domain-feedback">Invalid domain.</p>

                            <span v-if="isDomainValid && isDomainFree === false"> This domain already taken. </span>
                            <p class="text-muted mt-3">
                                A domain name can be up to 63 characters (letters, numbers or combination) long.
                                <br />The only symbol character domain names can include is a hyphen (-) although the
                                domain name cannot start or end with a hyphen nor have consecutive hyphens.
                            </p>
                        </b-form-group>

                        <div class="d-flex align-items-center justify-content-end">
                            <b-button variant="primary" type="submit" :disabled="!isDomainFree || !isDomainValid"
                                >Save
                            </b-button>
                        </div>
                    </b-form>
                </div>
            </b-overlay>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import debounce from 'debounce';
import Vue from 'vue';
import EventBus from '@/eventBus';
import { test } from 'linkifyjs';

export default {
    name: 'Domain',

    data() {
        return {
            loading: false,
            space: null,

            newDomain: '',
            currentDomain: '',
            isDomainFree: null,
            isDomainValid: true,
            checkingAvailability: false,

            cancelTokenSource: null,
            pad: 0,
        };
    },
    created() {
        this.getSpaceSettings();
    },
    methods: {
        handleDomainState() {
            this.checkingAvailability = true;
            this.isDomainFree = null;
            this.isDomainValid = true;

            if (this.cancelTokenSource) {
                this.cancelTokenSource.cancel('Previous request aborted due to a new request.');
            }
            this.cancelTokenSource = axios.CancelToken.source();

            this.domainState();
        },
        domainState: debounce(function (e) {
            if (/^[a-zA-Z0-9](?!.*--)[a-zA-Z0-9-]{1,61}[a-zA-Z0-9]$/.test(`${this.newDomain}`) === false) {
                this.pad++;
                this.isDomainValid = false;
                this.isDomainFree = false;
                return;
            }
            this.isDomainValid = true;

            const subdomain = this.newDomain;
            if (subdomain === this.currentDomain) {
                this.isDomainFree = null;
            } else if (subdomain === '' || subdomain === null || subdomain === undefined || subdomain.includes(' ')) {
                this.isDomainFree = false;
            } else {
                this.isDomainFree = null;
                this.checkingAvailability = true;

                this.pad++; // pad makes sure that only latest call matter
                const { pad } = this;
                axios({
                    cancelToken: this.cancelTokenSource.token,
                    method: 'GET',
                    url: `/admin/space/is-domain-free?subdomain=${subdomain}`,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then((response) => {
                        if (pad === this.pad) {
                            this.checkingAvailability = false;
                            this.isDomainFree = true;
                        }
                    })
                    .catch((error) => {
                        if (!axios.isCancel(error) && pad === this.pad) {
                            this.checkingAvailability = false;
                            this.isDomainFree = false;
                        }
                    });
            }
        }, 2000),
        handleSubmit() {
            this.$refs.inputDomain.blur();
            this.loading = true;
            const data = JSON.stringify({ Subdomain: this.newDomain });

            this.isDomainFree = null;

            axios({
                method: 'POST',
                url: '/admin/space/new-built-in-domain',
                withCredentials: true,
                data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    this.currentDomain = this.newDomain;
                    if (response && response.data) {
                        const msg = {
                            Message: 'Built-in domain updated',
                            Details: '',
                        };
                        EventBus.$emit('INFO', msg);
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to change built-in domain',
                        Details: '',
                    };
                    EventBus.$emit('ERROR', msg);
                });
        },
        getSpaceSettings() {
            this.loading = true;
            axios({
                method: 'GET',
                url: '/admin/space/settings',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    this.loading = false;
                    if (response && response.data) {
                        this.space = response.data;
                        if (this.space.DomainName.includes('.')) {
                            const tokens = this.space.DomainName.split('.');
                            this.newDomain = tokens[0];
                        } else {
                            this.newDomain = this.space.DomainName;
                        }
                        this.currentDomain = this.newDomain;
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    const msg = {
                        Message: 'Failed to get space settings',
                        Details: '',
                    };
                    EventBus.$emit('ERROR', msg);
                });
        },
    },
};
</script>
