<template>
    <b-overlay :show="isLoading">
        <div class="mb-3">
            <Editor
                id="textarea-default"
                :placeholder="$t('placeholders.updatecreate')"
                rows="5"
                class=""
                ref="tiptap"
                with-image
                v-model="text"
                v-bind:imageFiles="attachments"
            ></Editor>
        </div>

        <div class="d-flex justify-content-end">
            <b-button class="my-2 mr-2" variant="outline-primary" @click="cancel">{{ $t('labels.cancel') }}</b-button>

            <b-button class="my-2" variant="primary" v-on:click="publishUpdate" :disabled="!text">
                <span class="align-items-center">{{ $t('labels.save') }}</span>
            </b-button>
        </div>
    </b-overlay>
</template>

<script>
import EventBus from '@/eventBus';
import Vue from 'vue';

export default {
    name: 'CreateProjectUpdate',
    data() {
        return {
            isLoading: false,
            attachments: [],
            attachmentsURLs: [],
            text: '',
            isOpen: false,
            update: {},
        };
    },
    props: ['user', 'projectID'],
    computed: {},

    watch: {
        state(newVal, oldVal) {
            if (!oldVal && newVal) {
                this.isOpen = newVal;
            }
        },
        attachmentsURLs(newVal, oldVal) {
            if (newVal.length == this.attachments.length) {
                this.postUpdate();
            }
        },
        $refs(newVal, oldVal) {
            // if (newVal.input) {
            //   newVal.input.focus();
            // }
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.$refs.tiptap.focus();
        });
    },
    updated() {
        // this.$refs.input.focus()
        // EventBus.$on('CREATEUPDATEVIEW', function (payLoad) {
        //     this.$refs.input.focus()
        //   }, this);
    },
    methods: {
        cancel() {
            EventBus.$emit('NEWUPDATE', null);
        },
        uploadImages() {
            const infoMsg = {
                Message: 'Uploading images',
                Details: null,
            };
            EventBus.$emit('INFO', infoMsg);
            const list = this.attachments;

            this.attachments.forEach(function (entry, index) {
                this.$store
                    .dispatch('imageUpload', {
                        file: entry.File,
                        url: '/upload/image/project-update',
                    })
                    .then((response) => {
                        this.attachmentsURLs.push(response);
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        const errorMsg = {
                            Message: 'Could not upload one or more images, please try again or check details',
                            Details: error.response.data,
                        };
                        if (
                            error.response &&
                            error.response.data &&
                            error.response.data.message === 'Request Entity Too Large'
                        ) {
                            errorMsg.Message = 'The file you tried to upload is too large. Max 10 MB.';
                        }
                        EventBus.$emit('ERROR', errorMsg);
                    });
            }, this);
        },

        publishUpdate(event) {
            this.isLoading = true;
            this.update.ProjectID = this.projectID;
            this.update.Text = this.text.replace(/<p><\/p>/g, '<br>');
            this.update.UserID = this.$store.state.me.ID;
            this.update.Version = '1';

            if (this.attachments.length !== 0) {
                this.uploadImages();
            } else {
                this.postUpdate();
            }
        },

        postUpdate() {
            this.update.ImageURLs = this.attachmentsURLs;
            const update = JSON.stringify(this.update);
            this.$store
                .dispatch('addUpdate', update)
                .then((response) => {
                    setTimeout(() => {
                        this.isLoading = false;
                        this.attachments = [];
                        this.$refs.tiptap.clear(true, true);
                        const infoMsg = {
                            Message: 'Update created',
                            Details: null,
                        };
                        EventBus.$emit('INFO', infoMsg);
                        EventBus.$emit('NEWUPDATE', null);
                        this.isOpen = false;
                    }, 1000);
                }, this)
                .catch((error) => {
                    this.isLoading = false;
                    const errorMsg = {
                        Message: 'Could not upload update, please try again or check details',
                        Details: error.response.data,
                    };
                    EventBus.$emit('ERROR', errorMsg);
                    this.isOpen = false;
                });
        },
    },
};
</script>
