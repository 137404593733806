<template>
    <textarea v-model="content" :placeholder="placeholder" @input="handleInput" :rows="rows"></textarea>
</template>

<script>
export default {
    name: 'CoTextArea',
    props: {
        label: String,
        value: String,
        type: String,
        placeholder: String,
        readonly: Boolean,
        icon: String,
        rows: {
            type: Number,
            default: 3,
        },
    },
    emits: ['input'],
    data() {
        return {
            content: this.value,
        };
    },
    watch: {
        content(newVal) {
            this.$emit('input', newVal);
        },
        value(newVal) {
            this.content = newVal;
        },
    },
    created() {
        this.content = this.value;
    },
    mounted() {
        this.$nextTick(() => {
            this.content = this.value;
        });
    },
    methods: {
        handleInput(e) {
            this.content = e.target.value;

            this.$emit('input', this.content);
        },
    },
};
</script>

<style lang="less" scoped>
@import '../../../assets/less/variables.less';
@import './CoTextArea.less';
</style>
