export function initializeSdk(options) {
    // Create a queue, but don't obliterate an existing one!
    const birdeatsbug = (window.birdeatsbug = window.birdeatsbug || []);
    // If the real SDK is already on the page, return.
    if (birdeatsbug.initialize) return;
    // If the snippet was invoked already, show an error.
    if (birdeatsbug.invoked) {
        // eslint-disable-next-line no-console
        console.error('birdeatsbug snippet included twice.');
        return;
    }
    // Invoked flag, to make sure the snippet is never invoked twice.
    birdeatsbug.invoked = true;
    // A list of the methods in the SDK to stub.
    birdeatsbug.methods = [
        'setOptions',
        'trigger',
        'resumeSession',
        'takeScreenshot',
        'startRecording',
        'stopRecording',
        'stopSession',
        'uploadSession',
        'deleteSession',
    ];
    // Define a factory to create stubs. These are placeholders
    // for methods in the SDK so that you never have to wait
    // for it to load to record method calls. The `method` is
    // stored as the first argument, so we can replay the data.
    birdeatsbug.factory = function (method) {
        return function () {
            const args = Array.prototype.slice.call(arguments);
            args.unshift(method);
            birdeatsbug.push(args);
            return birdeatsbug;
        };
    };
    // For each of our methods, generate a queueing stub.
    for (let i = 0; i < birdeatsbug.methods.length; i++) {
        const key = birdeatsbug.methods[i];
        birdeatsbug[key] = birdeatsbug.factory(key);
    }
    // Define a method to load the SDK
    // and that will be sure to only ever load it once.
    birdeatsbug.load = function () {
        // Create an async script element.
        const script = document.createElement('script');
        script.type = 'module';
        script.async = true;
        script.src = `https://sdk.birdeatsbug.com/v2/core.js`;
        // Insert the script next to the first script element.
        const mountJsBefore = document.getElementsByTagName('script')[0] || document.body.firstChild;
        mountJsBefore.parentNode.insertBefore(script, mountJsBefore);

        // Create an async style element
        const style = document.createElement('link');
        style.rel = 'stylesheet';
        style.type = 'text/css';
        style.href = `https://sdk.birdeatsbug.com/v2/style.css`;
        // Insert the styles before the 1st style
        const mountCssBefore = document.querySelector('link[rel="stylesheet"]') || mountJsBefore;
        mountCssBefore.parentNode.insertBefore(style, mountCssBefore);
    };
    // Load SDK
    birdeatsbug.load();
    // Pass options
    window.birdeatsbug.setOptions({
        ...options,
        publicAppId: 'bec77021-b415-4b14-ad4b-96963e6fd471',
    });
}

export const sdkOptions = {
    // ... our custom SDK options
    ui: {
        theme: 'light',
        defaultButton: false,
        position: 'bottom-right',
        watermark: false,
        previewScreen: {
            /* either screenshot or screen recording needs to be provided */
            visualProof: 'required',
            /* screenshot button is hidden, but screen recording is shown */
            visualProofButtons: {
                screenshot: false,
                screenRecording: true,
            },
        },
    },
};
